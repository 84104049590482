import React, { Component } from "react";
import "../mapactionone/index.scss";
import mapActionApiActions from "../../redux/mapactions/actions";
import { connect } from "react-redux";
import ReactLoader from "../../component/react-loader/react-loader.component";
import summaryApiActions from "../../redux/summary/actions";

import vehicleMapDashboardApiActions from "../../redux/vehicle_map_dashboard/actions";
import { withRouter } from "react-router";
let start_date = "";
let end_date = "";
let duration = "";

const { getVehicleMapDashboardAPI } = vehicleMapDashboardApiActions;
const { getSummaryAPI } = summaryApiActions;

const { getMapActionAPI } = mapActionApiActions;
class MapactionOne extends Component {
  constructor(props) {
    super(props);
    this.state = {
      button: true,
      buttonone: true,
      buttontwo: true,

      running_count: "",
      stopped_count: "",
      not_reachable_count: "",
      spare:"",
      all: "",
      clickedall: true,
      clickedMoving: false,
      clickedStopped: false,
      clickedDisconnected: false,
      clickedSpare:false,

    };
    this.handleClickMoving = this.handleClickMoving.bind(this);
    this.handleClickStopped = this.handleClickStopped.bind(this);
    this.handleClickNotReachable = this.handleClickNotReachable.bind(this);
    this.handleClickAllVehicle = this.handleClickAllVehicle.bind(this);
    this.handleClickspare=this.handleClickspare.bind(this);
    this.getMapActionDetails = this.getMapActionDetails.bind(this);
  }

  componentDidMount() {
    start_date = sessionStorage.getItem("start_timestamp");
    end_date = sessionStorage.getItem("end_timestamp");
    duration = sessionStorage.getItem("duration");
    this.getMapActionDetails();
    this.setState({ clickedDisconnected: false });
    this.setState({ clickedStopped: false });
    this.setState({ clickedMoving: false });
    this.setState({ clickedMoving: false });
    this.setState({clickedSpare:false})
    sessionStorage.setItem("vehicle_status", "ALL");
  }

  getVehicleMap = async (status) => {
    sessionStorage.setItem("vehicle_status", status);
    let payLoad = {
      api_key: "MAP_DASHBOARD_VEHICLE",
      status: status,
      customerId: sessionStorage.getItem("customerId"),
    };

    await this.props.getVehicleMapDashboardAPI(payLoad);
  };

  getSummaryDetails = async () => {
    let payLoad = {
      //user_id: "1",
      user_id: sessionStorage.getItem("userId"),
      customerId: sessionStorage.getItem("customerId"),
      status: sessionStorage.getItem("vehicle_status"),
    };

    await this.props.getSummaryAPI(payLoad);
  };

  getMapActionDetails = async () => {
    let payLoad = {
      api_key: "CONNECTED_VEHICLE_TRACK_CHARGER_AND_VEHICLE_COUNT",
      charger_location: "Bangalore",
      // user_id: "1",
      user_id: sessionStorage.getItem("userId"),
      customerId: sessionStorage.getItem("customerId"),
      vehicle_number: ["BLR001", "BLR002", "BLR003", "BLR004"],
      start_timestamp: start_date,
      end_timestamp: end_date,
      duration: duration,
    };

    await this.props.getMapActionAPI(payLoad);
  };

  componentWillReceiveProps(nextProps) {
    //console.log("mapaction",nextProps?.mapActionApi?.mapActionApi?.result?.data?.data)
    if (nextProps?.mapActionApi?.mapActionApi?.result?.data?.responseData) {
      this.setState({
        running_count:
          nextProps.mapActionApi.mapActionApi.result.data.responseData
            .moving_vehicle_count,
      });
      this.setState({
        stopped_count:
          nextProps.mapActionApi.mapActionApi.result.data.responseData
            .stopped_vehicle,
      });
      this.setState({
        not_reachable_count:
          nextProps.mapActionApi.mapActionApi.result.data.responseData
            .non_respond_vehicle,
      });
      this.setState({
        spare:
          nextProps.mapActionApi.mapActionApi.result.data.responseData
            .spare,
      });
      this.setState({
        all:
          this.state.running_count +
          this.state.stopped_count +
          this.state.not_reachable_count +
          this.state.spare
      });
    }
  }
  onHeaderPressed = () => {
    this.setState({
      "box-shadow": "3px 3px 3px 3px rgba(0, 0, 0, 0.2) !important",
    });
  };

  handleClickMoving(event) {
    // this.setState({
    //   button: !this.state.button,
    // });
    //console.log("moving");
    this.getVehicleMap("RUNNING");
    this.setState({
      "box-shadow": "3px 3px 3px 3px rgba(0, 0, 0, 0.2) !important",
    });
    this.setState({ clickedStopped: false });
    this.setState({ clickedDisconnected: false });
    this.setState({ clickedall: false });
    this.setState({clickedSpare:false});
    this.setState({ clickedMoving: true });
    sessionStorage.setItem("Clicked", false);
    // this.getSummaryDetails();
  }
  handleClickStopped() {
    this.getVehicleMap("STOPPED");
    this.setState({
      "box-shadow": "3px 3px 3px 3px rgba(0, 0, 0, 0.2) !important",
    });
    this.setState({ clickedMoving: false });
    this.setState({ clickedDisconnected: false });
    this.setState({ clickedall: false });
    this.setState({clickedSpare:false});
    this.setState({ clickedStopped: true });
    sessionStorage.setItem("Clicked", false);
    //  this.getSummaryDetails();
  }

  handleClickNotReachable() {
    this.getVehicleMap("DISCONNECTED");
    this.setState({
      "box-shadow": "3px 3px 3px 3px rgba(0, 0, 0, 0.2) !important",
    });
    this.setState({ clickedStopped: false });
    this.setState({ clickedMoving: false });
    this.setState({ clickedall: false });
    this.setState({clickedSpare:false});
    this.setState({ clickedDisconnected: true });
    sessionStorage.setItem("Clicked", false);
    //  this.getSummaryDetails();
  }

  handleClickspare(){
    this.getVehicleMap("SPARE");
    this.setState({
      "box-shadow": "3px 3px 3px 3px rgba(0, 0, 0, 0.2) !important",
    });
    this.setState({ clickedStopped: false });
    this.setState({ clickedMoving: false });
    this.setState({ clickedall: false });
    this.setState({ clickedDisconnected: false });
    this.setState({ clickedSpare: true });
    sessionStorage.setItem("Clicked", false);
    //  this.getSummaryDetails();
  }
  handleClickAllVehicle() {
    this.getVehicleMap("ALL");
    this.setState({ clickedDisconnected: false });
    this.setState({ clickedStopped: false });
    this.setState({ clickedSpare: false });
    this.setState({ clickedall: true });
    this.setState({ clickedMoving: false });
    sessionStorage.setItem("Clicked", false);
    this.getSummaryDetails();
  }

  getLoader = () => (
    <div className="loader-overlay">
      <div className="loader-container">
        <ReactLoader />
      </div>
    </div>
  );
  render() {
    //  const {item}=this.state

    // console.log("moving", this.state.clickedMoving)
    // console.log("stop", this.state.clickedStopped)
    // console.log("disconnect", this.state.clickedDisconnected)
    return (
      <div className="container-fluid d-flex">
        {this.props.mapActionApi?.mapActionApi?.loading ? (
          <div className="loader-overlay-one">
            <div className="loader-container">
              <ReactLoader />
            </div>
          </div>
        ) : null}
        {/* <div className="mapactionone-head" onClick={this.handleClickAllVehicle}>
          Vehicle Status ({this.state.all})
        </div> */}

        <div className="row ">
          <div className="col-sm-2 gx-2 me-3">
            <div
              className={`card allbg ${
                this.state.clickedall && "headSelected"
              }`}
            >
              <div onClick={this.handleClickAllVehicle}>
                <p className="mapaction-cardtext-2">All</p>
                <div className="all_countNumberBox">
                  <span style={{ color: "red" }}>
                    <b>{this.state.all}</b>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-2 gx-2 me-3">
            <div
              className={`card runningBg ${
                this.state.clickedMoving && "headSelected"
              }`}
            >
              <div onClick={this.handleClickMoving}>
                <p className="mapaction-cardtext">Moving</p>
                <div className="moving_countNumberBox">
                  <span style={{ color: "red" }}>
                    <b>{this.state.running_count}</b>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-2 gx-2 me-3">
            <div
              className={`card stoppedBg ${
                this.state.clickedStopped && "headSelected"
              }`}
            >
              <div onClick={this.handleClickStopped}>
                <p className="mapaction-cardtext">Stopped</p>
                <div className="stopped_countNumberBox">
                  <span style={{ color: "red" }}>
                    <b>{this.state.stopped_count}</b>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-2 gx-2 me-3">
            {/* <div className="card" style={{ backgroundColor: "#8c98b2" }}> */}
            <div
              className={`card disconnectedBg ${
                this.state.clickedDisconnected && "headSelected"
              }`}
            >
              <div
                // className={this.state.buttontwo ? "buttonTrue" : "buttonFalse"}
                onClick={this.handleClickNotReachable}
              >
                <p className="mapaction-cardtext-1">Not Reachable</p>
                <div className="reachable_countNumberBox">
                  <span style={{ color: "red" }}>
                    <b>{this.state.not_reachable_count}</b>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-2 gx-2 me-2">
            {/* <div className="card" style={{ backgroundColor: "#8c98b2" }}> */}
            <div
              className={`card spareBg ${
                this.state.clickedSpare && "headSelected"
              }`}
            >
              <div
                // className={this.state.buttontwo ? "buttonTrue" : "buttonFalse"}
                onClick={this.handleClickspare}
              >
                <p className="mapaction-cardtext-1">Spare Vehicle</p>
                <div className="reachable_countNumberBox">
                  <span style={{ color: "red" }}>
                    <b>{this.state.spare}</b>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
    //chargeSummary: state.chargeSummary,
  };
};

export default connect(mapStateToProps, {
  getMapActionAPI,
  getVehicleMapDashboardAPI,
  getSummaryAPI,
})(withRouter(MapactionOne));
