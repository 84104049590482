import React, { Component } from 'react';
import './index.scss';

class RefreshToggler extends Component {
    constructor(props) {
        super(props);
         this.state = {
              off : true,
         }
        
    }
    componentDidMount(){

        if (sessionStorage.getItem('dashboardrefresh')) {
            this.setState({
                off: false,
            })
        }
        else{
            this.setState({
                off: true,
            })
         }
    }
    togglerClick(e, val) {
        e.preventDefault();
         if(val==="off"){
            this.setState({
                off: true,
            })
         }
         else{
            this.setState({
                off: false,
            })
         }
     
        this.props.RefreshTogglerFunction(val);
    }

    render() {
        return (
            <div>
                <div className="refresh-toggler">

                    {/* <span className="refresh-toggler-text">Auto Refresh</span>
                    <div className= {!this.state.off? "refresh-toggler-on-state" :"refresh-toggler-on"}  onClick={(e) => { this.togglerClick(e, "on") }}>ON</div>
                    <div className= {this.state.off? "refresh-toggler-off-state" :"refresh-toggler-off"} onClick={(e) => { this.togglerClick(e, "off") }} > OFF</div>
               */}
                </div>
            </div>

        );
    }
}

export default RefreshToggler;