import React, { Component } from "react";
import "./index.scss";
import ReactTable from "react-table";
import ReactTooltip from "react-tooltip";
import "react-table/react-table.css";
import Workbook from "react-excel-workbook";
import ReactLoader from "../../component/react-loader/react-loader.component";
import moment from "moment";
import DatePicker from "react-datepicker";
import { addDays } from "date-fns";
import "react-datepicker/dist/react-datepicker.css";
import BackToScreen from "../../component/back_to_screen";

import dailyShiftWiseReportApiActions from "../../redux/daily_shift_wise_report/actions";
import { connect } from "react-redux";
import { withRouter } from "react-router";

// var now = new Date();
// now.setDate(now.getDate());

// var lastDay = new Date();
// lastDay.setHours(lastDay.getHours() - 6);

var startTimestamp = new Date();
startTimestamp.setHours(0);
startTimestamp.setMinutes(0);
startTimestamp.setSeconds(0);

var endTimestamp = new Date();
endTimestamp.setHours(23);
endTimestamp.setMinutes(59);
endTimestamp.setSeconds(58);
const { getDailyShiftWiseReportAPI } = dailyShiftWiseReportApiActions;

class DailyOpShiftWiseReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      Chargesummaryreport: [],
      tempData: [],
      filteredData: [],
      searchInput: "",
      start_date: startTimestamp,
      end_date: endTimestamp,
      date: new Date(),
      editingfields: {},
    };
    this.backtodashboardPage = this.backtodashboardPage.bind(this);
    //this.getDailyShiftWiseDetails = this.getDailyShiftWiseDetails.bind(this);
  }

  componentDidMount() {
    // start_date = sessionStorage.getItem("start_timestamp");
    // end_date = sessionStorage.getItem("end_timestamp");
    //duration = sessionStorage.getItem("duration");
    this.getDailyShiftWiseDetails();
  }

  getDailyShiftWiseDetails() {
    const { start_date, end_date } = this.state;
    if (start_date < end_date) {
      let payLoad = {
        charger_location: "Bangalore",
        user_id: sessionStorage.getItem("userId"),
        customerId: sessionStorage.getItem("customerId"),
        start_timestamp: moment(start_date).format("YYYY-MM-DD HH:mm:ss"),
        end_timestamp: moment(end_date).format("YYYY-MM-DD HH:mm:ss"),
        vehicle_number: ["BLR001", "BLR002", "BLR003", "BLR004"],

        // start_timestamp: moment((this.state.start_date.setHours(0, 0, 0))).format(
        //   "YYYY-MM-DD HH:mm:ss"
        // ),
        // end_timestamp: moment(
        //   addDays(this.state.end_date.setHours(23, 59, 59), 0)
        // ).format("YYYY-MM-DD HH:mm:ss"),
      };

      this.props.getDailyShiftWiseReportAPI(payLoad);
    } else if (start_date > end_date) {
      alert("End Date should be greater than Start Date");
    } else {
      alert("End Date should be greater than Start Date");
    }
  }

  componentWillReceiveProps(nextProps) {
    console.log("dailyShiftWise", nextProps);
    if (
      nextProps?.DailyShiftWiseReport?.dailyShiftWiseReportApi?.result?.data
        ?.responseData
    ) {
      this.setState({
        data: nextProps.DailyShiftWiseReport.dailyShiftWiseReportApi.result.data
          .responseData,
      });
      this.setState({
        filteredData:
          nextProps.DailyShiftWiseReport.dailyShiftWiseReportApi.result.data
            .responseData,
      });
    }
  }

  onSubmit = (e) => {
    this.getDailyShiftWiseDetails();
    // this.onReset()
  };

  dailyShiftWiseHandleChange = (event) => {
    event.preventDefault();

    const search = event.target.value.toLowerCase();
    this.setState({
      searchInput: search,
      filteredData: this.state.data.filter(
        (item) =>
          (item.reg_no && item.reg_no.toLowerCase().includes(search)) 
          //||
          // (item.date && item.date.toLowerCase().includes(search))
      ),
    });
  };

  backtodashboardPage() {
    let tabItem = {
      tabIndex: 16,
      tabName: "Dashboard",
    };
    this.props.selectedTab(null, tabItem);
  }

  getLoader = () => (
    <div className="loader-overlay">
      <div className="loader-container">
        <ReactLoader />
      </div>
    </div>
  );

  onReset = () => {
    //console.log("haii")
    this.setState({
      startTimestamp: "",
      endTimestamp: "",
    });
  };
  Compare = (name, value) => {
    if (name == "start_date") {
      //if
      this.setState({ start_date: value });
    } else if (name == "end_date") {
      if (
        this.state.start_date != "" &&
        this.state.end_date != "" &&
        value >= this.state.start_date
      ) {
        this.setState({ end_date: value });
      } else {
        if (value < this.state.start_date) {
          alert("End Date should be greater than Start Date");
        }
      }
    }
  };
  handleDateChange(evt) {
    const value = evt.target.value;

    const name = evt.target.name;
    this.setState((prevState) => ({
      editingfields: {
        // object that we want to update

        ...prevState.editingfields, // keep all other key-value pairs
        [name]: moment(value).format("YYYY-MM-DD HH:mm:00"),
        // update the value of specific key
      },
    }));
    this.setState({ [name]: value });
  }
  render() {
    // console.log("val check",this.state.end_date)
    let { searchInput } = this.state;
    let chargeData = [];
    if (this.state.data) {
      chargeData = this.state.data;
    }

    return (
      <div>
        {this.props.DailyShiftWiseReport?.dailyShiftWiseReportApi?.loading ? (
          <div className="loader-overlay">
            <div className="loader-container">
              <ReactLoader />
            </div>
          </div>
        ) : null}
        <div className="fleet-health-backward">
          <BackToScreen routingCall={this.backtodashboardPage} />
          <h3 className="daily_shift_op_report">Daily Shift Wise Report</h3>
        </div>

        <div className="daily_shift_op_report_div">
          <div className="daily_shift_op_report_labels">
            <label className="daily_shift_op_report_style_1">Start Date:</label>
            <DatePicker
              autocomplete="off"
              name="start_date"
              selected={this.state.start_date}
              maxDate={moment().toDate()}
              minDate={addDays(new Date(), -90)}
              value={
                this.state.editingfields.start_date == "undefined"
                  ? this.state.start_date.setHours(0, 0, 0)
                  : this.state.editingfields.start_date
              }
              onChange={(value) =>
                this.handleDateChange({
                  target: { name: "start_date", value },
                })
              }
              timeInputLabel="Time:"
              dateFormat="yyyy-MM-dd HH:mm:00"
              showTimeInput={true}
            />

            <label className="daily_shift_op_report_style">End Date:</label>
            <DatePicker
              autocomplete="off"
              // showTimeSelect
              selectsEnd
              name="end_date"
              selected={this.state.end_date}
              //selected={this.state.end_date.setHours(23, 59, 59)}
              maxDate={moment().toDate()}
              minDate={addDays(new Date(), -90)}
              value={
                this.state.editingfields.end_date == "undefined"
                  ? this.state.end_date.setHours(23, 59, 59)
                  : this.state.editingfields.end_date
              }
              onChange={(value) =>
                this.handleDateChange({
                  target: { name: "end_date", value },
                })
              }
              timeInputLabel="Time:"
              dateFormat="yyyy-MM-dd HH:mm:00"
              showTimeInput
            />
          </div>

          <div>
            <button
              className="daily_shift_op_report_button_submit"
              onClick={this.onSubmit}
            >
              <i className="fa fa-check" style={{ paddingRight: "5px" }}></i>
              Submit
            </button>
          </div>
          <div>
            <button
              className="daily_shift_op_report_button-submit_1"
              onClick={this.onReset}
            >
              <i className="fa fa-refresh" style={{ paddingRight: "5px" }}></i>{" "}
              Reset
            </button>
          </div>

          <div className="daily_shift_op_report_search">
            <form
              onSubmit={(e) => {
                e.preventDefault();
              }}
            >
              <input
                className="daily_shift_op_report_search_input"
                type="text"
                id="filter"
                placeholder="Search Table (Vehicle Name)"
                value={searchInput || ""}
                onChange={this.dailyShiftWiseHandleChange}
                // onkeypress="return event.keyCode != 13"
              />
            </form>
          </div>

          <div>
            <Workbook
              filename="Daily-Shift-Wise-Report.xlsx"
              element={
                <div
                  className="daily_shift_op_report_download"
                  data-tip
                  data-for="registerTip"
                >
                  Export
                  <img
                    src={require("../../assets/images/export/export.png")}
                    alt=""
                    className="daily_shift_op_report_download_img"
                  />
                  <ReactTooltip id="registerTip" place="top" effect="solid">
                    Download
                  </ReactTooltip>
                </div>
              }
            >
              <Workbook.Sheet
                data={this.state.filteredData}
                name="Shift-Wise-Report"
              >
                <Workbook.Column label="Date" value="date" />
                <Workbook.Column label="Vehicle Name" value="reg_no" />
                <Workbook.Column
                  label="1st Shift Start SOC % "
                  value="morning_start_soc"
                />

                <Workbook.Column
                  label="1st Shift End SOC %"
                  value="morning_end_soc"
                />

                <Workbook.Column
                  label="1st Shift SOC Discharged"
                  value="morning_total_soc"
                />

                <Workbook.Column
                  label="1st Shift Start ODO"
                  value="morning_start_odo"
                />

                <Workbook.Column
                  label="1st Shift End ODO"
                  value="morning_end_odo"
                />

                {/* <Workbook.Column
                  label="Morning Total ODO"
                  value="morning_total_odo"
                /> */}

                <Workbook.Column
                  label=" 1st Shift Km Covered"
                  value="morning_distance_travelled"
                />

                <Workbook.Column
                  label="1st Shift KM/SOC"
                  value="morning_km_per_soc"
                />

                <Workbook.Column
                  label="1st Shift Driver Name"
                  value="morning_driver_name"
                />

                <Workbook.Column
                  label="1st Shift Start hrlfc"
                  value="morning_start_hrlfc                  "
                />

                <Workbook.Column
                  label="2nd Shift Start SOC %"
                  value="evening_start_soc"
                />
                <Workbook.Column
                  label="2nd Shift End SOC %"
                  value="evening_end_soc"
                />
                <Workbook.Column
                  label="2nd Shift SOC Discharged"
                  value="evening_total_soc"
                />
                <Workbook.Column
                  label="2nd Shift Start ODO"
                  value="evening_start_odo"
                />
                <Workbook.Column
                  label="2nd Shift End ODO"
                  value="evening_end_odo"
                />
                {/* <Workbook.Column
                  label="Evening Total ODO"
                  value="evening_total_odo"
                /> */}
                <Workbook.Column
                  label=" 2nd Shift Km Covered"
                  value="evening_distance_travelled"
                />

                <Workbook.Column
                  label="2nd shift Km/Soc"
                  value="evening_km_per_soc"
                />

                <Workbook.Column
                  label="2nd Shift Driver Name"
                  value="evening_driver_name"
                />

                <Workbook.Column
                  label="2nd Shift Start hrlfc"
                  value="evening_start_hrlfc"
                />

                <Workbook.Column
                  label="Total Soc Consumed"
                  value="total_soc_consumed_per_day"
                />
                <Workbook.Column
                  label="Total Km Consumed"
                  value="total_km_consumed_per_day"
                />
                <Workbook.Column
                  label="Total Kw/Soc"
                  value="total_km_per_soc_consumed_per_day"
                />
                <Workbook.Column
                  label="total kw consumed per day"
                  value="total_kw_consumed_per_day"
                />
                <Workbook.Column label="Total KW/KM" value="total_kw_per_km" />
              </Workbook.Sheet>
            </Workbook>
          </div>
        </div>
        <div className="daily_op_wise_table">
          <ReactTooltip place="left" type="success" effect="solid" />
          <ReactTable
            NoDataComponent={() => null}
            pageSizeOptions={[20, 30, 50, 80, 90, 100, 125, 130, 200, 500]}
            style={{
              height: "490px",
              marginTop: "0%",
              marginLeft: "40px",
              marginRight: "30px",
              //direction: "rtl",
            }}
            data={this.state.filteredData}
            columns={[
              {
                Header: "S.No",
                id: "serialNumber",
                filterable: false,
                resizable: false,
                width: 90,

                Cell: (row) => {
                  return <div>{row.index + 1}</div>;
                },
              },

              {
                Header: "Date",
                id: "date",
                accessor: (d) => d.date,
                width: 160,
                filterAll: true,
                resizable: false,
              },

              {
                Header: "Vehicle Name",
                id: "reg_no",
                accessor: (d) => d.reg_no,
                width: 160,
                filterAll: true,
                resizable: false,
              },

              // {
              //   Header: "Bus No.",
              //   id: "vehicleName",
              //   accessor: (d) => d.vehicleName,
              //   width: 160,
              //   filterAll: true,
              //   resizable: false,
              // },

              // {
              //   Header: "Route No.",
              //   id: "vehicleName",
              //   accessor: (d) => d.vehicleName,
              //   width: 160,
              //   filterAll: true,
              //   resizable: false,
              // },

              // {
              //   Header: "Route Details",
              //   id: "vehicleName",
              //   accessor: (d) => d.vehicleName,
              //   width: 160,
              //   filterAll: true,
              //   resizable: false,
              // },

              // {
              //   Header: "END Charging As Per Charger",
              //   id: "vehicleName",
              //   accessor: (d) => d.vehicleName,
              //   width: 160,
              //   filterAll: true,
              //   resizable: false,
              // },

              {
                Header: () => (
                  <div>
                    1st Shift
                    <br />
                    Start SOC %
                  </div>
                ),
                id: "morning_start_soc",
                accessor: (d) => d.morning_start_soc,
                width: 160,
                filterAll: true,
                resizable: false,
              },

              {
                Header: () => (
                  <div>
                    1st Shift
                    <br />
                    End SOC %
                  </div>
                ),
                id: "morning_end_soc",
                accessor: (d) => d.morning_end_soc,
                width: 160,
                filterAll: true,
                resizable: false,
              },

              {
                Header: () => (
                  <div>
                    1st Shift SOC
                    <br />
                    Discharged
                  </div>
                ),
                id: "morning_total_soc",
                accessor: (d) => d.morning_total_soc,
                width: 160,
                filterAll: true,
                resizable: false,
              },

              {
                Header: () => (
                  <div>
                    1st Shift
                    <br />
                    Start ODO
                  </div>
                ),
                id: "morning_start_odo",
                accessor: (d) => d.morning_start_odo,
                width: 160,
                filterAll: true,
                resizable: false,
              },

              {
                Header: () => (
                  <div>
                    1st Shift
                    <br />
                    End ODO
                  </div>
                ),
                id: "morning_end_odo",
                accessor: (d) => d.morning_end_odo,
                width: 160,
                filterAll: true,
                resizable: false,
              },

              {
                Header: () => (
                  <div>
                    1st Shift
                    <br />
                    Km Covered
                  </div>
                ),
                id: "morning_distance_travelled",
                accessor: (d) => d.morning_distance_travelled,
                width: 160,
                filterAll: true,
                resizable: false,
              },

              {
                Header: () => (
                  <div>
                    1st Shift
                    <br />
                    Km / SOC
                  </div>
                ),
                id: "morning_km_per_soc                ",
                accessor: (d) => d.morning_km_per_soc,
                width: 160,
                filterAll: true,
                resizable: false,
              },
              {
                Header: () => (
                  <div>
                    1st Shift
                    <br />
                    Driver Name
                  </div>
                ),
                id: "morning_driver_name                ",
                accessor: (d) => d.morning_driver_name,
                width: 160,
                filterAll: true,
                resizable: false,
              },
              {
                Header: () => (
                  <div>
                    1st Shift
                    <br />
                    Start hrlfc
                  </div>
                ),
                id: "morning_start_hrlfc                ",
                accessor: (d) => d.morning_start_hrlfc,
                width: 160,
                filterAll: true,
                resizable: false,
              },
              {
                Header: () => (
                  <div>
                    2nd Shift
                    <br />
                    Start SOC %
                  </div>
                ),
                id: "evening_start_soc                ",
                accessor: (d) => d.evening_start_soc,
                width: 160,
                filterAll: true,
                resizable: false,
              },
              {
                Header: () => (
                  <div>
                    2nd Shift
                    <br />
                    End SOC %
                  </div>
                ),
                id: "evening_end_soc                ",
                accessor: (d) => d.evening_end_soc,
                width: 160,
                filterAll: true,
                resizable: false,
              },
              {
                Header: () => (
                  <div>
                    2nd Shift
                    <br />
                    SOC Discharged
                  </div>
                ),
                id: "evening_total_soc                ",
                accessor: (d) => d.evening_total_soc,
                width: 160,
                filterAll: true,
                resizable: false,
              },
              {
                Header: () => (
                  <div>
                    2nd Shift
                    <br />
                    Start ODO
                  </div>
                ),
                id: "evening_start_odo                ",
                accessor: (d) => d.evening_start_odo,
                width: 160,
                filterAll: true,
                resizable: false,
              },
              {
                Header: () => (
                  <div>
                    2nd Shift
                    <br />
                    End ODO
                  </div>
                ),
                id: "evening_end_odo                ",
                accessor: (d) => d.evening_end_odo,
                width: 160,
                filterAll: true,
                resizable: false,
              },
              {
                Header: () => (
                  <div>
                    2nd Shift
                    <br />
                    Km Covered
                  </div>
                ),
                id: "evening_distance_travelled                ",
                accessor: (d) => d.evening_distance_travelled,
                width: 160,
                filterAll: true,
                resizable: false,
              },
              {
                Header: () => (
                  <div>
                    2nd Shift
                    <br />
                    Km / SOC
                  </div>
                ),
                id: "evening_km_per_soc                ",
                accessor: (d) => d.evening_km_per_soc,
                width: 160,
                filterAll: true,
                resizable: false,
              },
              {
                Header: () => (
                  <div>
                    2nd Shift
                    <br />
                    Driver Name
                  </div>
                ),

                id: "evening_driver_name                ",
                accessor: (d) => d.evening_driver_name,
                width: 160,
                filterAll: true,
                resizable: false,
              },

              {
                Header: () => (
                  <div>
                    2nd Shift
                    <br />
                    Start hrlfc
                  </div>
                ),

                id: "evening_start_hrlfc                ",
                accessor: (d) => d.evening_start_hrlfc,
                width: 160,
                filterAll: true,
                resizable: false,
              },

              {
                Header: () => (
                  <div>
                    Total SOC
                    <br />
                    Consumed
                  </div>
                ),
                id: "total_soc_consumed_per_day                ",
                accessor: (d) => d.total_soc_consumed_per_day,
                width: 160,
                filterAll: true,
                resizable: false,
              },

              {
                Header: () => (
                  <div>
                    Total Km
                    <br />
                    Covered
                  </div>
                ),
                id: "total_km_consumed_per_day                ",
                accessor: (d) => d.total_km_consumed_per_day,
                width: 160,
                filterAll: true,
                resizable: false,
              },

              {
                Header: "Total Km / SOC",
                id: "total_km_per_soc_consumed_per_day",
                accessor: (d) => d.total_km_per_soc_consumed_per_day,
                width: 160,
                filterAll: true,
                resizable: false,
              },

              {
                Header: () => (
                  <div>
                    Total KW
                    <br />
                    Consumed
                  </div>
                ),
                id: "total_kw_consumed_per_day",
                accessor: (d) => d.total_kw_consumed_per_day,
                width: 160,
                filterAll: true,
                resizable: false,
              },

              {
                Header: "Total KW/KM",
                id: "total_kw_per_km",
                accessor: (d) => d.total_kw_per_km,
                width: 160,
                filterAll: true,
                resizable: false,
              },
            ]}
            defaultSorted={[
              {
                id: "serialNumber",
                asc: true,
              },
            ]}
            // data={this.state.sosData} // should default to []
            defaultPageSize={50}
            onPageSizeChange={() => {
              ReactTooltip.rebuild();
            }}
            className="-striped -highlight"
          >
            {(state, makeTable, instance) => {
              let recordsInfoText = "";

              const { filtered, pageRows, pageSize, sortedData, page } = state;

              if (sortedData && sortedData.length > 0) {
                let isFiltered = filtered.length > 0;

                let totalRecords = sortedData.length;

                let recordsCountFrom = page * pageSize + 1;

                let recordsCountTo = recordsCountFrom + pageRows.length - 1;

                if (isFiltered)
                  recordsInfoText = `${recordsCountFrom}-${recordsCountTo} of ${totalRecords} filtered records`;
                else
                  recordsInfoText = `${recordsCountFrom}-${recordsCountTo} of ${totalRecords} records`;
              } else recordsInfoText = "No records";

              return (
                <div className="main-grid">
                  <div className="above-table text-right">
                    <div className="col-sm-12">
                      <span className="records-info">{recordsInfoText}</span>
                    </div>
                  </div>

                  {makeTable()}
                </div>
              );
            }}
          </ReactTable>
        </div>

        {/* <Footer /> */}
      </div>
    );
  }
}

//export default ChargeSummary;
const mapStateToProps = (state) => {
  return {
    ...state,
    //  chargeSummary: state.chargeSummary,
  };
};

export default connect(mapStateToProps, {
  getDailyShiftWiseReportAPI,
})(withRouter(DailyOpShiftWiseReport));
