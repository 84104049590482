const contractalKPIApiActions = {
    //contractualDetails
    GET_CONTRACTUALDETAILS: "GET_CONTRACTUALDETAILS",
    GET_CONTRACTUALDETAILS_SUCCESS: "GET_CONTRACTUALDETAILS_SUCCESS",
    GET_CONTRACTUALDETAILS_FAILURE: "GET_CONTRACTUALDETAILS_FAILURE",
  
    getContractualDetailsAPI: (data) => ({
      type: contractalKPIApiActions.GET_CONTRACTUALDETAILS,
      payload: { data },
    }),
  
    getContractualDetailsAPISuccess: (saveStatusCode, result) => ({
      type: contractalKPIApiActions.GET_CONTRACTUALDETAILS_SUCCESS,
      saveStatusCode,
      result,
    }),
  
    getContractualDetailsAPIFailure: (saveStatusCode, message) => ({
      type: contractalKPIApiActions.GET_CONTRACTUALDETAILS_FAILURE,
      saveStatusCode,
      message,
    }),
  


    //contractual_assured_fleet_availability

    GET_CONTRACTUAL_AASURED_AVAILABITY_LIST: "GET_CONTRACTUAL_AASURED_AVAILABITY_LIST",
    GET_CONTRACTUAL_AASURED_AVAILABITY_LIST_SUCCESS: "GET_CONTRACTUAL_AASURED_AVAILABITY_LIST_SUCCESS",
    GET_CONTRACTUAL_AASURED_AVAILABITY_LIST_FAILURE: "GET_CONTRACTUAL_AASURED_AVAILABITY_LIST_FAILURE",
  
    getContractualAssuredavailabilityAPI: (data) => ({
      type: contractalKPIApiActions.GET_CONTRACTUAL_AASURED_AVAILABITY_LIST,
      payload: { data },
    }),
  
    getContractualAssuredavailabilityAPISuccess: (saveStatusCode, result) => ({
      type: contractalKPIApiActions.GET_CONTRACTUAL_AASURED_AVAILABITY_LIST_SUCCESS,
      saveStatusCode,
      result,
    }),
  
    getContractualAssuredavailabilityAPIFailure: (saveStatusCode, message) => ({
      type: contractalKPIApiActions.GET_CONTRACTUAL_AASURED_AVAILABITY_LIST_FAILURE,
      saveStatusCode,
      message,
    }),
  




//financialDetails
    GET_FINANCIALDETAILS: "GET_FINANCIALDETAILS",
    GET_FINANCIALDETAILS_SUCCESS: "GET_FINANCIALDETAILS_SUCCESS",
    GET_FINANCIALDETAILS_FAILURE: "GET_FINANCIALDETAILS_FAILURE",
  
    getFinancialDetailsAPI: (data) => ({
      type: contractalKPIApiActions.GET_FINANCIALDETAILS,
      payload: { data },
    }),
  
    getFinancialDetailsAPISuccess: (saveStatusCode, result) => ({
      type: contractalKPIApiActions.GET_FINANCIALDETAILS_SUCCESS,
      saveStatusCode,
      result,
    }),
  
    getFinancialDetailsAPIFailure: (saveStatusCode, message) => ({
      type: contractalKPIApiActions.GET_FINANCIALDETAILS_FAILURE,
      saveStatusCode,
      message,
    }),
  
//sustainanceDetails
    GET_SUSTAINANCEDETAILS: "GET_SUSTAINANCEDETAILS",
    GET_SUSTAINANCEDETAILS_SUCCESS: "GET_SUSTAINANCEDETAILS_SUCCESS",
    GET_SUSTAINANCEDETAILS_FAILURE: "GET_SUSTAINANCEDETAILS_FAILURE",
  
    getSustainanceDetailsAPI: (data) => ({
      type: contractalKPIApiActions.GET_SUSTAINANCEDETAILS,
      payload: { data },
    }),
  
    getSustainanceDetailsAPISuccess: (saveStatusCode, result) => ({
      type: contractalKPIApiActions.GET_SUSTAINANCEDETAILS_SUCCESS,
      saveStatusCode,
      result,
    }),
  
    getSustainanceDetailsAPIFailure: (saveStatusCode, message) => ({
      type: contractalKPIApiActions.GET_SUSTAINANCEDETAILS_FAILURE,
      saveStatusCode,
      message,
    }),
  



    //driverscore


    GET_DRIVERSCOREDETAILS: "GET_DRIVERSCOREDETAILS",
    GET_DRIVERSCOREDETAILS_SUCCESS: "GET_DRIVERSCOREDETAILS_SUCCESS",
    GET_DRIVERSCOREDETAILS_FAILURE: "GET_DRIVERSCOREDETAILS_FAILURE",
  
    getDriverScoreDetailsAPI: (data) => ({
      type: contractalKPIApiActions.GET_DRIVERSCOREDETAILS,
      payload: { data },
    }),
  
    getDriverScoreDetailsAPISuccess: (saveStatusCode, result) => ({
      type: contractalKPIApiActions.GET_DRIVERSCOREDETAILS_SUCCESS,
      saveStatusCode,
      result,
    }),
  
    getDriverScoreDetailsAPIFailure: (saveStatusCode, message) => ({
      type: contractalKPIApiActions.GET_DRIVERSCOREDETAILS_FAILURE,
      saveStatusCode,
      message,
    }),


    //safty
    GET_SAFTYCONTRACTUAL: "GET_SAFTYCONTRACTUAL",
    GET_SAFTYCONTRACTUAL_SUCCESS: "GET_SAFTYCONTRACTUAL_SUCCESS",
    GET_SAFTYCONTRACTUAL_FAILURE: "GET_SAFTYCONTRACTUAL_FAILURE",
  
    getSaftyContractualAPI: (data) => ({
      type: contractalKPIApiActions.GET_SAFTYCONTRACTUAL,
      payload: { data },
    }),
  
    getSaftyContractualAPISuccess: (saveStatusCode, result) => ({
      type: contractalKPIApiActions.GET_SAFTYCONTRACTUAL_SUCCESS,
      saveStatusCode,
      result,
    }),
  
    getSaftyContractualAPIFailure: (saveStatusCode, message) => ({
      type: contractalKPIApiActions.GET_SAFTYCONTRACTUAL_FAILURE,
      saveStatusCode,
      message,
    }),

//general-saftey-list


GET_GENERALSAFTYCONTRACTUALLIST: "GET_GENERALSAFTYCONTRACTUALLIST",
GET_GENERALSAFTYCONTRACTUALLIST_SUCCESS: "GET_GENERALSAFTYCONTRACTUALLIST_SUCCESS",
GET_GENERALSAFTYCONTRACTUALLIST_FAILURE: "GET_GENERALSAFTYCONTRACTUALLIST_FAILURE",

getGeneralSaftyContractualAPI: (data) => ({
  type: contractalKPIApiActions.GET_GENERALSAFTYCONTRACTUALLIST,
  payload: { data },
}),

getGeneralSaftyContractualAPISuccess: (saveStatusCode, result) => ({
  type: contractalKPIApiActions.GET_GENERALSAFTYCONTRACTUALLIST_SUCCESS,
  saveStatusCode,
  result,
}),

getGeneralSaftyContractualAPIFailure: (saveStatusCode, message) => ({
  type: contractalKPIApiActions.GET_GENERALSAFTYCONTRACTUALLIST_FAILURE,
  saveStatusCode,
  message,
}),









//severe-saftey-list


GET_SEVERESAFTYCONTRACTUALLIST: "GET_SEVERESAFTYCONTRACTUALLIST",
GET_SEVERESAFTYCONTRACTUALLIST_SUCCESS: "GET_SEVERESAFTYCONTRACTUALLIST_SUCCESS",
GET_SEVERESAFTYCONTRACTUALLIST_FAILURE: "GET_SEVERESAFTYCONTRACTUALLIST_FAILURE",

getSevereSaftyContractualAPI: (data) => ({
  type: contractalKPIApiActions.GET_SEVERESAFTYCONTRACTUALLIST,
  payload: { data },
}),

getSevereSaftyContractualAPISuccess: (saveStatusCode, result) => ({
  type: contractalKPIApiActions.GET_SEVERESAFTYCONTRACTUALLIST_SUCCESS,
  saveStatusCode,
  result,
}),

getSevereSaftyContractualAPIFailure: (saveStatusCode, message) => ({
  type: contractalKPIApiActions.GET_SEVERESAFTYCONTRACTUALLIST_FAILURE,
  saveStatusCode,
  message,
}),

/// scheduleAdhrence

GET_CONTRACTUALSCHEDULEADHERENCE: "GET_CONTRACTUALSCHEDULEADHERENCE",
GET_CONTRACTUALSCHEDULEADHERENCE_SUCCESS: "GET_CONTRACTUALSCHEDULEADHERENCE_SUCCESS",
GET_CONTRACTUALSCHEDULEADHERENCE_FAILURE: "GET_CONTRACTUALSCHEDULEADHERENCE_FAILURE",

getContractualScheduleAdherenceAPI: (data) => ({
  type: contractalKPIApiActions.GET_CONTRACTUALSCHEDULEADHERENCE,
  payload: { data },
}),

getContractualScheduleAdherenceAPISuccess: (saveStatusCode, result) => ({
  type: contractalKPIApiActions.GET_CONTRACTUALSCHEDULEADHERENCE_SUCCESS,
  saveStatusCode,
  result,
}),

getContractualScheduleAdherenceAPIFailure: (saveStatusCode, message) => ({
  type: contractalKPIApiActions.GET_CONTRACTUALSCHEDULEADHERENCE_FAILURE,
  saveStatusCode,
  message,
}),

//reliability popup

GET_CONTRACTUALSRELIABILITY: "GET_CONTRACTUALSRELIABILITY",
GET_CONTRACTUALSRELIABILITY_SUCCESS: "GET_CONTRACTUALSRELIABILITY_SUCCESS",
GET_CONTRACTUALSRELIABILITY_FAILURE: "GET_CONTRACTUALSRELIABILITY_FAILURE",

getContractualReliabilityAPI: (data) => ({
  type: contractalKPIApiActions.GET_CONTRACTUALSRELIABILITY,
  payload: { data },
}),

getContractualReliabilityAPISuccess: (saveStatusCode, result) => ({
  type: contractalKPIApiActions.GET_CONTRACTUALSRELIABILITY_SUCCESS,
  saveStatusCode,
  result,
}),

getContractualReliabilityAPIFailure: (saveStatusCode, message) => ({
  type: contractalKPIApiActions.GET_CONTRACTUALSRELIABILITY_FAILURE,
  saveStatusCode,
  message,
}),

//breakdownsummary popup

GET_CONTRACTUALBREAKDOWNSUMMARYPOPUP: "GET_CONTRACTUALBREAKDOWNSUMMARYPOPUP",
GET_CONTRACTUALBREAKDOWNSUMMARYPOPUP_SUCCESS: "GET_CONTRACTUALBREAKDOWNSUMMARYPOPUP_SUCCESS",
GET_CONTRACTUALBREAKDOWNSUMMARYPOPUP_FAILURE: "GET_CONTRACTUALBREAKDOWNSUMMARYPOPUP_FAILURE",

getContractualBreakdownsummaryViewAPI: (data) => ({
  type: contractalKPIApiActions.GET_CONTRACTUALBREAKDOWNSUMMARYPOPUP,
  payload: { data },
}),

getContractualBreakdownsummaryViewAPISuccess: (saveStatusCode, result) => ({
  type: contractalKPIApiActions.GET_CONTRACTUALBREAKDOWNSUMMARYPOPUP_SUCCESS,
  saveStatusCode,
  result,
}),

getContractualBreakdownsummaryViewAPIFailure: (saveStatusCode, message) => ({
  type: contractalKPIApiActions.GET_CONTRACTUALBREAKDOWNSUMMARYPOPUP_FAILURE,
  saveStatusCode,
  message,
}),





//start punctuality-------weekly

GET_STARTPUNCTUALITYWEEKLYDATA: "GET_STARTPUNCTUALITYWEEKLYDATA",
GET_STARTPUNCTUALITYWEEKLYDATA_SUCCESS: "GET_STARTPUNCTUALITYWEEKLYDATA_SUCCESS",
GET_STARTPUNCTUALITYWEEKLYDATA_FAILURE: "GET_STARTPUNCTUALITYWEEKLYDATA_FAILURE",

getStartPunctualityWeeklyDataAPI: (data) => ({
  type: contractalKPIApiActions.GET_STARTPUNCTUALITYWEEKLYDATA,
  payload: { data },
}),

getStartPunctualityWeeklyDataAPISuccess: (saveStatusCode, result) => ({
  type: contractalKPIApiActions.GET_STARTPUNCTUALITYWEEKLYDATA_SUCCESS,
  saveStatusCode,
  result,
}),

getStartPunctualityWeeklyDataAPIFailure: (saveStatusCode, message) => ({
  type: contractalKPIApiActions.GET_STARTPUNCTUALITYWEEKLYDATA_FAILURE,
  saveStatusCode,
  message,
}),


//arrival punctuality-------monthly
// GET_ARRIVALPUNCTUALITYMONTHLYDATA: "GET_ARRIVALPUNCTUALITYMONTHLYDATA",
// GET_ARRIVALPUNCTUALITYMONTHLYDATA_SUCCESS: "GET_ARRIVALPUNCTUALITYMONTHLYDATA_SUCCESS",
// GET_ARRIVALPUNCTUALITYMONTHLYDATA_FAILURE: "GET_ARRIVALPUNCTUALITYMONTHLYDATA_FAILURE",

// getArrivalPunctualityMonthlyDataAPI: (data) => ({
//   type: contractalKPIApiActions.GET_ARRIVALPUNCTUALITYMONTHLYDATA,
//   payload: { data },
// }),

// getArrivalPunctualityMonthlyDataAPISuccess: (saveStatusCode, result) => ({
//   type: contractalKPIApiActions.GET_ARRIVALPUNCTUALITYMONTHLYDATA_SUCCESS,
//   saveStatusCode,
//   result,
// }),

// getArrivalPunctualityMonthlyDataAPIFailure: (saveStatusCode, message) => ({
//   type: contractalKPIApiActions.GET_ARRIVALPUNCTUALITYMONTHLYDATA_FAILURE,
//   saveStatusCode,
//   message,
// }),

//arrival punctuality-------weekly

GET_ARRIVALPUNCTUALITYWEEKLYDATA: "GET_ARRIVALPUNCTUALITYWEEKLYDATA",
GET_ARRIVALPUNCTUALITYWEEKLYDATA_SUCCESS: "GET_ARRIVALPUNCTUALITYWEEKLYDATA_SUCCESS",
GET_ARRIVALPUNCTUALITYWEEKLYDATA_FAILURE: "GET_ARRIVALPUNCTUALITYWEEKLYDATA_FAILURE",

getArrivalPunctualityWeeklyDataAPI: (data) => ({
  type: contractalKPIApiActions.GET_ARRIVALPUNCTUALITYWEEKLYDATA,
  payload: { data },
}),

getArrivalPunctualityWeeklyDataAPISuccess: (saveStatusCode, result) => ({
  type: contractalKPIApiActions.GET_ARRIVALPUNCTUALITYWEEKLYDATA_SUCCESS,
  saveStatusCode,
  result,
}),

getArrivalPunctualityWeeklyDataAPIFailure: (saveStatusCode, message) => ({
  type: contractalKPIApiActions.GET_ARRIVALPUNCTUALITYWEEKLYDATA_FAILURE,
  saveStatusCode,
  message,
}),





  };
  
  export default contractalKPIApiActions;
  