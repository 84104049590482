import React, { Component } from "react";
import "../fleet_health/index.scss";
import ReactTable from "react-table";
import "react-table/react-table.css";
import ReactLoader from "../../component/react-loader/react-loader.component";
import { connect } from "react-redux";
import Workbook from "react-excel-workbook";
import ReactTooltip from "react-tooltip";
import { withRouter } from "react-router";
import moment from "moment";
import ViewMore from "../../component/view_more";
import "react-datepicker/dist/react-datepicker.css";
import fleetUptimeMangementApiActions from "../../redux/uptime_mangement/actions";
import BackToScreen from "../../component/back_to_screen";
const { getFleetHealthPanelViewAPI, getFleetHealthViewAPI } = fleetUptimeMangementApiActions;
var now = new Date();
now.setDate(now.getDate());
var lastDay = new Date();
lastDay.setHours(lastDay.getHours() - 6);

var startTimestamp = new Date();
startTimestamp.setHours(0);
startTimestamp.setMinutes(0);
startTimestamp.setSeconds(0);

var endTimestamp = new Date();
endTimestamp.setHours(23);
endTimestamp.setMinutes(59);
endTimestamp.setSeconds(58);
class FleetHealth extends Component {
  constructor(props) {
    super(props);
    this.state = {

      start_date: startTimestamp,
      end_date: endTimestamp,
      editingfields: {},
      data: [],
      dataSet: [],
      closedDriver: 0,
      closedService: 0,
      closedStopNow: 0,
      closedVisitSoon: 0,
      driverAlerts: 0,
      openDriver: 0,
      openService: 0,
      openStopNow: 0,
      openVisitSoon: 0,
      serviceAlerts: 0,
      stopNow: 0,
      visitSoon: 0,
      filteredData: [],
      inputSearch: "",
      cardClickedServiceAlerts: false,
      cardClickedStopNow: false,
      cardClickedVisitSoon: false,
      cardClickedBreakdownAlerts: false,
    };
    this.backtodashboardPage = this.backtodashboardPage.bind(this);
    this.navTofleethealthDetails = this.navTofleethealthDetails.bind(this);
    this.handleClickStatusService = this.handleClickStatusService.bind(this);
    this.handleClickStatusStopNow = this.handleClickStatusStopNow.bind(this);
    this.handleClickStatusVisitSoon =
      this.handleClickStatusVisitSoon.bind(this);
    this.handleClickStatusBreakdown =
      this.handleClickStatusBreakdown.bind(this);
  }

  componentDidMount() {
    // sessionStorage.setItem("severity","Stop Now");
    this.getfleetHealthViewDetails();
    this.getfleetHealthPanelViewDetails();
    this.setState({ cardClickedStopNow: false });
    this.setState({ cardClickedVisitSoon: false });
    this.setState({ cardClickedBreakdownAlerts: false });
    this.setState({ cardClickedServiceAlerts: true });
  }

  componentWillUnmount() {
    sessionStorage.removeItem("severity");
  }

  getfleetHealthPanelViewDetails = async () => {
    const { start_date, end_date } = this.state;
    let payLoad = {
      // user_id: "1",
      user_id: sessionStorage.getItem("userId"),
      customerId: sessionStorage.getItem("customerId"),
      // start_timestamp: moment(start_date).format("YYYY-MM-DD 00:00:00"),
      // end_timestamp: moment(end_date).format("YYYY-MM-DD 23:59:59"),
    };
    await this.props.getFleetHealthPanelViewAPI(payLoad);
  };

  getfleetHealthViewDetails = async (Status) => {
    let statusVal = "";
    // console.log("status",Status)
    if (Status == undefined || null) {
      statusVal = sessionStorage.getItem("severity")
        ? sessionStorage.getItem("severity")
        : "Service Alerts";
    } else {
      statusVal = Status;
    }

    const { start_date, end_date, clickStatus } = this.state;
    if (start_date < end_date) {
      let payLoad = {
        // user_id: "1",
        user_id: sessionStorage.getItem("userId"),
        customerId: sessionStorage.getItem("customerId"),
        severity:
          statusVal != undefined
            ? statusVal
            : sessionStorage.getItem("severity"),
        start_timestamp: moment(start_date).format("YYYY-MM-DD HH:mm:ss"),
        end_timestamp: moment(end_date).format("YYYY-MM-DD HH:mm:ss"),
      };
      await this.props.getFleetHealthViewAPI(payLoad);
    } else if (start_date > end_date) {
      alert("End Date should be greater than Start Date");
    } else {
      alert("End Date should be greater than Start Date");
    }
  };

  handleDateChange(evt) {
    const value = evt.target.value;

    const name = evt.target.name;
    this.setState((prevState) => ({
      editingfields: {
        ...prevState.editingfields,
        [name]: moment(value).format("YYYY-MM-DD HH:mm:00"),
      },
    }));
    this.setState({ [name]: value });
  }

  onSubmit = (e) => {
    this.getfleetHealthViewDetails();
  };

  componentWillReceiveProps(nextProps) {
    // console.log(
    //   "props check",
    //   nextProps.FleetUptimeMangementApi.FleetHealthViewApi.result.data
    //     .responseData
    // );
    if (
      nextProps?.FleetUptimeMangementApi?.FleetHealthViewApi?.result?.data
        .responseData?.length > 0
    ) {
      this.setState({
        data: nextProps.FleetUptimeMangementApi.FleetHealthViewApi.result.data
          .responseData,
      });
      this.setState({
        filteredData:
          nextProps.FleetUptimeMangementApi.FleetHealthViewApi.result.data
            .responseData,
      });
    } else {
      this.setState({
        data: [],
      });
      this.setState({
        filteredData: [],
      });
    }

    if (
      nextProps?.FleetUptimeMangementApi?.FleetHealthPanelViewApi?.result?.data
        .responseData
    ) {
      this.setState({
        dataSet:
          nextProps.FleetUptimeMangementApi.FleetHealthPanelViewApi.result.data
            .responseData,
      });

      this.setState({
        closedDriver:
          nextProps.FleetUptimeMangementApi.FleetHealthPanelViewApi.result.data
            .responseData.closedDriver,
      });

      this.setState({
        openDriver:
          nextProps.FleetUptimeMangementApi.FleetHealthPanelViewApi.result.data
            .responseData.openDriver,
      });

      this.setState({
        closedService:
          nextProps.FleetUptimeMangementApi.FleetHealthPanelViewApi.result.data
            .responseData.closedService,
      });
      this.setState({
        openService:
          nextProps.FleetUptimeMangementApi.FleetHealthPanelViewApi.result.data
            .responseData.openService,
      });

      this.setState({
        stopNow:
          nextProps.FleetUptimeMangementApi.FleetHealthPanelViewApi.result.data
            .responseData.stopNow,
      });

      this.setState({
        serviceAlerts:
          nextProps.FleetUptimeMangementApi.FleetHealthPanelViewApi.result.data
            .responseData.serviceAlerts,
      });

      this.setState({
        visitSoon:
          nextProps.FleetUptimeMangementApi.FleetHealthPanelViewApi.result.data
            .responseData.visitSoon,
      });

      this.setState({
        driverAlerts:
          nextProps.FleetUptimeMangementApi.FleetHealthPanelViewApi.result.data
            .responseData.driverAlerts,
      });

      this.setState({
        openStopNow:
          nextProps.FleetUptimeMangementApi.FleetHealthPanelViewApi.result.data
            .responseData.openStopNow,
      });

      this.setState({
        closedStopNow:
          nextProps.FleetUptimeMangementApi.FleetHealthPanelViewApi.result.data
            .responseData.closedStopNow,
      });

      this.setState({
        closedVisitSoon:
          nextProps.FleetUptimeMangementApi.FleetHealthPanelViewApi.result.data
            .responseData.closedVisitSoon,
      });

      this.setState({
        openVisitSoon:
          nextProps.FleetUptimeMangementApi.FleetHealthPanelViewApi.result.data
            .responseData.openVisitSoon,
      });
    }
  }

  handlefleethealthSearch = (event) => {
    const search = event.target.value.toLowerCase();
    this.setState({
      inputSearch: search,
      filteredData: this.state.data.filter(
        (item) =>
          (item.registrationNo &&
            item.registrationNo.toLowerCase().includes(search)) ||
          (item.caseType && item.caseType.toLowerCase().includes(search)) ||
          (item.caseNo && item.caseNo.toLowerCase().includes(search))
      ),
    });
  };

  navTofleethealthDetails = () => {
    let tabItem = {
      tabIndex: 35,
      tabName: "Fleet Health Detail",
    };
    this.props.selectedTab(null, tabItem);
  };

  backtodashboardPage() {
    let tabItem = {
      tabIndex: 16,
      tabName: "Dashboard",
    };
    this.props.selectedTab(null, tabItem);
  }

  handleClickStatusService() {
    //  console.log("called");
    sessionStorage.setItem("severity", "Service Alerts");
    this.getfleetHealthViewDetails("Service Alerts");
    this.setState({ cardClickedStopNow: false });
    this.setState({ cardClickedVisitSoon: false });
    this.setState({ cardClickedBreakdownAlerts: false });
    this.setState({ cardClickedServiceAlerts: true });
  }

  handleClickStatusStopNow() {
    // console.log("called");
    sessionStorage.setItem("severity", "Stop Now");
    this.getfleetHealthViewDetails("Stop Now");
    this.setState({ cardClickedServiceAlerts: false });
    this.setState({ cardClickedVisitSoon: false });
    this.setState({ cardClickedBreakdownAlerts: false });
    this.setState({ cardClickedStopNow: true });
  }

  handleClickStatusVisitSoon() {
    // console.log("called");
    sessionStorage.setItem("severity", "Visit Soon");
    this.getfleetHealthViewDetails("Visit Soon");
    this.setState({ cardClickedServiceAlerts: false });
    this.setState({ cardClickedBreakdownAlerts: false });
    this.setState({ cardClickedStopNow: false });
    this.setState({ cardClickedVisitSoon: true });
  }

  handleClickStatusBreakdown() {
    // console.log("called");
    sessionStorage.setItem("severity", "Breakdown Alerts");
    this.getfleetHealthViewDetails("Breakdown Alerts");
    this.setState({ cardClickedServiceAlerts: false });
    this.setState({ cardClickedStopNow: false });
    this.setState({ cardClickedVisitSoon: false });
    this.setState({ cardClickedBreakdownAlerts: true });
  }
  // setdata = (data) => {

  //   sessionStorage.setItem("fleetHealthcaseNoClicked", data);
  //   //this.props.getfleetHealthDetails();
  //  this.function();
  // };
  render() {
    let { inputSearch } = this.state;
    return (
      <div>
        {this.props.FleetUptimeMangementApi?.FleetHealthPanelViewApi
          ?.loading ? (
          <div className="loader-overlay">
            <div className="loader-container">
              <ReactLoader />
            </div>
          </div>
        ) : null}
        <div className="fleet-health-backward">
          <BackToScreen routingCall={this.backtodashboardPage} />
          <h3 className="fleet-health-heading">Fleet Health</h3>
        </div>

        <div className="container-fluid">
          <div className="row mt-1 ms-2">
            <div className="col-lg-2 w-2 ms-4 gx-0  bg-body ">
              <div
                className={`fleet-health-head ${
                  this.state.cardClickedServiceAlerts && "card-clicked"
                }`}
                onClick={this.handleClickStatusService}
              >
                <img
                  src={require("../../assets/images/fleet-health/service-1.png")}
                  className="fleet-health-image"
                />
                <div className="fleet-health-head-text">Service Alerts</div>
                <p className="fleet-health-head-number">
                  {this.state.serviceAlerts}
                </p>
              </div>

              {/* 
                <div className="row bg-red ms-0 fleet-hover-1">
                  <div className="col-sm border-rt  ">
                    <p className="fleet-health-left-no">
                      {this.state.openService}
                    </p>
                    <p className="fleet-health-left-text">
                     
                      SERVICE
                    </p>
                  </div>
                  <div className="col-sm">
                    <p className="fleet-health-right-no">
                      {this.state.closedService}
                    </p>
                    <p className="fleet-health-right-text">
                      SERVICE
                    </p>
                </div>
              </div> */}
            </div>
            <div className="col-lg-2 w-2  gx-0  bg-body ">
              <div
                className={`fleet-health-head ${
                  this.state.cardClickedStopNow && "card-clicked"
                }`}
                onClick={this.handleClickStatusStopNow}
              >
                <img
                  src={require("../../assets/images/fleet-health/stop-1.png")}
                  className="fleet-health-image"
                />
                <p className="fleet-health-head-text">Stop Now</p>
                <p className="fleet-health-head-number">{this.state.stopNow}</p>
              </div>
              {/*
                <div className="row bg-red fleet-hover-2 ms-0">
                  <div className="col-sm border-rt">
                    <p className="fleet-health-left-no">
                      {this.state.openStopNow}
                    </p>
                    <p className="fleet-health-left-text">
                     
                      STOP NOW
                    </p>
                  </div>
                  <div className="col-sm">
                    <p className="fleet-health-right-no">
                      {this.state.closedStopNow}
                    </p>
                    <p className="fleet-health-right-text">
                      STOP NOW
                    </p>
                  </div>
              </div> */}
            </div>
            <div className="col-lg-2 w-2  gx-0  bg-body">
              <div
                className={`fleet-health-head ${
                  this.state.cardClickedVisitSoon && "card-clicked"
                }`}
                onClick={this.handleClickStatusVisitSoon}
              >
                <img
                  src={require("../../assets/images/fleet-health/visit-1.png")}
                  className="fleet-health-image"
                />
                <p className="fleet-health-head-text">Visit Soon</p>
                <p className="fleet-health-head-number">
                  {this.state.visitSoon}
                </p>
              </div>

              {/* <div className="row bg-red fleet-hover-3 ms-0 ">
                <div className="col-sm border-rt ">
                  <p className="fleet-health-left-no">
                    {this.state.openVisitSoon}
                  </p>
                  <p className="fleet-health-left-text">
                  
                    VISIT SOON
                  </p>
                </div>
                <div className="col-sm">
                  <p className="fleet-health-right-no">
                    {this.state.closedVisitSoon}
                  </p>
                  <p className="fleet-health-right-text">
                   VISIT SOON
                  </p>
                </div>
              </div> */}
            </div>

            <div className="col-lg-2 w-2  gx-0  ">
              <div
                className={`fleet-health-head ${
                  this.state.cardClickedBreakdownAlerts && "card-clicked"
                }`}
                onClick={this.handleClickStatusBreakdown}
              >
                <img
                  src={require("../../assets/images/fleet-health/alert-1.png")}
                  className="fleet-health-image-last"
                />
                <p className="fleet-health-head-text">BREAKDOWN ALERTS</p>
                <p className="fleet-health-head-number">
                  {this.state.driverAlerts}
                </p>
              </div>

              {/* 
                <div className="row bg-red fleet-hover-4 ms-0 me-0">
                  <div className="col-sm border-rt">
                    <p className="fleet-health-left-no">
                      {this.state.openDriver}
                    </p>
                    <p className="fleet-health-left-text">
                   
                      BREAKDOWN
                    </p>
                  </div>
                  <div className="col-sm">
                    <p className="fleet-health-right-no">
                      {this.state.closedDriver}
                    </p>
                    <p className="fleet-health-right-text">
                     BREAKDOWN
                    </p>
                  </div>
              </div> */}
            </div>
          </div>
        </div>

        <div className="fleet-health-div">
          {/* <div className="fleet-health_report_div_labels">
            <label className="fleet_health_report_div_labels_style">
              Start Date:
            </label>

            <DatePicker
              autocomplete="off"
              name="start_date"
              selected={this.state.start_date}
              maxDate={moment().toDate()}
              minDate={addDays(new Date(), -90)}
              value={
                this.state.editingfields.start_date == "undefined"
                  ? this.state.start_date.setHours(0, 0, 0)
                  : this.state.editingfields.start_date
              }
              onChange={(value) =>
                this.handleDateChange({
                  target: { name: "start_date", value },
                })
              }
              //defaultValue={moment(now).toDate()}
              timeInputLabel="Time:"
              dateFormat="yyyy-MM-dd HH:mm:00"
              showTimeInput
            />

            <label className="fleet_health_report_div_labels_style-1">
              End Date:
            </label>

            <DatePicker
              autocomplete="off"
              // valueDefault={null}
              //maxDate={moment().toDate()}
              name="end_date"
              selected={this.state.end_date}
              maxDate={moment().toDate()}
              minDate={addDays(new Date(), -90)}
              value={
                this.state.editingfields.end_date == "undefined"
                  ? this.state.end_date.setHours(23, 59, 59)
                  : this.state.editingfields.end_date
              }
              //value={this.state.editingfields.end_date}
              onChange={(value) =>
                this.handleDateChange({
                  target: { name: "end_date", value },
                })
              }
              //defaultValue="01-01-2001 01:01:01"
              timeInputLabel="Time:"
              dateFormat="yyyy-MM-dd HH:mm:00"
              showTimeInput
            />
          </div>
          <div>
            <button
              className="fleet_health_report_div_button-submit"
              onClick={this.onSubmit}
            >
              <i className="fa fa-check" style={{ paddingRight: "5px" }}></i>
              Submit
            </button>
          </div>

          <div>
            <button
              className="fleet_health_div_button-submit-1"
              onClick={this.onReset}
            >
              <i className="fa fa-refresh" style={{ paddingRight: "5px" }}></i>{" "}
              Reset
            </button>
          </div> */}

          <form
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            <input
              className="fleet-health-details-search"
              type="text"
              id="filter"
              placeholder="Search Table (Registration No / Case No /Case Type)"
              value={inputSearch || ""}
              onChange={this.handlefleethealthSearch}
            />
          </form>

          <div>
            <Workbook
              filename="Fleet-health-report.xlsx"
              element={
                <div
                  className="Fleet-health-report_download"
                  data-tip
                  data-for="registerTip"
                >
                  Export
                  <img
                    src={require("../../assets/images/export/export.png")}
                    alt=""
                    className="Fleet-health-report_download-img"
                  />
                  <ReactTooltip id="registerTip" place="top" effect="solid">
                    Download
                  </ReactTooltip>
                </div>
              }
            >
              <Workbook.Sheet
                data={this.state.filteredData}
                name="Fleet-health-report"
              >
                <Workbook.Column label="Reg NO." value="registrationNo" />
                <Workbook.Column label="Case No." value="caseNo" />
                <Workbook.Column label="Case Type" value="caseType" />
                <Workbook.Column label="Timestamp" value="timestamp" />
                <Workbook.Column
                  label="Resolution Status"
                  value="resolutionStatus"
                />
              </Workbook.Sheet>
            </Workbook>
          </div>
        </div>
        <div>
          {this.props.FleetUptimeMangementApi?.FleetHealthViewApi?.loading ? (
            <div className="loader-overlay">
              <div className="loader-container">
                <ReactLoader />
              </div>
            </div>
          ) : null}
          <ReactTooltip place="left" type="success" effect="solid" />
          <ReactTable
            NoDataComponent={() => null}
            pageSizeOptions={[20, 30, 50, 80, 100, 130, 200, 500]}
            style={{
              height: "400px",
              marginTop: "0%",
              marginLeft: "40px",
              marginRight: "40px",
            }}
            data={this.state.filteredData}
            showPagination={true}
            columns={[
              {
                Header: "S.No",
                id: "serialNumber",
                resizable: false,
                filterable: false,
                width: 90,
                Cell: (row) => {
                  return <div>{row.index + 1}</div>;
                },
              },

              {
                Header: "Registration No.",
                id: "registrationNo",
                accessor: (d) => d.registrationNo,
                width: 200,
                filterAll: true,
                resizable: false,
              },

              {
                Header: "Case No",
                id: "caseNo",
                accessor: (d) => d.caseNo,
                width: 200,
                Cell: (rows) => {
                  /* Add data-tip */
                  return <span title={rows.value}>{rows.value}</span>;
                },
                filterAll: true,
                resizable: false,
              },
              {
                Header: "Case Type",
                id: "caseType",
                accessor: (d) => d.caseType,
                width: 200,
                Cell: (rows) => {
                  /* Add data-tip */
                  return <span title={rows.value}>{rows.value}</span>;
                },
                filterAll: true,
                resizable: false,
              },
              {
                Header: "Timestamp",
                id: "timestamp",
                accessor: (d) => d.timestamp,
                width: 300,

                resizable: false,
              },
              {
                Header: "Resolution Status",
                id: "resolutionStatus",
                accessor: (d) => d.resolutionStatus,
                width: 200,

                filterAll: true,
                resizable: false,
              },

              {
                Header: "Action",

                // Cell: (props) => (
                //   <a
                //     className="driver-details-action"
                //     href="#"
                //     //style="text-decoration:none;"
                //     target=""
                //     function={this.navTofleethealthDetails}
                //     onClick={() => this.setdata(props)}
                //   >
                //     View Details{" "}
                //     <img
                //       className="driverdetails-action-img"
                //       src={require("../../assets/images/driver-monitoring/view-more.png")}
                //       alt=""
                //     />
                //   </a>
                // ),

                Cell: (props) => (
                  <ViewMore
                    className="driver-details-actions"
                    data={props}
                    function={this.navTofleethealthDetails}
                    componentName="driverDetails"
                  ></ViewMore>
                ),

                width: 250,

                filterAll: true,
                resizable: false,
              },
            ]}
            defaultSorted={[
              {
                id: "serialNumber",
                asc: true,
              },
            ]}
            defaultPageSize={50}
            className="-striped -highlight"
          >
            {(state, makeTable, instance) => {
              let recordsInfoText = "";

              const { filtered, pageRows, pageSize, sortedData, page } = state;

              if (sortedData && sortedData.length > 0) {
                let isFiltered = filtered.length > 0;

                let totalRecords = sortedData.length;

                let recordsCountFrom = page * pageSize + 1;

                let recordsCountTo = recordsCountFrom + pageRows.length - 1;

                if (isFiltered)
                  recordsInfoText = `${recordsCountFrom}-${recordsCountTo} of ${totalRecords} filtered records`;
                else
                  recordsInfoText = `${recordsCountFrom}-${recordsCountTo} of ${totalRecords} records`;
              } else recordsInfoText = "No records";

              return (
                <div className="main-grid">
                  <div className="above-table text-right">
                    <div className="col-sm-12">
                      <span className="records-info">{recordsInfoText}</span>
                    </div>
                  </div>

                  {makeTable()}
                </div>
              );
            }}
          </ReactTable>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps, {
  getFleetHealthPanelViewAPI,
  getFleetHealthViewAPI,
})(withRouter(FleetHealth));
