import { all, takeEvery, put, call } from "redux-saga/effects";
import actions from "./actions";
import * as dataAccess from "../../utils/ajax";
import { DAILY_SHIFT_WISE_REPORT } from "../../constants/api-constants";

export const postLambda = async (url, payload) =>
  await dataAccess.postLambda(url, payload);

export function* getDailyShiftWiseReportApiSagas(data) {
  const { payload } = data;
  let url = DAILY_SHIFT_WISE_REPORT;
  try {
    let response = yield call(postLambda, url, payload);

    if (response) {
      yield put(actions.getDailyShiftWiseReportAPISuccess(0, response));
    }
  } catch (error) {
    yield put(actions.getDailyShiftWiseReportAPIFailure(1));
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.GET_DAILYSHIFTWISEREPORT, getDailyShiftWiseReportApiSagas),
  ]);
}
