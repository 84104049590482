import actions from "./actions";
import { combineReducers } from "redux";

const initStateDailyShiftWiseReport = {
  loading: false,
  result: null,
  error: false,
};

function dailyShiftWiseReportApi(state = initStateDailyShiftWiseReport, action) {
  switch (action.type) {
    case actions.GET_DAILYSHIFTWISEREPORT:
      return {
        ...state,
        loading: true,
      };
    case actions.GET_DAILYSHIFTWISEREPORT_SUCCESS:
      return {
        ...state,
        loading: false,
        result: action.result,
      };
    case actions.GET_DAILYSHIFTWISEREPORT_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        result: {
          errorResponse: action.saveStatusCode,
          message: action.message,
        },
      };
    default:
      return state;
  }
}

export default combineReducers({
  dailyShiftWiseReportApi,
});
