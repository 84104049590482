import React, { Component } from "react";
import "./index.scss";

class Notification extends Component {
  constructor(props) {
    super(props);
    this.messageTimeOut = null;
    this.state = {
      selTab: 0,
      message: "",
      messageType: 0,
    };
    
  }

  render() {
    let notificationCount = sessionStorage.getItem("notification_count");
   // const notificationCount = 100;
    let width = "70px"; // default width
    if (notificationCount > 99) {
      notificationCount = "99+"; // increase width based on notification count
    }
    else{
      notificationCount = sessionStorage.getItem("notification_count"); 
    }
    return (
      <div
        className="notification-1"
        onClick={this.props.clickdatas}
      >
        <img
          src={require("../../assets/images/Group 5204/Group 5204.png")}
          className=""
        />
        {/* <p className="notification_count_alert">
          {sessionStorage.getItem("notification_count")}
        </p> */}

        
          <p className="notification_count_alert">{notificationCount}</p>
        
      </div>
    );
  }
}

export default Notification;
