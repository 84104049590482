const vehicleSummaryReportApiActions = {
    GET_VEHICLESUMMARYREPORT: "GET_VEHICLESUMMARYREPORT",
    GET_VEHICLESUMMARYREPORT_SUCCESS: "GET_VEHICLESUMMARYREPORT_SUCCESS",
    GET_VEHICLESUMMARYREPORT_FAILURE: "GET_VEHICLESUMMARYREPORT_FAILURE",
  
    getvehicleSummaryReportAPI: (data) => ({
      type:vehicleSummaryReportApiActions.GET_VEHICLESUMMARYREPORT,
      payload: { data },
    }),
  
    getvehicleSummaryReportAPISuccess: (saveStatusCode, result) => ({
      type:vehicleSummaryReportApiActions.GET_VEHICLESUMMARYREPORT_SUCCESS,
      saveStatusCode,
      result,
    }),
  
    getvehicleSummaryReportAPIFailure: (saveStatusCode, message) => ({
      type: vehicleSummaryReportApiActions.GET_VEHICLESUMMARYREPORT_FAILURE,
      saveStatusCode,
      message,
    }),


    GET_VEHICLESUMMARYGRAPH: "GET_VEHICLESUMMARYGRAPH",
    GET_VEHICLESUMMARYGRAPH_SUCCESS: "GET_VEHICLESUMMARYGRAPH_SUCCESS",
    GET_VEHICLESUMMARYGRAPH_FAILURE: "GET_VEHICLESUMMARYGRAPH_FAILURE",
  
    getvehicleSummaryGraphAPI: (data) => ({
      type:vehicleSummaryReportApiActions.GET_VEHICLESUMMARYGRAPH,
      payload: { data },
    }),
  
    getvehicleSummaryGraphAPISuccess: (saveStatusCode, result) => ({
      type:vehicleSummaryReportApiActions.GET_VEHICLESUMMARYGRAPH_SUCCESS,
      saveStatusCode,
      result,
    }),
  
    getvehicleSummaryGraphAPIFailure: (saveStatusCode, message) => ({
      type: vehicleSummaryReportApiActions.GET_VEHICLESUMMARYGRAPH_FAILURE,
      saveStatusCode,
      message,
    }),
  };
  
  export default vehicleSummaryReportApiActions;
  