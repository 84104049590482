const efficiencyReportApiActions = {
    GET_EFFICIENCY_REPORT: "GET_EFFICIENCY_REPORT",
    GET_EFFICIENCY_REPORT_SUCCESS: "GET_EFFICIENCY_REPORT_SUCCESS",
    GET_EFFICIENCY_REPORT_FAILURE: "GET_EFFICIENCY_REPORT_FAILURE",
  
    getEfficiencyReportAPI: (data) => ({
      type: efficiencyReportApiActions.GET_EFFICIENCY_REPORT,
      payload: { data },
    }),
  
    getEfficiencyReportAPISuccess: (saveStatusCode, result) => ({
      type: efficiencyReportApiActions.GET_EFFICIENCY_REPORT_SUCCESS,
      saveStatusCode,
      result,
    }),
  
    getEfficiencyReportAPIFailure: (saveStatusCode, message) => ({
      type: efficiencyReportApiActions.GET_EFFICIENCY_REPORT_FAILURE,
      saveStatusCode,
      message,
    }),
  };
  
  export default efficiencyReportApiActions;
  