
import React, { Component } from "react";
import "./index.scss";
import Lottie from "lottie-react";
import DataAnimation from "../../assets/lotties/green_tick.json";
import DataAnimations from "../../assets/lotties/red_warning.json";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import ReactLoader from "../../component/react-loader/react-loader.component";
import { Modal } from "react-bootstrap";
import moment from "moment";

// //Actions
import contractalKPIApiActions from "../../redux/contractual_kpi/actions";
import SaftyKpiGeneralPopup from "../safty_kpi_general_popup";
import SaftyKpiSeverePopup from "../safty_kpi_severe_popup"
const { getSaftyContractualAPI,getGeneralSaftyContractualAPI,
  getSevereSaftyContractualAPI } = contractalKPIApiActions;


var startTimestamp = new Date();
startTimestamp.setHours(0);
startTimestamp.setMinutes(0);
startTimestamp.setSeconds(0);

var endTimestamp = new Date();
endTimestamp.setHours(23);
endTimestamp.setMinutes(59);
endTimestamp.setSeconds(58);

class SafetyKPI extends Component {
  constructor(props) {
    super(props);
    this.state = {
      severe_safety: "",
      general_safety: "",
      showModal: false,
      showPopupGeneral: false,
      showPopupSafety: false,
      cardClicked: "",
      addModalshow: false,
      data:[],
      dataone:[],
      popupData: [],
      severePopupData:[],
      generalPopupData:[],
      start_date: startTimestamp,
      end_date: endTimestamp,
      currentMonth: ''
    };
    this.onSaftyKPISubmission = this.onSaftyKPISubmission.bind(this);
    this.onSevereSaftyKPISubmission=this.onSevereSaftyKPISubmission.bind(this)
  }

  componentDidMount() {
    this.getSaftyContractualdetails();
    this.getgeneralSaftydetails();
    this.getSevereSaftydetails()

    const currentDate = new Date();
    const currentMonth = currentDate.toLocaleString('default', { month: 'long' });
    this.setState({ currentMonth });
  }

  onSaftyKPISubmission() {
    this.setState({ addModalshow: true });
    this.setState({ showPopupGeneral: true });
    this.setState({ showPopupSafety: false });
  }

  onSevereSaftyKPISubmission() {
    this.setState({ addModalshow: true });
    this.setState({ showPopupSafety: true });
    this.setState({ showPopupGeneral: false });
  }
  addModalClose = () => this.setState({ addModalshow: false });


  getSaftyContractualdetails() {
    //const { start_date, end_date } = this.state;
    this.setState({ loading: true });
    const payload = {
      //id: "1",
      user_id: sessionStorage.getItem("userId"),
      customerId: sessionStorage.getItem("customerId"),
    };
    this.props.getSaftyContractualAPI(payload);
  }

  getgeneralSaftydetails() {
    const { start_date, end_date } = this.state;
    this.setState({ loading: true });
    const payload = {
    user_id: sessionStorage.getItem("userId"),
    customerId: sessionStorage.getItem("customerId"),
    start_timestamp: moment(start_date).format("YYYY-MM-DD HH:mm:ss"),
    end_timestamp: moment(end_date).format("YYYY-MM-DD HH:mm:ss"),
    };
    this.props.getGeneralSaftyContractualAPI(payload);
  }

  getSevereSaftydetails() {
    const { start_date, end_date } = this.state;
    this.setState({ loading: true });
    const payload = {
    user_id: sessionStorage.getItem("userId"),
    customerId: sessionStorage.getItem("customerId"),
    start_timestamp: moment(start_date).format("YYYY-MM-DD HH:mm:ss"),
    end_timestamp: moment(end_date).format("YYYY-MM-DD HH:mm:ss"),
    };
    this.props.getSevereSaftyContractualAPI(payload);
  }


  componentWillReceiveProps(nextProps) {
    if (this.props !== nextProps) {
      //console.log("sasfty", nextProps);
      if (
        nextProps?.ContractalKpiApi?.saftyContractualApi?.result?.data
          ?.responseData
      ) {
        this.setState({
          general_safety:
            nextProps?.ContractalKpiApi?.saftyContractualApi?.result?.data
              ?.responseData.general_safety,
        });
        this.setState({
          severe_safety:
            nextProps?.ContractalKpiApi?.saftyContractualApi?.result?.data
              ?.responseData.severe_safety,
        });
      }

//general

      if (
        nextProps?.ContractalKpiApi?.generalsaftyContractualApi?.result?.data
          ?.responseData
      ) {
        this.setState({
          generalPopupData:
          nextProps.ContractalKpiApi.generalsaftyContractualApi.result.data
            .responseData,
        })
      }


      //severe


      if (
        nextProps?.ContractalKpiApi?.severesaftyContractualApi?.result?.data
          ?.responseData
      ) {
        this.setState({
          severePopupData:
          nextProps.ContractalKpiApi.severesaftyContractualApi.result.data
            .responseData,
        })
      }

    }
  }
  render() {
    //  {this.state.currentMonth} 
    return (
      <div className="container-fluid">
        <h2 className="safty_kpi_header">
        
        Current Month KPI Data</h2> 
        {this.props.ContractalKpiApi?.saftyContractualApi?.loading ? (
          <div className="loader-overlay">
            <div className="loader-container">
              <ReactLoader />
            </div>
          </div>
        ) : null}
        <div className="row">
          <div className="col-lg-4 widthy-20 ms-3 gx-0 shadow bg-body rounded">
            <div className="safety_kpi_head">
              <div className="safety_kpi_head-text-1">
                GENERAL SAFETY SCORE
                <button
                  type="button"
                  className="safety_kpi-btns"
                  onClick={this.onSaftyKPISubmission}
                >
                  VIEW MORE
                  <img
                    className="safety_kpi-button-png"
                    src={require("../../assets/images/arrow-red/red.png")}
                    alt="next"
                  />
                </button>
                {this.state.showPopupGeneral && (
                  <SaftyKpiGeneralPopup
                     buttonClicked={this.state.cardClicked}
                     dataone={this.state.generalPopupData}
                    show={this.state.addModalshow}
                    closemodal={this.addModalClose}
                  />
                )}
              </div>

              <div class="content_img">
                <img
                  className="safety_kpi_head_img-1"
                  src={require("../../assets/images/headernew/head.png")}
                  alt="next"
                />
              </div>
            </div>
            {this.state.general_safety >= 1 ? (
              <div>
                <Lottie
                 // className="lotty-player-safty"
                  animationData={DataAnimation}
                  height="20px"
                  width="15px"
                />

                <p className="safety_kpi_text-2">
                  * you have achieved your internal target
                </p>
                <p className="safety_kpi_text-3">{this.state.general_safety}</p>
                <hr />
                <p className="safety_kpi_footer">
                  * Your Internal Target: ≥1 Per 100000
                </p>
              </div>
            ) : (
              <div>
                <Lottie
                  className="lotty-player-compliance-11"
                  animationData={DataAnimations}
                  height="10px"
                  width="10px"
                />
                <p className="safety_kpi_text-1">
                  *You have not achieved your internal target!
                </p>
                <p className="safety_kpi_text-3">{this.state.general_safety}</p>
                <hr />
                <p className="safety_kpi_footer">
                  * Your Internal Target: ≥1 Per 100000
                </p>
              </div>
            )}
          </div>
          <div className="col-lg-4 widthy-20 ms-4 gx-0 shadow bg-body rounded">
            <div className="safety_kpi_head">
              <div className="safety_kpi_head-text-1">
                SEVERE SAFETY SCORE
                <button
                  type="button"
                  className="safety_kpi-btns"
                  onClick={this.onSevereSaftyKPISubmission}
                >
                  VIEW MORE
                  <img
                    className="safety_kpi-button-png"
                    src={require("../../assets/images/arrow-red/red.png")}
                    alt="next"
                  />
                </button>
                {this.state.showPopupSafety && (
                  <SaftyKpiSeverePopup
                     buttonClicked={this.state.cardClicked}
                    data={this.state.severePopupData}
                    show={this.state.addModalshow}
                    closemodal={this.addModalClose}
                  />
                )}
              </div>
              <div class="content_img">
                <img
                  className="safety_kpi_head_img-1"
                  src={require("../../assets/images/headernew/head.png")}
                  alt="next"
                />
              </div>
            </div>

            {this.state.severe_safety >= 1 ? (
              <div>
                <Lottie
                  className="lotty-player-safty"
                  animationData={DataAnimation}
                  height="20px"
                  width="15px"
                />

                <p className="safety_kpi_text-2">
                  * you have achieved your internal target
                </p>
                <p className="safety_kpi_text-3">{this.state.severe_safety}</p>
                <hr />
                <p className="safety_kpi_footer">
                  * Your Internal Target: ≥1 Per 100000
                </p>
              </div>
            ) : (
              <div>
                <Lottie
                  className="lotty-player-compliance"
                  animationData={DataAnimations}
                  height="20px"
                  width="15px"
                />
                <p className="safety_kpi_text-1">
                  *You have not achieved your internal target!
                </p>
                <p className="safety_kpi_text-3">{this.state.severe_safety}</p>
                <hr />
                <p className="safety_kpi_footer">
                  * Your Internal Target: ≥1 Per 100000
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

//export default SafetyKPI;

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps, {
  getSaftyContractualAPI,
  getGeneralSaftyContractualAPI,
getSevereSaftyContractualAPI
})(withRouter(SafetyKPI));