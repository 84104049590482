import React, { Component } from "react";
import ReactTable from "react-table";
import "react-table/react-table.css";
import "./index.scss";
import ReactTooltip from "react-tooltip";
import { connect } from "react-redux";
import { withRouter } from "react-router";
//import { matchSorter } from "match-sorter";
import ReactLoader from "../react-loader/react-loader.component";
import moment from "moment";
import DatePicker from "react-datepicker";
import { addDays } from "date-fns";
import "react-datepicker/dist/react-datepicker.css";
import BackToScreen from "../../component/back_to_screen";
import Workbook from "react-excel-workbook";
// //Actions
import tripReportActions from "../../redux/trip_report/actions";

const { getTripReportAPI } = tripReportActions;
var now = new Date();
now.setDate(now.getDate());
var lastDay = new Date();
lastDay.setHours(lastDay.getHours() - 6);

var startTimestamp = new Date();
startTimestamp.setHours(0);
startTimestamp.setMinutes(0);
startTimestamp.setSeconds(0);

var endTimestamp = new Date();
endTimestamp.setHours(23);
endTimestamp.setMinutes(59);
endTimestamp.setSeconds(58);

class TripDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      pages: null,
      loading: true,
      message: "",
      messageType: 0,
      dataSet: [],
      selected: null,
      // start_date: lastDay,
      // end_date: now,
      start_date: startTimestamp,
      end_date: endTimestamp,
      filteredData: [],
      searchInput: "",
      editingfields: {},
    };
    this.backtodashboardPage = this.backtodashboardPage.bind(this);
  }

  reset = () => {
    this.setState({
      start_date: now,
      end_date: now,
    });
  };

  handleDateChange(evt) {
    const value = evt.target.value;

    const name = evt.target.name;
    this.setState((prevState) => ({
      editingfields: {
        // object that we want to update

        ...prevState.editingfields, // keep all other key-value pairs
        [name]: moment(value).format("YYYY-MM-DD HH:mm:00"),
        // update the value of specific key
      },
    }));
    this.setState({ [name]: value });
  }
  componentDidMount() {
    //getting all the data
    this.getTripReport();
  }

  getTripReport() {
    const { start_date, end_date } = this.state;
    this.setState({ loading: true });
    if (start_date < end_date) {
      const payload = {
        //api_key: "REPORT_TRIP_DETAILS",
        user_id: sessionStorage.getItem("userId"),
        customerId: sessionStorage.getItem("customerId"),
        start_timestamp: moment(start_date).format("YYYY-MM-DD HH:mm:ss"),
        end_timestamp: moment(end_date).format("YYYY-MM-DD HH:mm:ss"),
      };
      this.props.getTripReportAPI(payload);
    } else if (start_date > end_date) {
      alert("End Date should be greater than Start Date");
    } else {
      alert("End Date should be greater than Start Date");
    }
  }

  componentDidUpdate(prevProps) {
    // console.log("tripdetails",this.props)
    if (prevProps !== this.props) {
      if (
        this.props.tripReportApi?.tripReportApi?.result?.data?.responseData
          ?.length > 0
      ) {
        this.setState({
          dataSet:
            this.props.tripReportApi.tripReportApi.result.data.responseData,
        });
        this.setState({
          filteredData:
            this.props.tripReportApi.tripReportApi.result.data.responseData,
        });
      } else {
        this.setState({ dataSet: [] });
      }
    }
  }

  onSubmit = (e) => {
    this.getTripReport();
  };

  tripdetrailshandleChange = (event) => {
  //   const search = event.target.value;
  //   this.setState({
  //     searchInput: search,
  //     filteredData: this.state.dataSet.filter(
  //       (item) =>
  //         (item.driver_name &&
  //           item.driver_name.toLowerCase().includes(search)) ||
  //         (item.reg_no && item.reg_no.toLowerCase().includes(search)) ||
  //         (item.trip_date && item.trip_date.toLowerCase().includes(search)) ||
  //         (item.driver_id && item.driver_id.toLowerCase().includes(search)) ||
  //         (item.typeauto_or_manual &&
  //           item.typeauto_or_manual.toLowerCase().includes(search))
  //     ),
  //   });
  // };

  const { value } = event.target;
  const { dataSet } = this.state;
  const searchInput = value.trim().toLowerCase();
  const filteredData = dataSet.filter((item) =>
    Object.keys(item).some(
      (key) =>
      typeof item[key] === "string" &&
        item[key] &&
        (key === "driver_name" ||
         key === "reg_no" ||
         key === "trip_date" ||
         key === "driver_id" || 
         key === "auto_or_manual"
         )
          &&
        item[key].toString().toLowerCase().includes(searchInput)
    )
  );
  this.setState({ searchInput, filteredData });
}
  backtodashboardPage() {
    let tabItem = {
      tabIndex: 16,
      tabName: "Dashboard",
    };
    this.props.selectedTab(null, tabItem);
  }

  render() {
    let { searchInput } = this.state;
    // const { data } = this.state;
    // console.log("dropdown props", this.props);

    return (
      <div>
        {this.props.tripReportApi?.tripReportApi?.loading ? (
          <div className="loader-overlay">
            <div className="loader-container">
              <ReactLoader />
            </div>
          </div>
        ) : null}

        <div className="fleet-health-backward">
          <BackToScreen routingCall={this.backtodashboardPage} />
          <h3 className="trip-details">Trip Details</h3>
        </div>
        <div className="trip_details_div">
          <div className="trip_details_div_labels">
            <label className="trip_details_div_labels_style-1">
              Start Date:
            </label>
            {/* <DatePicker
              name="start_date"
              selected={this.state.start_date}
              maxDate={moment().toDate()}
              // minDate={addDays(new Date(), -90)}
              value={this.state.start_date}
              // onChange={(value) => {
              //   this.setState({ start_date: value }, () => this.Compare());
              // }}
              onChange={(value) => this.Compare("start_date", value)}
              dateFormat="yyyy-MM-dd"
            /> */}
            <DatePicker
              autocomplete="off"
              name="start_date"
              //selected={this.state.start_date}

              selected={this.state.start_date}
              maxDate={moment().toDate()}
              minDate={addDays(new Date(), -90)}
              value={
                this.state.editingfields.start_date == "undefined"
                  ? this.state.start_date.setHours(0, 0, 0)
                  : this.state.editingfields.start_date
              }
              // value={this.state.editingfields.start_date}
              onChange={(value) =>
                this.handleDateChange({
                  target: { name: "start_date", value },
                })
              }
              //defaultValue={moment(now).toDate()}
              timeInputLabel="Time:"
              dateFormat="yyyy-MM-dd HH:mm:00"
              showTimeInput
            />

            <label className="trip_details_div_labels_style">End Date:</label>

            <DatePicker
              autocomplete="off"
              // valueDefault={null}
              //maxDate={moment().toDate()}
              name="end_date"
              //selected={this.state.end_date}
              selected={this.state.end_date}
              maxDate={moment().toDate()}
              minDate={addDays(new Date(), -90)}
              value={
                this.state.editingfields.end_date == "undefined"
                  ? this.state.end_date.setHours(23, 59, 59)
                  : this.state.editingfields.end_date
              }
              //value={this.state.editingfields.end_date}
              onChange={(value) =>
                this.handleDateChange({
                  target: { name: "end_date", value },
                })
              }
              //defaultValue="01-01-2001 01:01:01"
              timeInputLabel="Time:"
              dateFormat="yyyy-MM-dd HH:mm:00"
              showTimeInput
            />
          </div>

          <div style={{ marginRight: "20px", marginLeft: "-140px" }}>
            <button
              className="trip_details_div_button-submit-kpi"
              onClick={this.onSubmit}
            >
              <i className="fa fa-check" style={{ paddingRight: "5px" }}></i>
              Submit
            </button>
          </div>
          <div style={{ marginRight: "20px" }}>
            <button
              className="trip_details_div_button-submit-kpi-1"
              onClick={this.reset}
            >
              <i className="fa fa-refresh" style={{ paddingRight: "5px" }}></i>{" "}
              Reset
            </button>
          </div>

          <div className="search-trip-report">
            <form
              onSubmit={(e) => {
                e.preventDefault();
              }}
            >
              <input
                // style={{ width: "310px", marginTop: "6px",}}
                className="search-trip-report-input"
                type="text"
                id="filter"
                placeholder="Search Table (Driver ID / Driver Name / Reg No / Trip Date / Type)"
                value={searchInput || ""}
                onChange={this.tripdetrailshandleChange}
              />
            </form>
          </div>

          <div>
            <Workbook
              filename="Trip-Report.xlsx"
              element={
                <div
                  className="search-trip-report-download"
                  data-tip
                  data-for="registerTip"
                >
                  Export
                  <img
                    src={require("../../assets/images/export/export.png")}
                    alt=""
                    className="search-trip-report-download-img"
                  />
                  <ReactTooltip id="registerTip" place="top" effect="solid">
                    Download
                  </ReactTooltip>
                </div>
              }
            >
              <Workbook.Sheet data={this.state.dataSet} name="Trip-Report">
                {/* <Workbook.Column label="Trip ID" value="trip_id" />
                <Workbook.Column label="Route ID" value="route_id" /> */}
                <Workbook.Column label="Route Name" value="route_name" />
                <Workbook.Column label="Driver ID" value="driver_id" />

                <Workbook.Column label="Duty" value="duty" />
                <Workbook.Column label="Registration Number" value="reg_no" />
                <Workbook.Column label="Driver Name" value="driver_name" />

                <Workbook.Column label="Vin No" value="vin_no" />

                <Workbook.Column label="Status" value="status" />
                <Workbook.Column label="Type" value="auto_or_manual" />
                <Workbook.Column label="Start ODO" value="start_odo" />
                <Workbook.Column label="End ODO" value="end_odo" />

                <Workbook.Column label="Start SOC" value="start_soc" />
                <Workbook.Column label="End SOC" value="end_soc" />
                <Workbook.Column
                  label="Distance Travelled"
                  value="distance_travelled"
                />

                <Workbook.Column label="Start Time" value="start_time" />
                <Workbook.Column label="End Time" value="end_time" />
                <Workbook.Column label="Trip Date" value="trip_date" />
                <Workbook.Column label="kWh consumed" value="totalSoc" />
                <Workbook.Column label="Efficiency" value="efficiency" />
                <Workbook.Column
                  label="Charging Time Taken"
                  value="charging_time"
                />
                <Workbook.Column
                  label="Planned Distance"
                  value="plannedDistance"
                />
              </Workbook.Sheet>
            </Workbook>
          </div>
        </div>

        <div className="trip-report-table">
          <ReactTooltip place="left" type="success" effect="solid" />
          <ReactTable
            NoDataComponent={() => null}
            pageSizeOptions={[20, 30, 50, 80, 100, 130, 200, 500]}
            style={{
              height: "490px",
              marginTop: "0%",
              marginRight: "30px",
              //direction: "rtl",
              marginLeft: "40px",
              // margin_right: "2%",
              // width: "1560px",
            }}
            data={this.state.filteredData}
            showPagination={true}
            columns={[
              {
                Header: "S.No",
                id: "serialNumber",
                resizable: false,
                filterable: false,
                width: 90,

                Cell: (row) => {
                  return <div>{row.index + 1}</div>;
                },
              },

              {
                Header: "Route Name",
                id: "route_name",
                accessor: (d) => d.route_name,
                width: 120,

                filterAll: true,
                resizable: false,
              },
              {
                Header: "Driver ID",
                id: "driver_id",
                accessor: (d) => d.driver_id,
                width: 100,

                filterAll: true,
                resizable: false,
              },

              {
                Header: "Driver Name",
                id: "driver_name",
                accessor: (d) => d.driver_name,
                Cell: (rows) => {
                  /* Add data-tip */
                  return <span title={rows.value}>{rows.value}</span>;
                },
                width: 150,

                filterAll: true,
                resizable: false,
              },

              {
                Header: "Duty",
                id: "duty",
                accessor: (d) => d.duty,
                Cell: (rows) => {
                  /* Add data-tip */
                  return <span title={rows.value}>{rows.value}</span>;
                },
                width: 70,

                filterAll: true,
                resizable: false,
              },

              {
                Header: "Reg No",
                id: "reg_no",
                accessor: (d) => d.reg_no,

                filterAll: true,
                resizable: false,
              },
              {
                Header: "VIN",
                id: "vin_no",
                width: 150,
                accessor: (d) => d.vin_no,

                filterAll: true,
                resizable: false,
              },

              {
                Header: "Status",
                id: "status",
                accessor: (d) => d.status,

                filterAll: true,
                resizable: false,
              },
              {
                Header: "Type",
                id: "typeauto_or_manual",
                accessor: (d) => d.auto_or_manual,

                filterAll: true,
                resizable: false,
              },
              {
                Header: "Trip Date",
                id: "trip_date",
                width: 120,
                accessor: (d) => d.trip_date,

                filterAll: true,
                resizable: false,
              },

              {
                Header: "Start ODO (Km)",
                id: "start_odo",
                width: 120,
                accessor: (d) => d.start_odo,

                filterAll: true,
                resizable: false,
              },
              {
                Header: "End ODO (Km)",
                id: "end_odo",
                width: 120,
                accessor: (d) => d.end_odo,

                filterAll: true,
                resizable: false,
              },
              {
                Header: "Start SOC (%)",
                id: "start_soc",
                width: 120,
                accessor: (d) => d.start_soc,

                filterAll: true,
                resizable: false,
              },
              {
                Header: "End SOC (%)",
                id: "end_soc",
                width: 120,
                accessor: (d) => d.end_soc,

                filterAll: true,
                resizable: false,
              },
              {
                Header: "Distance Travelled",
                id: "distance_travelled",
                width: 150,
                accessor: (d) => d.distance_travelled,

                filterAll: true,
                resizable: false,
              },

              {
                Header: "Start Time",
                id: "start_time",
                width: 150,
                Cell: (rows) => {
                  /* Add data-tip */
                  return <span title={rows.value}>{rows.value}</span>;
                },
                accessor: (d) => d.start_time,

                filterAll: true,
                resizable: false,
              },

              {
                Header: "Kwh consumed",
                id: "totalSoc",
                width: 150,
                accessor: (d) => d.totalSoc,

                filterAll: true,
                resizable: false,
              },
              {
                Header: "End Time",
                id: "end_time",
                width: 150,
                Cell: (rows) => {
                  /* Add data-tip */
                  return <span title={rows.value}>{rows.value}</span>;
                },
                accessor: (d) => d.end_time,

                filterAll: true,
                resizable: false,
              },

              {
                Header: "Efficiency (Kwh)",
                id: "efficiency",
                width: 150,
                accessor: (d) => d.efficiency,

                filterAll: true,
                resizable: false,
              },

              // {
              //   Header: "Route ID",
              //   id: "routeid",
              //   accessor: (d) => d.routeid,
              //   width: 100,
              //   getProps: (state, rowInfo, column) => {
              //     return {
              //       style: {
              //         direction: "ltr",
              //       },
              //     };
              //   },
              //   filterAll: true,
              //   resizable: false,
              // },

              // {
              //   Header: "Trip ID",
              //   id: "trip_id",
              //   accessor: (d) => d.trip_id,
              //   width: 100,
              //   getProps: (state, rowInfo, column) => {
              //     return {
              //       style: {
              //         direction: "ltr",
              //       },
              //     };
              //   },

              //   filterAll: true,
              //   resizable: false,
              // },

              {
                Header: "Planned Distance (Km)",
                id: "plannedDistance",
                width: 150,
                accessor: (d) => d.plannedDistance,

                filterAll: true,
                resizable: false,
              },

              {
                Header: "Charging Time Taken (hh:mm:ss)",
                id: "totalTime",
                width: 190,
                accessor: (d) => d.totalTime,

                filterAll: true,
                resizable: false,
              },
            ]}
            defaultSorted={[
              {
                id: "serialNumber",
                asc: true,
              },
            ]}
            defaultPageSize={50}
            className="-striped -highlight"
          >
            {(state, makeTable, instance) => {
              let recordsInfoText = "";

              const { filtered, pageRows, pageSize, sortedData, page } = state;

              if (sortedData && sortedData.length > 0) {
                let isFiltered = filtered.length > 0;

                let totalRecords = sortedData.length;

                let recordsCountFrom = page * pageSize + 1;

                let recordsCountTo = recordsCountFrom + pageRows.length - 1;

                if (isFiltered)
                  recordsInfoText = `${recordsCountFrom}-${recordsCountTo} of ${totalRecords} filtered records`;
                else
                  recordsInfoText = `${recordsCountFrom}-${recordsCountTo} of ${totalRecords} records`;
              } else recordsInfoText = "No records";

              return (
                <div className="main-grid">
                  <div className="above-table text-right">
                    <div className="col-sm-12">
                      <span className="records-info">{recordsInfoText}</span>
                    </div>
                  </div>

                  {makeTable()}
                </div>
              );
            }}
          </ReactTable>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps, {
  getTripReportAPI,
})(withRouter(TripDetails));

//export default TripReport;
