import React, { Component } from "react";
import ReactTable from "react-table";
import "react-table/react-table.css";
//import Buttons from "../button";
import "./index.scss";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { matchSorter } from "match-sorter";
import Workbook from "react-excel-workbook";
import moment from "moment";
import ReactTooltip from "react-tooltip";
import DatePicker from "react-datepicker";
import BackToScreen from "../../component/back_to_screen";
import { addDays } from "date-fns";
import "react-datepicker/dist/react-datepicker";
import ReactLoader from "../react-loader/react-loader.component";
import checklistReportActions from "../../redux/checklist_report/actions";

const { getChecklistReportAPI } = checklistReportActions;
// var now = new Date();
// now.setDate(now.getDate());
// var lastDay = new Date();
// lastDay.setHours(lastDay.getHours() - 6)

var startTimestamp = new Date();
startTimestamp.setHours(0);
startTimestamp.setMinutes(0);
startTimestamp.setSeconds(0);

var endTimestamp = new Date();
endTimestamp.setHours(23);
endTimestamp.setMinutes(59);
endTimestamp.setSeconds(58);

class CheckListReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      pages: null,
      loading: true,
      message: "",
      messageType: 0,
      dataSet: [],
      selected: null,
      // start_date: lastDay,
      // end_date: now,
      start_date: startTimestamp,
      end_date: endTimestamp,
      editingfields: {},
    };

    this.backtodashboardPage = this.backtodashboardPage.bind(this);
  }

  // reset = () => {
  //   this.setState({
  //     start_date: now,
  //     end_date: now,
  //   });
  // };

  componentDidMount() {
    //getting all the data
    this.getChecklistReport();
  }

  getChecklistReport() {
    const { start_date, end_date } = this.state;
    this.setState({ loading: true });
    if (start_date < end_date) {
      const payload = {
        //api_key: "REPORT_CHECKLIST_DETAILS",
        user_id: sessionStorage.getItem("userId"),
        customerId: sessionStorage.getItem("customerId"),
        start_timestamp: moment(start_date).format("YYYY-MM-DD HH:mm:ss"),
        end_timestamp: moment(end_date).format("YYYY-MM-DD HH:mm:ss"),
      };
      this.props.getChecklistReportAPI(payload);
    } else if (start_date > end_date) {
      alert("End Date should be greater than Start Date");
    } else {
      alert("End Date should be greater than Start Date");
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      if (
        this.props.checklistReportApi?.checklistReportApi?.result?.data
          ?.responseData?.length > 0
      ) {
        this.setState({
          dataSet:
            this.props.checklistReportApi.checklistReportApi.result.data
              .responseData,
        });

        this.setState({
          filteredData:
            this.props.checklistReportApi.checklistReportApi.result.data
              .responseData,
        });
      } else {
        this.setState({ dataSet: [] });
      }
    }
  }

  onSubmit = (e) => {
    this.getChecklistReport();
  };

  driverfeedbackhandleChange = (event) => {
    const search = event.target.value.toLowerCase();
    this.setState({
      searchInput: search,
      filteredData: this.state.dataSet.filter(
        (item) =>
          (item.name && item.name.toLowerCase().includes(search)) ||
          (item.reg_no && item.reg_no.toLowerCase().includes(search))
      ),
    });
  };

  handleDateChange(evt) {
    const value = evt.target.value;

    const name = evt.target.name;
    this.setState((prevState) => ({
      editingfields: {
        // object that we want to update

        ...prevState.editingfields, // keep all other key-value pairs
        [name]: moment(value).format("YYYY-MM-DD HH:mm:00"),
        // update the value of specific key
      },
    }));
    this.setState({ [name]: value });
  }
  backtodashboardPage() {
    let tabItem = {
      tabIndex: 16,
      tabName: "Dashboard",
    };
    this.props.selectedTab(null, tabItem);
  }

  render() {
    let { searchInput } = this.state;
    return (
      <div>
        {this.props.checklistReportApi?.checklistReportApi?.loading ? (
          <div className="loader-overlay">
            <div className="loader-container">
              <ReactLoader />
            </div>
          </div>
        ) : null}

        <div className="fleet-health-backward">
          <BackToScreen routingCall={this.backtodashboardPage} />
          <h3 className="checklistsummary">Driver Feedback</h3>
        </div>

        <div className="container-fluid">
          <div className="row d-flex ms-2 mt-1">
            <div className="col-6 d-flex">
              <div className="driver-feedback_labels">
                <label className="driver-feedback_labels_styles">
                  Start Date:
                </label>

                <DatePicker
                  autocomplete="off"
                  name="start_date"
                  selected={this.state.start_date}
                  //selected={this.state.start_date.setHours(0, 0, 0)}
                  maxDate={moment().toDate()}
                  minDate={addDays(new Date(), -90)}
                  value={
                    this.state.editingfields.start_date == "undefined"
                      ? this.state.start_date.setHours(0, 0, 0)
                      : this.state.editingfields.start_date
                  }
                  onChange={(value) =>
                    this.handleDateChange({
                      target: { name: "start_date", value },
                    })
                  }
                  //defaultValue={moment(now).toDate()}
                  timeInputLabel="Time:"
                  dateFormat="yyyy-MM-dd HH:mm:00"
                  showTimeInput
                />

                <label className="driver-feedback_labels_styles-1">
                  End Date:
                </label>

                <DatePicker
                  autocomplete="off"
                  // valueDefault={null}
                  //maxDate={moment().toDate()}
                  name="end_date"
                  selected={this.state.end_date}
                  // selected={this.state.end_date.setHours(23, 59, 59)}
                  maxDate={moment().toDate()}
                  minDate={addDays(new Date(), -90)}
                  value={
                    this.state.editingfields.end_date == "undefined"
                      ? this.state.end_date.setHours(23, 59, 59)
                      : this.state.editingfields.end_date
                  }
                  onChange={(value) =>
                    this.handleDateChange({
                      target: { name: "end_date", value },
                    })
                  }
                  //defaultValue="01-01-2001 01:01:01"
                  timeInputLabel="Time:"
                  dateFormat="yyyy-MM-dd HH:mm:00"
                  showTimeInput
                />
              </div>
              <div>
                <button
                  className="driver-feedback-button-submit-kpis"
                  onClick={this.onSubmit}
                >
                  <i
                    className="fa fa-check"
                    style={{ paddingRight: "5px" }}
                  ></i>
                  Submit
                </button>
              </div>
              <div>
                <button
                  className="driver-feedback-button-submit-kpis-1"
                  onClick={this.reset}
                >
                  <i
                    className="fa fa-refresh"
                    style={{ paddingRight: "5px" }}
                  ></i>{" "}
                  Reset
                </button>
              </div>
            </div>
            <div className="col-6 d-flex">
              <div className="driver-feedback-report">
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                  }}
                >
                  <input
                    className="driver-feedback-input"
                    type="text"
                    id="filter"
                    placeholder="Search Table (Driver Name / Reg No)"
                    value={searchInput || ""}
                    onChange={this.driverfeedbackhandleChange}
                  />
                </form>
              </div>
              <div>
                <Workbook
                  filename="Driver-Feedback.xlsx"
                  element={
                    <div
                      className="driver-feedback-download"
                      data-tip
                      data-for="registerTip"
                    >
                      Export
                      <img
                        src={require("../../assets/images/export/export.png")}
                        alt=""
                        className="driver-feedback-download-img"
                      />
                      <ReactTooltip id="registerTip" place="top" effect="solid">
                        Download
                      </ReactTooltip>
                    </div>
                  }
                >
                  <Workbook.Sheet
                    data={this.state.dataSet}
                    name="Driver-Feedback"
                  >
                    <Workbook.Column label="Driver Name" value="name" />
                    <Workbook.Column label="Reg No" value="reg_no" />
                    <Workbook.Column
                      label="Checklist Area"
                      value="checklist_name"
                    />
                    <Workbook.Column
                      label="Check Points"
                      value="checklist_type"
                    />
                    <Workbook.Column
                      label="Description"
                      value="checklist_description"
                    />
                    <Workbook.Column
                      label="Reported Time"
                      value="created_timestamp"
                    />
                  </Workbook.Sheet>
                </Workbook>
              </div>
            </div>
          </div>
        </div>

        <div className="upload-container icon_pos">
          <ReactTable
            NoDataComponent={() => null}
            pageSizeOptions={[20, 30, 50, 80, 100, 130, 200, 500]}
            style={{
              height: "490px",
              marginTop: "0%",
              marginRight: "30px",
              marginLeft: "40px",
            }}
            data={this.state.filteredData}
            showPagination={true}
            columns={[
              {
                Header: "S.No",
                id: "serialNumber",
                filterable: false,
                width: 90,
                Cell: (row) => {
                  return <div>{row.index + 1}</div>;
                },
              },
              {
                Header: "Driver Name",
                id: "name",
                accessor: (d) => d.name,
                width: 220,
                filterMethod: (filter, rows) =>
                  matchSorter(rows, filter.value, { keys: ["name"] }),
                filterAll: true,
                resizable: false,
              },
              {
                Header: "Reg No.",
                id: "reg_no",
                accessor: (d) => d.reg_no,
                width: 200,
                filterMethod: (filter, rows) =>
                  matchSorter(rows, filter.value, { keys: ["reg_no"] }),
                filterAll: true,
                resizable: false,
              },
              {
                Header: "Checking Area",
                id: "checklist_name",
                accessor: (d) => d.checklist_name,
                width: 300,
                filterMethod: (filter, rows) =>
                  matchSorter(rows, filter.value, { keys: ["checklist_name"] }),
                filterAll: true,
                resizable: false,
              },
              {
                Header: "Check Points",
                id: "checklist_type",
                accessor: (d) => d.checklist_type,
                width: 140,
                filterMethod: (filter, rows) =>
                  matchSorter(rows, filter.value, { keys: ["checklist_type"] }),
                filterAll: true,
                resizable: false,
              },
              {
                Header: "Description",
                id: "checklist_description",
                accessor: (d) => d.checklist_description,
                width: 280,
                filterMethod: (filter, rows) =>
                  matchSorter(rows, filter.value, {
                    keys: ["checklist_description"],
                  }),
                filterAll: true,
                resizable: false,
              },
              {
                Header: "Reported Time",
                id: "created_timestamp",
                accessor: (d) => d.created_timestamp,
                width: 220,
                filterMethod: (filter, rows) =>
                  matchSorter(rows, filter.value, {
                    keys: ["created_timestamp"],
                  }),
                filterAll: true,
                resizable: false,
              },
            ]}
            defaultSorted={[
              {
                id: "serialNumber",
                asc: true,
              },
            ]}
            defaultPageSize={50}
            className="-striped -highlight"
          >
            {(state, makeTable, instance) => {
              let recordsInfoText = "";

              const { filtered, pageRows, pageSize, sortedData, page } = state;

              if (sortedData && sortedData.length > 0) {
                let isFiltered = filtered.length > 0;

                let totalRecords = sortedData.length;

                let recordsCountFrom = page * pageSize + 1;

                let recordsCountTo = recordsCountFrom + pageRows.length - 1;

                if (isFiltered)
                  recordsInfoText = `${recordsCountFrom}-${recordsCountTo} of ${totalRecords} filtered records`;
                else
                  recordsInfoText = `${recordsCountFrom}-${recordsCountTo} of ${totalRecords} records`;
              } else recordsInfoText = "No records";

              return (
                <div className="main-grid">
                  <div className="above-table text-right">
                    <div className="col-sm-12">
                      <span className="records-info">{recordsInfoText}</span>
                    </div>
                  </div>

                  {makeTable()}
                </div>
              );
            }}
          </ReactTable>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps, {
  getChecklistReportAPI,
})(withRouter(CheckListReport));

//export default CheckListReport;
