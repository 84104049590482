import React, { Component } from "react";
import "./index.scss";

class DashboardSpeedBand extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
    };
  }
  render() {
    const res = this.props.speedbanddatagraph;
    let datass1 = res[0].count;
    let datass2 = res[1].count;
    let datass3 = res[2].count;
    let datass4 = res[3].count;

    return (
      <div>
        <div className="speed_band_content">
          <div className="speed_band_1">
            <button style={{ cursor: "default" }} className="speed_band_button">
              <p className="speed_band_button_value">{datass1}</p>
            </button>
            <p className="speed_band_value">0-20</p>
          </div>
          <div className="speed_band_2">
            <button
              style={{ cursor: "default" }}
              className="speed_band_button-1"
            >
              <p className="speed_band_button_value">{datass2}</p>
            </button>
            <p className="speed_band_value">20-40</p>
          </div>
          <div className="speed_band_3">
            <button
              style={{ cursor: "default" }}
              className="speed_band_button-2"
            >
              <p className="speed_band_button_value">{datass3}</p>
            </button>
            <p className="speed_band_value">40-50</p>
          </div>
          <div className="speed_band_4">
            <button
              style={{ cursor: "default" }}
              className="speed_band_button-3"
            >
              <p className="speed_band_button_value">{datass4}</p>
            </button>
            <p className="speed_band_value">50+</p>
          </div>
        </div>
      </div>
    );
  }
}
export default DashboardSpeedBand;
