const contractualPunctualityGraphApiActions = {
    //contractualDetails
    GET_CONTRACTUALSTARTPUNCTUALGRAPHDETAILS: "GET_CONTRACTUALSTARTPUNCTUALGRAPHDETAILS",
    GET_CONTRACTUALSTARTPUNCTUALGRAPHDETAILS_SUCCESS: "GET_CONTRACTUALSTARTPUNCTUALGRAPHDETAILS_SUCCESS",
    GET_CONTRACTUALSTARTPUNCTUALGRAPHDETAILS_FAILURE: "GET_CONTRACTUALSTARTPUNCTUALGRAPHDETAILS_FAILURE",
  
    getContractualStartPunctualGraphAPI: (data) => ({
      type: contractualPunctualityGraphApiActions.GET_CONTRACTUALSTARTPUNCTUALGRAPHDETAILS,
      payload: { data },
    }),
  
    getContractualStartPunctualGraphAPISuccess: (saveStatusCode, result) => ({
      type: contractualPunctualityGraphApiActions.GET_CONTRACTUALSTARTPUNCTUALGRAPHDETAILS_SUCCESS,
      saveStatusCode,
      result,
    }),
  
    getContractualStartPunctualGraphAPIFailure: (saveStatusCode, message) => ({
      type: contractualPunctualityGraphApiActions.GET_CONTRACTUALSTARTPUNCTUALGRAPHDETAILS_FAILURE,
      saveStatusCode,
      message,
    }),

    // Arrival Punctuality graph

    GET_CONTRACTUALARRIVALPUNCTUALGRAPHDETAILS: "GET_CONTRACTUALARRIVALPUNCTUALGRAPHDETAILS",
    GET_CONTRACTUALARRIVALPUNCTUALGRAPHDETAILS_SUCCESS: "GET_CONTRACTUALARRIVALPUNCTUALGRAPHDETAILS_SUCCESS",
    GET_CONTRACTUALARRIVALPUNCTUALGRAPHDETAILS_FAILURE: "GET_CONTRACTUALARRIVALPUNCTUALGRAPHDETAILS_FAILURE",
  
    getContractualArrivalPunctualGraphAPI: (data) => ({
      type: contractualPunctualityGraphApiActions.GET_CONTRACTUALARRIVALPUNCTUALGRAPHDETAILS,
      payload: { data },
    }),
  
    getContractualArrivalPunctualGraphAPISuccess: (saveStatusCode, result) => ({
      type: contractualPunctualityGraphApiActions.GET_CONTRACTUALARRIVALPUNCTUALGRAPHDETAILS_SUCCESS,
      saveStatusCode,
      result,
    }),
  
    getContractualArrivalPunctualGraphAPIFailure: (saveStatusCode, message) => ({
      type: contractualPunctualityGraphApiActions.GET_CONTRACTUALARRIVALPUNCTUALGRAPHDETAILS_FAILURE,
      saveStatusCode,
      message,
    }),

}

export default contractualPunctualityGraphApiActions;