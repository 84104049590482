const speedBandApiAction = {
  GET_SPEED_BAND: "GET_SPEED_BAND",
  GET_SPEED_BAND_SUCCESS: " GET_SPEED_BAND_SUCCESS",
  GET_SPEED_BAND_FAILURE: " GET_SPEED_BAND_FAILURE",

  getSpeedbandGraphAPI: (data) => ({
    type: speedBandApiAction.GET_SPEED_BAND,
    payload: { data },
  }),

  getSpeedbandGraphAPISuccess: (saveStatusCode, result) => ({
    type: speedBandApiAction.GET_SPEED_BAND_SUCCESS,
    saveStatusCode,
    result,
  }),

  getSpeedbandGraphAPIFailure: (saveStatusCode, message) => ({
    type: speedBandApiAction.GET_SPEED_BAND_FAILURE,
    saveStatusCode,
    message,
  }),

  GET_SPEED_BAND_LIST: "GET_SPEED_BAND_LIST",
  GET_SPEED_BAND_LIST_SUCCESS: " GET_SPEED_BAND_LIST_SUCCESS",
  GET_SPEED_BAND_LIST_FAILURE: " GET_SPEED_BAND_LIST_FAILURE",

  getSpeedbandListAPI: (data) => ({
    type: speedBandApiAction.GET_SPEED_BAND_LIST,
    payload: { data },
  }),

  getSpeedbandListAPISuccess: (saveStatusCode, result) => ({
    type: speedBandApiAction.GET_SPEED_BAND_LIST_SUCCESS,
    saveStatusCode,
    result,
  }),

  getSpeedbandListAPIFailure: (saveStatusCode, message) => ({
    type: speedBandApiAction.GET_SPEED_BAND_LIST_FAILURE,
    saveStatusCode,
    message,
  }),
};

export default speedBandApiAction;
