import { all, takeEvery, put, call } from "redux-saga/effects";
import actions from "./actions";
import * as dataAccess from "../../utils/ajax";
import {
    ACCIDENT_MASTER_VIEW,
  ACCIDENT_MASTER_CREATE,
  ACCIDENT_MASTER_DELETE,
  ACCIDENT_MASTER_UPDATE

} from "../../constants/api-constants";

export const postLambda = async (url, payload) =>
  await dataAccess.postLambda(url, payload);

export function* getAccidentMasterApiSagas(data) {
  const { payload } = data;
  let url = ACCIDENT_MASTER_VIEW;
  try {
    let response = yield call(postLambda, url, payload);

    if (response) {
      yield put(actions.getAccidentMasterAPISuccess(0, response));
    }
  } catch (error) {
    yield put(actions.getAccidentMasterAPIFailure(1));
  }
}

export function* createAccidentMasterApiSagas(data) {
  const { payload } = data;
  let url =ACCIDENT_MASTER_CREATE;
  try {
    let response = yield call(postLambda, url, payload);

    if (response) {
      yield put(actions.createAccidentMasterAPISuccess(0, response));
    }
  } catch (error) {
    yield put(actions.createAccidentMasterAPIFailure(1));
  }
}

export function* deleteAccidentMasterApiSagas(data) {
  const { payload } = data;
  let url = ACCIDENT_MASTER_DELETE;
  try {
    let response = yield call(postLambda, url, payload);

    if (response) {
      yield put(actions.deleteAccidentMasterAPISuccess(0, response));
    }
  } catch (error) {
    yield put(actions.deleteAccidentMasterAPIFailure(1));
  }
}

export function* updateAccidentMasterApiSagas(data) {
  const { payload } = data;
  let url = ACCIDENT_MASTER_UPDATE;
  try {
    let response = yield call(postLambda, url, payload);

    if (response) {
      yield put(actions.updateAccidentMasterAPISuccess(0, response));
    }
  } catch (error) {
    yield put(actions.updateAccidentMasterAPIFailure(1));
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.GET_ACCIDENTMASTERDETAILS, getAccidentMasterApiSagas),
    takeEvery(actions.CREATE_ACCIDENTMASTERDETAILS, createAccidentMasterApiSagas),
    takeEvery(actions.DELETE_ACCIDENTMASTERDETAILS, deleteAccidentMasterApiSagas),
    takeEvery(actions.UPDATE_ACCIDENTMASTERDETAILS, updateAccidentMasterApiSagas),
  ]);
}
