import React from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalTitle,
  Button,
  closeButton,
} from "react-bootstrap";
import moment from "moment";
import DatePicker from "react-datepicker";

import "./index.scss";

import { connect } from "react-redux";
import { withRouter } from "react-router";
//import * as User from "../../shared/app-data/user";
//import DateTimePicker from "react-datepicker";

import accidentMasterApiActions from "../../redux/master_accident/actions";

const { updateAccidentMasterAPI } = accidentMasterApiActions;

class MasterAccidentPopup extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      show: false,
      editingfields: {},
      message: "",
      messageType: 0,
      date: new Date(),
      accident_reason: "",
      actual_cost: "",
      additional_remarks: "",
      air_serial_number: "",
      bus_on_road_date: "",
      corrective_action: "",
      cost_of_labour: "",
      cost_of_part: "",
      createdtimestamp_ist: "",
      damaged_parts: "",
      date: "",
      description: "",
      driver_name: "",
      estimation_cost: "",
      id: "",
      immediate_action: "",
      insurance_claim_required: "",
      job_card_number: "",
      loss: "",
      loss_of_revenue: "",
      major_minor: "",
      planned_days_for_repair_the_bus: "",
      recovry_from_insurance: "",
      registration_number: "",
      time: "",
      total_days_used_for_accident_repair: "",
      type: "",
      vehicleid: "",
      driver_badge_number: "",
      popData: "",
      driverList: [],
      vehicleList: [],
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps != this.props) {
      this.setState({
        show: this.props.show,
        driverList: this.props.driverData,
        vehicleList: this.props.vehicleData,
      });
    }
  }

  componentDidMount() {
    this.setState({
      show: this.props.show,
      editingfields: this.props.data,
      driverList: this.props.driverData,
      vehicleList: this.props.vehicleData,
    });
  }

  handleDateChange(evt) {
    const value = evt.target.value;
    const name = evt.target.name;
    this.setState((prevState) => ({
      editingfields: {
        // object that we want to update
        ...prevState.editingfields, // keep all other key-value pairs
        [name]: moment(value).format("YYYY-MM-DD HH:mm:00"), // update the value of specific key
      },
    }));
    this.setState({ [name]: value });
  }

  handleClose = () => {
    this.setState({ show: true });
  };

  handleChange = (evt) => {
    const value = evt.target.value;

    const name = evt.target.name;

    this.setState((prevState) => ({
      editingfields: {
        // object that we want to update
        ...prevState.editingfields, // keep all other key-value pairs
        [name]: value, // update the value of specific key
      },
    }));
    ///this.getTripMasterDetails();
  };

  handleSubmit = (event) => {
    //console.log("submit",this.props.updateAccidentMasterAPI)
    event.preventDefault();

    const {
      accident_reason,
      actual_cost,
      additional_remarks,
      air_serial_number,
      bus_on_road_date,
      corrective_action,
      cost_of_labour,
      cost_of_part,
      createdtimestamp_ist,
      damaged_parts,
      date,
      description,
      driver_name,
      estimation_cost,
      id,
      immediate_action,
      insurance_claim_required,
      job_card_number,
      loss,
      loss_of_revenue,
      major_minor,
      planned_days_for_repair_the_bus,
      recovry_from_insurance,
      registration_number,
      time,
      total_days_used_for_accident_repair,
      type,
      vehicleid,
      driver_badge_number,
      reg_no,
      name_tagid,
      reg_vin,
    } = this.state.editingfields;

    let a = reg_no.split("->");

    var driverString = name_tagid.split(/->/);
    var driverarr = [driverString.shift(), driverString.join(" ")];
    // console.log("Welcome to Programiz!1", driverarr[0]);
    // console.log("Welcome to Programiz!2", driverarr[1]);

    var vehicleString = reg_vin.split(/->/);
    var vehiclearr = [vehicleString.shift(), vehicleString.join(" ")];
    // console.log("Welcome to Programiz!3", vehiclearr[0]);
    // console.log("Welcome to Programiz!4", vehiclearr[1]);

    const payload = {
      accident_reason,
      actual_cost,
      additional_remarks,
      air_serial_number,
      bus_on_road_date,
      corrective_action,
      cost_of_labour,
      cost_of_part,
      createdtimestamp_ist,
      damaged_parts,
      date,
      description,
      driver_name,
      estimation_cost,
      immediate_action,
      insurance_claim_required,
      job_card_number,
      loss,
      loss_of_revenue,
      major_minor,
      planned_days_for_repair_the_bus,
      recovry_from_insurance,
      registration_number,
      time,
      total_days_used_for_accident_repair,
      type,
      vehicleid,
      driver_badge_number,

      driver_id: driverarr[1],
      reg_no: vehiclearr[0],
      vin_no: vehiclearr[1],
    };
    this.props.updateAccidentMasterAPI(payload);
  };

  render() {
    let val = this.state.editingfields.reg_no;
    //console.log("searching1111", this.state.editingfields);
    return (
      <div className="driver-popup">
        <Modal
          // style={{
          //   height: "1000px",
          //   minHeight: "500px",
          // }}
          show={this.state.show}
          size="xl"
          aria-labelledby="example-modal-sizes-title-xl"
          centered
          onHide={this.props.closemodal}
          //dialogClassName="modal-90w"
        >
          <Modal.Header closeButton style={{ borderBottom: "none" }}>
            <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
          </Modal.Header>
          <form
            className="accident-popup-update"
            autocomplete="off"
            onSubmit={this.handleSubmit}
          >
            <Modal.Body
              style={{
                "max-height": "calc(80vh - 210px)",

                "overflow-y": "auto",
              }}
            >
              <div className="formwidth">
                <div className="col_50">
                  <div className="col_100">
                    <label>
                      Vehicle:<span style={{ color: "red" }}> *</span>
                    </label>
                  </div>
                  <div className="col_100">
                    <select
                      placeholder="Registration Number:"
                      name="reg_vin"
                      onChange={this.handleChange}
                      value={this.state.editingfields.reg_vin}
                    >
                      <option defaultValue=""></option>
                      {this.state.vehicleList.map((e, i) => (
                        <option key={i} value={e.reg_vin}>
                          {e.reg_vin}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="col_50">
                  <div className="col_100">
                    <label>
                      Driver Badge No:<span style={{ color: "red" }}> *</span>
                    </label>
                  </div>
                  <div className="col_100">
                    <select
                      placeholder="Driver Name:"
                      name="name_tagid"
                      onChange={this.handleChange}
                      value={this.state.editingfields.name_tagid}
                    >
                      <option defaultValue=""> </option>
                      {this.state.driverList.map((e, i) => (
                        <option key={i} value={e.name_tagid}>
                          {e.name_tagid}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                <div className="col_50">
                  <div className="col_100">
                    <label>
                      Description:<span style={{ color: "red" }}> *</span>
                    </label>
                  </div>
                  <div className="col_100">
                    <div className="col_100">
                      <input
                        type="text"
                        placeholder="Description"
                        name="description"
                        style={{ textTransform: "uppercase" }}
                        value={this.state.editingfields.description}
                        onChange={this.handleChange}
                        autocomplete="off"
                        //required
                      />
                    </div>
                  </div>
                </div>

                <div className="col_50">
                  <div className="col_100">
                    <label>
                      Timestamp:<span style={{ color: "red" }}> *</span>
                    </label>
                  </div>
                  <div className="col_100">
                    <input
                      type="text"
                      placeholder="timestamp"
                      name="createdtimestamp_ist"
                      style={{ textTransform: "uppercase" }}
                      value={this.state.editingfields.createdtimestamp_ist}
                      onChange={this.handleChange}
                      autocomplete="off"
                      //required
                    />
                  </div>
                </div>

                <div className="col_50">
                  <div className="col_100">
                    <label>
                      Bus Registration No:<span style={{ color: "red" }}> *</span>
                    </label>
                  </div>
                  <div className="col_100">
                    <div className="col_100">
                      <input
                        type="text"
                        placeholder="Description"
                        name="registration_number"
                        style={{ textTransform: "uppercase" }}
                        value={this.state.editingfields.registration_number}
                        onChange={this.handleChange}
                        autocomplete="off"
                        //required
                      />
                    </div>
                  </div>
                </div>

                <div className="col_50">
                  <div className="col_100">
                    <label>
                      Date Of Accident:<span style={{ color: "red" }}> *</span>
                    </label>
                  </div>
                  <div className="col_100">
                    <div className="col_100">
                      <input
                        type="text"
                        placeholder="Description"
                        name="date"
                        style={{ textTransform: "uppercase" }}
                        value={this.state.editingfields.date}
                        onChange={this.handleChange}
                        autocomplete="off"
                        //required
                      />
                    </div>
                  </div>
                </div>

                <div className="col_50">
                  <div className="col_100">
                    <label>
                      Time Of Accident:<span style={{ color: "red" }}> *</span>
                    </label>
                  </div>
                  <div className="col_100">
                    <div className="col_100">
                      <input
                        type="text"
                        placeholder="Description"
                        name="time"
                        style={{ textTransform: "uppercase" }}
                        value={this.state.editingfields.time}
                        onChange={this.handleChange}
                        autocomplete="off"
                        //required
                      />
                    </div>
                  </div>
                </div>

                <div className="col_50">
                  <div className="col_100">
                    <label>
                      AIR Serial No:<span style={{ color: "red" }}> *</span>
                    </label>
                  </div>
                  <div className="col_100">
                    <div className="col_100">
                      <input
                        type="text"
                        placeholder="Description"
                        name="air_serial_number"
                        style={{ textTransform: "uppercase" }}
                        value={this.state.editingfields.air_serial_number}
                        onChange={this.handleChange}
                        autocomplete="off"
                      //  required
                      />
                    </div>
                  </div>
                </div>

                <div className="col_50">
                  <div className="col_100">
                    <label>
                      Damaged Part:<span style={{ color: "red" }}> *</span>
                    </label>
                  </div>
                  <div className="col_100">
                    <div className="col_100">
                      <input
                        type="text"
                        placeholder="Description"
                        name="damaged_parts"
                        style={{ textTransform: "uppercase" }}
                        value={this.state.editingfields.damaged_parts}
                        onChange={this.handleChange}
                        autocomplete="off"
                       // required
                      />
                    </div>
                  </div>
                </div>

                <div className="col_50">
                  <div className="col_100">
                    <label>
                      Immediate Action:<span style={{ color: "red" }}> *</span>
                    </label>
                  </div>
                  <div className="col_100">
                    <div className="col_100">
                      <input
                        type="text"
                        placeholder="Description"
                        name="immediate_action"
                        style={{ textTransform: "uppercase" }}
                        value={this.state.editingfields.immediate_action}
                        onChange={this.handleChange}
                        autocomplete="off"
                        //required
                      />
                    </div>
                  </div>
                </div>

                <div className="col_50">
                  <div className="col_100">
                    <label>
                      Reason Of Accident:
                      <span style={{ color: "red" }}> *</span>
                    </label>
                  </div>
                  <div className="col_100">
                    <div className="col_100">
                      <input
                        type="text"
                        placeholder="Description"
                        name="accident_reason"
                        style={{ textTransform: "uppercase" }}
                        value={this.state.editingfields.accident_reason}
                        onChange={this.handleChange}
                        autocomplete="off"
                       // required
                      />
                    </div>
                  </div>
                </div>

                <div className="col_50">
                  <div className="col_100">
                    <label>
                      Insurance Claim Required:
                      <span style={{ color: "red" }}> *</span>
                    </label>
                  </div>
                  <div className="col_100">
                    <div className="col_100">
                      <input
                        type="text"
                        placeholder="Description"
                        name="insurance_claim_required"
                        style={{ textTransform: "uppercase" }}
                        value={
                          this.state.editingfields.insurance_claim_required
                        }
                        onChange={this.handleChange}
                        autocomplete="off"
                      //  required
                      />
                    </div>
                  </div>
                </div>

                <div className="col_50">
                  <div className="col_100">
                    <label>
                      Job Card Number:<span style={{ color: "red" }}> *</span>
                    </label>
                  </div>
                  <div className="col_100">
                    <div className="col_100">
                      <input
                        type="text"
                        placeholder="Description"
                        name="job_card_number"
                        style={{ textTransform: "uppercase" }}
                        value={this.state.editingfields.job_card_number}
                        onChange={this.handleChange}
                        autocomplete="off"
                      //  required
                      />
                    </div>
                  </div>
                </div>

                <div className="col_50">
                  <div className="col_100">
                    <label>
                      Cost Of Part:<span style={{ color: "red" }}> *</span>
                    </label>
                  </div>
                  <div className="col_100">
                    <div className="col_100">
                      <input
                        type="text"
                        placeholder="Description"
                        name=" cost_of_part"
                        style={{ textTransform: "uppercase" }}
                        value={this.state.editingfields.cost_of_part}
                        onChange={this.handleChange}
                        autocomplete="off"
                       // required
                      />
                    </div>
                  </div>
                </div>

                <div className="col_50">
                  <div className="col_100">
                    <label>
                      Cost Of Labour:<span style={{ color: "red" }}> *</span>
                    </label>
                  </div>
                  <div className="col_100">
                    <div className="col_100">
                      <input
                        type="text"
                        placeholder="Description"
                        name=" cost_of_labour"
                        style={{ textTransform: "uppercase" }}
                        value={this.state.editingfields.cost_of_labour}
                        onChange={this.handleChange}
                        autocomplete="off"
                       // required
                      />
                    </div>
                  </div>
                </div>

                <div className="col_50">
                  <div className="col_100">
                    <label>
                      Actual Cost:<span style={{ color: "red" }}> *</span>
                    </label>
                  </div>
                  <div className="col_100">
                    <div className="col_100">
                      <input
                        type="text"
                        placeholder="Description"
                        name="actual_cost"
                        style={{ textTransform: "uppercase" }}
                        value={this.state.editingfields.actual_cost}
                        onChange={this.handleChange}
                        autocomplete="off"
                        //required
                      />
                    </div>
                  </div>
                </div>

                <div className="col_50">
                  <div className="col_100">
                    <label>
                      Estimation Cost:<span style={{ color: "red" }}> *</span>
                    </label>
                  </div>
                  <div className="col_100">
                    <div className="col_100">
                      <input
                        type="text"
                        placeholder="Description"
                        name="estimation_cost"
                        style={{ textTransform: "uppercase" }}
                        value={this.state.editingfields.estimation_cost}
                        onChange={this.handleChange}
                        autocomplete="off"
                        //required
                      />
                    </div>
                  </div>
                </div>

                <div className="col_50">
                  <div className="col_100">
                    <label>
                      Recovery From Insurance:
                      <span style={{ color: "red" }}> *</span>
                    </label>
                  </div>
                  <div className="col_100">
                    <div className="col_100">
                      <input
                        type="text"
                        placeholder="Description"
                        name="recovry_from_insurance"
                        style={{ textTransform: "uppercase" }}
                        value={this.state.editingfields.recovry_from_insurance}
                        onChange={this.handleChange}
                        autocomplete="off"
                       // required
                      />
                    </div>
                  </div>
                </div>

                <div className="col_50">
                  <div className="col_100">
                    <label>
                      Loss:<span style={{ color: "red" }}> *</span>
                    </label>
                  </div>
                  <div className="col_100">
                    <div className="col_100">
                      <input
                        type="text"
                        placeholder="Description"
                        name="loss"
                        style={{ textTransform: "uppercase" }}
                        value={this.state.editingfields.loss}
                        onChange={this.handleChange}
                        autocomplete="off"
                        //required
                      />
                    </div>
                  </div>
                </div>

                <div className="col_50">
                  <div className="col_100">
                    <label>
                      Major/Minor:<span style={{ color: "red" }}> *</span>
                    </label>
                  </div>
                  <div className="col_100">
                    <div className="col_100">
                      <input
                        type="text"
                        placeholder="Description"
                        name=" major_minor"
                        style={{ textTransform: "uppercase" }}
                        value={this.state.editingfields.major_minor}
                        onChange={this.handleChange}
                        autocomplete="off"
                       // required
                      />
                    </div>
                  </div>
                </div>

                <div className="col_50">
                  <div className="col_100">
                    <label>
                      Bus On Road Date:<span style={{ color: "red" }}> *</span>
                    </label>
                  </div>
                  <div className="col_100">
                    <div className="col_100">
                      <input
                        type="text"
                        placeholder="Description"
                        name=" bus_on_road_date"
                        style={{ textTransform: "uppercase" }}
                        value={this.state.editingfields.bus_on_road_date}
                        onChange={this.handleChange}
                        autocomplete="off"
                       // required
                      />
                    </div>
                  </div>
                </div>

                <div className="col_50">
                  <div className="col_100">
                    <label>
                      Planned Dates For Repair the bus:
                      <span style={{ color: "red" }}> *</span>
                    </label>
                  </div>
                  <div className="col_100">
                    <div className="col_100">
                      <input
                        type="text"
                        placeholder="Description"
                        name="planned_days_for_repair_the_bus"
                        style={{ textTransform: "uppercase" }}
                        value={
                          this.state.editingfields
                            .planned_days_for_repair_the_bus
                        }
                        onChange={this.handleChange}
                        autocomplete="off"
                       // required
                      />
                    </div>
                  </div>
                </div>

                <div className="col_50">
                  <div className="col_100">
                    <label>
                      Corrective Action:<span style={{ color: "red" }}> *</span>
                    </label>
                  </div>
                  <div className="col_100">
                    <div className="col_100">
                      <input
                        type="text"
                        placeholder="Description"
                        name="corrective_action"
                        style={{ textTransform: "uppercase" }}
                        value={this.state.editingfields.corrective_action}
                        onChange={this.handleChange}
                        autocomplete="off"
                       // required
                      />
                    </div>
                  </div>
                </div>

                <div className="col_50">
                  <div className="col_100">
                    <label>
                      Total Days Used For Repair The Bus:
                      <span style={{ color: "red" }}> *</span>
                    </label>
                  </div>
                  <div className="col_100">
                    <div className="col_100">
                      <input
                        type="text"
                        placeholder="Description"
                        name="total_days_used_for_accident_repair"
                        style={{ textTransform: "uppercase" }}
                        value={
                          this.state.editingfields
                            .total_days_used_for_accident_repair
                        }
                        onChange={this.handleChange}
                        autocomplete="off"
                       // required
                      />
                    </div>
                  </div>

                  <div className="col_50">
                    <div className="col_100">
                      <label>
                        Remarks:<span style={{ color: "red" }}> *</span>
                      </label>
                    </div>
                    <div className="col_100">
                      <div className="col_100">
                        <input
                          type="text"
                          placeholder="Description"
                          name="additional_remarks"
                          style={{ textTransform: "uppercase" }}
                          value={this.state.editingfields.additional_remarks}
                          onChange={this.handleChange}
                          autocomplete="off"
                          //required
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer style={{ borderTop: "none" }}>
              <input className="button1" type="submit" value="Update" />
            </Modal.Footer>
          </form>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps, {
  updateAccidentMasterAPI,
})(withRouter(MasterAccidentPopup));
