import React, { Component } from "react";
import "./index.scss";
class TermsAnsConditions extends Component {
  render() {
    return (
      <div className="terms_and_condition">
        <h3 className="terms_and_condition_head-main">PRIVACY POLICY</h3>
        <h3 className="terms_and_condition_head-text">EV</h3>

        <p className="terms_and_condition_head-text-3">
          EV (“we” or “us” or “our”) respects the privacy of our users (“user”
          or “you”). This Privacy Policy explains how we collect, use, disclose,
          and safeguard your information when you visit our mobile application
          (the “Application”). By installing and using this Application, you
          acknowledge and agree to all the terms stated herein and agree that
          this Application will only be construed and evaluated according to
          laws of republic of India. If you use this Application from other
          locations, you are responsible for compliance with any and all
          applicable local laws. Please read this Privacy Policy carefully. IF
          YOU DO NOT AGREE WITH THE TERMS OF THIS PRIVACY POLICY, PLEASE DO NOT
          ACCESS THE APPLICATION. VE Commercial Vehicles Limited (hereinafter
          referred to as the Company) is the owner of everything on this
          Application and no portion of this Application, including but not
          limited to the text, images, audio or video, shall be used for any
          commercial purpose. By installing this Application, you acknowledge
          and agree that any name, logo, trademark or service mark contained on
          this Application, shall not be used by you in any manner whatsoever.
          We reserve the right to make changes to this Privacy Policy at any
          time and for any reason. You are encouraged to periodically review
          this Privacy Policy to stay informed of updates. You will be deemed to
          have been made aware of, will be subject to, and will be deemed to
          have accepted the changes in any revised Privacy Policy by your
          continued use of the Application after the date such revised Privacy
          Policy is posted.
        </p>

        <p className="terms_and_condition_head-text-3">
          This Privacy Policy does not apply to any third-party online/mobile
          store from which you download and install the Application other than
          the Google Play Store and Apple Store. We are not responsible for any
          of the data collected by any such third party.
        </p>

        <h3 className="terms_and_condition_head">
          COLLECTION OF YOUR INFORMATION
        </h3>
        <p className="terms_and_condition_head-text-3">
          We may collect information about you in a variety of ways. The
          information we may collect via the Application depends on the content
          and materials you use, and includes:
        </p>

        <h3 className="terms_and_condition_head-text-2">Personal Data</h3>
        <p className="terms_and_condition_head-text-1">
          Demographic and other personally identifiable information (such as
          your name and mobile number) that you voluntarily give to us when
          choosing to participate in various activities related to the
          Application. Do not post on this Application or transmit to this
          Application, any pornographic, obscene profane, defamatory, libelous,
          threatening, unlawful or other material which could constitute or
          encourage conduct that would be considered as criminal offence, give
          rise to civil liability or otherwise violate any law or regulation.
        </p>

        <h3 className="terms_and_condition_head-text-2">
          Geo-Location Information
        </h3>
        <p className="terms_and_condition_head-text-1">
          We may request access or permission to and track location-based
          information from your electronic device, either continuously or while
          you are using the Application, to provide location-based services.
        </p>

        <h3 className="terms_and_condition_head">USE OF YOUR INFORMATION</h3>
        <p className="terms_and_condition_head-text-1">
          Having accurate information including personal data about you permits
          us to provide you with a smooth, efficient, and customized experience.
        </p>

        <h3 className="terms_and_condition_head-text">
          DISCLOSURE OF YOUR INFORMATION
        </h3>

        <p className="terms_and_condition_head-text-1">
          We may share information we have collected about you in certain
          situations. Your information may be disclosed as follows:
        </p>
        <h3 className="terms_and_condition_head-text-2">
          By Law or to Protect Rights
        </h3>
        <p className="terms_and_condition_head-text-1">
          If we believe the release of information about you is necessary to
          respond to legal process, to investigate or remedy potential
          violations of our policies, or to protect the rights, property, and
          safety of others, we may share your information as permitted or
          required by any applicable law, rule, or regulation. This includes
          exchanging information with other entities for fraud protection and
          credit risk reduction.
        </p>

        <h3 className="terms_and_condition_head-text">
          SECURITY OF YOUR INFORMATION
        </h3>
        <p className="terms_and_condition_head-text-1">
          We use administrative, technical, and physical security measures to
          help protect your personal information. While we have taken reasonable
          steps to secure the personal information you provide to us, please be
          aware that despite our efforts, no security measures are perfect or
          impenetrable, and no method of data transmission can be guaranteed
          against any interception or other type of misuse. Any information
          disclosed online is vulnerable to interception and misuse by
          unauthorized parties. Therefore, we cannot guarantee complete security
          if you provide personal information.
        </p>
        <h3 className="terms_and_condition_head-text">LIABILITIES</h3>
        <p className="terms_and_condition_head-text-1">
          No information on this Application shall constitute an invitation to
          invest in the Company or any of its affiliates. Neither the Company
          nor any of its affiliates nor their or their affiliate's officers,
          employees or agents shall be liable for any loss, damages or expense
          arising out of any access to nor use of this Application or any site/
          application linked to it, including, without limitation, any loss of
          profit, indirect, incidental or consequential loss.
        </p>

        <h3 className="terms_and_condition_head-text">POLICY FOR CHILDREN</h3>

        <p className="terms_and_condition_head-text-1">
          We do not knowingly solicit information from or market to children
          under the age of 13. If you become aware of any data we have collected
          from children under age 13, please contact us using the contact
          information provided below.
        </p>
        <h3 className="terms_and_condition_head-text">
          APPLICABLE LAWS AND JURISDICTION
        </h3>
        <p className="terms_and_condition_head-text-1">
          This Application is governed by the laws in India, you hereby consent
          to the exclusive jurisdiction and venue of courts in New Delhi, India
          in all disputes arising out of or relating to the use of the
          Application. A printed version of this policy and of any notice given
          in electronic form shall be admissible in judicial or administrative
          proceedings based upon or relating to this Application to the same
          extent and subject to the same conditions as other business documents
          and records originally generated and maintained in printed form.
        </p>

        <h3 className="terms_and_condition_head-text">CONTACT US</h3>
        <p className="terms_and_condition_head-text-1">
          If you have questions or comments about this Privacy Policy, please
          contact us at:
        </p>

        <p className="terms_and_condition_head-text-3">
          [VE Commercial Vehicle Limited]
        </p>
        <p className="terms_and_condition_head-text-1">[#96, Sector 32]</p>
        <p className="terms_and_condition_head-text-1">
          [Gurgaon,Haryana 122001]
        </p>
        {/* <h3 className="terms_and_condition_head-1">INTRODUCTIONS</h3>
        <p>
          <span className="terms_and_condition_subhead_1">IMPORTANT </span>:
          <span className="terms_and_condition_subhead_2">
            {" "}
            PLEASE READ THESE TERMS AND CONDITIONS BEFORE YOU REGISTER FOR AND
            USE "EV" MOBILE & PORTAL APPLICATION SERVICES (Eicher
            Customer means
            Customer who has purchased the Eicher Branded Vehicles
            manufactured by VECV).
          </span>
        </p>

        <p className="terms_and_condition_subhead_2">
          YOU CAN REGISTER FOR THE SERVICE THROUGH YOUR MOBILE DEVICE & PORTAL.
          BY REGISTERING FOR THE SERVICE, YOU WILL BE TAKEN TO HAVE READ,
          UNDERSTOOD AND ACCEPTED THESE TERMS AND CONDITIONS IN THE SAME
          WAY AS IF YOU HAVE SIGNED A PRINTED VERSION AND YOU WILL BE BOUND BY
          THEN AND THEY WILL CONTINUE TO APPLY
          EVERY TIME YOU USE THE SERVICES ON YOUR MOBILE DEVICE & PORTAL.
        </p>

        <p className="terms_and_condition_subhead_2">
          In these terms and conditions, "we", "our" and "us" mean VE Commercial
          Vehicles Limited (Hereinafter referred to as “ VECV”) or its
          Authorized Dealers and "you" and "your" mean the
          individual who is using the Service. These terms and conditions apply
          to your use of the EV mobile application & portal), and any
          information contained therein. If you do not agree with any of
          the terms set out herein, ou should not use the Service, and if you
          have already installed EV mobile application software (the
          "Application"), you should uninstall the Application from your
          mobile device and portal.
        </p>

        <p>
          <span className="terms_and_condition_subhead_3">Services:</span>
          <br />
          <p className="terms_and_condition_subhead_4">
            1. Registration in App (Login Creation and Vehicle addition to a
            login)
            <br />
            2. Service Booking with booked service history viewing
            <br />
            3. Complaints creation with booked complaints history viewing
            <br />
            4. Service History viewing (Summary and Detailed)
            <br />
            5. Vehicle Details viewing
            <br />
            6. Provision to upload important documents under various category
            <br />
            7. Access Eicher Live services
            <br />
            8. View and locate dealer locations on map view
            <br />
            9. View various maintenance tips shared by VECV
            <br />
            10.View VECV important office address and contact numbers
            <br />
            11.View important product circulars & communications from VECV
          </p>
        </p>
        <span className="terms_and_condition_subhead_3">
          1. The Application:
        </span>

        <p className="terms_and_condition_subhead_2">
          As consideration for your continuing compliance with an agreement to
          be bound by the Terms and Conditions, we hereby grant and you hereby
          accept a limited, non-exclusive, non-transferable, non-assignable use
          of the Application (which shall include future updates made available
          to you from time to time, provided you understand that such updates
          may be subject to additional terms in respect of which you will be
          notified at the time such update is made available) to access your
          VECV vehicle account from your mobile device/portal, subject to the
          Terms and Conditions.
        </p>

        <p className="terms_and_condition_subhead_5">
          You understand, acknowledge and agree that:
        </p>
        <p className="terms_and_condition_subhead_4">
          1. You must be a VECV customer with a legally purchased VECV vehicle.
          <br />
          2. You may only use the Application on mobile devices and operating
          systems that are authorized/recognized by VECV as being eligible to
          receive the Service.
          <br />
          3. You will only use the Service and the Application solely and
          exclusively for your own personal, private, non-commercial use and on
          registered mobile devices belonging to you.
          <br />
          4. You will at all times comply with and be bound by the Terms and
          Conditions.
          <br />
          5. You will at all times comply with (and your use of the Service and
          the Application will at all times be in compliance with) all
          applicable laws, rules and regulations, including, without limitation,
          and you will not allow any other party to use the Service or the
          Application for or in connection with any illegal purpose or activity.
          The Application is not intended for distribution or use by any person
          in any jurisdiction or country where such use and/or distribution
          would be contrary to applicable laws, rules and/or regulations. It is
          your responsibility to comply with all applicable rules and/or
          regulations.
          <br />
          6. You may only use the Application for the purpose of accessing 
          EV Application, its Authorized Dealers, its Affiliates or its
          Licensors services and for no other purpose whatsoever.
          <br />
          7. The Application is licensed only to you in accordance with the
          terms hereof and for the limited purposes set out herein.
          <br />
          8. Use of the Service (including the Application) is at your own risk
          and you are solely responsible for the use of any data, information or
          services obtained through the use of the Service.
          <br />
          9. The Service is provided as a convenience to you. The information
          accessible through the Service is more limited than the information
          available with the VECV or its AUTHORIZED DEALERS in electronic or
          hard form, and does not constitute an official record for the
          information to which it pertains.
          <br />
          10. You will comply at all times with all technical and security
          requirements which we establish regarding the Service.
          <br />
          11.In the event that you fail to comply with the Terms and Conditions
          or you are no longer the owner of a VECV vehicle, all rights granted
          to you in relation to the Application and the Service shall
          immediately come to an end and you will immediately uninstall the
          Application and destroy all copies of the Application. VECV, its
          affiliates, its licensors or its Authorized Dealers shall not be
          responsible in any way for the use of the Application by you after you
          cease to be an owner of the VECV vehicle."
        </p>
        <p>
          <p className="terms_and_condition_subhead_5">You will not:</p>
          <p className="terms_and_condition_subhead_4">
            1. Alter, modify, adapt or translate the whole or any part of the
            Application, or any other computer software made accessible to you
            or otherwise used by you to access and use the Service, or perform
            maintenance on the Application, nor will you duplicate, compile,
            disseminate, reverse engineer, disassemble, decompile, disable any
            features, or otherwise derive the source code for the Application,
            or any component of the Application, in whole or in part.
            <br />
            2. Merge the Application or any component thereof into any other
            programs or create derivative works based on any component of the
            Application.
            <br />
            3. Use the Application in whole or in part or any confidential
            information relating thereto to create software that is functionally
            equivalent to the Application or any part thereof.
            <br />
            4. License, sublicense, sell, disseminate, broadcast, transmit, or
            otherwise distribute the Application or the Service in any form or
            by any means, or otherwise transfer, assign, manipulate, or grant
            any rights of use or any other rights in respect of the Application
            or the Service, or any part thereof, or the information contained
            therein, to any other party.
            <br />
            5. Copy the Application or the information accessible through the
            Service onto any public or publicly accessible network, or make any
            additional copies of or reproduce in any way the whole or any part
            of the Application or the Service whatsoever.
            <br />
            6. Remove or tamper with any proprietary notices or labels,
            including copyright notices or data source attributions, attached to
            or contained within the Application or the Service
            <br />
            7. Use the Application or the Service in any way that may lead to
            the encouragement, procurement or carrying out of any unlawful or
            criminal activity or which may cause any harm or injury to any
            person.
            <br />
            8. Cause damage to any of the websites, servers, systems or
            equipment used in the provision of the Service by VECV, its
            Authorized Dealers or any other third party, and you may not access
            or attempt to access any user data or to penetrate any of the
            security measures relating to the Service; and will in all cases
            indemnify and hold harmless VECV, its affiliates and its Authorized
            Dealers from any and all Claims in respect thereof.
          </p>
        </p>

        <span className="terms_and_condition_subhead_3">2. Access:</span>
        <p className="terms_and_condition_subhead_2">
          You understand and agree that you are solely responsible for all
          orders, instructions and information entered through or electronically
          transmitted to us through the Service using your CHASSIS or
          Registration number, sign-on password, and/or mobile number, and you
          accept that all such orders, instructions and data transmissions are
          undertaken at your own risk. Neither VECV, nor any of its affiliates
          or licensors, nor the Authorized Dealers of VECV is liable for any
          error made by you in entering any details or providing any orders or
          instructions when you use the Service. You agree that VECV and its
          Authorized Dealers shall be entitled to rely upon orders and
          instructions received when entered using your CHASSIS or Registration
          number, sign-on password. You understand that once your CHASSIS or
          Registration Number, sign-on password have been entered in a given
          session, no additional passwords are required for availing the
          Services, and you will take all necessary precautions to prevent
          unauthorized access to your VECV account(s). VECV or its Authorized
          Dealers and employees may rely on all instructions and any other
          communications given or entered by you or anyone else using your VECV
          account, and you will be bound by any agreement entered into an act
          undertaken by VECV, its Licensors, its Affiliates or its Authorized
          Dealers in reliance on such orders, instructions and communications.
          Without limiting the generality of the foregoing, you will be
          responsible for all instructions received by VECV, its Licensors, its
          Affiliates or its
          <br />
          <br />
          Authorized Dealers between the time you first pass the secured log-in
          procedure until the time you log-out of the Application. Please note
          that this includes any input errors sent by anyone else. Please do not
          leave your mobile device unattended while you remain logged into the
          Application and delete the password (which remains saved as a
          functionality) after you have logged out.
          <br />
          <br />
          You must always keep confidential, and will take all necessary
          precautions to ensure the confidentiality and security of all of your
          access credentials, including your CHASSIS or Registration number,
          passwords, and will take all necessary precautions to prevent
          unauthorized access to the Service. You will NOT divulge your Access
          Credentials to anyone, - including family members, friends, employees,
          bookkeepers or anyone else.
        </p>
        <p>
          <span className="terms_and_condition_subhead_5">You will NOT:</span>
          <p className="terms_and_condition_subhead_4">
            1. write your Access Credentials down.
            <br />
            2. Keep a poorly disguised record of them."
            <br />
            3. Save your Access Credentials in your mobile device.
            <br />
            4. Respond to text messages, pop-ups, emails or other Internet
            requests that ask you to reveal personal information about yourself
            or your VECV account. WE WILL NEVER SEND YOU UNSOLICITED TEXT
            MESSAGES OR EMAILS ASKING FOR YOUR ACCESS CREDENTIALS. We will never
            ask you to validate or restore your account or EV mobile
            application access through unsolicited text messages or emails. VECV
            and its Affiliates shall not be made liable if in case your
            credentials are misused by any person, as the safe – keeping of
            credentials is solely your responsibility.
            <br />
            <br />
          </p>
          <p className="terms_and_condition_subhead_2">
            In the event that you suspect or immediately upon becoming aware
            that someone else knows or may have unauthorized access to your
            Access Credentials, or in the event of any other attempted or actual
            unauthorized access to the Service, or the loss, theft, misuse or
            compromise of any of your Access Credentials, you will immediately
            change your credentials or notify VECV representative.
            <br />
            <br />
            You must review your instructions, communications, appointment
            details and information shared by you on a regular basis and report
            any errors in a timely manner. You will notify VECV or its
            Authorized Dealers immediately upon receipt of any confirmations of
            acts or appointments that were not placed by you or someone whom you
            have authorized to do or book in your account(s), and in the event
            that you do not receive confirmations for the acts or appointments
            that you did place."
          </p>
        </p>
        <span className="terms_and_condition_subhead_3">3. Title:</span>
        <p className="terms_and_condition_subhead_2">
          You understand, acknowledge and agree that you acquire no right, title
          or interest whatsoever in or to the Application or the Service, or to
          VECV as either of them exist now or in the future, and all ownership,
          rights, title, interest and intellectual property rights in the
          Application and the Service, including, but not limited to, all trade
          names, and any and all copyright, patents, trademarks, and service
          marks relating thereto, are and shall remain the property of VECV, its
          affiliates and/or its licensors, to the extent applicable, and nothing
          herein shall be construed as granting any rights therein to you.
        </p>
        <span className="terms_and_condition_subhead_3">
          4. Disclaimer of Warranties and Availability:
        </span>
        <p className="terms_and_condition_subhead_2">
          You understand and agree that the Service and the Application are
          provided as is and as available, and all warranties, representations
          and guarantees, express or implied, are hereby disclaimed. Without
          limiting the generality of the foregoing, any and all warranties of
          merchantability, fitness for a particular purpose, suitability,
          reliability, accuracy, performance, compatibility or non-infringement,
          or that the Service or the Application will be free from defects,
          errors, viruses or harmful programs, or be of a satisfactory quality,
          are hereby expressly disclaimed.
          <br />
          <br />
          Neither VECV, its affiliates, its licensors, its sub licensors, its
          Authorized Dealers nor any other third party provider makes any
          warranty, representation or guarantee as to the accessibility,
          reliability, communication links, accuracy, truth, timeliness, or
          completeness of any Information, or other information or data
          furnished through the Service, or that any such Information
          disseminated may be solely relied upon for doing any act or other
          purposes. You acknowledge that it is your responsibility to verify any
          Information that you elect to use when taking any action.
          <br />
          <br />
          You acknowledge that it is not possible for us to provide the Service
          free from defects. The Service may be delayed, unavailable, not
          delivered and/or inaccurate from time to time due to a variety of
          factors, including technical reasons, for planned or unplanned
          maintenance or downtime or for any other reason. The Service may be
          adversely affected by the performance of and any outages on mobile
          device networks, or when you are not in an area of mobile coverage, or
          other factors beyond the control of VECV or any of its affiliates or
          its licensors or its Authorized Dealers or any third party, and
          neither VECV, nor its affiliates nor its licensors nor its Authorized
          Dealers will be liable therefore.
        </p>
        <span className="terms_and_condition_subhead_3">5. Information:</span>
        <p className="terms_and_condition_subhead_2">
          We provide information, including but not limited to (SERVICES TO BE
          ADDED), service tips, driving tips, maintenance tips, alerts,
          indicators, service due reminders, vehicle finder etc. (collectively,
          the Information) to you under license from various exchanges and
          information providers. This Information is indicative only. You may
          not redistribute or re-sell any Information which you obtain through
          the app. All intellectual property rights owned by third parties in
          any Information provided by third parties shall at all times remain
          the property of such third parties. Your use of such Information is
          subject to the terms and conditions of each applicable third party or
          exchange data provider. We accept no liability for any Information
          provided by third parties and make no representations or warranties
          that such Information will be accurate or timely. The Service, the
          Application and all Information contained therein is and remains our
          property and/or the property of our third party licensors. The
          information and record provided vide this platform is solely for your
          personal reference, records and convenience. The information shall not
          be circulated openly and the user shall maintain the information in a
          very restricted manner.
        </p>
        <span className="terms_and_condition_subhead_3">
          6. Account Activity:
        </span>
        <p className="terms_and_condition_subhead_2">
          Instructions placed through the Service or service appointments and
          service requests made through the Service will be reflected in the
          data maintained by the Authorized Dealers of VECV in the same manner
          as the data maintained in electronic or hard form by telephonic or
          physical service requests. This App only grants access to the records
          available in database of VECV and its Authorized Dealers and that non-
          availability of record shall put Dealers and that non-availability of
          record shall put VECV under only obligation / liability. You shall not
          assume that any appointment has been confirmed until you have received
          a confirmation of such appointment transaction.
        </p>
        <span className="terms_and_condition_subhead_3">
          7. Termination and Modification:
        </span>
        <p className="terms_and_condition_subhead_2">
          You understand, acknowledge and agree that VECV or its Authorized
          Dealers may in its sole discretion, at any time:
          <p className="terms_and_condition_subhead_4">
            1. Modify any part of the Service and/or change the terms and
            conditions upon which the Service is provided to you.
            <br />
            2. Discontinue or terminate the Service in part or in its entirety.
            <br />
            3. Without notice terminate or otherwise limit your access to the
            Service at any time for any reason, including, without limiting the
            generality of the foregoing, in the event that we believe that you
            are in breach of or have failed to comply with any of the Terms and
            Conditions or in the event that VECV or its Authorized Dealers
            believes that you are using the Service illegally, fraudulently or
            improperly, in which event all rights granted to you hereunder shall
            immediately cease without notice to you. Termination of your access
            to the Service shall not alleviate any continuing or unfulfilled
            obligations which you may have under the Terms and Conditions.
          </p>
        </p>
        <span className="terms_and_condition_subhead_3">
          8. Important Limitation on Liabilities:
        </span>
        <p className="terms_and_condition_subhead_2">
          You are sole and exclusive remedy against VECV, its affiliates, its
          licensors, its Authorized Dealers and any other third party provider
          of services for the mobile app/portal, with respect to these terms and
          conditions, the Service, the Application, and any Information, and any
          errors, inaccuracy, omissions or delay therein or thereof, shall be
          limited to the issuance of corrected information as soon as reasonably
          practicable following VECV and receipt of written notice of such
          problem from you.
          <br />
          <br />
          YOU ACKNOWLEDGE THAT THE SERVICE AND THE APPLICATION UTILIZE COMPLEX
          COMPUTER AND TELECOMMUNICATIONS NETWORKS AND THAT, AS SUCH, CONTINUED,
          UNINTERRUPTED AND ERROR FREE ACCESS TO THE SERVICE AND/OR THE
          APPLICATION CANNOT BE GUARANTEED. IN LIGHT OF THE FOREGOING, NEITHER
          VECV., ITS AFFILIATES, ITS LICENSORS, ITS AUTHORIZED DEALERS NOR ANY
          OTHER THIRD PARTY PROVIDER SHALL BE LIABLE IN ANY WAY FOR ANY LOSS OR
          DAMAGE, DIRECT OR INDIRECT, WHICH MAY ARISE OUT OF ANY OBLIGATION OF
          VECV OR ITS AFFILIATES OR ITS LICENSORS OR ITS AUTHORIZED DEALERS
          UNDER THIS AGREEMENT, FROM ANY OUTAGE, INTERRUPTION, DISCONTINUATION
          OR DELAY IN THE SERVICE OR THE APPLICATION OR ANY PART THEREOF OR ANY
          ERROR CONTAINED THEREIN, OR FROM ANY OTHER NON-PERFORMANCE, DEFECTIVE
          PERFORMANCE OR LATE PERFORMANCE DUE TO ANY CAUSE WHATSOEVER, INCLUDING
          ERRORS DUE TO MALFUNCTION OF EQUIPMENT, PROGRAMS OR OPERATIONS OF
          VECV, ITS AFFILIATES, ITS LICENSORS, ITS AUTHORIZED DEALERS OR ANY
          THIRD PARTIES, OR NEGLIGENCE OF VECV., ITS AFFILIATES, ITS LICENSORS,
          ITS AUTHORIZED DEALERS OR THIRD PARTY PROVIDERS, OPERATORS OR
          PROGRAMMERS. IN NO EVENT WILL VECV, ITS AFFILIATES, ITS LICENSORS, ITS
          AUTHORIZED DEALERS OR ANY THIRD PARTY PROVIDER BE LIABLE FOR ANY
          PUNITIVE, INCIDENTAL DIRECT, INDIRECT, SPECIAL, EXEMPLARY OR
          CONSEQUENTIAL DAMAGES, INCLUDING, BUT NOT LIMITED TO, LOSS OF USE,
          LOSS OF DATA, LOSS OF OPPORTUNITY, HOWEVER CAUSED, RELATED TO, ARISING
          OUT OF OR IN CONNECTION WITH YOUR USE OF, RELIANCE ON, OR INABILITY TO
          ACCESS OR USE THE SERVICE, THE APPLICATION OR THE INFORMATION, OR FOR
          ANY DELAY IN OR FAILURE OF TRANSMISSION OF ANY INSTRUCTIONS OR
          NOTIFICATIONS SENT TO US THROUGH THE SERVICE EVEN IF SUCH PARTY KNEW,
          SHOULD HAVE KNOWN OR WAS ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
        </p>
        <span className="terms_and_condition_subhead_3">9. General:</span>
        <p className="terms_and_condition_subhead_2">
          These terms and conditions will be governed by and construed in
          accordance with the laws of India. You agree that only courts at New
          Delhi will have exclusive jurisdiction for the determination of any
          matters arising out of these terms and conditions. This Application is
          for citizens of India only. You agree that no joint venture,
          partnership, employment, or agency relationship exists between you and
          VEC or its Authorized Dealers as a result of this agreement or use of
          this Application. VECV or its Authorized Dealers performance of this
          agreement is subject to existing laws and legal process, and nothing
          contained in this agreement is in derogation of VECV or its Authorized
          Dealers’ right to comply with governmental, court and law enforcement
          requests or requirements relating to your use of this Application or
          information provided to or gathered by VECV or its Authorized Dealers
          with respect to such use. Performance of any obligation hereunder by
          VECV or its Authorized Dealers shall be excused if prevented by acts
          of God, public enemy, fire or other casualty, labor dispute,
          communications, computer, software, hardware or equipment failure, act
          or omission of any market center, utility, communications service,
          common carrier, Internet or network access or backbone provider or
          information provider, or any other circumstances beyond VECV or its
          Authorized Dealer reasonable control Telephone calls, use of the
          Service and communication between us, via messaging or any electronic
          mode including via email, WhatsApp, etc., may be recorded. These
          recordings will be our sole property and may be used for training and
          commercial purposes or as evidence in the event of a dispute. The
          Application shall be used / operated only the registered owner of a
          VECV vehicle or by a person doing so on directions of the Registered
          Owner of VECV Vehicle. Any person using the App on behalf of the
          registered Owner of a VECV Vehicle shall be presumed to be working
          under direct authority of the Owner. All acts done by any such person
          shall be deemed to be done by the Registered Owner himself and that
          all such acts shall be entirely at the account / responsibility of the
          Registered Owner. If you have any queries in relation to the Terms and
          Conditions or the Service, please contact us at 18001023531 If any
          provision of this Agreement is declared to be invalid or unenforceable
          under any applicable law, the validity or enforceability of the
          remaining provisions contained herein shall not be affected in any
          manner whatsoever, and any such invalid or unenforceable provision
          shall be deemed to be severable and ineffective only to the extent
          necessary to render the remaining portions of these terms and
          conditions valid and enforceable. You acknowledge and agree that you
          have read and understood, and agree to be bound by, the Terms and
          Conditions, including, without limitation, the terms and conditions
          contained herein, and the provisions herein that limit the liability
          of VECV, its Licensors or its Affiliates or its Authorized Dealers for
          damages, including, without limitation, damages caused by, arising
          from or in connection with technical errors affecting the Service. You
          and we have expressly requested that these terms and conditions and
          all related documents, including notices, be drawn up only in English.
          <br />
          <br />
          These terms and conditions form a contract between you and us in
          respect of the Service. For your own benefit and protection you should
          read the terms and conditions set out herein carefully before you
          start to use the Service.
          <br />
          <br />
          All Trademarks and Copyrights owned by VECV
        </p> */}
        {/* <span className="terms_and_condition_subhead_3">
           Privacy Policy:
        </span> */}
        {/* <p className="terms_and_condition_subhead_2">
          VECV Statement of Privacy at VECV we take your privacy seriously.
          Please read the following to learn more about our terms and
          conditions.
        </p>
        <span className="terms_and_condition_subhead_5">
          What the terms and conditions cover:
        </span>
        <p className="terms_and_condition_subhead_2">
          This covers VECV treatment of personally identifiable information that
          VECV collects when you use the EV application and when you use
          our services. This policy also covers VECV treatment of any personally
          identifiable information that VECV shares with you. This policy does
          not apply to the practices of Companies that VECV does not own or
          control or VECV does not own or employ or manage.
        </p>
        <span className="terms_and_condition_subhead_5">
          Information Collection and Use:
        </span>
        <p className="terms_and_condition_subhead_2">
          VECV collects personally identifiable information when you register
          for the VECV account, when you choose the services and promotions.
          VECV may also receive personally identifiable information from our
          business partners. When you are registered as VECV CUSTOMER, we ask
          for your name, birth date, anniversary date occupation, PAN, Address
          only for verification and records purpose. Once you register with VECV
          and sign in to our services, you are not anonymous to us. VECV uses
          information for three general purposes: to fulfill your requests for
          certain products and services and to contact you about specials and
          new products.
        </p>
        <span className="terms_and_condition_subhead_5">
          Information Sharing and Disclosure:
        </span>
        <p className="terms_and_condition_subhead_2">
          VECV will not sell or rent your Personally Identifiable Information to
          anyone. VECV will send Personally Identifiable Information about you
          when:
        </p>
        <p className="terms_and_condition_subhead_2">
          We have consent to share the information.
        </p>
        <p className="terms_and_condition_subhead_2">
          We need to share your information to provide the product or service
          you have requested. We respond to subpoenas, court orders or legal
          process. When we find your action on the mobile application violates
          VECV terms and condition or any of your usage guidelines for specific
          products or services.
        </p>
        <span className="terms_and_condition_subhead_5">Security:</span>
        <p className="terms_and_condition_subhead_2">
          Your VECV CUSTOMER account information is password-protected for your
          privacy and security. We have taken adequate measures to secure access
          to your personal data.
        </p>
        <span className="terms_and_condition_subhead_5">
          Changes to this Policy:
        </span>
        <p className="terms_and_condition_subhead_2">
          VECV may edit this policy from time to time. If we make any
          substantial changes, we will notify you by posting a prominent
          announcement on the Application. However, it shall be the obligation
          of the user to remain updated with the changes in Terms and
          Conditions. Even in case the Terms and Conditions are not notified by
          VECV, the contents therein shall be binding on the user.
        </p> */}
      </div>
    );
  }
}

export default TermsAnsConditions;
