const tripMasterDetailsApiActions = {
  GET_TRIPMASTERDETAILS: "GET_TRIPMASTERDETAILS",
  GET_TRIPMASTERDETAILS_SUCCESS: "GET_TRIPMASTERDETAILS_SUCCESS",
  GET_TRIPMASTERDETAILS_FAILURE: "GET_TRIPMASTERDETAILS_FAILURE",

  getTripMasterDetailsAPI: (data) => ({
    type: tripMasterDetailsApiActions.GET_TRIPMASTERDETAILS,
    payload: { data },
  }),

  getTripMasterDetailsAPISuccess: (saveStatusCode, result) => ({
    type: tripMasterDetailsApiActions.GET_TRIPMASTERDETAILS_SUCCESS,
    saveStatusCode,
    result,
  }),

  getTripMasterDetailsAPIFailure: (saveStatusCode, message) => ({
    type: tripMasterDetailsApiActions.GET_TRIPMASTERDETAILS_FAILURE,
    saveStatusCode,
    message,
  }),

  GET_TRIPSUBMISSIONDETAILS: "GET_TRIPSUBMISSIONDETAILS",
  GET_TRIPSUBMISSIONDETAILS_SUCCESS: "GET_TRIPSUBMISSIONDETAILS_SUCCESS",
  GET_TRIPSUBMISSIONDETAILS_FAILURE: "GET_TRIPSUBMISSIONDETAILS_FAILURE",

  getTripSubmissionDetailsAPI: (data) => ({
    type: tripMasterDetailsApiActions.GET_TRIPSUBMISSIONDETAILS,
    payload: { data },
  }),

  getTripSubmissionDetailsAPISuccess: (saveStatusCode, result) => ({
    type: tripMasterDetailsApiActions.GET_TRIPSUBMISSIONDETAILS_SUCCESS,
    saveStatusCode,
    result,
  }),

  getTripSubmissionDetailsAPIFailure: (saveStatusCode, message) => ({
    type: tripMasterDetailsApiActions.GET_TRIPSUBMISSIONDETAILS_FAILURE,
    saveStatusCode,
    message,
  }),



  GET_TRIPMASTERSUBMISSION: "GET_TRIPMASTERSUBMISSION",
  GET_TRIPMASTERSUBMISSION_SUCCESS: "GET_TRIPMASTERSUBMISSION_SUCCESS",
  GET_TRIPMASTERSUBMISSION_FAILURE: "GET_TRIPMASTERSUBMISSION_FAILURE",

  getTripMasterSubmissionAPI: (data) => ({
    type: tripMasterDetailsApiActions.GET_TRIPMASTERSUBMISSION,
    payload: { data },
  }),

  getTripMasterSubmissionAPISuccess: (saveStatusCode, result) => ({
    type: tripMasterDetailsApiActions.GET_TRIPMASTERSUBMISSION_SUCCESS,
    saveStatusCode,
    result,
  }),

  getTripMasterSubmissionAPIFailure: (saveStatusCode, message) => ({
    type: tripMasterDetailsApiActions.GET_TRIPMASTERSUBMISSION_FAILURE,
    saveStatusCode,
    message,
  }),




  GET_TRIPMASTERHISTORY: "GET_TRIPMASTERHISTORY",
  GET_TRIPMASTERHISTORY_SUCCESS: "GET_TRIPMASTERHISTORY_SUCCESS",
  GET_TRIPMASTERHISTORY_FAILURE: "GET_TRIPMASTERHISTORY_FAILURE",

  getTripMasterHistoryAPI: (data) => ({
    type: tripMasterDetailsApiActions.GET_TRIPMASTERHISTORY,
    payload: { data },
  }),

  getTripMasterHistoryAPISuccess: (saveStatusCode, result) => ({
    type: tripMasterDetailsApiActions.GET_TRIPMASTERHISTORY_SUCCESS,
    saveStatusCode,
    result,
  }),

  getTripMasterHistoryAPIFailure: (saveStatusCode, message) => ({
    type: tripMasterDetailsApiActions.GET_TRIPMASTERHISTORY_FAILURE,
    saveStatusCode,
    message,
  }),
};

export default tripMasterDetailsApiActions;
