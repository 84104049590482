// import React, { Component } from "react";
// import { connect } from "react-redux";
// import { withRouter } from "react-router";
// import moment from "moment";
// import ReactLoader from "../../component/react-loader/react-loader.component";
// import "./index.scss";
// //import DashboardVehicletenDays from "../dashboard_vehicle_tendays";

// import {
//   ResponsiveContainer,
//   BarChart,
//   CartesianGrid,
//   XAxis,
//   YAxis,
//   Bar,
//   Tooltip,
//   Label,
// } from "recharts";

// import fleetUptimeMangementApiActions from "../../redux/uptime_mangement/actions";
// const { getFleetUptimeHistoricgraphAPI } = fleetUptimeMangementApiActions;

// var startTimestamp = new Date();
// startTimestamp.setDate(startTimestamp.getDate() - 5);
// startTimestamp.setHours(0);
// startTimestamp.setMinutes(0);
// startTimestamp.setSeconds(0);

// var endTimestamp = new Date();
// endTimestamp.setHours(23);
// endTimestamp.setMinutes(59);
// endTimestamp.setSeconds(58);

// class HistoricGraphView extends Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       data: [],
//       start_date: startTimestamp,
//       end_date: endTimestamp,
//       FA_vehicleCount:"",
//     };
//   }
//   componentDidMount() {
//      //console.log("calledc")
//     this.getFleetUptimeGraphDetails();
//   }

//   componentWillReceiveProps(nextProps) {
//     // console.log(
//     //   "inside temp",
//     //   nextProps
//     // );
//     if (
//       nextProps?.FleetUptimeMangementApi?.FleetUptimeHistoricGraph?.result?.data
//         ?.responseData
//     ) {
//       this.setState({
//         data: nextProps.FleetUptimeMangementApi?.FleetUptimeHistoricGraph
//           ?.result?.data?.responseData,
//       });
//     }

//     if (
//       nextProps?.dashboardStatusCountsApi?.dashboardStatusCountsApi?.result
//         ?.data?.responseData.fleetAvailablity
//     ) {
//       this.setState({
//         FA_vehicleCount:
//           nextProps?.dashboardStatusCountsApi?.dashboardStatusCountsApi
//             ?.result?.data?.responseData.fleetAvailablity.operatingVehicle
//       });
//     }
//   }

//   getFleetUptimeGraphDetails = () => {
//     const { start_date, end_date } = this.state;
//     let payLoad = {
//       user_id: sessionStorage.getItem("userId"),
//       customerId: sessionStorage.getItem("customerId"),
//       start_timestamp: moment(start_date).format("YYYY-MM-DD HH:mm:ss"),
//       end_timestamp: moment(end_date).format("YYYY-MM-DD HH:mm:ss"),
//     };

//     this.props.getFleetUptimeHistoricgraphAPI(payLoad);
//   };

//   render() {
//     // let dataJson = [
//     //   { regNo: "GJ01DF2134", value: 1, vehicleId:1 },
//     //   { regNo: "GJ01DF2135", value: 2, vehicleId:21 },
//     //   { regNo: "GJ01DF2136", value: 3, vehicleId:31 },
//     //   { regNo: "GJ01DF21347", value: 4, vehicleId:41 },
//     //   { regNo: "GJ01DF2138", value: 5, vehicleId:5 }]
//     //   { regNo: "GJ01DF2139", value: 6 },
//     //   { regNo: "GJ01DF2140", value: 7 },
//     //   { regNo: "GJ01DF2141", value: 8 },
//     //   { regNo: "GJ01DF2142", value: 9 },
//     //   { regNo: "GJ01DF2143", value: 10 },
//     //   { regNo: "GJ01DF2144", value: 11 },
//     //   { regNo: "GJ01DF2145", value: 12 },
//     //   { regNo: "GJ01DF2146", value: 13 },
//     //   { regNo: "GJ01DF2147", value: 14 },
//     //   { regNo: "GJ01DF2148", value: 15 },
//     //   { regNo: "GJ01DF2149", value: 16 },
//     //   { regNo: "GJ01DF2150", value: 17 },
//     //   { regNo: "GJ01DF2151", value: 18 },
//     //   { regNo: "GJ01DF2152", value: 19 },
//     //   { regNo: "GJ01DF2153", value: 20 },
//     //   { regNo: "GJ01DF2154", value: 21 },
//     //   { regNo: "GJ01DF2155", value: 22 },
//     //   { regNo: "GJ01DF2156", value: 23 },
//     //   { regNo: "GJ01DF2157", value: 24 },
//     //   { regNo: "GJ01DF2158", value: 25 },
//     //   { regNo: "GJ01DF2159", value: 26 },
//     //   { regNo: "GJ01DF2160", value: 27 },
//     //   { regNo: "GJ01DF2161", value: 28 },
//     //   { regNo: "GJ01DF2162", value: 29 },
//     //   { regNo: "GJ01DF2163", value: 30 },
//     //   { regNo: "GJ01DF2164", value: 31 },
//     //   { regNo: "GJ01DF2165", value: 32 },
//     //   { regNo: "GJ01DF2166", value: 33 },
//     //   { regNo: "GJ01DF2167", value: 34 },
//     //   { regNo: "GJ01DF2168", value: 35 },
//     //   { regNo: "GJ01DF2169", value: 36 },
//     //   { regNo: "GJ01DF2170", value: 37 },
//     //   { regNo: "GJ01DF2171", value: 38 },
//     //   { regNo: "GJ01DF2172", value: 39 },
//     //   { regNo: "GJ01DF2173", value: 40 },
//     // ];
//     //const { data } = this.state;
//     //console.log("data", this.state.data);
//     return (
//       <div className="BarChart">
//         {this.props.FleetUptimeMangementApi?.FleetUptimeHistoricGraph
//           ?.loading ? (
//           <div className="loader-overlay">
//             <div className="loader-container">
//               <ReactLoader />
//             </div>
//           </div>
//         ) : null}
//         <ResponsiveContainer
//           //  width={950} height={400}
//           width="58.6%"
//           // margin-top= "10%"
//           //  height={10}
//           // height="100%"
//           // width={950} height={40}

//           aspect={3}
//         >
//           <BarChart
//             data={this.state.data}
//             margin={{ top: 20, right: 0, left: 10, bottom: 20 }}
//           >
//             <CartesianGrid stroke="#ccc" />
//             <XAxis
//               dataKey="regNo"
//               style={{ fontFamily: "roboto-regular" }}
//               tickSize={100}
//               ticks={[
//                 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18,
//                 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34,
//                 35, 36, 37, 38, 39, 40,
//               ]}
//               domain={[1, 100]}
//               tickCount={100}
//             >
//               <Label
//                 angle={0}
//                 position="bottom"
//                 style={{
//                   textAnchor: "bottom",
//                   marginTop: "20px",
//                   FontFace: "roboto-regular",
//                 }}
//               >
//                 Vehicles operated in selected period : {this.state.FA_vehicleCount}
//               </Label>
//             </XAxis>
//             <YAxis
//               dataKey="value"
//               label={{
//                 value: "No. of KMS",
//                 angle: -90,
//                 position: "insideLeft",
//               }}
//               style={{ fontFamily: "roboto" }}
//             />
//             <Tooltip />
//             <Bar type="monotone" dataKey="value" fill="#4b85f7" />
//           </BarChart>
//         </ResponsiveContainer>
//       </div>
//     );
//   }
// }

// const mapStateToProps = (state) => {
//   return {
//     ...state,
//   };
// };

// export default connect(mapStateToProps, {
//   getFleetUptimeHistoricgraphAPI,
// })(withRouter(HistoricGraphView));

import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import moment from "moment";
import ReactLoader from "../../component/react-loader/react-loader.component";
import "./index.scss";
//import DashboardVehicletenDays from "../dashboard_vehicle_tendays";

import {
  ResponsiveContainer,
  BarChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Bar,
  Tooltip,
  Label,
  Text,
} from "recharts";

import fleetUptimeMangementApiActions from "../../redux/uptime_mangement/actions";
const { getFleetUptimeHistoricgraphAPI } = fleetUptimeMangementApiActions;

var startTimestamp = new Date();
startTimestamp.setDate(startTimestamp.getDate() - 5);
startTimestamp.setHours(0);
startTimestamp.setMinutes(0);
startTimestamp.setSeconds(0);

var endTimestamp = new Date();
endTimestamp.setHours(23);
endTimestamp.setMinutes(59);
endTimestamp.setSeconds(58);

class HistoricGraphView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      start_date: startTimestamp,
      end_date: endTimestamp,
      FA_vehicleCount: "",
    };
  }
  componentDidMount() {
    //console.log("calledc")
    this.getFleetUptimeGraphDetails();
  }

  componentWillReceiveProps(nextProps) {
    // console.log(
    //   "inside temp",
    //   nextProps
    // );
    if (
      nextProps?.FleetUptimeMangementApi?.FleetUptimeHistoricGraph?.result?.data
        ?.responseData
    ) {
      this.setState({
        data: nextProps.FleetUptimeMangementApi?.FleetUptimeHistoricGraph
          ?.result?.data?.responseData,
      });
    }

    if (
      nextProps?.dashboardStatusCountsApi?.dashboardStatusCountsApi?.result
        ?.data?.responseData.fleetAvailablity
    ) {
      this.setState({
        FA_vehicleCount:
          nextProps?.dashboardStatusCountsApi?.dashboardStatusCountsApi?.result
            ?.data?.responseData.fleetAvailablity.operatingVehicle,
      });
    }
  }

  getFleetUptimeGraphDetails = () => {
    const { start_date, end_date } = this.state;
    let payLoad = {
      user_id: sessionStorage.getItem("userId"),
      customerId: sessionStorage.getItem("customerId"),
      start_timestamp: moment(start_date).format("YYYY-MM-DD HH:mm:ss"),
      end_timestamp: moment(end_date).format("YYYY-MM-DD HH:mm:ss"),
    };

    this.props.getFleetUptimeHistoricgraphAPI(payLoad);
  };

  render() {
    // let dataJson = [
    //   { regNo: "GJ01DF2134", value: 1, vehicleId:1 },
    //   { regNo: "GJ01DF2135", value: 2, vehicleId:21 },
    //   { regNo: "GJ01DF2136", value: 3, vehicleId:31 },
    //   { regNo: "GJ01DF21347", value: 4, vehicleId:41 },
    //   { regNo: "GJ01DF2138", value: 5, vehicleId:5 }]
    //   { regNo: "GJ01DF2139", value: 6 },
    //   { regNo: "GJ01DF2140", value: 7 },
    //   { regNo: "GJ01DF2141", value: 8 },
    //   { regNo: "GJ01DF2142", value: 9 },
    //   { regNo: "GJ01DF2143", value: 10 },
    //   { regNo: "GJ01DF2144", value: 11 },
    //   { regNo: "GJ01DF2145", value: 12 },
    //   { regNo: "GJ01DF2146", value: 13 },
    //   { regNo: "GJ01DF2147", value: 14 },
    //   { regNo: "GJ01DF2148", value: 15 },
    //   { regNo: "GJ01DF2149", value: 16 },
    //   { regNo: "GJ01DF2150", value: 17 },
    //   { regNo: "GJ01DF2151", value: 18 },
    //   { regNo: "GJ01DF2152", value: 19 },
    //   { regNo: "GJ01DF2153", value: 20 },
    //   { regNo: "GJ01DF2154", value: 21 },
    //   { regNo: "GJ01DF2155", value: 22 },
    //   { regNo: "GJ01DF2156", value: 23 },
    //   { regNo: "GJ01DF2157", value: 24 },
    //   { regNo: "GJ01DF2158", value: 25 },
    //   { regNo: "GJ01DF2159", value: 26 },
    //   { regNo: "GJ01DF2160", value: 27 },
    //   { regNo: "GJ01DF2161", value: 28 },
    //   { regNo: "GJ01DF2162", value: 29 },
    //   { regNo: "GJ01DF2163", value: 30 },
    //   { regNo: "GJ01DF2164", value: 31 },
    //   { regNo: "GJ01DF2165", value: 32 },
    //   { regNo: "GJ01DF2166", value: 33 },
    //   { regNo: "GJ01DF2167", value: 34 },
    //   { regNo: "GJ01DF2168", value: 35 },
    //   { regNo: "GJ01DF2169", value: 36 },
    //   { regNo: "GJ01DF2170", value: 37 },
    //   { regNo: "GJ01DF2171", value: 38 },
    //   { regNo: "GJ01DF2172", value: 39 },
    //   { regNo: "GJ01DF2173", value: 40 },
    // ];
    //const { data } = this.state;
    //console.log("data", this.state.data);
    return (
      <div className="BarChart">
        {this.props.FleetUptimeMangementApi?.FleetUptimeHistoricGraph
          ?.loading ? (
          <div className="loader-overlay">
            <div className="loader-container">
              <ReactLoader />
            </div>
          </div>
        ) : null}
        <ResponsiveContainer
          //  width={950} height={400}
          width="58.6%"
          // margin-top= "10%"
          //  height={10}
          // height="100%"
          // width={950} height={40}

          aspect={3}
        >
          <BarChart
            data={this.state.data}
            margin={{ top: 20, right: 0, left: 10, bottom: 20 }}
          >
            <CartesianGrid stroke="#ccc" />
            <XAxis
              dataKey="regNo"
              style={{ fontFamily: "roboto-regular" }}
              tickSize={100}
              ticks={[
                1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18,
                19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34,
                35, 36, 37, 38, 39, 40,
              ]}
              domain={[1, 100]}
              tickCount={100}
            />
            {/* <Label
                angle={0}
                position="bottom"
                style={{
                  textAnchor: "bottom",
                  marginTop: "20px",
                  FontFace: "roboto-regular",
                }}
              >
                Vehicles operated in selected period : {this.state.FA_vehicleCount}
              </Label>
            </XAxis> */}
            <YAxis
              dataKey="value"
              label={{
                value: "No. of KMS",
                angle: -90,
                position: "insideLeft",
              }}
              style={{ fontFamily: "roboto" }}
            />
            <Tooltip />
            <Bar type="monotone" dataKey="value" fill="#4b85f7" />
          </BarChart>
        </ResponsiveContainer>
        <p
          x={475}
          y={430}
          textAnchor="middle"
          fontFamily="Roboto, sans-serif"
          style={{ marginLeft: "20%", marginTop: "-3%" }}
        >
          Vehicles operated in selected period: {this.state.FA_vehicleCount}
        </p>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps, {
  getFleetUptimeHistoricgraphAPI,
})(withRouter(HistoricGraphView));
