import React, { Component } from "react";
import "./index.scss";
import ReactTable from "react-table";
import "react-table/react-table.css";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import BackToScreen from "../../component/back_to_screen";
import ReactTooltip from "react-tooltip";
import ReactLoader from "../../component/react-loader/react-loader.component";
import moment from "moment";
import { addDays } from "date-fns";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

import Workbook from "react-excel-workbook";
import speedBandApiAction from "../../redux/speed_band/actions";

const { getSpeedbandListAPI } = speedBandApiAction;
var lastDay = new Date();
lastDay.setHours(lastDay.getHours() - 6);
var now = new Date();
now.setDate(now.getDate());

var startTimestamp = new Date();
startTimestamp.setHours(0);
startTimestamp.setMinutes(0);
startTimestamp.setSeconds(0);

var endTimestamp = new Date();
endTimestamp.setHours(23);
endTimestamp.setMinutes(59);
endTimestamp.setSeconds(58);

class SpeedBand extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // start_date: lastDay,
      // end_date: now,
      start_date: startTimestamp,
      end_date: endTimestamp,
      speedBandListdata: [],
      filterData: [],
      searchInput: "",
      editingfields: {},
    };
    this.backtodashboardPage = this.backtodashboardPage.bind(this);
  }

  componentDidMount() {
    this.getSpeedbandListreport();
  }

  getSpeedbandListreport() {
    const { start_date, end_date } = this.state;
    this.setState({ loading: true });

    if (start_date < end_date) {
      const payload = {
        user_id: sessionStorage.getItem("userId"),
        customerId: sessionStorage.getItem("customerId"),
        start_timestamp: moment(start_date).format("YYYY-MM-DD HH:mm:ss"),
        end_timestamp: moment(end_date).format("YYYY-MM-DD HH:mm:ss"),
      };
      this.props.getSpeedbandListAPI(payload);
    } else if (start_date > end_date) {
      alert("End Date should be greater than Start Date");
    } else {
      alert("End Date should be greater than Start Date");
    }
  }

  componentWillReceiveProps(nextProps) {
    //console.log("speedbandlistreport", nextProps);
    if (
      nextProps?.SpeedBandGraphApi?.speedBandListApi?.result?.data?.responseData
    ) {
      this.setState({
        speedBandListdata:
          nextProps.SpeedBandGraphApi.speedBandListApi.result.data.responseData,
      });
      this.setState({
        filterData:
          nextProps.SpeedBandGraphApi.speedBandListApi.result.data.responseData,
      });
    }
  }

  handleDateChange(evt) {
    const value = evt.target.value;

    const name = evt.target.name;
    this.setState((prevState) => ({
      editingfields: {
        // object that we want to update

        ...prevState.editingfields, // keep all other key-value pairs
        [name]: moment(value).format("YYYY-MM-DD HH:mm:00"),
        // update the value of specific key
      },
    }));
    this.setState({ [name]: value });
  }

  onSubmit = (e) => {
    this.getSpeedbandListreport();
  };

  speedBandhandleChange = (event) => {
    const search = event.target.value.toLowerCase();
    this.setState({
      searchInput: search,
      filterData: this.state.speedBandListdata.filter(
        (item) => item.regNo && item.regNo.toLowerCase().includes(search)
      ),
    });
  };

  backtodashboardPage() {
    let tabItem = {
      tabIndex: 16,
      tabName: "Dashboard",
    };
    this.props.selectedTab(null, tabItem);
  }

  render() {
    let { searchInput } = this.state;
    return (
      <div className="speed_band">
        {this.props.SpeedBandGraphApi?.speedBandListApi?.loading ? (
          <div className="loader-overlay">
            <div className="loader-container">
              <ReactLoader />
            </div>
          </div>
        ) : null}
        <div className="fleet-health-backward">
          <BackToScreen routingCall={this.backtodashboardPage} />
          <h3 className="speed_band-head">Speed Band</h3>
        </div>

        <div className="speed_band_div">
          <div className="speed_band_div_labels">
            <label className="speed_band_div_labels_style">Start Date:</label>

            <DatePicker
              autocomplete="off"
              name="start_date"
              selected={this.state.start_date}
              maxDate={moment().toDate()}
              minDate={addDays(new Date(), -90)}
              value={
                this.state.editingfields.start_date === "undefined"
                  ? this.state.start_date.setHours(0, 0, 0)
                  : this.state.editingfields.start_date
              }
              onChange={(value) =>
                this.handleDateChange({
                  target: { name: "start_date", value },
                })
              }
              timeInputLabel="Time:"
              dateFormat="yyyy-MM-dd HH:mm:00"
              showTimeInput
            />

            <label className="speed_band_div_labels_style-1">End Date:</label>

            <DatePicker
              autocomplete="off"
              name="end_date"
              selected={this.state.end_date}
              maxDate={moment().toDate()}
              minDate={addDays(new Date(), -90)}
              value={
                this.state.editingfields.end_date === "undefined"
                  ? this.state.end_date.setHours(23, 59, 59)
                  : this.state.editingfields.end_date
              }
              onChange={(value) =>
                this.handleDateChange({
                  target: { name: "end_date", value },
                })
              }
              timeInputLabel="Time:"
              dateFormat="yyyy-MM-dd HH:mm:00"
              showTimeInput
            />
          </div>

          <div>
            <button
              className="speed_band_div_button-submit"
              onClick={this.onSubmit}
            >
              <i className="fa fa-check" style={{ paddingRight: "5px" }}></i>
              Submit
            </button>
          </div>
          <div>
            <button
              className="speed_band_div_button-submit-1"
              onClick={this.reset}
            >
              <i className="fa fa-refresh" style={{ paddingRight: "5px" }}></i>{" "}
              Reset
            </button>
          </div>

          <div className="speed_band_search">
            <form
              onSubmit={(e) => {
                e.preventDefault();
              }}
            >
              <input
                className="speed_band_search-input"
                type="text"
                id="filter"
                placeholder="Search Table (Reg No)"
                value={searchInput || ""}
                onChange={this.speedBandhandleChange}
              />
            </form>
          </div>

          <div>
            <Workbook
              filename="speed_band.xlsx"
              element={
                <div
                  className="speed_band-download"
                  data-tip
                  data-for="registerTip"
                >
                  Export
                  <img
                    src={require("../../assets/images/export/export.png")}
                    alt=""
                    className="speed_band-download-img"
                  />
                  <ReactTooltip id="registerTip" place="top" effect="solid">
                    Download
                  </ReactTooltip>
                </div>
              }
            >
              <Workbook.Sheet
                data={this.state.speedBandListdata}
                name="speed-band-list-Report"
              >
                <Workbook.Column label="Reg No" value="regNo" />
                <Workbook.Column label="Current Speed(Km/Hr)" value="speed" />
                <Workbook.Column label="Duration" value="duration" />
                <Workbook.Column label="Max Speed" value="maxSpeed" />
                <Workbook.Column label="Maximum Speed Timestamp" value="startTime" />
                {/* <Workbook.Column label="End Time" value="endTime" /> */}
              </Workbook.Sheet>
            </Workbook>
          </div>
        </div>

        <div className="speed_band_table">
          <ReactTable
            NoDataComponent={() => null}
            pageSizeOptions={[20, 30, 50, 80, 100, 130, 200, 500]}
            style={{
              height: "490px",
              marginTop: "0%",
              marginRight: "30px",
              marginLeft: "40px",
            }}
            data={this.state.filterData}
            showPagination={true}
            columns={[
              {
                Header: "S.No",
                id: "serialNumber",
                filterable: false,
                resizable: false,
                width: 90,
                Cell: (row) => {
                  return <div>{row.index + 1}</div>;
                },
              },
              {
                Header: "Reg No.",
                id: "regNo",
                accessor: (d) => d.regNo,
                width: 300,

                resizable: false,
              },
              {
                Header: "Current Speed(Km/Hr)",
                id: "speed",
                accessor: (d) => d.speed,
                width: 360,

                filterAll: true,
                resizable: false,
              },
              // {
              //   Header: "Duration",
              //   id: "duration",
              //   accessor: (d) => d.duration,
              //   width: 200,

              //   resizable: false,
              // },
              {
                Header: "Max Speed",
                id: "maxSpeed",
                accessor: (d) => d.maxSpeed,
                width: 300,

                filterAll: true,
                resizable: false,
              },

              {
                Header: "Maximum Speed Timestamp",
                id: "startTime",
                accessor: (d) => d.startTime,
                width: 400,

                filterAll: true,
                resizable: false,
              },

              // {
              //   Header: "End Time",
              //   id: "endTime",
              //   accessor: (d) => d.endTime,
              //   width: 190,

              //   filterAll: true,
              //   resizable: false,
              // },
              // {
              //   Header: "Km Operated",
              //   id: "kmOperated",
              //   accessor: (d) => d.kmOperated,
              //   width: 250,

              //   filterAll: true,
              //   resizable: false,
              // },
            ]}
            defaultSorted={[
              {
                id: "serialNumber",
                asc: true,
              },
            ]}
            defaultPageSize={50}
            className="-striped -highlight"
          >
            {(state, makeTable, instance) => {
              let recordsInfoText = "";

              const { filtered, pageRows, pageSize, sortedData, page } = state;

              if (sortedData && sortedData.length > 0) {
                let isFiltered = filtered.length > 0;

                let totalRecords = sortedData.length;

                let recordsCountFrom = page * pageSize + 1;

                let recordsCountTo = recordsCountFrom + pageRows.length - 1;

                if (isFiltered)
                  recordsInfoText = `${recordsCountFrom}-${recordsCountTo} of ${totalRecords} filtered records`;
                else
                  recordsInfoText = `${recordsCountFrom}-${recordsCountTo} of ${totalRecords} records`;
              } else recordsInfoText = "No records";

              return (
                <div className="main-grid">
                  <div className="above-table text-right">
                    <div className="col-sm-12">
                      <span className="records-info">{recordsInfoText}</span>
                    </div>
                  </div>

                  {makeTable()}
                </div>
              );
            }}
          </ReactTable>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps, {
  getSpeedbandListAPI,
})(withRouter(SpeedBand));
