
import React, { Component } from "react";
import "../driver_details/index.scss";
import ReactTable from "react-table";
import moment from "moment";
import ReactTooltip from "react-tooltip";
import DatePicker from "react-datepicker";
import Workbook from "react-excel-workbook";
import { addDays } from "date-fns";
import BackToScreen from "../../component/back_to_screen";
import "react-datepicker/dist/react-datepicker.css";
import "react-table/react-table.css";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import SubMenuHeader from "../../container/sub_menu_header";
import ReactLoader from "../../component/react-loader/react-loader.component";
import driverDetailsApiActions from "../../redux/driver_details/actions";
import ViewMoreButton from "../../component/view_more_button";
var now = new Date();
now.setDate(now.getDate());
var lastDay = new Date();
lastDay.setHours(lastDay.getHours() - 6);

var startTimestamp = new Date();
startTimestamp.setHours(0);
startTimestamp.setMinutes(0);
startTimestamp.setSeconds(0)

var endTimestamp = new Date();
endTimestamp.setHours(23);
endTimestamp.setMinutes(59);
endTimestamp.setSeconds(58)
const { getDriverdetailsAPI } = driverDetailsApiActions;
class DriverDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataSet: [],
      inputSearch: "",
      filteredData: [],
      // start_date: lastDay,
      // end_date: now,
      start_date: startTimestamp,
      end_date: endTimestamp,
      date: new Date(),
      editingfields: {},
    };
    this.backtodashboardPage = this.backtodashboardPage.bind(this);
    this.navToAttandance = this.navToAttandance.bind(this);
    this.navToTripLogs = this.navToTripLogs.bind(this);
  }

  componentDidMount() {
    this.getdriverDetails();
  }

  getdriverDetails = async () => {
    const { start_date, end_date } = this.state;
    if (start_date < end_date) {
    let payLoad = {
      //user_id: "1",
      user_id : sessionStorage.getItem("userId"),
      customerId: sessionStorage.getItem("customerId"),
      start_timestamp: moment(start_date).format("YYYY-MM-DD HH:mm:ss"),
      end_timestamp: moment(end_date).format("YYYY-MM-DD HH:mm:ss"),
    };
    await this.props.getDriverdetailsAPI(payLoad);

  } else if (start_date > end_date) {
    alert("End Date should be greater than Start Date");
  } else {
    alert("End Date should be greater than Start Date");
  }
  };

  onSubmit = (e) => {
    this.getdriverDetails();
  };

  componentWillReceiveProps(nextProps) {
    //  console.log("driverdetails", nextProps);
    if (
      nextProps?.DriverDetailsApi?.driverDetailsApi?.result?.data?.responseData
    ) {
      this.setState({
        dataSet:
          nextProps.DriverDetailsApi.driverDetailsApi.result.data.responseData,
      });

      this.setState({
        filteredData:
          nextProps.DriverDetailsApi.driverDetailsApi.result.data.responseData,
      });
    }
  }

  handledriverdetailsSearch = (event) => {
    //console.log("serach ele");
    const search = event.target.value.toLowerCase();
    this.setState({
      inputSearch: search,
      filteredData: this.state.dataSet.filter(
        (item) =>
          (item.driverName && item.driverName.toLowerCase().includes(search)) ||
          (item.driverId && item.driverId.toLowerCase().includes(search)) ||
          (item.status && item.status.toLowerCase().includes(search))
      ),
    });
  };

  navToAttandance() {
    let tabItem = {
      tabIndex: 27,
      tabName: "Attandance",
    };
    this.props.selectedTab(null, tabItem);
  }

  navToTripLogs() {
    let tabItem = {
      tabIndex: 29,
      tabName: "Trip Monitoring Log",
    };
    this.props.selectedTab(null, tabItem);
  }

  onClick(value) {}

  backtodashboardPage() {
    let tabItem = {
      tabIndex: 16,
      tabName: "Dashboard",
    };
    this.props.selectedTab(null, tabItem);
  }

  handleDateChange(evt) {
    const value = evt.target.value;

    const name = evt.target.name;
    this.setState((prevState) => ({
      editingfields: {
        // object that we want to update

        ...prevState.editingfields, // keep all other key-value pairs
        [name]: moment(value).format("YYYY-MM-DD HH:mm:00"),
        // update the value of specific key
      },
    }));
    this.setState({ [name]: value });
  }
  render() {
    let { inputSearch } = this.state;

    return (
      <div>
        {this.props.DriverDetailsApi?.driverDetailsApi?.loading ? (
          <div className="master-driver-loader-overlay">
            <div className="master-driver-loader-container">
              <ReactLoader />
            </div>
          </div>
        ) : null}
        <div className="fleet-health-backward">
          <BackToScreen routingCall={this.backtodashboardPage} />
          <h3 className="driver-details-text">Driver Details</h3>
        </div>
        {/* 
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-8 d-flex">
              <SubMenuHeader
                visible="false"
                component="driverDetails"
                data={this.state.filteredData}
              />
            </div>
            <div className="col-4 d-flex">
              <form>
                <input
                  className="driver-details-search"
                  type="text"
                  id="filter"
                  placeholder="Search Table (Driver Name / Status)"
                  value={inputSearch || ""}
                  onChange={this.handledriverdetailsSearch}
                />
              </form>
            </div>
          </div>
        </div> */}

        <div className="driver_details_div">
          {/* <div className="driver_details_div_labels">
            <label className="driver_details_div_labels_style-1">
              Start Date:
            </label>
            <DatePicker
              autocomplete="off"
              name="start_date"
              selected={this.state.start_date}
              maxDate={moment().toDate()}
              minDate={addDays(new Date(), -90)}
              value={
                this.state.editingfields.start_date == "undefined"
                  ? this.state.start_date.setHours(0, 0, 0)
                  : this.state.editingfields.start_date
              }
              onChange={(value) =>
                this.handleDateChange({
                  target: { name: "start_date", value },
                })
              }
              //defaultValue={moment(now).toDate()}
              timeInputLabel="Time:"
              dateFormat="yyyy-MM-dd HH:mm:00"
              showTimeInput
            />

            <label className="driver_details_div_labels_style">End Date:</label>
            <DatePicker
              autocomplete="off"
              // valueDefault={null}
              //maxDate={moment().toDate()}
              name="end_date"
              selected={this.state.end_date}
              maxDate={moment().toDate()}
              minDate={addDays(new Date(), -90)}
              value={
                this.state.editingfields.end_date == "undefined"
                  ? this.state.end_date.setHours(23, 59, 59)
                  : this.state.editingfields.end_date
              }
              onChange={(value) =>
                this.handleDateChange({
                  target: { name: "end_date", value },
                })
              }
              //defaultValue="01-01-2001 01:01:01"
              timeInputLabel="Time:"
              dateFormat="yyyy-MM-dd HH:mm:00"
              showTimeInput
            />
          </div>

          <div>
            <button
              className="driver_details_div_button-submit"
              onClick={this.onSubmit}
            >
              <i className="fa fa-check" style={{ paddingRight: "5px" }}></i>
              Submit
            </button>
          </div>
          <div>
            <button
              className="driver_details_div_button-submit-1"
              onClick={this.reset}
            >
              <i className="fa fa-refresh" style={{ paddingRight: "5px" }}></i>{" "}
              Reset
            </button>
          </div> */}

          <div className="driver_details_search">
            <form
              onSubmit={(e) => {
                e.preventDefault();
              }}
            >
              <input
                className="driver_details_search-input"
                type="text"
                id="filter"
                placeholder="Search Table (Driver ID / Driver Name)"
                value={inputSearch || ""}
                onChange={this.handledriverdetailsSearch}
              />
            </form>
          </div>

          <div>
            <Workbook
              filename="driver-details-Report.xlsx"
              element={
                <div
                  className="driver_details-download"
                  data-tip
                  data-for="registerTip"
                >
                  Export
                  <img
                    src={require("../../assets/images/export/export.png")}
                    alt=""
                    className="driver_details-download-img"
                  />
                  <ReactTooltip id="registerTip" place="top" effect="solid">
                    Download
                  </ReactTooltip>
                </div>
              }
            >
              <Workbook.Sheet
                data={this.state.filteredData}
                name="driver-details"
              >
                <Workbook.Column label="Driver Name" value="driverName" />
           
                <Workbook.Column label="Driver ID" value="driverId" />
                <Workbook.Column label="Driver Score" value="driverEfficiency" />
              </Workbook.Sheet>
            </Workbook>
          </div>
        </div>

        <div style={{ marginTop: "-30px" }}>
          <ReactTable
            NoDataComponent={() => null}
            pageSizeOptions={[20, 30, 50, 80, 100, 130, 200, 500]}
            style={{
              height: "490px",
              marginTop: "0%",
              marginLeft: "40px",
              marginRight: "40px",
            }}
            data={this.state.filteredData}
            showPagination={true}
            columns={[
              {
                Header: "S.No",
                id: "serialNumber",
                filterable: false,
                resizable: false,
                width: 90,
                Cell: (row) => {
                  return <div>{row.index + 1}</div>;
                },
              },
              {
                Header: "Driver Name",
                id: "driverName",
                accessor: (d) => d.driverName,
                width: 260,
                filterAll: true,
                resizable: false,
              },
              {
                Header: "Driver ID",
                id: "driverId",
                accessor: (d) => d.driverId,
                width: 250,

                resizable: false,
              },
              // {
              //   Header: "Total Trips",
              //   id: "numOfTrips",
              //   accessor: (d) => d.numOfTrips,
              //   width: 120,

              //   resizable: false,
              // },
              // {
              //   Header: "Total Attendance",
              //   id: "numOfAttendances",
              //   accessor: (d) => d.numOfAttendances,
              //   width: 200,

              //   resizable: false,
              // },

              {
                Header: "Driver Score",
                id: "driverEfficiency",
                accessor: (d) => d.driverEfficiency,
                width: 320,

                resizable: false,
              },

              {
                Header: "Driver Award",
                width: 300,
                Cell: (props) => (
                  <div>
                    <i
                      className="fa fa-star"
                      title="Edit"
                      style={{ fontSize: "22px", color: "#FFD700" }}
                      // onClick={() => this.setdata(this.props.data.original)}
                    ></i>
                  </div>
                ),
                resizable: false,
                filter: false,
              },

              {
                Header: "Action",
                Cell: (props) => (
                  <ViewMoreButton
                    className="driver-details-actions"
                    data={props}
                    function={this.navToAttandance}
                    function2={this.navToTripLogs}
                    componentName="driverDetails"
                  ></ViewMoreButton>
                ),
                // Cell: (props) => (
                //   <a
                //     className="driver-details-action"
                //     href="#"
                //     //style="text-decoration:none;"
                //     target=""
                //     onClick={this.navToAttandance(props)}
                //   >
                //     VIEW MORE{" "}
                //     <img
                //       className="driverdetails-action-img"
                //       src={require("../../assets/images/driver-monitoring/view-more.png")}
                //       alt=""
                //     />
                //   </a>
                // ),

                width: 220,

                filterAll: false,
                resizable: false,
              },
              // {
              //   Header: "Status",
              //   id: "status",
              //   accessor: (d) => d.status,
              //   width: 100,

              //   filterAll: true,
              //   resizable: false,
              // },
              // {
              //   Header: "Reported Time",
              //   id: "reportTime",
              //   accessor: (d) => d.reportTime,
              //   width: 200,

              //   filterAll: true,
              //   resizable: false,
              // },
            ]}
            defaultSorted={[
           
              {
                id: "serialNumber",
                asc: true,
              },
            ]}
            defaultPageSize={50}
            className="-striped -highlight"
          >
            {(state, makeTable, instance) => {
              let recordsInfoText = "";

              const { filtered, pageRows, pageSize, sortedData, page } = state;

              if (sortedData && sortedData.length > 0) {
                let isFiltered = filtered.length > 0;

                let totalRecords = sortedData.length;

                let recordsCountFrom = page * pageSize + 1;

                let recordsCountTo = recordsCountFrom + pageRows.length - 1;

                if (isFiltered)
                  recordsInfoText = `${recordsCountFrom}-${recordsCountTo} of ${totalRecords} filtered records`;
                else
                  recordsInfoText = `${recordsCountFrom}-${recordsCountTo} of ${totalRecords} records`;
              } else recordsInfoText = "No records";

              return (
                <div className="main-grid">
                  <div className="above-table text-right">
                    <div className="col-sm-12">
                      <span className="records-info-2">{recordsInfoText}</span>
                    </div>
                  </div>

                  {makeTable()}
                </div>
              );
            }}
          </ReactTable>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps, {
  getDriverdetailsAPI,
})(withRouter(DriverDetails));
