import React, { Component } from "react";
import ReactTable from "react-table";
import "react-table/react-table.css";
import { connect } from "react-redux";
import Workbook from "react-excel-workbook";
import ReactLoader from "../../component/react-loader/react-loader.component";
import moment from "moment";
import DatePicker from "react-datepicker";
import ReactTooltip from "react-tooltip";
import BackToScreen from "../../component/back_to_screen";
import { addDays } from "date-fns";
import "react-datepicker/dist/react-datepicker.css";
import { withRouter } from "react-router";
import "../trip_monitoring_report/index.scss";
import tripMonitoringApiActions from "../../redux/trip_monitoring/actions";
import ViewMoreButton from "../../component/view_more_button";

const { getTripMonitoringAPI } = tripMonitoringApiActions;
var now = new Date();
now.setDate(now.getDate());
var lastDay = new Date();
lastDay.setHours(lastDay.getHours() - 6);

var startTimestamp = new Date();
startTimestamp.setHours(0);
startTimestamp.setMinutes(0);
startTimestamp.setSeconds(0);

var endTimestamp = new Date();
endTimestamp.setHours(23);
endTimestamp.setMinutes(59);
endTimestamp.setSeconds(58);

class TripMonitoringReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataSet: [],
      inputSearch: "",
      filteredData: [],
      // start_date: lastDay,
      // end_date: now,
      start_date: startTimestamp,
      end_date: endTimestamp,
      date: new Date(),
      editingfields: {},
    };
    this.backtodriverdetailsPage = this.backtodriverdetailsPage.bind(this);
    this.navToTripLogs = this.navToTripLogs.bind(this);
    this.navToAttandance = this.navToAttandance.bind(this);
  }

  componentDidMount() {
    this.gettripMonitoring();
  }

  gettripMonitoring = async () => {
    const { start_date, end_date } = this.state;
    if (start_date < end_date) {
      let payLoad = {
        //user_id: "1",
        user_id: sessionStorage.getItem("userId"),
        customerId: sessionStorage.getItem("customerId"),
        start_timestamp: moment(start_date).format("YYYY-MM-DD HH:mm:ss"),
        end_timestamp: moment(end_date).format("YYYY-MM-DD HH:mm:ss"),
      };
      await this.props.getTripMonitoringAPI(payLoad);
    } else if (start_date > end_date) {
      alert("End Date should be greater than Start Date");
    } else {
      alert("End Date should be greater than Start Date");
    }
  };

  navToTripLogs() {
    let tabItem = {
      tabIndex: 29,
      tabName: "Trip Monitoring Log",
    };
    this.props.selectedTab(null, tabItem);
  }

  navToAttandance() {
    let tabItem = {
      tabIndex: 27,
      tabName: "Attandance",
    };
    this.props.selectedTab(null, tabItem);
  }

  onSubmit = (e) => {
    this.gettripMonitoring();
  };
  componentWillReceiveProps(nextProps) {
    if (
      nextProps?.TripMonitoringApi?.tripmonitoringApi?.result?.data
        ?.responseData
    ) {
      this.setState({
        dataSet:
          nextProps.TripMonitoringApi.tripmonitoringApi.result.data
            .responseData,
      });

      this.setState({
        filteredData:
          nextProps.TripMonitoringApi.tripmonitoringApi.result.data
            .responseData,
      });
    }
  }

  handleTripmonitoringSearch = (event) => {
    //console.log("serach ele");
    const search = event.target.value.toLowerCase();
    this.setState({
      inputSearch: search,
      filteredData: this.state.dataSet.filter(
        (item) =>
          (item.driverName && item.driverName.toLowerCase().includes(search)) ||
          (item.driverId && item.driverId.toLowerCase().includes(search))
      ),
    });
  };

  backtodriverdetailsPage = () => {
    let tabItem = {
      tabIndex: 26,
      tabName: "Driver Details",
    };
    this.props.selectedTab(null, tabItem);
  };

  handleDateChange(evt) {
    const value = evt.target.value;

    const name = evt.target.name;
    this.setState((prevState) => ({
      editingfields: {
        // object that we want to update

        ...prevState.editingfields, // keep all other key-value pairs
        [name]: moment(value).format("YYYY-MM-DD HH:mm:00"),
        // update the value of specific key
      },
    }));
    this.setState({ [name]: value });
  }
  render() {
    let { inputSearch } = this.state;

    return (
      <div>
        <div className="fleet-health-backward">
          <BackToScreen routingCall={this.backtodriverdetailsPage} />
          <h3 className="Trip_Monitoring-text">Trip Monitoring</h3>
        </div>

        <div className="trip_monitoring_report_div">
          <div className="trip_monitoring_report_div_labels">
            <label className="trip_monitoring_report_div_labels_style-1">
              Start Date:
            </label>
            <DatePicker
              autocomplete="off"
              name="start_date"
              //selected={this.state.start_date}

              selected={this.state.start_date}
              maxDate={moment().toDate()}
              minDate={addDays(new Date(), -90)}
              value={
                this.state.editingfields.start_date === "undefined"
                  ? this.state.start_date.setHours(0, 0, 0)
                  : this.state.editingfields.start_date
              }
              onChange={(value) =>
                this.handleDateChange({
                  target: { name: "start_date", value },
                })
              }
              //defaultValue={moment(now).toDate()}
              timeInputLabel="Time:"
              dateFormat="yyyy-MM-dd HH:mm:00"
              showTimeInput
            />

        

            <label className="trip_monitoring_report_div_labels_style">
              End Date:
            </label>


            <DatePicker
              autocomplete="off"
              name="end_date"
              //selected={this.state.end_date}
              selected={this.state.end_date}
              maxDate={moment().toDate()}
              minDate={addDays(new Date(), -90)}
              value={
                this.state.editingfields.end_date === "undefined"
                  ? this.state.end_date.setHours(23, 59, 59)
                  : this.state.editingfields.end_date
              }
              onChange={(value) =>
                this.handleDateChange({
                  target: { name: "end_date", value },
                })
              }
              timeInputLabel="Time:"
              dateFormat="yyyy-MM-dd HH:mm:00"
              showTimeInput
            />
          </div>

          <div>
            <button
              className="trip_monitoring_report_div_button-submit"
              onClick={this.onSubmit}
            >
              <i className="fa fa-check" style={{ paddingRight: "5px" }}></i>
              Submit
            </button>
          </div>
          <div>
            <button
              className="trip_monitoring_report_div_button-submit-1"
              //onClick={this.reset}
            >
              <i className="fa fa-refresh" style={{ paddingRight: "5px" }}></i>{" "}
              Reset
            </button>
          </div>

          <div className="trip_monitoring_report_search">
            <form
              onSubmit={(e) => {
                e.preventDefault();
              }}
            >
              <input
                className="trip_monitoring_report_search-input"
                type="text"
                id="filter"
                placeholder="Search Table (Driver Name / Driver ID )"
                value={inputSearch || ""}
                onChange={this.handleTripmonitoringSearch}
              />
            </form>
          </div>

          <div>
            <Workbook
              filename="trip-monitoring-report.xlsx"
              element={
                <div
                  className="trip_monitoring_report-download"
                  data-tip
                  data-for="registerTip"
                >
                  Export
                  <img
                    src={require("../../assets/images/export/export.png")}
                    alt=""
                    className="trip_monitoring_report-download-img"
                  />
                  <ReactTooltip id="registerTip" place="top" effect="solid">
                    Download
                  </ReactTooltip>
                </div>
              }
            >
              <Workbook.Sheet
                data={this.state.dataSet}
                name="trip-monitoring-report"
              >
                <Workbook.Column label="ID" value="id" />
                <Workbook.Column label="Driver ID" value="driverId" />
                <Workbook.Column label="Driver Name" value="driverName" />
                <Workbook.Column
                  label="Driver Efficiency"
                  value="driverEfficiency"
                />
                <Workbook.Column label="Num Of Trips" value="numOfTrips" />
              </Workbook.Sheet>
            </Workbook>
          </div>
        </div>

        <div style={{ marginTop: "-30px" }}>
          {this.props.TripMonitoringApi?.tripMonitoringApi?.loading ? (
            <div className="loader-overlay">
              <div className="loader-container">
                <ReactLoader />
              </div>
            </div>
          ) : null}

          <ReactTable
            NoDataComponent={() => null}
            pageSizeOptions={[20, 30, 50, 80, 100, 130, 200, 500]}
            style={{
              height: "490px",
              marginTop: "0%",
              marginLeft: "40px",
              marginRight: "30px",
            }}
            data={this.state.filteredData}
            showPagination={true}
            columns={[
              {
                Header: "S.No",
                id: "serialNumber",
                filterable: false,
                resizable: false,
                width: 90,
                Cell: (row) => {
                  return <div>{row.index + 1}</div>;
                },
              },

              {
                Header: "ID",
                id: "id",
                accessor: (d) => d.id,
                width: 200,

                resizable: false,
              },
              {
                Header: "Driver ID",
                id: "driverId",
                accessor: (d) => d.driverId,
                width: 220,

                resizable: false,
              },
              {
                Header: "Driver Name",
                id: "driverName",
                accessor: (d) => d.driverName,
                width: 240,

                resizable: false,
              },

              {
                Header: "Driver  Efficiency(%)",
                id: "driverEfficiency",
                accessor: (d) => d.driverEfficiency,
                width: 250,
                filterAll: true,
                resizable: false,
              },

              {
                Header: "No Of Trips",
                id: "numOfTrips",
                accessor: (d) => d.numOfTrips,
                width: 230,
                filterAll: true,
                resizable: false,
              },

              {
                Header: "Action",
                Cell: (props) => (
                  <ViewMoreButton
                    className="driver-details-actions"
                    data={props}
                    function={this.navToTripLogs}
                    function1={this.navToAttandance}
                  ></ViewMoreButton>
                ),

                width: 100,

                filterAll: true,
                resizable: false,
              },
            ]}
            defaultSorted={[
              {
                id: "serialNumber",
                asc: true,
              },
            ]}
            defaultPageSize={50}
            className="-striped -highlight"
          >
            {(state, makeTable, instance) => {
              let recordsInfoText = "";

              const { filtered, pageRows, pageSize, sortedData, page } = state;

              if (sortedData && sortedData.length > 0) {
                let isFiltered = filtered.length > 0;

                let totalRecords = sortedData.length;

                let recordsCountFrom = page * pageSize + 1;

                let recordsCountTo = recordsCountFrom + pageRows.length - 1;

                if (isFiltered)
                  recordsInfoText = `${recordsCountFrom}-${recordsCountTo} of ${totalRecords} filtered records`;
                else
                  recordsInfoText = `${recordsCountFrom}-${recordsCountTo} of ${totalRecords} records`;
              } else recordsInfoText = "No records";

              return (
                <div className="main-grid">
                  <div className="col-sm-12">
                    <span className="records-info">{recordsInfoText}</span>
                  </div>

                  {makeTable()}
                </div>
              );
            }}
          </ReactTable>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps, {
  getTripMonitoringAPI,
})(withRouter(TripMonitoringReport));
