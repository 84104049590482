import React, { Component } from "react";
//import Header from "../header/header.component";
import { connect } from "react-redux";
import * as QuickSightEmbedding from "amazon-quicksight-embedding-sdk";
import { withRouter } from "react-router";
import QuicksightApiActions from "../../redux/its_report/actions";
//import classes from "./landingpage.module.scss";
import "../../container/its_report/index.scss";
import ReactLoader from "../../component/react-loader/react-loader.component";
//import Login from "../../components/login/login.component";
//import Footer from "../footer/footer.component";

const { getQuicksightAPI } = QuicksightApiActions;

class ItsReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      url: "",
      username: "",
      password: "",
      loader: true,
      userId: "",
    };
  }

  componentDidMount() {
    // this.loginCheck();
    this.setState({
      loader: true,
    });

    this.signinCheckFunction();

    // 5 mins QS api call - 300000 ms
    // setTimeout(() => {
    //   this.signinCheckFunction();
    // }, 60000);

    // 60 mins session time out
    setTimeout(() => {
      this.sessionClear();
    }, 3600000);
  }

  // refreshPage = () => {
  //   // if (refreshCounter == 1) {
  //   window.location.reload(false);
  //   //  }
  // };

  sessionClear = () => {
    sessionStorage.clear();
    window.location.reload(false);
  };

  signinCheckFunction = async () => {
    // let user = localStorage.getItem("username");
    // let pass = localStorage.getItem("password");

    let payLoad = {
      //dashboardID: sessionStorage.getItem("dashboardId"),
     // dashboardID: "e6192ce9-730f-4e24-ae35-aaa4d522403b",
      userId: sessionStorage.getItem("userId"),
    };

    await this.props.getQuicksightAPI(payLoad);
  };

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      // console.log("final props", this.props);
       if(this.props.ItsReportApi?.QuicksightApi?.result?.data?.url!==prevProps.ItsReportApi?.QuicksightApi?.result?.data?.url){
      if (this.props.ItsReportApi?.QuicksightApi?.result?.data?.url) {
        let link = this.props.ItsReportApi.QuicksightApi.result.data.url;
        let parameter =
          this.props.ItsReportApi.QuicksightApi.result.data.parameters;

        this.getQuickSightUrl(link, parameter);
      }
    }
    }
  }

  // static getDerivedStateFromProps(props, state) {
  //   if (props?.QuicksightApi?.QuicksightApi?.result?.data?.url) {
  //     let link = props.QuicksightApi.QuicksightApi.result.data.url;
  //     let parameter = props.QuicksightApi.QuicksightApi.result.data.parameters;
  //     this.getQuickSightUrl(link, parameter);
  //   }
  // }

  // componentWillReceiveProps(nextProps) {
  //   console.log("np", nextProps);
  //   if (nextProps?.QuicksightApi?.QuicksightApi?.result?.data?.url) {
  //     let link = nextProps.QuicksightApi.QuicksightApi.result.data.url;
  //     let parameter =
  //       nextProps.QuicksightApi.QuicksightApi.result.data.parameters;
  //     // this.setState({
  //     //   url: link,
  //     //   // username: nextProps?.loginApi?.loginApi?.result?.data?.userName,
  //     //   // password: nextProps?.loginApi?.loginApi?.result?.data?.password,
  //     //   // parameters: nextProps?.loginApi?.loginApi?.result?.data?.parameters,
  //     // });
  //     //   this.setState({
  //     //     url: nextProps?.loginApi?.loginApi?.result?.data?.url,
  //     //     username: nextProps?.loginApi?.loginApi?.result?.data?.userName,
  //     //     password: pwd,
  //     //     parameters: nextProps?.loginApi?.loginApi?.result?.data?.parameters,
  //     //   });
  //     // } else {
  //     //   console.log("User Login Failed - URL not found");
  //     // }

  //     this.getQuickSightUrl(link, parameter);
  //   }
  // }

  // loginCheck() {
  //   let userNo = sessionStorage.getItem("userId");
  //   if (userNo) {
  //     this.setState({ userId: userNo });
  //     userNo === null ? this.props.history.push("/") : console.log("loggedin");
  //   }
  // }

  onError = (payload) => {
    console.log("Do something when the dashboard fails loading");
    // this.getQuikcSightUrl();
  };

  getQuickSightUrl(link, parameter) {
  //  console.log("called",link)
    let { url, parameters } = this.state;
    var params = "";
    if (parameter === null) {
      params = " ";
    } else {
      params = parameter;
    }
    var containerDiv = document.getElementById("embeddingContainer");
    const width =
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth;
    const height =
      window.innerHeight ||
      document.documentElement.clientHeight ||
      document.body.clientHeight;
    var options = {
      url: link,
    //  url: "https://us-east-1.quicksight.aws.amazon.com/embed/b1b4f8a0dc5740868fba3d08e5b2dee7/dashboards/e6192ce9-730f-4e24-ae35-aaa4d522403b?undoRedoDisabled=true&resetDisabled=true",
      container: containerDiv,
      scrolling: "no",
      // height: height,
      // width: width - 40,
      height: height,
      width: width - 40,
      footerPaddingEnabled: true,
      printEnabled: false,
      parameters: params,
    };
    dashboard = QuickSightEmbedding.embedDashboard(options);
    dashboard.on("error", this.onError());
    var dashboard;
  }

 // getQuikcSightUrl() {}

  render() {
   
    let { url, username, password, userId } = this.state;
    if (sessionStorage.getItem("userId")) {
      // return(<Login/>)
      return (
        <div className="topheight">
          {/* <Header user={this.state.username} pwd={this.state.password} /> */}
          <div id="embeddingContainer">
            {this.props.ItsReportApi.QuicksightApi.loading ? (
              <div className="reactLoader">
                <ReactLoader />
              </div>
            ) : null}
          </div>
          {/* <Footer /> */}
        </div>
      );
    } else {
      return (
        <div className="topheight">
          {/* <Header user={this.state.username} pwd={this.state.password} /> */}
          <div id="embeddingContainer">
            {/* <Login /> */}
          </div>
          {/* <Footer /> */}
        </div>
      );
    }
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
   // landingPage: state.landingPage,
  };
};

export default connect(mapStateToProps, {
  getQuicksightAPI,
})(withRouter(ItsReport));

//export default LandingPage;
