import React, { Component } from "react";
import "../../container/dashboard_status/index.scss";
import DashboardVehicleReadlines from "../../component/dashboard_vehicle_readlines";
import DashboardVehicleStatus from "../../component/dashboard_vehicle_status";
import DashboardVehicletenDays from "../../component/dashboard_vehicle_tendays";
//import DashboardVehicleperDay from "../../component/dashboard_fleet_uptime";
import DashboardEnergyStatus from "../../component/dashboard_energy_status";
import DashboardUptimeAlert from "../../component/dashboard_uptime_alert";
import DashboardDriverDetails from "../../component/dashboard_driver_details";
import DashboardVehicleEffciency from "../../component/dashboard_vehicle_effciency";
//import DashboardConsumingPower from "../../component/dashboard_performance_insight";
import DashboardDepotChargers from "../../component/dashboard_depot_chargers";
import DashboardOpportunityChargers from "../../component/dashboard_opportunity_chargers";
import DashboardChargeBandStatus from "../../component/dashboard_chargeband_status";
import DashboardFleetAvailability from "../../component/dashboard_fleet_availability";
import RefreshToggler from "../../component/refresh_toggler";
import moment from "moment";
import dashboardStatusCountsApiActions from "../../redux/dashboard_status_counts/actions";
import fleetUptimeDetailsApiActions from "../../redux/fleet_uptime_details/actions";
import vehiclePerformanceDetailsApiActions from "../../redux/vehicle_performance_details/actions";

import { connect } from "react-redux";
import { withRouter } from "react-router";
import DashboardBatteryTemperature from "../../component/dashboard_battery_temperature";
import DashboardVehicleEffciencyOne from "../../component/dashboard_vehicle_effciency_one";
import DashboardPerfomanceInsight from "../../component/dashboard_performance_insight";
import DashboardFleetUptime from "../../component/dashboard_fleet_uptime";

import batteryTempApiAction from "../../redux/battery_temp_view/actions";
import chargeBandApiAction from "../../redux/charging_band_view/actions";
import stationApiActions from "../../redux/station_details/actions";
import mapActionApiActions from "../../redux/mapactions/actions";
import DashbordUnplannedStop from "../../component/dashboard_unplaned_stop";
import alertApiActions from "../../redux/alert/actions";
import speedBandApiAction from "../../redux/speed_band/actions";
import DashboardSpeedBand from "../../component/dashboard_speed_band";
const { getSpeedbandGraphAPI } = speedBandApiAction;
const { getDashboardStatusCountsAPI } = dashboardStatusCountsApiActions;
const { getFleetUptimeDetailsAPI } = fleetUptimeDetailsApiActions;
const { getVehiclePerformanceDetailsAPI } = vehiclePerformanceDetailsApiActions;
const { getBatteryTempAPI } = batteryTempApiAction;
const { getChargeBandAPI } = chargeBandApiAction;
const { getStationAPI } = stationApiActions;
const { getMapActionAPI } = mapActionApiActions;
const { getAlertAPI } = alertApiActions;

//import VehicleStatusReadliness from '../vehicle_status_readliness';

var now = new Date();
now.setDate(now.getDate());

class DashboardStatus extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      VR_washing: "",
      VR_charging: "",
      VR_cleaning: "",
      VR_maintenance: "",
      VR_readyfortrip: "",
      FA_vehicleCount: "",
      FA_totalKmPerDay:"",
      FA_kmsPerDay: "",
      DD_present: "",
      DD_absent: "",
      DD_reportedLate: "",
      DD_total: "",
      DD_start: "",
      DD_completed: "",
      DD_estimatedTrips: "",
      DD_driverReportedAdh: "",
      DD_tripStartedAdh: "",
      DD_tripCompletedAdh: "",
      DD_dailyCheck: "",
      DD_dailyCheckAdh: "",
      UA_serviceAlerts: "",
      UA_stopNow: "",
      UA_visitSoon: "",
      UA_breakdown: "",
      FU_avgVehPerDay: "",
      FU_vehAbove200: "",
      FU_vehBelow200: "",
      PowerConsByVeh: "",
      VE_vehOperatingUnder1kw: "",
      VE_vehOperatingAbove1kw: "",
      ES_acConsumption: "",
      ES_dcConsumption: "",
      ES_powerDrawnByVeh: "",
      ES_acdcConversion: "",
      ES_dcdcConversion: "",
      ES_acTodc: "",
      VP_tableData: [],
      vehRunning10Days: [
        { text: "0", value: "0" },
        { text: "0", value: "0" },
        { text: "0", value: "0" },
        { text: "0", value: "0" },
        { text: "0", value: "0" },
      ],
      batteryTempData: [
        { text: "0", value: "0" },
        { text: "0", value: "0" },
        { text: "0", value: "0" },
        { text: "0", value: "0" },
        { text: "0", value: "0" },
      ],
      chargeBandData: [
        { text: "0", value: "0" },
        { text: "0", value: "0" },
        { text: "0", value: "0" },
        { text: "0", value: "0" },
        { text: "0", value: "0" },
      ],

      speedBandData: [
        { text: "0", value: "0" },
        { text: "0", value: "0" },
        { text: "0", value: "0" },
        { text: "0", value: "0" },
        { text: "0", value: "0" },
      ],
      availableCharger: "",
      occupiedCharger: "",
      breakdownCharger: "",
      chargerEfficiency: "",
      oppoChargeAvailable: "",
      oppoChargeOccupied: "",
      oppoChargerBreakdown: "",
      oppoChargerEfficiency: "",
      running_count: "",
      stopped_count: "",
      not_reachable_count: "",
      all: "",
      breakdown_count: 0,
      accident_count: 0,
      //breakdown:0,
    };
    this.navtoFleetHealth = this.backtoFleetHealth.bind(this);
    this.navTotrackPage = this.navTotrackPage.bind(this);
    this.navToLiveDashboard = this.navToLiveDashboard.bind(this);
    this.navtoVehicleSummaryReport = this.navtoVehicleSummaryReport.bind(this);
    this.navToReportingdetailsPage = this.navToReportingdetailsPage.bind(this);
    this.navTochargerreport = this.navTochargerreport.bind(this);
    this.navTovehiclesummaryPage = this.navTovehiclesummaryPage.bind(this);
    this.navtofleetUptime = this.navtofleetUptime.bind(this);
    this.navToSpeedBand = this.navToSpeedBand.bind(this);
    this.navtoTrackSummaryReport = this.navtoTrackSummaryReport.bind(this);
    this.navtochargersummary=this.navtochargersummary.bind(this);
    this.navtoFleetUptimeGraphView=this.navtoFleetUptimeGraphView.bind(this)
    this.navtoDriverMaster=this.navtoDriverMaster.bind(this)
    this.navToAccidentreport = this.navToAccidentreport.bind(this)
    this.navtobreakdownreport = this.navtobreakdownreport.bind(this)
    this.navTotrackPagess=this.navTotrackPagess.bind(this);
    this.RefreshTogglerFunction = this.RefreshTogglerFunction.bind(this);
    this.RefreshCallAllApi = this.RefreshCallAllApi.bind(this);
  }

  componentDidMount() {
    this.getDashboardDetails();
    this.getFleetUptimeDetails();
    this.getVehPerformanceDetails();
    this.getBatteryTempDetails();
    this.getChargeBandDetails();
    this.getStationDetails();
    this.getMapActionDetails();
    this.getAlertReport();
    this.getSpeedBandGraph();

    if (sessionStorage.getItem("dashboardrefresh")) {
      clearInterval(this.interval);
   //   console.log("called auto")
      sessionStorage.setItem("dashboardrefresh", "");
      this.RefreshTogglerFunction("on");
    }
  }


  RefreshTogglerFunction(val) {
    var that = this;

    if (sessionStorage.getItem("dashboardrefresh")) {
      this.interval = sessionStorage.getItem("dashboardrefresh");
    }
    if (val === "on") {
     // console.log("if",val)
      this.interval = setInterval(function () {
        that.RefreshCallAllApi();
      }, 120000);
      sessionStorage.setItem("dashboardrefresh", this.interval);
    } else {
    //  console.log("else",val)
      if (this.interval) {
        clearInterval(this.interval);
        sessionStorage.setItem("dashboardrefresh", "");
      }
    }
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }
  RefreshCallAllApi() {
   
    
    // this.getFleetUptimeDetails();
    // this.getVehPerformanceDetails();
    // this.getAlertReport();

    // this.getDashboardDetails();
    // this.getBatteryTempDetails();
    // this.getChargeBandDetails();
    // this.getStationDetails();
    // this.getMapActionDetails();
    // this.getSpeedBandGraph();
  }
  getSpeedBandGraph = async () => {
    let payLoad = {
      //user_id: "2",
      user_id: sessionStorage.getItem("userId"),
      customerId: sessionStorage.getItem("customerId"),
      start_timestamp: sessionStorage.getItem("start_timestamp"),
      end_timestamp: sessionStorage.getItem("end_timestamp"),
      duration: sessionStorage.getItem("duration"),
    };

    await this.props.getSpeedbandGraphAPI(payLoad);
  };
  getDashboardDetails = async () => {
    const { start_date, end_date } = this.state;
    let payLoad = {
      api_key: "DASHBOARD_STATUS_ALERT",
      charger_location: "Bangalore",
      //user_id: "1",
      user_id: sessionStorage.getItem("userId"),
      customerId: sessionStorage.getItem("customerId"),
      vehicle_number: ["BLR001", "BLR002", "BLR003", "BLR004"],
      // start_timestamp: sessionStorage.getItem("start_timestamp"),
      // end_timestamp: sessionStorage.getItem("end_timestamp"),
      start_timestamp: moment(start_date).format("YYYY-MM-DD 00:00:00"),
      end_timestamp: moment(end_date).format("YYYY-MM-DD 23:59:59"),
      duration: "CURRENT",
    };

    await this.props.getDashboardStatusCountsAPI(payLoad);
  };

  getFleetUptimeDetails = async () => {
    const { start_date, end_date } = this.state;
    let payLoad = {
      api_key: "DASHBOARD_VEHICLE_EFFICIENCY_COUNT",
      charger_location: "Bangalore",
      //user_id: "1",
      user_id: sessionStorage.getItem("userId"),
      customerId: sessionStorage.getItem("customerId"),
      vehicle_number: ["BLR001", "BLR002", "BLR003", "BLR004"],
      // start_timestamp: sessionStorage.getItem("start_timestamp"),
      // //end_timestamp: sessionStorage.getItem("end_timestamp"),
      start_timestamp: moment(start_date).format("YYYY-MM-DD 00:00:00"),
      end_timestamp: moment(end_date).format("YYYY-MM-DD 23:59:59"),
      duration: "CURRENT",
    };

    await this.props.getFleetUptimeDetailsAPI(payLoad);
  };

  getAlertReport() {
    const { start_date, end_date } = this.state;
    this.setState({ loading: true });
    const payload = {
      //user_id: "1",
      user_id: sessionStorage.getItem("userId"),
      customerId: sessionStorage.getItem("customerId"),
      start_timestamp: moment(start_date).format("YYYY-MM-DD 00:00:00"),
      end_timestamp: moment(end_date).format("YYYY-MM-DD 23:59:59"),
      status: "Open",
    };
    this.props.getAlertAPI(payload);
  }

  getVehPerformanceDetails = async () => {
    const { start_date, end_date } = this.state;
    let payLoad = {
      api_key: "DASHBOARD_VEHICLE_PERFORMANCE_INSIGHT",
      charger_location: "Bangalore",
      //user_id: "1",
      user_id: sessionStorage.getItem("userId"),
      customerId: sessionStorage.getItem("customerId"),
      vehicle_number: ["BLR001", "BLR002", "BLR003", "BLR004"],
      // start_timestamp: sessionStorage.getItem("start_timestamp"),
      // end_timestamp: sessionStorage.getItem("end_timestamp"),
      start_timestamp: moment(start_date).format("YYYY-MM-DD 00:00:00"),
      end_timestamp: moment(end_date).format("YYYY-MM-DD 23:59:59"),
      duration: "CURRENT",
    };

    await this.props.getVehiclePerformanceDetailsAPI(payLoad);
  };

  getBatteryTempDetails = async () => {
    const { start_date, end_date } = this.state;
    let payLoad = {
      api_key: "DASHBOARD_BARGRAPH",
      request_type: "BATTERY_TEMP",
      charger_location: "Bangalore",
      //user_id: "1",
      user_id: sessionStorage.getItem("userId"),
      customerId: sessionStorage.getItem("customerId"),
      vehicle_number: ["BLR001", "BLR002", "BLR003", "BLR004"],
      start_timestamp: moment(start_date).format("YYYY-MM-DD 00:00:00"),
      end_timestamp: moment(end_date).format("YYYY-MM-DD 23:59:59"),
      duration: sessionStorage.getItem("duration"),
    };

    await this.props.getBatteryTempAPI(payLoad);
  };

  getChargeBandDetails = async () => {
    const { start_date, end_date } = this.state;
    let payLoad = {
      api_key: "DASHBOARD_BARGRAPH",
      request_type: "CHARGE_BAND",
      charger_location: "Bangalore",
      //user_id: "1",
      user_id: sessionStorage.getItem("userId"),
      customerId: sessionStorage.getItem("customerId"),
      vehicle_number: ["BLR001", "BLR002", "BLR003", "BLR004"],
      start_timestamp: moment(start_date).format("YYYY-MM-DD 00:00:00"),
      end_timestamp: moment(end_date).format("YYYY-MM-DD 23:59:59"),
      duration: sessionStorage.getItem("duration"),
    };

    await this.props.getChargeBandAPI(payLoad);
  };

  getStationDetails = async () => {
    // console.log("inside");
    const { start_date, end_date } = this.state;
    let payLoad = {
      api_key: "DASHBOARD_COUNT",
      charger_location: "Bangalore",
      // user_id: "2",
      user_id: sessionStorage.getItem("userId"),
      customerId: sessionStorage.getItem("customerId"),
      vehicle_number: ["BLR001", "BLR002", "BLR003", "BLR004"],
      // start_timestamp: start_date,
      // end_timestamp: end_date,
      start_timestamp: moment(start_date).format("YYYY-MM-DD 00:00:00"),
      end_timestamp: moment(end_date).format("YYYY-MM-DD 23:59:59"),
      duration: sessionStorage.getItem("duration"),
    };

    await this.props.getStationAPI(payLoad);
  };

  getMapActionDetails = async () => {
    const { start_date, end_date } = this.state;
    let payLoad = {
      api_key: "CONNECTED_VEHICLE_TRACK_CHARGER_AND_VEHICLE_COUNT",
      charger_location: "Bangalore",
      //user_id: "1",
      user_id: sessionStorage.getItem("userId"),
      customerId: sessionStorage.getItem("customerId"),
      vehicle_number: ["BLR001", "BLR002", "BLR003", "BLR004"],
      start_timestamp: moment(start_date).format("YYYY-MM-DD 00:00:00"),
      end_timestamp: moment(end_date).format("YYYY-MM-DD 23:59:59"),
      duration: sessionStorage.getItem("duration"),
    };

    await this.props.getMapActionAPI(payLoad);
  };

  componentWillReceiveProps(nextProps) {
    //console.log("this. props check", nextProps);

    if (this.props !== nextProps) {
      ///// vehicle readiness
      if (
        nextProps?.dashboardStatusCountsApi?.dashboardStatusCountsApi?.result
          ?.data?.responseData.vehicleReadiness
      ) {
        this.setState({
          VR_washing:
            nextProps.dashboardStatusCountsApi.dashboardStatusCountsApi.result
              .data.responseData.vehicleReadiness.washing,
        });
        this.setState({
          VR_charging:
            nextProps?.dashboardStatusCountsApi?.dashboardStatusCountsApi
              ?.result?.data?.responseData.vehicleReadiness.charging,
        });
        this.setState({
          VR_cleaning:
            nextProps?.dashboardStatusCountsApi?.dashboardStatusCountsApi
              ?.result?.data?.responseData.vehicleReadiness.cleaning,
        });
        this.setState({
          VR_maintenance:
            nextProps?.dashboardStatusCountsApi?.dashboardStatusCountsApi
              ?.result?.data?.responseData.vehicleReadiness.maintanance,
        });
        this.setState({
          VR_readyfortrip:
            nextProps?.dashboardStatusCountsApi?.dashboardStatusCountsApi
              ?.result?.data?.responseData.vehicleReadiness.readyForTrip,
        });
      }

      /// fleet availability
      if (
        nextProps?.dashboardStatusCountsApi?.dashboardStatusCountsApi?.result
          ?.data?.responseData.fleetAvailablity
      ) {
        this.setState({
          FA_vehicleCount:
            nextProps?.dashboardStatusCountsApi?.dashboardStatusCountsApi
              ?.result?.data?.responseData.fleetAvailablity.operatingVehicle,
        });
        this.setState({
          FA_kmsPerDay:
            nextProps?.dashboardStatusCountsApi?.dashboardStatusCountsApi
              ?.result?.data?.responseData.fleetAvailablity.kmOperatedPerDay,
        });
        this.setState({
          FA_totalKmPerDay:
            nextProps?.dashboardStatusCountsApi?.dashboardStatusCountsApi
              ?.result?.data?.responseData.fleetAvailablity.totalKmPlannedPerDay,
        });
        
      }

      /// driver details
      if (
        nextProps?.dashboardStatusCountsApi?.dashboardStatusCountsApi?.result
          ?.data?.responseData.driverDetails
      ) {
        this.setState({
          DD_present:
            nextProps?.dashboardStatusCountsApi?.dashboardStatusCountsApi
              ?.result?.data?.responseData.driverDetails.present,
        });
        this.setState({
          DD_absent:
            nextProps?.dashboardStatusCountsApi?.dashboardStatusCountsApi
              ?.result?.data?.responseData.driverDetails.absent,
        });
        this.setState({
          DD_reportedLate:
            nextProps?.dashboardStatusCountsApi?.dashboardStatusCountsApi
              ?.result?.data?.responseData.driverDetails.reportedLate,
        });
        this.setState({
          DD_total:
            nextProps?.dashboardStatusCountsApi?.dashboardStatusCountsApi
              ?.result?.data?.responseData.driverDetails.totalDriver,
        });
        this.setState({
          DD_start:
            nextProps?.dashboardStatusCountsApi?.dashboardStatusCountsApi
              ?.result?.data?.responseData.driverDetails.tripStarted,
        });
        this.setState({
          DD_completed:
            nextProps?.dashboardStatusCountsApi?.dashboardStatusCountsApi
              ?.result?.data?.responseData.driverDetails.tripCompleted,
        });
        this.setState({
          DD_estimatedTrips:
            nextProps?.dashboardStatusCountsApi?.dashboardStatusCountsApi
              ?.result?.data?.responseData.driverDetails.estimatedTrips,
        });
        this.setState({
          DD_driverReportedAdh:
            nextProps?.dashboardStatusCountsApi?.dashboardStatusCountsApi
              ?.result?.data?.responseData.driverDetails.driverAdherence,
        });
        this.setState({
          DD_tripStartedAdh:
            nextProps?.dashboardStatusCountsApi?.dashboardStatusCountsApi
              ?.result?.data?.responseData.driverDetails.tripStartedAdherence,
        });
        this.setState({
          DD_tripCompletedAdh:
            nextProps?.dashboardStatusCountsApi?.dashboardStatusCountsApi
              ?.result?.data?.responseData.driverDetails.tripCompletedAdherence,
        });
        this.setState({
          DD_dailyCheck:
            nextProps?.dashboardStatusCountsApi?.dashboardStatusCountsApi
              ?.result?.data?.responseData.driverDetails.dailyCheckDone,
        });
        this.setState({
          DD_dailyCheckAdh:
            nextProps?.dashboardStatusCountsApi?.dashboardStatusCountsApi
              ?.result?.data?.responseData.driverDetails
              .dailyCheckDoneAdherence,
        });
      }

      /// uptime Alert
      if (
        nextProps?.dashboardStatusCountsApi?.dashboardStatusCountsApi?.result
          ?.data?.responseData.upTimeAlert
      ) {
        this.setState({
          UA_serviceAlerts:
            nextProps?.dashboardStatusCountsApi?.dashboardStatusCountsApi
              ?.result?.data?.responseData.upTimeAlert.serviceAlerts,
        });
        this.setState({
          UA_stopNow:
            nextProps?.dashboardStatusCountsApi?.dashboardStatusCountsApi
              ?.result?.data?.responseData.upTimeAlert.stopNow,
        });
        this.setState({
          UA_visitSoon:
            nextProps?.dashboardStatusCountsApi?.dashboardStatusCountsApi
              ?.result?.data?.responseData.upTimeAlert.visitSoon,
        });
        this.setState({
          UA_breakdown:
            nextProps?.dashboardStatusCountsApi?.dashboardStatusCountsApi
              ?.result?.data?.responseData.upTimeAlert.breakdown,
        });
      }

      /// fleet uptime

      if (
        nextProps?.fleetUptimeDetailsApi?.fleetUptimeDetailsApi?.result?.data
          ?.responseData
      ) {
        this.setState({
          FU_avgVehPerDay:
            nextProps?.fleetUptimeDetailsApi?.fleetUptimeDetailsApi?.result
              ?.data?.responseData.avgVehicleRunPerDay,
        });
        this.setState({
          FU_vehAbove200:
            nextProps?.fleetUptimeDetailsApi?.fleetUptimeDetailsApi?.result
              ?.data?.responseData.vehRunAbove200KmEachDay,
        });
        this.setState({
          FU_vehBelow200:
            nextProps?.fleetUptimeDetailsApi?.fleetUptimeDetailsApi?.result
              ?.data?.responseData.vehRunBelow200KmEachDay,
        });

        // single power consumption
        this.setState({
          PowerConsByVeh:
            nextProps?.fleetUptimeDetailsApi?.fleetUptimeDetailsApi?.result
              ?.data?.responseData.powerConsumptionByVehPerKm,
        });

        /// vehicle efficiency
        this.setState({
          VE_vehOperatingUnder1kw:
            nextProps?.fleetUptimeDetailsApi?.fleetUptimeDetailsApi?.result
              ?.data?.responseData.vehOperatingUnder1Kwh,
        });
        this.setState({
          VE_vehOperatingAbove1kw:
            nextProps?.fleetUptimeDetailsApi?.fleetUptimeDetailsApi?.result
              ?.data?.responseData.vehOperatingAbove1Kwh,
        });

        ///// energy status
        this.setState({
          ES_acConsumption:
            nextProps?.fleetUptimeDetailsApi?.fleetUptimeDetailsApi?.result
              ?.data?.responseData.energyStatus.acConumption,
        });
        this.setState({
          ES_dcConsumption:
            nextProps?.fleetUptimeDetailsApi?.fleetUptimeDetailsApi?.result
              ?.data?.responseData.energyStatus.dcConsumptionByCharger,
        });
        this.setState({
          ES_powerDrawnByVeh:
            nextProps?.fleetUptimeDetailsApi?.fleetUptimeDetailsApi?.result
              ?.data?.responseData.energyStatus.powerDrawnByVehicle,
        });
        this.setState({
          ES_acdcConversion:
            nextProps?.fleetUptimeDetailsApi?.fleetUptimeDetailsApi?.result
              ?.data?.responseData.energyStatus.acToDcConversion,
        });
        this.setState({
          ES_dcdcConversion:
            nextProps?.fleetUptimeDetailsApi?.fleetUptimeDetailsApi?.result
              ?.data?.responseData.energyStatus.dcToDcConversion,
        });
        this.setState({
          ES_acTodc:
            nextProps?.fleetUptimeDetailsApi?.fleetUptimeDetailsApi?.result
              ?.data?.responseData.energyStatus.acInputToDcVehicleBattery,
        });

        /// vehicle running 10 days
        this.setState({
          vehRunning10Days:
            nextProps?.fleetUptimeDetailsApi?.fleetUptimeDetailsApi?.result
              ?.data?.responseData.avgVehicleRunFor10Day,
        });
      }

      /// vehicle performance insights

      if (
        nextProps?.vehiclePerformanceDetailsApi?.vehiclePerformanceDetailsApi
          ?.result?.data?.responseData
      ) {
        this.setState({
          VP_tableData:
            nextProps?.vehiclePerformanceDetailsApi
              ?.vehiclePerformanceDetailsApi?.result?.data?.responseData,
        });
      }

      //speedband

      if (
        nextProps?.SpeedBandGraphApi?.speedBandGraphApi?.result?.data
          ?.responseData?.bandData
      ) {
        this.setState({
          speedBandData:
            nextProps.SpeedBandGraphApi.speedBandGraphApi.result.data
              .responseData.bandData,
        });
      }

      // last cards charge band and battery temperature
      if (nextProps?.batteryTemp?.batteryTempApi?.result?.data?.responseData) {
        this.setState({
          batteryTempData:
            nextProps.batteryTemp.batteryTempApi.result.data.responseData,
        });
      }
      if (nextProps?.chargeBand?.chargeBandApi?.result?.data?.responseData) {
        this.setState({
          chargeBandData:
            nextProps.chargeBand.chargeBandApi.result.data.responseData,
        });
      }
      /// depot chargers

      if (nextProps?.stationApi?.stationApi?.result?.data?.responseData) {
        this.setState({
          availableCharger:
            nextProps.stationApi.stationApi.result.data?.responseData
              .available_gun,
        });
        this.setState({
          occupiedCharger:
            nextProps.stationApi.stationApi.result.data?.responseData
              .occupied_gun,
        });
        this.setState({
          breakdownCharger:
            nextProps.stationApi.stationApi.result.data?.responseData
              .breakdown_gun,
        });
        this.setState({
          chargerEfficiency:
            nextProps.stationApi.stationApi.result.data?.responseData
              .gun_efficiency,
        });
        this.setState({
          oppoChargeAvailable:
            nextProps.stationApi.stationApi.result.data?.responseData
              .oppoChargeAvailable,
        });
        this.setState({
          oppoChargeOccupied:
            nextProps.stationApi.stationApi.result.data?.responseData
              .oppoChargeOccupied,
        });
        this.setState({
          oppoChargerBreakdown:
            nextProps.stationApi.stationApi.result.data?.responseData
              .oppoChargerBreakdown,
        });
        this.setState({
          oppoChargerEfficiency:
            nextProps.stationApi.stationApi.result.data?.responseData
              .oppoChargerEfficiency,
        });
      }

      /// alert status

      if (nextProps?.AlertApi?.alertApi?.result?.data?.responseData) {
        let alert_count =
          nextProps.AlertApi.alertApi.result.data.responseData.length;
        // this.setState({
        //   Alertdata: nextProps.AlertApi.alertApi.result.data.responseData,
        // });
        sessionStorage.setItem("notification_count", alert_count);
      }

      /// vehicle status counts running stooped, disconnect_delay

      if (nextProps?.mapActionApi?.mapActionApi?.result?.data?.responseData) {
        this.setState({
          running_count:
            nextProps.mapActionApi.mapActionApi.result.data.responseData
              .moving_vehicle_count,
        });
        this.setState({
          stopped_count:
            nextProps.mapActionApi.mapActionApi.result.data.responseData
              .stopped_vehicle,
        });
        this.setState({
          not_reachable_count:
            nextProps.mapActionApi.mapActionApi.result.data.responseData
              .non_respond_vehicle,
        });
        this.setState({
          all:
            this.state.running_count +
            this.state.stopped_count +
            this.state.not_reachable_count,
        });

        this.setState({
          breakdown_count:
            nextProps.mapActionApi.mapActionApi.result.data.responseData
              .unplanned_stop_breakdown_report,
        });
        this.setState({
          accident_count:
            nextProps.mapActionApi.mapActionApi.result.data.responseData
              .unplanned_stop_accident_reported,
        });
      } else {
        console.log("User Login Failed - URL not found");
      }
    }
  }

  // tabClickHomess = (evt, tabIndex) => {
  //   const { selectedTab } = this.props;
  //   let payload = { tabIndex: 5 };

  //   evt.currentTarget.classList.add("active");
  //   this.setState({ selTab: tabIndex });
  //   selectedTab && selectedTab(evt, payload);
  // };

  // login = () =>{
  //   <Redirect to="/connectedmodule" />
  // }

  // login = () =>{
  //   <Redirect to="/track" />
  // }

  navToGraphPopup(cardName) {
    //  console.log("chargecardName", cardName);
    sessionStorage.setItem("popupClicked", cardName);
    this.navToLiveDashboard();
  }
  navToSpeedBand() {
    let tabItem = {
      tabIndex: 41,
      tabName: "Speed Band Report",
    };
    this.props.selectedTab(null, tabItem);
  }

  navTovehiclesummaryPage() {
    let tabItem = {
      tabIndex: 23,
      tabName: "Vehicle Summary",
    };
    // this.setState({selTab:tabIndex,selTab:tabName});
    this.props.selectedTab(null, tabItem);
  }

  navToReportingdetailsPage() {
    let tabItem = {
      tabIndex: 15,
      tabName: "Reporting Details",
    };
    // this.setState({selTab:tabIndex,selTab:tabName});
    this.props.selectedTab(null, tabItem);
  }

  navTotrackPage() {
    sessionStorage.setItem("graphView",true)
    let tabItem = {
      tabIndex: 7,
      tabName: "Track",
    };
    // this.setState({selTab:tabIndex,selTab:tabName});
    this.props.selectedTab(null, tabItem);
  }

  //track map
  navTotrackPagess(){
    let tabItem = {
      tabIndex: 7,
      tabName: "Track",
    };
    // this.setState({selTab:tabIndex,selTab:tabName});
    this.props.selectedTab(null, tabItem);
  }
  /// Live Dashboard
  navToLiveDashboard() {
    let tabItem = {
      tabIndex: 1,
      tabName: "Live Dashboard",
    };
    // this.setState({selTab:tabIndex,selTab:tabName});
    this.props.selectedTab(null, tabItem);
  }

  navtoFleetHealth = () => {
    let tabItem = {
      tabIndex: 21,
      tabName: "Fleet Health",
    };
    this.props.selectedTab(null, tabItem);
  };
  backtoFleetHealth = () => {
    let tabItem = {
      tabIndex: 21,
      tabName: "Fleet Health",
    };
    this.props.selectedTab(null, tabItem);
  };
navtoDriverMaster(){
  let tabItem = {
    tabIndex: 33,
    tabName: "Driver Master",
  };
  // this.setState({selTab:tabIndex,selTab:tabName});
  this.props.selectedTab(null, tabItem);

}
  navTochargerreport() {
    let tabItem = {
      tabIndex: 24,
      tabName: "Charger Report",
    };
    // this.setState({selTab:tabIndex,selTab:tabName});
    this.props.selectedTab(null, tabItem);
  }

  navtoTrackSummaryReport() {
    let tabItem = {
      tabIndex: 42,
      tabName: "Summary",
    };
    // this.setState({selTab:tabIndex,selTab:tabName});
    this.props.selectedTab(null, tabItem);
  }
  navtoVehicleSummaryReport() {
    let tabItem = {
      tabIndex: 50,
      tabName: "Vehicle Summary"
    };
    
    // this.setState({selTab:tabIndex,selTab:tabName});
    this.props.selectedTab(null, tabItem);
  }

  navtofleetUptime() {
    let tabItem = {
      tabIndex: 22,
      tabName: "Fleet Uptime",
    };

    // this.setState({selTab:tabIndex,selTab:tabName});
    this.props.selectedTab(null, tabItem);
  }

  navtochargersummary() {
    let tabItem = {
      tabIndex: 2,
      tabName: "Charging Summary",
    };

    // this.setState({selTab:tabIndex,selTab:tabName});
    this.props.selectedTab(null, tabItem);
  }

  navToAccidentreport() {
    let tabItem = {
      tabIndex: 36,
      tabName: "Accident Details",
    };
    this.props.selectedTab(null, tabItem);
  }
  navtobreakdownreport() {
    let tabItem = {
      tabIndex: 12,
      tabName: "Breakdown Details",
    };
    this.props.selectedTab(null, tabItem);
  }

  navtoFleetUptimeGraphView(){
    sessionStorage.setItem("graphView",true)
    let tabItem = {
      tabIndex: 22,
      tabName: "Fleet Uptime",
    };

    // this.setState({selTab:tabIndex,selTab:tabName});
    this.props.selectedTab(null, tabItem);
  }
  // onHomeClick = () => {
  //   return(<></>)
  //  // window.location.href = "/track";
  // };
  render() {
    // console.log("this.check", this.state.DD_total);

    return (
      <div className="container-fluid">
        <div className="d-flex">
        <h3 className="dashboard-text">Dashboard</h3>
        <h2 style={{marginLeft:"73%"}}>
        <RefreshToggler
          RefreshTogglerFunction={this.RefreshTogglerFunction}
        />
        </h2>
        </div>
        <div className="row">
          <div className="col-lg-2 w-20 ms-4 gx-0 shadow bg-body rounded">
            <div className="head">
              <div className="head-text-1">FLEET STATUS</div>
              <div class="content_img">
                <img
                  className="dashboard_status_head_img-1"
                  src={require("../../assets/images/headernew/head.png")}
                  alt="next"
                />
                <div>Veh In Operation Today</div>
              </div>
              <button
                type="button"
                className="vehicle-status-btns"
                onClick={this.navTotrackPage}
              >
                VIEW MORE
                <img
                  className="vehicle-status-button-png"
                  src={require("../../assets/images/arrow-red/red.png")}
                  alt="next"
                />
              </button>
            </div>
            <div>
              <DashboardFleetAvailability
                vehicleCount={this.state.FA_vehicleCount}
                kmsperday={this.state.FA_kmsPerDay}
                totalKmsPerDay={this.state.FA_totalKmPerDay}
              />
            </div>
          </div>
          <div className="col-lg-2 w-20 ms-3 gx-0 shadow bg-body rounded">
            <div className="head">
              <div className="head-text-1">
                {" "}
                {/* <img
                  className="dashboard_status_head_img"
                  src={require("../../assets/images/headernew/head.png")}
                  alt="next"
                /> */}
                CONNECTED VEHICLE
              </div>
              <div class="content_imgs">
                <img
                  className="dashboard_status_head_img-1"
                  src={require("../../assets/images/headernew/head.png")}
                  alt="next"
                />
                <div>
                  1. Moving: Vehicles Currently Moving
                  <br />
                  2. Stopped: Vehicle Currently Stopped
                  <br />
                  3. Not Reachable: Vehicles Currently Not Communicating
                </div>
              </div>

              <button
                type="button"
                className="vehicle-status-btns_11"
                //onClick={this.redirect}
                onClick={this.navTotrackPagess}
                //onClick={this.props.history.push("/")}
                // onClick={(e) => {
                //   this.tabClickHomess(e, 5);
                // }}
              >
                VIEW IN MAP
                <img
                  className="vehicle-status-button-png"
                  src={require("../../assets/images/arrow-red/red.png")}
                  alt="next"
                />
              </button>
            </div>
            <div>
              <DashboardVehicleStatus
                running={this.state.running_count}
                stopped={this.state.stopped_count}
                notreachable={this.state.not_reachable_count}
                total={this.state.all}
              />
            </div>
          </div>
          <div className="col-lg-8 w-55 ms-3 gx-0 shadow bg-body rounded">
            <div className="head">
              <div className="head-text-1"> VEHICLE READINESS</div>
              <div class="content_img">
                <img
                  className="dashboard_status_head_img-1"
                  src={require("../../assets/images/headernew/head.png")}
                  alt="next"
                />
                <div>Present Status Of Vehicle At Depot</div>
              </div>

              <button
                type="button"
                className="vehicle-status-btn-one"
                navToTrendsPage={this.navToTrendsPage}
              >
                VIEW MORE
                <img
                  className="vehicle-status-button-png-one"
                  src={require("../../assets/images/arrow-red/red.png")}
                />
              </button>
            </div>

            <div>
              <DashboardVehicleReadlines
                washing={this.state.VR_washing}
                cleaning={this.state.VR_cleaning}
                charging={this.state.VR_charging}
                maintenance={this.state.VR_maintenance}
                //readyfortrip={this.state.VR_readyfortrip}
                readyfortrip={this.state.FA_vehicleCount}
                occupiedcharger={this.state.occupiedCharger}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-2 w-12 mt-3 ms-4 gx-0 shadow bg-body rounded">
            <div className="head">
              <div className="head-text-1"> FLEET UPTIME</div>
              <div class="content_img">
                <img
                  className="dashboard_status_head_img-1"
                  src={require("../../assets/images/headernew/head.png")}
                  alt="next"
                />
                <div>Average KMS Coverage of All Vehicles Yesterday</div>
              </div>
              <button
                type="button"
                className="vehicle-status-btns-1"
                onClick={this.navtofleetUptime}
              >
                VIEW MORE
                <img
                  className="vehicle-status-button-png"
                  src={require("../../assets/images/arrow-red/red.png")}
                  alt="next"
                />
              </button>
            </div>
            <div>
              <DashboardFleetUptime
                avgvehrunning={this.state.FU_avgVehPerDay}
                vehabove200={this.state.FU_vehAbove200}
                vehbelow200={this.state.FU_vehBelow200}
              />
            </div>
          </div>

          <div className="col-lg-2 w-12 mt-3 ms-3 gx-0 shadow bg-body rounded">
            <div className="head">
              <div className="head-text-1"> UNPLANNED STOP</div>
              <div class="content_img">
                <img
                  className="dashboard_status_head_img-1"
                  src={require("../../assets/images/headernew/head.png")}
                  alt="next"
                />
                <div>
                  {" "}
                  Unplanned Stops Due To Breakdown And Accident Report Today
                </div>
              </div>
            </div>
            <div>
              <DashbordUnplannedStop
                breakdown={
                  this.state.breakdown_count !== null
                    ? this.state.breakdown_count
                    : 0
                }
                accident={
                  this.state.accident_count !== 0
                    ? this.state.accident_count
                    : 0
                }
                clickdata1={this.navToAccidentreport}
                clickdata2={this.navtobreakdownreport}
                />
            </div>
          </div>
          <div className="col-lg-4 w-30 mt-3 ms-3 gx-0 shadow bg-body rounded">
            <div className="head">
              <div className="head-text-1">
                VEHICLE RUNNING KM FOR LAST 5 DAYS
              </div>
              <div class="content_img">
                <img
                  className="dashboard_status_head_img-1"
                  src={require("../../assets/images/headernew/head.png")}
                  alt="next"
                />
                <div>VEHICLE RUNNING KM FOR LAST 5 DAYS</div>
              </div>
              <button
                type="button"
                className="vehicle-status-btn-two"
                onClick={this.navtoFleetUptimeGraphView}
              >
                LIST VIEW
                <img
                  className="vehicle-status-button-png-two"
                  src={require("../../assets/images/arrow-red/red.png")}
                  alt="next"
                />
              </button>
            </div>
            <div>
              <DashboardVehicletenDays
                vehrunning10days={this.state.vehRunning10Days}
              />
            </div>
          </div>
          <div className="col-lg-4 w-30 mt-3 ms-3 gx-0 shadow bg-body rounded">
            <div className="head">
              <div className="head-text-1">ENERGY STATUS</div>
              <div class="content_imgs">
                <img
                  className="dashboard_status_head_img-1"
                  src={require("../../assets/images/headernew/head.png")}
                  alt="next"
                />
                <div>
                  Energy Status : Energy Given To Charger,Power Output At
                  Charger & Power Consumed By Battery During Charging And Their
                  Efficiency
                  <br />
                  1. Ratio Of Charger Output/Energy Input In % <br />
                  2. Ratio Of Power Consumed By Battery During Charging /
                  Charger Output In %<br />
                  3. Ratio Of Power Consumed By Battery During Charging / Energy
                  Input In %
                </div>
              </div>
              <button
                type="button"
                className="vehicle-status-btn-two"
                onClick={this.navtochargersummary}
                //onClick={this.navTochargerreport}
              >
                LIST VIEW
                <img
                  className="vehicle-status-button-png-three"
                  src={require("../../assets/images/arrow-red/red.png")}
                  alt="next"
                />
              </button>
            </div>
            <div>
              <DashboardEnergyStatus
                acconsumption={this.state.ES_acConsumption}
                dcconsumption={this.state.ES_dcConsumption}
                powerdrawn={this.state.ES_powerDrawnByVeh}
                acdcconversion={this.state.ES_acdcConversion}
                dcdcconversion={this.state.ES_dcdcConversion}
                actodcinput={this.state.ES_acTodc}
              />
            </div>
          </div>
        </div>
        {/* <div className="row">
          <div className="col-lg-8 w-97 mt-3 ms-4 gx-0 shadow bg-body rounded d-flex">
            <div>
            <DashboardDepotChargers/>
            </div>
            <br />
            <div>
            <DashboardOpportunityChargers/>
            </div>
          </div>
        </div> */}

        <div className="row">
          <div className="col-lg-6 w-46 mt-3 mb-3 ms-4 gx-0 shadow bg-body rounded">
            <div>
              <DashboardDepotChargers
                availablecharger={this.state.availableCharger}
                occupiedcharger={this.state.occupiedCharger}
                breakdowncharger={this.state.breakdownCharger}
                chargerefficiency={this.state.chargerEfficiency}
                clickdata={this.navToLiveDashboard}
              />
            </div>
          </div>
          <div className="col-lg-6 w-50 mt-3 mb-3 ms-3 gx-0 shadow bg-body rounded">
            <div>
              <DashboardOpportunityChargers
                oppoavailablecharger={this.state.oppoChargeAvailable}
                oppooccupiedcharger={this.state.oppoChargeOccupied}
                oppobreakdowncharger={this.state.oppoChargerBreakdown}
                oppochargerefficiency={this.state.oppoChargerEfficiency}
                clickdata={this.navToLiveDashboard}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-2 w-20 mt-3 ms-4 gx-0 shadow bg-body rounded">
            <div className="head">
              <div className="head-text-1">UPTIME ALERTS</div>
              <div class="content_img">
                <img
                  className="dashboard_status_head_img-1"
                  src={require("../../assets/images/headernew/head.png")}
                  alt="next"
                />
                <div>
                  Current Vehicle Health Status
                  <br />
                  Stop Now - Faults where Immediate Intervention Is Required To
                  Prevent The Breakdown Of Vehicle
                  <br />
                  Visit Soon - Faults where Intervention Is Required But Vehicle
                  Can be Run
                  <br />
                  Breakdown - Faults where Unplanned Stop Occured
                  <br />
                  In Service - Vehicle Under Service/Maintanance
                </div>
              </div>
              <button
                type="button"
                className="vehicle-status-btns"
                onClick={this.backtoFleetHealth}
              >
                VIEW MORE
                <img
                  className="vehicle-status-button-png"
                  src={require("../../assets/images/arrow-red/red.png")}
                  alt="next"
                />
              </button>
            </div>
            <div>
              <DashboardUptimeAlert
                servicealerts={this.state.UA_serviceAlerts}
                stopnow={this.state.UA_stopNow}
                visitsoon={this.state.UA_visitSoon}
                breakdown={this.state.UA_breakdown}
                clickdata={this.navtoFleetHealth}
              />
            </div>
          </div>
          <div className="col-lg-4 w-30 mt-3 ms-3 gx-0 shadow bg-body rounded">
            <div className="head">
              <div className="head-text-1">
                TOP 5 VEHICLES CONSUMING MORE POWER
              </div>
              <div class="content_imgs">
                <img
                  className="dashboard_status_head_img-1"
                  src={require("../../assets/images/headernew/head.png")}
                  alt="next"
                />
                <div>
                  List Of Vehicles Consuming More Energy Today Than Nominal
                  Consumption
                </div>
              </div>
              <button
                type="button"
                className="vehicle-status-btn-two"
                onClick={this.navTotrackPage}
              >
                VIEW MORE
                <img
                  className="vehicle-status-button-png-two"
                  src={require("../../assets/images/arrow-red/red.png")}
                  alt="next"
                />
              </button>
            </div>
            <div>
              <DashboardPerfomanceInsight tabledata={this.state.VP_tableData} />
            </div>
          </div>
          <div className="col-lg-6 w-45 mt-3 ms-3 gx-0 shadow bg-body rounded">
            <div className="head">
              <div className="head-text-1">DRIVER DETAILS</div>
              <div class="content_imgs">
                <img
                  className="dashboard_status_head_img-1"
                  src={require("../../assets/images/headernew/head.png")}
                  alt="next"
                />
                <div>
                  Driver App Uttilization Summary: Total Drivers Assigned &
                  Estimated Trips Planned Today
                  <br />
                  1. Driver Reported Score
                  <br />
                  2. Drivers Performed Dily Check Score
                  <br />
                  3. Total Trips Started
                  <br />
                  4. Total Trips Completed
                </div>
              </div>
              <button
                type="button"
                className="vehicle-status-btn-three"
                onClick={this.navToReportingdetailsPage}
                //onClick={this.navtoDriverMaster}
              >
                VIEW MORE
                <img
                  className="vehicle-status-button-png-three"
                  src={require("../../assets/images/arrow-red/red.png")}
                  alt="next"
                />
              </button>
            </div>
            <div>
              <DashboardDriverDetails
                present={this.state.DD_present}
                absent={this.state.DD_absent}
                reportedlate={this.state.DD_reportedLate}
                total={this.state.DD_total}
                start={this.state.DD_start}
                completed={this.state.DD_completed}
                estimatedtrips={this.state.DD_estimatedTrips}
                driverreportedAdh={this.state.DD_driverReportedAdh}
                tripstartedAdh={this.state.DD_tripStartedAdh}
                tripcompletedAdh={this.state.DD_tripCompletedAdh}
                dailycheck={this.state.DD_dailyCheck}
                dailycheckAdh={this.state.DD_dailyCheckAdh}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-2 w-20 mt-3 ms-4 gx-0 shadow bg-body rounded">
            <div className="head">
              <div className="head-text-1">ENERGY consumption</div>
              <div class="content_img">
                <img
                  className="dashboard_status_head_img-1"
                  src={require("../../assets/images/headernew/head.png")}
                  alt="next"
                />
                <div>Avg Power Consumption By Vehicle Today</div>
              </div>
            </div>

            <div>
              <DashboardVehicleEffciencyOne
                powerconsumedbyveh={this.state.PowerConsByVeh}
              />
            </div>
          </div>
          {/* <div className="col-lg-10 w-76 mt-3 ms-3 gx-0 shadow bg-body rounded">
            <div className="head">
              <div className="head-text-1">VEHICLE EFFICIENCY</div>
              <div class="content_img">
                <img
                  className="dashboard_status_head_img-1"
                  src={require("../../assets/images/headernew/head.png")}
                  alt="next"
                />
                <div>Vehicle Efficiency Today During Opearions</div>
              </div>
              <button
                type="button"
                className="vehicle-status-btn-four"
                onClick={this.navtoVehicleSummaryReport}
              >
                LIST VIEW
                <img
                  className="vehicle-status-button-png-four"
                  src={require("../../assets/images/arrow-red/red.png")}
                  alt="next"
                />
              </button>
            </div>
            <div>
              <DashboardVehicleEffciency
                vehoperatingunder1kwh={this.state.VE_vehOperatingUnder1kw}
                vehoperatingabove1kwh={this.state.VE_vehOperatingAbove1kw}
              />
            </div>
          </div> */}

          <div className="col-lg-4 w-30 mt-3 ms-3 gx-0 shadow bg-body rounded">
            <div className="head">
              <div className="head-text-1">VEHICLE EFFICIENCY</div>
              <div class="content_img">
                <img
                  className="dashboard_status_head_img-1"
                  src={require("../../assets/images/headernew/head.png")}
                  alt="next"
                />
                <div>Vehicle Efficiency Today During Opearions</div>
              </div>
              <button
                type="button"
                className="vehicle-status-btn-two"
                onClick={this.navtoVehicleSummaryReport}
              >
                LIST VIEW
                <img
                  className="vehicle-status-button-png-four"
                  src={require("../../assets/images/arrow-red/red.png")}
                  alt="next"
                />
              </button>
            </div>
            <div>
              <DashboardVehicleEffciency
                vehoperatingunder1kwh={this.state.VE_vehOperatingUnder1kw}
                vehoperatingabove1kwh={this.state.VE_vehOperatingAbove1kw}
              />
            </div>
          </div>
          <div className="col-lg-6 w-45 mt-3 ms-3 gx-0 shadow bg-body rounded">
            <div className="head">
              <div className="head-text-two">
                {" "}
                <div className="colorDotone" />
                LIVE SPEED BAND{" "}
              </div>
              <div class="content_img">
                <img
                  className="dashboard_status_head_img-1"
                  src={require("../../assets/images/headernew/head.png")}
                  alt="next"
                />
                <div>Speed Band</div>
              </div>
              <button
                type="button"
                className="vehicle-status-btn-three"
                onClick={this.navToSpeedBand}
              >
                VIEW MORE
                <img
                  className="vehicle-status-button-png-three"
                  src={require("../../assets/images/arrow-red/red.png")}
                  alt="next"
                />
              </button>
            </div>
            <div>
              <DashboardSpeedBand
                speedbanddatagraph={this.state.speedBandData}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6 w-46 mt-3 mb-3 ms-4 gx-0 shadow bg-body rounded">
            <div className="head">
              <div className="head-text-1">CURRENT CHARGE BAND STATUS</div>
              <div class="content_img">
                <img
                  className="dashboard_status_head_img-1"
                  src={require("../../assets/images/headernew/head.png")}
                  alt="next"
                />
                <div>Current Vehicles Charging Band Status</div>
              </div>
              <button
                type="button"
                className="vehicle-status-btn-seven"
                onClick={() => this.navToGraphPopup("chargingBand")}
                //clickdata={this.navToLiveDashboard}
              >
                VIEW MORE
                <img
                  className="vehicle-status-button-png-seven"
                  src={require("../../assets/images/arrow-red/red.png")}
                  alt="next"
                />
              </button>
            </div>
            <div>
              <DashboardChargeBandStatus piedata={this.state.chargeBandData} />
            </div>
          </div>
          <div className="col-lg-6 w-50 mt-3 mb-3 ms-3 gx-0 shadow bg-body rounded">
            <div className="head">
              <div className="head-text-1">BATTERY TEMPERATURE</div>
              <div class="content_img">
                <img
                  className="dashboard_status_head_img-1"
                  src={require("../../assets/images/headernew/head.png")}
                  alt="next"
                />
                <div>Current Vehicles Battery Temperature Status</div>
              </div>
              <button
                type="button"
                className="vehicle-status-btn-six"
                onClick={() => this.navToGraphPopup("temperatureStatus")}
                // clickdata={this.navToLiveDashboard}
              >
                LIST VIEW
                <img
                  className="vehicle-status-button-png-six"
                  src={require("../../assets/images/arrow-red/red.png")}
                  alt="next"
                />
              </button>
            </div>
            <div>
              <DashboardBatteryTemperature
                temperaturedata={this.state.batteryTempData}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps, {
  getDashboardStatusCountsAPI,
  getFleetUptimeDetailsAPI,
  getVehiclePerformanceDetailsAPI,
  getBatteryTempAPI,
  getChargeBandAPI,
  getStationAPI,
  getMapActionAPI,
  getAlertAPI,
  getSpeedbandGraphAPI,
})(withRouter(DashboardStatus));
