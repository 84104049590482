import React, { Fragment, Component } from "react";
import { matchSorter } from "match-sorter";
import "../kpi_contractual_popup/index.scss"
import { connect } from "react-redux";
import { withRouter } from "react-router";
import ReactTable from "react-table";
import moment from "moment";
import DatePicker from "react-datepicker";
import { addDays } from "date-fns";
import "react-datepicker/dist/react-datepicker";
import "react-datepicker/dist/react-datepicker-cssmodules.css";
import ReactLoader from "../react-loader/react-loader.component";
import "react-table/react-table.css";
//Actions
import { Modal } from "react-bootstrap";
import contractalKPIApiActions from "../../redux/contractual_kpi/actions";
const { getContractualScheduleAdherenceAPI } = contractalKPIApiActions;

var startTimestamp = new Date();
startTimestamp.setDate(1);
startTimestamp.setHours(0);
startTimestamp.setMinutes(0);
startTimestamp.setSeconds(0);

var endTimestamp = new Date();
endTimestamp.setHours(23);
endTimestamp.setMinutes(59);
endTimestamp.setSeconds(58);

class KpiContractualScheduleAdhPopup extends Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      addModalshow: false,
      selecteddata: {},
      editingfields:{},
      open: false,
      show: false,
      start_date: startTimestamp,
      end_date: endTimestamp,
      buttonClicked: "",
      responseData: [],
     
    };
  }

  handleDateChange(evt) {
    const value = evt.target.value;

    const name = evt.target.name;
    this.setState((prevState) => ({
      editingfields: {
        // object that we want to update

        ...prevState.editingfields, // keep all other key-value pairs
        [name]: moment(value).format("YYYY-MM-DD HH:mm:00"),
        // update the value of specific key
      },
    }));
    this.setState({ [name]: value });
  }
  componentDidUpdate(prevProps, prevState) {
    //console.log("haiiiii");
    if (prevProps != this.props) {
      this.setState({ show: this.props.show });
      this.setState({ responseData: this.props.data });
    }
  }

  componentDidMount() {
    this.getContractualScheduleAdhDetails();
  }

  onSubmit = (e) => {
    e.preventDefault();
    this.getContractualScheduleAdhDetails()
  };

  getContractualScheduleAdhDetails() {
    const { start_date, end_date } = this.state;
    if (start_date < end_date) {
    this.setState({ loading: true });
    const payload = {
      //id: "1",
      user_id: sessionStorage.getItem("userId"),
      customerId: sessionStorage.getItem("customerId"),
      start_timestamp: moment(start_date).format("YYYY-MM-DD HH:mm:ss"),
      end_timestamp: moment(end_date).format("YYYY-MM-DD HH:mm:ss"),
    };
    this.props.getContractualScheduleAdherenceAPI(payload);
  } else if (start_date > end_date) {
    alert("End Date should be greater than Start Date");
  } else {
    alert("End Date should be greater than Start Date");
  }
  }


  render() {
    

    return (
      <Modal
        show={this.props.show}
        size="xl"
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
        centered
        onHide={this.props.closemodal}
      >
        <Modal.Header closeButton style={{ borderBottom: "none" }}>
          <Modal.Title
            id="contained-modal-title-vcenter"
            //className="title"
            style={{
              marginLeft: "566px",
              color: "#083a81",
              fontSize: "16px",
              fontFamily: "roboto-bold",
            }}
          >
            Schedule Adherence
          </Modal.Title>
        </Modal.Header>
        <form
          className="kpi-update"
          autocomplete="off"
          onSubmit={this.saftykpihandleSubmit}
        >
          <Modal.Body
            style={{
              "max-height": "calc(100vh - 210px)",
            }}
          >
            <div className="kpi_contractual_div" style={{marginTop:"-30px"}}>
              <div className="kpi_contractual_div_labels">
                <label className="kpi_contractual_div_labels_style-1">
                  Start Date:
                </label>

                <DatePicker
                  autocomplete="off"
                  name="start_date"
                  selected={this.state.start_date}
                  maxDate={moment().toDate()}
                  //minDate={addDays(new Date(), -90)}
                   value={this.state.editingfields.start_date}

                  onChange={(value) =>
                    this.handleDateChange({
                      target: { name: "start_date", value },
                    })
                  }
                  timeInputLabel="Time:"
                  dateFormat="yyyy-MM-dd HH:mm:00"
                  showTimeInput
                />

                <label className="kpi_contractual_div_labels_style">
                  End Date:
                </label>

                <DatePicker
                  autocomplete="off"
                  name="end_date"
                  selected={this.state.end_date}
                  maxDate={moment().toDate()}
                   value={this.state.editingfields.end_date}
                  onChange={(value) =>
                    this.handleDateChange({
                      target: { name: "end_date", value },
                    })
                  }
                  timeInputLabel="Time:"
                  dateFormat="yyyy-MM-dd HH:mm:00"
                  showTimeInput
                />
              </div>

              <div>
                <button
                  className="general_safty_button_submit"
                  onClick={this.onSubmit}
                >
                  <i
                    className="fa fa-check"
                    style={{ paddingRight: "5px" }}
                  ></i>
                  Submit
                </button>
              </div>
            </div>

            <div>
            {this.props.ContractalKpiApi?.contractualScheduleAdherenceApi
                ?.loading ? (
                <div className="loader-overlay">
                  <div className="loader-container">
                    <ReactLoader />
                  </div>
                </div>
              ) : null}
              <ReactTable
                NoDataComponent={() => null}
                pageSizeOptions={[20, 30, 50, 80, 90, 100, 125, 130, 200, 500]}
                style={{
                  height: "460px",
                  marginTop: "-30px",
                }}
                data={this.state.responseData}
                columns={[
                  {
                    Header: "Date",
                    id: "created_date",
                    accessor: (d) => d.created_date,
                    filterAll: true,
                    width: 250,
                    resizable: false,
                  },

                  {
                    Header: "Total Trips",
                    id: "total_trips",
                    accessor: (d) => d.total_trips,
                   
                    filterAll: true,
                    width: 280,
                    resizable: false,
                  },
                  {
                    Header: "Actual Trips",
                    id: "actual_trips",
                    accessor: (d) => d.actual_trips,
                    filterAll: true,
                    width: 270,
                    resizable: false,
                  },
                  {
                    Header: "Driver Trips",
                    id: "driver_trips",
                    accessor: (d) => d.driver_trips,
                    filterAll: true,
                    width: 250,
                    resizable: false,
                  },
                  {
                    Header: "Adherence",
                    id: "availability",
                    accessor: (d) => d.availability,
                    filterAll: true,
                    width: 250,
                    resizable: false,
                  },
                ]}
                defaultPageSize={50}
                showPagination={false}
                className="-striped -highlight"
              />
            </div>
          </Modal.Body>
        </form>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps, {
  getContractualScheduleAdherenceAPI
})(withRouter(KpiContractualScheduleAdhPopup));
