//accidentsummarypopup

const contractalKPIAccidentApiActions = {

GET_CONTRACTUALACCIDENTSUMMARYPOPUP: "GET_CONTRACTUALACCIDENTSUMMARYPOPUP",
GET_CONTRACTUALACCIDENTSUMMARYPOPUP_SUCCESS: "GET_CONTRACTUALACCIDENTSUMMARYPOPUP_SUCCESS",
GET_CONTRACTUALACCIDENTSUMMARYPOPUP_FAILURE: "GET_CONTRACTUALACCIDENTSUMMARYPOPUP_FAILURE",

getContractualAccidentsummaryViewAPI: (data) => ({
  type: contractalKPIAccidentApiActions.GET_CONTRACTUALACCIDENTSUMMARYPOPUP,
  payload: { data },
}),

getContractualAccidentsummaryViewAPISuccess: (saveStatusCode, result) => ({
  type: contractalKPIAccidentApiActions.GET_CONTRACTUALACCIDENTSUMMARYPOPUP_SUCCESS,
  saveStatusCode,
  result,
}),

getContractualAccidentsummaryViewAPIFailure: (saveStatusCode, message) => ({
  type: contractalKPIAccidentApiActions.GET_CONTRACTUALACCIDENTSUMMARYPOPUP_FAILURE,
  saveStatusCode,
  message,
}),

};
  
export default contractalKPIAccidentApiActions;