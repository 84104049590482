import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import "./index.scss";
import Dashboard from "../../pages/dashboard-page";
import ReactLoader from "../../component/react-loader/react-loader.component";

//actions
import dataChangePopupApiActions from "../../redux/data_change_popup_one/actions";
const { getDataChangePopupAPI } = dataChangePopupApiActions;

let responseList = [];

class DataChangePopupOne extends Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      addModalshow: false,
      selecteddata: {},
      dataSet: [],
    };
    this.Navtodashboard = this.Navtodashboard.bind(this);
  }

  Navtodashboard(cust) {
    sessionStorage.setItem("showMenus", true);
    sessionStorage.setItem("customerId", cust);
    let tabItem = {
      tabIndex: 16,
      tabName: "Dashboard",
    };
    this.props.selectedTab(null, tabItem);

    // this.props.history.push("/dashboard");
  }

  componentDidMount() {
    //getting all the data
    this.getDatapopupProjectDetails();
  }

  getDatapopupProjectDetails() {
    this.setState({ loading: true });
    const payload = {
      user_id: sessionStorage.getItem("userId"),
      customerId: sessionStorage.getItem("customerId"),
    };
    this.props.getDataChangePopupAPI(payload);
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
    //  console.log("dataaaa", prevProps);
      if (
        this.props.DataChangePopupApi?.dataChangePopupApi?.result?.data
          .responseData?.length > 0
      ) {
        this.setState({
          dataSet:
            this.props.DataChangePopupApi.dataChangePopupApi.result.data
              .responseData,
        });
      } else {
        this.setState({ dataSet: [] });
      }
    }
  }

  render() {
    // console.log("dataset", this.state.dataSet);
    responseList = [];
    this.state.dataSet.forEach((res, index) => {
      // responseList.push(<li key={index}>{res}</li>);
      responseList.push(
        <div
          className="col-lg-4 wdh-20 ms-4 mt-4 gx-0 shadow bg-body rounded"
          key={index}
        >
          <div className="data_change_head">
            <div className="data_change_head_text">{res.project_name}</div>
          </div>
          <div>
            <p className="data_change_body_1">
              Total Veh Deployed : {res.deployed_vehicles}
            </p>
            <p className="data_change_body_2">
              No. of Veh In Operation : {res.operational_vehicles}
            </p>
            <p className="data_change_body_2">
              Daily Running of Veh : {res.daily_running_vehicles}
            </p>
            <p className="data_change_body_2">
              No. of B/D : {res.breakdown_count}
            </p>
            <p className="data_change_body_2">
              No of Accidents : {res.accident_count}
            </p>
            <button
              className="data_change_button"
              onClick={() => this.Navtodashboard(res.customer_id)}
            >
              Details
            </button>
          </div>
        </div>
      );
    });

    return (
      <div className="container-fluid">
        {this.props.DataChangePopupApi?.dataChangePopupApi?.loading ? (
          <div className="loader-overlay">
            <div className="loader-container">
              <ReactLoader />
            </div>
          </div>
        ) : null}

        <div className="row mt-5 ms-1">
          {/* <div className="col-4 mt-5 ms-2">
          <Card className="rounded-4 border-bottom-0" style={{ width: '19rem',height:"5rem",background:"rgb(213, 202, 202)" }}>
               
                <Card.Body>
                    <Card.Title className="text-primary text-center"><p>Card Title</p></Card.Title> */}
          {/* <Card.Text  className="text-primary">
                    Some quick example text to build on the card title and make up the bulk of
                    the card's content.
                    </Card.Text> */}

          {/* </Card.Body>
                </Card>
                <Card className="rounded-4" style={{ width: '19rem',height:"16rem",marginTop:"0px",background:"rgb(213, 202, 202)" }}>
               
               <Card.Body>
               <Card.Text className="text-primary text-center">
                   Some quick example text to build on the card title and make up the bulk of
                   the card's content.
                   </Card.Text>
                   <Card.Text className="text-primary text-center">
                   Some quick example text to build on the card title and make up the bulk of
                   the card's content.
                   </Card.Text>
                   <Card.Text className="text-primary text-center">
                   Some quick example text to build on the card 
                  
                   </Card.Text>
                  
               </Card.Body>
               </Card>
</div> */}

          {responseList}

          {/* <div className="col-lg-4 wdh-20 ms-4 mt-4 gx-0 shadow bg-body rounded">
            <div className="data_change_head">
              <div className="data_change_head_text">CTU - Chandigarh</div>
            </div>
            <div>
              <p className="data_change_body_1">Total Veh Deployed : 14</p>
              <p className="data_change_body_2">No. of Veh In Operation : 08</p>
              <p className="data_change_body_2">Daily Running of Veh : 02</p>
              <p className="data_change_body_2">No. of B/D : 02</p>
              <p className="data_change_body_2">No of Accidents : 0</p>
              <button
              className="data_change_button"
               onClick={this.Navtodashboard}
              >Go To</button>
            </div>
          </div> */}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps, {
  getDataChangePopupAPI,
})(withRouter(DataChangePopupOne));
