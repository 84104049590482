import React, { Component } from "react";
import "../view_more_button/index.scss";
import { connect } from "react-redux";
import { withRouter } from "react-router";

class ViewMore extends Component {
  constructor(props) {
    super(props);
    this.state = {

    };
  }



  setdata = (data) => {
  //console.log("haii")
    sessionStorage.setItem("fleetHealthcaseNoClicked", data);
    this.props.function();
  };

  render() {
    // let { inputSearch } = this.state;
    //console.log("view ore", this.props.data);
    return (
      <div>
      
         
            <button
              onClick={() => this.setdata(this.props.data.original.caseNo)}
              className="driver-details-action"
            >
              View Details
              <img
                className="driverdetails-action-img"
                src={require("../../assets/images/driver-monitoring/view-more.png")}
                alt=""
              />
            </button>
            
     
      </div>
    );
  }
}

export default ViewMore;
