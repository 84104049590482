import { all, takeEvery, put, call } from "redux-saga/effects";
import actions from "./actions";
import * as dataAccess from "../../utils/ajax";
import { speed_band_API, SPEED_BAND_LIST } from "../../constants/api-constants";

export const postLambda = async (url, payload) =>
  await dataAccess.postLambda(url, payload);

export function* getSpeedBandGraphApiSagas(data) {
  const { payload } = data;
  let url = speed_band_API;
  try {
    let response = yield call(postLambda, url, payload);
    if (response) {
      yield put(actions.getSpeedbandGraphAPISuccess(0, response));
    }
  } catch (error) {
    yield put(actions.getSpeedbandGraphAPIFailure(1));
  }
}

export function* getSpeedBandListApiSagas(data) {
  const { payload } = data;
  let url = SPEED_BAND_LIST;
  try {
    let response = yield call(postLambda, url, payload);
    if (response) {
      yield put(actions.getSpeedbandListAPISuccess(0, response));
    }
  } catch (error) {
    yield put(actions.getSpeedbandListAPIFailure(1));
  }
}
export default function* rootSaga() {
  yield all([
    takeEvery(actions.GET_SPEED_BAND, getSpeedBandGraphApiSagas),
    takeEvery(actions.GET_SPEED_BAND_LIST, getSpeedBandListApiSagas),
  ]);
}
