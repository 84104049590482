import { all, takeEvery, put, call } from "redux-saga/effects";
import actions from "./actions";
import * as dataAccess from "../../utils/ajax";
import {

  CONTRACTUAL_PUNCTUALITY_START_GRAPH,
  CONTRACTUAL_PUNCTUALITY_ARRIVAL_GRAPH,
} from "../../constants/api-constants";

export const postLambda = async (url, payload) =>
  await dataAccess.postLambda(url, payload);

export function* getContractualStartPunctualityGraphApiSagas(data) {
  const { payload } = data;
  let url = CONTRACTUAL_PUNCTUALITY_START_GRAPH;
  try {
    let response = yield call(postLambda, url, payload);

    if (response) {
      yield put(actions.getContractualStartPunctualGraphAPISuccess(0, response));
    }
  } catch (error) {
    yield put(actions.getContractualStartPunctualGraphAPIFailure(1));
  }
}

export function* getContractualArrivalPunctualityGraphApiSagas(data) {
  const { payload } = data;

  let url = CONTRACTUAL_PUNCTUALITY_ARRIVAL_GRAPH;
  try {
    let response = yield call(postLambda, url, payload);

    if (response) {
      yield put(actions.getContractualArrivalPunctualGraphAPISuccess(0, response));
    }
  } catch (error) {
    yield put(actions.getContractualArrivalPunctualGraphAPIFailure(1));
  }
}

export default function* rootSaga() {
    yield all([
      takeEvery(actions.GET_CONTRACTUALSTARTPUNCTUALGRAPHDETAILS, getContractualStartPunctualityGraphApiSagas),
      takeEvery(actions.GET_CONTRACTUALARRIVALPUNCTUALGRAPHDETAILS, getContractualArrivalPunctualityGraphApiSagas),
    ]);
  }

