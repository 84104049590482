import React, { Component } from "react";
import BackToScreen from "../../component/back_to_screen";
import FleetUptimeCurrentView from "../../component/fleet_uptime_current_view";
import FleetUptimeHistoricView from "../../component/fleet_uptime_historic_view";
import "../fleet_uptime/index.scss";
class FleetUptime extends Component {
  constructor(props) {
    super(props);
    this.messageTimeOut = null;
    //let loginId = User.getLoginId();
    this.state = {
      selTabs: 1,
      message: "",
      messageType: 0,
      tabs: [
        // {
        //   tabIndex: 0,
        //   tabName: "Current View",
        // },
        {
          tabIndex: 1,
          tabName: "Historic View",
        },
      ],
    };
    this.backtoFleetHealth = this.backtoFleetHealth.bind(this);
    this.tabClicks = this.tabClicks.bind(this);
  }

  tabClicks = (evt, tabItem) => {
    // const { selectedTab } = this.props;
    this.onClickCloseMessage();

    if (evt.target.parentElement) {
      let tabs = evt.target.parentElement.getElementsByClassName(
        "fleet-uptime__layout__col-1__block__wrapper__header__left__tab"
      );
      Array.prototype.forEach.call(tabs, function (tab) {
        tab.classList.remove("active");
      });
    }
    evt.currentTarget.classList.add("active");

    this.setState({ selTabs: tabItem.tabIndex });
    // selectedTab && selectedTab(evt, tabItem);
  };

  tabClicksBackRouting = (evt, tabItem) => {
    const { selectedTab } = this.props;
    this.onClickCloseMessage();

    if (evt.target.parentElement) {
      let tabs = evt.target.parentElement.getElementsByClassName(
        "fleet-uptime__layout__col-1__block__wrapper__header__left__tab"
      );
      Array.prototype.forEach.call(tabs, function (tab) {
        tab.classList.remove("active");
      });
    }
    evt.currentTarget.classList.add("active");

    this.setState({ selTabs: tabItem.tabIndex });
    selectedTab && selectedTab(evt, tabItem);
  };

  onClickCloseMessage() {
    this.messageTimeOut = null;
    this.setState({
      message: "",
      messageType: 0,
    });
  }

  // backtoFleetHealth=()=> {

  //     let tabItem = {
  //       tabIndex: 21,
  //       tabName: "Fleet Health",
  //     };
  //     this.props.selectedTab(null, tabItem);

  // }

  backtoFleetHealth = () => {
    let tabItem = {
      tabIndex: 21,
      tabName: "Fleet Health",
    };
    this.props.selectedTab(null, tabItem);
  };

  render() {
    const { tabs, selTabs } = this.state;
    let tabDoms = [];
    if (tabs && tabs.length > 0) {
      tabs.map(function (tabItem, index) {
        let className =
          selTabs === tabItem.tabIndex
            ? "active fleet-uptime__layout__col-1__block__wrapper__header__left__tab ripple-effect"
            : "fleet-uptime__layout__col-1__block__wrapper__header__left__tab ripple-effect";
        tabDoms.push(
          <li
            className={className}
            key={tabItem.tabIndex}
            onClick={(e) => this.tabClicks(e, tabItem)}
          >
            <a>{tabItem.tabName}</a>
            <span className="tab-strips-1"></span>
          </li>
        );
      }, this);
    }
    return (
      <div className="fleet-uptime">
        <div className="fleet-uptime__layout">
          <div className="fleet-uptime__layout__col-1">
            <div className="fleet-uptime__layout__col-1__block">
              <div className="fleet-uptime__layout__col-1__block__wrapper">
                {/* <p>   <img  onClick={this.backtoFleetHealth}
                      className=""
                      src={require("../../assets/images/fleet-health/left-arrow.png")}
                      alt=""
                    />
                    Back</p> */}
                <div className="fleet-uptime-backward">
                  <BackToScreen routingCall={this.backtoFleetHealth} />
                  <h3 className="fleet-uptime-heading">Fleet Uptime</h3>
                </div>
                <div className="fleet-uptime__layout__col-1__block__wrapper__header">
                  <div className="fleet-uptime__layout__col-1__block__wrapper__header__left">
                    {tabDoms}
                    {this.state.message && (
                      <div
                        className={`alert ${
                          this.state.messageType == 1
                            ? "success"
                            : this.state.messageType == -1
                            ? "error"
                            : ""
                        }`}
                      >
                        <span
                          className="closebtn"
                          onClick={() => this.onClickCloseMessage()}
                        >
                          &times;
                        </span>
                        <strong>{this.state.message}</strong>
                      </div>
                    )}
                  </div>
                </div>
                <hr className="fleet-uptime__layout__col-1__block__wrapper__horizontal-line" />
                <div className="fleet-uptime__layout__col-1__block__wrapper__content">
                  {(() => {
                    if (selTabs === 1) {
                      return <FleetUptimeHistoricView />;
                    } 
                    // else {
                    //   return <FleetUptimeHistoricView />;
                    // }
                  })()}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default FleetUptime;
