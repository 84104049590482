import actions from "./actions";
import { combineReducers } from "redux";

const initStateSpeedBandGraph = {
  loading: false,
  result: null,
  error: false,
};
const initStateSpeedBandList = {
  loading: false,
  result: null,
  error: false,
};

function speedBandGraphApi(state = initStateSpeedBandGraph, action) {
  switch (action.type) {
    case actions.GET_SPEED_BAND:
      return {
        ...state,
        loading: true,
      };
    case actions.GET_SPEED_BAND_SUCCESS:
      return {
        ...state,
        loading: false,
        result: action.result,
      };
    case actions.GET_SPEED_BAND_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        result: {
          errorResponse: action.saveStatusCode,
          message: action.message,
        },
      };
    default:
      return state;
  }
}

function speedBandListApi(state = initStateSpeedBandList, action) {
  switch (action.type) {
    case actions.GET_SPEED_BAND_LIST:
      return {
        ...state,
        loading: true,
      };
    case actions.GET_SPEED_BAND_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        result: action.result,
      };
    case actions.GET_SPEED_BAND_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        result: {
          errorResponse: action.saveStatusCode,
          message: action.message,
        },
      };
    default:
      return state;
  }
}

export default combineReducers({
  speedBandGraphApi,
  speedBandListApi,
});
