import React, { Component } from 'react';
import "../../container/contractual_kpi/index.scss"
import BackToScreen from "../../component/back_to_screen";
import SafetyKPI from '../../component/safety_kpi';
import ComplianceKPI from '../../component/compliance_kpi';
import Sustainance from '../../component/sustainance_kpi';
import FinancialKPI from '../../component/finacial_kpi';
import KPIcontractual from '../../component/kpi_contractual';
class ContractualKPI extends Component {
    constructor(props) {
        super(props);
        this.messageTimeOut = null;
        this.state = {
          selTab: 0,
          message: "",
          messageType: 0,
          tabs: [
            {
              tabIndex: 0,
              tabName: "SAFETY",
            },
            {
              tabIndex: 1,
              tabName: "CONTRACTUAL",
              
            },
            {
                tabIndex: 2,
                tabName: "FINANCIAL",
              },
              {
                tabIndex: 3,
                tabName: "SUSTAINANCE",
                
              },
              {
                tabIndex: 4,
                tabName: "COMPLIANCE",
              },
             
          ],
        };
        this.backtodashboardPage = this.backtodashboardPage.bind(this);
        this.tabClick = this.tabClick.bind(this);
      }
    
      tabClick = (evt, tabItem) => {
        const { selectedTab } = this.props;
        this.onClickCloseMessage();
    
        if (evt.target.parentElement) {
          let tabs = evt.target.parentElement.getElementsByClassName(
            "contractual_kpi__layout__col-1__block__wrapper__header__left__tab"
          );
          Array.prototype.forEach.call(tabs, function (tab) {
            tab.classList.remove("active");
          });
        }
        evt.currentTarget.classList.add("active");
    
        this.setState({ selTab: tabItem.tabIndex });
        //selectedTab && selectedTab(evt, tabItem);
      };
    
      onClickCloseMessage() {
        this.messageTimeOut = null;
        this.setState({
          message: "",
          messageType: 0,
        });
      }
    
      backtodashboardPage() {
        let tabItem = {
          tabIndex: 16,
          tabName: "Dashboard",
        };
        this.props.selectedTab(null, tabItem);
      };
    
      render() {
        const { tabs, selTab } = this.state;
    
        let tabDom = [];
    
        if (tabs && tabs.length > 0) {
          tabs.map(function (tabItem, index) {
            let className =
              selTab === tabItem.tabIndex
                ? "active contractual_kpi__layout__col-1__block__wrapper__header__left__tab ripple-effect"
                : "contractual_kpi__layout__col-1__block__wrapper__header__left__tab ripple-effect";
            tabDom.push(
              <li
                className={className}
                key={tabItem.tabIndex}
                onClick={(e) => this.tabClick(e, tabItem)}
              >
                <a>{tabItem.tabName}</a>
                <span className="tab-strips-3"></span>
              </li>
            );
          }, this);
        }
    
    
        return (
          <div className="contractual_kpi ">
            <div className="contractual_kpi__layout tabMargin">
              <div className="contractual_kpi__layout__col-1">
                <div className="contractual_kpi__layout__col-1__block">
                  <div className="contractual_kpi__layout__col-1__block__wrapper tabHeight">
                  <div className="fleet-uptime-backward">
          <BackToScreen routingCall={this.backtodashboardPage} />
                    <h2 className="contractual_kpi-heading">Contractual KPI</h2>
                    </div>
                    <div className="contractual_kpi__layout__col-1__block__wrapper__header">
                      <div className="contractual_kpi__layout__col-1__block__wrapper__header__left">
                        {tabDom}
                        <span className="tab-strip"></span>
                      </div>
                    </div>
                    <hr className="contractual_kpi__layout__col-1__block__wrapper__horizontal-line" />
                    <div className="contractual_kpi__layout__col-1__block__wrapper__content tabHeightTrend">
                      {(() => {
                        if (selTab === 0) {
                          return <SafetyKPI/>;
                       
                        } else if(selTab === 1) {
                          return <KPIcontractual/>;
                        }
                        else if(selTab === 2) {
                          return<FinancialKPI/>;
                        }
                        else if(selTab === 3) {
                          return <Sustainance/>;
                        }
                        else {
                          return<ComplianceKPI/>;
                        }
                      })()}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      }
    }
    
    // const mapStateToProps = (state) => {
    //   return {
    //     ...state,
    //   };
    // };
    
    // export default connect(mapStateToProps, {})(withRouter(ContractualKPI));
    

    export default ContractualKPI;




