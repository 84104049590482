import React, { Component } from "react";
import "./index.scss";

class DashbordUnplannedStop extends Component {
  constructor(props) {
    super(props);
    this.state = {
      //data: [],
    };
  }

  accidentClicked() {
    return this.props.clickdata1;
  }

  breakdownClicked() {
    return this.props.clickdata2;
  }

  render() {
    return (
      <div className="unplaned_stop">
        <div className="unplaned_stop_one" onClick={this.breakdownClicked()}>
          <img
            className="unplaned_stop_image_one"
            src={require("../../assets/images/unplanned/1.png")}
            alt=""
          />
          <div>
            <div className="unplaned-number-one">{this.props.breakdown}</div>
            <p className="unplaned-text-one">
              {" "}
              BREAKDOWN <br /> REPORTED
            </p>
          </div>
        </div>
        <hr className="unplaned_perpendiculars-line" />
        <div className="double-image-two" onClick={this.accidentClicked()}>
          <img
            className="unplaned_stop_image_two"
            src={require("../../assets/images/unplanned/2.png")}
            alt=""
          />
          <div className="unplaned-number-one-1">{this.props.accident}</div>
          <p className="unplaned-text-one-1">
            ACCIDENT <br /> REPORTED
          </p>
        </div>
      </div>
    );
  }
}
export default DashbordUnplannedStop;
