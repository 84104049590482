import { all, takeEvery, put, call } from "redux-saga/effects";
import actions from "./actions";
import * as dataAccess from "../../utils/ajax";
import {
  KPI_FINACIAL,
  KPI_CONTRACTUAL,
  KPI_SUSTAINANCE,
  KPI_DRIVERSCORE,
  SAFTY_CONTRACTUAL,
  ASSURED_FLEET_AVAILABILITY_LIST,
  SEVERE_SAFETY_LIST,
  GENERAL_SAFETY_LIST,
  CONTRACTUAL_SCHEDULE_ADHERENCE,
  CONTRACTUAL_RELIABILITY_POPUP,
  CONTRACTUAL_BREAKDOWN_SUMMARY,
  //CONTRACTUAL_ACCIDENT_SUMMARY,
  CONTRACTUAL_PUNCTUALITY_START_WEEKLY,
  CONTRACTUAL_PUNCTUALITY_ARRIVAL_WEEKLY,
} from "../../constants/api-constants";

export const postLambda = async (url, payload) =>
  await dataAccess.postLambda(url, payload);

export function* getContractaulDetailsApiSagas(data) {
  const { payload } = data;
  let url = KPI_CONTRACTUAL;
  try {
    let response = yield call(postLambda, url, payload);

    if (response) {
      yield put(actions.getContractualDetailsAPISuccess(0, response));
    }
  } catch (error) {
    yield put(actions.getContractualDetailsAPIFailure(1));
  }
}
//
export function* getContractaulAssuredAvailabilitylistApiSagas(data) {
  const { payload } = data;
  let url = ASSURED_FLEET_AVAILABILITY_LIST;
  try {
    let response = yield call(postLambda, url, payload);

    if (response) {
      yield put(
        actions.getContractualAssuredavailabilityAPISuccess(0, response)
      );
    }
  } catch (error) {
    yield put(actions.getContractualAssuredavailabilityAPIFailure(1));
  }
}

export function* getFinancialDetailsApiSagas(data) {
  const { payload } = data;
  let url = KPI_FINACIAL;
  try {
    let response = yield call(postLambda, url, payload);

    if (response) {
      yield put(actions.getFinancialDetailsAPISuccess(0, response));
    }
  } catch (error) {
    yield put(actions.getFinancialDetailsAPIFailure(1));
  }
}

export function* getSustainanceDetailsApiSagas(data) {
  const { payload } = data;
  let url = KPI_SUSTAINANCE;
  try {
    let response = yield call(postLambda, url, payload);

    if (response) {
      yield put(actions.getSustainanceDetailsAPISuccess(0, response));
    }
  } catch (error) {
    yield put(actions.getSustainanceDetailsAPIFailure(1));
  }
}

export function* getDriverScoreDetailsApiSagas(data) {
  const { payload } = data;
  let url = KPI_DRIVERSCORE;
  try {
    let response = yield call(postLambda, url, payload);

    if (response) {
      yield put(actions.getDriverScoreDetailsAPISuccess(0, response));
    }
  } catch (error) {
    yield put(actions.getDriverScoreDetailsAPIFailure(1));
  }
}

export function* getSaftyContractualApiSagas(data) {
  const { payload } = data;
  let url = SAFTY_CONTRACTUAL;
  try {
    let response = yield call(postLambda, url, payload);

    if (response) {
      yield put(actions.getSaftyContractualAPISuccess(0, response));
    }
  } catch (error) {
    yield put(actions.getSaftyContractualAPIFailure(1));
  }
}

export function* getGeneralaftyContractualApiSagas(data) {
  const { payload } = data;
  let url = GENERAL_SAFETY_LIST;
  try {
    let response = yield call(postLambda, url, payload);

    if (response) {
      yield put(actions.getGeneralSaftyContractualAPISuccess(0, response));
    }
  } catch (error) {
    yield put(actions.getGeneralSaftyContractualAPIFailure(1));
  }
}

export function* getSevereSaftyContractualApiSagas(data) {
  const { payload } = data;
  let url = SEVERE_SAFETY_LIST;
  try {
    let response = yield call(postLambda, url, payload);

    if (response) {
      yield put(actions.getSevereSaftyContractualAPISuccess(0, response));
    }
  } catch (error) {
    yield put(actions.getSevereSaftyContractualAPIFailure(1));
  }
}

export function* getContractualScheduleAdherenceApiSagas(data) {
  const { payload } = data;
  let url = CONTRACTUAL_SCHEDULE_ADHERENCE;
  try {
    let response = yield call(postLambda, url, payload);

    if (response) {
      yield put(actions.getContractualScheduleAdherenceAPISuccess(0, response));
    }
  } catch (error) {
    yield put(actions.getContractualScheduleAdherenceAPIFailure(1));
  }
}

export function* getContractualReliabilityApiSagas(data) {
  const { payload } = data;
  let url = CONTRACTUAL_RELIABILITY_POPUP;
  try {
    let response = yield call(postLambda, url, payload);

    if (response) {
      yield put(actions.getContractualReliabilityAPISuccess(0, response));
    }
  } catch (error) {
    yield put(actions.getContractualReliabilityAPIFailure(1));
  }
}

export function* getContractualBreakdownSummaryApiSagas(data) {
  const { payload } = data;
  let url = CONTRACTUAL_BREAKDOWN_SUMMARY;
  try {
    let response = yield call(postLambda, url, payload);

    if (response) {
      yield put(
        actions.getContractualBreakdownsummaryViewAPISuccess(0, response)
      );
    }
  } catch (error) {
    yield put(actions.getContractualBreakdownsummaryViewAPIFailure(1));
  }
}


//start

export function* getContractualstartPunctualityWeeklyApiSagas(data) {
  const { payload } = data;
  let url = CONTRACTUAL_PUNCTUALITY_START_WEEKLY;
  try {
    let response = yield call(postLambda, url, payload);

    if (response) {
      yield put(actions.getStartPunctualityWeeklyDataAPISuccess(0, response));
    }
  } catch (error) {
    yield put(actions.getStartPunctualityWeeklyDataAPIFailure(1));
  }
}


export function* getContractualarrivalPunctualityWeeklyApiSagas(data) {
  const { payload } = data;
  let url = CONTRACTUAL_PUNCTUALITY_ARRIVAL_WEEKLY;
  try {
    let response = yield call(postLambda, url, payload);

    if (response) {
      yield put(actions.getArrivalPunctualityWeeklyDataAPISuccess(0, response));
    }
  } catch (error) {
    yield put(actions.getArrivalPunctualityWeeklyDataAPIFailure(1));
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.GET_CONTRACTUALDETAILS, getContractaulDetailsApiSagas),
    takeEvery(actions.GET_FINANCIALDETAILS, getFinancialDetailsApiSagas),
    takeEvery(actions.GET_SUSTAINANCEDETAILS, getSustainanceDetailsApiSagas),
    takeEvery(actions.GET_DRIVERSCOREDETAILS, getDriverScoreDetailsApiSagas),
    takeEvery(actions.GET_SAFTYCONTRACTUAL, getSaftyContractualApiSagas),
    takeEvery(
      actions.GET_CONTRACTUAL_AASURED_AVAILABITY_LIST,
      getContractaulAssuredAvailabilitylistApiSagas
    ),
    takeEvery(
      actions.GET_GENERALSAFTYCONTRACTUALLIST,
      getGeneralaftyContractualApiSagas
    ),
    takeEvery(
      actions.GET_SEVERESAFTYCONTRACTUALLIST,
      getSevereSaftyContractualApiSagas
    ),
    takeEvery(
      actions.GET_CONTRACTUALSCHEDULEADHERENCE,
      getContractualScheduleAdherenceApiSagas
    ),

    takeEvery(
      actions.GET_CONTRACTUALSRELIABILITY,
      getContractualReliabilityApiSagas
    ),
    takeEvery(
      actions.GET_CONTRACTUALBREAKDOWNSUMMARYPOPUP,
      getContractualBreakdownSummaryApiSagas
    ),
    // takeEvery(actions.GET_STARTPUNCTUALITYMONTHLYDATA,getContractualstartPunctualityMonthlyApiSagas),
    takeEvery(
      actions.GET_STARTPUNCTUALITYWEEKLYDATA,
      getContractualstartPunctualityWeeklyApiSagas
    ),

    // takeEvery(actions.GET_ARRIVALPUNCTUALITYMONTHLYDATA,getContractualarrivalPunctualityMonthlyApiSagas),
    takeEvery(
      actions.GET_ARRIVALPUNCTUALITYWEEKLYDATA,
      getContractualarrivalPunctualityWeeklyApiSagas
    ),

   
  ]);
}
