import React, { Component } from "react";
import "./index.scss";

class LoginHeader extends Component {
  render() {
    return (
      <div className="container-fluid logo_bg_color">
        <div className="row">
          <div className="col-md-2 mt-4 ms-3 wd-15">
            <img
              src={require("../../assets/VE_left_logo.png")}
              className="logo_bg_color"
              alt="logo"
              onClick={this.onLogoClick}
            />
          </div>
          <div className="col-md-2 wd-15 ms-4"></div>
          <div className="col-md-2 ms-4"></div>
          <div className="col-md-2 ms-4"></div>
          <div className="col-md-2 ms-4"></div>
          <div className="col-md-1 mt-4">
            <img
              src={require("../../assets/VE_right_logo.png")}
              className="logo_bg_color"
              alt="logo"
              onClick={this.onLogoClick}
            />
          </div>
        </div>
      </div>
    );
  }
}
export default LoginHeader;
