import React, { Component } from "react";
import "./index.scss";
import ReactTable from "react-table";
import ReactTooltip from "react-tooltip";
import "react-table/react-table.css";
import Workbook from "react-excel-workbook";
import { format ,subMonths } from 'date-fns';
import ReactLoader from "../../component/react-loader/react-loader.component";
//import moment from "moment";

import "react-datepicker/dist/react-datepicker.css";
import BackToScreen from "../../component/back_to_screen";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

import efficiencyReportApiActions from "../../redux/efficiency_report/actions";
import { connect } from "react-redux";
import { withRouter } from "react-router";

const { getEfficiencyReportAPI } = efficiencyReportApiActions;

class EfficiencyReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      Chargesummaryreport: [],
      tempData: [],
      filteredData: [],
      searchInput: "",
      date: new Date(),
      editingfields: {},
    };
    this.backtodashboardPage = this.backtodashboardPage.bind(this);
  }

  componentDidMount() {
    this.getEfficiencyReports();
  }

  getEfficiencyReports() {
    let payLoad = {
      user_id: sessionStorage.getItem("userId"),
      customerId: sessionStorage.getItem("customerId"),
    };
    this.props.getEfficiencyReportAPI(payLoad);
  }

  componentWillReceiveProps(nextProps) {
    console.log("efficiencyreport", nextProps);
    if (
      nextProps?.EfficiencyReportApi?.efficiencyReportsApi?.result?.data
        ?.responseData
    ) {
      this.setState({
        data: nextProps.EfficiencyReportApi.efficiencyReportsApi.result.data
          .responseData,
      });
      this.setState({
        filteredData:
          nextProps.EfficiencyReportApi.efficiencyReportsApi.result.data
            .responseData,
      });
    }
  }



  backtodashboardPage() {
    let tabItem = {
      tabIndex: 16,
      tabName: "Dashboard",
    };
    this.props.selectedTab(null, tabItem);
  }

  render() {
    const currentMonth = format(new Date(), 'MMMM');
    //const previousMonth = format(subMonths(new Date(), 1), 'MMMM');
    return (
      <div>
        {this.props.EfficiencyReportApi?.efficiencyReportsApi?.loading ? (
          <div className="loader-overlay">
            <div className="loader-container">
              <ReactLoader />
            </div>
          </div>
        ) : null}

        <div className="efficiency_report-backward">
          <BackToScreen routingCall={this.backtodashboardPage} />
          <h3 className="efficiency_report">Efficiency Report</h3>
          <div>
            <Workbook
              filename="Efficiency-Report.xlsx"
              element={
                <div
                  className="efficiency_report-downloads"
                  data-tip
                  data-for="registerTip"
                >
                  Export
                  <img
                    src={require("../../assets/images/export/export.png")}
                    alt=""
                    className="efficiency_report-download-imgs"
                  />
                  <ReactTooltip id="registerTip" place="top" effect="solid">
                    Download
                  </ReactTooltip>
                </div>
              }
            >
              <Workbook.Sheet data={this.state.data} name="Efficiency-Report">
              <Workbook.Column label="Reg No" value="regNo" />
                <Workbook.Column label={`1st ${currentMonth}`} value="date1" />
                <Workbook.Column label={`2nd ${currentMonth}`} value="date2" />
                <Workbook.Column label={`3rd ${currentMonth}`} value="date3" />
                <Workbook.Column label={`4th ${currentMonth}`} value="date4" />
                <Workbook.Column label={`5th ${currentMonth}`} value="date5" />
                <Workbook.Column label={`6th ${currentMonth}`} value="date6" />

                <Workbook.Column label={`7th ${currentMonth}`} value="date7" />
                <Workbook.Column label={`8th ${currentMonth}`} value="date8" />
                <Workbook.Column label={`9th ${currentMonth}`} value="date9" />
                <Workbook.Column label={`10th ${currentMonth}`} value="date10" />
                <Workbook.Column label={`11th ${currentMonth}`} value="date11" />
                <Workbook.Column label={`12th ${currentMonth}`} value="date12" />
                <Workbook.Column label={`13th ${currentMonth}`} value="date13" />
                <Workbook.Column label={`14th ${currentMonth}`} value="date14" />
                <Workbook.Column label={`15th ${currentMonth}`} value="date15" />
                <Workbook.Column label={`16th ${currentMonth}`} value="date16" />
                <Workbook.Column label={`17th ${currentMonth}`} value="date17" />
                <Workbook.Column label={`18th ${currentMonth}`} value="date18" />
                <Workbook.Column label={`19th ${currentMonth}`} value="date19" />
                <Workbook.Column label={`20th ${currentMonth}`} value="date20" />
                <Workbook.Column label={`21th ${currentMonth}`} value="date21" />
                <Workbook.Column label={`22nd ${currentMonth}`} value="date22" />
                <Workbook.Column label={`23rd ${currentMonth}`} value="date23" />
                <Workbook.Column label={`24th ${currentMonth}`} value="date24" />
                <Workbook.Column label={`25th ${currentMonth}`} value="date25" />
                <Workbook.Column label={`26th ${currentMonth}`} value="date26" />
                <Workbook.Column label={`27th ${currentMonth}`} value="date27" />
                <Workbook.Column label={`28th ${currentMonth}`} value="date28" />
                <Workbook.Column label={`29th ${currentMonth}`} value="date29" />
                <Workbook.Column label={`30th ${currentMonth}`} value="date30" />
                <Workbook.Column label={`31st ${currentMonth}`} value="date31" />
               
              </Workbook.Sheet>
            </Workbook>
          </div>
        </div>

        {/* <div className="charge_summary_div">
        <FormControl sx={{ m: 1, minWidth: 170 }} size="small">
            <InputLabel style={{marginTop:"-5px"}} id="demo-simple-select-autowidth-label">
              Duration
            </InputLabel>
            <Select style={{fontSize:"12px",fontFamily:"roboto-bold"}}
              labelId="demo-simple-select-autowidth-label"
              id="demo-simple-select-autowidth"
              value={this.state.selectedOptions}
              label="selectedVehicle"
              onChange={this.handleSelectChange}
            >
              <MenuItem value="Today">January</MenuItem>
              <MenuItem value="Yesterday">February</MenuItem>
              <MenuItem value="Last 7 Days">March</MenuItem>
              <MenuItem value="Last 1 Month">April</MenuItem>
              <MenuItem value="Last 3 Month">May</MenuItem>
              <MenuItem value="Last 3 Month">June</MenuItem>
              <MenuItem value="Last 3 Month"> July</MenuItem>
              <MenuItem value="Last 3 Month">August</MenuItem>
              <MenuItem value="Last 3 Month">September</MenuItem>
              <MenuItem value="Last 3 Month">October</MenuItem>
              <MenuItem value="Last 3 Month">November</MenuItem>
              <MenuItem value="Last 3 Month">December</MenuItem>
              
            </Select>
          </FormControl>
          <button className="efficiency_report_submit" onClick={this.onSubmit}>
              Submit
            </button>
        </div> */}
        <div className="efficiency__report_table">
          <ReactTooltip place="left" type="success" effect="solid" />
          <ReactTable
            NoDataComponent={() => null}
            pageSizeOptions={[20, 30, 50, 80, 90, 100, 125, 130, 200, 500]}
            style={{
              height: "539px",
              marginTop: "0%",
              marginLeft: "40px",
              marginRight: "30px",
            }}
            data={this.state.data}
            columns={[
              {
                Header: "Sl. No.",
                id: "serialNumber",
                filterable: false,
                resizable: false,
                width: 90,
                Cell: (row) => {
                  return <div>{row.index + 1}</div>;
                },
              },
              {
                Header: "Reg No.",
                id: "regNo",
                accessor: (d) => d.regNo,
                width: 100,

                filterAll: true,
                resizable: false,
              },
              {
                Header: `1st ${currentMonth} (Kwh/Km) `,
                id: "date1",
                accessor: (d) => d.date1,
                width: 130,
                filterAll: true,
                resizable: false,
              },
              {
                Header: `2nd ${currentMonth} (Kwh/Km)`,
                id: "date2",
                accessor: (d) => d.date2,
                width: 150,

                filterAll: true,
                resizable: false,
              },

              {
                Header: `3rd ${currentMonth} (Kwh/Km)`,
                id: "date3",
                accessor: (d) => d.date3,
                width: 150,

                resizable: false,
              },
              {
                Header: `4th ${currentMonth} (Kwh/Km)`,
                id: "date4",
                accessor: (d) => d.date4,
                width: 150,

                resizable: false,
              },
              {
                Header: `5th ${currentMonth} (Kwh/Km)`,
                id: "date5",
                accessor: (d) => d.date5,
                width: 150,

                filterAll: true,
                resizable: false,
              },

              {
                Header: `6th ${currentMonth} (Kwh/Km)`,
                id: "date6",
                accessor: (d) => d.date6,
                width: 150,

                filterAll: true,
                resizable: false,
              },

              {
                Header: `7th ${currentMonth} (Kwh/Km)`,
                id: "date7",
                accessor: (d) => d.date7,
                width: 150,

                filterAll: true,
                resizable: false,
              },
              {
                Header: `8th ${currentMonth} (Kwh/Km)`,
                id: "date8",
                accessor: (d) => d.date8,
                width: 150,

                filterAll: true,
                resizable: false,
              },
              {
                Header: `9th ${currentMonth} (Kwh/Km)`,
                id: "date9",
                accessor: (d) => d.date9,
                width: 150,

                filterAll: true,
                resizable: false,
              },
              {
                Header: `10th ${currentMonth} (Kwh/Km)`,
                id: "date10",
                accessor: (d) => d.date10,
                width: 150,

                filterAll: true,
                resizable: false,
              },
              {
                Header: `11th ${currentMonth} (Kwh/Km)`,
                id: "date11",
                accessor: (d) => d.date11,
                width: 150,

                filterAll: true,
                resizable: false,
              },

              {
                Header: `12th ${currentMonth} (Kwh/Km)`,
                id: "date12",
                accessor: (d) => d.date12,
                width: 150,

                filterAll: true,
                resizable: false,
              },
              {
                Header: `13th ${currentMonth} (Kwh/Km)`,
                id: "date13",
                accessor: (d) => d.date13,
                width: 150,

                filterAll: true,
                resizable: false,
              },
              {
                Header: `14th ${currentMonth} (Kwh/Km)`,
                id: "date14",
                accessor: (d) => d.date14,
                width: 150,

                filterAll: true,
                resizable: false,
              },
              {
                Header: `15th ${currentMonth} (Kwh/Km)`,
                id: "date15",
                accessor: (d) => d.date15,
                width: 100,

                filterAll: true,
                resizable: false,
              },
              {
                Header: `16th ${currentMonth} (Kwh/Km)`,
                id: "date16",
                accessor: (d) => d.date16,
                width: 150,

                filterAll: true,
                resizable: false,
              },
              {
                Header: `17th ${currentMonth} (Kwh/Km)`,
                id: "date17",
                accessor: (d) => d.date17,
                width: 150,

                filterAll: true,
                resizable: false,
              },
              {
                Header: `18th ${currentMonth} (Kwh/Km)`,
                id: "date18",
                accessor: (d) => d.date18,
                width: 150,

                filterAll: true,
                resizable: false,
              },

              {
                Header: `19th ${currentMonth} (Kwh/Km)`,
                id: "date19",
                accessor: (d) => d.date19,
                width: 150,
                filterAll: true,
                resizable: false,
              },
              {
                Header: `20th ${currentMonth} (Kwh/Km)`,
                id: "date20",
                accessor: (d) => d.date20,
                width: 150,

                filterAll: true,
                resizable: false,
              },
              {
                Header: `21st ${currentMonth} (Kwh/Km)`,
                id: "date21",
                accessor: (d) => d.date21,
                width: 150,

                filterAll: true,
                resizable: false,
              },
              {
                Header: `22nd ${currentMonth} (Kwh/Km)`,
                id: "date22",
                accessor: (d) => d.date22,
                width: 150,

                filterAll: true,
                resizable: false,
              },
              {
                Header: `23rd ${currentMonth} (Kwh/Km)`,
                id: "date23",
                accessor: (d) => d.date23,
                width: 150,

                filterAll: true,
                resizable: false,
              },
              {
                Header: `24th ${currentMonth} (Kwh/Km)`,
                id: "date24",
                accessor: (d) => d.date24,
                width: 150,

                filterAll: true,
                resizable: false,
              },
              {
                Header: `25th ${currentMonth} (Kwh/Km)`,
                id: "date25",
                accessor: (d) => d.date25,
                width: 150,

                filterAll: true,
                resizable: false,
              },
              {
                Header: `26th ${currentMonth} (Kwh/Km)`,
                id: "date26",
                accessor: (d) => d.date26,
                width: 150,

                filterAll: true,
                resizable: false,
              },
              {
                Header: `27th ${currentMonth} (Kwh/Km)`,
                id: "date27",
                accessor: (d) => d.date27,
                width: 150,

                filterAll: true,
                resizable: false,
              },
              {
                Header: `28th ${currentMonth} (Kwh/Km)`,
                id: "date28",
                accessor: (d) => d.date28,
                width: 150,

                filterAll: true,
                resizable: false,
              },
              {
                Header: `29th ${currentMonth} (Kwh/Km)`,
                id: "date29",
                accessor: (d) => d.date29,
                width: 150,

                filterAll: true,
                resizable: false,
              },
              {
                Header: `30th ${currentMonth} (Kwh/Km)`,
                id: "date30",
                accessor: (d) => d.date30,
                width: 150,

                filterAll: true,
                resizable: false,
              },
              {
                Header: `31st ${currentMonth} (Kwh/Km)`,
                id: "date31",
                accessor: (d) => d.date31,
                width: 150,

                filterAll: true,
                resizable: false,
              },

           
            ]}
            defaultSorted={[
              {
                id: "serialNumber",
                asc: true,
              },
            ]}
            // data={this.state.sosData} // should default to []
            defaultPageSize={50}
            //pages={this.state.pages} // should default to -1 (which means we don't know how many pages we have)
            // loading={this.state.loading}
            //manual
            //onFetchData={this.fetchData}
            onPageSizeChange={() => {
              ReactTooltip.rebuild();
            }}
            className="-striped -highlight"
          >
            {(state, makeTable, instance) => {
              let recordsInfoText = "";

              const { filtered, pageRows, pageSize, sortedData, page } = state;

              if (sortedData && sortedData.length > 0) {
                let isFiltered = filtered.length > 0;

                let totalRecords = sortedData.length;

                let recordsCountFrom = page * pageSize + 1;

                let recordsCountTo = recordsCountFrom + pageRows.length - 1;

                if (isFiltered)
                  recordsInfoText = `${recordsCountFrom}-${recordsCountTo} of ${totalRecords} filtered records`;
                else
                  recordsInfoText = `${recordsCountFrom}-${recordsCountTo} of ${totalRecords} records`;
              } else recordsInfoText = "No records";

              return (
                <div className="main-grid">
                  <div className="above-table text-right">
                    <div className="col-sm-12">
                      <span className="records-info">{recordsInfoText}</span>
                    </div>
                  </div>

                  {makeTable()}
                </div>
              );
            }}
          </ReactTable>
        </div>

        {/* <Footer /> */}
      </div>
    );
  }
}

//export default ChargeSummary;
const mapStateToProps = (state) => {
  return {
    ...state,
    //  chargeSummary: state.chargeSummary,
  };
};

export default connect(mapStateToProps, {
  getEfficiencyReportAPI,
})(withRouter(EfficiencyReport));
