import React, { Component } from "react";
import "../charge_summary/index.scss";
import ReactTable from "react-table";
import ReactTooltip from "react-tooltip";
import "react-table/react-table.css";
import Workbook from "react-excel-workbook";
import ReactLoader from "../../component/react-loader/react-loader.component";
import moment from "moment";
import DatePicker from "react-datepicker";
import { addDays } from "date-fns";
import "react-datepicker/dist/react-datepicker.css";
import BackToScreen from "../../component/back_to_screen";

import chargeSummaryApiActions from "../../redux/charge_summary/actions";
import { connect } from "react-redux";
import { withRouter } from "react-router";
let start_date = "";
let end_date = "";
let duration = "";

var now = new Date();
now.setDate(now.getDate());

var lastDay = new Date();
lastDay.setHours(lastDay.getHours() - 6);

var startTimestamp = new Date();
startTimestamp.setHours(0);
startTimestamp.setMinutes(0);
startTimestamp.setSeconds(0);

var endTimestamp = new Date();
endTimestamp.setHours(23);
endTimestamp.setMinutes(59);
endTimestamp.setSeconds(58);
const { getChargeSummaryAPI } = chargeSummaryApiActions;

class ChargeSummary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      Chargesummaryreport: [],
      tempData: [],
      filteredData: [],
      searchInput: "",
      // start_date: lastDay,
      // end_date: now,
      start_date: startTimestamp,
      end_date: endTimestamp,
      date: new Date(),
      editingfields: {},
    };
    this.backtodashboardPage = this.backtodashboardPage.bind(this);
    this.getChargeSummaryDetails = this.getChargeSummaryDetails.bind(this);
  }

  componentDidMount() {
    // start_date = sessionStorage.getItem("start_timestamp");
    // end_date = sessionStorage.getItem("end_timestamp");
    //duration = sessionStorage.getItem("duration");
    this.getChargeSummaryDetails();
  }

  getChargeSummaryDetails = async () => {
    const { start_date, end_date } = this.state;
    if (start_date < end_date) {
      let payLoad = {
        api_key: "CHARGE_SUMMARY_DETAILS",
        charger_location: "Bangalore",
        // user_id: "1",
        user_id: sessionStorage.getItem("userId"),
        customerId: sessionStorage.getItem("customerId"),
        vehicle_number: ["BLR001", "BLR002", "BLR003", "BLR004"],
        // start_timestamp: start_date,
        // end_timestamp: end_date,
        // start_timestamp: moment(start_date).format("YYYY-MM-DD 00:00:00"),
        // end_timestamp: moment(end_date).format("YYYY-MM-DD 23:59:59"),

        start_timestamp: moment(start_date).format("YYYY-MM-DD HH:mm:ss"),
        end_timestamp: moment(end_date).format("YYYY-MM-DD HH:mm:ss"),

        // start_timestamp: moment((this.state.start_date.setHours(0, 0, 0))).format(
        //   "YYYY-MM-DD HH:mm:ss"
        // ),
        // end_timestamp: moment(
        //   addDays(this.state.end_date.setHours(23, 59, 59), 0)
        // ).format("YYYY-MM-DD HH:mm:ss"),

        //duration: duration,
      };

      await this.props.getChargeSummaryAPI(payLoad);
    } else if (start_date > end_date) {
      alert("End Date should be greater than Start Date");
    } else {
      alert("End Date should be greater than Start Date");
    }
  };

  componentWillReceiveProps(nextProps) {
    // console.log("chargesummary", nextProps);
    if (
      nextProps?.chargeSummaryApi?.chargeSummaryApi?.result?.data?.responseData
    ) {
      this.setState({
        data: nextProps.chargeSummaryApi.chargeSummaryApi.result.data
          .responseData,
      });
      this.setState({
        filteredData:
          nextProps.chargeSummaryApi.chargeSummaryApi.result.data.responseData,
      });
    }
  }

  onSubmit = (e) => {
    this.getChargeSummaryDetails();
    // this.onReset()
  };

  chargesummaryhandleChange = (event) => {
    event.preventDefault();

    const search = event.target.value.toLowerCase();
    this.setState({
      searchInput: search,
      filteredData: this.state.data.filter(
        (item) =>
          (item.charging_station_name &&
            item.charging_station_name.toLowerCase().includes(search)) ||
          (item.charge_point_identifier &&
            item.charge_point_identifier.toLowerCase().includes(search)) ||
          (item.reg_no && item.reg_no.toLowerCase().includes(search))
      ),
    });
  };

  backtodashboardPage() {
    let tabItem = {
      tabIndex: 16,
      tabName: "Dashboard",
    };
    this.props.selectedTab(null, tabItem);
  }

  getLoader = () => (
    <div className="loader-overlay">
      <div className="loader-container">
        <ReactLoader />
      </div>
    </div>
  );

  onReset = () => {
    //console.log("haii")
    this.setState({
      startTimestamp: "",
      endTimestamp: "",
    });
  };
  Compare = (name, value) => {
    if (name == "start_date") {
      //if
      this.setState({ start_date: value });
    } else if (name == "end_date") {
      if (
        this.state.start_date != "" &&
        this.state.end_date != "" &&
        value >= this.state.start_date
      ) {
        this.setState({ end_date: value });
      } else {
        if (value < this.state.start_date) {
          alert("End Date should be greater than Start Date");
        }
      }
    }
  };
  handleDateChange(evt) {
    const value = evt.target.value;

    const name = evt.target.name;
    this.setState((prevState) => ({
      editingfields: {
        // object that we want to update

        ...prevState.editingfields, // keep all other key-value pairs
        [name]: moment(value).format("YYYY-MM-DD HH:mm:00"),
        // update the value of specific key
      },
    }));
    this.setState({ [name]: value });
  }
  render() {
    // console.log("val check",this.state.end_date)
    let { searchInput } = this.state;
    let chargeData = [];
    if (this.state.data) {
      chargeData = this.state.data;
    }

    return (
      <div>
        {this.props.chargeSummaryApi &&
          this.props.chargeSummaryApi.chargeSummaryApi &&
          this.props.chargeSummaryApi.chargeSummaryApi.loading &&
          this.getLoader()}
        <div className="fleet-health-backward">
          <BackToScreen routingCall={this.backtodashboardPage} />
          <h3 className="charging-summary">Charging Summary</h3>
        </div>

        <div className="charge_summary_div">
          <div className="charge_summary_div_labels">
            <label className="charge_summary_div_labels_style-1">
              Start Date:
            </label>
            <DatePicker
              autocomplete="off"
              name="start_date"
              //selected={this.state.start_date}
              // showTimeSelect
              //timeIntervals={1}
              // selectsStart
              //selected={this.state.start_date.setHours(0, 0, 0)}
              selected={this.state.start_date}
              maxDate={moment().toDate()}
              minDate={addDays(new Date(), -90)}
              value={
                this.state.editingfields.start_date == "undefined"
                  ? this.state.start_date.setHours(0, 0, 0)
                  : this.state.editingfields.start_date
              }
              onChange={(value) =>
                this.handleDateChange({
                  target: { name: "start_date", value },
                })
              }
              //defaultValue={moment(now).toDate()}
              timeInputLabel="Time:"
              dateFormat="yyyy-MM-dd HH:mm:00"
              showTimeInput={true}
              //shouldCloseOnSelect={true}
              // showTime = {{ user24hours: true }}
              // allowClear={true}
              // showTime={{ use12Hours: true, format: "HH:mm A" }}
            />

            {/* <DatePicker
              name="start_date"
              selected={this.state.start_date}
              maxDate={moment().toDate()}
              value={this.state.start_date}
              onChange={(value) => this.Compare("start_date", value)}
              dateFormat="yyyy-MM-dd"
            /> */}

            <label className="charge_summary_div_labels_style">End Date:</label>
            <DatePicker
              autocomplete="off"
              // showTimeSelect
              selectsEnd
              // valueDefault={null}
              //maxDate={moment().toDate()}
              name="end_date"
              selected={this.state.end_date}
              //selected={this.state.end_date.setHours(23, 59, 59)}
              maxDate={moment().toDate()}
              minDate={addDays(new Date(), -90)}
              value={
                this.state.editingfields.end_date == "undefined"
                  ? this.state.end_date.setHours(23, 59, 59)
                  : this.state.editingfields.end_date
              }
              onChange={(value) =>
                this.handleDateChange({
                  target: { name: "end_date", value },
                })
              }
              //defaultValue="01-01-2001 01:01:01"
              timeInputLabel="Time:"
              dateFormat="yyyy-MM-dd HH:mm:00"
              showTimeInput
              //locale="en-GB"
              //timeFormat="HH:mm"
            />
            {/* <DatePicker
              wrapperClassName="datepicker"
              name="end_date"
              selected={this.state.end_date}
              maxDate={moment().toDate()}
              value={this.state.end_date}
              onChange={(value) => this.Compare("end_date", value)}
              dateFormat="yyyy-MM-dd"
            /> */}
          </div>

          <div>
            <button
              className="charge_summary_div_button-submit"
              onClick={this.onSubmit}
            >
              <i className="fa fa-check" style={{ paddingRight: "5px" }}></i>
              Submit
            </button>
          </div>
          <div>
            <button
              className="charge_summary_div_button-submit-1"
              onClick={this.onReset}
            >
              <i className="fa fa-refresh" style={{ paddingRight: "5px" }}></i>{" "}
              Reset
            </button>
          </div>

          <div className="charge_summary_search">
            <form
              onSubmit={(e) => {
                e.preventDefault();
              }}
            >
              <input
                className="charge_summary_search-input"
                type="text"
                id="filter"
                placeholder="Search Table (Charger name / Reg No )"
                value={searchInput || ""}
                onChange={this.chargesummaryhandleChange}
                // onkeypress="return event.keyCode != 13"
              />
            </form>
          </div>

          <div>
            <Workbook
              filename="charge-summary-Report.xlsx"
              element={
                <div
                  className="charge_summary-download"
                  data-tip
                  data-for="registerTip"
                >
                  Export
                  <img
                    src={require("../../assets/images/export/export.png")}
                    alt=""
                    className="charge_summary-download-img"
                  />
                  <ReactTooltip id="registerTip" place="top" effect="solid">
                    Download
                  </ReactTooltip>
                </div>
              }
            >
              <Workbook.Sheet
                data={this.state.filteredData}
                name="charge-summary"
              >
                {/* <Workbook.Column
                  label=""
                  value="charging_station_name"
                /> */}
                <Workbook.Column
                  label="Charger Name"
                  value="charge_point_identifier"
                />
                <Workbook.Column label="Register Number" value="reg_no" />

                <Workbook.Column label="Odometer" value="odometer" />
                <Workbook.Column
                  label="DC Power at Charging Point "
                  value="dc_power_at_charging_point"
                />
                <Workbook.Column
                  label="Power drawn by Vehicle"
                  value="power_consumed_by_vehicle"
                />
                <Workbook.Column label="START SOC" value="start_soc" />

                <Workbook.Column label="END SOC" value="end_soc" />

                {/* <Workbook.Column label="Timestamp" value="created_timestamp" /> */}
                <Workbook.Column
                  label="Start Timestamp"
                  value="start_timestamp"
                />

                <Workbook.Column label="End Timestamp" value="end_timestamp" />

                <Workbook.Column
                  label="Time Taken fo Charging"
                  value="time_taken"
                />
                <Workbook.Column label="Charged SOC" value="soc" />
                <Workbook.Column label="KW/SOC Charged" value="kwPerSoc" />
                <Workbook.Column
                  label="Min/SOC Charged"
                  value="minutesPerSoc"
                />

                <Workbook.Column label="Location" value="location" />
                <Workbook.Column
                  label="Charging Station Name"
                  value="charging_station_name"
                />
              </Workbook.Sheet>
            </Workbook>
          </div>
        </div>
        <div className="charge-summary-table">
          <ReactTooltip place="left" type="success" effect="solid" />
          <ReactTable
            NoDataComponent={() => null}
            pageSizeOptions={[20, 30, 50, 80, 90, 100, 125, 130, 200, 500]}
            style={{
              height: "480px",
              //overflowX: "auto",
              //fontSize: "10px",
              marginTop: "0%",
              marginLeft: "40px",
              marginRight: "30px",
              //direction: "rtl",
            }}
            data={this.state.filteredData}
            columns={[
              {
                Header: "S.No",
                id: "serialNumber",
                filterable: false,
                resizable: false,
                width: 90,
                // getProps: (state, rowInfo, column) => {
                //   return {
                //     style: {
                //       direction: "ltr",
                //     },
                //   };
                // },
                Cell: (row) => {
                  return <div>{row.index + 1}</div>;
                },
              },

              {
                Header: "Charger Name",
                id: "charge_point_identifier",

                accessor: (d) => d.charge_point_identifier,
                Cell: (rows) => {
                  /* Add data-tip */
                  return <span title={rows.value}>{rows.value}</span>;
                },
                // getProps: (state, rowInfo, column) => {
                //   return {
                //     style: {
                //       direction: "ltr",
                //     },
                //   };
                // },

                width: 160,
                resizable: false,
              },

              {
                Header: "Reg No",
                id: "reg_no",

                accessor: (d) => d.reg_no,
                Cell: (rows) => {
                  /* Add data-tip */
                  return <span title={rows.value}>{rows.value}</span>;
                },
                // getProps: (state, rowInfo, column) => {
                //   return {
                //     style: {
                //       direction: "ltr",
                //     },
                //   };
                // },

                width: 120,
                resizable: false,
              },

              {
                Header: "Odometer (Km)",
                id: "odometer",
                accessor: (d) => d.odometer,
                Cell: (rows) => {
                  /* Add data-tip */
                  return <span title={rows.value}>{rows.value}</span>;
                },
                // getProps: (state, rowInfo, column) => {
                //   return {
                //     style: {
                //       direction: "ltr",
                //     },
                //   };
                // },

                width: 140,
                resizable: false,
              },

              {
                Header: () => (
                  <div>
                    DC Power At
                    <br />
                    Charging Point(kwh)
                  </div>
                ),
                id: "dc_power_at_charging_point",
                Cell: (rows) => {
                  /* Add data-tip */
                  return <span title={rows.value}>{rows.value}</span>;
                },
                // getProps: (state, rowInfo, column) => {
                //   return {
                //     style: {
                //       direction: "ltr",
                //     },
                //   };
                // },
                accessor: (d) => d.dc_power_at_charging_point,

                width: 150,
                resizable: false,
              },

              {
                Header: () => (
                  <div>
                    Power Drawn By
                    <br />
                    Vehicle(kwh)
                  </div>
                ),
                id: "power_consumed_by_vehicle",
                Cell: (rows) => {
                  /* Add data-tip */
                  return <span title={rows.value}>{rows.value}</span>;
                },
                // getProps: (state, rowInfo, column) => {
                //   return {
                //     style: {
                //       direction: "ltr",
                //     },
                //   };
                // },
                accessor: (d) => d.power_consumed_by_vehicle,

                width: 150,
                resizable: false,
              },

              {
                Header: "Start SOC (%)",
                id: "start_soc",
                accessor: (d) => d.start_soc,
                Cell: (rows) => {
                  /* Add data-tip */
                  return <span title={rows.value}>{rows.value}</span>;
                },
                // getProps: (state, rowInfo, column) => {
                //   return {
                //     style: {
                //       direction: "ltr",
                //     },
                //   };
                // },

                width: 120,
                resizable: false,
              },

              {
                Header: "End SOC (%)",
                id: "end_soc",
                accessor: (d) => d.end_soc,
                Cell: (rows) => {
                  /* Add data-tip */
                  return <span title={rows.value}>{rows.value}</span>;
                },
                // getProps: (state, rowInfo, column) => {
                //   return {
                //     style: {
                //       direction: "ltr",
                //     },
                //   };
                // },

                width: 120,
                resizable: false,
              },
              {
                Header: () => (
                  <div>
                    Time Taken For
                    <br />
                    Charging (HH:MM:SS)
                  </div>
                ),
                id: "time_taken",
                accessor: (d) => d.time_taken,
                Cell: (rows) => {
                  /* Add data-tip */
                  return <span title={rows.value}>{rows.value}</span>;
                },
                // getProps: (state, rowInfo, column) => {
                //   return {
                //     style: {
                //       direction: "ltr",
                //     },
                //   };
                // },

                width: 150,
                resizable: false,
              },

              {
                Header: "Start Timestamp",
                id: "start_timestamp",
                accessor: (d) => d.start_timestamp,
                Cell: (rows) => {
                  /* Add data-tip */
                  return <span title={rows.value}>{rows.value}</span>;
                },

                // getProps: (state, rowInfo, column) => {
                //   return {
                //     style: {
                //       direction: "ltr",
                //     },
                //   };
                // },
                //filterAll: true,
                width: 160,
                resizable: false,
              },
              {
                Header: "End Timestamp",
                id: "end_timestamp",
                accessor: (d) => d.end_timestamp,
                Cell: (rows) => {
                  /* Add data-tip */
                  return <span title={rows.value}>{rows.value}</span>;
                },

                // getProps: (state, rowInfo, column) => {
                //   return {
                //     style: {
                //       direction: "ltr",
                //     },
                //   };
                // },
                //filterAll: true,
                width: 160,
                resizable: false,
              },
              {
                Header: "Min/SOC Charged (%)",
                id: "minutesPerSoc",
                accessor: (d) => d.minutesPerSoc,
                Cell: (rows) => {
                  /* Add data-tip */
                  return <span title={rows.value}>{rows.value}</span>;
                },

                // getProps: (state, rowInfo, column) => {
                //   return {
                //     style: {
                //       direction: "ltr",
                //     },
                //   };
                // },
                //filterAll: true,
                width: 160,
                resizable: false,
              },
              {
                Header: "KW/SOC Charged (Kwh)",
                id: "kwPerSoc",
                accessor: (d) => d.kwPerSoc,
                Cell: (rows) => {
                  /* Add data-tip */
                  return <span title={rows.value}>{rows.value}</span>;
                },

                // getProps: (state, rowInfo, column) => {
                //   return {
                //     style: {
                //       direction: "ltr",
                //     },
                //   };
                // },
                //filterAll: true,
                width: 160,
                resizable: false,
              },

              {
                Header: "Charged SOC (%)",
                id: "soc",
                accessor: (d) => d.soc,
                Cell: (rows) => {
                  /* Add data-tip */
                  return <span title={rows.value}>{rows.value}</span>;
                },

                // getProps: (state, rowInfo, column) => {
                //   return {
                //     style: {
                //       direction: "ltr",
                //     },
                //   };
                // },
                //filterAll: true,
                width: 160,
                resizable: false,
              },
              {
                Header: "Location",
                id: "location",
                accessor: (d) => d.location,
                Cell: (rows) => {
                  /* Add data-tip */
                  return <span title={rows.value}>{rows.value}</span>;
                },

                // getProps: (state, rowInfo, column) => {
                //   return {
                //     style: {
                //       direction: "ltr",
                //     },
                //   };
                // },
                width: 280,
                resizable: false,
              },
              {
                Header: "Charging Station Name",
                //  Cell: () => (
                //   <img src={require("../../assets/images/arrow-up/up.png")}
                //   />
                //  ),
                id: "charging_station_name",
                accessor: (d) => d.charging_station_name,
                Cell: (rows) => {
                  /* Add data-tip */
                  return <span title={rows.value}>{rows.value}</span>;
                },

                // getProps: (state, rowInfo, column) => {
                //   return {
                //     style: {
                //       direction: "ltr",
                //     },
                //   };
                // },
                width: 220,
                resizable: false,
              },
            ]}
            defaultSorted={[
              {
                id: "serialNumber",
                asc: true,
              },
            ]}
            // data={this.state.sosData} // should default to []
            defaultPageSize={50}
            //pages={this.state.pages} // should default to -1 (which means we don't know how many pages we have)
            // loading={this.state.loading}
            //manual
            //onFetchData={this.fetchData}
            onPageSizeChange={() => {
              ReactTooltip.rebuild();
            }}
            className="-striped -highlight"
          >
            {(state, makeTable, instance) => {
              let recordsInfoText = "";

              const { filtered, pageRows, pageSize, sortedData, page } = state;

              if (sortedData && sortedData.length > 0) {
                let isFiltered = filtered.length > 0;

                let totalRecords = sortedData.length;

                let recordsCountFrom = page * pageSize + 1;

                let recordsCountTo = recordsCountFrom + pageRows.length - 1;

                if (isFiltered)
                  recordsInfoText = `${recordsCountFrom}-${recordsCountTo} of ${totalRecords} filtered records`;
                else
                  recordsInfoText = `${recordsCountFrom}-${recordsCountTo} of ${totalRecords} records`;
              } else recordsInfoText = "No records";

              return (
                <div className="main-grid">
                  <div className="above-table text-right">
                    <div className="col-sm-12">
                      <span className="records-info">{recordsInfoText}</span>
                    </div>
                  </div>

                  {makeTable()}
                </div>
              );
            }}
          </ReactTable>
        </div>

        {/* <Footer /> */}
      </div>
    );
  }
}

//export default ChargeSummary;
const mapStateToProps = (state) => {
  return {
    ...state,
    chargeSummary: state.chargeSummary,
  };
};

export default connect(mapStateToProps, {
  getChargeSummaryAPI,
})(withRouter(ChargeSummary));
