import React, { Component } from "react";
import "./index.scss";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import ReactTable from "react-table";
import "react-table/react-table.css";
import ReactLoader from "../../component/react-loader/react-loader.component";
// //Actions
import contractalKPIApiActions from "../../redux/contractual_kpi/actions";
const { getSustainanceDetailsAPI, getDriverScoreDetailsAPI } =
  contractalKPIApiActions;

class Sustainance extends Component {
  constructor(props) {
    super(props);

    this.state = {
      switchValue: false,
      togglevalues: false,
      data: [],
      DriverScoredata: [],
      TotalTripkms: "",
      TotalRunkms: "",
      TotalDeadkms: "",
      AvgRevenuekms: "",
      AvgTotalKms: "",
      TripEfficiency: "",
    };
    this.toggleStatevalue = this.toggleStatevalue.bind(this);
  }

  toggleStatevalue() {
    this.setState({
      togglevalues: !this.state.togglevalues,
    });
    if (this.state.togglevalues == false) {
      this.getDriverScoredetails("weekly");
    } else {
      this.getDriverScoredetails("monthly");
    }
  }

  componentDidMount() {
    this.getSustaincedetails();
    this.getDriverScoredetails("monthly");
  }

  getSustaincedetails() {
    //const { start_date, end_date } = this.state;
    this.setState({ loading: true });
    const payload = {
     // id: "1",
     user_id: sessionStorage.getItem("userId"),
     customerId: sessionStorage.getItem("customerId"),
      // start_timestamp: moment(start_date).format("YYYY-MM-DD 00:00:00"),
      // end_timestamp: moment(end_date).format("YYYY-MM-DD 23:59:59"),
    };
    this.props.getSustainanceDetailsAPI(payload);
  }

  getDriverScoredetails(status) {
    const { start_date, end_date, toggleswitch } = this.state;
    this.setState({ loading: true });
    const payload = {
     // id: "1",
     user_id: sessionStorage.getItem("userId"),
     customerId: sessionStorage.getItem("customerId"),
      //status:toggleswitch===this.state.DriverScoredata ? "monthly" : "weekly"
      status: status,
      // start_timestamp: moment(start_date).format("YYYY-MM-DD 00:00:00"),
      // end_timestamp: moment(end_date).format("YYYY-MM-DD 23:59:59"),
    };
    this.props.getDriverScoreDetailsAPI(payload);
  }
  componentWillReceiveProps(nextProps) {
    if (this.props !== nextProps) {
      // console.log("sustaninace", nextProps);
      if (
        nextProps?.ContractalKpiApi?.sustainanceDetailsApi?.result?.data
          ?.responseData
      ) {
        this.setState({
          TotalTripkms:
            nextProps?.ContractalKpiApi?.sustainanceDetailsApi?.result?.data
              ?.responseData.total_trip_kms,
        });
        this.setState({
          TotalRunkms:
            nextProps?.ContractalKpiApi?.sustainanceDetailsApi?.result?.data
              ?.responseData.total_run_kms,
        });
        this.setState({
          TotalDeadkms:
            nextProps?.ContractalKpiApi?.sustainanceDetailsApi?.result?.data
              ?.responseData.total_dead_kms,
        });
        this.setState({
          AvgRevenuekms:
            nextProps?.ContractalKpiApi?.sustainanceDetailsApi?.result?.data
              ?.responseData.avg_revenue_kms_day,
        });
        this.setState({
          AvgTotalKms:
            nextProps?.ContractalKpiApi?.sustainanceDetailsApi?.result?.data
              ?.responseData.avg_total_kms_day,
        });
        this.setState({
          TripEfficiency:
            nextProps?.ContractalKpiApi?.sustainanceDetailsApi?.result?.data
              ?.responseData.trip_efficiency,
        });

        this.setState({
          DriverScoredata:
            nextProps?.ContractalKpiApi?.driverscoreDetailsApi?.result?.data
              ?.responseData,
        });
      }
    }
  }
  render() {
    return (
      <div className="container-fluid">
        {this.props.ContractalKpiApi?.sustainanceDetailsApi?.loading ? (
          <div className="loader-overlay">
            <div className="loader-container">
              <ReactLoader />
            </div>
          </div>
        ) : null}
        <div className="row ms-2">
          <div className="col-lg-6 width-25  gx-0 shadow bg-body rounded">
            <div className="sustainance_kpi_head">
              <div className="sustainance_kpi_head-text-1">KMS CALCULATION</div>
              <div class="content_img">
                <img
                  className="sustainance_kpi_head_img-1"
                  src={require("../../assets/images/headernew/head.png")}
                  alt="next"
                />
              </div>
            </div>
            <div className="row">
              <div className="col border-right">
                <img
                  src={require("../../assets/images/kpi/kpi-6.png")}
                  className="sustainance_kpi_image"
                />
                <p className="sustainance_kpi-text_body">
                  {this.state.TotalTripkms} Km
                </p>
                <p className="sustainance_kpi-text_body_1">TOTAL TRIP KMS</p>
              </div>
              <div className="col border-right">
                <img
                  src={require("../../assets/images/kpi/kpi-5.png")}
                  className="sustainance_kpi_image"
                />
                <p className="sustainance_kpi-text_body">
                  {this.state.TotalRunkms} Km
                </p>
                <p className="sustainance_kpi-text_body_1">
                  TOTAL RUN KMS (CUM)
                </p>
              </div>
              <div className="col">
                <img
                  src={require("../../assets/images/kpi/kpi-4.png")}
                  className="sustainance_kpi_image"
                />
                <p className="sustainance_kpi-text_body">
                  {this.state.TotalDeadkms} Kms
                </p>
                <p className="sustainance_kpi-text_body_1">TOTAL DEAD KMS</p>
              </div>
            </div>
          </div>

          <div className="col-lg-6 width-25 ms-4 gx-0 shadow bg-body rounded">
            <div className="sustainance_kpi_head">
              <div className="sustainance_kpi_head-text-1">
                TRIP CALCULATION
              </div>
              <div class="content_img">
                <img
                  className="sustainance_kpi_head_img-1"
                  src={require("../../assets/images/headernew/head.png")}
                  alt="next"
                />
              </div>
            </div>
            <div className="row">
              <div className="col border-right">
                <img
                  src={require("../../assets/images/kpi/kpi-3.png")}
                  className="sustainance_kpi_image"
                />
                <p className="sustainance_kpi-text_body">
                  Rs {this.state.AvgRevenuekms}
                </p>
                <p className="sustainance_kpi-text_body_1">
                  AVG REVENUE KMS/ DAY
                </p>
              </div>
              <div className="col border-right">
                <img
                  src={require("../../assets/images/kpi/kpi-2.png")}
                  className="sustainance_kpi_image"
                />
                <p className="sustainance_kpi-text_body">
                  {this.state.AvgTotalKms} Kms
                </p>
                <p className="sustainance_kpi-text_body_1">
                  AVG TOTAL KMS/ DAY
                </p>
              </div>
              <div className="col">
                <img
                  src={require("../../assets/images/kpi/kpi-1.png")}
                  className="sustainance_kpi_image"
                />
                <p className="sustainance_kpi-text_body">
                  {this.state.TripEfficiency} Kwh/Km
                </p>
                <p className="sustainance_kpi-text_body_1">TRIP EFFICIENCY</p>
              </div>
            </div>
          </div>
        </div>

        <div className="row mt-5 ms-2">
          <div className="col-lg-4 width-25  gx-0 shadow bg-body rounded">
            <div className="sustainance_kpi_head">
              <div className="sustainance_kpi_head-text-1">
                TOP 5 DRIVERS AND SCORE
                <div className="sustainance_kpi_switch-field">
                  <input
                    type="radio"
                    id="switch_left"
                    name="switchToggle"
                    value={this.props.leftLabel}
                    onChange={this.toggleStatevalue}
                    checked={!this.state.togglevalues}
                  />
                  <label htmlFor="switch_left">
                    {" "}
                    {/* <img
                    src={require("../../assets/images/summary/summary.png")}
                    className="switch-img-1"
                    alt=""
                  /> */}
                    Monthly
                  </label>

                  <input
                    type="radio"
                    id="switch_right"
                    name="switchToggle"
                    value={this.props.rightLabel}
                    onChange={this.toggleStatevalue}
                    checked={this.state.togglevalues}
                  />
                  <label htmlFor="switch_right">
                    {" "}
                    {/* <img
                    src={require("../../assets/images/summary/summary.png")}
                    className="switch-img"
                    alt=""
                  /> */}
                    Weekly
                  </label>
                </div>
              </div>

              <div class="content_img">
                <img
                  className="sustainance_kpi_head_img-1"
                  src={require("../../assets/images/headernew/head.png")}
                  alt="next"
                />
              </div>
            </div>
            {this.state.togglevalues == true ? (
              <div>
                <ReactTable
                  NoDataComponent={() => null}
                  pageSizeOptions={[20, 30, 50, 80, 100, 130, 200, 500]}
                  style={{
                    height: "195px",
                    marginTop: "0px",
                    marginLeft: "0px",
                  }}
                  data={this.state.DriverScoredata}
                  showPagination={false}
                  columns={[
                    {
                      Header: "Ranks",
                      id: "rank",
                      accessor: (d) => d.rank,
                      width: 150,
                      filterAll: true,
                      resizable: false,
                    },
                    {
                      Header: "Driver ID",
                      id: "driver_id",
                      accessor: (d) => d.driver_id,
                      width: 150,
                      filterAll: true,
                      resizable: false,
                    },
                    {
                      Header: "Driver Names ",
                      id: "driver_name",
                      accessor: (d) => d.driver_name,
                      Cell: (rows) => {
                        return <span title={rows.value}>{rows.value}</span>;
                      },
                      width: 204,

                      filterAll: true,
                      resizable: false,
                    },
                    {
                      Header: "Pilot Scores",
                      id: "pilot_score",
                      accessor: (d) => d.pilot_score,
                      width: 180,

                      filterAll: true,
                      resizable: false,
                    },
                  ]}
                  defaultSorted={[
                    {
                      id: "serialNumber",
                      asc: true,
                    },
                  ]}
                  defaultPageSize={50}
                  className="-striped -highlight"
                />
              </div>
            ) : (
              <div>
                <ReactTable
                  NoDataComponent={() => null}
                  pageSizeOptions={[20, 30, 50, 80, 100, 130, 200, 500]}
                  style={{
                    height: "195px",
                    marginTop: "0px",
                    marginLeft: "0px",
                  }}
                  data={this.state.DriverScoredata}
                  showPagination={false}
                  columns={[
                    {
                      Header: "Ranks",
                      id: "rank",
                      accessor: (d) => d.rank,
                      width: 150,
                      filterAll: true,
                      resizable: false,
                    },
                    {
                      Header: "Driver ID",
                      id: "driver_id",
                      accessor: (d) => d.driver_id,
                      width: 150,
                      filterAll: true,
                      resizable: false,
                    },

                    {
                      Header: "Driver Name ",
                      id: "driver_name",
                      accessor: (d) => d.driver_name,
                      Cell: (rows) => {
                        /* Add data-tip */
                        return <span title={rows.value}>{rows.value}</span>;
                      },
                      width: 200,

                      filterAll: true,
                      resizable: false,
                    },
                    {
                      Header: "Pilot Score",
                      id: "pilot_score",
                      accessor: (d) => d.pilot_score,
                      width: 184,

                      filterAll: true,
                      resizable: false,
                    },
                  ]}
                  defaultSorted={[
                    {
                      id: "serialNumber",
                      asc: true,
                    },
                  ]}
                  defaultPageSize={50}
                  className="-striped -highlight"
                />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps, {
  getSustainanceDetailsAPI,
  getDriverScoreDetailsAPI,
})(withRouter(Sustainance));
