import React, { Component } from "react";
import "./index.scss";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { addDays } from "date-fns";
import Mapactiontwo from "../../component/mapactiontwo";
import LiveTrackingMap from "../../component/live_trip_tracking_map";
import VehicleNumberDropdown from "../../component/vehicle_number";
import moment from "moment";
import ReactLoader from "../../component/react-loader/react-loader.component";
import DatePicker from "react-datepicker";
import BackToScreen from "../../component/back_to_screen";
import "react-datepicker/dist/react-datepicker.css";
import liveTripTrackSummaryApiActions from "../../redux/live_trip_track_summary/actions";
import liveTripTrackMapDetailsApiActions from "../../redux/live_trip_track_map_details/actions";
import LiveTripSummaryDetails from "../../component/live_trip_track_map_details";

const { getLiveTripTrackSummaryAPI } = liveTripTrackSummaryApiActions;
const { getLiveTripTrackMapDetailsAPI } = liveTripTrackMapDetailsApiActions;
const stopdataArray = [];
const vehdataArray = [];
//mapVehClicked
var now = new Date();
now.setDate(now.getDate());

var lastDay = new Date();
lastDay.setHours(lastDay.getHours() - 6);

var startTimestamp = new Date();
startTimestamp.setHours(0);
startTimestamp.setMinutes(0);
startTimestamp.setSeconds(0);

var endTimestamp = new Date();
endTimestamp.setHours(23);
endTimestamp.setMinutes(59);
endTimestamp.setSeconds(58);

class LiveTripTracking extends Component {
  constructor(props) {
    super(props);

    this.state = {
      username: "",
      password: "",
      flag: "",
      role: "",
      vehicleNo: [],
      regNo: "",
      vehModel: "",
      startTime: "",
      endTime: "",
      startOdo: "",
      endOdo: "",
      driverName: "",
      tripCount: "",
      breakdownCount: "",
      routeNo: "",
      powerConsumption: "",
      socConsumed: "",
      efficiency: "",
      averageSpeed: "",
      chargingTime: "",
      chargingEvents: "",
      stopCount: "",
      stoppageData: [],
      vehCurrentLoc: [],
      vehCurrentData: "",
      // start_date: lastDay,
      // end_date: now,

      start_date: startTimestamp,
      end_date: endTimestamp,
      editingfields: {},
    };
    this.getSummaryDetails = this.getSummaryDetails.bind(this);
    this.getLiveMapDetails = this.getLiveMapDetails.bind(this);
    this.backTotrackPage = this.backTotrackPage.bind(this);
    this.OnSubmit = this.OnSubmit.bind(this);
  }

  componentDidMount() {
    if (
      sessionStorage.getItem("mapVehClicked") ||
      sessionStorage.getItem("mapVehClicked") != null
    ) {
      // let start_timestamp = moment(now).format("YYYY-MM-DD 00:00:00");
      // let end_timestamp = moment(now).format("YYYY-MM-DD 23:59:59");
      let start_timestamp = moment(this.state.start_date).format(
        "YYYY-MM-DD HH:mm:ss"
      );
      let end_timestamp = moment(this.state.end_date).format(
        "YYYY-MM-DD HH:mm:ss"
      );

      this.getSummaryDetails(start_timestamp, end_timestamp);
      this.getLiveMapDetails(start_timestamp, end_timestamp);
    }
  }

  OnSubmit() {
    const { start_date, end_date } = this.state;
    this.setState({ loading: true });

    let start_timestamp = moment(start_date).format("YYYY-MM-DD HH:mm:ss");
    let end_timestamp = moment(end_date).format("YYYY-MM-DD HH:mm:ss");

    this.getSummaryDetails(start_timestamp, end_timestamp);
    this.getLiveMapDetails(start_timestamp, end_timestamp);
  }

  getSummaryDetails = async (start_timestamp, end_timestamp) => {
    let payLoad = {
      user_id: sessionStorage.getItem("userId"),
      customerId: sessionStorage.getItem("customerId"),
      vehicle_number: [sessionStorage.getItem("mapVehClicked")],
      start_timestamp: start_timestamp,
      end_timestamp: end_timestamp,
    };
    await this.props.getLiveTripTrackSummaryAPI(payLoad);
  };

  //
  getLiveMapDetails = async (start_timestamp, end_timestamp) => {
    let payLoad = {
      user_id: sessionStorage.getItem("userId"),
      customerId: sessionStorage.getItem("customerId"),
      vehicle_number: [sessionStorage.getItem("mapVehClicked")],
      start_timestamp: start_timestamp,
      end_timestamp: end_timestamp,
    };
    await this.props.getLiveTripTrackMapDetailsAPI(payLoad);
  };

  componentWillReceiveProps(nextProps) {
    // console.log(
    //   "props check map",
    //   nextProps.LiveTripTrackMapDetailsApi?.liveTripTrackMapDetailsApi?.result
    //     ?.data?.responseData.stoppages
    // );
    if (
      nextProps.LiveTripTrackMapDetailsApi?.liveTripTrackMapDetailsApi?.result
        ?.data?.responseData
    ) {
      this.setState({
        stoppageData:
          nextProps.LiveTripTrackMapDetailsApi.liveTripTrackMapDetailsApi.result
            .data.responseData.stoppages,
      });
      // const stoppageData = [
      //   ...nextProps.LiveTripTrackMapDetailsApi.liveTripTrackMapDetailsApi
      //     .result.data.responseData.stoppages,
      // ];
      // stopdataArray.push(stoppageData);
      this.setState({
        vehCurrentData:
          nextProps.LiveTripTrackMapDetailsApi.liveTripTrackMapDetailsApi.result
            .data.responseData.vehicleCurrentLocation,
      });
      //  console.log("stops", this.state.stoppageData);
    }

    if (this.props !== nextProps) {
      if (
        nextProps.LiveTripTrackSummaryApi?.liveTripTrackSummaryApi?.result?.data
          ?.responseData
      ) {
        this.setState({
          regNo:
            nextProps.LiveTripTrackSummaryApi.liveTripTrackSummaryApi.result
              .data.responseData.vehicleNumber,
        });
        this.setState({
          vehModel:
            nextProps.LiveTripTrackSummaryApi.liveTripTrackSummaryApi.result
              .data.responseData.vehicleDescription,
        });
        this.setState({
          startTime:
            nextProps.LiveTripTrackSummaryApi.liveTripTrackSummaryApi.result
              .data.responseData.startTime,
        });
        this.setState({
          endTime:
            nextProps.LiveTripTrackSummaryApi.liveTripTrackSummaryApi.result
              .data.responseData.endTime,
        });
        this.setState({
          startOdo:
            nextProps.LiveTripTrackSummaryApi.liveTripTrackSummaryApi.result
              .data.responseData.startOdometer,
        });
        this.setState({
          endOdo:
            nextProps.LiveTripTrackSummaryApi.liveTripTrackSummaryApi.result
              .data.responseData.endOdometer,
        });
        this.setState({
          driverName:
            nextProps.LiveTripTrackSummaryApi.liveTripTrackSummaryApi.result
              .data.responseData.driverName,
        });
        this.setState({
          tripCount:
            nextProps.LiveTripTrackSummaryApi.liveTripTrackSummaryApi.result
              .data.responseData.numberOfTrips,
        });
        this.setState({
          breakdownCount:
            nextProps.LiveTripTrackSummaryApi.liveTripTrackSummaryApi.result
              .data.responseData.numberOfBreakDowns,
        });
        this.setState({
          routeNo:
            nextProps.LiveTripTrackSummaryApi.liveTripTrackSummaryApi.result
              .data.responseData.routeNumber,
        });
        this.setState({
          powerConsumption:
            nextProps.LiveTripTrackSummaryApi.liveTripTrackSummaryApi.result
              .data.responseData.powerConsumption,
        });
        this.setState({
          socConsumed:
            nextProps.LiveTripTrackSummaryApi.liveTripTrackSummaryApi.result
              .data.responseData.socConsumed,
        });
        this.setState({
          efficiency:
            nextProps.LiveTripTrackSummaryApi.liveTripTrackSummaryApi.result
              .data.responseData.efficiency,
        });
        this.setState({
          averageSpeed:
            nextProps.LiveTripTrackSummaryApi.liveTripTrackSummaryApi.result
              .data.responseData.averageSpeed,
        });
        this.setState({
          chargingTime:
            nextProps.LiveTripTrackSummaryApi.liveTripTrackSummaryApi.result
              .data.responseData.chargingTime,
        });
        this.setState({
          chargingEvents:
            nextProps.LiveTripTrackSummaryApi.liveTripTrackSummaryApi.result
              .data.responseData.numberOfChargingEvents,
        });
        this.setState({
          stopCount:
            nextProps.LiveTripTrackSummaryApi.liveTripTrackSummaryApi.result
              .data.responseData.numOfStoppages,
        });
      }
    }
  }

  // backtodashboardPage() {
  //   let tabItem = {
  //     tabIndex: 16,
  //     tabName: "Dashboard",
  //   };
  //   this.props.selectedTab(null, tabItem);
  // }
  backTotrackPage() {
    let tabItem = {
      tabIndex: 7,
      tabName: "Track",
    };
    this.props.selectedTab(null, tabItem);
  }
  handleDateChange(evt) {
    const value = evt.target.value;

    const name = evt.target.name;
    this.setState((prevState) => ({
      editingfields: {
        // object that we want to update

        ...prevState.editingfields, // keep all other key-value pairs
        [name]: moment(value).format("YYYY-MM-DD HH:mm:00"),
        // update the value of specific key
      },
    }));
    this.setState({ [name]: value });
  }

  render() {
    return (
      <div>
        {this.props.LiveTripTrackMapDetailsApi?.liveTripTrackMapDetailsApi
          ?.loading ? (
          <div className="livetrip-loader-overlay">
            <div className="livetrip-loader-container">
              <ReactLoader />
            </div>
          </div>
        ) : null}

        <div className="fleet-health-backward">
          <BackToScreen routingCall={this.backTotrackPage} />

          <h3 className="live-trip-track-head">Live Tracking</h3>
        </div>
        <div className="live-trip-track-submenu">
          <VehicleNumberDropdown />
          {/* <SubMenuHeader visible="true" /> */}

          <div className="live-trip-track_div_labels">
            <label className="live-trip-track_div_labels_style">
              Start Date:
            </label>

            <DatePicker
              autocomplete="off"
              name="start_date"
              selected={this.state.start_date}
              //selected={this.state.start_date.setHours(0, 0, 0)}
              maxDate={moment().toDate()}
              minDate={addDays(new Date(), -90)}
              value={
                this.state.editingfields.start_date == "undefined"
                  ? this.state.start_date.setHours(0, 0, 0)
                  : this.state.editingfields.start_date
              }
              onChange={(value) =>
                this.handleDateChange({
                  target: { name: "start_date", value },
                })
              }
              //defaultValue={moment(now).toDate()}
              timeInputLabel="Time:"
              dateFormat="yyyy-MM-dd HH:mm:00"
              showTimeInput
            />

            <label className="live-trip-track_div_labels_style">
              End Date:
            </label>

            <DatePicker
              autocomplete="off"
              // valueDefault={null}
              //maxDate={moment().toDate()}
              name="end_date"
              selected={this.state.end_date}
              //selected={this.state.end_date.setHours(23, 59, 59)}
              maxDate={moment().toDate()}
              minDate={addDays(new Date(), -90)}
              value={
                this.state.editingfields.end_date == "undefined"
                  ? this.state.end_date.setHours(23, 59, 59)
                  : this.state.editingfields.end_date
              }
              onChange={(value) =>
                this.handleDateChange({
                  target: { name: "end_date", value },
                })
              }
              //defaultValue="01-01-2001 01:01:01"
              timeInputLabel="Time:"
              dateFormat="yyyy-MM-dd HH:mm:00"
              showTimeInput
            />
          </div>

          <button
            className="vehicle_number_drop_submit"
            onClick={this.OnSubmit}
          >
            Submit
          </button>
        </div>
        <div className="container-fluid">
          <div className="row bg-color border ms-4 me-3  mt-2">
            <div className="col-2 border-right  wd-10">
              <div className="live-trip-track-image">
                <img
                  src={require("../../assets/images/live-track/live.png")}
                  className=""
                />

                <p className="live-trip-track-col-1-text">{this.state.regNo}</p>
              </div>
              <p className="live-trip-track-col-1-text-2">
                {this.state.vehModel}
              </p>
            </div>
            <div className="col-4 border-right wd-20">
              <div className="live-trip-track-col-2">
                <p className="live-trip-track-col-2-text-1">Start Time</p>
                <hr
                  style={{
                    width: "18px",
                    marginTop: "36px",
                    marginLeft: "33px",
                  }}
                />
                <img
                  src={require("../../assets/images/live-track/clock.png")}
                  className="live-trip-track-col-2-image"
                />
                <hr
                  style={{
                    width: "18px",
                    marginLeft: "10px",
                    marginTop: "36px",
                  }}
                />
                <p className="live-trip-track-col-2-text-2">End Time</p>
              </div>
              <div className="live-trip-track-col-2-div">
                <p className="live-trip-track-col-2-text-3">
                  {this.state.startTime}
                </p>
                <p className="live-trip-track-col-2-text-4">
                  {this.state.endTime}
                </p>
              </div>
            </div>

            <div className="col border-right">
              <p className="live-trip-track-col-3-text-1">Start Odometer</p>
              <p className="live-trip-track-col-3-text-2">
                {this.state.startOdo} Km
              </p>
            </div>
            <div className="col border-right">
              <p className="live-trip-track-col-3-text-1">End Odometer</p>
              <p className="live-trip-track-col-3-text-2">
                {this.state.endOdo} Km
              </p>
            </div>
            <div className="col border-right">
              <p className="live-trip-track-col-3-text-1">Driver Name</p>
              <p className="live-trip-track-col-3-text-3">
                {this.state.driverName}
              </p>
            </div>
            <div className="col border-right">
              <p className="live-trip-track-col-3-text-1">No. Of trips</p>
              <p className="live-trip-track-col-3-text-3">
                {this.state.tripCount}
              </p>
            </div>
            <div className="col border-right">
              <p className="live-trip-track-col-3-text-1">No. Of breakdown</p>
              <p className="live-trip-track-col-3-text-3">
                {this.state.breakdownCount}
              </p>
            </div>
            <div className="col">
              <p className="live-trip-track-col-3-text-1">Route No.</p>
              <p className="live-trip-track-col-3-text-3">
                {this.state.routeNo}
              </p>
            </div>
          </div>

          <div className="row bg-gray border  mt-1 ms-4 me-3 ">
            <div className="col-2 border-right wd-10">
              <p className="live-trip-track-second-text-1">
                Power Consumption (KWH)
              </p>
              <p className="live-trip-track-second-text-2">
                {this.state.powerConsumption} Kwh
              </p>
            </div>
            <div className="col border-right wd-10">
              <p className="live-trip-track-second-text-1">
                Distance Travelled
              </p>

              <p className="live-trip-track-second-text-2">
                {(this.state.endOdo - this.state.startOdo).toFixed(2)} km
              </p>
            </div>
            <div className="col border-right">
              <p className="live-trip-track-second-text-1-text">
                Efficiency (Kwh/km)
              </p>
              <p className="live-trip-track-second-text-2">
                {this.state.efficiency} Kwh/km
              </p>
            </div>
            <div className="col border-right">
              <p className="live-trip-track-second-text-1">Average Speed</p>
              <p className="live-trip-track-second-text-2">
                {this.state.averageSpeed} Km/H
              </p>
            </div>
            <div className="col border-right">
              <p className="live-trip-track-second-text-1">Charging Time</p>
              <p className="live-trip-track-second-text-2">
                {this.state.chargingTime} Hrs
              </p>
            </div>
            <div className="col border-right">
              <p className="live-trip-track-second-text-1">Charging Events</p>
              <p className="live-trip-track-second-text-2">
                {this.state.chargingEvents}
              </p>
            </div>
            <div className="col border-right">
              <p className="live-trip-track-second-text-1">Stoppages</p>
              <p className="live-trip-track-second-text-2">
                {this.state.stoppageData != null
                  ? this.state.stoppageData.length
                  : 0}
              </p>
            </div>

            <div className="col-3">
              <div>
                <p className="live-trip-track-second-text-mapaction">
                  Charger Status
                </p>
              </div>
              <div className="live-track-mapaction">
                <Mapactiontwo visible="false" />
              </div>
            </div>
          </div>
        </div>
        <div className="row ms-4 mt-0 me-2">
          <div className="col-5">
            <LiveTripSummaryDetails />
          </div>
          <div className="col-7">
            <LiveTrackingMap />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps, {
  getLiveTripTrackSummaryAPI,
  getLiveTripTrackMapDetailsAPI,
})(withRouter(LiveTripTracking));
