import React, { Component } from "react";
import ChargerOpportunityPopupButton from "../../component/charger_opportunity_popup_button";
import stationApiActions from "../../redux/station_details/actions";
import occupiedStationApiAction from "../../redux/occupied_station_view/actions";
import chargerEfficiencyApiAction from "../../redux/charger_efficiency_view/actions";
import availableChargersApiAction from "../../redux/available_chargers_view/actions";
import breakdownChargersApiActions from "../../redux/breakdown_chargers_view/actions";
import opportunityChargingApiActions from "../../redux/opportunity_charging_view/actions";
import ReactLoader from "../../component/react-loader/react-loader.component";
import { connect } from "react-redux";
import { withRouter } from "react-router";

import moment from "moment";
//import ChargeSummaryDetails from "../charge_summary_grid/index.js";
import "../chargers/index.scss";

const { getStationAPI } = stationApiActions;
const { getOccupiedStationAPI } = occupiedStationApiAction;
const { getChargerEfficiencyAPI } = chargerEfficiencyApiAction;
const { getAvailableChargersAPI } = availableChargersApiAction;
const { getBreakdownChargersAPI } = breakdownChargersApiActions;
const { getOpportunityChargingAPI } = opportunityChargingApiActions;

// let start_date = "";
// let end_date = "";
let duration = "";

const occupiedInitialData = [
  {
    charging_station_name: "",
    vehicle_number: "",
    SOC: "",
    remaining_time: "",
  },
];
var now = new Date();
now.setDate(now.getDate());

class OpportunityChargers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      showPopup: false,
      cardClicked: "",
      availableCharger: "",
      occupiedCharger: "",
      breakdownCharger: "",
      chargerEfficiency: "",
      oppurtunityCharging: "",
      oppoChargeAvailable: "",
      oppoChargeOccupied: "",
      oppoChargerBreakdown: "",
      oppoChargerEfficiency: "",
      popupDatas: [],
      occupiedStationView: "",
      chargerEfficiencyView: "",
      availableStationView: "",
      breakdownStationView: "",
      opportunitychargingView: "",
      start_timestamp: "",
      end_timestamp: "",
      addModalshow: false,
      start_date: now,
      end_date: now,
    };
  }

  componentDidMount() {
    // start_date = sessionStorage.getItem("start_timestamp");
    // end_date = sessionStorage.getItem("end_timestamp");
    duration = sessionStorage.getItem("duration");
    this.CardOpenFromDashboardoppo();
    // this.getStationDetails();
    //  this.getOccupiedStationDetails();
    //  this.getChargerEfficiencyDetails();
  }

  componentWillReceiveProps(nextProps) {
    // //console.log("datasss", this.props);

    if (this.props !== nextProps) {
      if (
        nextProps?.stationApi?.stationApi?.result?.data?.responseData
      ) {
        this.setState({
          oppoChargeAvailable:
            nextProps.stationApi.stationApi.result.data.responseData
              .oppoChargeAvailable,
        });
        this.setState({
          oppoChargeOccupied:
            nextProps.stationApi.stationApi.result.data.responseData
              .oppoChargeOccupied,
        });
        this.setState({
          oppoChargerBreakdown:
            nextProps.stationApi.stationApi.result.data.responseData
              .oppoChargerBreakdown,
        });
        this.setState({
          oppoChargerEfficiency:
            nextProps.stationApi.stationApi.result.data.responseData
              .oppoChargerEfficiency,
        });
      }
   

      if (this.state.cardClicked === "occupiedStations") {
        if (nextProps?.occupiedStationApi?.occupiedStationApi?.result?.data) {
          this.setState({
            occupiedStationView:
              nextProps.occupiedStationApi.occupiedStationApi.result.data
                .responseData,
          });
          this.setState({
            popupDatas:
              nextProps.occupiedStationApi.occupiedStationApi.result.data
                .responseData,
          });
        }
      }
      if (this.state.cardClicked === "chargerEfficiencys") {
        if (
          nextProps?.chargerEfficiencyApi?.chargerEfficiencyApi?.result?.data
        ) {
          this.setState({
            chargerEfficiencyView:
              nextProps.chargerEfficiencyApi.chargerEfficiencyApi.result.data
                .responseData,
          });
          this.setState({
            popupDatas:
              nextProps.chargerEfficiencyApi.chargerEfficiencyApi.result.data
                .responseData,
          });
        }
      }
      if (this.state.cardClicked === "availableStations") {
        if (
          nextProps?.availableChargersApi?.AvailableChargersApi?.result?.data
        ) {
          this.setState({
            availableStationView:
              nextProps.availableChargersApi.AvailableChargersApi.result.data
                .responseData,
          });
          this.setState({
            popupDatas:
              nextProps.availableChargersApi.AvailableChargersApi.result.data
                .responseData,
          });
        }
      }
      if (this.state.cardClicked === "breakdownChargers") {
        if (
          nextProps?.breakdownChargersApi?.BreakdownChargersApi?.result?.data
        ) {
          this.setState({
            breakdownStationView:
              nextProps.breakdownChargersApi.BreakdownChargersApi.result.data
                .responseData,
          });
          this.setState({
            popupDatas:
              nextProps.breakdownChargersApi.BreakdownChargersApi.result.data
                .responseData,
          });
        }
      }

      if (this.state.cardClicked === "opportunityChargings") {
        if (
          nextProps?.opportunityChargingApi?.OpportunityChargingApi?.result
            ?.data
        ) {
          this.setState({
            opportunitychargingView:
              nextProps.opportunityChargingApi.OpportunityChargingApi.result
                .data.responseData,
          });
          this.setState({
            popupDatas:
              nextProps.opportunityChargingApi.OpportunityChargingApi.result
                .data.responseData,
          });
        }
      }
    }
  }

  getOccupiedStationDetailss = async () => {
    const { start_date, end_date } = this.state;
    let payLoad = {
      api_key: "DASHBOARD_DETAILS",
      request_type: "OCCUPIED_STATION",
      charger_location: "Bangalore",
      // user_id: "1",
      user_id: sessionStorage.getItem("userId"),
      customerId: sessionStorage.getItem("customerId"),
      vehicle_number: ["BLR001", "BLR002", "BLR003", "BLR004"],
      // start_timestamp: sessionStorage.getItem("start_timestamp"),
      // end_timestamp: sessionStorage.getItem("end_timestamp"),
      start_timestamp: moment(start_date).format("YYYY-MM-DD 00:00:00"),
      end_timestamp: moment(end_date).format("YYYY-MM-DD 23:59:59"),
      duration: sessionStorage.getItem("duration"),
      charger_type:"OC"
    };

    await this.props.getOccupiedStationAPI(payLoad);
  };

  getChargerEfficiencyDetailss = async () => {
    const { start_date, end_date } = this.state;
    let payLoad = {
      api_key: "DASHBOARD_DETAILS",
      request_type: "CHARGER_EFFICIENCY",
      charger_location: "Bangalore",
      // user_id: "1",
      user_id: sessionStorage.getItem("userId"),
      customerId: sessionStorage.getItem("customerId"),
      vehicle_number: ["BLR001", "BLR002", "BLR003", "BLR004"],
      // start_timestamp: sessionStorage.getItem("start_timestamp"),
      // end_timestamp: sessionStorage.getItem("end_timestamp"),
      start_timestamp: moment(start_date).format("YYYY-MM-DD 00:00:00"),
      end_timestamp: moment(end_date).format("YYYY-MM-DD 23:59:59"),
      duration: sessionStorage.getItem("duration"),
      charger_type:"OC"
    };
    await this.props.getChargerEfficiencyAPI(payLoad);
  };

  getAvailableChargersDetailss = async () => {
    const { start_date, end_date } = this.state;
    let payLoad = {
      api_key: "DASHBOARD_DETAILS",
      request_type: "AVAILABLE_STATION",
      charger_location: "Bangalore",
      //user_id: "1",
      user_id: sessionStorage.getItem("userId"),
      customerId: sessionStorage.getItem("customerId"),
      vehicle_number: ["BLR001", "BLR002", "BLR003", "BLR004"],
      // start_timestamp: sessionStorage.getItem("start_timestamp"),
      // end_timestamp: sessionStorage.getItem("end_timestamp"),
      start_timestamp: moment(start_date).format("YYYY-MM-DD 00:00:00"),
      end_timestamp: moment(end_date).format("YYYY-MM-DD 23:59:59"),
      duration: sessionStorage.getItem("duration"),
      charger_type:"OC"
    };
    await this.props.getAvailableChargersAPI(payLoad);
  };

  getBreakdownChargersDetailss = async () => {
    const { start_date, end_date } = this.state;
    let payLoad = {
      api_key: "DASHBOARD_DETAILS",
      request_type: "BREAKDOWN_STATION",
      charger_location: "Bangalore",
      //user_id: "1",
      user_id: sessionStorage.getItem("userId"),
      customerId: sessionStorage.getItem("customerId"),
      vehicle_number: ["BLR001", "BLR002", "BLR003", "BLR004"],
      // start_timestamp: sessionStorage.getItem("start_timestamp"),
      // end_timestamp: sessionStorage.getItem("end_timestamp"),
      start_timestamp: moment(start_date).format("YYYY-MM-DD 00:00:00"),
      end_timestamp: moment(end_date).format("YYYY-MM-DD 23:59:59"),
      duration: sessionStorage.getItem("duration"),
      charger_type:"OC"
    };
    await this.props.getBreakdownChargersAPI(payLoad);
  };

  getOpportunityChargersDetailss = async () => {
    const { start_date, end_date } = this.state;
    let payLoad = {
      api_key: "DASHBOARD_DETAILS",
      request_type: "OPPORTUNITY_CHARGING",
      charger_location: "Bangalore",
      // user_id: "1",
      user_id: sessionStorage.getItem("userId"),
      customerId: sessionStorage.getItem("customerId"),
      vehicle_number: ["BLR001", "BLR002", "BLR003", "BLR004"],
      // start_timestamp: sessionStorage.getItem("start_timestamp"),
      // end_timestamp: sessionStorage.getItem("end_timestamp"),
      start_timestamp: moment(start_date).format("YYYY-MM-DD 00:00:00"),
      end_timestamp: moment(end_date).format("YYYY-MM-DD 23:59:59"),
      duration: sessionStorage.getItem("duration"),
      charger_type:"OC"
    };
    await this.props.getOpportunityChargingAPI(payLoad);
  };
  //request_type: "BREAKDOWN_STATION",
  //request_type: "OPPORTUNITY_CHARGING",

  occupiedStation = () => {
    sessionStorage.setItem("popupClicked", "occupiedStations");
    this.setState({ cardClicked: "occupiedStations" });
    this.getOccupiedStationDetailss();
    this.setState({ addModalshow: true });
    this.setState({ showPopup: true });
    // if (this.state.occupiedStationView) {
    //   this.setState({
    //     popupData: this.state.occupiedStationView,
    //   });
    // }
  };

  chargerEfficiency = () => {
    sessionStorage.setItem("popupClicked", "chargerEfficiencys");
    this.setState({ cardClicked: "chargerEfficiencys" });
    this.getChargerEfficiencyDetailss();
    this.setState({ addModalshow: true });
    this.setState({ showPopup: true });
    // if (this.state.chargerEfficiencyView) {
    //   this.setState({
    //     popupData: this.state.chargerEfficiencyView,
    //   });
    // }
  };

  availableCharger = () => {
    sessionStorage.setItem("popupClicked", "availableStations");
    this.setState({ cardClicked: "availableStations" });
     this.getAvailableChargersDetailss();
    this.setState({ addModalshow: true });
    this.setState({ showPopup: true });
    // if (this.state.availableStationView) {
    //   this.setState({
    //     popupData: this.state.availableStationView,
    //   });
    // }
  };

  breakdownCharger = () => {
    sessionStorage.setItem("popupClicked", "breakdownChargers");
    this.setState({ cardClicked: "breakdownChargers" });
    this.getBreakdownChargersDetailss();
    this.setState({ addModalshow: true });
    this.setState({ showPopup: true });
    // if (this.state.breakdownStationView) {
    //   this.setState({
    //     popupData: this.state.breakdownStationView,
    //   });
    // }
  };

  opportunityCharging = () => {
    sessionStorage.setItem("popupClicked", "opportunityChargings");
    this.setState({ cardClicked: "opportunityChargings" });
    this.getOpportunityChargersDetailss();
    this.setState({ addModalshow: true });
    this.setState({ showPopup: true });
    // if (this.state.opportunitychargingView) {
    //   this.setState({
    //     popupData: this.state.opportunitychargingView,
    //   });
    // }
  };
  addModalClose = () => this.setState({ addModalshow: false });
  CardOpenFromDashboardoppo() {
    if (sessionStorage.getItem("cardClicked") === "oppo_available") {
      this.availableCharger();
    } else if (sessionStorage.getItem("cardClicked") === "oppo_occupied") {
      this.occupiedStation();
    } else if (sessionStorage.getItem("cardClicked") === "oppo_breakdown") {
      this.breakdownCharger();
    } else if (sessionStorage.getItem("cardClicked") === "oppo_charger") {
      this.chargerEfficiency();
    }
  }
  render() {
    return (
      <div className="container-4">
        {this.props.stationApi?.stationApi?.loading ? (
          <div className="loader-overlay">
            <div className="loader-container">
              <ReactLoader />
            </div>
          </div>
        ) : null}
        <div className="row">
          <div className="col-sm pl-4">
            <div className="left">
              <div className="number-pulse">
                <div className="pulsatingDotone" />
                <div className="number-1">
                  <p>{this.state.oppoChargeAvailable}</p>
                </div>
              </div>

              <div className="text-1">
                <div className="text_alignment">AVAILABLE CHARGING POINTS</div>
              </div>
            </div>
            <div className="right">
              <img
                src={require("../../assets/images/Group 5058/Group 5058.png")}
                alt=""
              />
            </div>
            <div className="charger_top_elements">
              <div class="charger_content_img">
                <img
                  className="charger_chargers_head_img"
                  src={require("../../assets/info.svg").default}
                  alt="next"
                />
                <div>Available Charging Points at Opportunity Charging</div>
              </div>
              <div className="chargers_button">
                <button
                  type="button"
                  className="lp_review_err_btn"
                  onClick={this.availableCharger}
                  //disabled="disabled"
                >
                  LIST VIEW
                  <img
                    className="lp_next_png"
                    src={require("../../assets/images/Icon/icon.png")}
                    alt="next"
                  />
                </button>
              </div>
            </div>
          </div>
          <div className="col-sm" style={{}}>
            <div className="left">
              <div className="number-pulse">
                <div className="pulsatingDottwo" />
                <div className="number-2">
                  <p>{this.state.oppoChargeOccupied}</p>{" "}
                </div>
              </div>
              <div className="text-2">
                <div className="text_alignment">OCCUPIED CHARGING POINTS</div>
              </div>
            </div>
            <div className="right_occupies_station">
              <img
                src={require("../../assets/images/5026/Group 5026.png")}
                alt=""
              />
            </div>
            <div className="charger_top_elements">
              <div class="charger_content_img">
                <img
                  className="charger_chargers_head_img"
                  src={require("../../assets/info.svg").default}
                  alt="next"
                />
                <div>Chargers Currently In Use</div>
              </div>
              <div className="chargers_button">
                <button
                  type="button"
                  className="lp_review_err_btn"
                  onClick={this.occupiedStation}
                 // disabled="disabled"
                >
                  LIST VIEW
                  <img
                    className="lp_next_png"
                    src={require("../../assets/images/Icon/icon.png")}
                    alt="next"
                  />
                </button>
              </div>
            </div>
          </div>

          <div className="col-sm" style={{}}>
            <div className="left">
              <div className="number-pulse">
                <div className="pulsatingDotthree" />
                <div className="number-3">
                  <p>{this.state.oppoChargerBreakdown}</p>
                </div>
              </div>
              <div className="text-3">
                <div className="text_alignment">BREAKDOWN CHARGING POINTS</div>
              </div>
            </div>
            <div className="right_breakdown_station">
              <img
                src={require("../../assets/images/5025/Group 5025.png")}
                alt=""
              />
            </div>
            <div className="charger_top_elements">
              <div class="charger_content_img">
                <img
                  className="charger_chargers_head_img"
                  src={require("../../assets/info.svg").default}
                  alt="next"
                />
                <div>Charging Points Under Maintenance / Breakdown</div>
              </div>
              <div className="chargers_button">
                <button
                  type="button"
                  className="lp_review_err_btn"
                  onClick={this.breakdownCharger}
                 // disabled="disabled"
                >
                  LIST VIEW
                  <img
                    className="lp_next_png"
                    src={require("../../assets/images/Icon/icon.png")}
                    alt="next"
                  />
                </button>
              </div>
            </div>
          </div>

          <div className="col-sm" style={{}}>
            <div className="left">
              <div className="number-pulse">
                <div className="pulsatingDotfour" />
                <div className="number-4">
                  <p>{this.state.oppoChargerEfficiency}%</p>
                </div>
              </div>
              <div className="text-4">
                <div className="text_alignment">CHARGER EFFICIENCY</div>
              </div>
            </div>
            <div className="right_charger_efficiency">
              <img
                src={require("../../assets/images/NEW/download.png")}
                alt=""
              />
            </div>
            <div className="charger_top_elements">
              <div class="charger_content_img">
                <img
                  className="charger_chargers_head_img"
                  src={require("../../assets/info.svg").default}
                  alt="next"
                />
                <div>Power Drawn By Veh / Power Available At Chargers</div>
              </div>
              <div className="chargers_button">
                <button
                  type="button"
                  className="lp_review_err_btn"
                  onClick={this.chargerEfficiency}
                >
                  LIST VIEW
                  <img
                    className="lp_next_png"
                    src={require("../../assets/images/Icon/icon.png")}
                    alt="next"
                  />
                </button>
              </div>
            </div>
          </div>
          {/*<div className="col-sm" style={{}}>
            <div className="left">
              <div className="number-pulse">
                <div className="pulsatingDotfive" />
                <div className="number-5">
                  <p>{this.state.oppurtunityCharging}</p>
                </div>
              </div>
              <div className="text-5">
                <div className="text_alignment">OPPORTUNITY CHARGING</div>
              </div>
            </div>
            <div className="button">
              <button
                type="button"
                className="lp_review_err_btn"
                onClick={this.opportunityCharging}
              >
                LIST VIEW
                <img
                  className="lp_next_png"
                  src={require("../../assets/images/Icon/icon.png")}
                  alt="next"
                />
              </button>
            </div>
            <div className="oppertunity_charger">
              <img
                src={require("../../assets/images/NEW/download.png")}
                alt=""
              />
            </div>
        </div>*/}
        </div>
        {this.state.showPopup && (
          <ChargerOpportunityPopupButton
            buttonClicked={this.state.cardClicked}
            data={this.state.popupDatas}
            show={this.state.addModalshow}
            closemodal={this.addModalClose}
          />
        )}
      </div>
    );
  }
}

//export default Station;
const mapStateToProps = (state) => {
  return {
    ...state,
    // station: state.station,
    // occupiedStation: state.occupiedStation,
    // chargerEfficiency: state.chargerEfficiency,
    // availableCharger: state.availableCharger,
    // breakdownCharger: state.breakdownCharger,
    // opportunityCharging: state.opportunityCharging,
  };
};

export default connect(mapStateToProps, {
  getStationAPI,
  getOccupiedStationAPI,
  getChargerEfficiencyAPI,
  getAvailableChargersAPI,
  getBreakdownChargersAPI,
  getOpportunityChargingAPI,
})(withRouter(OpportunityChargers));
