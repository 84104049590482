import React, { Component } from "react";
import Map from "../map/index";
import Summary from "../summary";
import "../track/index.scss";
import MapActionTwo from "../../component/mapactiontwo";
import MapactionOne from "../../component/mapactionone";
var flagB = sessionStorage.getItem("graphView");

let flag = false;
class Track extends Component {
  constructor() {
    super();
    this.state = {
      toggleValue: false,
      toggle: false,
    };
    this.backtodashboardPage = this.backtodashboardPage.bind(this);
    this.toggleState = this.toggleState.bind(this);
    this.gotoLiveTracking = this.gotoLiveTracking.bind(this);
  }

  toggleState() {
    sessionStorage.setItem("map", false);
    flag = false;
    this.setState({
      toggle: !this.state.toggle,
    });
  }

  gotoLiveTracking() {
    let tabItem = {
      tabIndex: 8,
      tabName: "LiveTripTracking",
    };
    this.props.selectedTab(null, tabItem);
  }

  componentWillUnmount() {
    sessionStorage.removeItem("graphView");
    sessionStorage.removeItem("map");
  }

  componentDidMount() {
    this.callingBoolean();
  }

  callingBoolean() {
    if (sessionStorage.getItem("graphView")) {
      this.setState({ toggle: true });
    } else {
      this.setState({ toggle: false });
    }
  }

  backtodashboardPage() {
    let tabItem = {
      tabIndex: 16,
      tabName: "Dashboard",
    };
    this.props.selectedTab(null, tabItem);
  }
  render() {
    // console.log("called");
    if (sessionStorage.getItem("map") === "true") {
      flag = true;
    }
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-6  ms-2 mt-4 w-47">
            <MapactionOne />
          </div>

          <div className="col-lg-3  mt-2 ms-0 d-flex">
            <MapActionTwo visible="true" />
          </div>
          <div className="col-lg-2 mt-4 mg-left">
            <div className="switch-field">
              <input
                type="radio"
                id="switch_left"
                name="switchToggle"
                value={this.props.leftLabel}
                onChange={this.toggleState}
                checked={flag === true ? this.state.toggle : !this.state.toggle}
              />
              <label htmlFor="switch_left">
                {" "}
                {/* <img
                    src={require("../../assets/images/summary/summary.png")}
                    className="switch-img-1"
                    alt=""
                  /> */}
                Map
              </label>

              <input
                type="radio"
                id="switch_right"
                name="switchToggle"
                value={this.props.rightLabel}
                onChange={this.toggleState}
                checked={flag === true ? !this.state.toggle : this.state.toggle}
              />
              <label htmlFor="switch_right">
                {" "}
                {/* <img
                    src={require("../../assets/images/summary/summary.png")}
                    className="switch-img"
                    alt=""
                  /> */}
                Summary
              </label>
            </div>
          </div>
        </div>
        <div className="track-hr">
          <hr />
        </div>
        <div className="map-view">
          {this.state.toggle === true ? (
            <Summary />
          ) : (
            <Map navToLiveTripTracking={this.gotoLiveTracking} />
          )}
        </div>
      </div>
    );
  }
}

export default Track;
