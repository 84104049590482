import React, { Component } from "react";
import "../fleet_uptime_historic_view/index.scss";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import ReactLoader from "../react-loader/react-loader.component";
import moment from "moment";
import { addDays } from "date-fns";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
//import ReactTable from "react-table";
//import "react-table/react-table.css";

import HistoricGraphView from "../historic_graph_view";
import HistoricTableView from "../historic_table_view";
import fleetUptimeMangementApiActions from "../../redux/uptime_mangement/actions";
const { getFleetUptimeHistoricAPI, getFleetUptimeHistoricBelowAPI, getFleetUptimeHistoricgraphAPI } = fleetUptimeMangementApiActions;
var now = new Date();
now.setDate(now.getDate());
var lastDay = new Date();
lastDay.setHours(lastDay.getHours() - 6);
const timestamp = new Date().getTime();
const yesterday = timestamp - 24*60*60*1000;
const Fivedays=timestamp-24*5*60*60*1000;
var startTimestamp = new Date(Fivedays);
startTimestamp.setHours(0);
startTimestamp.setMinutes(0);
startTimestamp.setSeconds(0)

var endTimestamp = new Date();
endTimestamp.setHours(23);
endTimestamp.setMinutes(59);
endTimestamp.setSeconds(58)
var condition = sessionStorage.getItem("graphView");

class FleetUptimeHistoricView extends Component {
  constructor() {
    super();
    this.state = {
      start_date: startTimestamp,
      end_date: endTimestamp,
      editingfields: {},
      toggles: false,
      data: [],
    };
    this.onReset = this.onReset.bind(this);
    this.toggleStates = this.toggleStates.bind(this);
  }

  toggleStates() {
    this.setState({
      toggles: !this.state.toggles,
    });
    // console.log("booleans", this.state.toggles);
    if (this.state.toggles == false) {
      this.getfleetUptimehistoricViewBelow();
    } else {
      this.getfleetUptimehistoricViewAbove();
    }
  }

  handleDateChange(evt) {
    const value = evt.target.value;

    const name = evt.target.name;
    this.setState((prevState) => ({
      editingfields: {
        ...prevState.editingfields,
        [name]: moment(value).format("YYYY-MM-DD HH:mm:00"),
      },
    }));
    this.setState({ [name]: value });
  }

  onSubmit = (e) => {
    const { vehicleAboveTarget, start_date, end_date } = this.state;
    let start_timestamp = moment(this.state.start_date).format(
      "YYYY-MM-DD HH:mm:ss"
    );
    let end_timestamp = moment(now).format("YYYY-MM-DD HH:mm:ss");

    sessionStorage.setItem("start_date", start_timestamp);
    sessionStorage.setItem("end_date", end_timestamp);

    this.getfleetUptimehistoricViewAbove(start_timestamp, end_timestamp);
    this.getfleetUptimehistoricViewBelow(start_timestamp, end_timestamp);
    this.getFleetUptimeGraphDetails(start_timestamp, end_timestamp);
    
  };

  onReset = () => {
    this.setState({
      start_date: lastDay,
      end_date: now,
    });
  };

  componentWillUnmount() {
    sessionStorage.removeItem("start_date");
    sessionStorage.removeItem("end_date");
  }
  componentDidMount() {
    let start_timestamp = moment(this.state.start_date).format(
      "YYYY-MM-DD HH:mm:ss"
    );
    let end_timestamp = moment(now).format("YYYY-MM-DD HH:mm:ss");

    sessionStorage.setItem("start_date", start_timestamp);
    sessionStorage.setItem("end_date", end_timestamp);

    this.getfleetUptimehistoricViewAbove(start_timestamp, end_timestamp);
    this.getfleetUptimehistoricViewBelow(start_timestamp, end_timestamp);
    this.getFleetUptimeGraphDetails(start_timestamp, end_timestamp);
    this.callingBoolean();
  }

  callingBoolean(){
    if(sessionStorage.getItem("graphView")){
      this.setState({toggles:true})
    }
    else{
      this.setState({toggles:false})
    }
  }



  getfleetUptimehistoricViewAbove = async () => {
    const { start_date, end_date } = this.state;

    if (start_date < end_date) {
      let payLoad = {
        // endTimestamp: "2023-01-18 23:59:59",
        // startTimestamp: "2023-01-18 00:00:00",
        vehicleAboveTarget: true,
        vehicleBelowTarget: false,
        start_timestamp: moment(start_date).format("YYYY-MM-DD HH:mm:ss"),
        end_timestamp: moment(end_date).format("YYYY-MM-DD HH:mm:ss"),
        customerId:sessionStorage.getItem("customerId"),
      };
      await this.props.getFleetUptimeHistoricAPI(payLoad);
    } else if (start_date > end_date) {
      alert("End Date should be greater than Start Date");
    } else {
      alert("End Date should be greater than Start Date");
    }
  };

  getfleetUptimehistoricViewBelow = async () => {
    const { start_date, end_date } = this.state;

    if (start_date < end_date) {
      let payLoad = {
        // endTimestamp: "2023-01-18 23:59:59",
        // startTimestamp: "2023-01-18 00:00:00",
        user_id : sessionStorage.getItem("userId"),
        vehicleAboveTarget: false,
        vehicleBelowTarget: true,
        start_timestamp: moment(start_date).format("YYYY-MM-DD HH:mm:ss"),
        end_timestamp: moment(end_date).format("YYYY-MM-DD HH:mm:ss"),
        customerId:sessionStorage.getItem("customerId"),
      };
      await this.props.getFleetUptimeHistoricBelowAPI(payLoad);
    } else if (start_date > end_date) {
      alert("End Date should be greater than Start Date");
    } else {
      alert("End Date should be greater than Start Date");
    }
  };

  componentWillUnmount(){
    sessionStorage.removeItem("graphView");
  }

  getFleetUptimeGraphDetails =  () => {
    const { start_date, end_date } = this.state;
    let payLoad = {
      user_id : sessionStorage.getItem("userId"),
      customerId: sessionStorage.getItem("customerId"),
      start_timestamp: moment(start_date).format("YYYY-MM-DD HH:mm:ss"),
      end_timestamp:moment(end_date).format("YYYY-MM-DD HH:mm:ss"),
     
    };

     this.props.getFleetUptimeHistoricgraphAPI(payLoad);
  };

  componentWillReceiveProps(nextProps) {
    //console.log("histo", nextProps);
    if (
      nextProps?.FleetUptimeMangementApi?.FleetUptimeHistoric?.result?.data
        ?.responseData
    ) {
      this.setState({
        data: nextProps.FleetUptimeMangementApi.FleetUptimeHistoric.result.data
          .responseData,
      });
    }
  }

  render() {
   // console.log("toggles",this.state.toggles)
    return (
      <div>
        {/* <SubMenuHeader visible="true"/> */}
        <div className="container-fluied">
          {this.props.FleetUptimeMangementApi?.FleetUptimeHistoric?.loading ? (
            <div className="loader-overlay">
              <div className="loader-container">
                <ReactLoader />
              </div>
            </div>
          ) : null}
          <div className="row d-flex">
            <div className="col-xl mt-0 d-flex">
              <div className="fleet-health_report_div_labels">
                <label className="fleet_health_report_div_labels_style">
                  Start Date:
                </label>

                <DatePicker
                  autocomplete="off"
                  name="start_date"
                  selected={this.state.start_date}
                  maxDate={moment().toDate()}
                  minDate={addDays(new Date(), -90)}
                  value={
                    this.state.editingfields.start_date == "undefined"
                      ? this.state.start_date.setHours(0, 0, 0)
                      : this.state.editingfields.start_date
                  }
                  onChange={(value) =>
                    this.handleDateChange({
                      target: { name: "start_date", value },
                    })
                  }
                  //defaultValue={moment(now).toDate()}
                  timeInputLabel="Time:"
                  dateFormat="yyyy-MM-dd HH:mm:00"
                  showTimeInput
                />

                <label className="fleet_health_report_div_labels_style-1">
                  End Date:
                </label>

                <DatePicker
                  autocomplete="off"
                  // valueDefault={null}
                  //maxDate={moment().toDate()}
                  name="end_date"
                  selected={this.state.end_date}
                  maxDate={moment().toDate()}
                  minDate={addDays(new Date(), -90)}
                  value={
                    this.state.editingfields.end_date == "undefined"
                      ? this.state.end_date.setHours(23, 59, 59)
                      : this.state.editingfields.end_date
                  }
                  //value={this.state.editingfields.end_date}
                  onChange={(value) =>
                    this.handleDateChange({
                      target: { name: "end_date", value },
                    })
                  }
                  //defaultValue="01-01-2001 01:01:01"
                  timeInputLabel="Time:"
                  dateFormat="yyyy-MM-dd HH:mm:00"
                  showTimeInput
                />
              </div>
              <div>
                <button
                  className="fleet_health_report_div_button-submit"
                  onClick={this.onSubmit}
                >
                  <i
                    className="fa fa-check"
                    style={{ paddingRight: "5px" }}
                  ></i>
                  Submit
                </button>
              </div>
              <div>
                <button
                  className="fleet_health_div_button-submit-1"
                  onClick={this.onReset}
                >
                  <i
                    className="fa fa-refresh"
                    style={{ paddingRight: "5px" }}
                  ></i>{" "}
                  Reset
                </button>
              </div>
              <div className="switch-field-fleet-uptime">
                <input
                  type="radio"
                  id="switch_left"
                  name="switchToggle"
                  value={this.props.leftLabel}
                  onChange={this.toggleStates}
                  checked={!this.state.toggles}
                />
                <label htmlFor="switch_left">
                  {" "}
                  {/* <img
                    src={require("../../assets/images/summary/summary.png")}
                    className="switch-img-1"
                    alt=""
                  /> */}
                  Summary View
                </label>

                <input
                  type="radio"
                  id="switch_right"
                  name="switchToggle"
                  value={this.props.rightLabel}
                  onChange={this.toggleStates}
                  checked={this.state.toggles}
                />
                <label htmlFor="switch_right">
                  {" "}
                  {/* <img
                    src={require("../../assets/images/summary/summary.png")}
                    className="switch-img"
                    alt=""
                  /> */}
                  Graph View
                </label>
              </div>
            </div>
            <div className="row">
              { this.state.toggles === true ? (
                <HistoricGraphView />
              ) : (
                <HistoricTableView />
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps, {
  getFleetUptimeHistoricAPI,
  getFleetUptimeHistoricBelowAPI,
  getFleetUptimeHistoricgraphAPI
})(withRouter(FleetUptimeHistoricView));
