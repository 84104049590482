


import { all, takeEvery, put, call } from "redux-saga/effects";
import actions from "./actions";
import * as dataAccess from "../../utils/ajax";
import {

  CONTRACTUAL_ACCIDENT_SUMMARY,

} from "../../constants/api-constants";

export const postLambda = async (url, payload) =>
  await dataAccess.postLambda(url, payload);

//ACCIDEDNT

export function* getContractualAccidentSummaryApiSagas(data) {
  const { payload } = data;
  let url = CONTRACTUAL_ACCIDENT_SUMMARY;
  try {
    let response = yield call(postLambda, url, payload);

    if (response) {
      yield put(
        actions.getContractualAccidentsummaryViewAPISuccess(0, response)
      );
    }
  } catch (error) {
    yield put(actions.getContractualAccidentsummaryViewAPIFailure(1));
  }
}






export default function* rootSaga() {
  yield all([
 
    takeEvery(
      actions.GET_CONTRACTUALACCIDENTSUMMARYPOPUP,
      getContractualAccidentSummaryApiSagas
    ),
   
  ]);
}
