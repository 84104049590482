import React, { Fragment, Component } from "react";
import { matchSorter } from "match-sorter";
import "../kpi_contractual_breakdown_summary_popup/index.scss"
import { connect } from "react-redux";
import { withRouter } from "react-router";
import ReactTable from "react-table";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker";
import "react-datepicker/dist/react-datepicker-cssmodules.css";
import ReactLoader from "../react-loader/react-loader.component";
import "react-table/react-table.css";

//Actions
import { Modal } from "react-bootstrap";
import contractalKPIAccidentApiActions from "../../redux/contractual_kpi_accident_report/actions";
const {
    getContractualAccidentsummaryViewAPI
  }=contractalKPIAccidentApiActions;

var startTimestamp = new Date();
startTimestamp.setDate(1);
startTimestamp.setHours(0);
startTimestamp.setMinutes(0);
startTimestamp.setSeconds(0);

var endTimestamp = new Date();
endTimestamp.setHours(23);
endTimestamp.setMinutes(59);
endTimestamp.setSeconds(58);

class KpiContractualAccidentSummaryPopup extends Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      addModalshow: false,
      selecteddata: {},
      editingfields:{},
      open: false,
      show: false,
      start_date: startTimestamp,
      end_date: endTimestamp,
      buttonClicked: "",
      responseData: [],
     
    };
    this.navtoaccidentsummaryreports=this.navtoaccidentsummaryreports.bind(this);
  }

  
  componentDidMount() {
    this.getContractualAccidentSummaryDetails()
  }
  handleDateChange(evt) {
    const value = evt.target.value;

    const name = evt.target.name;
    this.setState((prevState) => ({
      editingfields: {
        // object that we want to update

        ...prevState.editingfields, // keep all other key-value pairs
        [name]: moment(value).format("YYYY-MM-DD HH:mm:00"),
        // update the value of specific key
      },
    }));
    this.setState({ [name]: value });
  }
  componentDidUpdate(prevProps, prevState) {
    //console.log("haiiiii");
    if (prevProps != this.props) {
      this.setState({ show: this.props.show });
      this.setState({ responseData: this.props.dataAccident });
    }
  }


  onSubmit = (e) => {
    e.preventDefault();
    this.getContractualAccidentSummaryDetails()
  };

  getContractualAccidentSummaryDetails() {
    const { start_date, end_date } = this.state;
    if (start_date < end_date) {
    this.setState({ loading: true });
    const payload = {
      user_id: sessionStorage.getItem("userId"),
      customerId: sessionStorage.getItem("customerId"),
      accident_breakdown_type :"CONTRACTUAL_ACCIDENT_SUMMARY",
      start_timestamp: moment(start_date).format("YYYY-MM-DD HH:mm:ss"),
      end_timestamp: moment(end_date).format("YYYY-MM-DD HH:mm:ss"),
    };
    this.props.getContractualAccidentsummaryViewAPI(payload);
  } else if (start_date > end_date) {
    alert("End Date should be greater than Start Date");
  } else {
    alert("End Date should be greater than Start Date");
  }
  }

  
  navtoaccidentsummaryreports() {
    let tabItem = {
      tabIndex: 36,
      tabName: "Accident Details",
    };
    this.props.selectedTab(null, tabItem);
  }

  render() {
    

    return (
      <Modal
        show={this.props.show}
        size="xl"
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
        centered
        onHide={this.props.closemodal}
      >
        <Modal.Header closeButton style={{ borderBottom: "none" }}>
          <Modal.Title
            id="contained-modal-title-vcenter"
            //className="title"
            style={{
              marginLeft: "566px",
              color: "#083a81",
              fontSize: "16px",
              fontFamily: "roboto-bold",
            }}
          >
         ACCIDENT SUMMARY
          </Modal.Title>
        </Modal.Header>
        <form
          className="kpi_breakdown_update"
          autocomplete="off"
          onSubmit={this.saftykpihandleSubmit}
        >
          <Modal.Body
            style={{
              "max-height": "calc(100vh - 210px)",
            }}
          >
            <div className="kpi_contractual_breakdown_div">
              <div className="kpi_contractual_breakdown_div_labels">
                <label className="kpi_contractual_breakdown_div_style-1">
                  Start Date:
                </label>

                <DatePicker
                  autocomplete="off"
                  name="start_date"
                  selected={this.state.start_date}
                  maxDate={moment().toDate()}
                  //minDate={addDays(new Date(), -90)}
                   value={this.state.editingfields.start_date}

                  onChange={(value) =>
                    this.handleDateChange({
                      target: { name: "start_date", value },
                    })
                  }
                  timeInputLabel="Time:"
                  dateFormat="yyyy-MM-dd HH:mm:00"
                  showTimeInput
                />

                <label className="kpi_contractual_breakdown_div_style">
                  End Date:
                </label>

                <DatePicker
                  autocomplete="off"
                  name="end_date"
                  selected={this.state.end_date}
                  maxDate={moment().toDate()}
                   value={this.state.editingfields.end_date}
                  onChange={(value) =>
                    this.handleDateChange({
                      target: { name: "end_date", value },
                    })
                  }
                  timeInputLabel="Time:"
                  dateFormat="yyyy-MM-dd HH:mm:00"
                  showTimeInput
                />
              </div>

              <div>
                <button
                  className="kpi_contractual_breakdown_button_submit"
                  onClick={this.onSubmit}
                >
                  <i
                    className="fa fa-check"
                    style={{ paddingRight: "5px" }}
                  ></i>
                  Submit
                </button>
              </div>

          
            </div>

            <div>
            {this.props.ContractalKpiApi?.contractualBreakdownSummmaryApi?.loading ? (
                <div className="loader-overlay">
                  <div className="loader-container">
                    <ReactLoader />
                  </div>
                </div>
              ) : null}
              <ReactTable
                NoDataComponent={() => null}
                pageSizeOptions={[20, 30, 50, 80, 90, 100, 125, 130, 200, 500]}
                style={{
                  height: "460px",
                  marginTop: "-30px",
                }}
                data={this.state.responseData}
                columns={[
                  {
                    Header: "Date",
                    id: "created_date",
                    accessor: (d) => d.created_date,
                    filterAll: true,
                    width: 300,
                    resizable: false,
                  },
                 
              
                  {
                    Header: "Breakdown Count",
                    id: "breakdown_count",
                    accessor: (d) => d. breakdown_count
                    ,
                   
                    filterAll: true,
                    width: 300,
                    resizable: false,
                  },
                  {
                    Header: " Trip Loss (Km)",
                    id: "trip_loss_km",
                    accessor: (d) => d. trip_loss_km,
                    filterAll: true,
                    width: 350,
                    resizable: false,
                  },
                  {
                    Header: "Action",
               
                      Cell: (props) => (
                  <a
                    className="driver-details-action"
                    href="#"
                    //style="text-decoration:none;"
                    target=""
                    onClick={this.navtoaccidentsummaryreports}
                  >
                    VIEW MORE{" "}
                    <img
                      className="driverdetails-action-img"
                      src={require("../../assets/images/driver-monitoring/view-more.png")}
                      alt=""
                    />
                  </a>
                ),
    
                }
                ]}
                defaultPageSize={50}
                showPagination={false}
                className="-striped -highlight"
              />
            </div>
          </Modal.Body>
        </form>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps, {
  getContractualAccidentsummaryViewAPI
})(withRouter(KpiContractualAccidentSummaryPopup));
