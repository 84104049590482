import React, { Component } from "react";
import "../../component/dashboard_uptime_alert/index.scss";
class DashboardUptimeAlert extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
    };
    this.NavtoFleetHealth = this.NavtoFleetHealth.bind(this);
    //this.severityClicked = this.severityClicked.bind(this)
  }
  NavtoFleetHealth = () => {
    let tabItem = {
      tabIndex: 21,
      tabName: "Fleet Health",
    };
    this.props.selectedTab(null, tabItem);
  };
  severityClicked(status) {
    sessionStorage.setItem("severity", status);
    return this.props.clickdata;
  }
  render() {
    return (
      <div className="uptime-alert">
        <div className="uptime-alert-div">
          <div className="uptime-alert-wash" onClick={this.props.clickdata}>
            <img
              className="uptime-alert-wash-img"
              src={require("../../assets/images/uptimealert/green-veh.png")}
              alt=""
            />
            <div className="uptime-alert-wash-one">
              <h3 className="uptime-alert-number">
                {this.props.servicealerts}
              </h3>
              <p className="uptime-alert-text">IN SERVICE</p>
            </div>
            <img
              onClick={() => this.severityClicked("Service Alerts")}
              className="uptime-alert-button-1"
              src={require("../../assets/images/uptime alerts/button.png")}
              alt=""
            />
          </div>
          <div className="uptime-alert-wash" onClick={this.props.clickdata}>
            <img
              className="uptime-alert-wash-img"
              src={require("../../assets/images/uptimealert/stop-new.png")}
              alt=""
            />
            <div className="uptime-alert-wash-one">
              <h3 className="uptime-alert-number">{this.props.stopnow}</h3>
              <p className="uptime-alert-text">STOP NOW CASE</p>
            </div>
            <img
              onClick={() => this.severityClicked("Stop Now")}
              className="uptime-alert-button"
              src={require("../../assets/images/uptime alerts/button.png")}
              alt=""
            />
          </div>
          <div className="uptime-alert-wash" onClick={this.props.clickdata}>
            <img
              className="uptime-alert-wash-img"
              src={require("../../assets/images/uptimealert/war-new.png")}
              alt=""
            />
            <div className="uptime-alert-wash-one">
              <h3 className="uptime-alert-number">{this.props.visitsoon}</h3>
              <p className="uptime-alert-text">VISIT SOON </p>
            </div>
            <img
              className="uptime-alert-button-1"
              onClick={() => this.severityClicked("Visit Soon")}
              src={require("../../assets/images/uptime alerts/button.png")}
              alt=""
            />
          </div>
          <div className="uptime-alert-wash" onClick={this.props.clickdata}>
            <img
              className="uptime-alert-wash-img"
              src={require("../../assets/images/uptimealert/man.png")}
              alt=""
            />
            <div className="uptime-alert-wash-one">
              <h3 className="uptime-alert-number">{this.props.breakdown}</h3>
              <p className="uptime-alert-text">BREAKDOWN</p>
            </div>
            <img
              className="uptime-alert-button-2"
              onClick={() => this.severityClicked("Breakdown Alerts")}
              src={require("../../assets/images/uptime alerts/button.png")}
              alt=""
            />
          </div>
        </div>
      </div>
    );
  }
}
export default DashboardUptimeAlert;
