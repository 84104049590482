import React, { Component } from "react";
import ReactTable from "react-table";
import "react-table/react-table.css";
import "./index.scss";
import ReactLoader from "../react-loader/react-loader.component";
import ReactTooltip from "react-tooltip";
import { connect } from "react-redux";
import Workbook from "react-excel-workbook";
import { withRouter } from "react-router";
import moment from "moment";
import DatePicker from "react-datepicker";
import { addDays } from "date-fns";
import BackToScreen from "../../component/back_to_screen";
import "react-datepicker/dist/react-datepicker.css";
import dailyOperationReportApiActions from "../../redux/daily_operation_report/actions";

var startTimestamp = new Date();
startTimestamp.setHours(0);
startTimestamp.setMinutes(0);
startTimestamp.setSeconds(0);

var endTimestamp = new Date();
endTimestamp.setHours(23);
endTimestamp.setMinutes(59);
endTimestamp.setSeconds(58);
const { getDailyoperationReportAPI, panelDailyOperationReportAPI } =
  dailyOperationReportApiActions;

class DailyOperationReport extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      date: "",
      dataSet: [],
      numOfBreakdownReported: "",
      numOfVehicles: "",
      scheduledKmsAdherence: "",
      scheduledKmsRun: "",
      scheduledTrips: "",
      shift: "",
      totalKmsRun: "",
      totalTrips: "",
      tripCompletionScore: "",
      tripLossKms: "",
      // start_date: lastDay,
      // end_date: now,
      start_date: startTimestamp,
      end_date: endTimestamp,
      filteredData: [],
      searchInput: "",
      editingfields: {},
    };
    this.backtodashboardPage = this.backtodashboardPage.bind(this);
  }

  componentDidMount() {
    //getting all the data
    this.getDailyOperationReport();
    this.getPanelDailyOperationReport();
  }

  getPanelDailyOperationReport() {
    const { start_date, end_date } = this.state;
    this.setState({ loading: true });
    if (start_date < end_date) {
      const payload = {
        user_id: sessionStorage.getItem("userId"),
        customerId: sessionStorage.getItem("customerId"),
        start_timestamp: moment(start_date).format("YYYY-MM-DD HH:mm:ss"),
        end_timestamp: moment(end_date).format("YYYY-MM-DD HH:mm:ss"),
      };
      this.props.panelDailyOperationReportAPI(payload);
    } else if (start_date > end_date) {
      alert("End Date should be greater than Start Date");
    } else {
      alert("End Date should be greater than Start Date");
    }
  }
  getDailyOperationReport() {
    const { start_date, end_date } = this.state;
    this.setState({ loading: true });
    const payload = {
      user_id: sessionStorage.getItem("userId"),
      customerId: sessionStorage.getItem("customerId"),
      // start_timestamp: moment(start_date).format("YYYY-MM-DD 00:00:00"),
      // end_timestamp: moment(end_date).format("YYYY-MM-DD 23:59:59"),
      start_timestamp: moment(start_date).format("YYYY-MM-DD HH:mm:ss"),
      end_timestamp: moment(end_date).format("YYYY-MM-DD HH:mm:ss"),
    };
    this.props.getDailyoperationReportAPI(payload);
  }

  componentDidUpdate(prevProps) {
    // console.log("dailyoperationreport", this.props.DailyOperationReportApi);
    if (prevProps !== this.props) {
      console.log("checking props", this.props);
      if (
        this.props.DailyOperationReportApi?.paneldailyOperationReportApi?.result
          ?.data.responseData
      ) {
        // this.setState({
        //   dataSet:
        //     this.props.DailyOperationReportApi.paneldailyOperationReportApi.result.data.responseData,
        // });

        this.setState({
          date: this.props.DailyOperationReportApi.paneldailyOperationReportApi
            .result.data.responseData.date,
        });

        this.setState({
          numOfBreakdownReported:
            this.props.DailyOperationReportApi.paneldailyOperationReportApi
              .result.data.responseData.numOfBreakdownReported,
        });

        this.setState({
          numOfVehicles:
            this.props.DailyOperationReportApi.paneldailyOperationReportApi
              .result.data.responseData.numOfVehicles,
        });

        this.setState({
          scheduledKmsAdherence:
            this.props.DailyOperationReportApi.paneldailyOperationReportApi
              .result.data.responseData.scheduledKmsAdherence,
        });

        this.setState({
          scheduledKmsRun:
            this.props.DailyOperationReportApi.paneldailyOperationReportApi
              .result.data.responseData.scheduledKmsRun,
        });

        this.setState({
          scheduledTrips:
            this.props.DailyOperationReportApi.paneldailyOperationReportApi
              .result.data.responseData.scheduledTrips,
        });
        this.setState({
          shift:
            this.props.DailyOperationReportApi.paneldailyOperationReportApi
              .result.data.responseData.shift,
        });
        this.setState({
          totalKmsRun:
            this.props.DailyOperationReportApi.paneldailyOperationReportApi
              .result.data.responseData.totalKmsRun,
        });
        this.setState({
          totalTrips:
            this.props.DailyOperationReportApi.paneldailyOperationReportApi
              .result.data.responseData.totalTrips,
        });
        this.setState({
          tripCompletionScore:
            this.props.DailyOperationReportApi.paneldailyOperationReportApi
              .result.data.responseData.tripCompletionScore,
        });
        this.setState({
          tripLossKms:
            this.props.DailyOperationReportApi.paneldailyOperationReportApi
              .result.data.responseData.tripLossKms,
        });
      } else {
        this.setState({ dataSet: [] });
      }

      if (
        this.props.DailyOperationReportApi?.dailyOperationReportApi?.result
          ?.data?.responseData?.length > 0
      ) {
        this.setState({
          data: this.props.DailyOperationReportApi.dailyOperationReportApi
            .result.data.responseData,
        });
      } else {
        this.setState({ data: [] });
      }
    }
  }

  onSubmit = (e) => {
    this.getPanelDailyOperationReport();
    this.getDailyOperationReport();
  };

  handleDateChange(evt) {
    const value = evt.target.value;

    const name = evt.target.name;
    this.setState((prevState) => ({
      editingfields: {
        // object that we want to update

        ...prevState.editingfields, // keep all other key-value pairs
        [name]: moment(value).format("YYYY-MM-DD HH:mm:00"),
        // update the value of specific key
      },
    }));
    this.setState({ [name]: value });
  }
  backtodashboardPage() {
    let tabItem = {
      tabIndex: 16,
      tabName: "Dashboard",
    };
    this.props.selectedTab(null, tabItem);
  }

  render() {
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col">
            <div className="daily-operation-backward">
              <BackToScreen routingCall={this.backtodashboardPage} />
              <h3 className="DailyOperationReport">Daily Operation Report</h3>
            </div>
          </div>

          <div className="col">
            <Workbook
              filename="Daily Operation Report.xlsx"
              element={
                <div
                  className="DailyOperationReport-download"
                  data-tip
                  data-for="registerTip"
                >
                  Export
                  <img
                    src={require("../../assets/images/export/export.png")}
                    alt=""
                    className="DailyOperationReport-download-img"
                  />
                  <ReactTooltip id="registerTip" place="top" effect="solid">
                    Download
                  </ReactTooltip>
                </div>
              }
            >
              <Workbook.Sheet
                data={this.state.data}
                name="Daily Operation Report"
              >
                <Workbook.Column label="Registration Number" value="regNo" />
                <Workbook.Column label="vin" value="vehicleName" />
                <Workbook.Column label="Route Name" value="routeName" />
                <Workbook.Column label="Start ODO(Km)" value="startOdometer" />
                <Workbook.Column label="End ODO(Km)" value="endOdometer" />
                <Workbook.Column
                  label="Distance Travelled(Km)"
                  value="totalDistanceCovered"
                />
                <Workbook.Column label="KMS Survey" value="surveyKms" />
                <Workbook.Column
                  label="Accident Count"
                  value="accident_count"
                />
                <Workbook.Column
                  label="Total Charge Time"
                  value="total_charge_time"
                />
                <Workbook.Column
                  label="Diff of Distance and Survey"
                  value="surveyAndTotalKmsDiff"
                />
                <Workbook.Column
                  label="Scheduled Start Time"
                  value="scheduledStartTime"
                />
                  <Workbook.Column
                  label="Scheduled End Time"
                  value="scheduledEndTime"
                />
                <Workbook.Column
                  label="Actual Start Time"
                  value="actualStartTime"
                />
              
                <Workbook.Column
                  label="Actual End Time"
                  value="actualEndTime"
                />
                <Workbook.Column
                  label="Diff Scheduled And Actual End Time"
                  value="diffScheduledAndActualEndTime"
                />
                <Workbook.Column
                  label="Diff Scheduled And Actual Start Time"
                  value="diffScheduledAndActualStartTime"
                />
                <Workbook.Column
                  label="Num Of Scheduled Trips"
                  value="surveyKms"
                />
                <Workbook.Column
                  label="Num Of Breakdown Reported"
                  value="numOfBreakdownReported"
                />
                <Workbook.Column
                  label="Total Trips"
                  value="totalTripsCompleted"
                />
              
                <Workbook.Column label="Start Soc" value="startSoc" />
                <Workbook.Column label="End Soc" value="endSoc" />
              </Workbook.Sheet>
            </Workbook>
          </div>
        </div>

        <div className="daily_operation_report_div">
          <div className="daily_operation_report_div_labels">
            <label className="daily_operation_report_div_labels_style-1">
              Start Date:
            </label>
            <DatePicker
              autocomplete="off"
              name="start_date"
              selected={this.state.start_date}
              //selected={this.state.start_date.setHours(0, 0, 0)}
              maxDate={moment().toDate()}
              minDate={addDays(new Date(), -90)}
              value={
                this.state.editingfields.start_date == "undefined"
                  ? this.state.start_date.setHours(0, 0, 0)
                  : this.state.editingfields.start_date
              }
              onChange={(value) =>
                this.handleDateChange({
                  target: { name: "start_date", value },
                })
              }
              timeInputLabel="Time:"
              dateFormat="yyyy-MM-dd HH:mm:00"
              showTimeInput
            />

            <label className="daily_operation_report_div_labels_style-2">
              End Date:
            </label>

            <DatePicker
              autocomplete="off"
              name="end_date"
              selected={this.state.end_date}
              maxDate={moment().toDate()}
              minDate={addDays(new Date(), -90)}
              value={
                this.state.editingfields.end_date == "undefined"
                  ? this.state.end_date.setHours(23, 59, 59)
                  : this.state.editingfields.end_date
              }
              onChange={(value) =>
                this.handleDateChange({
                  target: { name: "end_date", value },
                })
              }
              timeInputLabel="Time:"
              dateFormat="yyyy-MM-dd HH:mm:00"
              showTimeInput
            />
          </div>

          <div>
            <button
              className="daily_operation_report_div_button-submit"
              onClick={this.onSubmit}
            >
              <i className="fa fa-check" style={{ paddingRight: "5px" }}></i>
              Submit
            </button>
          </div>
          <div>
            <button
              className="daily_operation_report_div_button-submit-1"
              onClick={this.reset}
            >
              <i className="fa fa-refresh" style={{ paddingRight: "5px" }}></i>{" "}
              Reset
            </button>
          </div>
        </div>

        <div className="container-fluid">
          <div className="row  ms-4 me-3 ">
            <div className="col me-5 border hg-36">
              <div className="row">
                <div className="col border-right hg-34">
                  <p className="daily-operation-text">Total Trips</p>
                </div>
                <div className="col-5">
                  <p className="daily-operation-text">
                    {this.state.totalTrips}
                  </p>
                </div>
              </div>
            </div>

            <div className="col ms-5 me-5 border hg-36">
              <div className="row">
                <div className="col border-right hg-36">
                  <p className="daily-operation-text">Scheduled Trips</p>
                </div>
                <div className="col-5">
                  <p className="daily-operation-text">
                    {this.state.scheduledTrips}
                  </p>
                </div>
              </div>
            </div>
            <div className="col border ms-5 me-5 hg-36">
              <div className="row">
                <div className="col border-right hg-36">
                  <p className="daily-operation-text">Trip Completion Score</p>
                </div>
                <div className="col-3">
                  <p className="daily-operation-text">
                    {this.state.tripCompletionScore}
                  </p>
                </div>
              </div>
            </div>
            {/* <div className="col  ms-5 me-2"></div> */}

            <div className="col  ms-5 me-2 border hg-36">
              <div className="row">
                <div className="col border-right hg-36">
                  <p className="daily-operation-text">No. of Vehicles</p>
                </div>
                <div className="col-3">
                  <p className="daily-operation-text">
                    {this.state.numOfVehicles}
                  </p>
                </div>
              </div>
            </div>

            <div className="col ms-5 me-5 border hg-36">
              <div className="row">
                <div className="col border-right hg-36">
                  <p className="daily-operation-text">No. Of B/D Reported</p>
                </div>
                <div className="col-3">
                  <p className="daily-operation-text">
                    {this.state.numOfBreakdownReported}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="row  ms-4 me-3  mt-2">
            {/* <div className="col me-5 border hg-36">
              <div className="row">
                <div className="col border-right hg-36">
                  <p className="daily-operation-text">Shift</p>
                </div>
                <div className="col-3">
                  <p className="daily-operation-text">{this.state.shift}</p>
                </div>
              </div>
            </div> */}

            <div className="col  me-5 border hg-36">
              <div className="row">
                <div className="col border-right hg-36">
                  <p className="daily-operation-text">Total KMS Run</p>
                </div>
                <div className="col-5">
                  <p className="daily-operation-text">
                    {this.state.totalKmsRun}
                  </p>
                </div>
              </div>
            </div>
            <div className="col border ms-5 me-5 hg-36">
              <div className="row">
                <div className="col border-right hg-36">
                  <p className="daily-operation-text">Scheduled KMS</p>
                </div>
                <div className="col-5">
                  <p className="daily-operation-text">
                    {this.state.scheduledKmsRun}
                  </p>
                </div>
              </div>
            </div>
            <div className="col border ms-5 me-5 hg-36">
              <div className="row">
                <div className="col border-right hg-36">
                  <p className="daily-operation-text">Scheduled KMS Adh</p>
                </div>
                <div className="col-3">
                  <p className="daily-operation-text">
                    {this.state.scheduledKmsAdherence}
                  </p>
                </div>
              </div>
            </div>

            <div className="col ms-5 me-5 border hg-36">
              <div className="row">
                <div className="col border-right hg-36">
                  <p className="daily-operation-text">Trip Loss KMS</p>
                </div>
                <div className="col-3">
                  <p className="daily-operation-text">
                    {this.state.tripLossKms}
                  </p>
                </div>
              </div>
            </div>
            <div className="col ms-5 me-2  hg-36"></div>
            {/* <div className="col border ms-5 me-2">
              <div className="row">
                <div className="col border-right">
                  <p className="daily-operation-text">
                    Scheduled KMS Adherence
                  </p>
                </div>
                <div className="col-2">
                  <p className="daily-operation-text">
                    {this.state.scheduledKmsAdherence}
                  </p>
                </div>
              </div>
            </div> */}
          </div>

          {/* <div className="row  ms-4 me-3  mt-2"> */}
          {/* <div className="col me-5 border"></div> */}

          {/* <div className="col border ms-5 me-5"></div> */}
          {/* <div className="col  ms-5 me-2"></div> */}
          {/* </div> */}
        </div>

        <div>
          {this.props.DailyOperationReportApi?.dailyOperationReportApi
            ?.loading ? (
            <div className="loader-overlay">
              <div className="loader-container">
                <ReactLoader />
              </div>
            </div>
          ) : null}
          <ReactTable
            NoDataComponent={() => null}
            pageSizeOptions={[20, 30, 50, 80, 100, 130, 200, 500]}
            style={{
              height: "380px",
              marginTop: "0%",
              marginRight: "30px",
              //direction: "rtl",
              // width: "1000px",
            }}
            data={this.state.data}
            showPagination={true}
            columns={[
              {
                Header: "S.No",
                id: "serialNumber",
                filterable: false,
                resizable: false,
                width: 90,
                Cell: (row) => {
                  return <div>{row.index + 1}</div>;
                },
              },

              {
                Header: "Reg No",
                id: "regNo",
                accessor: (d) => d.regNo,
                width: 120,
                filterAll: true,
                resizable: false,
              },

              {
                Header: "VIN",
                id: "vehicleName",
                accessor: (d) => d.vehicleName,
                width: 160,
                filterAll: true,
                resizable: false,
              },
              {
                Header: "Route No",
                id: "routeName",
                accessor: (d) => d.routeName,
                width: 100,
                filterAll: true,
                resizable: false,
              },

              {
                Header: "Start ODO (Km)",
                id: "startOdometer",
                accessor: (d) => d.startOdometer,
                width: 100,
                filterAll: true,
                resizable: false,
              },
              {
                Header: "End ODO (Km)",

                id: "endOdometer",
                accessor: (d) => d.endOdometer,
                width: 100,
                filterAll: true,
                resizable: false,
              },
              {
                Header: "Distance Travelled (Km)",
                id: "totalDistanceCovered",
                accessor: (d) => d.totalDistanceCovered,
                width: 150,
                filterAll: true,
                resizable: false,
              },
              {
                Header: "KMS Survey (Km)",
                id: "surveyKms",
                accessor: (d) => d.surveyKms,
                width: 160,
                filterAll: true,
                resizable: false,
              },
              {
                Header: "Accident Count",
                id: "accident_count",
                accessor: (d) => d.accident_count,
              width: 160,
                filterAll: true,
                resizable: false,
              },

              {
                Header: "Total Charge Time (HH.MM)",
                id: "total_charge_time",
                accessor: (d) => d.total_charge_time,
                width: 160,
                filterAll: true,
                resizable: false,
              },

              {
                Header: "Diff - Survey And ODO KM",
                id: "surveyAndTotalKmsDiff",
                accessor: (d) => d.surveyAndTotalKmsDiff,
                width: 170,
                filterAll: true,
                resizable: false,
              },
             

    
              {
                Header: "Schedule Veh in Time",
                id: "scheduledEndTime",
                accessor: (d) => d.scheduledEndTime,
                width: 190,
                filterAll: true,
                resizable: false,
              },
              {
                Header: "Schedule Veh out Time",
                id: "scheduledStartTime",
                accessor: (d) => d.scheduledStartTime,
                width: 160,
                filterAll: true,
                resizable: false,
              },

              {
                Header: "Veh in Time",
                id: "actualEndTime",
                accessor: (d) => d.actualEndTime,
                width: 120,
              filterAll: true,
                resizable: false,
              },
              {
                Header: "Vehicle out Time ",
                id: "actualStartTime",
                accessor: (d) => d.actualStartTime,
                width: 180,
                filterAll: true,
                resizable: false,
              },

              {
                Header: "Difference in Time (IN)",
                id: "diffScheduledAndActualEndTime",
                accessor: (d) => d.diffScheduledAndActualEndTime,
                width: 190,
                filterAll: true,
                resizable: false,
              },
              {
                Header: "Difference in Time (OUT)",
                id: "diffScheduledAndActualStartTime",
                accessor: (d) => d.diffScheduledAndActualStartTime,
                width: 190,
                filterAll: true,
                resizable: false,
              },
              {
                Header: "Scheduled Trip",
                id: "scheduled_trips",
                accessor: (d) => d.scheduled_trips,
                width: 120,
                filterAll: true,
                resizable: false,
              },

              {
                Header: "Breakdown Reported",
                id: "numOfBreakdownReported",
                accessor: (d) => d.numOfBreakdownReported,
                width: 160,
                filterAll: true,
                resizable: false,
              },

              {
                Header: "Total Trips",
                id: "totalTripsCompleted",
                accessor: (d) => d.totalTripsCompleted,
                width: 120,
                filterAll: true,
                resizable: false,
              },
              {
                Header: "Start SOC (%)",
                id: "startSoc",
                accessor: (d) => d.startSoc,
                width: 120,
                filterAll: true,
                resizable: false,
              },
              {
                Header: "End SOC (%)",
                id: "endSoc",
                accessor: (d) => d.endSoc,
                width: 120,
                filterAll: true,
                resizable: false,
              },

              // {
              //   Header: "Min Temperature",
              //   id: "minTemperature",
              //   accessor: (d) => d.minTemperature,
              //   width: 140,

              //   filterAll: true,
              //   resizable: false,
              // },

              // {
              //   Header: "Max Temperature",
              //   id: "maxTemperature",
              //   accessor: (d) => d.maxTemperature,
              //   width: 140,

              //   filterAll: true,
              //   resizable: false,
              // },
            ]}
            defaultSorted={[
              {
                id: "serialNumber",
                asc: true,
              },
            ]}
            defaultPageSize={50}
            className="-striped -highlight"
          >
            {(state, makeTable, instance) => {
              let recordsInfoText = "";

              const { filtered, pageRows, pageSize, sortedData, page } = state;

              if (sortedData && sortedData.length > 0) {
                let isFiltered = filtered.length > 0;

                let totalRecords = sortedData.length;

                let recordsCountFrom = page * pageSize + 1;

                let recordsCountTo = recordsCountFrom + pageRows.length - 1;

                if (isFiltered)
                  recordsInfoText = `${recordsCountFrom}-${recordsCountTo} of ${totalRecords} filtered records`;
                else
                  recordsInfoText = `${recordsCountFrom}-${recordsCountTo} of ${totalRecords} records`;
              } else recordsInfoText = "No records";

              return (
                <div className="main-grid">
                  <div className="above-table text-right">
                    <div className="col-sm-12">
                      <span className="records-info">{recordsInfoText}</span>
                    </div>
                  </div>

                  {makeTable()}
                </div>
              );
            }}
          </ReactTable>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps, {
  getDailyoperationReportAPI,
  panelDailyOperationReportAPI,
})(withRouter(DailyOperationReport));
