import React, { Component } from "react";
import "./index.scss";

import ReactTable from "react-table";
import "react-table/react-table.css";
class DashboardPerfomanceInsight extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
    };
  }
  render() {
    return (
      <div className="consuming-power">
        <div className="table-align">
          {/* <h2 className="msg">
            Note: Future trips will be reflected in Excel Sheet
          </h2> */}
          <ReactTable
            NoDataComponent={() => null}            
            style={{
              height: "236px",
              marginTop: "0%",
              width:"93%",
              // margin_right: "2%",
              // width: "1560px",
              scrollY:"hidden",
              overFlowY:"hidden"
            }}
          
            data={this.props.tabledata}
            showPagination={false}
            pageSize={5}
            columns={[
              {
                Header: () => (
                  <div>
                    Vehicle
                    <br />
                    No
                  </div>
                ),              
                id: "regNo",
                accessor: (d) => d.regNo,
                width: 100,
                resizable: false,
                sortable: false,
              },
              {
                Header: () => (
                  <div>
                    Route
                    <br />
                    Name
                  </div>
                ),              
                id: "routeName",
                accessor: (d) => d.routeName,
                width: 100,
                resizable: false,
                sortable: false,
              },
              {
                Header: () => (
                  <div>
                    Power Consumption
                    <br />
                     (kwh)
                  </div>
                ),            
                id: "powerConsumption",
                accessor: (d) => d.powerConsumption,
                width: 135,
                resizable: false,
                sortable: false,
              },
              {
                Header: () => (
                  <div>
                    SOC
                    <br />
                    (%)
                  </div>
                ),             
                id: "soc",
                accessor: (d) => d.soc,
                width: 100,
                resizable: false,
                sortable: false,
              },
            ]}          
            className="-striped -highlight"
          />
        </div>
      </div>
    );
  }
}

export default DashboardPerfomanceInsight;
