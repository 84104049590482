import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router"
import moment from "moment";
import ReactLoader from "../../component/react-loader/react-loader.component";
import vehicleSummaryReportApiActions from "../../redux/vehicle_summary_report/actions";
import {
  Bar,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Label,
  ComposedChart,
} from "recharts";


const timestamp = new Date().getTime();
const yesterday = timestamp - 24 * 60 * 60 * 1000;

var startTimestamp = new Date(yesterday);
startTimestamp.setHours(0);
startTimestamp.setMinutes(0);
startTimestamp.setSeconds(0);

var endTimestamp = new Date();
endTimestamp.setHours(23);
endTimestamp.setMinutes(59);
endTimestamp.setSeconds(58);

const { getvehicleSummaryGraphAPI } = vehicleSummaryReportApiActions;
class VehicleSummaryGraph extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
     start_date: startTimestamp,
     end_date: endTimestamp,
      inputSearch: "",
      filteredDatas: [],
      graphData:"",
      //editingfields: {},
    };
    
  }

  componentDidMount(){
   // console.log("called", this.props.startDate)
    this.getVehicleSummaryGraphData();
  }

  getVehicleSummaryGraphData(){
    const { start_date, end_date } = this.state;

    if (start_date < end_date) {
      this.setState({ loading: true });
      const payload = {
        user_id: sessionStorage.getItem("userId"),
        customerId: sessionStorage.getItem("customerId"),
        start_timestamp: moment(start_date).format("YYYY-MM-DD HH:mm:ss"),
        end_timestamp: moment(end_date).format("YYYY-MM-DD HH:mm:ss"),
      };
      this.props.getvehicleSummaryGraphAPI(payload);
     } 
    else if (start_date > end_date) {
      alert("End Date should be greater than Start Date");
    } else {
      alert("End Date should be greater than Start Date");
    }
    
  }

  componentWillReceiveProps(nextProps) {
    //console.log("vehiclesummarygraph", nextProps);
    if (
      nextProps?.VehicleSummaryReportApi?.vehicleSummaryGraphApi?.result?.data?.responseData
    ) {
      this.setState({
        graphData:
          nextProps.VehicleSummaryReportApi.vehicleSummaryGraphApi.result.data.responseData,
      });
    }
   
  }


  componentDidUpdate(prevProps) {
   
    if (prevProps !== this.props) {
      // if (
      //   this.props.VehicleSummaryReportApi?.vehicleSummaryReportApi?.result
      //     ?.data?.responseData?.length > 0
      // ) {
      //   this.setState({
      //     data: this.props.VehicleSummaryReportApi.vehicleSummaryReportApi
      //       .result.data.responseData,
      //   });
      //   this.setState({
      //     filteredDatas:
      //       this.props.VehicleSummaryReportApi.vehicleSummaryReportApi.result
      //         .data.responseData,
      //   });
      // } else {
      //   this.setState({ data: [] });
      // }
    }
  }

  render() {

    
    // const data = [
    //   { reg: "Jan", km: 4000, efficiency: 2400 },
    //   { reg: "Feb", km: 3000, efficiency: 1398 },
    //   { reg: "Mar", km: 2000, efficiency: 9800 },
    //   { reg: "Apr", km: 2780, efficiency: 3908 },
    // ];
    return (
      <div>
         {this.props.VehicleSummaryReportApi?.vehicleSummaryGraphApi
          ?.loading ? (
          <div className="loader-overlay">
            <div className="loader-container">
              <ReactLoader />
            </div>
          </div>
        ) : null}
        <ComposedChart width={1500} height={400} margin={{top: 20, right: 20, bottom: 0, left: 30}}data={this.state.graphData}>
          <XAxis dataKey="reg"
          // angle={-45}
          tickSize={40}
          ticks={[1, 2, 3,4,5,6,7,8,9,10,11,12,13,14,15,
            16,17,18,19,20,21,22,23,24,25,26,27,28,
            29,30,31,32,33,34,35,36,37,38,39,40]}
            domain={[1, 40]}
            tickCount={40}
          
          dx={2}
        dy={10}>
           {/* <Label value="Month" position="insideBottom" /> */}
          </XAxis>
          <YAxis yAxisId="left" domain={['auto', 'auto']} >
          <Label angle={-90} value="Distance (Km)" position="insideLeft" style={{fontFamily:"roboto-medium"}}/>
            </YAxis>
          <YAxis yAxisId="right" orientation="right" type="number" domain={['auto', 'auto']} >
          <Label angle={90} value="Efficiency (Kwh/Km)" position="insideRight" style={{fontFamily:"roboto-medium"}}/>
            </YAxis>
          <CartesianGrid stroke="#f5f5f5" />
          <Tooltip />
          <Legend />
          <Bar yAxisId="left" dataKey="km" barSize={20} fill="#9b9a9a" />
          <Line yAxisId="right" dataKey="efficiency" stroke="#ff7300" strokeWidth={3} />
        </ComposedChart>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps, {
   getvehicleSummaryGraphAPI,
})(withRouter(VehicleSummaryGraph));
