import actions from "./actions";
import { combineReducers } from "redux";

const initStateDataChangepopupone = {
  loading: false,
  result: null,
  error: false,
};

function dataChangePopupApi(state = initStateDataChangepopupone, action) {
  switch (action.type) {
    case actions.GET_DATACHANGEPOPUP:
      return {
        ...state,
        loading: true,
      };
    case actions.GET_DATACHANGEPOPUP_SUCCESS:
      return {
        ...state,
        loading: false,
        result: action.result,
      };
    case actions.GET_DATACHANGEPOPUP_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        result: {
          errorResponse: action.saveStatusCode,
          message: action.message,
        },
      };
    default:
      return state;
  }
}

export default combineReducers({
    dataChangePopupApi,
});
