import actions from "./actions";
import { combineReducers } from "redux";

const initStatecontractualdetails = {
  loading: false,
  result: null,
  error: false,
};
const initStatecontractualAssuredAvailabityList = {
  loading: false,
  result: null,
  error: false,
};
const initStatefinancialdetails = {
  loading: false,
  result: null,
  error: false,
};

const initStatesustainancedetails = {
  loading: false,
  result: null,
  error: false,
};

const initStatedriverscoredetails={
  loading: false,
  result: null,
  error: false,
}

const initStatesaftyContractual={
  loading: false,
  result: null,
  error: false,
}

const initStategeneralsaftyContractual={
  loading: false,
  result: null,
  error: false,
}

const initStateseveresaftyContractual={
  loading: false,
  result: null,
  error: false,
}

const initStatesevereContractualScheduleAdherence={
  loading: false,
  result: null,
  error: false,
}
const initStatesevereContractualReliability={
  loading: false,
  result: null,
  error: false,
}
const initStatesevereBreakdownsummary={
  loading: false,
  result: null,
  error: false,
}


const initStateseverestartPunctualityMonthly={
  loading: false,
  result: null,
  error: false,
}

const initStateseverestartPunctualityWeekly={
  loading: false,
  result: null,
  error: false,
}

const initStateseverearrivalPunctualityMonthly={
  loading: false,
  result: null,
  error: false,
}
const initStatesevereArrivalPunctualityWeekly={
  loading: false,
  result: null,
  error: false,
}
function contractualDetailsApi(state = initStatecontractualdetails, action) {
  switch (action.type) {
    case actions.GET_CONTRACTUALDETAILS:
      return {
        ...state,
        loading: true,
      };
    case actions.GET_CONTRACTUALDETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        result: action.result,
        actionType: actions.GET_CONTRACTUALDETAILS_SUCCESS,
      };
    case actions.GET_CONTRACTUALDETAILS_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        actionType: actions.GET_CONTRACTUALDETAILS_FAILURE,
        result: {
          errorResponse: action.saveStatusCode,
          message: action.message,
        },
      };
    default:
      return state;
  }
}


//


function contractualAssuredAvailabilityApi(state = initStatecontractualAssuredAvailabityList, action) {
  switch (action.type) {
    case actions.GET_CONTRACTUAL_AASURED_AVAILABITY_LIST:
      return {
        ...state,
        loading: true,
      };
    case actions.GET_CONTRACTUAL_AASURED_AVAILABITY_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        result: action.result,
        actionType: actions.GET_CONTRACTUAL_AASURED_AVAILABITY_LIST_SUCCESS,
      };
    case actions.GET_CONTRACTUAL_AASURED_AVAILABITY_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        actionType: actions.GET_CONTRACTUAL_AASURED_AVAILABITY_LIST_FAILURE,
        result: {
          errorResponse: action.saveStatusCode,
          message: action.message,
        },
      };
    default:
      return state;
  }
}


function finacialDetailsApi(
  state = initStatefinancialdetails,
  action
) {
  switch (action.type) {
    case actions.GET_FINANCIALDETAILS:
      return {
        ...state,
        loading: true,
      };
    case actions.GET_FINANCIALDETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        result: action.result,
        actionType: actions.GET_FINANCIALDETAILS_SUCCESS,
      };
    case actions.GET_FINANCIALDETAILS_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        actionType: actions.GET_FINANCIALDETAILS_FAILURE,
        result: {
          errorResponse: action.saveStatusCode,
          message: action.message,
        },
      };
    default:
      return state;
  }
}

function sustainanceDetailsApi(
  state = initStatesustainancedetails,
  action
) {
  switch (action.type) {
    case actions.GET_SUSTAINANCEDETAILS:
      return {
        ...state,
        loading: true,
      };
    case actions.GET_SUSTAINANCEDETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        result: action.result,
        actionType: actions.GET_SUSTAINANCEDETAILS_SUCCESS,
      };
    case actions.GET_SUSTAINANCEDETAILS_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        actionType: actions.GET_SUSTAINANCEDETAILS_FAILURE,
        result: {
          errorResponse: action.saveStatusCode,
          message: action.message,
        },
      };
    default:
      return state;
  }
}

function driverscoreDetailsApi(
  state = initStatedriverscoredetails,
  action
) {
  switch (action.type) {
    case actions.GET_DRIVERSCOREDETAILS:
      return {
        ...state,
        loading: true,
      };
    case actions.GET_DRIVERSCOREDETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        result: action.result,
        actionType: actions.GET_DRIVERSCOREDETAILS_SUCCESS,
      };
    case actions.GET_DRIVERSCOREDETAILS_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        actionType: actions.GET_DRIVERSCOREDETAILS_FAILURE,
        result: {
          errorResponse: action.saveStatusCode,
          message: action.message,
        },
      };
    default:
      return state;
  }
}

function saftyContractualApi(
  state = initStatesaftyContractual,
  action
) {
  switch (action.type) {
    case actions.GET_SAFTYCONTRACTUAL:
      return {
        ...state,
        loading: true,
      };
    case actions.GET_SAFTYCONTRACTUAL_SUCCESS:
      return {
        ...state,
        loading: false,
        result: action.result,
        actionType: actions.GET_SAFTYCONTRACTUAL_SUCCESS,
      };
    case actions.GET_SAFTYCONTRACTUAL_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        actionType: actions.GET_SAFTYCONTRACTUAL_FAILURE,
        result: {
          errorResponse: action.saveStatusCode,
          message: action.message,
        },
      };
    default:
      return state;
  }
}

function generalsaftyContractualApi(
  state = initStategeneralsaftyContractual,
  action
) {
  switch (action.type) {
    case actions.GET_GENERALSAFTYCONTRACTUALLIST:
      return {
        ...state,
        loading: true,
      };
    case actions.GET_GENERALSAFTYCONTRACTUALLIST_SUCCESS:
      return {
        ...state,
        loading: false,
        result: action.result,
        actionType: actions.GET_GENERALSAFTYCONTRACTUALLIST_SUCCESS,
      };
    case actions.GET_GENERALSAFTYCONTRACTUALLIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        actionType: actions.GET_GENERALSAFTYCONTRACTUALLIST_FAILURE,
        result: {
          errorResponse: action.saveStatusCode,
          message: action.message,
        },
      };
    default:
      return state;
  }
}

function severesaftyContractualApi(
  state = initStateseveresaftyContractual,
  action
) {
  switch (action.type) {
    case actions.GET_SEVERESAFTYCONTRACTUALLIST:
      return {
        ...state,
        loading: true,
      };
    case actions.GET_SEVERESAFTYCONTRACTUALLIST_SUCCESS:
      return {
        ...state,
        loading: false,
        result: action.result,
        actionType: actions.GET_SEVERESAFTYCONTRACTUALLIST_SUCCESS,
      };
    case actions.GET_SEVERESAFTYCONTRACTUALLIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        actionType: actions.GET_SEVERESAFTYCONTRACTUALLIST_FAILURE,
        result: {
          errorResponse: action.saveStatusCode,
          message: action.message,
        },
      };
    default:
      return state;
  }
}

function contractualScheduleAdherenceApi(
  state = initStatesevereContractualScheduleAdherence,
  action
) {
  switch (action.type) {
    case actions.GET_CONTRACTUALSCHEDULEADHERENCE:
      return {
        ...state,
        loading: true,
      };
    case actions.GET_CONTRACTUALSCHEDULEADHERENCE_SUCCESS:
      return {
        ...state,
        loading: false,
        result: action.result,
        actionType: actions.GET_CONTRACTUALSCHEDULEADHERENCE_SUCCESS,
      };
    case actions.GET_CONTRACTUALSCHEDULEADHERENCE_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        actionType: actions.GET_CONTRACTUALSCHEDULEADHERENCE_FAILURE,
        result: {
          errorResponse: action.saveStatusCode,
          message: action.message,
        },
      };
    default:
      return state;
  }
}

//reliability
function contractualReliabilityApi(
  state = initStatesevereContractualReliability,
  action
) {
  switch (action.type) {
    case actions.GET_CONTRACTUALSRELIABILITY:
      return {
        ...state,
        loading: true,
      };
    case actions.GET_CONTRACTUALSRELIABILITY_SUCCESS:
      return {
        ...state,
        loading: false,
        result: action.result,
        actionType: actions.GET_CONTRACTUALSRELIABILITY_SUCCESS,
      };
    case actions.GET_CONTRACTUALSRELIABILITY_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        actionType: actions.GET_CONTRACTUALSRELIABILITY_FAILURE,
        result: {
          errorResponse: action.saveStatusCode,
          message: action.message,
        },
      };
    default:
      return state;
  }
}

//breakdownsummarypopup

function contractualBreakdownSummmaryApi(
  state = initStatesevereBreakdownsummary,
  action
) {
  switch (action.type) {
    case actions.GET_CONTRACTUALBREAKDOWNSUMMARYPOPUP:
      return {
        ...state,
        loading: true,
      };
    case actions.GET_CONTRACTUALBREAKDOWNSUMMARYPOPUP_SUCCESS:
      return {
        ...state,
        loading: false,
        result: action.result,
        actionType: actions.GET_CONTRACTUALBREAKDOWNSUMMARYPOPUP_SUCCESS,
      };
    case actions.GET_CONTRACTUALBREAKDOWNSUMMARYPOPUP_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        actionType: actions.GET_CONTRACTUALBREAKDOWNSUMMARYPOPUP_FAILURE,
        result: {
          errorResponse: action.saveStatusCode,
          message: action.message,
        },
      };
    default:
      return state;
  }
}







//start --weekly

function contractualstartPunctualityWeeklyApi(
  state = initStateseverestartPunctualityWeekly,
  action
) {
  switch (action.type) {
    case actions.GET_STARTPUNCTUALITYWEEKLYDATA:
      return {
        ...state,
        loading: true,
      };
    case actions.GET_STARTPUNCTUALITYWEEKLYDATA_SUCCESS:
      return {
        ...state,
        loading: false,
        result: action.result,
        actionType: actions.GET_STARTPUNCTUALITYWEEKLYDATA_SUCCESS,
      };
    case actions.GET_STARTPUNCTUALITYWEEKLYDATA_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        actionType: actions.GET_STARTPUNCTUALITYWEEKLYDATA_FAILURE,
        result: {
          errorResponse: action.saveStatusCode,
          message: action.message,
        },
      };
    default:
      return state;
  }
}



//arrival --weekly

function contractualarrivalPunctualityWeeklyApi(
  state = initStatesevereArrivalPunctualityWeekly,
  action
) {
  switch (action.type) {
    case actions.GET_ARRIVALPUNCTUALITYWEEKLYDATA:
      return {
        ...state,
        loading: true,
      };
    case actions.GET_ARRIVALPUNCTUALITYWEEKLYDATA_SUCCESS:
      return {
        ...state,
        loading: false,
        result: action.result,
        actionType: actions.GET_ARRIVALPUNCTUALITYWEEKLYDATA_SUCCESS,
      };
    case actions.GET_ARRIVALPUNCTUALITYWEEKLYDATA_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        actionType: actions.GET_ARRIVALPUNCTUALITYWEEKLYDATA_FAILURE,
        result: {
          errorResponse: action.saveStatusCode,
          message: action.message,
        },
      };
    default:
      return state;
  }
}



export default combineReducers({
    contractualDetailsApi,
    finacialDetailsApi,
    sustainanceDetailsApi,
    driverscoreDetailsApi,
    saftyContractualApi,
    contractualAssuredAvailabilityApi,
    generalsaftyContractualApi,
    severesaftyContractualApi,
    contractualScheduleAdherenceApi,
    contractualReliabilityApi,
    contractualBreakdownSummmaryApi,
    contractualstartPunctualityWeeklyApi,
    contractualarrivalPunctualityWeeklyApi,
    

});
