import { all, takeEvery, put, call } from "redux-saga/effects";
import actions from "./actions";
import * as dataAccess from "../../utils/ajax";
import {
  TRIP_UPDATE_MASTER,
  TRIP_SUBMISSION,
  TRIP_SUBMISSION_API,
  TRIP_HISTORY_API
} from "../../constants/api-constants";

export const postLambda = async (url, payload) =>
  await dataAccess.postLambda(url, payload);

export function* getTripMasterDetailsApiSagas(data) {
  const { payload } = data;
  let url = TRIP_UPDATE_MASTER;
  try {
    let response = yield call(postLambda, url, payload);

    if (response) {
      yield put(actions.getTripMasterDetailsAPISuccess(0, response));
    }
  } catch (error) {
    yield put(actions.getTripMasterDetailsAPIFailure(1));
  }
}

export function* getTripSubmissionDetailsApiSagas(data) {
  const { payload } = data;
  let url = TRIP_SUBMISSION;
  try {
    let response = yield call(postLambda, url, payload);

    if (response) {
      yield put(actions.getTripSubmissionDetailsAPISuccess(0, response));
    }
  } catch (error) {
    yield put(actions.getTripSubmissionDetailsAPIFailure(1));
  }
}

export function* getTripMasterSubmissionApiSagas(data) {
  const { payload } = data;
  let url = TRIP_SUBMISSION_API;
  try {
    let response = yield call(postLambda, url, payload);

    if (response) {
      yield put(actions.getTripMasterSubmissionAPISuccess(0, response));
    }
  } catch (error) {
    yield put(actions.getTripMasterSubmissionAPIFailure(1));
  }
}


export function* getTripMasterHistoryApiSagas(data) {
  const { payload } = data;
  let url = TRIP_HISTORY_API;
  try {
    let response = yield call(postLambda, url, payload);

    if (response) {
      yield put(actions.getTripMasterHistoryAPISuccess(0, response));
    }
  } catch (error) {
    yield put(actions.getTripMasterHistoryAPIFailure(1));
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.GET_TRIPMASTERDETAILS, getTripMasterDetailsApiSagas),
    takeEvery(
      actions.GET_TRIPSUBMISSIONDETAILS,
      getTripSubmissionDetailsApiSagas
    ),
    takeEvery(actions.GET_TRIPMASTERSUBMISSION, getTripMasterSubmissionApiSagas),
    takeEvery(actions.GET_TRIPMASTERHISTORY, getTripMasterHistoryApiSagas),
  ]);
}
