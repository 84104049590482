import { all, takeEvery, put, call } from "redux-saga/effects";
import actions from "./actions";
import * as dataAccess from "../../utils/ajax";
import {
    FLEET_HEALTH_PANEL_VIEW,
    FLEET_HEALTH_VIEW,
    FLEET_UPTIME_CURRENT_VIEW,
    FLEET_HEALTH_DETAILS,
    FLEET_UPTIME_HISTORIC,
    HISTORIC_GRAPH
  
} from "../../constants/api-constants";

export const postLambda = async (url, payload) =>
  await dataAccess.postLambda(url, payload);

export function* getFleetHealthPanelApiSagas(data) {
  const { payload } = data;
  let url = FLEET_HEALTH_PANEL_VIEW;
  try {
    let response = yield call(postLambda, url, payload);

    if (response) {
      yield put(actions.getFleetHealthPanelViewAPISuccess(0, response));
    }
  } catch (error) {
    yield put(actions.getFleetHealthPanelViewAPIFailure(1));
  }
}



export function* getFleetHealthViewApiSagas(data) {
  const { payload } = data;
  let url = FLEET_HEALTH_VIEW;
  try {
    let response = yield call(postLambda, url, payload);

    if (response) {
      yield put(actions.getFleetHealthViewAPISuccess(0, response));
    }
  } catch (error) {
    yield put(actions.getFleetHealthViewAPIFailure(1));
  }
}




export function* FleetUptimeCurrentViewApiSagas(data) {
  const { payload } = data;
  let url = FLEET_UPTIME_CURRENT_VIEW;
  try {
    let response = yield call(postLambda, url, payload);

    if (response) {
      yield put(actions.getFleetUptimeCurrentViewAPISuccess(0, response));
    }
  } catch (error) {
    yield put(actions.getFleetUptimeCurrentViewAPIFailure(1));
  }
}

export function* getFleetHealthDetailsApiSagas(data) {
  const { payload } = data;
  let url = FLEET_HEALTH_DETAILS;
  try {
    let response = yield call(postLambda, url, payload);

    if (response) {
      yield put(actions.getFleetHealthDetailsAPISuccess(0, response));
    }
  } catch (error) {
    yield put(actions.getFleetHealthDetailsAPIFailure(1));
  }
}

export function* getFleetUptimeHistoricApiSagas(data) {
  const { payload } = data;
  let url = FLEET_UPTIME_HISTORIC;
  try {
    let response = yield call(postLambda, url, payload);

    if (response) {
      yield put(actions.getFleetUptimeHistoricAPISuccess(0, response));
    }
  } catch (error) {
    yield put(actions.getFleetUptimeHistoricAPIFailure(1));
  }
}

export function* getFleetUptimeHistoricBelowApiSagas(data) {
  const { payload } = data;
  let url = FLEET_UPTIME_HISTORIC;
  try {
    let response = yield call(postLambda, url, payload);

    if (response) {
      yield put(actions.getFleetUptimeHistoricBelowAPISuccess(0, response));
    }
  } catch (error) {
    yield put(actions.getFleetUptimeHistoricBelowAPIFailure(1));
  }
}

export function* getFleetUptimeHistoricGraphApiSagas(data) {
  const { payload } = data;
  let url = HISTORIC_GRAPH;
  try {
    let response = yield call(postLambda, url, payload);

    if (response) {
      yield put(actions.getFleetUptimeHistoricgraphAPISuccess(0, response));
    }
  } catch (error) {
    yield put(actions.getFleetUptimeHistoricgraphAPIFailure(1));
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.GET_FLEETHEALTHPANELVIEW, getFleetHealthPanelApiSagas),
takeEvery(actions.GET_FLEETHEALTHVIEW, getFleetHealthViewApiSagas),
takeEvery(actions.GET_FLEETUPTIMECURRENTVIEW, FleetUptimeCurrentViewApiSagas),
takeEvery(actions.GET_FLEETHEALTHDETAILS, getFleetHealthDetailsApiSagas),
takeEvery(actions.GET_FLEETUPTIMEHISTORIC, getFleetUptimeHistoricApiSagas),
takeEvery(actions.GET_FLEETUPTIMEHISTORICBELOW, getFleetUptimeHistoricBelowApiSagas),
takeEvery(actions.GET_FLEETUPTIMEHISTORIC_GRAPH, getFleetUptimeHistoricGraphApiSagas),

  ]);
}
