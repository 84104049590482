import React, { Component } from "react";
import "./index.scss";
import Lottie from "lottie-react";
import DataAnimation from "../../assets/lotties/bell_contractual_kpi.json";
class ComplianceKPI extends Component {
  render() {
    return (
      <div className="container-fluid">
        <div className="row ms-2">
          <div className="col-lg-4 width-20  gx-0 shadow bg-body rounded">
            <div className="compliance_kpi_head">
              <div className="compliance_kpi_head-text-1">
                CERTIFICATE REMINDER 1
              </div>
              <div class="content_img">
                <img
                  className="compliance_kpi_head_img-1"
                  src={require("../../assets/images/headernew/head.png")}
                  alt="next"
                />
              </div>
            </div>
            <div>
              <Lottie
                className="lotty-player-compliance"
                animationData={DataAnimation}
                height="10px"
                width="10px"
              />
              <p className="compliance_kpi_text-1">Your Due Date is Coming!</p>
              <hr />
              <div className="row">
                <div className="col border-right">
                  <p className="compliance_kpi_text-2">COMPLETE</p>
                </div>
                <div className="col">
                  <p className="compliance_kpi_text-2">DISMISS</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 width-20 ms-4 gx-0 shadow bg-body rounded">
            <div className="compliance_kpi_head">
              <div className="compliance_kpi_head-text-1">
                CERTIFICATE REMINDER 2
              </div>
              <div class="content_img">
                <img
                  className="compliance_kpi_head_img-1"
                  src={require("../../assets/images/headernew/head.png")}
                  alt="next"
                />
              </div>
            </div>
            <div>
              <Lottie
                className="lotty-player-compliance"
                animationData={DataAnimation}
                height="10px"
                width="10px"
              />
              <p className="compliance_kpi_text-1">Your Due Date is Coming!</p>
              <hr />
              <div className="row">
                <div className="col border-right">
                  <p className="compliance_kpi_text-2">COMPLETE</p>
                </div>
                <div className="col">
                  <p className="compliance_kpi_text-2">DISMISS</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 width-20 ms-4 gx-0 shadow bg-body rounded">
            <div className="compliance_kpi_head">
              <div className="compliance_kpi_head-text-1">
                CERTIFICATE REMINDER 3
              </div>
              <div class="content_img">
                <img
                  className="compliance_kpi_head_img-1"
                  src={require("../../assets/images/headernew/head.png")}
                  alt="next"
                />
              </div>
            </div>
            <div>
              <Lottie
                className="lotty-player-compliance"
                animationData={DataAnimation}
                height="10px"
                width="10px"
              />
              <p className="compliance_kpi_text-1">Your Due Date is Coming!</p>
              <hr />
              <div className="row">
                <div className="col border-right">
                  <p className="compliance_kpi_text-2">COMPLETE</p>
                </div>
                <div className="col">
                  <p className="compliance_kpi_text-2">DISMISS</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 width-20 ms-4 gx-0 shadow bg-body rounded">
            <div className="compliance_kpi_head">
              <div className="compliance_kpi_head-text-1">
                {" "}
                CERTIFICATE REMINDER 4
              </div>
              <div class="content_img">
                <img
                  className="compliance_kpi_head_img-1"
                  src={require("../../assets/images/headernew/head.png")}
                  alt="next"
                />
              </div>
            </div>
            <div>
              <Lottie
                className="lotty-player-compliance"
                animationData={DataAnimation}
                height="10px"
                width="10px"
              />
              <p className="compliance_kpi_text-1">Your Due Date is Coming!</p>
              <hr />
              <div className="row">
                <div className="col border-right">
                  <p className="compliance_kpi_text-2">COMPLETE</p>
                </div>
                <div className="col">
                  <p className="compliance_kpi_text-2">DISMISS</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ComplianceKPI;
