import actions from "./actions";
import { combineReducers } from "redux";

const initStateDailyoperationReport = {
  loading: false,
  result: null,
  error: false,
};

const initStatePanelDailyoperationReport = {
  loading: false,
  result: null,
  error: false,
};


function dailyOperationReportApi(state = initStateDailyoperationReport, action) {
  switch (action.type) {
    case actions.GET_DAILYOPERATIONREPORT:
      return {
        ...state,
        loading: true,
      };
    case actions.GET_DAILYOPERATIONREPORT_SUCCESS:
      return {
        ...state,
        loading: false,
        result: action.result,
      };
    case actions.GET_DAILYOPERATIONREPORT_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        result: {
          errorResponse: action.saveStatusCode,
          message: action.message,
        },
      };
    default:
      return state;
  }
}

function  paneldailyOperationReportApi(
  state = initStatePanelDailyoperationReport,
  action
) {
  switch (action.type) {
    case actions.PANEL_DAILYOPERATIONREPORT:
      return {
        ...state,
        loading: true,
      };
    case actions.PANEL_DAILYOPERATIONREPORT_SUCCESS:
      return {
        ...state,
        loading: false,
        result: action.result,
      };
    case actions.PANEL_DAILYOPERATIONREPORT_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        result: {
          errorResponse: action.saveStatusCode,
          message: action.message,
        },
      };
    default:
      return state;
  }
}



export default combineReducers({
    dailyOperationReportApi,
  paneldailyOperationReportApi
 
});
