export const TAB_MY_DASHBOARD = {
  ID: 1,
  NAME: "Live Dashboard",
};

export const TAB_CHARGING_SUMMARY = {
  ID: 2,
  NAME: "Charging Summary",
};

export const TAB_CHARGING_INFRASTRUCTURE = {
  ID: 3,
  NAME: "Charging Infrastructure",
};

export const TAB_MAP_DASHBOARD = {
  ID: 4,
  NAME: "Map Dashboard",
};

export const TAB_CONNECTED_MODULE = {
  ID: 5,
  NAME: "Connected Module",
};

export const TAB_REPORTS = {
  ID: 6,
  NAME: "Reports",
};

export const TAB_TRACK = {
  ID: 7,
  NAME: "Track",
};

export const TAB_LIVE_TRIP_TRACKING = {
  ID: 8,
  NAME: "Live Trip Tracking",
};

export const TAB_GEOFENCE = {
  ID: 9,
  NAME: "Geofence",
};

export const VIEW_REPORTS = {
  ID: 10,
  NAME: "Operation",
};

export const TAB_TRIP_UPDATE = {
  ID: 11,
  NAME: "Trip Updation",
};

export const TAB_BREAKDOWN_REPORT = {
  ID: 12,
  NAME: "Breakdown Details",
};

export const TAB_CHECKLIST_REPORT = {
  ID: 13,
  NAME: "Driver Feedback",
};

export const TAB_TRIP_DETAILS = {
  ID: 14,
  NAME: "Trip Details",
};
export const TAB_REPORTING_DETAILS = {
  ID: 15,
  NAME: "Reporting Details",
};

export const TAB_DASHBOARD = {
  ID: 16,
  NAME: "Dashboard",
};

export const TAB_REPORT = {
  ID: 17,
  NAME: "Report",
};

export const TAB_DRIVER_MONITORING = {
  ID: 18,
  NAME: "Driver Monitoring",
};

export const TAB_UPTIME_MANAGEMENT = {
  ID: 19,
  NAME: "Uptime Management",
};

export const TAB_CONTRACTUAL_KPI = {
  ID: 20,
  NAME: "Contractual KPI",
};

export const TAB_FLEET_HEALTH = {
  ID: 21,
  NAME: "Fleet Health",
};

export const TAB_FLEET_UPTIME = {
  ID: 22,
  NAME: "Fleet Uptime",
};

export const TAB_VEHICLE_SUMMARY_REPORT = {
  ID: 23,
  NAME: "Vehicle Summary",
};

export const TAB_CHARGER_REPORT = {
  ID: 24,
  NAME: "Charger Report",
};

export const TAB_TRIP_SUMMARY = {
  ID: 25,
  NAME: "Trip Summary Report",
};

export const TAB_DRIVER_DETAILS = {
  ID: 26,
  NAME: "Driver Details",
};

export const TAB_ATTENDANCE = {
  ID: 27,
  NAME: "Attendance",
};
export const TAB_TRIP_MONITORING = {
  ID: 28,
  NAME: "Trip Monitoring",
};

export const TAB_TRIP_MONITORING_LOG = {
  ID: 29,
  NAME: "Trip Monitoring Log",
};

export const TAB_MASTER = {
  ID: 30,
  NAME: "Master",
};

export const TAB_VEHICLE_MASTER = {
  ID: 31,
  NAME: "Vehicle Master",
};
export const TAB_ROOT_MASTER = {
  ID: 32,
  NAME: "Route Master",
};
export const TAB_DRIVER_MASTER = {
  ID: 33,
  NAME: "Driver Master",
};
export const TAB_DAILY_OPERATION_REPORT = {
  ID: 34,
  NAME: "Daily Operation Report",
};

export const TAB_FLEET_HEALTH_DETAILS = {
  ID: 35,
  NAME: "Fleet Health Detail",
};
export const TAB_ACCIDENT_MASTER = {
  ID: 36,
  NAME: "Accident Details",
};

export const TAB_TRIP_MASTER = {
  ID: 37,
  NAME: "Trip Master",
};

export const TAB_ALERT = {
  ID: 38,
  NAME: "Alert",
};

export const TAB_ALERT_History = {
  ID: 39,
  NAME: "Alert History",
};

export const TAB_LIVE_ALERT = {
  ID: 40,
  NAME: "Live Alerts",
};

export const TAB_SPEED_BAND = {
  ID: 41,
  NAME: "Speed Band Report",
};

export const TAB_TRACK_SUMMARY= {
  ID: 42,
  NAME: "Summary",
};
export const TAB_FLEET_UPTIME_GRAPH_VIEW= {
  ID: 43,
  NAME: "Fleet Uptime Graph View",
};

export const TAB_FLEET_UPTIME_VIEW= {
  ID: 44,
  NAME: "Fleet Uptime View",
};
export const TAB_ONBOARDING= {
  ID: 45,
  NAME: "Onboarding",
};

export const TAB_ONBOARDING_CREATE_PROJECT= {
  ID: 46,
  NAME: "Create Project",
};
export const TAB_ONBOARDING_CREATE_USER= {
  ID: 47,
  NAME: "Create User",
};

export const TAB_ONBOARDING_ASSIGN_PROJECT= {
  ID: 48,
  NAME: "Assign Project",
};


export const TAB_DATA_CHANGE_POPUP_ONE= {
  ID: 49,
  NAME: "Data Change Popup One",
};

export const TAB_VEHICLE_SUMMARY_TOGGLE = {
  ID: 50,
  NAME: "Vehicle Summary ",
};


export const TAB_EFFICIENCY_REPORT = {
  ID: 51,
  NAME: "Efficiency Report ",
};

export const TAB_DAILY_OP_SHIFT_WISE_REPORT = {
  ID: 52,
  NAME: "Daily Shift Wise Report ",
};

export const TAB_ITS_REPORT = {
  ID: 53,
  NAME: "ITS Report ",
};


export const COMMON_ERROR_MSG = "Something went wrong, please try again";
