import React from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalTitle,
  Button,
  closeButton,
} from "react-bootstrap";
import moment from "moment";
import DatePicker from "react-datepicker";
import TimePicker from "rc-time-picker";

import "./index.scss";

import { connect } from "react-redux";
import { withRouter } from "react-router";
import DateTimePicker from "react-datepicker";

//Actions
import tripMasterUpdateActions from "../../redux/trip_master_update/actions";
import tripMasterDetailsActions from "../../redux/trip_master_details/actions";

const { getTripMasterUpdateAPI } = tripMasterUpdateActions;
const { getTripMasterDetailsAPI } = tripMasterDetailsActions;

const now = moment().hour(0).minute(0);

class TripupdationPopup extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      show: false,
      editingfields: {},
      message: "",
      messageType: 0,
      date: new Date(),
      driverList: [],
      vehicleList: [],
      start_time: now,
      end_time: now,

      // actual_trip_start: "",
      // actual_trip_end: "",
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps != this.props) {
      this.setState({
        show: this.props.show,
        editingfields: this.props.data,
        driverList: this.props.driverData,
        vehicleList: this.props.vehicleData,
      });
    }
  }

  CompareTime = () => {
    if (
      this.state.start_time != "" &&
      this.state.end_time != "" &&
      this.state.start_time > this.state.end_time
    ) {
      alert("End Time should be greater than start time");
    }
  };

  componentDidMount() {
    this.setState({
      show: this.props.show,
      editingfields: this.props.data,
      driverList: this.props.driverData,
      vehicleList: this.props.vehicleData,
    });
  }

  getTripMasterDetails() {
    this.setState({ loading: true });
    const payload = {
      api_key: "ALL_TRIP_DETAILS",
      user_id: sessionStorage.getItem("userId"),
      customerId: sessionStorage.getItem("customerId"),
    };
    this.props.getTripMasterDetailsAPI(payload);
  }

  handleDateChange(evt) {
    const value = evt.target.value;
    const name = evt.target.name;
    this.setState((prevState) => ({
      editingfields: {
        // object that we want to update
        ...prevState.editingfields, // keep all other key-value pairs
        [name]: moment(value).format("YYYY-MM-DD HH:mm:00"), // update the value of specific key
      },
    }));
    this.setState({ [name]: value });
  }

  handleClose = () => {
    this.setState({ show: true });
  };

  handleChange = (evt) => {
    const value = evt.target.value;

    const name = evt.target.name;

    this.setState((prevState) => ({
      editingfields: {
        // object that we want to update
        ...prevState.editingfields, // keep all other key-value pairs
        [name]: value, // update the value of specific key
      },
    }));
    ///this.getTripMasterDetails();
  };

  handleSubmit = (event) => {
    event.preventDefault();
    //  console.log("popup resp", this.state.editingfields);
    const {
      schedule_id,
      route_id,
      driver_id,
      driver_name,
      vin_no,
      reg_no,
      name_tagid,
      reg_vin,
    } = this.state.editingfields;
    let a = reg_no.split("->");

    var driverString = name_tagid.split(/->/);
    var driverarr = [driverString.shift(), driverString.join(" ")];
    // console.log("Welcome to Programiz!1", driverarr[0]);
    // console.log("Welcome to Programiz!2", driverarr[1]);

    var vehicleString = reg_vin.split(/->/);
    var vehiclearr = [vehicleString.shift(), vehicleString.join(" ")];
    // console.log("Welcome to Programiz!3", vehiclearr[0]);
    // console.log("Welcome to Programiz!4", vehiclearr[1]);

    const payload = {
      schedule_id,
      route_id,
      driver_id: driverarr[1],
      reg_no: vehiclearr[0],
      vin_no: vehiclearr[1],
    };
    // console.log("payload", payload);
    this.props.getTripMasterUpdateAPI(payload);

    setTimeout(() => {
      this.getTripMasterDetails();
    }, 3000);
    ///this.getTripMasterDetails();
  };

  render() {
    let val = this.state.editingfields.reg_no;
    //console.log("searching", this.state.editingfields.name_tagid);
    var time = this.state.editingfields.start_time;
    var time1 = this.state.editingfields.end_time;
    var date = "2017-03-13";
    return (
      <div className="popup-box">
        <Modal
          style={{
            height: "800px",
            minHeight: "500px",
          }}
          show={this.state.show}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          onHide={this.props.closemodal}
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
          </Modal.Header>
          <form
            className="trip-popup-update"
            autocomplete="off"
            onSubmit={this.handleSubmit}
          >
            <Modal.Body
              style={{
                "max-height": "calc(80vh - 210px)",

                "overflow-y": "auto",
              }}
            >
              <div className="tripid">
                <span>Trip ID : {this.state.editingfields["schedule_id"]}</span>
                <span>Route No : {this.state.editingfields["route_id"]}</span>
                {/* <span>
                  Start Time : {this.state.editingfields["start_time"]}
                </span> */}
              </div>
              <div className="formwidth">
                <div className="col_50">
                  <div className="col_100">
                    <label>Registration Number :</label>
                    <span style={{ color: "red", marginTop: "5%", marginLeft: "-5%"}}> *</span>
                  </div>
                  <div className="col_100">
                    <select
                      placeholder="Registration Number:"
                      name="reg_vin"
                      onChange={this.handleChange}
                      value={this.state.editingfields.reg_vin}
                    >
                      <option defaultValue=""></option>
                      {this.state.vehicleList.map((e, i) => (
                        <option key={i} value={e.reg_vin}>
                          {e.reg_vin}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="col_50">
                  <div className="col_100">
                    <label>Driver Name:</label>
                    <span style={{ color: "red", marginTop: "5%", marginLeft: "-23%" }}> *</span>
                  </div>
                  <div className="col_100">
                    <select
                      placeholder="Driver Name:"
                      name="name_tagid"
                      onChange={this.handleChange}
                      value={this.state.editingfields.name_tagid}
                    >
                      <option defaultValue=""> </option>
                      {this.state.driverList.map((e, i) => (
                        <option key={i} value={e.name_tagid}>
                          {e.name_tagid}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                
                <div className="col_50">
                  <div className="col_100">
                    <label>Start Time :</label>
                    <span style={{ color: "red", marginLeft: "-26%",marginTop: "6%"}}> *</span>
                  </div>
                  <div className="col_100">
                  <TimePicker
                    autocomplete="off"
                    TimeFormat="HH:mm"
                    showSecond={false}
                    className="xxx"
                    //value={this.state.editingfields.start_time}
                    defaultValue={moment(date + " " + time)}
                    onChange={(value) => {
                      this.setState({ start_time: value }, () =>
                        this.CompareTime()
                      );
                    }}
                    selected={this.state.editingfields.start_time}
                    name="start_time"
                    required={true}
                  />
                  </div>
                </div>
                <div className="col_50">
                  <div className="col_100">
                    <label>End Time :</label>
                    <span style={{ color: "red", marginLeft: "-28%",marginTop: "6%" }}> *</span>
                  </div>
                  <div className="col_100">
                  <TimePicker
                    autocomplete="off"
                    TimeFormat="HH:mm:00"
                    showSecond={false}
                    defaultValue={moment(date + " " + time1)}
                    //value={this.state.editingfields.end_time}
                    className="xxx"
                    onChange={(value) => {
                      this.setState({ end_time: value }, () =>
                        this.CompareTime()
                      );
                    }}
                    required
                    name="end_time"
                    selected={this.state.end_time}
                  />
                  </div>
                </div>

                <div class="col_50">
              <div className="col_100">
                <label>
                  Start Location:<span style={{ color: "red" }}> *</span>
                </label>
              </div>
              <div className="col_100">
                <input
                  type="text"
                  placeholder=""
                  //style={{ textTransform: "uppercase" }}
                  name="duty"
                    value={this.state.editingfields.start_location}
                  //  onChange={this.handleChange}
                  autocomplete="off"
                  required
                />
              </div>
            </div>
            <div class="col_50">
              <div className="col_100">
                <label>
                  End Location:<span style={{ color: "red" }}> *</span>
                </label>
              </div>
              <div className="col_100">
                <input
                  type="text"
                  placeholder=""
                  //style={{ textTransform: "uppercase" }}
                  name="duty"
                    value={this.state.editingfields.end_location}
                  //  onChange={this.handleChange}
                  autocomplete="off"
                  required
                />
              </div>
            </div>

              </div>
            </Modal.Body>
            <Modal.Footer>
              <input className="button1" type="submit" value="Update" />
            </Modal.Footer>
          </form>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps, {
  getTripMasterUpdateAPI,
  getTripMasterDetailsAPI,
})(withRouter(TripupdationPopup));

//export default Popup;
