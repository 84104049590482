import React from "react";
import Mydashboard from "../../container/my_dashboard";
import ChargeSummary from "../../container/charge_summary";
import MenuHeader from "../../container/menu_header";
import BasePage from "../../pages/base-page";
import Header from "../../container/header";
import Track from "../../container/track";
import BreakdownReport from "../../component/breakdown_report";
import ChecklistReport from "../../component/driver-feedback";
import TripDetails from "../../component/trip_details";
import LiveTripTracking from "../../container/live_trip_tracking";
import ReportingDetails from "../../component/reporting_details";
import ReactLoader from "../../component/react-loader/react-loader.component";
import DashboardStatus from "../../container/dashboard_status";
import ItsReport from "../../container/its_report"

import {
  TAB_MY_DASHBOARD as TabMyDashboard,
  TAB_CHARGING_SUMMARY as TabChargingSummary,
  TAB_TRACK as TabTrack,
  TAB_LIVE_TRIP_TRACKING as TabLiveTripTracking,
  TAB_BREAKDOWN_REPORT as TabBreakdownReport,
  TAB_CHECKLIST_REPORT as TabChecklistReport,
  TAB_REPORTING_DETAILS as TabReportingDetails,
  TAB_TRIP_DETAILS as TabTripDetails,
  TAB_DASHBOARD as TabDashboard,
  TAB_FLEET_HEALTH as TabFleethealth,
  TAB_FLEET_UPTIME as TabFleetuptime,
  TAB_VEHICLE_SUMMARY_REPORT as TabVehicleSummaryReport,
  TAB_CHARGER_REPORT as TabChargerReport,
  TAB_TRIP_SUMMARY as TabTripSummary,
  TAB_DRIVER_DETAILS as TabDriverDetails,
  TAB_ATTENDANCE as TabAttandance,
  TAB_TRIP_MONITORING as TabTripmonitoring,
  TAB_TRIP_MONITORING_LOG as TabTripminitoringlog,
  TAB_VEHICLE_MASTER as TabVehicleMaster,
  TAB_ROOT_MASTER as TabRootMaster,
  TAB_DRIVER_MASTER as TabDriverMaster,
  TAB_CONTRACTUAL_KPI as TabContractualKPI,
  TAB_DAILY_OPERATION_REPORT as TabDailyOperationReport,
  TAB_FLEET_HEALTH_DETAILS as TabFleetHealthDetails,
  TAB_ACCIDENT_MASTER as TabAccidentMaster,
  TAB_TRIP_MASTER as TabTripMaster,
  TAB_SPEED_BAND as TabSpeedBand,
  TAB_LIVE_ALERT as TabLiveAlert,
  TAB_ALERT_History as TabAlertHistory,
  TAB_FLEET_UPTIME_GRAPH_VIEW as TabFleetUptimeGraphView,
  TAB_ONBOARDING_CREATE_PROJECT as TabCreateproject,
  TAB_ONBOARDING_CREATE_USER as TabCreateUser,
  TAB_ONBOARDING_ASSIGN_PROJECT as TabAssignProject,
  TAB_DATA_CHANGE_POPUP_ONE as TabDataChangePopupOne,
  TAB_VEHICLE_SUMMARY_TOGGLE as TabVehicleSummaryToggle,
  TAB_EFFICIENCY_REPORT as TabEfficiencyReport,
  TAB_DAILY_OP_SHIFT_WISE_REPORT as TabDailyOpWiseReport,
  TAB_ITS_REPORT as TabItsReport,
} from "../../constants/app-contants";
import FleetHealth from "../../container/fleet_health";
import FleetUptime from "../../container/fleet_uptime";
import VehicleSummaryReport from "../../container/vehicle_summary_report";
import ChargerReport from "../../container/charger_report";
import TripSummaryReport from "../../container/trip_summary_report";
import DriverDetails from "../../container/driver_details";
import DriverMonitoringAttandance from "../../container/driver_monitoring_attandance";
import TripMonitoringReport from "../../container/trip_monitoring_report";
import TripMonitoringLog from "../../container/trip_monitoring_log";
import MasterVehicle from "../../component/master_vehicle";
import MasterRoute from "../../component/master_route";
import MasterDriver from "../../component/master_driver";
import ContractualKPI from "../../container/contractual_kpi";
import DailyOperationReport from "../../component/daily_operaton_report";
import FleetHealthDetails from "../../container/fleet_health_details";
import MasterAccident from "../../component/master_accident";
import TripMaster from "../../container/trip_master";
import Alert from "../../container/alert";
import AlertHistory from "../../container/alert_history";
import SpeedBand from "../../container/speed_band";
import HistoricGraphView from "../../component/historic_graph_view";
import OnboardingAssignProject from "../../container/onboarding_assign_project";
import OnboardingCreateUser from "../../container/onboarding_create_user";
import OnboardingCreateProject from "../../container/onboarding_create_project";
import DataChangePopupOne from "../../component/data_change_popup_one";
import VehicleSummaryReportToggle from "../../container/vehicle_summary_toggle";
import EfficiencyReport from "../../component/efficiency_report";
import DailyOpShiftWiseReport from "../../component/daily_op_shift_wise_report";
//import FleetUptimeHistoricView from "../../component/fleet_uptime_historic_view";
class Dashboard extends BasePage {
  constructor(props) {
    super(props);
    this.state = {
      loader: false,
      hideMenu: false,
    };
    let selId = "";
    // let selId = sessionStorage.getItem("selId");
    //   let selId = 16;

    //   this.state = {
    //     selTab: selId ? parseInt(selId, 10) : TabTrack.ID,
    //   };

    //   this.selectedTab = this.selectedTab.bind(this);
    // }

//commeted

    if (sessionStorage.getItem("showMenus") === "true") {
      selId = 16;
    } else {
      selId = 49;
    }

    this.state = {
      selTab: selId ? parseInt(selId, 10) : TabTrack.ID,
    };

    this.selectedTab = this.selectedTab.bind(this);



  //   if (sessionStorage.getItem("showMenus") == "false") {
  //     selId = 16;
  //   } 
  //   // else {
  //   //   selId = 49;
  //   // }
  //   this.state = {
  //     selTab: selId ? parseInt(selId, 10) : TabDashboard.ID,
  //   };
  //   this.selectedTab = this.selectedTab.bind(this);
  
   }


  componentWillUnmount() {}

  // componentDidMount() {
  //   // this.props.history.push("/dashboard");
  // }
  // componentDidMount() {
  //   const isMenuHidden = sessionStorage.getItem("hideMenu");
  //   if (isMenuHidden === "true") {
  //     this.setState({ hideMenu: true });
  //   }
  // }

  componentWillReceiveProps(nextProps) {}

  selectedTab = (evt, tabItem) => {
    this.setState({
      selTab: tabItem.tabIndex,
    });
    sessionStorage.setItem("selId", tabItem.tabIndex);
  };

  getLoader = () => (
    <div className="loader-overlay">
      <div className="loader-container">
        <ReactLoader />
      </div>
    </div>
  );
  render() {
    const { selTab, loginDetails, PageLoader } = this.state;
   // console.log("tab click", selTab);
    return (
      <BasePage pageIdentifier="dashboard-page">
        {/* {this.getLoader()} */}

        {/* {PageLoader ? (
            <div className="landing-page--loader">
              <ReactLoader />
            </div>
          ) : null} */}

        <Header selectedTab={this.selectedTab} />

        <MenuHeader selTab={selTab} selectedTab={this.selectedTab} />

        {TabMyDashboard.ID === selTab && (
          <Mydashboard selectedTab={this.selectedTab} />
        )}
        {TabChargingSummary.ID === selTab && (
          <ChargeSummary selectedTab={this.selectedTab} />
        )}
        {TabTrack.ID === selTab && <Track selectedTab={this.selectedTab} />}
        {/* {TabTripUpdate.ID === selTab && <TripUpdate selectedTab={this.selectedTab}/>} */}
        {TabBreakdownReport.ID === selTab && (
          <BreakdownReport selectedTab={this.selectedTab} />
        )}
        {TabChecklistReport.ID === selTab && (
          <ChecklistReport selectedTab={this.selectedTab} />
        )}
        {TabReportingDetails.ID === selTab && (
          <ReportingDetails selectedTab={this.selectedTab} />
        )}
        {TabTripDetails.ID === selTab && (
          <TripDetails selectedTab={this.selectedTab} />
        )}
        {TabLiveTripTracking.ID === selTab && (
          <LiveTripTracking selectedTab={this.selectedTab} />
        )}
        {TabDashboard.ID === selTab && (
          <DashboardStatus selectedTab={this.selectedTab} />
        )}
        {TabFleethealth.ID === selTab && (
          <FleetHealth selectedTab={this.selectedTab} />
        )}
        {TabFleetuptime.ID === selTab && (
          <FleetUptime selectedTab={this.selectedTab} />
        )}
        {TabVehicleSummaryReport.ID === selTab && (
          <VehicleSummaryReport selectedTab={this.selectedTab} />
        )}
        {TabChargerReport.ID === selTab && (
          <ChargerReport selectedTab={this.selectedTab} />
        )}
        {TabTripSummary.ID === selTab && (
          <TripSummaryReport selectedTab={this.selectedTab} />
        )}
        {TabDailyOperationReport.ID === selTab && (
          <DailyOperationReport selectedTab={this.selectedTab} />
        )}
        {TabDriverDetails.ID === selTab && (
          <DriverDetails selectedTab={this.selectedTab} />
        )}
        {TabAttandance.ID === selTab && (
          <DriverMonitoringAttandance selectedTab={this.selectedTab} />
        )}
        {TabTripmonitoring.ID === selTab && (
          <TripMonitoringReport selectedTab={this.selectedTab} />
        )}
        {TabTripminitoringlog.ID === selTab && (
          <TripMonitoringLog selectedTab={this.selectedTab} />
        )}

        {TabVehicleMaster.ID === selTab && (
          <MasterVehicle selectedTab={this.selectedTab} />
        )}
        {TabRootMaster.ID === selTab && (
          <MasterRoute selectedTab={this.selectedTab} />
        )}
        {TabDriverMaster.ID === selTab && (
          <MasterDriver selectedTab={this.selectedTab} />
        )}
        {TabContractualKPI.ID === selTab && (
          <ContractualKPI selectedTab={this.selectedTab} />
        )}

        {TabFleetHealthDetails.ID === selTab && (
          <FleetHealthDetails selectedTab={this.selectedTab} />
        )}
        {TabAccidentMaster.ID === selTab && (
          <MasterAccident selectedTab={this.selectedTab} />
        )}
        {TabTripMaster.ID === selTab && (
          <TripMaster selectedTab={this.selectedTab} />
        )}

        {TabLiveAlert.ID === selTab && <Alert selectedTab={this.selectedTab} />}
        {TabAlertHistory.ID === selTab && (
          <AlertHistory selectedTab={this.selectedTab} />
        )}
        {TabSpeedBand.ID === selTab && (
          <SpeedBand selectedTab={this.selectedTab} />
        )}
        {/* {TabTrackSummary.ID === selTab && (
          <Summary selectedTab={this.selectedTab} />
        )} */}
        {TabFleetUptimeGraphView.ID === selTab && (
          <HistoricGraphView selectedTab={this.selectedTab} />
        )}

        {TabCreateproject.ID === selTab && (
          <OnboardingCreateProject selectedTab={this.selectedTab} />
        )}

        {TabCreateUser.ID === selTab && (
          <OnboardingCreateUser selectedTab={this.selectedTab} />
        )}

        {TabAssignProject.ID === selTab && (
          <OnboardingAssignProject selectedTab={this.selectedTab} />
        )}
        {TabDataChangePopupOne.ID === selTab && (
          <DataChangePopupOne selectedTab={this.selectedTab} />
        )}

        {TabVehicleSummaryToggle.ID === selTab && (
          <VehicleSummaryReportToggle selectedTab={this.selectedTab} />
        )}

        {TabEfficiencyReport.ID === selTab && (
          <EfficiencyReport selectedTab={this.selectedTab} />
        )}

        {TabDailyOpWiseReport.ID === selTab && (
          <DailyOpShiftWiseReport selectedTab={this.selectedTab} />
        )}
        {TabItsReport.ID === selTab && (
          <ItsReport selectedTab={this.selectedTab} />
        )}
      </BasePage>
    );
  }
}

export default Dashboard;
