import React, { Component } from "react";
import Header from "../header";
import SubMenuHeader from "../sub_menu_header";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import Subheader from "../menu_header";
import Chargers from "../chargers";
import OpportunityChargers from "../opportunity_chargers";
import Dashboard from "../../pages/dashboard-page";
import BackToScreen from "../../component/back_to_screen";
import stationApiActions from "../../redux/station_details/actions";

import moment from "moment";

import Footer from "../footer/index";
import "../my_dashboard/index.scss";
import BatteryGraphStatus from "../battery_graph_status/index";
import ChargeSummaryGrid from "../charge_summary_grid/index.js";

const { getStationAPI } = stationApiActions;

class Mydashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      userid: "",
      password: "",
      totalDepotChargers:"",
      totalOpportunityChargers:""
    };

    this.backtodashboardPage = this.backtodashboardPage.bind(this);
  }

  backtodashboardPage() {
    let tabItem = {
      tabIndex: 16,
      tabName: "Dashboard",
    };
    this.props.selectedTab(null, tabItem);
  }

  componentWillReceiveProps(nextProps) {
    //  console.log("datasss", this.props);
      if (this.props !== nextProps) {
        if (
          nextProps?.stationApi?.stationApi?.result?.data?.responseData
        ) {
          this.setState({
            totalDepotChargers:
              nextProps.stationApi.stationApi.result.data.responseData
                .total_depot_chargers,
          });
          this.setState({
            totalOpportunityChargers:
              nextProps.stationApi.stationApi.result.data.responseData
                .total_opportunity_chargers,
          });
        }
      }
    }

  render() {
    //  window.location.reload(false);
    return (
      <div className="mydashboard">
        <div className="child">
          <div className="fleet-health-backward">
            <BackToScreen routingCall={this.backtodashboardPage} />
            <h3 className="charging_infrastructure_head">
              Charging Infrastructure
            </h3>
          </div>
          <div className="head-mydashboard">
            <div className="container-fluid">
              <div className="row">
                <div className="col-2">
                  <span>Depot Chargers</span>
                </div>
                <div className="col-8"></div>
                <div className="col-2">
                  <span>Total Number Of Chargers : {this.state.totalDepotChargers}</span>
                </div>
              </div>
            </div>
          </div>

          {/* <SubMenuHeader component="mydashboard" /> */}
          <Chargers />
          <div className="head-mydashboard">
            <div className="container-fluid">
              <div className="row">
                <div className="col-2">
                  <span>Opportunity Chargers</span>
                </div>
                <div className="col-8"></div>
                <div className="col-2">
                  <span className="">Total Number Of Chargers : {this.state.totalOpportunityChargers}</span>
                </div>
              </div>
            </div>
          </div>
          <OpportunityChargers />
          <BatteryGraphStatus />
          <ChargeSummaryGrid />
          <Footer />
        </div>
      </div>
    );
  }
}

//export default withRouter(Mydashboard);

const mapStateToProps = (state) => {
  return {
    ...state,
    // login: state.login,
  };
};

export default connect(mapStateToProps, {getStationAPI})(withRouter(Mydashboard));
//export default connect(mapStateToProps, {})(withRouter(Mydashboard));
