import React, { Component } from "react";
import ReactTable from "react-table";
import "react-table/react-table.css";
import "./index.scss";
import ReactTooltip from "react-tooltip";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import Workbook from "react-excel-workbook";
import BackToScreen from "../../component/back_to_screen";
import { matchSorter } from "match-sorter";
import moment from "moment";
import ReactLoader from "../react-loader/react-loader.component";
import ReactDatePicker from "react-datepicker";
import { addDays } from "date-fns";
import DatePicker from "react-datepicker";
// //Actions
import driverReportingDetailsActions from "../../redux/driver_reporting_details/actions";

const { getDriverReportingDetailsAPI } = driverReportingDetailsActions;
var startTimestamp = new Date();
startTimestamp.setHours(0);
startTimestamp.setMinutes(0);
startTimestamp.setSeconds(0);

var endTimestamp = new Date();
endTimestamp.setHours(23);
endTimestamp.setMinutes(59);
endTimestamp.setSeconds(58);

var now = new Date();
now.setDate(now.getDate());
class ReportingDetails extends Component {
  constructor(props) {
    super(props);
  
    this.state = {
      data: [],
      pages: null,
      loading: true,
      message: "",
      messageType: 0,
      driverdataSet: [],
      selected: null,
      start_date: startTimestamp,
      end_date: endTimestamp,
      filteredData: [],
      searchInput: "",
      editingfields: {},
    };
    this.backtodashboardPage = this.backtodashboardPage.bind(this);
  }
  reset = () => {
    this.setState({
      start_date: startTimestamp,
      end_date: endTimestamp,
    });
  };

  componentDidMount() {
    //getting all the data
    this.getReportingDetails();
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      // console.log("this.props",this.props)
      if (
        this.props.driverReportingDetailsApi?.driverReportingDetailsApi?.result
          ?.data?.responseData?.length > 0
      ) {
        this.setState({
          driverdataSet:
            this.props.driverReportingDetailsApi.driverReportingDetailsApi
              .result.data.responseData,
        });

        this.setState({
          filteredData:
            this.props.driverReportingDetailsApi.driverReportingDetailsApi
              .result.data.responseData,
        });
      } else {
        this.setState({ driverdataSet: [] });
      }
    }
  }

  onSubmit = (e) => {
    this.getReportingDetails();
  };

  getReportingDetails() {
    const { start_date, end_date } = this.state;
    if (start_date < end_date) {
      const payload = {
        //api_key: "REPORT_DRIVER_REPORTING_DETAILS",
        user_id: sessionStorage.getItem("userId"),
        customerId: sessionStorage.getItem("customerId"),
        start_timestamp: moment(start_date).format("YYYY-MM-DD HH:mm:ss"),
        end_timestamp: moment(end_date).format("YYYY-MM-DD HH:mm:ss"),
      };
      this.props.getDriverReportingDetailsAPI(payload);
    } else if (start_date > end_date) {
      alert("End Date should be greater than Start Date");
    } else {
      alert("End Date should be greater than Start Date");
    }
  }
  // getSummary = () => {
  //   document.getElementById("summaryExcelDownloadBtn").click();
  // };

  reportingdetailshandleChange = (event) => {
    const search = event.target.value.toLowerCase();
    this.setState({
      searchInput: search,
      filteredData: this.state.driverdataSet.filter(
        (item) =>
          (item.name && item.name.toLowerCase().includes(search)) ||
          (item.driverid && item.driverid.toLowerCase().includes(search))
      ),
    });
  };

  backtodashboardPage() {
    let tabItem = {
      tabIndex: 16,
      tabName: "Dashboard",
    };
    this.props.selectedTab(null, tabItem);
  }
  handleDateChange(evt) {
    const value = evt.target.value;

    const name = evt.target.name;
    this.setState((prevState) => ({
      editingfields: {
        // object that we want to update

        ...prevState.editingfields, // keep all other key-value pairs
        [name]: moment(value).format("YYYY-MM-DD HH:mm:00"),
        // update the value of specific key
      },
    }));
    this.setState({ [name]: value });
  }

  render() {
    let { searchInput } = this.state;
    // const { data } = this.state;
    // console.log("dropdown props", this.props);
    return (
      <div>
        {this.props.driverReportingDetailsApi?.driverReportingDetailsApi
          ?.loading ? (
          <div className="loader-overlay">
            <div className="loader-container">
              <ReactLoader />
            </div>
          </div>
        ) : null}

        <div className="fleet-health-backward">
          <BackToScreen routingCall={this.backtodashboardPage} />
          <h3 className="tripsummary">Reporting Details</h3>
        </div>

        <div className="container-fluid">
          <div className="row d-flex ms-2 mt-1">
            <div className="col-6 d-flex">
              <div className="reporting_details_labels">
                <label className="reporting_details_labels_styles">
                  Start Date:
                </label>

                <DatePicker
                  autocomplete="off"
                  name="start_date"
                  //selected={this.state.start_date}

                  selected={this.state.start_date}
                  maxDate={moment().toDate()}
                  minDate={addDays(new Date(), -90)}
                  value={
                    this.state.editingfields.start_date == "undefined"
                      ? this.state.start_date.setHours(0, 0, 0)
                      : this.state.editingfields.start_date
                  }
                  onChange={(value) =>
                    this.handleDateChange({
                      target: { name: "start_date", value },
                    })
                  }
                  //defaultValue={moment(now).toDate()}
                  timeInputLabel="Time:"
                  dateFormat="yyyy-MM-dd HH:mm:00"
                  showTimeInput
                />

                <label className="reporting_details_labels_styles-1">
                  End Date:
                </label>

                <DatePicker
                  autocomplete="off"
                  // valueDefault={null}
                  //maxDate={moment().toDate()}
                  name="end_date"
                  //selected={this.state.end_date}
                  selected={this.state.end_date}
                  maxDate={moment().toDate()}
                  minDate={addDays(new Date(), -90)}
                  value={
                    this.state.editingfields.end_date == "undefined"
                      ? this.state.end_date.setHours(23, 59, 59)
                      : this.state.editingfields.end_date
                  }
                  onChange={(value) =>
                    this.handleDateChange({
                      target: { name: "end_date", value },
                    })
                  }
                  //defaultValue="01-01-2001 01:01:01"
                  timeInputLabel="Time:"
                  dateFormat="yyyy-MM-dd HH:mm:00"
                  showTimeInput
                />
              </div>
              <div>
                <button
                  className="reporting_details_button-submit-kpis"
                  onClick={this.onSubmit}
                >
                  <i
                    className="fa fa-check"
                    style={{ paddingRight: "5px" }}
                  ></i>
                  Submit
                </button>
              </div>
              <div>
                <button
                  className="reporting_details_button-submit-kpis-1"
                  onClick={this.reset}
                >
                  <i
                    className="fa fa-refresh"
                    style={{ paddingRight: "5px" }}
                  ></i>{" "}
                  Reset
                </button>
              </div>
            </div>
            <div className="col-6 d-flex">
              <div className="reporting_details_report">
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                  }}
                >
                  <input
                    className="reporting_details_input"
                    type="text"
                    id="filter"
                    placeholder="Search Table (Driver Name / Driver ID)"
                    value={searchInput || ""}
                    onChange={this.reportingdetailshandleChange}
                  />
                </form>
              </div>
              <div>
                <Workbook
                  filename="Reporting-Details.xlsx"
                  element={
                    <div
                      className="reporting_details_download"
                      data-tip
                      data-for="registerTip"
                    >
                      Export
                      <img
                        src={require("../../assets/images/export/export.png")}
                        alt=""
                        className="reporting_details_download-img"
                      />
                      <ReactTooltip id="registerTip" place="top" effect="solid">
                        Download
                      </ReactTooltip>
                    </div>
                  }
                >
                  <Workbook.Sheet
                    data={this.state.driverdataSet}
                    name="Reporting-Details"
                  >
                    <Workbook.Column label="Driver ID" value="driverid" />
                    <Workbook.Column label="Driver name" value="name" />
                    <Workbook.Column
                      label="Reporting timestamp"
                      value="reportingtime"
                    />
                    <Workbook.Column label="Location" value="location" />
                  </Workbook.Sheet>
                </Workbook>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="tripsummary-first-div">
            <div className="tripsummary-labels">
              <label className="tripsummary-labels_style-1">Start Date:</label>
              <DatePicker
                name="start_date"
                selected={this.state.start_date}
                maxDate={moment().toDate()}
                // minDate={addDays(new Date(), -90)}
                value={this.state.start_date}
                // onChange={(value) => {
                //   this.setState({ start_date: value }, () => this.Compare());
                // }}
                onChange={(value) => this.Compare("start_date", value)}
                dateFormat="yyyy-MM-dd"
              />

              <label className="tripsummary-labels_style">End Date:</label>
              <DatePicker
                wrapperClassName="datepicker"
                name="end_date"
                selected={this.state.end_date}
                maxDate={moment().toDate()}
                // minDate={addDays(new Date(), -90)}
                value={this.state.end_date}
                // onChange={(value) => {
                //   this.setState({ end_date: value }, () => this.Compare());
                // }}
                onChange={(value) => this.Compare("end_date", value)}
                dateFormat="yyyy-MM-dd"
              />
            </div>
            <div style={{ marginLeft: "-140px" }}>
              <button
                className="tripsummary-button-submit-kpi"
                onClick={this.onSubmit}
              >
                <i className="fa fa-check" style={{ paddingRight: "5px" }}></i>
                Submit
              </button>
            </div>
            <div>
              <button
                className="tripsummary-button-submit-kpi"
                onClick={this.reset}
              >
                <i
                  className="fa fa-refresh"
                  style={{ paddingRight: "5px" }}
                ></i>{" "}
                Reset
              </button>
            </div>

          
            <div className="Reporting-Details-report">
          <form>
            <input
             
              className="Reporting-Details-input"
              type="text"
              id="filter"
              placeholder="Search Table (Driver Name / Driver ID)"
              value={searchInput || ""}
              onChange={this.reportingdetailshandleChange}
            />
          </form>
        </div>

        <div>
              <Workbook
                filename="Reporting-Details.xlsx"
                element={
                  <div className="Reporting-Details-download">
                  Export
                  <img
                    src={require("../../assets/images/export/export.png")}
                    alt=""
                    className="Reporting-Details-download-img"
                  />
                </div>
                }
              >
                <Workbook.Sheet
                  data={this.state.driverdataSet}
                  name="Reporting-Details"
                >
                  <Workbook.Column label="Driver name" value="name" />
                  <Workbook.Column
                    label="Reporting timestamp"
                    value="reportingtime"
                  />
                  <Workbook.Column label="Location" value="location" />
                </Workbook.Sheet>
              </Workbook>
             
            </div>


          </div> */}

        <div className="upload-container icon_pos">
          {/* <h2 className="msg">
            Note: Future trips will be reflected in Excel Sheet
          </h2> */}
          <ReactTable
            NoDataComponent={() => null}
            // previousText={myCustomPreviousText}
            // nextText={myCustomNextText}
            pageSizeOptions={[20, 30, 50, 80, 100, 130, 200, 500]}
            style={{
              height: "490px",
              // width: "1570px",
              marginRight: "30px",
              marginLeft: "40px",
              marginTop: "0%",
            }}
            data={this.state.filteredData}
            // // defaultPageSize={initialSize}
            // //pageSize={updatedSize}
            // loading={this.state.loading}
            // filterable
            // defaultFilterMethod={(filter, row) =>
            //   String(row[filter.id]) === filter.value
            // }
            columns={[
              {
                Header: "S.No",
                id: "serialNumber",
                filterable: false,
                resizable: false,
                width: 90,
                Cell: (row) => {
                  return <div>{row.index + 1}</div>;
                },
              },

              {
                Header: "Driver ID",
                id: "driverid",
                accessor: (d) => d.driverid,
                width: 100,
                filterMethod: (filter, rows) =>
                  matchSorter(rows, filter.value, { keys: ["driverid"] }),
                filterAll: true,
                resizable: false,
              },
              {
                Header: "Driver Name",
                id: "name",
                accessor: (d) => d.name,
                width: 400,
                filterMethod: (filter, rows) =>
                  matchSorter(rows, filter.value, { keys: ["name"] }),
                filterAll: true,
                resizable: false,
              },
              {
                Header: "Reporting Timestamp",
                id: "reportingtime",
                accessor: (d) => d.reportingtime,
                width: 360,
                filterMethod: (filter, rows) =>
                  matchSorter(rows, filter.value, {
                    keys: ["reportingtime"],
                  }),
                filterAll: true,
                resizable: false,
              },
              {
                Header: "Location",
                id: "location",
                accessor: (d) => d.location,
                width: 500,
                filterMethod: (filter, rows) =>
                  matchSorter(rows, filter.value, { keys: ["location"] }),
                filterAll: true,
                resizable: false,
              },
            ]}
            defaultSorted={[
              {
                id: "serialNumber",
                asc: true,
              },
              // {
              //   id: "status",
              // asc: true,
              // },
            ]}
            defaultPageSize={50}
            showPagination={true}
            className="-striped -highlight"
          >
            {(state, makeTable, instance) => {
              let recordsInfoText = "";

              const { filtered, pageRows, pageSize, sortedData, page } = state;

              if (sortedData && sortedData.length > 0) {
                let isFiltered = filtered.length > 0;

                let totalRecords = sortedData.length;

                let recordsCountFrom = page * pageSize + 1;

                let recordsCountTo = recordsCountFrom + pageRows.length - 1;

                if (isFiltered)
                  recordsInfoText = `${recordsCountFrom}-${recordsCountTo} of ${totalRecords} filtered records`;
                else
                  recordsInfoText = `${recordsCountFrom}-${recordsCountTo} of ${totalRecords} records`;
              } else recordsInfoText = "No records";

              return (
                <div className="main-grid">
                  <div className="above-table text-right">
                    <div className="col-sm-12">
                      <span className="records-info">{recordsInfoText}</span>
                    </div>
                  </div>

                  {makeTable()}
                </div>
              );
            }}
          </ReactTable>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps, {
  getDriverReportingDetailsAPI,
})(withRouter(ReportingDetails));

//export default ReportingDetails;
