import React, { Component } from "react";
import "../driver_monitoring_attandance/index.scss";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import BackToScreen from "../../component/back_to_screen";
import { addDays } from "date-fns";

import { connect } from "react-redux";
import { withRouter } from "react-router";
import DriverMonitoringCalanderView from "../../component/driver_monitoring_calander_view";
import DrivermonitoringListview from "../../component/driver_monitoring_list_view";

import driverMonitoringviewApiActions from "../../redux/driver_monitoring_list_view/actions";

//import driverMonitoringviewApiActions from "../../redux/driver_monitoring_list_view/actions";
const { getDriverMonitoringViewAPI } = driverMonitoringviewApiActions;
const { getDriverMonitoringCalendarViewAPI } = driverMonitoringviewApiActions;
var now = new Date();
now.setDate(now.getDate());

var lastDay = new Date();
lastDay.setHours(lastDay.getHours() - 6);
var startTimestamp = new Date();
startTimestamp.setHours(0);
startTimestamp.setMinutes(0);
startTimestamp.setSeconds(0);

var endTimestamp = new Date();
endTimestamp.setHours(23);
endTimestamp.setMinutes(59);
endTimestamp.setSeconds(58);
class DriverMonitoringAttandance extends Component {
  constructor(props) {
    super(props);
    this.state = {
      date: now,
      // start_date: lastDay,
      // end_date: now,
      start_date: startTimestamp,
      end_date: endTimestamp,
      editingfields: {},
      togglevalue: false,
    };

    this.backtodriverdetailsPage = this.backtodriverdetailsPage.bind(this);
    this.toggleState = this.toggleState.bind(this);
    // this.OnSubmit = this.OnSubmit.bind(this);
  }

  toggleState() {
    this.setState({
      togglevalue: !this.state.togglevalue,
    });
  }

  handleDateChange(evt) {
    const value = evt.target.value;

    const name = evt.target.name;
    this.setState((prevState) => ({
      editingfields: {
        // object that we want to update

        ...prevState.editingfields, // keep all other key-value pairs
        [name]: moment(value).format("YYYY-MM-DD HH:mm:00"),
        // update the value of specific key
      },
    }));
    this.setState({ [name]: value });
  }

  backtodriverdetailsPage = () => {
    let tabItem = {
      tabIndex: 26,
      tabName: "Driver Details",
    };
    this.props.selectedTab(null, tabItem);
  };

  componentDidMount() {
    // let start_timestamp = moment(now).format("YYYY-MM-DD 00:00:00");
    // let end_timestamp = moment(now).format("YYYY-MM-DD 23:59:59");

   //console.log("test",sessionStorage.getItem("DriverClicked"))
    let start_timestamp = moment(this.state.start_date).format(
      "YYYY-MM-DD HH:mm:ss"
    );
    let end_timestamp = moment(this.state.end_date).format(
      "YYYY-MM-DD HH:mm:ss"
    );

    this.getDriverMonitoringListview(start_timestamp, end_timestamp);
    this.getdriverCalendarDetails(start_timestamp, end_timestamp);
  }

  onSubmit = (e) => {
    const { start_date, end_date } = this.state;
    this.setState({ loading: true });

    let start_timestamp = moment(start_date).format("YYYY-MM-DD HH:mm:ss");
    let end_timestamp = moment(end_date).format("YYYY-MM-DD HH:mm:ss");

    sessionStorage.setItem(
      "calendarStart",
      moment(start_date).format("YYYY-MM-DD")
    );
    sessionStorage.setItem(
      "calendarEnd",
      moment(end_date).format("YYYY-MM-DD")
    );
    this.getDriverMonitoringListview(start_timestamp, end_timestamp);
    this.getdriverCalendarDetails(start_timestamp, end_timestamp);
  };
  getdriverCalendarDetails = (start_timestamp, end_timestamp) => {
    let payLoad = {
      user_id: sessionStorage.getItem("userId"),
      customerId: sessionStorage.getItem("customerId"),
      id: sessionStorage.getItem("driverMonitoringIDClicked"),
      // start_timestamp: start_timestamp,
      // end_timestamp: end_timestamp,
      // id: 5,
    };
    this.props.getDriverMonitoringCalendarViewAPI(payLoad);
  };

  getDriverMonitoringListview(start_timestamp, end_timestamp) {
    this.setState({ loading: true });
    const payload = {
      user_id: sessionStorage.getItem("userId"),
      customerId: sessionStorage.getItem("customerId"),
      id: sessionStorage.getItem("driverMonitoringIDClicked"),
      start_timestamp: start_timestamp,
      end_timestamp: end_timestamp,
    };
    this.props.getDriverMonitoringViewAPI(payload);
  }

  render() {
    return (
      <div className="driver-monitoring">
        <div className="fleet-health-backward">
          <BackToScreen routingCall={this.backtodriverdetailsPage} />
          <h3 className="driver-monitoring-text">
            Attendance: {sessionStorage.getItem("DriverClicked")}
          </h3>
        </div>

        {this.state.togglevalue == true ? (
            <div className="driver-monitoring_div">
            <div className="driver-monitoring_div_labels">
              {/* <label className="driver-monitoring_div_labels_style">
                Start Date:
              </label> */}
  
              {/* <DatePicker
                autocomplete="off"
                name="start_date"
                //selected={this.state.start_date}
  
                selected={this.state.start_date}
                maxDate={moment().toDate()}
                minDate={addDays(new Date(), -90)}
                value={
                  this.state.editingfields.start_date == "undefined"
                    ? this.state.start_date.setHours(0, 0, 0)
                    : this.state.editingfields.start_date
                }
                //value={this.state.editingfields.start_date}
                onChange={(value) =>
                  this.handleDateChange({
                    target: { name: "start_date", value },
                  })
                }
                //defaultValue={moment(now).toDate()}
                timeInputLabel="Time:"
                dateFormat="yyyy-MM-dd HH:mm:00"
                showTimeInput
              /> */}
              {/* <label className="driver-monitoring_div_labels_style-1">
                End Date:
              </label> */}
              {/* <DatePicker
                autocomplete="off"
                // valueDefault={null}
                //maxDate={moment().toDate()}
                name="end_date"
                //selected={this.state.end_date}
                selected={this.state.end_date}
                maxDate={moment().toDate()}
                minDate={addDays(new Date(), -90)}
                value={
                  this.state.editingfields.end_date == "undefined"
                    ? this.state.end_date.setHours(23, 59, 59)
                    : this.state.editingfields.end_date
                }
                // value={this.state.editingfields.end_date}
                onChange={(value) =>
                  this.handleDateChange({
                    target: { name: "end_date", value },
                  })
                }
                //defaultValue="01-01-2001 01:01:01"
                timeInputLabel="Time:"
                dateFormat="yyyy-MM-dd HH:mm:00"
                showTimeInput
              /> */}
            </div>
  
            <div style={{ marginRight: "20px", marginLeft: "-140px" }}>
              {/* <button
                className="driver-monitoring_div_button-submit"
                onClick={this.onSubmit}
              >
                <i className="fa fa-check" style={{ paddingRight: "5px" }}></i>
                Submit
              </button> */}
            </div>
            <div style={{ marginRight: "20px" }}>
              {/* <button
                className="driver-monitoring_div_button-submit-1"
                onClick={this.reset}
              >
                <i className="fa fa-refresh" style={{ paddingRight: "5px" }}></i>{" "}
                Reset
              </button> */}
            </div>
  
            <div className="switch-field-one">
              <input
                type="radio"
                id="switch_left"
                name="switchToggle"
                value={this.props.leftLabel}
                onChange={this.toggleState}
                checked={!this.state.togglevalue}
              />
              <label htmlFor="switch_left">
                {" "}
                {/* <img
                      src={require("../../assets/images/summary/summary.png")}
                      className="switch-img-1"
                      alt=""
                    /> */}
                List View
              </label>
  
              <input
                type="radio"
                id="switch_right"
                name="switchToggle"
                value={this.props.rightLabel}
                onChange={this.toggleState}
                checked={this.state.togglevalue}
              />
              <label htmlFor="switch_right">
                {" "}
                {/* <img
                      src={require("../../assets/images/summary/summary.png")}
                      className="switch-img"
                      alt=""
                    /> */}
                Calendar View
              </label>
            </div>
          </div>
          ) : (
            <div className="driver-monitoring_div">
            <div className="driver-monitoring_div_labels">
              <label className="driver-monitoring_div_labels_style">
                Start Date:
              </label>
  
              <DatePicker
                autocomplete="off"
                name="start_date"
                //selected={this.state.start_date}
  
                selected={this.state.start_date}
                maxDate={moment().toDate()}
                minDate={addDays(new Date(), -90)}
                value={
                  this.state.editingfields.start_date == "undefined"
                    ? this.state.start_date.setHours(0, 0, 0)
                    : this.state.editingfields.start_date
                }
                //value={this.state.editingfields.start_date}
                onChange={(value) =>
                  this.handleDateChange({
                    target: { name: "start_date", value },
                  })
                }
                //defaultValue={moment(now).toDate()}
                timeInputLabel="Time:"
                dateFormat="yyyy-MM-dd HH:mm:00"
                showTimeInput
              />
              <label className="driver-monitoring_div_labels_style-1">
                End Date:
              </label>
              <DatePicker
                autocomplete="off"
                // valueDefault={null}
                //maxDate={moment().toDate()}
                name="end_date"
                //selected={this.state.end_date}
                selected={this.state.end_date}
                maxDate={moment().toDate()}
                minDate={addDays(new Date(), -90)}
                value={
                  this.state.editingfields.end_date == "undefined"
                    ? this.state.end_date.setHours(23, 59, 59)
                    : this.state.editingfields.end_date
                }
                // value={this.state.editingfields.end_date}
                onChange={(value) =>
                  this.handleDateChange({
                    target: { name: "end_date", value },
                  })
                }
                //defaultValue="01-01-2001 01:01:01"
                timeInputLabel="Time:"
                dateFormat="yyyy-MM-dd HH:mm:00"
                showTimeInput
              />
            </div>
  
            <div style={{ marginRight: "20px", marginLeft: "-140px" }}>
              <button
                className="driver-monitoring_div_button-submit"
                onClick={this.onSubmit}
              >
                <i className="fa fa-check" style={{ paddingRight: "5px" }}></i>
                Submit
              </button>
            </div>
            <div style={{ marginRight: "20px" }}>
              <button
                className="driver-monitoring_div_button-submit-1"
                onClick={this.reset}
              >
                <i className="fa fa-refresh" style={{ paddingRight: "5px" }}></i>{" "}
                Reset
              </button>
            </div>
  
            <div className="switch-field-one">
              <input
                type="radio"
                id="switch_left"
                name="switchToggle"
                value={this.props.leftLabel}
                onChange={this.toggleState}
                checked={!this.state.togglevalue}
              />
              <label htmlFor="switch_left">
                {" "}
                {/* <img
                      src={require("../../assets/images/summary/summary.png")}
                      className="switch-img-1"
                      alt=""
                    /> */}
                List View
              </label>
  
              <input
                type="radio"
                id="switch_right"
                name="switchToggle"
                value={this.props.rightLabel}
                onChange={this.toggleState}
                checked={this.state.togglevalue}
              />
              <label htmlFor="switch_right">
                {" "}
                {/* <img
                      src={require("../../assets/images/summary/summary.png")}
                      className="switch-img"
                      alt=""
                    /> */}
                Calendar View
              </label>
            </div>
          </div>
          )}
        

        <div className="calander-view">
          {this.state.togglevalue == true ? (
            <DriverMonitoringCalanderView />
          ) : (
            <DrivermonitoringListview />
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps, {
  getDriverMonitoringCalendarViewAPI,
  getDriverMonitoringViewAPI,
})(withRouter(DriverMonitoringAttandance));
