const fleetUptimeMangementApiActions = {

  //health_panel_view
  GET_FLEETHEALTHPANELVIEW: "GET_FLEETHEALTHPANELVIEW",
  GET_FLEETHEALTHPANELVIEW_SUCCESS: "GET_FLEETHEALTHPANELVIEW_SUCCESS",
  GET_FLEETHEALTHPANELVIEW_FAILURE: "GET_FLEETHEALTHPANELVIEW_FAILURE",

  getFleetHealthPanelViewAPI: (data) => ({
    type: fleetUptimeMangementApiActions.GET_FLEETHEALTHPANELVIEW,
    payload: { data },
  }),

  getFleetHealthPanelViewAPISuccess: (saveStatusCode, result) => ({
    type: fleetUptimeMangementApiActions.GET_FLEETHEALTHPANELVIEW_SUCCESS,
    saveStatusCode,
    result,
  }),

  getFleetHealthPanelViewAPIFailure: (saveStatusCode, message) => ({
    type: fleetUptimeMangementApiActions.GET_FLEETHEALTHPANELVIEW_FAILURE,
    saveStatusCode,
    message,
  }),

//fleet_health_view
  
GET_FLEETHEALTHVIEW: "GET_FLEETHEALTHVIEW",
GET_FLEETHEALTHVIEW_SUCCESS: "GET_FLEETHEALTHVIEW_SUCCESS",
GET_FLEETHEALTHVIEW_FAILURE: "GET_FLEETHEALTHVIEW_FAILURE",

getFleetHealthViewAPI: (data) => ({
type: fleetUptimeMangementApiActions.GET_FLEETHEALTHVIEW,
payload: { data },
}),

getFleetHealthViewAPISuccess: (saveStatusCode, result) => ({
type: fleetUptimeMangementApiActions.GET_FLEETHEALTHVIEW_SUCCESS,
saveStatusCode,
result,
}),

getFleetHealthViewAPIFailure: (saveStatusCode, message) => ({
type: fleetUptimeMangementApiActions.GET_FLEETHEALTHVIEW_FAILURE,
saveStatusCode,
message,
}),


//fleet_uptime_current_view


GET_FLEETUPTIMECURRENTVIEW: "GET_FLEETUPTIMECURRENTVIEW",
GET_FLEETUPTIMECURRENTVIEW_SUCCESS: "GET_FLEETUPTIMECURRENTVIEW_SUCCESS",
GET_FLEETUPTIMECURRENTVIEW_FAILURE: "GET_FLEETUPTIMECURRENTVIEW_FAILURE",

getFleetUptimeCurrentViewAPI: (data) => ({
  type: fleetUptimeMangementApiActions.GET_FLEETUPTIMECURRENTVIEW,
  payload: { data },
}),

getFleetUptimeCurrentViewAPISuccess: (saveStatusCode, result) => ({
  type: fleetUptimeMangementApiActions.GET_FLEETUPTIMECURRENTVIEW_SUCCESS,
  saveStatusCode,
  result,
}),

getFleetUptimeCurrentViewAPIFailure: (saveStatusCode, message) => ({
  type: fleetUptimeMangementApiActions.GET_FLEETUPTIMECURRENTVIEW_FAILURE,
  saveStatusCode,
  message,
}),

//fleethalth details
GET_FLEETHEALTHDETAILS: "GET_FLEETHEALTHDETAILS",
GET_FLEETHEALTHDETAILS_SUCCESS: "GET_FLEETHEALTHDETAILS_SUCCESS",
GET_FLEETHEALTHDETAILS_FAILURE: "GET_FLEETHEALTHDETAILS_FAILURE",

getFleetHealthDetailsAPI: (data) => ({
type: fleetUptimeMangementApiActions.GET_FLEETHEALTHDETAILS,
payload: { data },
}),

getFleetHealthDetailsAPISuccess: (saveStatusCode, result) => ({
type: fleetUptimeMangementApiActions.GET_FLEETHEALTHDETAILS_SUCCESS,
saveStatusCode,
result,
}),

getFleetHealthDetailsAPIFailure: (saveStatusCode, message) => ({
type: fleetUptimeMangementApiActions.GET_FLEETHEALTHDETAILS_FAILURE,
saveStatusCode,
message,
}),



//fleetuptime historic
GET_FLEETUPTIMEHISTORIC: "GET_FLEETUPTIMEHISTORIC",
GET_FLEETUPTIMEHISTORIC_SUCCESS: "GET_FLEETUPTIMEHISTORIC_SUCCESS",
GET_FLEETUPTIMEHISTORIC_FAILURE: "GET_FLEETUPTIMEHISTORIC_FAILURE",

getFleetUptimeHistoricAPI: (data) => ({
type: fleetUptimeMangementApiActions.GET_FLEETUPTIMEHISTORIC,
payload: { data },
}),

getFleetUptimeHistoricAPISuccess: (saveStatusCode, result) => ({
type: fleetUptimeMangementApiActions.GET_FLEETUPTIMEHISTORIC_SUCCESS,
saveStatusCode,
result,
}),

getFleetUptimeHistoricAPIFailure: (saveStatusCode, message) => ({
type: fleetUptimeMangementApiActions.GET_FLEETUPTIMEHISTORIC_FAILURE,
saveStatusCode,
message,
}),

//fleetuptime historic below target
GET_FLEETUPTIMEHISTORICBELOW: "GET_FLEETUPTIMEHISTORICBELOW",
GET_FLEETUPTIMEHISTORICBELOW_SUCCESS: "GET_FLEETUPTIMEHISTORICBELOW_SUCCESS",
GET_FLEETUPTIMEHISTORICBELOW_FAILURE: "GET_FLEETUPTIMEHISTORICBELOW_FAILURE",

getFleetUptimeHistoricBelowAPI: (data) => ({
type: fleetUptimeMangementApiActions.GET_FLEETUPTIMEHISTORICBELOW,
payload: { data },
}),

getFleetUptimeHistoricBelowAPISuccess: (saveStatusCode, result) => ({
type: fleetUptimeMangementApiActions.GET_FLEETUPTIMEHISTORICBELOW_SUCCESS,
saveStatusCode,
result,
}),

getFleetUptimeHistoricBelowAPIFailure: (saveStatusCode, message) => ({
type: fleetUptimeMangementApiActions.GET_FLEETUPTIMEHISTORICBELOW_FAILURE,
saveStatusCode,
message,
}),


//fleetuptime historic GRAPH
GET_FLEETUPTIMEHISTORIC_GRAPH: "GET_FLEETUPTIMEHISTORIC_GRAPH",
GET_FLEETUPTIMEHISTORIC_GRAPH_SUCCESS: "GET_FLEETUPTIMEHISTORIC_GRAPH_SUCCESS",
GET_FLEETUPTIMEHISTORIC_GRAPH_FAILURE: "GET_FLEETUPTIMEHISTORIC_GRAPH_FAILURE",

getFleetUptimeHistoricgraphAPI: (data) => ({
type: fleetUptimeMangementApiActions.GET_FLEETUPTIMEHISTORIC_GRAPH,
payload: { data },
}),

getFleetUptimeHistoricgraphAPISuccess: (saveStatusCode, result) => ({
type: fleetUptimeMangementApiActions.GET_FLEETUPTIMEHISTORIC_GRAPH_SUCCESS,
saveStatusCode,
result,
}),

getFleetUptimeHistoricgraphAPIFailure: (saveStatusCode, message) => ({
type: fleetUptimeMangementApiActions.GET_FLEETUPTIMEHISTORIC_GRAPH_FAILURE,
saveStatusCode,
message,
}),

};

export default fleetUptimeMangementApiActions;
