import React, { Component } from "react";
import ReactTable from "react-table";
import "react-table/react-table.css";
import MasterDriverButton from "../master_driver_button";
import "../master_driver_update/index.scss";
//import * as User from "shared/app-data/user";
import Workbook from "react-excel-workbook";
import ReactTooltip from "react-tooltip";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import ReactLoader from "../../component/react-loader/react-loader.component";
// //Actions
import driverMasterApiActions from "../../redux/master_driver/actions";
const {
  getDriverMasterAPI,
  UPDATE_DRIVERMASTERDETAILS_SUCCESS,
  UPDATE_DRIVERMASTERDETAILS_FAILURE,
  DELETE_DRIVERMASTERDETAILS_SUCCESS,
  DELETE_DRIVERMASTERDETAILS_FAILURE,
} = driverMasterApiActions;
class MasterDriverUpdate extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // loginId,
      // data1,
      data: [],
      pages: null,
      loading: true,
      message: "",
      messageType: 0,
      dataSet: [],
      selected: null,
      vehicleList: [],
      driverList: [],
      filteredData: [],
      searchInput: "",
    };
  }

  componentDidMount() {
    //getting all the data
    this.getDriverMaster();
  }

  getDriverMaster() {
    this.setState({ loading: true });
    const payload = {
      // user_id: "1",
      user_id: sessionStorage.getItem("userId"),
      customerId: sessionStorage.getItem("customerId"),
    };
    this.props.getDriverMasterAPI(payload);
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      if (
        this.props.DriverMasterApi?.driverMasterApi?.result?.data?.responseData
          ?.length > 0
      ) {
        this.setState({
          dataSet:
            this.props.DriverMasterApi.driverMasterApi.result.data.responseData,
        });
        this.setState({
          filteredData:
            this.props.DriverMasterApi.driverMasterApi.result.data.responseData,
        });
      } else {
        this.setState({ dataSet: [] });
      }

      //update
      if (
        prevProps.DriverMasterApi.updateDriverMasterApi !=
        this.props.DriverMasterApi.updateDriverMasterApi
      ) {
        if (
          this.props.DriverMasterApi.updateDriverMasterApi.actionType ===
            UPDATE_DRIVERMASTERDETAILS_SUCCESS &&
          this.props.DriverMasterApi.updateDriverMasterApi.loading === false
        ) {
          this.setState({
            message: "Driver has been updated successfully",
            messageType: 1,
          });
          this.messageTimeOut = setTimeout(
            () => this.onClickCloseMessage(),
            3 * 1000
          );
          //this.getVehicleMaster();
        } else if (
          this.props.DriverMasterApi.updateDriverMasterApi.actionType ===
            UPDATE_DRIVERMASTERDETAILS_FAILURE &&
          this.props.DriverMasterApi.updateDriverMasterApi.loading === false
        ) {
          this.setState({
            message: "Driver can not be updated",
            messageType: -1,
          });
          this.messageTimeOut = setTimeout(
            () => this.onClickCloseMessage(),
            3 * 1000
          );
        }
      }

      //delete
      if (
        prevProps.DriverMasterApi.deleteDriverMasterApi !=
        this.props.DriverMasterApi.deleteDriverMasterApi
      ) {
        if (
          this.props.DriverMasterApi.deleteDriverMasterApi.actionType ===
            DELETE_DRIVERMASTERDETAILS_SUCCESS &&
          this.props.DriverMasterApi.deleteDriverMasterApi.loading === false
        ) {
          this.setState({
            message: "Driver has been deleted successfully",
            messageType: 1,
          });
          this.messageTimeOut = setTimeout(
            () => this.onClickCloseMessage(),
            3 * 1000
          );
          // this.getVehicleMaster();
        } else if (
          this.props.DriverMasterApi.deleteDriverMasterApi.actionType ===
            DELETE_DRIVERMASTERDETAILS_FAILURE &&
          this.props.DriverMasterApi.deleteDriverMasterApi.loading === false
        ) {
          this.setState({
            message: "Driver can not be deleted",
            messageType: -1,
          });
          this.messageTimeOut = setTimeout(
            () => this.onClickCloseMessage(),
            3 * 1000
          );
        }
      }
    }
  }
  onClickCloseMessage() {
    this.messageTimeOut = null;
    this.setState({
      message: "",
      messageType: 0,
    });
  }
  handleDrivermaster = (event) => {
    const search = event.target.value;
    this.setState({
      searchInput: search,
      filteredData: this.state.dataSet.filter(
        (item) =>
          (item.driverName && item.driverName.includes(search)) ||
          (item.driverId && item.driverId.toLowerCase().includes(search)) ||
          (item.phoneNumber && item.phoneNumber.toLowerCase().includes(search))
      ),
    });
  };

  render() {
    let { searchInput } = this.state;
    return (
      <div>
        {this.state.message && (
          <div
            className={`alert ${
              this.state.messageType === 1
                ? "success"
                : this.state.messageType === -1
                ? "error"
                : ""
            }`}
          >
            <span
              className="closebtn"
              onClick={() => this.onClickCloseMessage()}
            >
              &times;
            </span>
            <strong>{this.state.message}</strong>
          </div>
        )}
        {this.props.DriverMasterApi?.driverMasterApi?.loading ? (
          <div className="loader-overlay">
            <div className="loader-container">
              <ReactLoader />
            </div>
          </div>
        ) : null}
        <div className="driver-update-subhead">
          {/* <h3 className="driver-update">Driver View</h3> */}

          {/* <div className="table-items-count">
            Items: {this.state.filteredData.length}
          </div> */}
          <div className="search-driver-update-report ">
            <form
              onSubmit={(e) => {
                e.preventDefault();
              }}
            >
              <input
                style={{ width: "400px" }}
                className="search-driver-update-input"
                type="text"
                id="filter"
                placeholder="Search Table ( Driver ID / Driver Name / Phone Number  )"
                value={searchInput || ""}
                onChange={this.handleDrivermaster}
              />
            </form>

            <div>
              <Workbook
                filename="driver-master-Report.xlsx"
                element={
                  <div
                    className="driver-master-Report-download"
                    data-tip
                    data-for="registerTip"
                  >
                    Export
                    <img
                      src={require("../../assets/images/export/export.png")}
                      alt=""
                      className="driver-master-Report-download-img"
                    />
                    <ReactTooltip id="registerTip" place="top" effect="solid">
                      Download
                    </ReactTooltip>
                  </div>
                }
              >
                <Workbook.Sheet
                  data={this.state.dataSet}
                  name="driver-master-Report"
                >
                  <Workbook.Column label="ID" value="id" />
                  <Workbook.Column label="Driver Name" value="driverName" />
                  <Workbook.Column label="Driver ID" value="driverId" />
                  <Workbook.Column label="Phone Number" value="phoneNumber" />

                  <Workbook.Column label="Date Of Birth" value="dob" />

                  <Workbook.Column
                    label="Anniversary Date"
                    value="anniversary"
                  />
                  <Workbook.Column label="Duty" value="duty" />
                </Workbook.Sheet>
              </Workbook>
            </div>
          </div>
        </div>
        <div style={{ marginTop: "-20px" }}>
          {/* <h2 className="msg">
            Note: Future trips will be reflected in Excel Sheet
          </h2> */}
          <ReactTooltip place="left" type="success" effect="solid" />
          <ReactTable
            NoDataComponent={() => null}
            pageSizeOptions={[20, 30, 50, 80, 100, 130, 200, 500]}
            style={{
              height: "440px",
              marginTop: "0%",
              // width: "1000px",
            }}
            data={this.state.filteredData}
            showPagination={true}
            columns={[
              {
                Header: "Edit",
                width: 90,
                Cell: (props) => (
                  <MasterDriverButton
                    data={props}
                    // driverDropdown={this.state.driverList}
                    // vehicleDropdown={this.state.vehicleList}
                  ></MasterDriverButton>
                ),
                resizable: false,
              },
              {
                Header: "S.No",
                id: "serialNumber",
                resizable: false,
                filterable: false,
                width: 90,
                Cell: (row) => {
                  return <div>{row.index + 1}</div>;
                },
              },

              // {
              //   Header: "ID",
              //   id: "id",
              //   accessor: (d) => d.id,
              //   width: 180,

              //   filterAll: true,
              //   resizable: false,
              // },
              {
                Header: "Driver Name",
                id: "driverName",
                Cell: (rows) => {
                  /* Add data-tip */
                  return <span title={rows.value}>{rows.value}</span>;
                },
                accessor: (d) => d.driverName,
                width: 250,

                filterAll: true,
                resizable: false,
              },

              {
                Header: "Driver ID",
                id: "driverId",
                accessor: (d) => d.driverId,
                width: 230,

                filterAll: true,
                resizable: false,
              },

              {
                Header: "Phone Number",
                id: "phoneNumber",
                accessor: (d) => d.phoneNumber,
                width: 260,

                filterAll: true,
                resizable: false,
              },
              {
                Header: "Date of Birth",
                id: "dob",
                accessor: (d) => d.dob,
                width: 240,

                filterAll: true,
                resizable: false,
              },
              {
                Header: "Anniversary Date",
                id: "anniversary",
                accessor: (d) => d.anniversary,
                width: 210,

                filterAll: true,
                resizable: false,
              },

              {
                Header: "Duty",
                id: "duty",
                accessor: (d) => d.duty,
                width: 55,

                filterAll: true,
                resizable: false,
              },
            ]}
            defaultSorted={[
              {
                id: "serialNumber",
                asc: true,
              },
            ]}
            defaultPageSize={50}
            className="-striped -highlight"
          >
            {(state, makeTable, instance) => {
              let recordsInfoText = "";

              const { filtered, pageRows, pageSize, sortedData, page } = state;

              if (sortedData && sortedData.length > 0) {
                let isFiltered = filtered.length > 0;

                let totalRecords = sortedData.length;

                let recordsCountFrom = page * pageSize + 1;

                let recordsCountTo = recordsCountFrom + pageRows.length - 1;

                if (isFiltered)
                  recordsInfoText = `${recordsCountFrom}-${recordsCountTo} of ${totalRecords} filtered records`;
                else
                  recordsInfoText = `${recordsCountFrom}-${recordsCountTo} of ${totalRecords} records`;
              } else recordsInfoText = "No records";

              return (
                <div className="main-grid">
                  <div className="above-table text-right">
                    <div className="col-sm-12">
                      <span className="records-info">{recordsInfoText}</span>
                    </div>
                  </div>

                  {makeTable()}
                </div>
              );
            }}
          </ReactTable>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps, {
  getDriverMasterAPI,
})(withRouter(MasterDriverUpdate));
