import React, { Component } from "react";
import "./index.scss";
import ReactLoader from "../../component/react-loader/react-loader.component";
import { Line } from "react-chartjs-2";
import { Doughnut } from "react-chartjs-2";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import moment from "moment";
import Lottie from "lottie-react";
import DataAnimation from "../../assets/lotties/green_tick.json";
import DataAnimations from "../../assets/lotties/red_warning.json";
import KpiContractualPopup from "../kpi_contractual_popup";
import KpiContractualAccidentSummaryPopup from "../kpi_contractual_accident_summary"
import KpiContractualScheduleAdhPopup from "../kpi_contractual_schedule_adh_popup";
//import DataAnimations from "../../assets/lotties/red_warning.json";
import KpiContractualReliabilityPopup from "../kpi_contractual_reliability_popup";
import contractalKPIApiActions from "../../redux/contractual_kpi/actions";
import contractalKPIAccidentApiActions from "../../redux/contractual_kpi_accident_report/actions";
import KpiContractualBreakdownSummaryPopup from "../kpi_contractual_breakdown_summary_popup";
import KpiContractualArrivalWeeklyPopup from "../kpi_contractual_arrival_weekly_popup";
import KpiContractualStartWeeklyPopup from "../kpi_contractual_start_weekly_popup";
import contractualPunctualityGraphApiActions from "../../redux/contractual_punctuality_graph/actions"
const { getContractualStartPunctualGraphAPI, getContractualArrivalPunctualGraphAPI } = contractualPunctualityGraphApiActions;
const {
  getContractualDetailsAPI,
  getContractualAssuredavailabilityAPI,
  getContractualScheduleAdherenceAPI,
  getContractualReliabilityAPI,
  getContractualBreakdownsummaryViewAPI,

   getStartPunctualityWeeklyDataAPI,
   getArrivalPunctualityWeeklyDataAPI,
  
} = contractalKPIApiActions;
const {
  getContractualAccidentsummaryViewAPI
}=contractalKPIAccidentApiActions;

var startTimestamp = new Date();
startTimestamp.setHours(0);
startTimestamp.setMinutes(0);
startTimestamp.setSeconds(0);

var startTimestampWeekly = new Date();
startTimestampWeekly.setDate(startTimestampWeekly.getDate() - 7)
startTimestampWeekly.setHours(0);
startTimestampWeekly.setMinutes(0);
startTimestampWeekly.setSeconds(0);

var endTimestamp = new Date();
endTimestamp.setHours(23);
endTimestamp.setMinutes(59);
endTimestamp.setSeconds(58);
class KPIcontractual extends Component {
  constructor(props) {
    super(props);
    //Initial data from API
    //let loginId = User.getLoginId();
    this.state = {
      switchValue: false,
      togglevalues: false,
      togglevalueArrival: false,
      data: [],
      AssuredFleetAvailability: "",
      MaintenanceAdherence: "",
      NoofBreakdownEvents: "",
      NoofAccidentEvents:"",
      TripLostDueToAccidents:"",
      ReliabilityFactor: "",
      ScheduleAdherence: "",
      TripLostDueToBreakdown: "",
      showModal: false,
      showPopup: false,
      showPopups: false,
      showPopupbreak: false,
      showPopupaccident: false,
      showPopupreliability: false,
      showPopupArrival:false,
      showPopupStart:false,
      addModalshowStart:false,
      addModalshowArrival:false,
      cardClicked: "",
      addModalshow: false,
      addModalshowpopup: false,
      addModalshowreliability: false,
      addModalshowbreak: false,
      addModalshowaccident: false,
      popupData: [],
      breakdownSummaryPopup: [],
      accidentSummaryPopup:[],
      scheduleAdherencePopup: [],
      realibilityPopup: [],
      assuredAvailabilitylistpopupData: [],
      start_date: startTimestamp,
      end_date: endTimestamp,
      start_date_weekly: startTimestampWeekly,
      MonText: "",
      MonValue: "",
      TuesText: "",
      TuesValue: "",
      WenText: "",
      WenValue: "",
      ThurText: "",
      ThurValue: "",
      FriText: "",
      FriValue: "",
      SatText: "",
      SatValue: "",
      SunText: "",
      SunValue: "",
      start_punctuality_graph_date:[],
      start_punctuality_graph_value:[],
      arrival_punctuality_graph_date:[],
      arrival_punctuality_graph_value:[],
      StartWeeklyPopup:[],
      ArrivalWeeklyPopup:[],
      currentMonth: ''


    };
    this.toggleStatevalue = this.toggleStatevalue.bind(this);
    this.toggleStatevalueArrival = this.toggleStatevalueArrival.bind(this);
    this.onKpiRealiability = this.onKpiRealiability.bind(this);
    this.onKPIcontractualBreakdownsummary =
      this.onKPIcontractualBreakdownsummary.bind(this);
    this.onKPIContractualSubmission =
      this.onKPIContractualSubmission.bind(this);
    this.onKPIcontractualScheduleAdherence =
      this.onKPIcontractualScheduleAdherence.bind(this);

      this.onKPIcontractualStartWeekly =
      this.onKPIcontractualStartWeekly.bind(this);

      this.onKPIcontractualArrivalWeeklyData =
      this.onKPIcontractualArrivalWeeklyData.bind(this);
      this.onKPIcontractualAccidentsummary=this.onKPIcontractualAccidentsummary.bind(this)
  }

  onKPIContractualSubmission() {
    this.getContractualAssuredavailabilitylistDetails();
    this.setState({ addModalshowpopup: true });
    this.setState({ showPopups: true });
  }
  onKpiRealiability() {
    this.getContractualreliabilitylistDetails();
    this.setState({ addModalshowreliability: true });
    this.setState({ showPopupreliability: true });
  }

  onKPIcontractualScheduleAdherence() {
    this.getContractualScheduleAdherenceDetails();
    this.setState({ addModalshow: true });
    this.setState({ showPopup: true });
  }

  onKPIcontractualBreakdownsummary() {
    this.getContractualbreakdownSummaryDetails();
    this.setState({ addModalshowbreak: true });
    this.setState({ showPopupbreak: true });
  }

  onKPIcontractualAccidentsummary() {
    this.getContractualAccidentSummaryDetails()
    this.setState({ addModalshowaccident: true });
    this.setState({ showPopupaccident: true });
   
  }
  onKPIcontractualStartWeekly() {
    this.getcontractualStartWeeklyData();
    this.setState({ addModalshowStart: true });
    this.setState({ showPopupStart: true });
  }


  onKPIcontractualArrivalWeeklyData() {
    this.getcontractualArrivalWeeklyData();
    this.setState({ addModalshowArrival: true });
    this.setState({ showPopupArrival: true });
  }

  getcontractualStartWeeklyGraphData(){
    const { start_date, end_date, start_date_weekly } = this.state;
    this.setState({ loading: true });
    const payload = {
      user_id: sessionStorage.getItem("userId"),
      customerId: sessionStorage.getItem("customerId"),
      start_timestamp: moment(start_date_weekly).format("YYYY-MM-DD HH:mm:ss"),
      end_timestamp: moment(end_date).format("YYYY-MM-DD HH:mm:ss"),
    }
    this.props.getContractualStartPunctualGraphAPI(payload);
  }

  getcontractualArrivalWeeklyGraphData(){
    const { start_date, end_date, start_date_weekly } = this.state;
    this.setState({ loading: true });
    const payload = {
      user_id: sessionStorage.getItem("userId"),
      customerId: sessionStorage.getItem("customerId"),
      start_timestamp: moment(start_date_weekly).format("YYYY-MM-DD HH:mm:ss"),
      end_timestamp: moment(end_date).format("YYYY-MM-DD HH:mm:ss"),
    }
    this.props.getContractualArrivalPunctualGraphAPI(payload);
  }



  

  addModalClose = () => this.setState({ addModalshow: false });
  addModalCloses = () => this.setState({ addModalshowpopup: false });
  addModalClosereliability = () =>
    this.setState({ addModalshowreliability: false });
  addModalCloseBreak = () => this.setState({ addModalshowbreak: false });
  addModalCloseArrival = () => this.setState({ addModalshowArrival: false });
  addModalCloseStart = () => this.setState({ addModalshowStart: false });

  addModalCloseAccident = () => this.setState({ addModalshowaccident: false });

  toggleStatevalue() {
    this.setState({
      togglevalues: !this.state.togglevalues,
    });
  }

  toggleStatevalueArrival() {
    this.setState({
      togglevalueArrival: !this.state.togglevalueArrival,
    });
  }

  componentDidMount() {
    this.getContractualKpidetails();
    //list view
    this.getContractualAssuredavailabilitylistDetails();
    this.getContractualScheduleAdhDetails();
    this.getContractualreliabilitylistDetails();
    this.getContractualbreakdownSummaryDetails()
    this.getcontractualStartWeeklyData();
    this.getcontractualArrivalWeeklyData();
    this.getcontractualStartWeeklyGraphData();
    this.getcontractualArrivalWeeklyGraphData();
   this.getContractualAccidentSummaryDetails()



   const currentDate = new Date();
   const currentMonth = currentDate.toLocaleString('default', { month: 'long' });
   this.setState({ currentMonth });
  }


  getContractualbreakdownSummaryDetails() {
    const { start_date, end_date } = this.state;
    this.setState({ loading: true });
    const payload = {
      user_id: sessionStorage.getItem("userId"),
      customerId: sessionStorage.getItem("customerId"),
      accident_breakdown_type :"CONTRACTUAL_BREAKDOWN_SUMMARY",
      start_timestamp: moment(start_date).format("YYYY-MM-DD HH:mm:ss"),
      end_timestamp: moment(end_date).format("YYYY-MM-DD HH:mm:ss"),
    };
    this.props.getContractualBreakdownsummaryViewAPI(payload);
  }

  getContractualAccidentSummaryDetails() {
    const { start_date, end_date } = this.state;
    this.setState({ loading: true });
    const payload = {
      user_id: sessionStorage.getItem("userId"),
      customerId: sessionStorage.getItem("customerId"),
      accident_breakdown_type :"CONTRACTUAL_ACCIDENT_SUMMARY",
      start_timestamp: moment(start_date).format("YYYY-MM-DD HH:mm:ss"),
      end_timestamp: moment(end_date).format("YYYY-MM-DD HH:mm:ss"),
    };
    this.props.getContractualAccidentsummaryViewAPI(payload);
  }
  getContractualreliabilitylistDetails() {
    const { start_date, end_date } = this.state;
    this.setState({ loading: true });
    const payload = {
      user_id: sessionStorage.getItem("userId"),
      customerId: sessionStorage.getItem("customerId"),
      start_timestamp: moment(start_date).format("YYYY-MM-DD HH:mm:ss"),
      end_timestamp: moment(end_date).format("YYYY-MM-DD HH:mm:ss"),
    };
    this.props.getContractualReliabilityAPI(payload);
  }

  getContractualScheduleAdherenceDetails() {
    this.setState({ loading: true });
    const payload = {
      user_id: sessionStorage.getItem("userId"),
      customerId: sessionStorage.getItem("customerId"),
      // start_timestamp: moment(start_date).format("YYYY-MM-DD 00:00:00"),
      // end_timestamp: moment(end_date).format("YYYY-MM-DD 23:59:59"),
    };
    this.props.getContractualDetailsAPI(payload);
  }
  getcontractualStartWeeklyData(){
    const { start_date, end_date } = this.state;
    this.setState({ loading: true });
    const payload = {
      user_id: sessionStorage.getItem("userId"),
      customerId: sessionStorage.getItem("customerId"),
      start_timestamp: moment(start_date).format("YYYY-MM-DD HH:mm:ss"),
      end_timestamp: moment(end_date).format("YYYY-MM-DD HH:mm:ss"),
    }
    this.props.getStartPunctualityWeeklyDataAPI(payload);
  }

  

  getcontractualArrivalWeeklyData(){
    const { start_date, end_date } = this.state;
    this.setState({ loading: true });
    const payload = {
      user_id: sessionStorage.getItem("userId"),
      customerId: sessionStorage.getItem("customerId"),
      start_timestamp: moment(start_date).format("YYYY-MM-DD HH:mm:ss"),
      end_timestamp: moment(end_date).format("YYYY-MM-DD HH:mm:ss"),
    }
    this.props.getArrivalPunctualityWeeklyDataAPI(payload);
  }
  getContractualKpidetails() {
    //const { start_date, end_date } = this.state;
    this.setState({ loading: true });
    const payload = {
      user_id: sessionStorage.getItem("userId"),
      customerId: sessionStorage.getItem("customerId"),
      // start_timestamp: moment(start_date).format("YYYY-MM-DD 00:00:00"),
      // end_timestamp: moment(end_date).format("YYYY-MM-DD 23:59:59"),
    };
    this.props.getContractualDetailsAPI(payload);
  }

  getContractualScheduleAdhDetails() {
    const { start_date, end_date } = this.state;
    this.setState({ loading: true });
    const payload = {
      //id: "1",
      user_id: sessionStorage.getItem("userId"),
      customerId: sessionStorage.getItem("customerId"),
      start_timestamp: moment(start_date).format("YYYY-MM-DD HH:mm:ss"),
      end_timestamp: moment(end_date).format("YYYY-MM-DD HH:mm:ss"),
    };
    this.props.getContractualScheduleAdherenceAPI(payload);
  }

  getContractualAssuredavailabilitylistDetails() {
    const { start_date, end_date } = this.state;
    this.setState({ loading: true });
    const payload = {
      user_id: sessionStorage.getItem("userId"),
      customerId: sessionStorage.getItem("customerId"),
      start_timestamp: moment(start_date).format("YYYY-MM-DD HH:mm:ss"),
      end_timestamp: moment(end_date).format("YYYY-MM-DD HH:mm:ss"),
    };
    this.props.getContractualAssuredavailabilityAPI(payload);
  }

  componentWillReceiveProps(nextProps) {
  
    if (this.props !== nextProps) {
     // console.log("accidentsummar",nextProps)
      if (
        nextProps?.ContractalKpiApi?.contractualDetailsApi?.result?.data
          ?.responseData
      ) {
        this.setState({
          AssuredFleetAvailability:
            nextProps?.ContractalKpiApi?.contractualDetailsApi?.result?.data
              ?.responseData.assured_fleet_availability,
        });
        this.setState({
          MaintenanceAdherence:
            nextProps?.ContractalKpiApi?.contractualDetailsApi?.result?.data
              ?.responseData.maintenance_adherence,
        });
        this.setState({
          NoofBreakdownEvents:
            nextProps?.ContractalKpiApi?.contractualDetailsApi?.result?.data
              ?.responseData.no_of_breakdown_events,
        });

        this.setState({
          NoofAccidentEvents:
            nextProps?.ContractalKpiApi?.contractualDetailsApi?.result?.data
              ?.responseData.no_of_accident_events,
        });

        this.setState({
          TripLostDueToAccidents:
            nextProps?.ContractalKpiApi?.contractualDetailsApi?.result?.data
              ?.responseData.trips_lost_due_to_accident,
        });

        this.setState({
          ReliabilityFactor:
            nextProps?.ContractalKpiApi?.contractualDetailsApi?.result?.data
              ?.responseData.reliability_factor,
        });

        this.setState({
          ScheduleAdherence:
            nextProps?.ContractalKpiApi?.contractualDetailsApi?.result?.data
              ?.responseData.schedule_adherence,
        });

        this.setState({
          TripLostDueToBreakdown:
            nextProps?.ContractalKpiApi?.contractualDetailsApi?.result?.data
              ?.responseData.trips_lost_due_to_breakdown,
        });
      }
      if (
        nextProps?.ContractalKpiApi?.contractualAssuredAvailabilityApi?.result
          ?.data?.responseData
      ) {
        this.setState({
          assuredAvailabilitylistpopupData:
            nextProps?.ContractalKpiApi?.contractualAssuredAvailabilityApi
              ?.result?.data?.responseData,
        });

        this.setState({
          popupData:
            nextProps.ContractalKpiApi.contractualAssuredAvailabilityApi.result
              .data.responseData,
        });

        // schedule adherence
        if (
          nextProps?.ContractalKpiApi?.contractualScheduleAdherenceApi?.result
            ?.data?.responseData
        ) {
          this.setState({
            scheduleAdherencePopup:
              nextProps.ContractalKpiApi.contractualScheduleAdherenceApi.result
                .data.responseData,
          });
        }

        //reliability popup

        if (
          nextProps?.ContractalKpiApi?.contractualReliabilityApi?.result?.data
            ?.responseData
        ) {
          this.setState({
            realibilityPopup:
              nextProps.ContractalKpiApi.contractualReliabilityApi.result.data
                .responseData,
          });
        }

        //breakdownsummary list view

        if (
          nextProps?.ContractalKpiApi?.contractualBreakdownSummmaryApi?.result
            ?.data?.responseData
        ) {
          this.setState({
            breakdownSummaryPopup:
              nextProps.ContractalKpiApi.contractualBreakdownSummmaryApi.result
                .data.responseData,
          });
        }

   



      // accidentSummaryPopup

      if (
        nextProps?.ContractualAccidentSummaryApi
        ?.contractualAccidentSummmaryApi?.result
          ?.data?.responseData
      ) {
        this.setState({
          accidentSummaryPopup:
            nextProps.ContractualAccidentSummaryApi
            .contractualAccidentSummmaryApi.result
              .data.responseData,
        });
      }




        //  //START PUNCTUALITY  weekly data

        if (
          nextProps?.ContractalKpiApi?.contractualstartPunctualityWeeklyApi?.result
            ?.data?.responseData
        ) {
          this.setState({
            StartWeeklyPopup:
              nextProps.ContractalKpiApi.contractualstartPunctualityWeeklyApi.result
                .data.responseData,
          });
        }
      

        //arrival punctualty weekly data
        if (
          nextProps?.ContractalKpiApi?.contractualarrivalPunctualityWeeklyApi?.result
            ?.data?.responseData
        ) {
          this.setState({
            ArrivalWeeklyPopup:
              nextProps.ContractalKpiApi.contractualarrivalPunctualityWeeklyApi.result
                .data.responseData,
          });
        }

        // start punctuality graph data
        
        if(nextProps?.ContractualPunctualityGraphApi?.contractualStartPunctualityGraphApi?.result
          ?.data?.responseData){
          this.setState({
            start_punctuality_graph_date:
              nextProps.ContractualPunctualityGraphApi.contractualStartPunctualityGraphApi.result
                .data.responseData.created_date,
          });
          this.setState({
            start_punctuality_graph_value:
              nextProps.ContractualPunctualityGraphApi.contractualStartPunctualityGraphApi.result
                .data.responseData.start_Punctuality,
          });
        }
          //arrival_punctuality_graph_date
      //    console.log("issue-1",nextProps)
          // console.log("issue",nextProps.ContractualPunctualityGraphApi.contractualArrivalPunctualityGraphApi.result
          // .data.responseData.created_date)
          // console.log("issue1",nextProps.ContractualPunctualityGraphApi.contractualArrivalPunctualityGraphApi.result)
          if(nextProps?.ContractualPunctualityGraphApi?.contractualArrivalPunctualityGraphApi?.result
            ?.data?.responseData){
          this.setState({
            arrival_punctuality_graph_date:
              nextProps.ContractualPunctualityGraphApi.contractualArrivalPunctualityGraphApi.result
                .data.responseData.created_date,
          });
          this.setState({
            arrival_punctuality_graph_value:
              nextProps.ContractualPunctualityGraphApi.contractualArrivalPunctualityGraphApi.result
                .data.responseData.arrival_Punctuality,
          });
        }
        
      
        
        
      }
    }
  }
  render() {
    let { AssuredFleetAvailability, MaintenanceAdherence, ScheduleAdherence } =
      this.state;

    // ASSURED FLEET AVAILABILITy

    let fleetAvailablityColor = "";
    if (AssuredFleetAvailability > 95) {
      fleetAvailablityColor = "#7bc84e";
    } else if (AssuredFleetAvailability > 70 && AssuredFleetAvailability < 95) {
      fleetAvailablityColor = "#F19E38";
    } else {
      fleetAvailablityColor = "#D8231D";
    }

    let ScheduleadherenceColor = "";
    if (ScheduleAdherence > 95) {
      ScheduleadherenceColor = "#7bc84e";
    } else if (ScheduleAdherence > 70 && ScheduleAdherence < 95) {
      ScheduleadherenceColor = "#F19E38";
    } else {
      ScheduleadherenceColor = "#D8231D";
    }

    let MaintenanceAdherenceColor = "";
    if (MaintenanceAdherence > 95) {
      MaintenanceAdherenceColor = "#7bc84e";
    } else if (MaintenanceAdherence > 70 && MaintenanceAdherence < 95) {
      MaintenanceAdherenceColor = "#F19E38";
    } else {
      MaintenanceAdherenceColor = "#D8231D";
    }

    var fleetavailabilitydataset = {
      datasets: [
        {
          data: [AssuredFleetAvailability, 100 - AssuredFleetAvailability],
          backgroundColor: [fleetAvailablityColor],
          display: true,
          borderColor: "#D1D6DC",
        },
      ],
    };

    //Scheduleadherence

    var Scheduleadherencedataset = {
      datasets: [
        {
          data: [ScheduleAdherence, 100 - ScheduleAdherence],
          backgroundColor: [ScheduleadherenceColor],
          display: true,
          borderColor: "#D1D6DC",
          //borderWidth: 8,
          //cutoutPercentage:80
        },
      ],
    };

    //Maintenancedherence

    var Maintenanceadherencedataset = {
      datasets: [
        {
          data: [MaintenanceAdherence, 100 - MaintenanceAdherence],
          backgroundColor: ["#D8231D"],
          display: true,
          borderColor: "#D1D6DC",
          boredrAlign: "center",
          //cutoutPercentage: 90,
          //borderWidth:
        },
      ],
    };

    //weeklylinedata ----------START  PUNCTUALITY

    const weeklylinedatastart = {
     // labels: ["Mon", "Tues", "Wen", "Thu", "Fri", "Sat", "Sun"],
       labels: this.state.start_punctuality_graph_date,
      datasets: [
        {
          label: "Start",
         // data: [0, 0, 0, 0, 0, 0, 0],
         data: this.state.start_punctuality_graph_value,
          fill: false,
          backgroundColor: "rgba(75,192,192,0.2)",
          borderColor: "#4B85F7",
        },
        // {
        //   label: "Arrival",
        //   data: [33, 55, 65, 71, 74, 76, 81],
        //   fill: false,
        //   borderColor: "#6BB13F",
        // },
      ],
    };

    const lineOptionss = {
      legend: {
        display: false,
      },

      //   onClick: (e, element) => {
      //     if (element.length > 0) {
      //       var ind = element[0]._index;
      //       alert(ind);
      //     }
      //   },
      scales: {
        xAxes: [
          {
            //type: "linear",
            display: true,
            scaleLabel: {
              display: true,
              labelString: "Week",
              FontFace: "roboto",
            },
            ticks: {
              fontSize: 10,
              FontFace: "roboto",
            },
          },
        ],
        yAxes: [
          {
            // type: "linear",
            display: true,
            scaleLabel: {
              display: true,
              labelString: "Punctuality Rate(%)",
              FontFace: "roboto",
            },
            ticks: {
              beginAtZero: true,
            },
          },
        ],
      },
      //   legend: {
      //     display: false
      //   },
      //   tooltips: {
      //     enabled: false
      //   }
    };

    //2)Monnthlylinedata-----------START  PUNCTUALITY
    const Monnthlylinedatastart = {
      labels: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      datasets: [
        {
          label: "Start",
          data: [33, 23, 15, 41, 44, 65, 33, 53, 85, 41, 44, 26],
          fill: false,
          backgroundColor: "rgba(75,192,192,0.2)",
          borderColor: "#4B85F7",
        },
        // {
        //   label: "Arrival",
        //   data: [33, 55, 65, 71, 74, 76, 33, 73, 85, 49, 74, 65],
        //   fill: false,
        //   borderColor: "#6BB13F",
        // },
      ],
    };

    const lineOptions = {
      legend: {
        display: true,
        position: "top",
      },
      //   onClick: (e, element) => {
      //     if (element.length > 0) {
      //       var ind = element[0]._index;
      //       alert(ind);
      //     }
      //   },
      scales: {
        xAxes: [
          {
            //type: "linear",
            display: true,
            scaleLabel: {
              display: true,
              labelString: "Week",
              FontFace: "roboto",
            },
            ticks: {
              fontSize: 10,
              FontFace: "roboto",
            },
          },
        ],

        yAxes: [
          {
            // type: "linear",
            display: true,
            scaleLabel: {
              display: true,
              labelString: "Punctuality Rate(%)",
              FontFace: "roboto",
            },
            ticks: {
              beginAtZero: true,
            },
          },
        ],
      },
      // legend: {
      //   display: true
      // },
      tooltips: {
        enabled: true,
      },
    };

    ///////3)Monnthlylinedata--------- ARRIVAL PUNCTUALITY

    const MonnthlylinedataArrival = {
      labels: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      datasets: [
        // {
        //   label: "Start",
        //   data: [33, 23, 15, 41, 44, 65, 33, 53, 85, 41, 44, 26],
        //   fill: false,
        //   backgroundColor: "rgba(75,192,192,0.2)",
        //   borderColor: "#4B85F7",
        // },
        {
          label: "Arrival",
          data: [33, 55, 65, 71, 74, 76, 33, 73, 85, 49, 74, 65],
          fill: false,
          borderColor: "#6BB13F",
        },
      ],
    };

    //4)weeklylinedata-----------ARRIVAL PUNCTUALITY

    const weeklylinedataArrival = {
      labels: this.state.arrival_punctuality_graph_date,
      datasets: [
        // {
        //   label: "Start",
        //   data: [33, 23, 15, 41, 44, 65, 12],
        //   fill: false,
        //   backgroundColor: "rgba(75,192,192,0.2)",
        //   borderColor: "#4B85F7",
        // },
        {
          label: "Arrival",
          data: this.state.arrival_punctuality_graph_value,
          fill: false,
          borderColor: "#6BB13F",
        },
      ],
    };
//{this.state.currentMonth} 
    return (
      <div className="container-fluid">
          <h2 className="kpi_contractual_header">Current Month KPI Data</h2>
        {this.props.ContractalKpiApi?.contractualDetailsApi?.loading ? (
          <div className="loader-overlay">
            <div className="loader-container">
              <ReactLoader />
            </div>
          </div>
        ) : null}

        <div className="row ms-2">
          <div className="col-lg-4 widthss-20  gx-0 shadow bg-body rounded">
            <div className="kpi_contractual_head">
              <div className="kpi_contractual_head-text-1">
                {" "}
                ASSURED FLEET AVAILABILITY
              </div>
              <button
                type="button"
                className="relibility_kpi-btns"
                onClick={this.onKPIContractualSubmission}
              >
                VIEW MORE
                <img
                  className="relibility_kpi-button-png"
                  src={require("../../assets/images/arrow-red/red.png")}
                  alt="next"
                />
              </button>
              {this.state.showPopups && (
                <KpiContractualPopup
                  // buttonClicked={this.state.cardClicked}
                  datas={this.state.popupData}
                  show={this.state.addModalshowpopup}
                  closemodal={this.addModalCloses}
                />
              )}

              <div class="kpi_content_img">
                <img
                  className="kpi_contractual_head_img-1"
                  src={require("../../assets/images/headernew/head.png")}
                  alt="next"
                />
              </div>
            </div>
            <div style={{ marginTop: "10px" }}>
              <p className="fleet-availability_half_donut_text-2">
                TARGET LINE : 95%
              </p>
              <div style={{ marginTop: "-15px" }}>
                <Doughnut
                  width={"140px"}
                  height={"40px"}
                  data={fleetavailabilitydataset}
                  options={{
                    responsive: true,
                    rotation: 1 * Math.PI,
                    circumference: 1 * Math.PI,
                    maintainAspectRatio: true,
                    legend: {
                      display: false,
                    },
                    borderWidth: 8,
                    cutoutPercentage: 80,
                  }}
                />
              </div>
              <p
                className="kpi_contractual_half_donut_text"
                style={{ color: fleetAvailablityColor }}
              >
                {this.state.AssuredFleetAvailability} %
              </p>
              <p className="fleet-availability_half_donut_text-1">
                OUT OF 100%
              </p>
            </div>
          </div>
          <div className="col-lg-4 widthss-20 ms-4 gx-0 shadow bg-body bg-lightred rounded">
            <div className="kpi_contractual_head">
              <div className="kpi_contractual_head-text-1">
                SCHEDULE ADHERENCE
              </div>
              <button
                type="button"
                className="relibility_kpi-btns"
                onClick={this.onKPIcontractualScheduleAdherence}
              >
                VIEW MORE
                <img
                  className="relibility_kpi-button-png"
                  src={require("../../assets/images/arrow-red/red.png")}
                  alt="next"
                />
              </button>
              {this.state.showPopup && (
                <KpiContractualScheduleAdhPopup
                  // buttonClicked={this.state.cardClicked}
                  data={this.state.scheduleAdherencePopup}
                  show={this.state.addModalshow}
                  closemodal={this.addModalClose}
                />
              )}
              <div class="kpi_content_img">
                <img
                  className="kpi_contractual_head_img-1"
                  src={require("../../assets/images/headernew/head.png")}
                  alt="next"
                />
              </div>
            </div>
            <div style={{ marginTop: "10px" }}>
              <p className="fleet-availability_half_donut_text-2">
                TARGET LINE : 95%
              </p>
              <div style={{ marginTop: "-15px" }}>
                <Doughnut
                  width={"140px"}
                  height={"40px"}
                  data={Scheduleadherencedataset}
                  options={{
                    responsive: true,
                    rotation: 1 * Math.PI,
                    circumference: 1 * Math.PI,
                    maintainAspectRatio: true,
                    legend: {
                      display: false,
                    },
                    borderWidth: 8,
                    cutoutPercentage: 80,
                  }}
                />
              </div>
              <p
                className="kpi_contractual_half_donut_text-1"
                style={{ color: ScheduleadherenceColor }}
              >
                {this.state.ScheduleAdherence} %
              </p>
              <p className="fleet-availability_half_donut_text-1">
                OUT OF 100%
              </p>
            </div>
          </div>
          <div className="col-lg-4 widthss-20 ms-4 gx-0 shadow bg-body rounded">
            <div className="kpi_contractual_head">
              <div className="kpi_contractual_head-text-1">
                RELIABILITY FACTOR
              </div>
              <button
                type="button"
                className="relibility_kpi-btns"
                onClick={this.onKpiRealiability}
              >
                VIEW MORE
                <img
                  className="relibility_kpi-button-png"
                  src={require("../../assets/images/arrow-red/red.png")}
                  alt="next"
                />
              </button>
              {this.state.showPopupreliability && (
                <KpiContractualReliabilityPopup
                  dataa={this.state.realibilityPopup}
                  show={this.state.addModalshowreliability}
                  closemodal={this.addModalClosereliability}
                />
              )}
              <div class="kpi_content_img">
                <img
                  className="kpi_contractual_head_img-1"
                  src={require("../../assets/images/headernew/head.png")}
                  alt="next"
                />
              </div>
            </div>
            <div>
            
                {this.state.ReliabilityFactor >= 1 ? (
                  <div  className="lotty-player-kpi-contractual">
                    <Lottie
                     
                      animationData={DataAnimation}
                      // height="10px"
                      // width="10px"
                      
                    />
                   
                  </div>
                ) : (
                  <div  className="lotty-player-kpi-contractual-danger" style={{ height: '70px', width: '70px' }}>
                    <Lottie
                     //style={{alignItems:"center"}}
                      animationData={DataAnimations}
                    
                    />
                    {/* * you have achieved your internal target */}
                  </div>
                )}
               <p className="kpi_contractual_text_real">
                    * you have achieved your internal target
                    </p>
              <p className="kpi_contractual_text_realiability">
                {this.state.ReliabilityFactor}
              
              </p>
              {/* <hr style={{marginTop:"-10px"}}/> */}

              {/* <div className="row">
                <p className="contractual_kpi_kpi_footer">
                  * Your Internal Target: ≥1 Per 10000
                </p>
              </div> */}
            </div>


          </div>

          <div className="col-lg-4 widthss-20 ms-4 gx-0 shadow bg-body rounded">
            <div className="kpi_contractual_head">
              <div className="kpi_contractual_head-text-1">
                {" "}
                MAINTENANCE ADHERENCE (%)
              </div>
              {/* <button
                  type="button"
                  className="relibility_kpi-btns"
                  onClick={this.onKPIcontractualScheduleAdherence}
                >
                  VIEW MORE
                  <img
                    className="relibility_kpi-button-png"
                    src={require("../../assets/images/arrow-red/red.png")}
                    alt="next"
                  />
                </button> */}
              {/* {this.state.showPopup && (
                  <KpiContractualScheduleAdhPopup
                     data={this.state.scheduleAdherencePopup}
                    show={this.state.addModalshow}
                    closemodal={this.addModalClose}
                  />
                )} */}
              <div class="kpi_content_img">
                <img
                  className="kpi_contractual_head_img-1"
                  src={require("../../assets/images/headernew/head.png")}
                  alt="next"
                />
              </div>
            </div>
            <div style={{ marginTop: "10px" }}>
              <p className="fleet-availability_half_donut_text-2">
                TARGET LINE : 95%
              </p>
              <div style={{ marginTop: "-15px" }}>
                <Doughnut
                  width={"140px"}
                  height={"40px"}
                  data={Maintenanceadherencedataset}
                  options={{
                    responsive: true,
                    rotation: 1 * Math.PI,
                    circumference: 1 * Math.PI,
                    maintainAspectRatio: true,
                    legend: {
                      display: false,
                    },
                    borderWidth: 8,
                    cutoutPercentage: 80,
                  }}
                />
              </div>
              <p
                className="kpi_contractual_half_donut_text-1"
                style={{ color: MaintenanceAdherenceColor }}
              >
                {this.state.MaintenanceAdherence} %
              </p>
              <p className="fleet-availability_half_donut_text-1">
                OUT OF 100%
              </p>
            </div>
          </div>
        </div>

        <div className="row mt-4 ms-2">
        <div className="col-lg-4 widthss-20  gx-0 shadow bg-body rounded">
          {/* <div className="col-lg-4 width-30 ms-0 gx-0 shadow bg-body rounded"> */}
            <div className="kpi_contractual_head">
              <div className="kpi_contractual_head-text-1">
                BREAKDOWN SUMMARY
              </div>
              <button
                type="button"
                className="relibility_kpi-btns"
                onClick={this.onKPIcontractualBreakdownsummary}
              >
                VIEW MORE
                <img
                  className="relibility_kpi-button-png"
                  src={require("../../assets/images/arrow-red/red.png")}
                  alt="next"
                />
              </button>
              {this.state.showPopupbreak && (
                <KpiContractualBreakdownSummaryPopup
                  dataBreak={this.state.breakdownSummaryPopup}
                  show={this.state.addModalshowbreak}
                  closemodal={this.addModalCloseBreak}
                />
              )}
              <div class="kpi_content_img">
                <img
                  className="kpi_contractual_head_img-1"
                  src={require("../../assets/images/headernew/head.png")}
                  alt="next"
                />
              </div>
            </div>
            <div className="row">
              <div className="col">
                <img
                  src={require("../../assets/images/kpi/contract-2.png")}
                  className="kpi_contractual_image-break"
                />
              </div>
              <div className="col">
                <p className="kpi_contractual_text-breakdown">
                  NUMBER OF BREAKDOWN EVENTS
                </p>
                <p className="kpi_contractual_number-breakdown">
                  {this.state.NoofBreakdownEvents}
                </p>
              </div>
            </div>
            <hr />

            <div className="row">
              <div className="col">
                <img
                  src={require("../../assets/images/kpi/kpi-10.png")}
                  className="kpi_contractual_image-break"
                />
              </div>
              <div className="col">
                <p className="kpi_contractual_text-breakdown">
                  TRIP LOST DUE TO BREAKDOWN
                </p>
                <p className="kpi_contractual_number-breakdown">
                  {this.state.TripLostDueToBreakdown}
                </p>
              </div>
            </div>
          </div>
          
          <div className="col-lg-4 widthss-20 ms-4 gx-0 shadow bg-body rounded">
            <div className="kpi_contractual_head">
              <div className="kpi_contractual_head-text-1">
                ACCIDENT SUMMARY
              </div>
              <button
                type="button"
                className="relibility_kpi-btns"
                onClick={this.onKPIcontractualAccidentsummary}
              >
                VIEW MORE
                <img
                  className="relibility_kpi-button-png"
                  src={require("../../assets/images/arrow-red/red.png")}
                  alt="next"
                />
              </button>
              {this.state.showPopupaccident && (
                <KpiContractualAccidentSummaryPopup
                  dataAccident={this.state.accidentSummaryPopup}
                  show={this.state.addModalshowaccident}
                  closemodal={this.addModalCloseAccident}
                />
              )}
              <div class="kpi_content_img">
                <img
                  className="kpi_contractual_head_img-1"
                  src={require("../../assets/images/headernew/head.png")}
                  alt="next"
                />
              </div>
            </div>
            <div className="row">
              <div className="col">
                <img
                  src={require("../../assets/images/kpi/contract-2.png")}
                  className="kpi_contractual_image-break"
                />
              </div>
              <div className="col">
                <p className="kpi_contractual_text-breakdown">
                  NUMBER OF ACCIDENT EVENTS
                </p>
                <p className="kpi_contractual_number-breakdown">
                  {this.state.NoofAccidentEvents}
                </p>
              </div>
            </div>
            <hr />

            <div className="row">
              <div className="col">
                <img
                  src={require("../../assets/images/kpi/kpi-10.png")}
                  className="kpi_contractual_image-break"
                />
              </div>
              <div className="col">
                <p className="kpi_contractual_text-breakdown">
                  TRIP LOST DUE TO ACCIDENT
                </p>
                <p className="kpi_contractual_number-breakdown">
                  {this.state.TripLostDueToAccidents}
                </p>
              </div>
            </div>
          </div>
          



          <div className="col-lg-4 widthss-20 ms-4 gx-0 shadow bg-body rounded">
          {/* <div className="col-lg-4 width-30 ms-4 gx-0 shadow bg-body rounded"> */}
            <div className="kpi_contractual_head">
              <div className="kpi_contractual_head-text-1">
                START PUNCTUALITY
              </div>
              <button
                type="button"
                className="relibility_kpi-btns"
                  onClick={this.onKPIcontractualStartWeekly}
              >
                VIEW MORE
                <img
                  className="relibility_kpi-button-png"
                  src={require("../../assets/images/arrow-red/red.png")}
                  alt="next"
                />
              </button>
                    {this.state.showPopupStart && (
                <KpiContractualStartWeeklyPopup
                  dataStart={this.state.StartWeeklyPopup}
                  show={this.state.addModalshowStart}
                  closemodal={this.addModalCloseStart}
                />
              )}

              <div class="content_img">
                <img
                  className="kpis_contractual_head_img-1"
                  src={require("../../assets/images/headernew/head.png")}
                  alt="next"
                />
              </div>
            </div>
            {/* <div className="kpi_contractual_switch-field">
              <input
                type="radio"
                id="switch_lefts"
                name="switchToggles"
                value={this.props.leftLabel}
                onChange={this.toggleStatevalue}
                checked={!this.state.togglevalues}
              />
              <label htmlFor="switch_lefts">Monthly</label>

              <input
                type="radio"
                id="switch_rights"
                name="switchToggles"
                value={this.props.rightLabel}
                onChange={this.toggleStatevalue}
                checked={this.state.togglevalues}
              />
              <label htmlFor="switch_rights">Weekly</label>
            </div>
            {this.state.togglevalues == true ? ( */}
            <div style={{ marginTop: "14px" }}>
              <Line data={weeklylinedatastart} options={lineOptionss} />
            </div>
            {/* // ) : (
            //   <div>
            //     <Line data={Monnthlylinedatastart} options={lineOptions} />
            //   </div>
            // )} */}
          </div>
          <div className="col-lg-4 widthss-20 ms-4 gx-0 shadow bg-body rounded">
          {/* <div className="col-lg-4 width-30 ms-4 gx-0 shadow bg-body rounded"> */}
            <div className="kpi_contractual_head">
              <div className="kpi_contractual_head-text-1">
                ARRIVAL PUNCTUALITY
              </div>
              <button
                type="button"
                className="relibility_kpi-btns"
                onClick={this.onKPIcontractualArrivalWeeklyData}
              >
                VIEW MORE
                <img
                  className="relibility_kpi-button-png"
                  src={require("../../assets/images/arrow-red/red.png")}
                  alt="next"
                />
              </button>
                    {this.state.showPopupArrival && (
                <KpiContractualArrivalWeeklyPopup
                  dataArrival={this.state.ArrivalWeeklyPopup}
                  show={this.state.addModalshowArrival}
                  closemodal={this.addModalCloseArrival}
                />
              )}
              <div class="content_img">
                <img
                  className="kpis_contractual_head_img-1"
                  src={require("../../assets/images/headernew/head.png")}
                  alt="next"
                />
              </div>
            </div>
            {/* <div className="kpi_contractual_switch-field">
              <input
                type="radio"
                id="switch_left"
                name="switchToggle"
                value={this.props.leftLabel}
                onChange={this.toggleStatevalueArrival}
                checked={!this.state.togglevalueArrival}
              />
              <label htmlFor="switch_left">Monthly</label>

              <input
                type="radio"
                id="switch_right"
                name="switchToggle"
                value={this.props.rightLabel}
                onChange={this.toggleStatevalueArrival}
                checked={this.state.togglevalueArrival}
              />
              <label htmlFor="switch_right">Weekly</label>
            </div> */}
            {/* {this.state.togglevalueArrival == true ? ( */}
            <div style={{ marginTop: "14px" }}>
              <Line data={weeklylinedataArrival} options={lineOptionss} />
            </div>
            {/* // ) : (
            //   <div>
            //     <Line data={MonnthlylinedataArrival} options={lineOptions} />
            //   </div>
            // )} */}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps, {
  getContractualDetailsAPI,
  getContractualAssuredavailabilityAPI,
  getContractualScheduleAdherenceAPI,
  getContractualReliabilityAPI,
  getContractualBreakdownsummaryViewAPI,
   getStartPunctualityWeeklyDataAPI,
   getArrivalPunctualityWeeklyDataAPI,
   getContractualStartPunctualGraphAPI,
   getContractualArrivalPunctualGraphAPI,
   getContractualAccidentsummaryViewAPI
})(withRouter(KPIcontractual));
