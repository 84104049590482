import React, { Component } from "react";
import ReactTable from "react-table";
import "react-table/react-table.css";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import ReactLoader from "../../component/react-loader/react-loader.component";
import ReactTooltip from "react-tooltip";
import moment from "moment";
import "../../container/vehicle_summary_report/index.scss";
import Workbook from "react-excel-workbook";

//actions
import vehicleSummaryReportApiActions from "../../redux/vehicle_summary_report/actions";
const { getvehicleSummaryReportAPI } = vehicleSummaryReportApiActions;

const timestamp = new Date().getTime();
const yesterday = timestamp - 24 * 60 * 60 * 1000;

var startTimestamp = new Date(yesterday);
startTimestamp.setHours(0);
startTimestamp.setMinutes(0);
startTimestamp.setSeconds(0);

var endTimestamp = new Date();
endTimestamp.setHours(23);
endTimestamp.setMinutes(59);
endTimestamp.setSeconds(58);

class VehicleSummaryReport extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      start_date: startTimestamp,
      end_date: endTimestamp,
      inputSearch: "",
      filteredDatas: [],
      sumOfDistanceTravelled: "",
      //editingfields: {},
    };
  }

  componentDidMount() {
    //getting all the data
    this.getVehicleSummaryReport();
  }

  getVehicleSummaryReport() {
    const { start_date, end_date } = this.state;

    if (start_date < end_date) {
      this.setState({ loading: true });
      const payload = {
        //user_id: "1",
        user_id: sessionStorage.getItem("userId"),
        customerId: sessionStorage.getItem("customerId"),
        start_timestamp: moment(start_date).format("YYYY-MM-DD HH:mm:ss"),
        end_timestamp: moment(end_date).format("YYYY-MM-DD HH:mm:ss"),
      };
      this.props.getvehicleSummaryReportAPI(payload);
    } else if (start_date > end_date) {
      alert("End Date should be greater than Start Date");
    } else {
      alert("End Date should be greater than Start Date");
    }
  }

  componentDidUpdate(prevProps) {
    console.log("vehiclesummarydetails", this.props.VehicleSummaryReportApi);
    if (prevProps !== this.props) {
      if (
        this.props.VehicleSummaryReportApi?.vehicleSummaryReportApi?.result
          ?.data?.responseData?.data.length > 0
      ) {
        this.setState({
          data: this.props.VehicleSummaryReportApi.vehicleSummaryReportApi
            .result.data.responseData.data,
        });
        this.setState({
          filteredDatas:
            this.props.VehicleSummaryReportApi.vehicleSummaryReportApi.result
              .data.responseData.data,
        });
        const sum =
          // console.log("map",sum)
          this.setState({
            sumOfDistanceTravelled:
              this.props.VehicleSummaryReportApi.vehicleSummaryReportApi.result
                .data.responseData.total_distance,
          });
      } else {
        this.setState({ data: [] });
      }
    }
  }

  handleSearchvehicleSummary = (event) => {
    const search = event.target.value.toLowerCase();
    this.setState({
      inputSearch: search,
      filteredDatas: this.state.data.filter(
        (item) =>
          (item.vehicleName &&
            item.vehicleName.toLowerCase().includes(search)) ||
          (item.regNo && item.regNo.toLowerCase().includes(search))
      ),
    });
  };
  render() {
    let { inputSearch } = this.state;
    let odo = this.state.sumOfDistanceTravelled;
    return (
      <div className="vehicle_summary_report">
        {this.props.VehicleSummaryReportApi?.vehicleSummaryReportApi
          ?.loading ? (
          <div className="loader-overlay">
            <div className="loader-container">
              <ReactLoader />
            </div>
          </div>
        ) : null}

        <div className="vehicle_summary_report_div">
          <div className="vehicle_summary_report_search">
            <form
              onSubmit={(e) => {
                e.preventDefault();
              }}
            >
              <input
                className="vehicle_summary_report_search-input"
                type="text"
                id="filter"
                placeholder="Search Table (VIN. / Reg No.)"
                value={inputSearch || ""}
                onChange={this.handleSearchvehicleSummary}
              />
            </form>
          </div>

          <div>
            <Workbook
              filename="vehicle-summary-Report.xlsx"
              element={
                <div
                  className="vehicle_summary_report-download"
                  data-tip
                  data-for="registerTip"
                >
                  Export
                  <img
                    src={require("../../assets/images/export/export.png")}
                    alt=""
                    className="vehicle_summary_report-download-img"
                  />
                  <ReactTooltip id="registerTip" place="top" effect="solid">
                    Download
                  </ReactTooltip>
                </div>
              }
            >
              <Workbook.Sheet
                data={this.state.data}
                name="vehicle-summary-Report"
              >
                <Workbook.Column label="Reg No." value="regNo" />
                <Workbook.Column label="VIN" value="vehicleName" />
                <Workbook.Column
                  label="Total Distance Travelled"
                  value="totalDistanceCovered"
                />
                <Workbook.Column
                  label="Total Power Consumed"
                  value="totalPowerConsumed"
                />
                <Workbook.Column
                  label="Total KWH Charged"
                  value="totalKwhCharged"
                />
                <Workbook.Column label="Efficiency" value="efficiency" />
                <Workbook.Column label="On Time Trips" value="onTimeTrips" />
                <Workbook.Column label="Delayed Trips" value="delayedTrips" />
                <Workbook.Column label="Alerts" value="alerts" />
                <Workbook.Column label="Route Name" value="routeName" />
                <Workbook.Column
                  label="Max Temperature"
                  value="maxTemperature"
                />
                <Workbook.Column
                  label="Min Temperature"
                  value="minTemperature"
                />
                <Workbook.Column
                  label="Total Trip Time"
                  value="totalTripsCompleted"
                />
                <Workbook.Column label="Date" value="createdDate" />
                <Workbook.Column label="Trip Loss" value="tripLoss" />
                <Workbook.Column label="Trip Loss KM" value="tripLossKm" />
                <Workbook.Column label="Issue Reported" value="issueReported" />
              </Workbook.Sheet>
            </Workbook>
          </div>
        </div>

        <div className="vehicle_summary_report_table">
          <ReactTooltip place="left" type="success" effect="solid" />
          <ReactTable
            NoDataComponent={() => null}
            pageSizeOptions={[20, 30, 50, 80, 100, 130, 200, 500]}
            style={{
              height: "430px",
              marginTop: "0%",
              marginRight: "30px",
              marginLeft: "40px",
            }}
            data={this.state.filteredDatas}
            showPagination={true}
            columns={[
              {
                Header: "S.No",
                id: "serialNumber",
                resizable: false,
                filterable: false,
                width: 90,

                Cell: (row) => {
                  return <div>{row.index + 1}</div>;
                },
              },

              {
                Header: "Reg No",
                id: "regNo",
                accessor: (d) => d.regNo,
                width: 160,

                filterAll: true,
                resizable: false,
              },
              {
                Header: "VIN",
                id: "vehicleName",
                accessor: (d) => d.vehicleName,
                width: 160,

                filterAll: true,
                resizable: false,
              },

              {
                Header:
                  "Total Distance" +
                  " (" +
                  this.state.sumOfDistanceTravelled +
                  " Km)",
                id: "totalDistanceCovered",
                accessor: (d) => d.totalDistanceCovered,
                width: 180,

                filterAll: true,
                resizable: false,
              },

              {
                Header: "Total Power Consumed (Kwh)",
                id: "totalPowerConsumed",
                accessor: (d) => d.totalPowerConsumed,
                width: 180,

                filterAll: true,
                resizable: false,
              },

              {
                Header: "Total KWH Charged (Kwh)",
                id: "totalKwhCharged",
                accessor: (d) => d.totalKwhCharged,

                width: 180,

                filterAll: true,
                resizable: false,
                // Cell: (props) => {
                //   return <span>{Math.round(props.value * 100) / 100}</span>;
                // },
              },
              // {
              //   Header: "Vehicle ID",
              //   id: "vehicleId",
              //   accessor: (d) => d.vehicleId,
              //   width: 160,
              //   getProps: (state, rowInfo, column) => {
              //     return {
              //       style: {
              //         direction: "ltr",
              //       },
              //     };
              //   },
              //   filterAll: true,
              //   resizable: false,
              // },

              {
                Header: "Efficiency (Kwh/km)",
                id: "efficiency",
                accessor: (d) => d.efficiency,
                width: 180,

                filterAll: true,
                resizable: false,
              },

              {
                Header: "On Time Trips",
                id: "onTimeTrips",
                accessor: (d) => d.onTimeTrips,
                width: 160,

                filterAll: true,
                resizable: false,
              },
              {
                Header: "Delayed Trips",
                id: "delayedTrips",
                accessor: (d) => d.delayedTrips,
                width: 160,

                filterAll: true,
                resizable: false,
              },

              {
                Header: "Alerts",
                id: "alerts",
                accessor: (d) => d.alerts,
                width: 160,

                filterAll: true,
                resizable: false,
              },
              {
                Header: "Route Name",
                id: "routeName",
                Cell: (rows) => {
                  /* Add data-tip */
                  return <span title={rows.value}>{rows.value}</span>;
                },
                accessor: (d) => d.routeName,
                width: 160,

                filterAll: true,
                resizable: false,
              },

              {
                Header: "Max Temperature ( °C )",
                id: "maxTemperature",
                accessor: (d) => d.maxTemperature,
                width: 200,

                filterAll: true,
                resizable: false,
              },

              {
                Header: "Total Trip Time",
                id: "totalTripsCompleted",
                accessor: (d) => d.totalTripsCompleted,
                width: 160,

                filterAll: true,
                resizable: false,
              },
              {
                Header: "Min Temperature ( °C )",
                id: "minTemperature",
                accessor: (d) => d.minTemperature,
                width: 200,

                filterAll: true,
                resizable: false,
              },
              {
                Header: "Date",
                id: "createdDate",
                accessor: (d) => d.createdDate,
                width: 130,

                filterAll: true,
                resizable: false,
              },
              {
                Header: "Trip Loss",
                id: "tripLoss",
                accessor: (d) => d.tripLoss,
                width: 130,

                filterAll: true,
                resizable: false,
              },
              {
                Header: "Trip Loss KM",
                id: "tripLossKm",
                accessor: (d) => d.tripLossKm,
                width: 130,

                filterAll: true,
                resizable: false,
              },
              {
                Header: "Issue Reported",
                id: "issueReported",
                accessor: (d) => d.issueReported,
                width: 130,

                filterAll: true,
                resizable: false,
              },
            ]}
            defaultSorted={[
              {
                id: "serialNumber",
                asc: true,
              },
            ]}
            defaultPageSize={50}
            className="-striped -highlight"
          >
            {(state, makeTable, instance) => {
              let recordsInfoText = "";

              const { filtered, pageRows, pageSize, sortedData, page } = state;

              if (sortedData && sortedData.length > 0) {
                let isFiltered = filtered.length > 0;

                let totalRecords = sortedData.length;

                let recordsCountFrom = page * pageSize + 1;

                let recordsCountTo = recordsCountFrom + pageRows.length - 1;

                if (isFiltered)
                  recordsInfoText = `${recordsCountFrom}-${recordsCountTo} of ${totalRecords} filtered records`;
                else
                  recordsInfoText = `${recordsCountFrom}-${recordsCountTo} of ${totalRecords} records`;
              } else recordsInfoText = "No records";

              return (
                <div className="main-grid">
                  <div className="col-sm-12">
                    <span className="records-info">{recordsInfoText}</span>
                  </div>

                  {makeTable()}
                </div>
              );
            }}
          </ReactTable>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps, {
  getvehicleSummaryReportAPI,
})(withRouter(VehicleSummaryReport));
