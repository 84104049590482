import React, { Component } from "react";
import "./index.scss";
import { connect } from "react-redux";
import BackToScreen from "../../component/back_to_screen";
import { withRouter } from "react-router";
import TripUpdate from "../../component/trip_updation";
import TripHistory from "../../component/trip_history";
import TripSubmission from "../../component/trip_submission";

class TripMaster extends Component {
  constructor(props) {
    super(props);
    this.messageTimeOut = null;
    this.state = {
      selTab: 0,
      message: "",
      messageType: 0,
      tabs: [
        {
          tabIndex: 0,
          tabName: "TRIP UPDATION",
        },
        {
          tabIndex: 1,
          tabName: "TRIP SUBMISSION",
        },
        {
          tabIndex: 2,
          tabName: "TRIP HISTORY",
        },
      ],
    };

    this.backtodashboardPage = this.backtodashboardPage.bind(this);
    this.tabClick = this.tabClick.bind(this);
  }

  tabClick = (evt, tabItem) => {
    // const { selectedTab } = this.props;
    this.onClickCloseMessage();

    if (evt.target.parentElement) {
      let tabs = evt.target.parentElement.getElementsByClassName(
        "accident_master__layout__col-1__block__wrapper__header__left__tab"
      );
      Array.prototype.forEach.call(tabs, function (tab) {
        tab.classList.remove("active");
      });
    }
    evt.currentTarget.classList.add("active");

    this.setState({ selTab: tabItem.tabIndex });
    // selectedTab && selectedTab(evt, tabItem);
  };

  onClickCloseMessage() {
    this.messageTimeOut = null;
    this.setState({
      message: "",
      messageType: 0,
    });
  }

  backtodashboardPage() {
    let tabItem = {
      tabIndex: 16,
      tabName: "Dashboard",
    };
    this.props.selectedTab(null, tabItem);
  }



  render() {
    const { tabs, selTab } = this.state;

    let tabDom = [];

    if (tabs && tabs.length > 0) {
      tabs.map(function (tabItem, index) {
        let className =
          selTab === tabItem.tabIndex
            ? "active accident_master__layout__col-1__block__wrapper__header__left__tab ripple-effect"
            : "accident_master__layout__col-1__block__wrapper__header__left__tab ripple-effect";
        tabDom.push(
          <li
            className={className}
            key={tabItem.tabIndex}
            onClick={(e) => this.tabClick(e, tabItem)}
          >
            <a>{tabItem.tabName}</a>
            <span className="tab-strips"></span>
          </li>
        );
      }, this);
    }

    return (
      <div className="accident_master ">
        <div className="accident_master__layout tabMargin">
          <div className="accident_master__layout__col-1">
            <div className="accident_master__layout__col-1__block">
              <div className="accident_master__layout__col-1__block__wrapper tabHeight">
                <div className="master-driver-backward">
                  <BackToScreen routingCall={this.backtodashboardPage} />
                  <h2 className="accident-master-heading">Trip Master</h2>
                </div>
                <div className="accident_master__layout__col-1__block__wrapper__header">
                  <div className="accident_master__layout__col-1__block__wrapper__header__left">
                    {tabDom}
                  
                  </div>
                </div>
                <hr className="accident_master__layout__col-1__block__wrapper__horizontal-line" />
                <div className="accident_master__layout__col-1__block__wrapper__content tabHeightTrend">
                  {(() => {
                    if (selTab === 0) {
                      return <TripUpdate />;
                    } else if (selTab === 1) {
                      return <TripSubmission />;
                    } else {
                      return <TripHistory />;
                    }
                  })()}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps, {})(withRouter(TripMaster));
