import React, { Component } from "react";
import ReactTable from "react-table";
import "react-table/react-table.css";
import MasterAccidentButton from "../master_accident_button";
import "../onboarding_view_create_project/index.scss";
import Workbook from "react-excel-workbook";
import ReactTooltip from "react-tooltip";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import ReactLoader from "../../component/react-loader/react-loader.component";
// //Actions
// import accidentMasterApiActions from "../../redux/master_accident/actions";
// import driverlistDropdownActions from "../../redux/driverlist_dropdown/actions";
// import vehiclelistDropdownActions from "../../redux/vehiclelist_dropdown/actions";
import moment from "moment";

// const { getDriverlistDropdownAPI } = driverlistDropdownActions;
// const { getVehiclelistDropdownAPI } = vehiclelistDropdownActions;
// const {
//   getAccidentMasterAPI,
//   UPDATE_ACCIDENTMASTERDETAILS_SUCCESS,
//   UPDATE_ACCIDENTMASTERDETAILS_FAILURE,
//   DELETE_ACCIDENTMASTERDETAILS_SUCCESS,
//   DELETE_ACCIDENTMASTERDETAILS_FAILURE,
// } = accidentMasterApiActions;
class OnboardingViewCreateProject extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // loginId,
      // data1,
      data: [],
      pages: null,
      loading: true,
      message: "",
      messageType: 0,
      dataSet: [],
      selected: null,
      vehicleList: [],
      driverList: [],
      filteredData: [],
      searchInput: "",
    };
  }

  //   componentDidMount() {
  //     //getting all the data
  //     this.getAccidentMaster();
  //     this.getDriverlistDropdown();
  //     this.getVehiclelistDropdown();
  //   }

  //   getAccidentMaster() {
  //     var endTimestamp = new Date();
  //     endTimestamp.setHours(23);
  //     endTimestamp.setMinutes(59);
  //     endTimestamp.setSeconds(58);
  //     this.setState({ loading: true });
  //     const payload = {
  //       // user_id: "1",
  //       user_id: sessionStorage.getItem("userId"),
  //       customerId: sessionStorage.getItem("customerId"),
  //       start_timestamp: "2021-02-23 00:00:00",
  //       end_timestamp: moment(endTimestamp).format("YYYY-MM-DD HH:mm:ss"),
  //     };
  //     this.props.getAccidentMasterAPI(payload);
  //   }

  //   componentDidUpdate(prevProps) {
  //     //console.log("accident", this.props);
  //     if (prevProps !== this.props) {
  //       if (
  //         this.props.AccidentMaster?.viewAccidentMasterApi?.result?.data
  //           ?.responseData?.length > 0
  //       ) {
  //         this.setState({
  //           dataSet:
  //             this.props.AccidentMaster.viewAccidentMasterApi.result.data
  //               .responseData,
  //         });
  //         this.setState({
  //           filteredData:
  //             this.props.AccidentMaster.viewAccidentMasterApi.result.data
  //               .responseData,
  //         });
  //       } else {
  //         this.setState({ dataSet: [] });
  //       }

  //       if (
  //         this.props.driverlistDropdownApi?.driverlistDropdownApi?.result?.data
  //           ?.data?.length > 0
  //       ) {
  //         this.setState({
  //           driverList:
  //             this.props.driverlistDropdownApi.driverlistDropdownApi.result.data
  //               .data,
  //         });
  //       } else {
  //         this.setState({ driverList: [] });
  //       }
  //       if (
  //         this.props.vehiclelistDropdownApi?.vehiclelistDropdownApi?.result?.data
  //           ?.data?.length > 0
  //       ) {
  //         this.setState({
  //           vehicleList:
  //             this.props.vehiclelistDropdownApi.vehiclelistDropdownApi.result.data
  //               .data,
  //         });
  //       } else {
  //         this.setState({ vehicleList: [] });
  //       }

  //       // update

  //       if (
  //         prevProps.AccidentMaster.updateAccidentMasterApi !=
  //         this.props.AccidentMaster.updateAccidentMasterApi
  //       ) {
  //         if (
  //           this.props.AccidentMaster.updateAccidentMasterApi.actionType ===
  //             UPDATE_ACCIDENTMASTERDETAILS_SUCCESS &&
  //           this.props.AccidentMaster.updateAccidentMasterApi.loading === false
  //         ) {
  //           this.setState({
  //             message: "Accident has been updated successfully",
  //             messageType: 1,
  //           });
  //           this.messageTimeOut = setTimeout(
  //             () => this.onClickCloseMessage(),
  //             3 * 1000
  //           );
  //           this.getAccidentMaster();
  //         } else if (
  //           this.props.AccidentMaster.updateAccidentMasterApi.actionType ===
  //             UPDATE_ACCIDENTMASTERDETAILS_FAILURE &&
  //           this.props.AccidentMaster.updateAccidentMasterApi.loading === false
  //         ) {
  //           this.setState({
  //             message: "Accident can not be updated",
  //             messageType: -1,
  //           });
  //           this.messageTimeOut = setTimeout(
  //             () => this.onClickCloseMessage(),
  //             3 * 1000
  //           );
  //         }
  //       }

  //       //       //delete
  //       if (
  //         prevProps.AccidentMaster.deleteAccidentMasterApi !=
  //         this.props.AccidentMaster.deleteAccidentMasterApi
  //       ) {
  //         if (
  //           this.props.AccidentMaster.deleteAccidentMasterApi.actionType ===
  //             DELETE_ACCIDENTMASTERDETAILS_SUCCESS &&
  //           this.props.AccidentMaster.deleteAccidentMasterApi.loading === false
  //         ) {
  //           this.setState({
  //             message: "Accident has been deleted successfully",
  //             messageType: 1,
  //           });
  //           this.messageTimeOut = setTimeout(
  //             () => this.onClickCloseMessage(),
  //             3 * 1000
  //           );
  //           this.getAccidentMaster();
  //         } else if (
  //           this.props.AccidentMaster.deleteAccidentMasterApi.actionType ===
  //             DELETE_ACCIDENTMASTERDETAILS_FAILURE &&
  //           this.props.AccidentMaster.deleteAccidentMasterApi.loading === false
  //         ) {
  //           this.setState({
  //             message: "Accident can not be deleted",
  //             messageType: -1,
  //           });
  //           this.messageTimeOut = setTimeout(
  //             () => this.onClickCloseMessage(),
  //             3 * 1000
  //           );
  //         }
  //       }
  //     }
  //   }

  //   getDriverlistDropdown() {
  //     this.setState({ loading: true });
  //     const payload = {
  //       api_key: "ALL_DRIVER_DETAILS",
  //       customerId: sessionStorage.getItem("customerId"),
  //     };
  //     this.props.getDriverlistDropdownAPI(payload);
  //   }

  //   getVehiclelistDropdown() {
  //     this.setState({ loading: true });
  //     const payload = {
  //       api_key: "ALL_VEHICLE_DETAILS",
  //       customerId: sessionStorage.getItem("customerId"),
  //     };
  //     this.props.getVehiclelistDropdownAPI(payload);
  //   }
  onClickCloseMessage() {
    this.messageTimeOut = null;
    this.setState({
      message: "",
      messageType: 0,
    });
  }
  //   handleaccidentmaster = (event) => {
  //     const search = event.target.value;
  //     this.setState({
  //       searchInput: search,
  //       filteredData: this.state.dataSet.filter(
  //         (item) =>
  //           (item.description && item.description.includes(search)) ||
  //           (item.registration_number &&
  //             item.registration_number.toLowerCase().includes(search)) ||
  //           (item.driver_badge_number &&
  //             item.driver_badge_number.toLowerCase().includes(search))
  //       ),
  //     });
  //   };

  render() {
    let { searchInput } = this.state;
    return (
      <div>
        {this.state.message && (
          <div
            className={`alert ${
              this.state.messageType === 1
                ? "success"
                : this.state.messageType === -1
                ? "error"
                : ""
            }`}
          >
            <span
              className="closebtn"
              onClick={() => this.onClickCloseMessage()}
            >
              &times;
            </span>
            <strong>{this.state.message}</strong>
          </div>
        )}
        {/* {this.props.AccidentMaster?.viewAccidentMasterApi?.loading ? (
          <div className="master-driver-loader-overlay">
            <div className="master-driver-loader-container">
              <ReactLoader />
            </div>
          </div>
        ) : null} */}
        <div className="create-project-subhead">
          <div className="create-project-update-report ">
            <form
              onSubmit={(e) => {
                e.preventDefault();
              }}
            >
              <input
                style={{ width: "400px" }}
                className="create-project-update-input"
                type="text"
                id="filter"
                placeholder="Search Table"
                // value={searchInput || ""}
                // onChange={this.handleaccidentmaster}
              />
            </form>

            <div>
              <Workbook
                filename="create-project-Report.xlsx"
                element={
                  <div
                    className="create-project-Report-download"
                    data-tip
                    data-for="registerTip"
                  >
                    Export
                    <img
                      src={require("../../assets/images/export/export.png")}
                      alt=""
                      className="create-project-Report-download-img"
                    />
                    <ReactTooltip id="registerTip" place="top" effect="solid">
                      Download
                    </ReactTooltip>
                  </div>
                }
              >
                {/* <Workbook.Sheet
                  data={this.state.dataSet}
                  name="accident-master-Report"
                >
                  <Workbook.Column label="Project Name" value="vehicleid" />
                  <Workbook.Column
                    label="Project Details"
                    value="createdtimestamp_ist"
                  />
                  <Workbook.Column label="City" value="description" />
                  <Workbook.Column
                    label="State"
                    value="air_serial_number"
                  />

                  <Workbook.Column
                    label="No Of Vehicles"
                    value="registration_number"
                  />

                  <Workbook.Column
                    label="No Of Schedule"
                    value="driver_badge_number"
                  />
                  <Workbook.Column label="No Of Route" value="date" />
                  <Workbook.Column label="Address" value="time" />
               
                  <Workbook.Column label="No Of Km" value="time" />
               
                </Workbook.Sheet> */}
              </Workbook>
            </div>
          </div>
        </div>
        <div style={{ marginTop: "-20px" }}>
          {/* <h2 className="msg">
            Note: Future trips will be reflected in Excel Sheet
          </h2> */}
          <ReactTooltip place="left" type="success" effect="solid" />
          <ReactTable
            NoDataComponent={() => null}
            pageSizeOptions={[20, 30, 50, 80, 100, 130, 200, 500]}
            style={{
              height: "440px",
              marginTop: "0%",
            }}
            // data={this.state.filteredData}
            showPagination={true}
            columns={[
              {
                Header: "Action",
                width: 90,
                Cell: (props) => (
                  <MasterAccidentButton
                    data={props}
                    driverDropdown={this.state.driverList}
                    vehicleDropdown={this.state.vehicleList}
                  ></MasterAccidentButton>
                ),
                resizable: false,
              },
              {
                Header: "S.No",
                id: "serialNumber",
                resizable: false,
                filterable: false,
                width: 90,
                Cell: (row) => {
                  return <div>{row.index + 1}</div>;
                },
              },

              {
                Header: "Project Name",
                id: "registration_number                ",
                accessor: (d) => d.registration_number,
                width: 140,

                filterAll: true,
                resizable: false,
              },

              {
                Header: "Project Details",
                id: "date",
                accessor: (d) => d.date,
                width: 140,

                filterAll: true,
                resizable: false,
              },

              {
                Header: "City",
                id: "time",
                accessor: (d) => d.time,
                width: 160,

                filterAll: true,
                resizable: false,
              },

              {
                Header: "State",
                id: "driver_badge_number                ",
                accessor: (d) => d.driver_badge_number,
                width: 140,

                filterAll: true,
                resizable: false,
              },

              {
                Header: "No of Vehicles",
                id: "description",
                accessor: (d) => d.description,
                width: 120,

                filterAll: true,
                resizable: false,
              },

              {
                Header: "No of Schedule",
                id: "air_serial_number",
                accessor: (d) => d.air_serial_number,
                width: 120,

                filterAll: true,
                resizable: false,
              },

              {
                Header: "No of Route",
                id: "damaged_parts",
                accessor: (d) => d.damaged_parts,
                width: 120,

                filterAll: true,
                resizable: false,
              },

              {
                Header: "No of Km",
                id: "accident_reason",
                accessor: (d) => d.accident_reason,
                width: 100,

                filterAll: true,
                resizable: false,
              },
              {
                Header: "Address",
                id: "immediate_action                ",
                accessor: (d) => d.immediate_action,
                width: 200,

                filterAll: true,
                resizable: false,
              },
            ]}
            defaultSorted={[
              {
                id: "serialNumber",
                asc: true,
              },
            ]}
            defaultPageSize={50}
            className="-striped -highlight"
          >
            {(state, makeTable, instance) => {
              let recordsInfoText = "";

              const { filtered, pageRows, pageSize, sortedData, page } = state;

              if (sortedData && sortedData.length > 0) {
                let isFiltered = filtered.length > 0;

                let totalRecords = sortedData.length;

                let recordsCountFrom = page * pageSize + 1;

                let recordsCountTo = recordsCountFrom + pageRows.length - 1;

                if (isFiltered)
                  recordsInfoText = `${recordsCountFrom}-${recordsCountTo} of ${totalRecords} filtered records`;
                else
                  recordsInfoText = `${recordsCountFrom}-${recordsCountTo} of ${totalRecords} records`;
              } else recordsInfoText = "No records";

              return (
                <div className="main-grid">
                  <div className="above-table text-right">
                    <div className="col-sm-12">
                      <span className="records-info">{recordsInfoText}</span>
                    </div>
                  </div>

                  {makeTable()}
                </div>
              );
            }}
          </ReactTable>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps, {
  //   getAccidentMasterAPI,
  //   getDriverlistDropdownAPI,
  //   getVehiclelistDropdownAPI,
})(withRouter(OnboardingViewCreateProject));
