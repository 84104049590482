import React, { Component } from "react";
import ReactTable from "react-table";
import "react-table/react-table.css";
import MasterRouteButton from "../master_route_button";
import "../master_route_update/index.scss";
import ReactTooltip from "react-tooltip";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import Workbook from "react-excel-workbook";
import routeMasterApiActions from "../../redux/master_route/actions";
import ReactLoader from "../../component/react-loader/react-loader.component";
const {
  getRouteMasterAPI,
  UPDATE_ROUTEMASTERDETAILS_SUCCESS,
  UPDATE_ROUTEMASTERDETAILS_FAILURE,
  DELETE_ROUTEMASTERDETAILS_SUCCESS,
  DELETE_ROUTEMASTERDETAILS_FAILURE,
} = routeMasterApiActions;
class MasterRouteUpdate extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // loginId,
      // data1,
      data: [],
      pages: null,
      loading: true,
      message: "",
      messageType: 0,
      dataSet: [],
      selected: null,
      vehicleList: [],
      driverList: [],
      filteredData: [],
      searchInput: "",
    };
  }

  componentDidMount() {
    this.getRouteMaster();
  }

  getRouteMaster() {
    this.setState({ loading: true });
    const payload = {
      //user_id: "1",
      user_id : sessionStorage.getItem("userId"),
      customerId: sessionStorage.getItem("customerId"),
    };
    this.props.getRouteMasterAPI(payload);
  }

  componentDidUpdate(prevProps) {
    //console.log("routemaster", this.props);
    if (prevProps !== this.props) {
      if (
        this.props.RouteMasterApi?.routeMasterApi?.result?.data?.responseData
          ?.length > 0
      ) {
        this.setState({
          dataSet:
            this.props.RouteMasterApi.routeMasterApi.result.data.responseData,
        });
        this.setState({
          filteredData:
            this.props.RouteMasterApi.routeMasterApi.result.data.responseData,
        });
      } else {
        this.setState({ dataSet: [] });
      }

      //update

      if (
        prevProps.RouteMasterApi.updateRouteMasterApi !=
        this.props.RouteMasterApi.updateRouteMasterApi
      ) {
        if (
          this.props.RouteMasterApi.updateRouteMasterApi.actionType ===
            UPDATE_ROUTEMASTERDETAILS_SUCCESS &&
          this.props.RouteMasterApi.updateRouteMasterApi.loading === false
        ) {
          this.setState({
            message: "Route has been updated successfully",
            messageType: 1,
          });
          this.messageTimeOut = setTimeout(
            () => this.onClickCloseMessage(),
            3 * 1000
          );
          this.getRouteMaster();
        } else if (
          this.props.RouteMasterApi.updateRouteMasterApi.actionType ===
            UPDATE_ROUTEMASTERDETAILS_FAILURE &&
          this.props.RouteMasterApi.updateRouteMasterApi.loading === false
        ) {
          this.setState({
            message: "Route can not be updated",
            messageType: -1,
          });
          this.messageTimeOut = setTimeout(
            () => this.onClickCloseMessage(),
            3 * 1000
          );
        }
      }

      //delete
      if (
        prevProps.RouteMasterApi.deleteRouteMasterApi !=
        this.props.RouteMasterApi.deleteRouteMasterApi
      ) {
        if (
          this.props.RouteMasterApi.deleteRouteMasterApi.actionType ===
            DELETE_ROUTEMASTERDETAILS_SUCCESS &&
          this.props.RouteMasterApi.deleteRouteMasterApi.loading === false
        ) {
          this.setState({
            message: "Route has been deleted successfully",
            messageType: 1,
          });
          this.messageTimeOut = setTimeout(
            () => this.onClickCloseMessage(),
            3 * 1000
          );
          this.getRouteMaster();
        } else if (
          this.props.RouteMasterApi.deleteRouteMasterApi.actionType ===
            DELETE_ROUTEMASTERDETAILS_FAILURE &&
          this.props.RouteMasterApi.deleteRouteMasterApi.loading === false
        ) {
          this.setState({
            message: "Route can not be deleted",
            messageType: -1,
          });
          this.messageTimeOut = setTimeout(
            () => this.onClickCloseMessage(),
            3 * 1000
          );
        }
      }
    }
  }

  handleroutemaster = (event) => {
  const { value } = event.target;
  const { dataSet } = this.state;
  const searchInput = value.trim().toLowerCase();
  const filteredData = dataSet.filter((item) =>
    Object.keys(item).some(
      (key) =>
      typeof item[key] === "string" &&
        item[key] &&
        (key === "routeName" ||
         key === "routeId"
         )
          &&
        item[key].toString().toLowerCase().includes(searchInput)
    )
  );
  this.setState({ searchInput, filteredData });
}
  

  onClickCloseMessage() {
    this.messageTimeOut = null;
    this.setState({
      message: "",
      messageType: 0,
    });
  }

  render() {
    let { searchInput } = this.state;

    return (
      <div>
        {this.state.message && (
          <div
            className={`alert ${
              this.state.messageType === 1
                ? "success"
                : this.state.messageType === -1
                ? "error"
                : ""
            }`}
          >
            <span
              className="closebtn"
              onClick={() => this.onClickCloseMessage()}
            >
              &times;
            </span>
            <strong>{this.state.message}</strong>
          </div>
        )}
        {this.props.RouteMasterApi?.routeMasterApi?.loading ? (
          <div className="master-driver-loader-overlay">
            <div className="master-driver-loader-container">
              <ReactLoader />
            </div>
          </div>
        ) : null}
        <div className="Route-update-subhead">
          {/* <h3 className="Route-update">Route View</h3> */}

          {/* <div className="table-items-count">
            Items: {this.state.filteredData.length}
          </div> */}
          <div className="search-Route-update-report ">
            <form onSubmit={e => { e.preventDefault(); }}>
              <input
                style={{ width: "400px" }}
                className="search-Route-update-input"
                type="text"
                id="filter"
                placeholder="Search Table ( Route Name/ Route ID )"
                value={searchInput || ""}
                onChange={this.handleroutemaster}
              />
            </form>
          </div>

          <div>
            <Workbook
              filename="Route-master-Report.xlsx"
              element={
                <div
                  className="route-master-Report-download"
                  data-tip
                  data-for="registerTip"
                >
                  Export
                  <img
                    src={require("../../assets/images/export/export.png")}
                    alt=""
                    className="route-master-Report-download-img"
                  />
                  <ReactTooltip id="registerTip" place="top" effect="solid">
                    Download
                  </ReactTooltip>
                </div>
              }
            >
              <Workbook.Sheet
                data={this.state.dataSet}
                name="Route-master-Report"
              >
                <Workbook.Column label="ID" value="id" />
                <Workbook.Column label="Route Name" value="routeName" />
                <Workbook.Column label="Route ID" value="routeId" />
                <Workbook.Column label="Start Location" value="startLocation" />
                <Workbook.Column label="End Location" value="endLocation" />
                <Workbook.Column label="Distance" value="distance" />
                <Workbook.Column label="Num Of Stops" value="numOfStops" />
                <Workbook.Column
                  label="Estimated Trips"
                  value="estimatedTrips"
                />
                <Workbook.Column label="Start Latitude" value="startLatitude" />
                <Workbook.Column
                  label="Start Longitude"
                  value="startLongitude"
                />
                <Workbook.Column label="End Latitude" value="endLatitude" />
                <Workbook.Column label="End Longitude" value="endLongitude" />
              </Workbook.Sheet>
            </Workbook>
          </div>
        </div>
        <div className="Route-update-table">
          {/* <h2 className="msg">
            Note: Future trips will be reflected in Excel Sheet
          </h2> */}
          <ReactTooltip place="left" type="success" effect="solid" />
          <ReactTable
            NoDataComponent={() => null}
            pageSizeOptions={[20, 30, 50, 80, 100, 130, 200, 500]}
            style={{
              height: "430px",
              marginTop: "0%",
            }}
            data={this.state.filteredData}
            showPagination={true}
            columns={[
              {
                Header: "Edit",
                width: 90,
                Cell: (props) => (
                  <MasterRouteButton
                    data={props}
                    driverDropdown={this.state.driverList}
                    vehicleDropdown={this.state.vehicleList}
                  ></MasterRouteButton>
                ),
                resizable: false,
              },
              // {
              //   Header: "Sl.",
              //   id: "serialNumber",
              //   filterable: false,
              //   resizable: false,
              //   width: 70,
              //   Cell: (row) => {
              //     return <div>{row.index + 1}</div>;
              //   },
              // },

              {
                Header: "ID",
                id: "id",
                accessor: (d) => d.id,
                width: 90,

                filterAll: true,
                resizable: false,
              },
              {
                Header: "Route ID",
                id: "routeId",
                accessor: (d) => d.routeId,
                width: 70,

                filterAll: true,
                resizable: false,
              },
              {
                Header: "Route Name",
                id: "routeName",
                accessor: (d) => d.routeName,
                width: 90,

                filterAll: true,
                resizable: false,
              },
              {
                Header: "Start Location",
                id: "startLocation",
                Cell: (rows) => {
                  /* Add data-tip */
                  return <span title={rows.value}>{rows.value}</span>;
                },
                width: 190,
                accessor: (d) => d.startLocation,

                filterAll: true,
                resizable: false,
              },
              {
                Header: "End Location",
                id: "endLocation",
                Cell: (rows) => {
                  /* Add data-tip */
                  return <span title={rows.value}>{rows.value}</span>;
                },
                width: 190,
                accessor: (d) => d.endLocation,

                filterAll: true,
                resizable: false,
              },

              {
                Header: "Distance",
                id: "distance",
                accessor: (d) => d.distance,
                width: 60,

                filterAll: true,
                resizable: false,
              },
              {
                Header: "No of Stops",
                id: "numOfStops",
                accessor: (d) => d.numOfStops,
                width: 100,

                filterAll: true,
                resizable: false,
              },
              {
                Header: "Estimated Trips",
                id: "estimatedTrips",
                accessor: (d) => d.estimatedTrips,
                width: 110,

                filterAll: true,
                resizable: false,
              },

              {
                Header: "Start Latitude",
                id: "startLatitude",
                Cell: (rows) => {
                  /* Add data-tip */
                  return <span title={rows.value}>{rows.value}</span>;
                },
                accessor: (d) => d.startLatitude,
                width: 110,

                filterAll: true,
                resizable: false,
              },

              {
                Header: "Start Longitude",
                id: "startLongitude",
                Cell: (rows) => {
                  /* Add data-tip */
                  return <span title={rows.value}>{rows.value}</span>;
                },
                accessor: (d) => d.startLongitude,
                width: 110,

                filterAll: true,
                resizable: false,
              },
              {
                Header: "End Latitude",
                id: "endLatitude",
                Cell: (rows) => {
                  /* Add data-tip */
                  return <span title={rows.value}>{rows.value}</span>;
                },
                accessor: (d) => d.endLatitude,
                width: 100,

                filterAll: true,
                resizable: false,
              },
              {
                Header: "End Longitude",
                id: "endLongitude",
                Cell: (rows) => {
                  /* Add data-tip */
                  return <span title={rows.value}>{rows.value}</span>;
                },
                accessor: (d) => d.endLongitude,
                width: 110,

                filterAll: true,
                resizable: false,
              },
            ]}
            defaultSorted={[
              {
                id: "id",
                asc: true,
              },
            ]}
            defaultPageSize={50}
            className="-striped -highlight"
          >
            {(state, makeTable, instance) => {
              let recordsInfoText = "";

              const { filtered, pageRows, pageSize, sortedData, page } = state;

              if (sortedData && sortedData.length > 0) {
                let isFiltered = filtered.length > 0;

                let totalRecords = sortedData.length;

                let recordsCountFrom = page * pageSize + 1;

                let recordsCountTo = recordsCountFrom + pageRows.length - 1;

                if (isFiltered)
                  recordsInfoText = `${recordsCountFrom}-${recordsCountTo} of ${totalRecords} filtered records`;
                else
                  recordsInfoText = `${recordsCountFrom}-${recordsCountTo} of ${totalRecords} records`;
              } else recordsInfoText = "No records";

              return (
                <div className="main-grid">
                  <div className="above-table text-right">
                    <div className="col-sm-12">
                      <span className="records-info">{recordsInfoText}</span>
                    </div>
                  </div>

                  {makeTable()}
                </div>
              );
            }}
          </ReactTable>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps, {
  getRouteMasterAPI,
})(withRouter(MasterRouteUpdate));
