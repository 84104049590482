import React, { Component } from "react";
import ReactTable from "react-table";
import "react-table/react-table.css";
import MasterAccidentButton from "../master_accident_button";
import "./index.scss";
//import * as User from "shared/app-data/user";
import Workbook from "react-excel-workbook";
import ReactTooltip from "react-tooltip";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import ReactLoader from "../../component/react-loader/react-loader.component";
// //Actions
import accidentMasterApiActions from "../../redux/master_accident/actions";
import driverlistDropdownActions from "../../redux/driverlist_dropdown/actions";
import vehiclelistDropdownActions from "../../redux/vehiclelist_dropdown/actions";
import moment from "moment";

const { getDriverlistDropdownAPI } = driverlistDropdownActions;
const { getVehiclelistDropdownAPI } = vehiclelistDropdownActions;
const {
  getAccidentMasterAPI,
  UPDATE_ACCIDENTMASTERDETAILS_SUCCESS,
  UPDATE_ACCIDENTMASTERDETAILS_FAILURE,
  DELETE_ACCIDENTMASTERDETAILS_SUCCESS,
  DELETE_ACCIDENTMASTERDETAILS_FAILURE,
} = accidentMasterApiActions;
class MasterAccidentUpdate extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // loginId,
      // data1,
      data: [],
      pages: null,
      loading: true,
      message: "",
      messageType: 0,
      dataSet: [],
      selected: null,
      vehicleList: [],
      driverList: [],
      filteredData: [],
      searchInput: "",
    };
  }

  componentDidMount() {
    //getting all the data
    this.getAccidentMaster();
    this.getDriverlistDropdown();
    this.getVehiclelistDropdown();
  }

  getAccidentMaster() {
    var endTimestamp = new Date();
    endTimestamp.setHours(23);
    endTimestamp.setMinutes(59);
    endTimestamp.setSeconds(58);
    this.setState({ loading: true });
    const payload = {
      // user_id: "1",
      user_id: sessionStorage.getItem("userId"),
      customerId: sessionStorage.getItem("customerId"),
      start_timestamp: "2021-02-23 00:00:00",
      end_timestamp: moment(endTimestamp).format("YYYY-MM-DD HH:mm:ss"),
    };
    this.props.getAccidentMasterAPI(payload);
  }

  componentDidUpdate(prevProps) {
    //console.log("accident", this.props);
    if (prevProps !== this.props) {
      if (
        this.props.AccidentMaster?.viewAccidentMasterApi?.result?.data
          ?.responseData?.length > 0
      ) {
        this.setState({
          dataSet:
            this.props.AccidentMaster.viewAccidentMasterApi.result.data
              .responseData,
        });
        this.setState({
          filteredData:
            this.props.AccidentMaster.viewAccidentMasterApi.result.data
              .responseData,
        });
      } else {
        this.setState({ dataSet: [] });
      }

      if (
        this.props.driverlistDropdownApi?.driverlistDropdownApi?.result?.data
          ?.data?.length > 0
      ) {
        this.setState({
          driverList:
            this.props.driverlistDropdownApi.driverlistDropdownApi.result.data
              .data,
        });
      } else {
        this.setState({ driverList: [] });
      }
      if (
        this.props.vehiclelistDropdownApi?.vehiclelistDropdownApi?.result?.data
          ?.data?.length > 0
      ) {
        this.setState({
          vehicleList:
            this.props.vehiclelistDropdownApi.vehiclelistDropdownApi.result.data
              .data,
        });
      } else {
        this.setState({ vehicleList: [] });
      }

      // update

      if (
        prevProps.AccidentMaster.updateAccidentMasterApi !=
        this.props.AccidentMaster.updateAccidentMasterApi
      ) {
        if (
          this.props.AccidentMaster.updateAccidentMasterApi.actionType ===
            UPDATE_ACCIDENTMASTERDETAILS_SUCCESS &&
          this.props.AccidentMaster.updateAccidentMasterApi.loading === false
        ) {
          this.setState({
            message: "Accident has been updated successfully",
            messageType: 1,
          });
          this.messageTimeOut = setTimeout(
            () => this.onClickCloseMessage(),
            3 * 1000
          );
          this.getAccidentMaster();
        } else if (
          this.props.AccidentMaster.updateAccidentMasterApi.actionType ===
            UPDATE_ACCIDENTMASTERDETAILS_FAILURE &&
          this.props.AccidentMaster.updateAccidentMasterApi.loading === false
        ) {
          this.setState({
            message: "Accident can not be updated",
            messageType: -1,
          });
          this.messageTimeOut = setTimeout(
            () => this.onClickCloseMessage(),
            3 * 1000
          );
        }
      }

      //       //delete
      if (
        prevProps.AccidentMaster.deleteAccidentMasterApi !=
        this.props.AccidentMaster.deleteAccidentMasterApi
      ) {
        if (
          this.props.AccidentMaster.deleteAccidentMasterApi.actionType ===
            DELETE_ACCIDENTMASTERDETAILS_SUCCESS &&
          this.props.AccidentMaster.deleteAccidentMasterApi.loading === false
        ) {
          this.setState({
            message: "Accident has been deleted successfully",
            messageType: 1,
          });
          this.messageTimeOut = setTimeout(
            () => this.onClickCloseMessage(),
            3 * 1000
          );
          this.getAccidentMaster();
        } else if (
          this.props.AccidentMaster.deleteAccidentMasterApi.actionType ===
            DELETE_ACCIDENTMASTERDETAILS_FAILURE &&
          this.props.AccidentMaster.deleteAccidentMasterApi.loading === false
        ) {
          this.setState({
            message: "Accident can not be deleted",
            messageType: -1,
          });
          this.messageTimeOut = setTimeout(
            () => this.onClickCloseMessage(),
            3 * 1000
          );
        }
      }
    }
  }

  getDriverlistDropdown() {
    this.setState({ loading: true });
    const payload = {
      api_key: "ALL_DRIVER_DETAILS",
      customerId: sessionStorage.getItem("customerId"),
    };
    this.props.getDriverlistDropdownAPI(payload);
  }

  getVehiclelistDropdown() {
    this.setState({ loading: true });
    const payload = {
      api_key: "ALL_VEHICLE_DETAILS",
      customerId: sessionStorage.getItem("customerId"),
    };
    this.props.getVehiclelistDropdownAPI(payload);
  }
  onClickCloseMessage() {
    this.messageTimeOut = null;
    this.setState({
      message: "",
      messageType: 0,
    });
  }
  handleaccidentmaster = (event) => {
    const { value } = event.target;
    const { dataSet } = this.state;
    const searchInput = value.trim().toLowerCase();
    const filteredData = dataSet.filter((item) =>
      Object.keys(item).some(
        (key) =>
        typeof item[key] === "string" &&
          item[key] &&
          (key === "registration_number" ||
           key === "driver_badge_number" ||
           key === "description" 
           )
            &&
          item[key].toString().toLowerCase().includes(searchInput)
      )
    );
    this.setState({ searchInput, filteredData });
  }
    
    

  

  render() {
    let { searchInput } = this.state;
    return (
      <div>
        {this.state.message && (
          <div
            className={`alert ${
              this.state.messageType === 1
                ? "success"
                : this.state.messageType === -1
                ? "error"
                : ""
            }`}
          >
            <span
              className="closebtn"
              onClick={() => this.onClickCloseMessage()}
            >
              &times;
            </span>
            <strong>{this.state.message}</strong>
          </div>
        )}
        {this.props.AccidentMaster?.viewAccidentMasterApi?.loading ? (
          <div className="master-driver-loader-overlay">
            <div className="master-driver-loader-container">
              <ReactLoader />
            </div>
          </div>
        ) : null}
        <div className="driver-update-subhead">
          {/* <h3 className="driver-update">Driver View</h3> */}

          {/* <div className="table-items-count">
            Items: {this.state.filteredData.length}
          </div> */}
          <div className="search-driver-update-report ">
            <form
              onSubmit={(e) => {
                e.preventDefault();
              }}
            >
              <input
                style={{ width: "400px" }}
                className="search-driver-update-input"
                type="text"
                id="filter"
                placeholder="Search Table ( Description /Registration No/ Driver Badge No )"
                value={searchInput || ""}
                onChange={this.handleaccidentmaster}
              />
            </form>

            <div>
              <Workbook
                filename="Accident-master-Report.xlsx"
                element={
                  <div
                    className="accident-master-Report-download"
                    data-tip
                    data-for="registerTip"
                  >
                    Export
                    <img
                      src={require("../../assets/images/export/export.png")}
                      alt=""
                      className="accident-master-Report-download-img"
                    />
                    <ReactTooltip id="registerTip" place="top" effect="solid">
                      Download
                    </ReactTooltip>
                  </div>
                }
              >
                <Workbook.Sheet
                  data={this.state.dataSet}
                  name="accident-master-Report"
                >
                  <Workbook.Column label="Vehicle" value="vehicleid" />
                  <Workbook.Column
                    label="Timestamp"
                    value="createdtimestamp_ist"
                  />
                  <Workbook.Column label="Description" value="description" />
                  <Workbook.Column
                    label="Air Serial Number"
                    value="air_serial_number"
                  />

                  <Workbook.Column
                    label="Registration Number"
                    value="registration_number"
                  />

                  <Workbook.Column
                    label="Driver Badge Number"
                    value="driver_badge_number"
                  />
                  <Workbook.Column label="Date Of Accident" value="date" />

                  <Workbook.Column label="Time Of Accident" value="time" />
                  <Workbook.Column
                    label="Damaged Parts"
                    value="damaged_parts"
                  />

                  <Workbook.Column
                    label="Immediate Action"
                    value="immediate_action"
                  />
                  <Workbook.Column
                    label="Reason Of Accident"
                    value="accident_reason"
                  />

                  <Workbook.Column
                    label="Corrective Action"
                    value="corrective_action"
                  />
                  <Workbook.Column label="Major/ Minor" value="major_minor" />

                  <Workbook.Column
                    label="Job Card Number"
                    value="job_card_number"
                  />
                  <Workbook.Column label="Cost Of Part" value="cost_of_part" />

                  <Workbook.Column
                    label="Insurance Claim Required "
                    value="insurance_claim_required "
                  />
                  <Workbook.Column
                    label="Cost Of Labour"
                    value="cost_of_labour"
                  />

                  <Workbook.Column label="Actual Cost" value="actual_cost" />
                  <Workbook.Column
                    label="Estimation Cost"
                    value="estimation_cost"
                  />

                  <Workbook.Column
                    label="Recovry From Insurance"
                    value="recovry_from_insurance"
                  />
                  <Workbook.Column label="Loss" value="loss" />

                  <Workbook.Column
                    label="Bus On Road date"
                    value="bus_on_road_date"
                  />
                  <Workbook.Column
                    label="Planned Days For Repair The Bus"
                    value="planned_days_for_repair_the_bus"
                  />

                  <Workbook.Column
                    label="Additional Remarks"
                    value="additional_remarks"
                  />
                  <Workbook.Column
                    label="Total Days Used For Accident Repair"
                    value="total_days_used_for_accident_repair"
                  />
                </Workbook.Sheet>
              </Workbook>
            </div>
          </div>
        </div>
        <div style={{ marginTop: "-20px" }}>
          {/* <h2 className="msg">
            Note: Future trips will be reflected in Excel Sheet
          </h2> */}
          <ReactTooltip place="left" type="success" effect="solid" />
          <ReactTable
            NoDataComponent={() => null}
            pageSizeOptions={[20, 30, 50, 80, 100, 130, 200, 500]}
            style={{
              height: "440px",
              marginTop: "0%",
              // width: "1000px",
            }}
            data={this.state.filteredData}
            showPagination={true}
            columns={[
              {
                Header: "Action",
                width: 90,
                Cell: (props) => (
                  <MasterAccidentButton
                    data={props}
                    driverDropdown={this.state.driverList}
                    vehicleDropdown={this.state.vehicleList}
                  ></MasterAccidentButton>
                ),
                resizable: false,
              },
              {
                Header: "S.No",
                id: "serialNumber",
                resizable: false,
                filterable: false,
                width: 90,
                Cell: (row) => {
                  return <div>{row.index + 1}</div>;
                },
              },

              {
                Header: "Reg No.",
                id: "registration_number                ",
                accessor: (d) => d.registration_number,
                width: 120,

                filterAll: true,
                resizable: false,
              },

              {
                Header: "Date Of Accident",
                id: "date                ",
                accessor: (d) => d.date,
                width: 120,

                filterAll: true,
                resizable: false,
              },

              {
                Header: "Time Of Accident",
                id: "time",
                accessor: (d) => d.time,
                width: 120,

                filterAll: true,
                resizable: false,
              },

              {
                Header: "Driver Badge No",
                id: "driver_badge_number                ",
                accessor: (d) => d.driver_badge_number,
                width: 120,

                filterAll: true,
                resizable: false,
              },

              {
                Header: "Description",
                id: "description",
                accessor: (d) => d.description,
                width: 120,
                Cell: (rows) => {
                  /* Add data-tip */
                  return <span title={rows.value}>{rows.value}</span>;
                },
                filterAll: true,
                resizable: false,
              },

              {
                Header: "AIR Serial No",
                id: "air_serial_number",
                accessor: (d) => d.air_serial_number,
                width: 120,

                filterAll: true,
                resizable: false,
              },

              {
                Header: "Damaged Part",
                id: "damaged_parts",
                accessor: (d) => d.damaged_parts,
                width: 120,

                filterAll: true,
                resizable: false,
              },

              {
                Header: "Immediate Action",
                id: "immediate_action                ",
                accessor: (d) => d.immediate_action,
                width: 120,

                filterAll: true,
                resizable: false,
              },

              {
                Header: "Reason Of Accident",
                id: "accident_reason",
                accessor: (d) => d.accident_reason,
                width: 120,

                filterAll: true,
                resizable: false,
              },

              {
                Header: "Corrective Action",
                id: "corrective_action",
                accessor: (d) => d.corrective_action,
                width: 120,

                filterAll: true,
                resizable: false,
              },

              {
                Header: "Major/Minor",
                id: "major_minor",
                accessor: (d) => d.major_minor,
                width: 120,

                filterAll: true,
                resizable: false,
              },

              {
                Header: "Insurance Claim Required",
                id: "insurance_claim_required                ",
                accessor: (d) => d.insurance_claim_required,
                width: 150,

                filterAll: true,
                resizable: false,
              },
              {
                Header: "Job Card Number",
                id: "job_card_number",
                accessor: (d) => d.job_card_number,
                width: 120,

                filterAll: true,
                resizable: false,
              },
              {
                Header: "Cost Of Part",
                id: "cost_of_part                ",
                accessor: (d) => d.cost_of_part,
                width: 120,

                filterAll: true,
                resizable: false,
              },
              {
                Header: "Cost Of Labour",
                id: "cost_of_labour                ",
                accessor: (d) => d.cost_of_labour,
                width: 120,

                filterAll: true,
                resizable: false,
              },
              {
                Header: "Actual Cost",
                id: "actual_cost",
                accessor: (d) => d.actual_cost,
                width: 120,

                filterAll: true,
                resizable: false,
              },
              {
                Header: "Estimation Cost",
                id: "estimation_cost                ",
                accessor: (d) => d.estimation_cost,
                width: 120,

                filterAll: true,
                resizable: false,
              },

              {
                Header: "Recovery From Insurance",
                id: "recovry_from_insurance",
                accessor: (d) => d.recovry_from_insurance,
                width: 150,

                filterAll: true,
                resizable: false,
              },

              {
                Header: "Loss",
                id: "loss",
                accessor: (d) => d.loss,
                width: 120,

                filterAll: true,
                resizable: false,
              },

              // {
              //   Header: "penalty incurred/Lose of Revenue",
              //   id: "phoneNumber",
              //   accessor: (d) => d.phoneNumber,
              //   width: 120,

              //   filterAll: true,
              //   resizable: false,
              // },

              {
                Header: "Bus On Road Date",
                id: "bus_on_road_date                ",
                accessor: (d) => d.bus_on_road_date,
                width: 120,

                filterAll: true,
                resizable: false,
              },

              {
                Header: "Planned Dates For Repair the bus",
                id: "planned_days_for_repair_the_bus",
                accessor: (d) => d.planned_days_for_repair_the_bus,
                width: 220,

                filterAll: true,
                resizable: false,
              },

              {
                Header: "Total Days Used For Repair The Bus",
                id: "total_days_used_for_accident_repair                ",
                accessor: (d) => d.total_days_used_for_accident_repair,
                width: 220,

                filterAll: true,
                resizable: false,
              },

              {
                Header: "Remarks",
                id: "additional_remarks                ",
                accessor: (d) => d.additional_remarks,
                width: 120,

                filterAll: true,
                resizable: false,
              },

              {
                Header: "Timestamp",
                id: "createdtimestamp_ist",
                accessor: (d) => d.createdtimestamp_ist,
                width: 190,

                filterAll: true,
                resizable: false,
              },
            ]}
            defaultSorted={[
              {
                id: "serialNumber",
                asc: true,
              },
            ]}
            defaultPageSize={50}
            className="-striped -highlight"
          >
            {(state, makeTable, instance) => {
              let recordsInfoText = "";

              const { filtered, pageRows, pageSize, sortedData, page } = state;

              if (sortedData && sortedData.length > 0) {
                let isFiltered = filtered.length > 0;

                let totalRecords = sortedData.length;

                let recordsCountFrom = page * pageSize + 1;

                let recordsCountTo = recordsCountFrom + pageRows.length - 1;

                if (isFiltered)
                  recordsInfoText = `${recordsCountFrom}-${recordsCountTo} of ${totalRecords} filtered records`;
                else
                  recordsInfoText = `${recordsCountFrom}-${recordsCountTo} of ${totalRecords} records`;
              } else recordsInfoText = "No records";

              return (
                <div className="main-grid">
                  <div className="above-table text-right">
                    <div className="col-sm-12">
                      <span className="records-info">{recordsInfoText}</span>
                    </div>
                  </div>

                  {makeTable()}
                </div>
              );
            }}
          </ReactTable>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps, {
  getAccidentMasterAPI,
  getDriverlistDropdownAPI,
  getVehiclelistDropdownAPI,
})(withRouter(MasterAccidentUpdate));
