import React, { Component } from "react";
import "./index.scss";
class BackToScreen extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      pages: null,
    };

  }
  render() {
    return (
      <div style={{marginLeft:"42px"}}>
        <img
          className=""
          src={require("../../assets/images/fleet-health/left-arrow.png")}
          alt=""
        />{" "}
        <span className="backtoscreen-text" onClick={this.props.routingCall}>
          {" "}
          Back
        </span>
      </div>
    );
  }
}

export default BackToScreen;
