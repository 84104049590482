import React, { Component } from "react";
import "../fleet_uptime_current_view/index.scss";
import ReactLoader from "../react-loader/react-loader.component";
import ReactTable from "react-table";
import "react-table/react-table.css";
import { connect } from "react-redux";
import Workbook from "react-excel-workbook";
import ReactTooltip from "react-tooltip";
import { withRouter } from "react-router";
import fleetUptimeMangementApiActions from "../../redux/uptime_mangement/actions";
const { getFleetUptimeCurrentViewAPI } = fleetUptimeMangementApiActions;

class FleetUptimeCurrentView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      inputSearch: "",
      filteredData: [],
    };
  }

  componentDidMount() {
    this.getfleetUptimeCurrentViewDetails();
  }

  getfleetUptimeCurrentViewDetails = async () => {
    const { start_date, end_date } = this.state;
    let payLoad = {
      //  user_id: "1",
      user_id: sessionStorage.getItem("userId"),
      customerId: sessionStorage.getItem("customerId"),
      // start_timestamp: moment(start_date).format("YYYY-MM-DD 00:00:00"),
      // end_timestamp: moment(end_date).format("YYYY-MM-DD 23:59:59"),
    };
    await this.props.getFleetUptimeCurrentViewAPI(payLoad);
  };

  // onSubmit = (e) => {
  //   this.getdriverDetails();
  // };

  handlefleetuptimecurrentviewSearch = (event) => {
    const search = event.target.value;
    this.setState({
      inputSearch: search,
      filteredData: this.state.data.filter(
        (item) =>
          (item.vehicleNo && item.vehicleNo.toLowerCase().includes(search)) ||
          (item.driverName && item.driverName.toLowerCase().includes(search)) ||
          (item.driverId && item.driverId.toLowerCase().includes(search))
      ),
    });
  };

  componentWillReceiveProps(nextProps) {
    //console.log("fleetuptimecureentlViewDetails", nextProps);
    if (
      nextProps?.FleetUptimeMangementApi?.FleetUptimeCurrentViewApi?.result
        ?.data?.responseData
    ) {
      this.setState({
        data: nextProps.FleetUptimeMangementApi.FleetUptimeCurrentViewApi.result
          .data.responseData,
      });

      this.setState({
        filteredData:
          nextProps.FleetUptimeMangementApi.FleetUptimeCurrentViewApi.result
            .data.responseData,
      });
    }
  }

  render() {
    let { inputSearch } = this.state;
    return (
      <div>
        <div className="fleet-uptime-current-div">
          <form
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            <input
              className="fleet-uptime-current-search"
              type="text"
              id="filter"
              placeholder="Search Table (Vehicle No / Driver Name / Driver ID)"
              value={inputSearch || ""}
              onChange={this.handlefleetuptimecurrentviewSearch}
            />
          </form>

          <div>
            <Workbook
              filename="Fleet-uptime-current-report.xlsx"
              element={
                <div
                  className="fleet-uptime-current_download"
                  data-tip
                  data-for="registerTip"
                >
                  Export
                  <img
                    src={require("../../assets/images/export/export.png")}
                    alt=""
                    className="fleet-uptime-current-img"
                  />
                  <ReactTooltip id="registerTip" place="top" effect="solid">
                    Download
                  </ReactTooltip>
                </div>
              }
            >
              <Workbook.Sheet
                data={this.state.data}
                name="Fleet-uptime-current-report"
              >
                <Workbook.Column label="Vehicle No." value="vehicleNo" />
                <Workbook.Column label="Route No " value="routeNo" />
                <Workbook.Column label="Driver Id" value="driverId" />
                <Workbook.Column label="Driver Name" value="driverName" />
                <Workbook.Column
                  label="Distance Travelled"
                  value="distanceTravelled"
                />
                <Workbook.Column label="Duration" value="duration" />
              </Workbook.Sheet>
            </Workbook>
          </div>
        </div>
        <div style={{ marginTop: "10px" }}>
          {this.props.FleetUptimeMangementApi?.FleetUptimeCurrentViewApi
            ?.loading ? (
            <div className="loader-overlay">
              <div className="loader-container">
                <ReactLoader />
              </div>
            </div>
          ) : null}
          <ReactTable
            NoDataComponent={() => null}
            pageSizeOptions={[20, 30, 50, 80, 100, 130, 200, 500]}
            style={{
              height: "450px",
              marginTop: "0%",
              marginLeft: "14px",
              marginRight: "10px",
            }}
            data={this.state.filteredData}
            showPagination={true}
            columns={[
              {
                Header: "Sl. No.",
                id: "serialNumber",
                filterable: false,
                resizable: false,
                width: 90,
                Cell: (row) => {
                  return <div>{row.index + 1}</div>;
                },
              },
              {
                Header: "Vehicle Number",
                id: "vehicleNo",
                accessor: (d) => d.vehicleNo,
                width: 200,
                filterAll: true,
                resizable: false,
              },
              {
                Header: "Route No.",
                id: "routeNo",
                accessor: (d) => d.routeNo,
                width: 200,

                filterAll: true,
                resizable: false,
              },

              {
                Header: "Driver ID",
                id: "driverId",
                accessor: (d) => d.driverId,
                width: 230,

                resizable: false,
              },
              {
                Header: "Driver Name",
                id: "driverName",
                accessor: (d) => d.driverName,
                width: 230,

                resizable: false,
              },
              {
                Header: "Distance Travelled (Kms)",
                id: "distanceTravelled",
                accessor: (d) => d.distanceTravelled,
                width: 200,

                filterAll: true,
                resizable: false,
              },

              {
                Header: "Duration",
                id: "duration",
                accessor: (d) => d.duration,
                width: 230,

                filterAll: true,
                resizable: false,
              },
            ]}
            defaultSorted={[
              {
                id: "serialNumber",
                asc: true,
              },
            ]}
            defaultPageSize={50}
            className="-striped -highlight"
          >
            {(state, makeTable, instance) => {
              let recordsInfoText = "";

              const { filtered, pageRows, pageSize, sortedData, page } = state;

              if (sortedData && sortedData.length > 0) {
                let isFiltered = filtered.length > 0;

                let totalRecords = sortedData.length;

                let recordsCountFrom = page * pageSize + 1;

                let recordsCountTo = recordsCountFrom + pageRows.length - 1;

                if (isFiltered)
                  recordsInfoText = `${recordsCountFrom}-${recordsCountTo} of ${totalRecords} filtered records`;
                else
                  recordsInfoText = `${recordsCountFrom}-${recordsCountTo} of ${totalRecords} records`;
              } else recordsInfoText = "No records";

              return (
                <div className="main-grid">
                  <div className="above-table text-right">
                    <div className="col-sm-12">
                      <span
                        style={{ marginLeft: "15px" }}
                        className="records-info"
                      >
                        {recordsInfoText}
                      </span>
                    </div>
                  </div>

                  {makeTable()}
                </div>
              );
            }}
          </ReactTable>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps, {
  getFleetUptimeCurrentViewAPI,
})(withRouter(FleetUptimeCurrentView));
