import React, { Component } from "react";
import ReactTooltip from "react-tooltip";
import { matchSorter } from "match-sorter";
import vehicleMapDashboardApiActions from "../../redux/vehicle_map_dashboard/actions";

import { connect } from "react-redux";
import { withRouter } from "react-router";
import "../summary/index.scss";
import ReactLoader from "../../component/react-loader/react-loader.component";
import ReactTable from "react-table";
import Map from "../../container/map";
import Workbook from "react-excel-workbook";
import "react-table/react-table.css";

let start_date = "";
let end_date = "";
let duration = "";
let singleVehicle = [];

const { getVehicleMapDashboardAPI } = vehicleMapDashboardApiActions;
class Summary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      filteredDatas: [],
      inputSearch: "",
      vehicleClicked: false,
    };
    this.getSummaryDetails = this.getSummaryDetails.bind(this);
  }

  componentDidMount() {
    start_date = sessionStorage.getItem("start_timestamp");
    end_date = sessionStorage.getItem("end_timestamp");
    duration = sessionStorage.getItem("duration");
    this.getSummaryDetails();
    sessionStorage.setItem("summary", true);
  }

  getSummaryDetails = async () => {
    let passValue = sessionStorage.getItem("vehicle_status");
    let payLoad = {
      api_key: "MAP_DASHBOARD_VEHICLE",
      status: "ALL",
      customerId: sessionStorage.getItem("customerId"),
    };

    await this.props.getVehicleMapDashboardAPI(payLoad);
  };

  componentWillReceiveProps(nextProps) {
    //console.log("summary", nextProps);
    if (
      nextProps?.vehicleMapDashboardApi?.vehicleMapDashboardApi?.result?.data
        ?.responseData
    ) {
      this.setState({
        data: nextProps.vehicleMapDashboardApi.vehicleMapDashboardApi.result
          .data.responseData,
      });

      this.setState({
        filteredDatas:
          nextProps.vehicleMapDashboardApi.vehicleMapDashboardApi.result.data
            .responseData,
      });
    }
  }

  // mapViewClicked = () => {
  //   console.log("mapViewClicked", this.state.data);

  // };

  setdata = (data) => {
    // let rowData = this.params;
    // let i = rowData.rowIndex;
    //this.setState({ addModalshow: true, selecteddata: data });
    // console.log("data",data.original)
    sessionStorage.setItem("map", true);
    this.setState({ vehicleClicked: true });
    singleVehicle.push(data.original);
  };

  componentWillUnmount() {
    this.setState({ vehicleClicked: false });
    singleVehicle.splice(0, singleVehicle.length);
    sessionStorage.removeItem("summary");
  }

  handleSearchSummary = (event) => {
    const search = event.target.value.toLowerCase();
    this.setState({
      inputSearch: search,
      filteredDatas: this.state.data.filter(
        (item) =>
          (item.status && item.status.toLowerCase().includes(search)) ||
          (item.reg_no && item.reg_no.toLowerCase().includes(search))
      ),
    });
  };

  getLoader = () => (
    <div className="loader-overlay">
      <div className="loader-container">
        <ReactLoader />
      </div>
    </div>
  );
  render() {
    let { inputSearch } = this.state;
    return (
      <div>
        {this.state.vehicleClicked === false ? (
          <div className="summary-table">
            {this.props.vehicleMapDashboardApi &&
              this.props.vehicleMapDashboardApi.vehicleMapDashboardApi &&
              this.props.vehicleMapDashboardApi.vehicleMapDashboardApi
                .loading &&
              this.getLoader()}

            <div className="search-summary-report ">
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                }}
              >
                <input
                  className="search-summary-report-input"
                  type="text"
                  id="filter"
                  placeholder="Search Table ( Status / Reg No. )"
                  value={inputSearch || ""}
                  onChange={this.handleSearchSummary}
                />
              </form>

              <div>
                <Workbook
                  filename="summary-Report.xlsx"
                  element={
                    <div
                      className="summary_report-download"
                      data-tip
                      data-for="registerTip"
                    >
                      Export
                      <img
                        src={require("../../assets/images/export/export.png")}
                        alt=""
                        className="summary_report-download-img"
                      />
                      <ReactTooltip id="registerTip" place="top" effect="solid">
                        Download
                      </ReactTooltip>
                    </div>
                  }
                >
                  <Workbook.Sheet
                    data={this.state.filteredDatas}
                    name="summary-Report"
                  >
                    <Workbook.Column label="Status" value="status" />
                    <Workbook.Column label="Reg No" value="reg_no" />
                    <Workbook.Column label="Pilot Name" value="driverName" />
                    <Workbook.Column label="Route Name" value="routeName" />
                    <Workbook.Column
                      label="Distance Travelled"
                      value="distanceTravelled"
                    />
                      <Workbook.Column label="Current SOC (%)" value="soc" />
                    <Workbook.Column label="ODO" value="odo" />
                    <Workbook.Column label="Speed" value="speed" />
                  
                    <Workbook.Column
                      label="Power Consumption"
                      value="powerConsumption"
                    />
                    <Workbook.Column
                      label="Last Communicated Time"
                      value="current_timestamp"
                    />
                    <Workbook.Column label="Location" value="location" />
                  </Workbook.Sheet>
                </Workbook>
              </div>
            </div>
            <ReactTooltip place="left" type="success" effect="solid" />
            <ReactTable
              NoDataComponent={() => null}
              pageSizeOptions={[20, 30, 50, 80, 90, 100, 125, 130, 200, 500]}
              style={{
                height: "460px",
                marginTop: "0%",
                marginRight: "25px",
                marginLeft: "8px",
                //direction:"rtl",
                //overflowX:"scroll"
                //horizontalDirection:"rtl"
                // width: "1000px",
              }}
              data={this.state.filteredDatas}
              // filterable
              // defaultFilterMethod={(filter, row) =>
              //   String(row[filter.id]) === filter.value
              // }
              columns={[
                {
                  Header: "S.No",
                  id: "serialNumber",
                  filterable: false,
                  width: 90,
                  Cell: (row) => {
                    return <div>{row.index + 1}</div>;
                  },
                },

                {
                  Header: "Action",
                  Cell: (props) => (
                    <a
                      className="onclick-action"
                      //data={props}
                      // style="text-decoration: none;"
                      href="#"
                      //style="text-decoration:none;"
                      target=""
                      onClick={() => this.setdata(props)}
                    >
                      <img
                        src={require("../../assets/images/Icon awesome-map-marked-alt/summary-1.jpg")}
                        alt=""
                      />{" "}
                      VIEW ON MAP
                    </a>
                  ),

                  // filterAll: true,
                  width: 155,
                  resizable: false,
                },
                {
                  Header: "Status",
                  id: "status",
                  accessor: (d) => d.status,
                  Cell: (rows) => {
                    /* Add data-tip */
                    return <span title={rows.value}>{rows.value}</span>;
                  },
                  filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, {
                      keys: ["status"],
                    }),

                  width: 100,
                  resizable: false,
                },

                {
                  Header: "Reg No.",
                  id: "reg_no",
                  accessor: (d) => d.reg_no,
                  filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, {
                      keys: ["reg_no"],
                    }),

                  width: 140,
                  resizable: false,
                },
                {
                  Header: "Pilot Name",
                  id: "driverName",
                  accessor: (d) => d.driverName,
                  filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, {
                      keys: ["driverName"],
                    }),
                  width: 140,
                  resizable: false,
                },
                {
                  Header: "Route Name",
                  id: "routeNumber",
                  accessor: (d) => d.routeNumber,
                  filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, {
                      keys: ["routeNumber"],
                    }),
                 width: 140,
                  resizable: false,
                },
                {
                  Header: "Distance Travelled (Km)",
                  id: "distanceTravelled",
                  accessor: (d) => d.distanceTravelled,
                  filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, {
                      keys: ["distanceTravelled"],
                    }),
                  //filterAll: true,
                  width: 170,
                  resizable: false,
                },

                {
                  Header: "Current SOC (%)",
                  id: "soc",
                  accessor: (d) => d.soc,
                  filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, {
                      keys: ["soc"],
                    }),
                  //filterAll: true,
                  width: 150,
                  resizable: false,
                },

                {
                  Header: "ODO",
                  id: "odo",
                  accessor: (d) => d.odo,
                  filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, {
                      keys: ["odo"],
                    }),

                  width: 80,
                  resizable: false,
                },

                {
                  Header: "Speed",
                  id: "speed",
                  accessor: (d) => d.speed,
                  filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, {
                      keys: ["speed"],
                    }),

                  width: 80,
                  resizable: false,
                },
                {
                  Header: "Power Consumption (kwh)",
                  id: "powerConsumption",
                  accessor: (d) => d.powerConsumption,
                  filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, {
                      keys: ["powerConsumption"],
                    }),
                  //filterAll: true,
                  width: 180,
                  resizable: false,
                },
                {
                  Header: "Last Communicated Time",
                  id: "current_timestamp",
                  accessor: (d) => d.current_timestamp,
                  filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, {
                      keys: ["current_timestamp"],
                    }),
                  //filterAll: true,
                  width: 210,
                  resizable: false,
                },

                {
                  Header: "Location",
                  id: "location",
                  accessor: (d) => d.location,
                  Cell: (rows) => {
                    /* Add data-tip */
                    return <span title={rows.value}>{rows.value}</span>;
                  },
                  filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, {
                      keys: ["location"],
                    }),
                  //filterAll: true,
                  width: 220,
                  resizable: false,
                },
              ]}
              defaultSorted={[
                {
                  id: "serialNumber",
                  asc: true,
                },
              ]}
              // data={this.state.sosData} // should default to []
              defaultPageSize={50}
              className="-striped -highlight"
            >
              {(state, makeTable, instance) => {
                let recordsInfoText = "";

                const { filtered, pageRows, pageSize, sortedData, page } =
                  state;

                if (sortedData && sortedData.length > 0) {
                  let isFiltered = filtered.length > 0;

                  let totalRecords = sortedData.length;

                  let recordsCountFrom = page * pageSize + 1;

                  let recordsCountTo = recordsCountFrom + pageRows.length - 1;

                  if (isFiltered)
                    recordsInfoText = `${recordsCountFrom}-${recordsCountTo} of ${totalRecords} filtered records`;
                  else
                    recordsInfoText = `${recordsCountFrom}-${recordsCountTo} of ${totalRecords} records`;
                } else recordsInfoText = "No records";

                return (
                  <div className="main-grid">
                    <div className="col-sm-12">
                      <span className="records-info">{recordsInfoText}</span>
                    </div>

                    {makeTable()}
                  </div>
                );
              }}
            </ReactTable>
          </div>
        ) : (
          <div>{<Map clickedVehicle={singleVehicle} />}</div>
        )}
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    ...state,
    // chargeSummary: state.chargeSummary,
  };
};

export default connect(mapStateToProps, {
  getVehicleMapDashboardAPI,
})(withRouter(Summary));
