import React, { Component } from "react";
import { addDays } from "date-fns";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import ReactLoader from "../../component/react-loader/react-loader.component";
import moment from "moment";
import DatePicker from "react-datepicker";
import BackToScreen from "../../component/back_to_screen";
import vehicleSummaryReportApiActions from "../../redux/vehicle_summary_report/actions";
import VehicleSummaryReport from "../vehicle_summary_report";
import VehicleSummaryGraph from "../vehicle_summary_graph";
import "./index.scss";
import "react-datepicker/dist/react-datepicker.css";

const timestamp = new Date().getTime();
const yesterday = timestamp - 24 * 60 * 60 * 1000;
var startTimestamp = new Date(yesterday);
startTimestamp.setHours(0);
startTimestamp.setMinutes(0);
startTimestamp.setSeconds(0);

var endTimestamp = new Date();
endTimestamp.setHours(23);
endTimestamp.setMinutes(59);
endTimestamp.setSeconds(58);

const { getvehicleSummaryReportAPI, getvehicleSummaryGraphAPI } =
  vehicleSummaryReportApiActions;
class VehicleSummaryReportToggle extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      // start_date: lastDay,
      // end_date: now,
      start_date: startTimestamp,
      end_date: endTimestamp,
      inputSearch: "",
      filteredDatas: [],
      editingfields: {},
      toggleValue: false,
      summarytoggles: false,
    };
    this.backtodashboardPage = this.backtodashboardPage.bind(this);
    this.summarytoggleStates = this.summarytoggleStates.bind(this);
  }

  componentDidMount() {
    //getting all the data
    this.getVehicleSummaryReport();
    this.getVehicleSummaryGraphData();
  }

  getVehicleSummaryReport() {
    const { start_date, end_date } = this.state;

    if (start_date < end_date) {
      this.setState({ loading: true });
      const payload = {
        user_id: sessionStorage.getItem("userId"),
        customerId: sessionStorage.getItem("customerId"),
        start_timestamp: moment(start_date).format("YYYY-MM-DD HH:mm:ss"),
        end_timestamp: moment(end_date).format("YYYY-MM-DD HH:mm:ss"),
      };
      this.props.getvehicleSummaryReportAPI(payload);
    } else if (start_date > end_date) {
      alert("End Date should be greater than Start Date");
    } else {
      alert("End Date should be greater than Start Date");
    }
  }

  getVehicleSummaryGraphData() {
    const { start_date, end_date } = this.state;

    if (start_date < end_date) {
      this.setState({ loading: true });
      const payload = {
        user_id: sessionStorage.getItem("userId"),
        customerId: sessionStorage.getItem("customerId"),
        start_timestamp: moment(start_date).format("YYYY-MM-DD HH:mm:ss"),
        end_timestamp: moment(end_date).format("YYYY-MM-DD HH:mm:ss"),
      };
      this.props.getvehicleSummaryGraphAPI(payload);
    } else if (start_date > end_date) {
      alert("End Date should be greater than Start Date");
    } else {
      alert("End Date should be greater than Start Date");
    }
  }

  handleDateChange(evt) {
    const value = evt.target.value;

    const name = evt.target.name;
    this.setState((prevState) => ({
      editingfields: {
        // object that we want to update

        ...prevState.editingfields, // keep all other key-value pairs
        [name]: moment(value).format("YYYY-MM-DD HH:mm:00"),
        // update the value of specific key
      },
    }));
    this.setState({ [name]: value });
  }

  backtodashboardPage() {
    let tabItem = {
      tabIndex: 16,
      tabName: "Dashboard",
    };
    this.props.selectedTab(null, tabItem);
  }

  onSubmit = (e) => {
    this.getVehicleSummaryReport();
    this.getVehicleSummaryGraphData();
  };

  summarytoggleStates() {
    this.setState({
      summarytoggles: !this.state.summarytoggles,
    });
  }

  render() {
    //  console.log("timestamp",this.state.start_date)
    return (
      <div>
        {this.props.VehicleSummaryReportApi?.vehicleSummaryReportApi
          ?.loading ? (
          <div className="loader-overlay">
            <div className="loader-container">
              <ReactLoader />
            </div>
          </div>
        ) : null}
        <div className="fleet-health-backward">
          <BackToScreen routingCall={this.backtodashboardPage} />
          <h3 className="vehicle_summary_report-head">Vehicle Summary</h3>
        </div>

        <div className="vehicle_summary_report_toggle_div">
          <div className="vehicle_summary_toggle_labels">
            <label className="vehicle_summary_toggle_labels_style">
              Start Date:
            </label>

            <DatePicker
              autocomplete="off"
              name="start_date"
              selected={this.state.start_date}
              maxDate={moment().toDate()}
              minDate={addDays(new Date(), -90)}
              //value={this.state.editingfields.start_date}

              value={
                this.state.editingfields.start_date === "undefined"
                  ? this.state.start_date.setHours(0, 0, 0)
                  : this.state.editingfields.start_date
              }
              onChange={(value) =>
                this.handleDateChange({
                  target: { name: "start_date", value },
                })
              }
              timeInputLabel="Time:"
              dateFormat="yyyy-MM-dd HH:mm:00"
              showTimeInput
            />
            <label className="vehicle_summary_toggle_labels_style-1">
              End Date:
            </label>
            <DatePicker
              autocomplete="off"
              //maxDate={moment().toDate()}
              name="end_date"
              selected={this.state.end_date}
              maxDate={moment().toDate()}
              minDate={addDays(new Date(), -90)}
              //value={this.state.editingfields.end_date}
              value={
                this.state.editingfields.end_date === "undefined"
                  ? this.state.end_date.setHours(23, 59, 59)
                  : this.state.editingfields.end_date
              }
              onChange={(value) =>
                this.handleDateChange({
                  target: { name: "end_date", value },
                })
              }
              timeInputLabel="Time:"
              dateFormat="yyyy-MM-dd HH:mm:00"
              showTimeInput
            />
          </div>

          <div>
            <button
              className="vehicle_summary_toggle_button-submit"
              onClick={this.onSubmit}
            >
              <i className="fa fa-check" style={{ paddingRight: "5px" }}></i>
              Submit
            </button>
          </div>
          <div>
            <button
              className="vehicle_summary_toggle_button-submit-1"
              onClick={this.reset}
            >
              <i className="fa fa-refresh" style={{ paddingRight: "5px" }}></i>{" "}
              Reset
            </button>
          </div>

          <div className="switch-field-vehicle-toggle">
            <input
              type="radio"
              id="switch_left"
              name="switchToggle"
              value={this.props.leftLabel}
              onChange={this.summarytoggleStates}
              checked={!this.state.summarytoggles}
            />
            <label htmlFor="switch_left">
              {" "}
              {/* <img
                    src={require("../../assets/images/summary/summary.png")}
                    className="switch-img-1"
                    alt=""
                  /> */}
              List View
            </label>

            <input
              type="radio"
              id="switch_right"
              name="switchToggle"
              value={this.props.rightLabel}
              onChange={this.summarytoggleStates}
              checked={this.state.summarytoggles}
            />
            <label htmlFor="switch_right">
              {" "}
              {/* <img
                    src={require("../../assets/images/summary/summary.png")}
                    className="switch-img"
                    alt=""
                  /> */}
              Graph View
            </label>
          </div>
        </div>
        <div>
          {this.state.summarytoggles === true ? (
            <VehicleSummaryGraph />
          ) : (
            <VehicleSummaryReport />
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps, {
  getvehicleSummaryReportAPI,
  getvehicleSummaryGraphAPI,
})(withRouter(VehicleSummaryReportToggle));
