const QuicksightApiActions = {
    GET_QUICKSIGHT: "GET_QUICKSIGHT",
    GET_QUICKSIGHT_SUCCESS: "GET_QUICKSIGHT_SUCCESS",
    GET_QUICKSIGHT_FAILURE: "GET_QUICKSIGHT_FAILURE",
  
    getQuicksightAPI: (data) => ({
      type: QuicksightApiActions.GET_QUICKSIGHT,
      payload: { data },
    }),
  
    getQuicksightAPISuccess: (saveStatusCode, result) => ({
      type: QuicksightApiActions.GET_QUICKSIGHT_SUCCESS,
      saveStatusCode,
      result,
    }),
  
    getQuicksightApiFailure: (saveStatusCode, message) => ({
      type: QuicksightApiActions.GET_QUICKSIGHT_FAILURE,
      saveStatusCode,
      message,
    }),
  };
  
  export default QuicksightApiActions;
  