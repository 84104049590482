import React, { Component } from "react";
import "./index.scss";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import moment from "moment";
import { addDays } from "date-fns";
import ReactLoader from "../react-loader/react-loader.component";
import driverMonitoringviewApiActions from "../../redux/driver_monitoring_list_view/actions";

const { getDriverMonitoringCalendarViewAPI } = driverMonitoringviewApiActions;

const mark = ["05-12-2022", "06-12-2022", "08-12-2022"];
const mark2 = ["01-12-2022", "02-12-2022", "07-12-2022"];

const today = new Date();
const maxDate = new Date(today.getFullYear(), today.getMonth() + 1, 0);
const threeMonthsAgo = new Date(today.getFullYear(), today.getMonth() - 3, 1);

var now = new Date();
now.setMonth(now.getMonth() - 3);
//now.setDate(now.getDate() - 3);

class DriverMonitoringCalanderView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      date: now,
      present: [],
      absent: [],
      driverName: "",
      start_date: now,
      end_date: now,
    };
  }

  componentDidMount() {
    this.getdriverCalendarDetails();
  }

  getdriverCalendarDetails = async () => {
    let payLoad = {
      user_id: sessionStorage.getItem("userId"),
      customerId: sessionStorage.getItem("customerId"),
      id: sessionStorage.getItem("driverMonitoringIDClicked"),
      // id: 5,
    };
    await this.props.getDriverMonitoringCalendarViewAPI(payLoad);
  };

  componentWillReceiveProps(nextProps) {
    if (
      nextProps?.DriverMonitoringViewApi?.driverMonitoringCalendarViewApi
        ?.result?.data?.responseData
    ) {
      this.setState({
        present:
          nextProps.DriverMonitoringViewApi.driverMonitoringCalendarViewApi
            .result.data.responseData.present,
      });

      this.setState({
        absent:
          nextProps.DriverMonitoringViewApi.driverMonitoringCalendarViewApi
            .result.data.responseData.absent,
      });

      this.setState({
        driverName:
          nextProps.DriverMonitoringViewApi.driverMonitoringCalendarViewApi
            .result.data.responseData.driverName,
      });
    }
  }

  componentWillUnmount() {
    sessionStorage.removeItem("calendarStart");
    sessionStorage.removeItem("calendarEnd");
  }

  onChange = () => {
    const { start_date, end_date, key } = this.state;
    this.setState({
      start_date: moment()
        .subtract(1, "months")
        .endOf("month")
        .startOf("week")
        .format("YYYY/MM/DD"),
      end_date: moment()
        .add(1, "months")
        .startOf("month")
        .endOf("week")
        .format("YYYY/MM/DD"),
      key: "selection",
    });
  };
  render() {
    
    //   console.log("driverdetails", this.state.absent);

    //const startDate = new Date(new Date().getFullYear(), new Date().getMonth(), 1)
    // const endDate = new Date(new Date().getFullYear(), new Date().getMonth(), 1)
    //const startDate = sessionStorage.getItem("calendarStart")?new Date(sessionStorage.getItem("calendarStart")):new Date(new Date().getFullYear(), new Date().getMonth(), 1)
    const startDate = 
    // new Date(
    //   new Date().getFullYear(),
    //   new Date().getMonth(),
    //   1
    // );

    new Date( new Date().getFullYear(),  new Date().getMonth() - 3, 1);
    
    const endDate =
      sessionStorage.getItem("calendarEnd") != ""
        ? new Date(sessionStorage.getItem("calendarEnd"))
        : new Date("2023-01-31");

       
    return (
      <div className="calendar-container">
        {this.props.DriverMonitoringViewApi?.driverMonitoringCalendarViewApi
          ?.loading ? (
          <div className="master-driver-loader-overlay">
            <div className="master-driver-loader-container">
              <ReactLoader />
            </div>
          </div>
        ) : null}
        <p className="monitoring-calendar-driver">
          {" "}
          {this.state.driverName} | Pilot{" "}
        </p>
        <Calendar
         //activeStartDate={startDate}
          onChange={this.onChange}
          view="month"
          //value={this.state.date}
          calendarType="US"
          //color

          tileClassName={({ date, view }) => {
            if (
              this.state.absent.find(
                (x) => x === moment(date).format("DD-MM-YYYY")
              )
            ) {
              return "highlight_absent";
            }
            if (
              this.state.present.find(
                (x) => x === moment(date).format("DD-MM-YYYY")
              )
            ) {
              return "highlight_present";
            } else {
              return "higlight_black";
            }
          }}

          // tileClassName={({ date, view }) => {
          //   if (view === 'month' > date) {
          //     // If the current view is a month view and the date is in the future, return an empty string
          //     return '';
          //   }
          //   const dateStr = moment(date).format('DD-MM-YYYY');
          //   const threeMonthsAgo = moment().subtract(3, 'months');
          //   if (moment(date).isBefore(threeMonthsAgo, 'day')) {
          //     // If the date is more than 3 months ago, return an empty string
          //     return '';
          //   } else if (this.state.absent.includes(dateStr)) {
          //     // If the date is in the absent array, add the "highlight_absent" class
          //     return 'highlight_absent';
          //   } else if (this.state.present.includes(dateStr)) {
          //     // If the date is in the present array, add the "highlight_present" class
          //     return 'highlight_present';
          //   } else {
          //     // Otherwise, add the "highlight_black" class
          //     return 'highlight_black';
          //   }
          // }}
        
          
          
          
          

          // tileClassName={({ date, view }) => {
            // if (view === 'month'  > date) {
            //   // If the current view is a month view and the date is in the future, return an empty string
            //   return '';
            // }
          //   const dateStr = moment(date).format('DD-MM-YYYY');
          //   if (this.state.absent.includes(dateStr)) {
          //     // If the date is in the absent array, add the "highlight_absent" class
          //     return 'highlight_absent';
          //   } else if (this.state.present.includes(dateStr)) {
          //     // If the date is in the present array, add the "highlight_present" class
          //     return 'highlight_present';
          //   } else {
          //     // Otherwise, add the "highlight_black" class
          //     return 'highlight_black';
          //   }
          // }}


          // tile style
          //   tileContent={({ activeStartDate, date, view }) => {
          //     return view === "month" &&
          //       mark.find((x) => x === moment(date).format("DD-MM-YYYY")) ? (
          //       <p
          //         className="calendar_tile_absent calendar_tile_absent_bg_img"
          //         onMouseEnter={
          //           //do whatever you want
          //           console.log("hi")
          //         }
          //       >
          //         Absent
          //       </p>
          //     ) : (
          //       <p
          //         className="calendar_tile_present calendar_tile_present_bg_img"
          //         // onMouseEnter={
          //         //   //do whatever you want
          //         //   console.log("hi")
          //         // }
          //       >
          //         Present
          //       </p>
          //     );
          //   }}
          //  tileContent="     | Present"
          // tileDisabled={({ date }) =>
          //   date.getDay() === 0 || date.getDay() === 6
          // }

          //   tileDisabled={({ date, view }) => {
          //     return date.getDay() === 0 ? (
          //       <p className="calendar_tile_absent calendar_tile_absent_bg_img">
          //         Weekend
          //       </p>
          //     ) : null;
          //   }}
          maxDate={maxDate}
           minDate={threeMonthsAgo}
        />
      </div>
    );
  }
}



const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps, {
  getDriverMonitoringCalendarViewAPI,
})(withRouter(DriverMonitoringCalanderView));
