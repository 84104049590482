import actions from "./actions";
import { combineReducers } from "redux";

const initStateFleetHealthPanelView = {
  loading: false,
  result: null,
  error: false,
};

const initStateFleetHealthView = {
  loading: false,
  result: null,
  error: false,
};

const initStateFleetUptimeCurrentView = {
  loading: false,
  result: null,
  error: false,
};

const initStateFleetHealthDetails = {
  loading: false,
  result: null,
  error: false,
};
const initStateFleetUptimeHistoric = {
  loading: false,
  result: null,
  error: false,
};

const initStateFleetUptimeHistoricBelow = {
  loading: false,
  result: null,
  error: false,
};

const initStateFleetUptimeHistoricgraph = {
  loading: false,
  result: null,
  error: false,
};

function FleetHealthPanelViewApi(state = initStateFleetHealthPanelView, action) {
  switch (action.type) {
    case actions.GET_FLEETHEALTHPANELVIEW:
      return {
        ...state,
        loading: true,
      };
    case actions.GET_FLEETHEALTHPANELVIEW_SUCCESS:
      return {
        ...state,
        loading: false,
        result: action.result,
      };
    case actions.GET_FLEETHEALTHPANELVIEW_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        result: {
          errorResponse: action.saveStatusCode,
          message: action.message,
        },
      };
    default:
      return state;
  }
}










function FleetHealthViewApi(
  state = initStateFleetHealthView,
  action
) {
  switch (action.type) {
    case actions.GET_FLEETHEALTHVIEW:
      return {
        ...state,
        loading: true,
      };
    case actions.GET_FLEETHEALTHVIEW_SUCCESS:
      return {
        ...state,
        loading: false,
        result: action.result,
      };
    case actions.GET_FLEETHEALTHVIEW_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        result: {
          errorResponse: action.saveStatusCode,
          message: action.message,
        },
      };
    default:
      return state;
  }
}



function FleetUptimeCurrentViewApi(
  state = initStateFleetUptimeCurrentView,
  action
) {
  switch (action.type) {
    case actions.GET_FLEETUPTIMECURRENTVIEW:
      return {
        ...state,
        loading: true,
      };
    case actions.GET_FLEETUPTIMECURRENTVIEW_SUCCESS:
      return {
        ...state,
        loading: false,
        result: action.result,
      };
    case actions.GET_FLEETUPTIMECURRENTVIEW_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        result: {
          errorResponse: action.saveStatusCode,
          message: action.message,
        },
      };
    default:
      return state;
  }
}



function FleetHealthdetails(
  state = initStateFleetHealthDetails,
  action
) {
  switch (action.type) {
    case actions.GET_FLEETHEALTHDETAILS:
      return {
        ...state,
        loading: true,
      };
    case actions.GET_FLEETHEALTHDETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        result: action.result,
      };
    case actions.GET_FLEETHEALTHDETAILS_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        result: {
          errorResponse: action.saveStatusCode,
          message: action.message,
        },
      };
    default:
      return state;
  }
}



function FleetUptimeHistoric(
  state = initStateFleetUptimeHistoric,
  action
) {
  switch (action.type) {
    case actions.GET_FLEETUPTIMEHISTORIC:
      return {
        ...state,
        loading: true,
      };
    case actions.GET_FLEETUPTIMEHISTORIC_SUCCESS:
      return {
        ...state,
        loading: false,
        result: action.result,
      };
    case actions.GET_FLEETUPTIMEHISTORIC_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        result: {
          errorResponse: action.saveStatusCode,
          message: action.message,
        },
      };
    default:
      return state;
  }
}

function FleetUptimeHistoricBelow(
  state = initStateFleetUptimeHistoricBelow,
  action
) {
  switch (action.type) {
    case actions.GET_FLEETUPTIMEHISTORICBELOW:
      return {
        ...state,
        loading: true,
      };
    case actions.GET_FLEETUPTIMEHISTORICBELOW_SUCCESS:
      return {
        ...state,
        loading: false,
        result: action.result,
      };
    case actions.GET_FLEETUPTIMEHISTORICBELOW_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        result: {
          errorResponse: action.saveStatusCode,
          message: action.message,
        },
      };
    default:
      return state;
  }
}


function FleetUptimeHistoricGraph(
  state = initStateFleetUptimeHistoricgraph,
  action
) {
  switch (action.type) {
    case actions.GET_FLEETUPTIMEHISTORIC_GRAPH:
      return {
        ...state,
        loading: true,
      };
    case actions.GET_FLEETUPTIMEHISTORIC_GRAPH_SUCCESS:
      return {
        ...state,
        loading: false,
        result: action.result,
      };
    case actions.GET_FLEETUPTIMEHISTORIC_GRAPH_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        result: {
          errorResponse: action.saveStatusCode,
          message: action.message,
        },
      };
    default:
      return state;
  }
}

export default combineReducers({
    FleetHealthPanelViewApi,
    FleetHealthViewApi,
    FleetUptimeCurrentViewApi,
    FleetHealthdetails,
    FleetUptimeHistoric,
    FleetUptimeHistoricBelow,
    FleetUptimeHistoricGraph,
});
