import React, { Component } from "react";
import "./index.scss";
import ReactTable from "react-table";
import "react-table/react-table.css";
import { connect } from "react-redux";
import { withRouter } from "react-router";
//import { matchSorter } from "match-sorter";
//import BackToScreen from "../../component/back_to_screen";
import ReactTooltip from "react-tooltip";
import ReactLoader from "../../component/react-loader/react-loader.component";
import moment from "moment";
import { addDays } from "date-fns";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

import Workbook from "react-excel-workbook";

import tripMasterDetailsActions from "../../redux/trip_master_details/actions";

const { getTripMasterSubmissionAPI } = tripMasterDetailsActions;
var lastDay = new Date();
lastDay.setHours(lastDay.getHours() - 6);
var now = new Date();
now.setDate(now.getDate());

var startTimestamp = new Date();
startTimestamp.setHours(0);
startTimestamp.setMinutes(0);
startTimestamp.setSeconds(0)

var endTimestamp = new Date();
endTimestamp.setHours(23);
endTimestamp.setMinutes(59);
endTimestamp.setSeconds(58)
class TripSubmission extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // start_date: lastDay,
      // end_date: now,
      start_date: startTimestamp,
      end_date: endTimestamp,
      submissiondata: [],
      filterData: [],
      searchInput: "",
      editingfields: {},
    };
    this.backtodashboardPage = this.backtodashboardPage.bind(this);
  }

  componentDidMount() {
    this.getTripSubmissionReport();
  }

  getTripSubmissionReport() {
    const { start_date, end_date } = this.state;
    this.setState({ loading: true });
    if (start_date < end_date) {
    const payload = {
      //user_id: "1",
      user_id : sessionStorage.getItem("userId"),
      customerId: sessionStorage.getItem("customerId"),
      start_timestamp: moment(start_date).format("YYYY-MM-DD HH:mm:ss"),
      end_timestamp: moment(end_date).format("YYYY-MM-DD HH:mm:ss"),
    };
    this.props.getTripMasterSubmissionAPI(payload);
  } else if (start_date > end_date) {
    alert("End Date should be greater than Start Date");
  } else {
    alert("End Date should be greater than Start Date");
  }
  }
  componentWillReceiveProps(nextProps) {
    //console.log("tripsubmissionreport", nextProps);
    if (
      nextProps?.tripMasterDetailsApi?.tripmasterSubmissionApi?.result?.data
        ?.responseData
    ) {
      this.setState({
        submissiondata:
          nextProps.tripMasterDetailsApi?.tripmasterSubmissionApi.result.data
            .responseData,
      });
      this.setState({
        filterData:
          nextProps.tripMasterDetailsApi?.tripmasterSubmissionApi.result.data
            .responseData,
      });
    }
  }

  handleDateChange(evt) {
    const value = evt.target.value;

    const name = evt.target.name;
    this.setState((prevState) => ({
      editingfields: {
        // object that we want to update

        ...prevState.editingfields, // keep all other key-value pairs
        [name]: moment(value).format("YYYY-MM-DD HH:mm:00"),
        // update the value of specific key
      },
    }));
    this.setState({ [name]: value });
  }

  onSubmit = (e) => {
    this.getTripSubmissionReport();
  };

  TripsubmissionreporthandleChange = (event) => {
    const search = event.target.value.toLowerCase();
    this.setState({
      searchInput: search,
      filterData: this.state.submissiondata.filter(
        (item) => item.username && item.username.toLowerCase().includes(search)
      ),
    });
  };

  backtodashboardPage() {
    let tabItem = {
      tabIndex: 16,
      tabName: "Dashboard",
    };
    this.props.selectedTab(null, tabItem);
  }

  render() {
    let { searchInput } = this.state;
    return (
      <div className="trip_submission">
        {this.props.tripMasterDetailsApi?.tripmasterSubmissionApi?.loading ? (
          <div className="loader-overlay">
            <div className="loader-container">
              <ReactLoader />
            </div>
          </div>
        ) : null}

        {/* <h3 className="trip_submission-head">Charger Report</h3> */}
        {/* </div> */}

        <div className="trip_submission_div">
          <label className="trip_submission_div_labels_style">
            Start Date:
          </label>

          <DatePicker
            autocomplete="off"
            name="start_date"
            //selected={this.state.start_date}

            selected={this.state.start_date}
            maxDate={moment().toDate()}
            minDate={addDays(new Date(), -90)}
            value={
              this.state.editingfields.start_date == "undefined"
                ? this.state.start_date.setHours(0, 0, 0)
                : this.state.editingfields.start_date
            }
            // value={this.state.editingfields.start_date}
            onChange={(value) =>
              this.handleDateChange({
                target: { name: "start_date", value },
              })
            }
            //defaultValue={moment(now).toDate()}
            timeInputLabel="Time:"
            dateFormat="yyyy-MM-dd HH:mm:00"
            showTimeInput
          />

          <label className="trip_submission_div_labels_style-1">
            End Date:
          </label>

          <DatePicker
            autocomplete="off"
            // valueDefault={null}
            //maxDate={moment().toDate()}
            name="end_date"
            //selected={this.state.end_date}
            selected={this.state.end_date}
            maxDate={moment().toDate()}
            minDate={addDays(new Date(), -90)}
            //value={this.state.editingfields.end_date}
            value={
              this.state.editingfields.end_date == "undefined"
                ? this.state.end_date.setHours(23, 59, 59)
                : this.state.editingfields.end_date
            }
            onChange={(value) =>
              this.handleDateChange({
                target: { name: "end_date", value },
              })
            }
            //defaultValue="01-01-2001 01:01:01"
            timeInputLabel="Time:"
            dateFormat="yyyy-MM-dd HH:mm:00"
            showTimeInput
          />

          <div>
            <button
              className="trip_submission_div_button-submit"
              onClick={this.onSubmit}
            >
              <i className="fa fa-check" style={{ paddingRight: "5px" }}></i>
              Submit
            </button>
          </div>
          <div>
            <button
              className="trip_submission_div_button-submit-1"
              onClick={this.reset}
            >
              <i className="fa fa-refresh" style={{ paddingRight: "5px" }}></i>{" "}
              Reset
            </button>
          </div>

          <div className="trip_submission_search">
            <form
              onSubmit={(e) => {
                e.preventDefault();
              }}
            >
              <input
                className="trip_submission_search-input"
                type="text"
                id="filter"
                placeholder="Search Table(User Name)"
                value={searchInput || ""}
                onChange={this.TripsubmissionreporthandleChange}
              />
            </form>
          </div>

          <div>
            <Workbook
              filename="trip_submission.xlsx"
              element={
                <div
                  className="trip_submission-download"
                  data-tip
                  data-for="registerTip"
                >
                  Export
                  <img
                    src={require("../../assets/images/export/export.png")}
                    alt=""
                    className="trip_submission-download-img"
                  />
                  <ReactTooltip id="registerTip" place="top" effect="solid">
                    Download
                  </ReactTooltip>
                </div>
              }
            >
              <Workbook.Sheet
                data={this.state.submissiondata}
                name="trip-submission-Report"
              >
                <Workbook.Column label="User ID" value="userid" />
                <Workbook.Column label="User Name" value="username" />
                <Workbook.Column label="Date" value="date" />
              </Workbook.Sheet>
            </Workbook>
          </div>
        </div>

        <div className="trip_submission_table">
          <ReactTable
            NoDataComponent={() => null}
            pageSizeOptions={[20, 30, 50, 80, 100, 130, 200, 500]}
            style={{
              height: "440px",
              marginTop: "0%",
              marginRight: "10px",
              marginLeft: "20px",
            }}
            data={this.state.filterData}
            showPagination={true}
            columns={[
              {
                Header: "S.No",
                id: "serialNumber",
                filterable: false,
                resizable: false,
                width: 90,
                Cell: (row) => {
                  return <div>{row.index + 1}</div>;
                },
              },
              {
                Header: "User ID",
                id: "userid",
                accessor: (d) => d.userid,
                width: 442,

                resizable: false,
              },
              {
                Header: "User Name",
                id: "username",
                accessor: (d) => d.username,
                width: 480,

                filterAll: true,
                resizable: false,
              },
              {
                Header: "Date",
                id: "date",
                accessor: (d) => d.date,
                width: 413,

                filterAll: true,
                resizable: false,
              },
            ]}
            defaultSorted={[
              {
                id: "serialNumber",
                asc: true,
              },
            ]}
            defaultPageSize={50}
            className="-striped -highlight"
          >
            {(state, makeTable, instance) => {
              let recordsInfoText = "";

              const { filtered, pageRows, pageSize, sortedData, page } = state;

              if (sortedData && sortedData.length > 0) {
                let isFiltered = filtered.length > 0;

                let totalRecords = sortedData.length;

                let recordsCountFrom = page * pageSize + 1;

                let recordsCountTo = recordsCountFrom + pageRows.length - 1;

                if (isFiltered)
                  recordsInfoText = `${recordsCountFrom}-${recordsCountTo} of ${totalRecords} filtered records`;
                else
                  recordsInfoText = `${recordsCountFrom}-${recordsCountTo} of ${totalRecords} records`;
              } else recordsInfoText = "No records";

              return (
                <div className="main-grid">
                  <div className="above-table text-right">
                    <div className="col-sm-12">
                      <span style={{marginLeft:"20px"}} className="records-info">{recordsInfoText}</span>
                    </div>
                  </div>

                  {makeTable()}
                </div>
              );
            }}
          </ReactTable>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps, {
  getTripMasterSubmissionAPI,
})(withRouter(TripSubmission));
