import React, { Component } from "react";
import ReactTable from "react-table";
import "./index.scss";
import "react-table/react-table.css";
import Workbook from "react-excel-workbook";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import ReactLoader from "../react-loader/react-loader.component";
import driverMonitoringviewApiActions from "../../redux/driver_monitoring_list_view/actions";
const { getDriverMonitoringViewAPI } = driverMonitoringviewApiActions;
let pilotName = "";
class DrivermonitoringListview extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      filteredData: [],
      searchInput: "",
    };
  }

  componentDidMount() {
    //getting all the data
    this.getDriverMonitoringListview();
  }

  getDriverMonitoringListview() {
    this.setState({ loading: true });
    const payload = {
      user_id: sessionStorage.getItem("userId"),
      customerId: sessionStorage.getItem("customerId"),
      id: sessionStorage.getItem("driverMonitoringIDClicked"),
    };
    this.props.getDriverMonitoringViewAPI(payload);
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      if (
        this.props.DriverMonitoringViewApi?.driverMonitoringViewApi?.result
          ?.data?.responseData?.length > 0
      ) {
        this.setState({
          data: this.props.DriverMonitoringViewApi.driverMonitoringViewApi
            .result.data.responseData,
        });
        pilotName =
          this.props.DriverMonitoringViewApi.driverMonitoringViewApi.result.data
            .responseData[0].driverName;
        sessionStorage.setItem("DriverClicked", pilotName);
        this.setState({
          filteredData:
            this.props.DriverMonitoringViewApi.driverMonitoringViewApi.result
              .data.responseData,
        });
      } else {
        this.setState({ data: [] });
      }
    }
  }

  handledrivermonitoringview = (event) => {
    const search = event.target.value.toLowerCase();
    this.setState({
      searchInput: search,
      filteredData: this.state.data.filter(
        (item) => item.status && item.status.toLowerCase().includes(search)
      ),
    });
  };

  render() {
    let { searchInput } = this.state;
    return (
      <div>
        {this.props.DriverMonitoringViewApi?.driverMonitoringViewApi
          ?.loading ? (
          <div className="master-driver-loader-overlay">
            <div className="master-driver-loader-container">
              <ReactLoader />
            </div>
          </div>
        ) : null}
        <div className="row ms-4 me-4">
          {/* <div className="col-6">
            <p className="driver-monitoring-driver"> {pilotName} | Pilot </p>
          </div> */}
          <div className="col-6 ms-1 d-flex">
            <form
              onSubmit={(e) => {
                e.preventDefault();
              }}
            >
              <input
                className="calanderListview-search-input"
                type="text"
                id="filter"
                placeholder="Search Table ( Status )"
                value={searchInput || ""}
                onChange={this.handledrivermonitoringview}
              />
            </form>

            <div>
              <Workbook
                filename="Trip-Monitoring-Report.xlsx"
                element={
                  <div className="driver-monitoring_div_button-submits">
                    Export
                    <img
                      src={require("../../assets/images/export/export.png")}
                      alt=""
                      className="driver-monitoring_div_button-submit-image"
                    />
                  </div>
                }
              >
                <Workbook.Sheet
                  data={this.state.filteredData}
                  name="Trip-Monitoring-Report"
                >
                  <Workbook.Column label="Date" value="date" />
                  <Workbook.Column label="Status" value="status" />
                  <Workbook.Column
                    label="Checked In Time"
                    value="checkedInTime"
                  />
                     <Workbook.Column
                    label="Checked Out Time"
                    value="checkedOutTime"
                  />
                  <Workbook.Column label="Total Hours" value="duration" />
                </Workbook.Sheet>
              </Workbook>
            </div>
          </div>
        </div>
        <div>
          <ReactTable
            NoDataComponent={() => null}
            pageSizeOptions={[20, 30, 50, 80, 100, 130, 200, 500]}
            style={{
              height: "450px",
              marginTop: "0%",
              marginLeft: "40px",
              marginRight: "40px",
            }}
            data={this.state.filteredData}
            showPagination={true}
            columns={[
              {
                Header: "S.No",
                id: "serialNumber",
                resizable: false,
                filterable: false,
                width: 90,
                Cell: (row) => {
                  return <div>{row.index + 1}</div>;
                },
              },
              {
                Header: "Date",
                id: "date",
                accessor: (d) => d.date,
                width: 230,
                resizable: false,
              },
              {
                Header: "Status",
                id: "status",
                accessor: (d) => d.status,
                width: 230,
                resizable: false,
              },
              {
                Header: "Check In",
                id: "checkedInTime",
                accessor: (d) => d.checkedInTime,
                width: 270,
                resizable: false,
              },

              {
                Header: "checkout",
                id: "checkedOutTime",
                accessor: (d) => d.checkedOutTime,
                width: 350,
                resizable: false,
              },
              {
                Header: "Total Hours",
                id: "duration",
                accessor: (d) => d.duration,
                width: 270,
                resizable: false,
              },
            ]}
            defaultSorted={[
              {
                id: "serialNumber",
                asc: true,
              },
          
            ]}
            defaultPageSize={50}
            className="-striped -highlight"
          >
            {(state, makeTable, instance) => {
              let recordsInfoText = "";

              const { filtered, pageRows, pageSize, sortedData, page } = state;

              if (sortedData && sortedData.length > 0) {
                let isFiltered = filtered.length > 0;

                let totalRecords = sortedData.length;

                let recordsCountFrom = page * pageSize + 1;

                let recordsCountTo = recordsCountFrom + pageRows.length - 1;

                if (isFiltered)
                  recordsInfoText = `${recordsCountFrom}-${recordsCountTo} of ${totalRecords} filtered records`;
                else
                  recordsInfoText = `${recordsCountFrom}-${recordsCountTo} of ${totalRecords} records`;
              } else recordsInfoText = "No records";

              return (
                <div className="main-grid">
                  <div className="col-sm-12">
                    <span className="records-info">{recordsInfoText}</span>
                  </div>

                  {makeTable()}
                </div>
              );
            }}
          </ReactTable>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps, {
  getDriverMonitoringViewAPI,
})(withRouter(DrivermonitoringListview));
