import React, { Component } from "react";
import "./index.scss";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import ReactTable from "react-table";
import "react-table/react-table.css";
//import moment from "moment";
import fleetUptimeMangementApiActions from "../../redux/uptime_mangement/actions";
const { getFleetUptimeHistoricAPI, getFleetUptimeHistoricBelowAPI } =
  fleetUptimeMangementApiActions;
class HistoricTableView extends Component {
  constructor(props) {
    super(props);
    this.messageTimeOut = null;
    //let loginId = User.getLoginId();
    this.state = {
      selTabs: 0,
      message: "",
      abovedata: [],
      belowdata: [],
      messageType: 0,
      tabs: [
        {
          tabIndex: 0,
          tabName: "Vehicle Above Target",
        },
        {
          tabIndex: 1,
          tabName: "Vehicle Below Target",
        },
      ],
    };

    this.tabClicks = this.tabClicks.bind(this);
  }

  tabClicks = (evt, tabItem) => {
    // const { selectedTab } = this.props;
    this.onClickCloseMessage();

    if (evt.target.parentElement) {
      let tabs = evt.target.parentElement.getElementsByClassName(
        "fleet-uptime-table__layout__col-1__block__wrapper__header__left__tab"
      );
      Array.prototype.forEach.call(tabs, function (tab) {
        tab.classList.remove("active");
      });
      this.getfleetUptimehistoricViewBelow();
    } else {
      this.getfleetUptimehistoricViewAbove();
    }
    evt.currentTarget.classList.add("active");

    this.setState({ selTabs: tabItem.tabIndex });

    // selectedTab && selectedTab(evt, tabItem);
  };

  onClickCloseMessage() {
    this.messageTimeOut = null;
    this.setState({
      message: "",
      messageType: 0,
    });
  }

  componentDidMount() {
    // this.getfleetUptimehistoricViewAbove();
    // this.getfleetUptimehistoricViewBelow()
  }

  getfleetUptimehistoricViewAbove = async () => {
    const { start_date, end_date } = this.state;

    let payLoad = {
      // endTimestamp: "2023-01-18 23:59:59",
      // startTimestamp: "2023-01-18 00:00:00",
      user_id: sessionStorage.getItem("userId"),
      vehicleAboveTarget: true,
      vehicleBelowTarget: false,
      start_timestamp: sessionStorage.getItem("start_date"),
      end_timestamp: sessionStorage.getItem("end_date"),
      customerId: sessionStorage.getItem("customerId"),
    };
    await this.props.getFleetUptimeHistoricAPI(payLoad);
  };

  getfleetUptimehistoricViewBelow = async () => {
    const { start_date, end_date } = this.state;

    let payLoad = {
      // endTimestamp: "2023-01-18 23:59:59",
      // startTimestamp: "2023-01-18 00:00:00",
      user_id: sessionStorage.getItem("userId"),
      vehicleAboveTarget: false,
      vehicleBelowTarget: true,
      start_timestamp: sessionStorage.getItem("start_date"),
      end_timestamp: sessionStorage.getItem("end_date"),
      customerId: sessionStorage.getItem("customerId"),
    };
    await this.props.getFleetUptimeHistoricBelowAPI(payLoad);
  };

  componentWillReceiveProps(nextProps) {
    // console.log("histo", nextProps);
    if (
      nextProps?.FleetUptimeMangementApi?.FleetUptimeHistoric?.result?.data
        ?.responseData
    ) {
      this.setState({
        abovedata:
          nextProps.FleetUptimeMangementApi.FleetUptimeHistoric?.result?.data
            ?.responseData,
      });

      this.setState({
        belowdata:
          nextProps.FleetUptimeMangementApi.FleetUptimeHistoricBelow?.result
            ?.data?.responseData,
      });
    }
  }

  render() {
    const { tabs, selTabs } = this.state;
    let tabDoms = [];
    if (tabs && tabs.length > 0) {
      tabs.map(function (tabItem, index) {
        let className =
          selTabs === tabItem.tabIndex
            ? "active fleet-uptime-table__layout__col-1__block__wrapper__header__left__tab ripple-effect"
            : "fleet-uptime-table__layout__col-1__block__wrapper__header__left__tab ripple-effect";
        tabDoms.push(
          <li
            className={className}
            key={tabItem.tabIndex}
            onClick={(e) => this.tabClicks(e, tabItem)}
          >
            <a>{tabItem.tabName}</a>
            <span className="tab-strips-1"></span>
          </li>
        );
      }, this);
    }
    return (
      <div className="fleet-uptime-table">
        <div className="fleet-uptime-table__layout">
          <div className="fleet-uptime-table__layout__col-1">
            <div className="fleet-uptime-table__layout__col-1__block">
              <div className="fleet-uptime-table__layout__col-1__block__wrapper">
                {/* <p>   <img  onClick={this.backtoFleetHealth}
                      className=""
                      src={require("../../assets/images/fleet-health/left-arrow.png")}
                      alt=""
                    />
                    Back</p> */}

                <div className="fleet-uptime-table__layout__col-1__block__wrapper__header">
                  <div className="fleet-uptime-table__layout__col-1__block__wrapper__header__left">
                    {tabDoms}
                  </div>
                </div>
                <hr
                  style={{ width: "330px" }}
                  className="fleet-uptime-table__layout__col-1__block__wrapper__horizontal-line"
                />
                <div className="fleet-uptime-table__layout__col-1__block__wrapper__content">
                  {(() => {
                    if (selTabs === 0) {
                      return (
                        <>
                          <ReactTable
                            NoDataComponent={() => null}
                            pageSizeOptions={[
                              20, 30, 50, 80, 100, 130, 200, 500,
                            ]}
                            style={{
                              height: "380px",
                              marginTop: "0%",
                              marginLeft: "10px",
                              marginRight: "-78px",
                              // width: "1300px",
                              //marginLeft: "120px",
                            }}
                            data={this.state.abovedata}
                            showPagination={true}
                            columns={[
                              {
                                Header: "S.No",
                                id: "serialNumber",
                                filterable: false,
                                resizable: false,
                                width: 90,
                                Cell: (row) => {
                                  return <div>{row.index + 1}</div>;
                                },
                              },
                              {
                                Header: "VIN",
                                id: "vehicleId",
                                accessor: (d) => d.vehicleId,
                                width: 220,

                                resizable: false,
                              },
                              {
                                Header: "Reg No",
                                id: "regNo",
                                accessor: (d) => d.regNo,
                                width: 280,

                                resizable: false,
                              },

                              {
                                Header: "Model No",
                                id: "modelNo",
                                accessor: (d) => d.modelNo,
                                width: 270,

                                resizable: false,
                              },

                              {
                                Header: "Distance Travelled",
                                id: "distanceTravelled",
                                accessor: (d) => d.distanceTravelled,
                                width: 270,

                                resizable: false,
                              },
                              {
                                Header: "Timestamp",
                                id: "timestamp",
                                accessor: (d) => d.timestamp,
                                width: 270,
                                resizable: false,
                              },
                            ]}
                            defaultPageSize={50}
                            className="-striped -highlight"
                            defaultSorted={[
                              {
                                id: "serialNumber",
                                asc: true,
                              },
                            ]}
                          >
                            {(state, makeTable, instance) => {
                              let recordsInfoText = "";

                              const {
                                filtered,
                                pageRows,
                                pageSize,
                                sortedData,
                                page,
                              } = state;

                              if (sortedData && sortedData.length > 0) {
                                let isFiltered = filtered.length > 0;

                                let totalRecords = sortedData.length;

                                let recordsCountFrom = page * pageSize + 1;

                                let recordsCountTo =
                                  recordsCountFrom + pageRows.length - 1;

                                if (isFiltered)
                                  recordsInfoText = `${recordsCountFrom}-${recordsCountTo} of ${totalRecords} filtered records`;
                                else
                                  recordsInfoText = `${recordsCountFrom}-${recordsCountTo} of ${totalRecords} records`;
                              } else recordsInfoText = "No records";

                              return (
                                <div className="main-grid">
                                  <div className="above-table text-right">
                                    <div className="col-sm-12">
                                      <span
                                        style={{ marginLeft: "10px" }}
                                        className="records-info"
                                      >
                                        {recordsInfoText}
                                      </span>
                                    </div>
                                  </div>

                                  {makeTable()}
                                </div>
                              );
                            }}
                          </ReactTable>
                        </>
                      );
                    } else {
                      return (
                        <>
                          <ReactTable
                            NoDataComponent={() => null}
                            pageSizeOptions={[
                              20, 30, 50, 80, 100, 130, 200, 500,
                            ]}
                            style={{
                              height: "380px",
                              marginTop: "0%",
                              marginLeft: "10px",
                              marginRight: "-78px",
                            }}
                            data={this.state.belowdata}
                            showPagination={true}
                            columns={[
                              {
                                Header: "S. No.",
                                id: "serialNumber",
                                filterable: false,
                                resizable: false,
                                width: 90,
                                Cell: (row) => {
                                  return <div>{row.index + 1}</div>;
                                },
                              },
                              {
                                Header: "VIN",
                                id: "vehicleId",
                                accessor: (d) => d.vehicleId,
                                width: 220,

                                resizable: false,
                              },
                              {
                                Header: "Reg No",
                                id: "regNo",
                                accessor: (d) => d.regNo,
                                width: 280,

                                resizable: false,
                              },

                              {
                                Header: "Model No",
                                id: "modelNo",
                                accessor: (d) => d.modelNo,
                                width: 270,

                                resizable: false,
                              },

                              {
                                Header: "Distance Travelled",
                                id: "distanceTravelled",
                                accessor: (d) => d.distanceTravelled,
                                width: 270,

                                resizable: false,
                              },
                              {
                                Header: "Timestamp",
                                id: "timestamp",
                                accessor: (d) => d.timestamp,
                                width: 270,

                                resizable: false,
                              },
                            ]}
                            defaultPageSize={50}
                            className="-striped -highlight"
                            defaultSorted={[
                              {
                                id: "serialNumber",
                                asc: true,
                              },
                            ]}
                          >
                            {(state, makeTable, instance) => {
                              let recordsInfoText = "";

                              const {
                                filtered,
                                pageRows,
                                pageSize,
                                sortedData,
                                page,
                              } = state;

                              if (sortedData && sortedData.length > 0) {
                                let isFiltered = filtered.length > 0;

                                let totalRecords = sortedData.length;

                                let recordsCountFrom = page * pageSize + 1;

                                let recordsCountTo =
                                  recordsCountFrom + pageRows.length - 1;

                                if (isFiltered)
                                  recordsInfoText = `${recordsCountFrom}-${recordsCountTo} of ${totalRecords} filtered records`;
                                else
                                  recordsInfoText = `${recordsCountFrom}-${recordsCountTo} of ${totalRecords} records`;
                              } else recordsInfoText = "No records";

                              return (
                                <div className="main-grid">
                                  <div className="above-table text-right">
                                    <div className="col-sm-12">
                                      <span
                                        style={{ marginLeft: "10px" }}
                                        className="records-info"
                                      >
                                        {recordsInfoText}
                                      </span>
                                    </div>
                                  </div>

                                  {makeTable()}
                                </div>
                              );
                            }}
                          </ReactTable>
                        </>
                      );
                    }
                  })()}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps, {
  getFleetUptimeHistoricAPI,
  getFleetUptimeHistoricBelowAPI,
})(withRouter(HistoricTableView));
