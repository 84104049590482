export const VOLVO_API =
  "https://ei8lbfz0rj.execute-api.us-east-1.amazonaws.com/v1/ui/dashboard";

export const STATION_API =
  "https://ei8lbfz0rj.execute-api.us-east-1.amazonaws.com/v1/ui/dashboard";

//PROD URL = "https://7f014kc3l7.execute-api.us-east-1.amazonaws.com/v1/"
//UAT URL = "https://femdt2vmgh.execute-api.us-east-1.amazonaws.com/v1/"
//PROD EBS = "https://wwkzooqnth.execute-api.us-east-1.amazonaws.com/v1/"

export const URL = "https://wwkzooqnth.execute-api.us-east-1.amazonaws.com/v1/";

//#
// export const REPORT_API =
//   "https://ei8lbfz0rj.execute-api.us-east-1.amazonaws.com/v1/ui/reports";
  //tripmaster->tripsubmission

export const TRIP_SUBMISSION_API =
  URL + "sb/operation/get-all-trip-submission";

export const TRIP_HISTORY_API =
  URL + "sb/operation/trip-update-history"

// export const TRIP_MASTER_UPDATE_API =
//   "https://ei8lbfz0rj.execute-api.us-east-1.amazonaws.com/v1/ui/trip-master-update";

// export const TECHNICIAN_FEEDBACK_UPDATE =
//   "https://ei8lbfz0rj.execute-api.us-east-1.amazonaws.com/v1/ui/technician-feedback-update";

export const TRIP_MASTER_UPDATE_API =
URL + "sb/operation/update-trip-details";

export const TECHNICIAN_FEEDBACK_UPDATE =
URL + "sb/operation/update-driver-feedback";


//#
// export const MAP_STATUS_COUNTS_API =
//   "https://ei8lbfz0rj.execute-api.us-east-1.amazonaws.com/v1/ui/connected-module";

//#  
// export const DASHBOARD_STATUS_COUNTS_API =
//   "https://ei8lbfz0rj.execute-api.us-east-1.amazonaws.com/v1/ui/home";

//#
// export const TRIP_MASTER_API =
//   "https://ei8lbfz0rj.execute-api.us-east-1.amazonaws.com/v1/ui/trip-master";

export const TRIP_UPLOAD_API =
  "http://3.91.42.175:8080/vecv/ev/file/uploadTripData?fileType=XLSX&templateName=EV_TRIPS";

export const REPORT_TRIP_SUMMARY_REPORT =
  URL + "sb/operation/tripreport";

export const REPORT_VEHICLE_SUMMARY_REPORT =
  URL + "sb/operation/vehiclesummaryreport";

export const REPORT_CHARGERS_REPORT =
  URL + "sb/operation/chargerreport";

export const DRIVER_DETAILS =
  URL + "sb/dashboard/driverdetails";

export const LIVE_TRIP_TRACK_SUMMARY_DETAILS =
  URL + "sb/dashboard/livetrackpaneldata";

export const LIVE_TRIP_TRACK_MAP_DETAILS =
  URL + "sb/dashboard/live-track";

// export const LIVE_TRIP_TRACK_SUMMARY_DETAILS =
//   "http://3.91.42.175:8081/vecv/ev/web/dashboard/liveTrackPanelData";

//  master view
export const DRIVER_MASTER_VIEW =
  URL + "sb/driver/getdriver";

export const VEHICLE_MASTER_VIEW =
  URL + "sb/vehicle/getvehicle";

export const ROUTE_MASTER_VIEW =
  URL + "sb/route/getroute";
  

  export const ACCIDENT_MASTER_VIEW =
  URL + "sb/master/get-accident-details";
  

// master create
export const DRIVER_MASTER_CREATE =
  URL + "sb/driver/adddriver";

export const VEHICLE_MASTER_CREATE =
  URL + "sb/vehicle/addvehicle";

export const ROUTE_MASTER_CREATE =
  URL + "sb/route/addroute";

  export const ACCIDENT_MASTER_CREATE=
  URL + "sb/master/add-accident-details";

// driver master update
export const DRIVER_MASTER_UPDATE =
  URL + "sb/driver/updatedriver";

export const VEHICLE_MASTER_UPDATE =
  URL + "sb/vehicle/updatevehicle";

export const ROUTE_MASTER_UPDATE =
  URL + "sb/route/updateroute";

  export const ACCIDENT_MASTER_UPDATE =
  URL + "sb/master/update-accident-details";

// master delete
export const DRIVER_MASTER_DELETE =
  URL + "sb/driver/deletedriver";

export const VEHICLE_MASTER_DELETE =
  URL + "sb/vehicle/deletevehicle";

export const ROUTE_MASTER_DELETE =
  URL + "sb/route/deleteroute";

  /// accicent master delete api is pending from backend developer
  export const ACCIDENT_MASTER_DELETE =
  URL + "sb/route/getroute";

  

export const DRIVER_MONITORING_VIEW =
  URL + "sb/driver/attendance-log";

export const DRIVER_MONITORING_CALENDAR =
  URL + "sb/driver/attendance-calender-log";

export const TRIP_MONITORING =
  URL + "sb/trip/trip-monitor";
export const TRIP_MONITORING_LOG =
  URL + "sb/trip/trip-monitor-log";

// export const DAILY_OPERATION_PANEL_REPORT=
// "http://3.91.42.175:8081/vecv/ev/web/operation/dailyOperationPanelReport";

// export const DAILY_OPERATION_REPORT=
// "http://3.91.42.175:8081/vecv/ev/web/operation/dailyOperationReport";

export const DAILY_OPERATION_PANEL_REPORT =
  URL + "sb/operation/panel-daily-operation-report";

export const DAILY_OPERATION_REPORT =
  URL + "sb/operation/daily-operation-report";

/// fleet uptime

export const FLEET_HEALTH_PANEL_VIEW =
  URL + "sb/up-time/get-fleet-health-panel-view";

export const FLEET_HEALTH_VIEW =
  URL + "sb/up-time/fleet-health-view";

export const FLEET_UPTIME_CURRENT_VIEW =
  URL + "sb/up-time/fleet-uptime-current-view";
//contractual kpi

export const KPI_FINACIAL =
  URL + "sb/contractual/financialdetails";

export const KPI_SUSTAINANCE =
  URL + "sb/contractual/sustainancedetails";

export const KPI_CONTRACTUAL =
  URL + "sb/contractual/contractualdetails";

export const KPI_DRIVERSCORE =
  URL + "sb/contractual/driverscore";

/// trip submission (Mandatory check)
export const TRIP_SUBMISSION =
  URL + "sb/operation/trip-submission";


  
//alert
export const ALERT =
URL + "sb/operation/alert-data"

//FLEET_HEALTH_DETAILS
export const FLEET_HEALTH_DETAILS =
URL + "sb/up-time/fleet-health-details"


//safety

export const SAFTY_CONTRACTUAL =
URL + "sb/contractual/safety-details"


// New spring boot APIs

//Operation Module end points
export const TRIP_DETAILS = 
  URL + "sb/operation/trip-details";

export const DRIVER_FEEDBACK = 
  URL + "sb/operation/driver-feedback";

export const BREAKDOWN_DETAILS_REPORT =
  URL + "sb/operation/breakdown-details";

export const ALL_DRIVER_DETAILS = 
  URL + "sb/operation/get-all-drivers";
  
export const ALL_VEHICLE_DETAILS = 
  URL + "sb/operation/get-all-vehicles";
  
export const TRIP_UPDATE_MASTER=
  URL + "sb/operation/trip-update-master";

export const DRIVER_REPORTING_DETAILS = 
  URL + "sb/operation/driver-reporting-details";

  //Dashboard end points
export const DASHBOARD_STATUS_ALERTS = 
  URL + "sb/dashboard/status-alerts";

export const DASHBOARD_VEHICLE_EFFICIENCY_COUNT = 
  URL + "sb/dashboard/vehicle-efficiency-count";

export const DASHBOARD_VEHICLE_PERFORMANCE_INSIGHT = 
  URL + "sb/dashboard/vehicle-performance-insight";


  //Uptimehistoric

export const FLEET_UPTIME_HISTORIC =
URL + "sb/up-time/fleet-uptime-historic-view"

// track APIs

export const MAP_DASHBOARD_VEHICLES = URL + "sb/dashboard/vehicles-on-map"

export const MAP_DASHBOARD_CHARGERS = URL + "sb/dashboard/chargers-on-map"

export const TRACK_CHARGER_AND_VEHICLE_COUNT = URL + "sb/dashboard/connected-charger-and-vehicle-count"

export const TRACK_SUMMARY_DATA = URL + "sb/dashboard/track-map-summary"

/// login APIs

export const login_API = URL + "sb/login-verification"


//speedbandgraph

export const speed_band_API = URL + "sb/dashboard/speed-band"
//speed band table

export const SPEED_BAND_LIST = URL + "sb/operation/speed-band-list"

//fleetuptime-graph

export const HISTORIC_GRAPH= URL + "sb/up-time/historic-graph"

/// login api

export const LOGIN_API= URL + "sb/login-verification"

// live dashboard count

export const LIVE_DASHBOARD_COUNT= URL + "sb/charging-infra/live-dashboard-count"

/// live dashboard bar graph for charge band and battery temp

export const LIVE_DASHBOARD_BAR_GRAPH= URL + "sb/charging-infra/live-dashboard-bargraph"

// charging summary 

export const CHARGING_SUMMARY = URL + "sb/charging-infra/charging-summary"

// battery temp list

export const BATTERY_TEMP_LIST = URL + "sb/charging-infra/live-dashboard-battery-temp-list"

// charge band list

export const CHARGE_BAND_LIST = URL + "sb/charging-infra/live-dashboard-charge-band-list"

// available charger list

export const AVAILABLE_CHARGER_LIST = URL + "sb/charging-infra/live-dashboard-available-list"

// occupied charging list

export const OCCUPIED_CHARGER_LIST = URL + "sb/charging-infra/live-dashboard-occupied-station-list"

// breakdown charging list

export const BREAKDOWN_CHARGER_LIST = URL + "sb/charging-infra/live-dashboard-breakdown-list"

// charger efficiency list

export const CHARGER_EFFICIENCY_LIST = URL + "sb/charging-infra/live-dashboard-charger-efficiency-list"

//
export const ASSURED_FLEET_AVAILABILITY_LIST= URL + "sb/contractual/assured-availability-list"

//
export const SEVERE_SAFETY_LIST= URL + "sb/contractual/severe-saftey-list"

//
export const GENERAL_SAFETY_LIST= URL + "sb/contractual/general-saftey-list"

// customer projects list

export const CUST_PROJECT_LIST= URL + "sb/login-verification/project-details"

//project details

export const PROJECT_LIST_DETAILS= URL + "sb/login-verification/project-details";

// vehicle summary graph

export const VEHICLE_SUMMARY_GRAPH= URL + "sb/operation/vehicle-summary-graph";

// contractual Schedule Adherence

export const CONTRACTUAL_SCHEDULE_ADHERENCE= URL + "sb/contractual/schedule-adherence-list";


//contractual reliability popup

export const  CONTRACTUAL_RELIABILITY_POPUP= URL +"sb/contractual/reliability-factor-list";

//contractual breakdown summary list view popup

export const CONTRACTUAL_BREAKDOWN_SUMMARY= URL + "sb/contractual/breakdown-summary-list";

   
  export const CONTRACTUAL_PUNCTUALITY_ARRIVAL_WEEKLY= URL + "sb/contractual/arrival-punctuality-list"
  export const CONTRACTUAL_PUNCTUALITY_START_WEEKLY= URL + "sb/contractual/start-punctuality-list"

  export const CONTRACTUAL_PUNCTUALITY_START_GRAPH = URL + "sb/contractual/start-punctuality-graph"
  export const CONTRACTUAL_PUNCTUALITY_ARRIVAL_GRAPH = URL + "sb/contractual/arrival-punctuality-graph"

  export const DAILY_SHIFT_WISE_REPORT = URL + "sb/operation/shift-report"

  export const CONTRACTUAL_ACCIDENT_SUMMARY= URL + "sb/contractual/breakdown-summary-list";

  export const EFFICIENCY_REPORT= URL + "sb/operation/vehile-monthly-efficiency-report"

  export const DOMAIN_URL = "https://msuappenv.d3da18urjxxeeu.amplifyapp.com/";
  
  export const QUICKSIGHT_API = URL + "qs/its-report";