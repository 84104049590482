import React, { Component } from "react";
import "./index.scss";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import BackToScreen from "../../component/back_to_screen";
import ReactTable from "react-table";
import "react-table/react-table.css";
import ReactLoader from "../../component/react-loader/react-loader.component";
import fleetUptimeMangementApiActions from "../../redux/uptime_mangement/actions";
const { getFleetHealthDetailsAPI } = fleetUptimeMangementApiActions;
class FleetHealthDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      registrationNo: "",
      data: [],
      chassisNumber: "",
      contactNo: "",
      odometerReading: "",
      lastUpdated: "",
      customerContacted: "",
      resolutionStatus: "",
      caseNo: "",
    };
    this.backtoFleetHealth = this.backtoFleetHealth.bind(this);
  }

  componentDidMount() {
    // console.log("called")
    let payLoad = {
      // caseNo: "DPT2301051149187"
      user_id: sessionStorage.getItem("userId"),
      customerId: sessionStorage.getItem("customerId"),
      caseNo: sessionStorage.getItem("fleetHealthcaseNoClicked"),
    };
    // console.log("called2")
    this.props.getFleetHealthDetailsAPI(payLoad);
  }

  getfleetHealthDetails() {
    const { start_date, end_date } = this.state;
    let payLoad = {
      // caseNo: "DPT2301051149187"
      //user_id: sessionStorage.getItem("userId"),
      customerId: sessionStorage.getItem("customerId"),
      caseNo: sessionStorage.getItem("fleetHealthcaseNoClicked"),
    };
    // console.log("called2")
    this.props.getFleetHealthDetailsAPI(payLoad);
  }

  componentWillReceiveProps(nextProps) {
    // console.log(
    //   "props checksssssssssssssss",
    //   nextProps
    // );

    if (
      nextProps?.FleetUptimeMangementApi?.FleetHealthdetails?.result?.data
        .responseData
    ) {
      this.setState({
        data: nextProps.FleetUptimeMangementApi.FleetHealthdetails.result.data
          .responseData,
      });

      this.setState({
        registrationNo:
          nextProps.FleetUptimeMangementApi.FleetHealthdetails.result.data
            .responseData[0].registrationNo,
      });

      this.setState({
        chassisNumber:
          nextProps.FleetUptimeMangementApi.FleetHealthdetails.result.data
            .responseData[0].chassisNumber,
      });

      this.setState({
        contactNo:
          nextProps.FleetUptimeMangementApi.FleetHealthdetails.result.data
            .responseData[0].contactNo,
      });
      this.setState({
        odometerReading:
          nextProps.FleetUptimeMangementApi.FleetHealthdetails.result.data
            .responseData[0].odometerReading,
      });

      this.setState({
        lastUpdated:
          nextProps.FleetUptimeMangementApi.FleetHealthdetails.result.data
            .responseData[0].lastUpdated,
      });

      this.setState({
        customerContacted:
          nextProps.FleetUptimeMangementApi.FleetHealthdetails.result.data
            .responseData[0].customerContacted,
      });

      this.setState({
        resolutionStatus:
          nextProps.FleetUptimeMangementApi.FleetHealthdetails.result.data
            .responseData[0].resolutionStatus,
      });

      this.setState({
        caseNo:
          nextProps.FleetUptimeMangementApi.FleetHealthdetails.result.data
            .responseData[0].caseNo,
      });
    }
  }

  backtoFleetHealth = () => {
    let tabItem = {
      tabIndex: 21,
      tabName: "Fleet Health",
    };
    this.props.selectedTab(null, tabItem);
  };
  render() {
    return (
      <div>
        {this.props.FleetUptimeMangementApi?.FleetHealthdetails?.loading ? (
          <div className="loader-overlay">
            <div className="loader-container">
              <ReactLoader />
            </div>
          </div>
        ) : null}

        <div className="fleet-health-details-backward">
          <BackToScreen routingCall={this.backtoFleetHealth} />
          <h3 className="fleet-health-details-heading">Fleet Health Detail</h3>
        </div>
        <div className="row ms-5 mt-3 me-5">
          <div className="col-2 border-right">
            <p className="fleet_health_detail_chassis">Chassis Number</p>
            <p className="fleet_health_detail_chassis_no">
              {this.state.chassisNumber}
            </p>
          </div>
          <div className="col-2">
            <p className="fleet_health_detail_reg">Registration No.</p>
            <p className="fleet_health_detail_reg_no">
              {this.state.registrationNo}
            </p>
          </div>
          <hr className="fleet_health_detail_hr" />

          <div className="row">
            <div className="col">
              <p className="fleet_details_text_1">Case ID.</p>
            </div>
            <div className="col bg-blue">
              <p className="fleet_details_text_2">{this.state.caseNo}</p>
            </div>
            <div className="col">
              <p className="fleet_details_text_1">Resolution Status</p>
            </div>
            <div className="col bg-blue">
              <p className="fleet_details_text_2">
                {this.state.resolutionStatus}
              </p>
            </div>
            <div className="col">
              <p className="fleet_details_text_1">Customer Contacted</p>
            </div>
            <div className="col bg-blue">
              <p className="fleet_details_text_2">
                {this.state.customerContacted}
              </p>
            </div>
          </div>
          <hr className="fleet_details_hr-1" />
          <div className="row">
            <div className="col">
              <p className="fleet_details_text_1">Contact No.</p>
            </div>
            <div className="col bg-blue">
              <p className="fleet_details_text_2">{this.state.contactNo}</p>
            </div>
            <div className="col">
              <p className="fleet_details_text_1">Last Updated</p>
            </div>
            <div className="col bg-blue">
              <p className="fleet_details_text_2">{this.state.lastUpdated}</p>
            </div>
            <div className="col">
              <p className="fleet_details_text_1">Odometer Reading</p>
            </div>
            <div className="col bg-blue">
              <p className="fleet_details_text_2">
                {this.state.odometerReading} Km
              </p>
            </div>
          </div>
          <hr />
        </div>

        <div>
          <ReactTable
            NoDataComponent={() => null}
            pageSizeOptions={[20, 30, 50, 80, 100, 130, 200, 500]}
            style={{
              height: "340px",
              marginTop: "10px",
              marginLeft: "40px",
              marginRight: "30px",
            }}
            data={this.state.data}
            showPagination={true}
            columns={[
              {
                Header: "Rule Name",
                id: "ruleName",
                accessor: (d) => d.ruleName,
                width: 480,
                filterAll: true,
                resizable: false,
              },
              {
                Header: "Recommended Actions",
                id: "recommendedActions",
                accessor: (d) => d.recommendedActions,
                width: 480,

                filterAll: true,
                resizable: false,
              },
              {
                Header: "Related Information",
                id: "relatedInfo",
                accessor: (d) => d.relatedInfo,
                width: 480,

                resizable: false,
              },
            ]}
            defaultSorted={[
              {
                id: "serialNumber",
                asc: true,
              },
            ]}
            defaultPageSize={50}
            className="-striped -highlight"
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps, {
  getFleetHealthDetailsAPI,
})(withRouter(FleetHealthDetails));
