import React, { Component } from "react";
import "../../component/dashboard_vehicle_tendays/index.scss";
import Chart from "react-google-charts";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import fleetUptimeDetailsApiActions from "../../redux/fleet_uptime_details/actions";
import moment from "moment";
const { getFleetUptimeDetailsAPI } = fleetUptimeDetailsApiActions;
var now = new Date();
now.setDate(now.getDate());
class DashboardVehicletenDays extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      vehRunning10Days: [
        { text: "0", value: "0" },
        { text: "0", value: "0" },
        { text: "0", value: "0" },
        { text: "0", value: "0" },
        { text: "0", value: "0" },
      ],
      FA_totalKmPerDay:0,
    };
  }

  componentDidMount() {
    // this.getDashboardDetails();
    this.getFleetUptimeDetails();
    // this.getVehPerformanceDetails();
    // this.getBatteryTempDetails();
    // this.getChargeBandDetails();
    // this.getStationDetails();
    // this.getMapActionDetails();
  }

  getFleetUptimeDetails = async () => {
    const { start_date, end_date } = this.state;
    let payLoad = {
      api_key: "DASHBOARD_VEHICLE_EFFICIENCY_COUNT",
      charger_location: "Bangalore",
      //user_id: "1",
      vehicle_number: ["BLR001", "BLR002", "BLR003", "BLR004"],
      user_id: sessionStorage.getItem("userId"),
      customerId: sessionStorage.getItem("customerId"),
      // start_timestamp: sessionStorage.getItem("start_timestamp"),
      // end_timestamp: sessionStorage.getItem("end_timestamp"),
      start_timestamp: moment(start_date).format("YYYY-MM-DD 00:00:00"),
      end_timestamp: moment(end_date).format("YYYY-MM-DD 23:59:59"),
      duration: "CURRENT",
    };

    await this.props.getFleetUptimeDetailsAPI(payLoad);
  };

  //   componentDidUpdate(prevProps, prevState, snapshot){
  //     console.log("graph data check",prevProps?.fleetUptimeDetailsApi?.fleetUptimeDetailsApi?.result
  //     ?.data?.data.avgVehicleRunFor10Day)
  // this.setState({
  //     vehRunning10Days:
  //     prevProps?.fleetUptimeDetailsApi?.fleetUptimeDetailsApi?.result
  //         ?.data?.data.avgVehicleRunFor10Day,
  //   });
  //   }

  componentWillReceiveProps(nextProps) {
    if (this.props !== nextProps) {
       //console.log("graph data check",nextProps)
      if (
        nextProps?.fleetUptimeDetailsApi?.fleetUptimeDetailsApi?.result?.data
          ?.responseData.avgVehicleRunFor10Day.length > 0
      ) {
        this.setState({
          vehRunning10Days:
            nextProps?.fleetUptimeDetailsApi?.fleetUptimeDetailsApi?.result
              ?.data?.responseData.avgVehicleRunFor10Day,
        });
      }

      if (
        nextProps?.dashboardStatusCountsApi?.dashboardStatusCountsApi?.result
          ?.data?.responseData.fleetAvailablity
      ) {
        this.setState({
          FA_totalKmPerDay:
            nextProps?.dashboardStatusCountsApi?.dashboardStatusCountsApi
              ?.result?.data?.responseData.fleetAvailablity.totalKmPlannedPerDay,
        });
        
      }
    }
  }

  render() {
    // console.log("called graph")
    // console.log("tshis 10 days", this.props.vehrunning10days);
    //const response = this.props.vehrunning10days;

    // const data = [
    //   [
    //     "Element",
    //     "no of vehicles",
    //     { role: "style" },
    //     {
    //       sourceColumn: 0,
    //       role: "annotation",
    //       type: "string",
    //       calc: "stringify",
    //     },
    //   ],
    //   ["20th-30th Oct", 24, " #4B85F7", 24],
    //   ["30th-9th Nov", 12, " #4B85F7", 12],
    //   ["9th-19th Nov", 160, " #4B85F7", 160],
    //   ["19th-29th Nov", 40, " #4B85F7", 40], // RGB value
    // ];

    let response = this.state.vehRunning10Days;
    const data = [
      [
        "Element",
        "No of Kms",
        { role: "style" },
        {
          sourceColumn: 0,
          role: "annotation",
          type: "string",
          calc: "stringify",
        },
      ],
      [response[0].text, response[0].value, " #4B85F7", response[0].value],
      [response[1].text, response[1].value, " #4B85F7", response[1].value],
      [response[2].text, response[2].value, " #4B85F7", response[2].value],
      [response[3].text, response[3].value, " #4B85F7", response[3].value],
      [response[4].text, response[4].value, " #4B85F7", response[4].value],
    ];
    return (
      <div className="dashboard-vehicle-tendays">
        <Chart
          id="chart_div"
          chartType="ColumnChart"
          width="98%"
          height="200px"
          data={data}
          loader={<div>Loading....</div>}
          options={{
            chartArea: {
              width: "70%",
              height: "56%",
              marginLeft: "30px",
              paddingRight: "10px",
              left: 100,
              // paddingLeft: "10px",
              //marginTop:"-20px"
              bottom: 70,
            },

            isStacked: true,
            displayAnnotations: true,
            annotations: {
              stemColor: "none",
              textStyle: {
                // The color of the text.
                color: "#353844",
                //opacity: 0.8,
                auraColor: "none",
                //stem: {color: "black"},
                fontSize: 10,
                fontName: "Roboto-Bold",
              },
              alwaysOutside: true,
            },
            bar: { groupWidth: "25%" },
            legend: { position: "none" },
            hAxis: {
              textStyle: {
                fontSize: 10,
                fontName: "Roboto-Medium",
                marginTop: "10",
                color: "#747892",
              },
              gridlines: { color: "#383B46" },
              title: "LAST 5 DAYS", //  getting output
              minValue: 0,
              titleTextStyle: {
                italic: false,
                fontName: "Roboto-Medium",
                // bold:true,
                marginTop: "15",
                //fontStyle: "bold",
                color: "black",
                fontSize: "12",
              },
            },
            backgroundColor: "transparent",
            vAxis: {
              textStyle: {
                fontSize: 10,
                fontName: "Roboto-Medium",
                marginTop: "10",
                color: "#747892",
              },
              ticks:[this.state.FA_totalKmPerDay],
              baseline: 0,
              viewWindowMode: "explicit",
              viewWindow: { min: 0, max: 10000 },
              gridlines: { color: "red", count: 4 },
              textPosition: true,
              title: "NO. OF KMS",
              titleTextStyle: {
                italic: false,
                fontName: "Roboto-Medium",
                // bold:true,
                //fontStyle: "medium",
                color: "black",
                fontSize: "12",
              },
            },
          }}
        />
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps, {
  // getDashboardStatusCountsAPI,
  getFleetUptimeDetailsAPI,
  // getVehiclePerformanceDetailsAPI,
  // getBatteryTempAPI,
  // getChargeBandAPI,
  // getStationAPI,
  // getMapActionAPI,
})(withRouter(DashboardVehicletenDays));
