
import React, { Component } from "react";
import "../../component/dashboard_driver_details/index.scss";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
class DashboardDriverDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
    };
  }

  render() {

    //console.log("pysch",this.props.start.toString().length)
    let start = this.props.start.toString().length;
    let completed = this.props.completed.toString().length;
    return (
      <div className="driver-details">
        <div className="driver-details-div-first-img">
          <img
            src={require("../../assets/images/driver-details/driver-image.png")}
            alt="home"
            className="driver-details-first-img"
          />

          <img
            src={require("../../assets/images/driver-details/men.png")}
            alt="home"
            className="DD-driver-image"
          />
          <p className="driver-details-first-image-no">
            <b>{this.props.total}</b>
          </p>
          <p className="drivers">Total No.of Drivers </p>

          <p className="driver-details-img-data-green">
            <b>{this.props.present}</b>
          </p>

          <p className="reported">Reported</p>

          <div
            style={{
              width: "50px",
              height: "50px",
              marginLeft: "490px",
              marginTop: "-45px",
              position: "relative",
            }}
          >
            <CircularProgressbar
              value={this.props.driverreportedAdh}
              styles={buildStyles({
                pathColor: "#7BC84E",
                trailColor: "#D7EEC9",
                strokeLinecap: "butt",
              })}
              strokeWidth={20}
            />
          </div>

          <div className="green-dot-driver-details">
            <img
              src={require("../../assets/images/driver-details/Ellipse 9291.png")}
              alt="home"
              className="driver-detils-dot-green-img"
            />
            <span className="driver_details_textss">
              {this.props.driverreportedAdh}% <span>Adherence</span>
              {/* 0% <span>Adherence</span> */}
            </span>
          </div>

          <p className="driver-details-img-data-red">
            <b>{this.props.dailycheck}</b>
            {/* <b>0</b> */}
          </p>

          <p className="daily-check-done">Daily Check Done</p>

          <div
            style={{
              width: "50px",
              height: "50px",
              marginLeft: "490px",
              marginTop: "-50px",
              position: "relative",
            }}
          >
            <CircularProgressbar
              value={this.props.dailycheckAdh}
              styles={buildStyles({
                pathColor: "#d8231d",
                trailColor: "#f7d2d1",
                strokeLinecap: "butt",
              })}
              strokeWidth={20}
            />
          </div>

          <div className="green-dot-driver-details">
            <img
              src={require("../../assets/images/effciencys/red-small-dot.png")}
              alt="home"
              className="driver-detils-dot-red-img"
            />
            <span className="driver_details_textss">
              {this.props.dailycheckAdh} % <span>Adherence</span>
            </span>
          </div>
        </div>

        <div className="driver-details-div-second-img">
          <img
            src={require("../../assets/images/driver-details/driver-image-2.png")}
            alt="home"
            className="driver_details_second_img"
          />

          <div className="driver-details-img-data-two">
            <img
              src={require("../../assets/images/driver-details/vehicle.png")}
              // src={require("../../assets/images/driver-details/vehicle.png")}

              alt="home"
              className="DD-bus-image"
            />
            <p className="driver-details-img-data-two-image-no">
              <b>{this.props.estimatedtrips}</b>
            </p>
            <p className="drivers-1">Estimated Trips</p>
          </div>

          <div>
            <p className={` ${
                  start>1 ? `driver-details-img-2-blue` : `driver-details-img-3-blue`
                }`}>
              <b>{this.props.start}</b>
            </p>

            <p className="started">Trip Started</p>
          </div>

          <div className="driver-details-img-2-orange-data">
            <p className={` ${
                  completed>1 ? `driver-details-img-2-orange` : `driver-details-img-3-orange`
                }`}>
              <b>{this.props.completed}</b>
            </p>

            <p className="completed">Trip completed</p>
          </div>

          <div>
            <div
              style={{
                width: "50px",
                height: "50px",
                marginLeft: "490px",
                marginTop: "-110px",
                position: "relative",
              }}
            >
              <CircularProgressbar
                value={this.props.tripstartedAdh}
                styles={buildStyles({
                  pathColor: "#19397C",
                  trailColor: "#DCE8FD",
                  strokeLinecap: "butt",
                })}
                strokeWidth={20}
              />
            </div>
            <div className="green-dot-driver-details-blue">
              <img
                src={require("../../assets/blue-circle.svg").default}
                alt="home"
                className="driver-detils-dot-img"
              />
              <span className="driver_details_textss-1">
                {this.props.tripstartedAdh}% <span>Adherence</span>
              </span>
            </div>
          </div>

          <div>
            <div
              style={{
                width: "50px",
                height: "50px",
                marginLeft: "490px",
                marginTop: "23px",
                position: "relative",
              }}
            >
              <CircularProgressbar
                value={this.props.tripcompletedAdh}
                styles={buildStyles({
                  pathColor: "#EDA252",
                  trailColor: "#FAE3CB",
                  strokeLinecap: "butt",
                })}
                strokeWidth={20}
              />
            </div>
            <div className="green-dot-driver-details-orange">
              <img
                src={require("../../assets/orange-circle.svg").default}
                alt="home"
                //className="driver-detils-dot-img"
              />
              <span className="driver_details_textss-1">
                {this.props.tripcompletedAdh}% <span>Adherence</span>
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default DashboardDriverDetails;
