import actions from "./actions";
import { combineReducers } from "redux";

const initStatecontractualStartPunctualGraphdetails = {
  loading: false,
  result: null,
  error: false,
};

const initStatecontractualArrivalPunctualGraphdetails = {
  loading: false,
  result: null,
  error: false,
};

function contractualStartPunctualityGraphApi(state = initStatecontractualStartPunctualGraphdetails, action) {
    switch (action.type) {
      case actions.GET_CONTRACTUALSTARTPUNCTUALGRAPHDETAILS:
        return {
          ...state,
          loading: true,
        };
      case actions.GET_CONTRACTUALSTARTPUNCTUALGRAPHDETAILS_SUCCESS:
        return {
          ...state,
          loading: false,
          result: action.result,
          actionType: actions.GET_CONTRACTUALSTARTPUNCTUALGRAPHDETAILS_SUCCESS,
        };
      case actions.GET_CONTRACTUALSTARTPUNCTUALGRAPHDETAILS_FAILURE:
        return {
          ...state,
          loading: false,
          error: true,
          actionType: actions.GET_CONTRACTUALSTARTPUNCTUALGRAPHDETAILS_FAILURE,
          result: {
            errorResponse: action.saveStatusCode,
            message: action.message,
          },
        };
      default:
        return state;
    }
  }

  function contractualArrivalPunctualityGraphApi(state = initStatecontractualArrivalPunctualGraphdetails, action) {
    switch (action.type) {
      case actions.GET_CONTRACTUALARRIVALPUNCTUALGRAPHDETAILS:
        return {
          ...state,
          loading: true,
        };
      case actions.GET_CONTRACTUALARRIVALPUNCTUALGRAPHDETAILS_SUCCESS:
        return {
          ...state,
          loading: false,
          result: action.result,
          actionType: actions.GET_CONTRACTUALARRIVALPUNCTUALGRAPHDETAILS_SUCCESS,
        };
      case actions.GET_CONTRACTUALARRIVALPUNCTUALGRAPHDETAILS_FAILURE:
        return {
          ...state,
          loading: false,
          error: true,
          actionType: actions.GET_CONTRACTUALARRIVALPUNCTUALGRAPHDETAILS_FAILURE,
          result: {
            errorResponse: action.saveStatusCode,
            message: action.message,
          },
        };
      default:
        return state;
    }
  }

  export default combineReducers({
    contractualStartPunctualityGraphApi,
    contractualArrivalPunctualityGraphApi,

});