const alertApiActions = {
  GET_ALERT: "GET_ALERT",
  GET_ALERT_SUCCESS: "GET_ALERT_SUCCESS",
  GET_ALERT_FAILURE: "GET_ALERT_FAILURE",

  getAlertAPI: (data) => ({
    type: alertApiActions.GET_ALERT,
    payload: { data },
  }),

  getAlertAPISuccess: (saveStatusCode, result) => ({
    type: alertApiActions.GET_ALERT_SUCCESS,
    saveStatusCode,
    result,
  }),

  getAlertAPIFailure: (saveStatusCode, message) => ({
    type: alertApiActions.GET_ALERT_FAILURE,
    saveStatusCode,
    message,
  }),
};

export default alertApiActions;
