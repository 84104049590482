import React from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalTitle,
  Button,
  closeButton,
} from "react-bootstrap";
import moment from "moment";
import DatePicker from "react-datepicker";
import "./index.scss";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import DateTimePicker from "react-datepicker";

//Actions
import technicianFeedbackUpdateApiActions from "../../redux/technician_feedback_update/actions";
import breakdownReportActions from "../../redux/breakdown_report/actions";

let description_value = "";
const { getFeedbackUpdateAPI } = technicianFeedbackUpdateApiActions;
const { getBreakdownReportAPI } = breakdownReportActions;

class BreakdownReportPopup extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      show: false,
      editingfields: {},
      message: "",
      messageType: 0,
      date: new Date(),
      description_value: "",
      status: "",
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeStatus = this.handleChangeStatus.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps != this.props) {
      this.setState({
        show: this.props.show,
        editingfields: this.props.data,
      });
    }
  }

  handleChange(evt) {
    const value = evt.target.value;
    this.setState({
      description_value: evt.target.value,
    });
  }

  handleChangeStatus(evt) {
    //console.log("val", evt.target.value);
    const value = evt.target.value;
    this.setState({
      status: evt.target.value,
    });
  }
  componentDidMount() {
    this.setState({
      show: this.props.show,
      editingfields: this.props.data,
    });
  }

  getBreakdownReport() {
    const { start_date, end_date } = this.state;
    this.setState({ loading: true });
    const payload = {
      user_id: sessionStorage.getItem("userId"),
      customerId: sessionStorage.getItem("customerId"),
      api_key: "REPORT_COMPLAINT_DETAILS",
      start_timestamp: moment(start_date).format("YYYY-MM-DD 00:00:00"),
      end_timestamp: moment(end_date).format("YYYY-MM-DD 23:59:59"),
    };
    this.props.getBreakdownReportAPI(payload);
  }

  handleSubmit = (event) => {
   // console.log();
    event.preventDefault();
    const { id } = this.state.editingfields;

    const payload = {
      id: this.state.editingfields.complaint_id,
      description: this.state.description_value,
      status: this.state.status,
    };
    this.props.getFeedbackUpdateAPI(payload);

    setTimeout(() => {
      this.getBreakdownReport();
    }, 2000);
  };

  render() {
    let val = this.state.editingfields.reg_no;
    //console.log("searching1111", this.state.editingfields);
    return (
      <div className="driver-popup">
        <Modal
          show={this.state.show}
          size="xl"
          aria-labelledby="example-modal-sizes-title-xl"
          centered
          onHide={this.props.closemodal}
          //dialogClassName="modal-90w"
        >
          <Modal.Header closeButton style={{ borderBottom: "none" }}>
            <Modal.Title id="contained-modal-title-vcenter">
           
            </Modal.Title>
          </Modal.Header>
          <form
            className="driver-popup-update"
            autocomplete="off"
            onSubmit={this.handleSubmit}
          >
            <Modal.Body
              style={{
                "max-height": "calc(80vh - 210px)",

                "overflow-y": "auto",
              }}
            >
                 <p className="breakdown-popup-id">
                {" "}
                Breakdown ID :{this.state.editingfields.complaint_id}
              </p>
              <div className="formwidth">
                <div className="col_50-BREAK">
                  <div className="col_100-BREAK">
                    <label>Description :</label>
                    <span
                      style={{
                        color: "red",
                        marginLeft: "-100px",
                        marginTop: "10px",
                      }}
                    >
                      {" "}
                      *
                    </span>
                  </div>
                  <div className="col_100-BREAK">
                    <input
                      type="text"
                      placeholder="Description:"
                      name="description_value"
                      style={{ textTransform: "uppercase" }}
                      value={this.state.description_value}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
                <div className="col_50-BREAK">
                  <div className="col_100-BREAK">
                    <label>Status</label>
                    <span
                      style={{
                        color: "red",
                        marginLeft: "-130px",
                        marginTop: "20px",
                      }}
                    >
                      {" "}
                      *
                    </span>
                  </div>
                  <div className="col_100-BREAK">
                    <select
                      placeholder="status:"
                      name="name_tagid"
                      onChange={this.handleChangeStatus}
                      value={this.state.status}
                    >
                      <option value="Open">Open </option>
                      <option value="In Progress">In Progress</option>
                      <option value="Closed">Closed </option>
                      {/* {this.state.driverList.map((e, i) => (
                        <option key={i} value={e.name_tagid}>
                          {e.name_tagid}
                        </option>
                      ))} */}
                    </select>
                  </div>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer style={{ borderTop: "none" }}>
              <input className="button1" type="submit" value="Update" />
            </Modal.Footer>
          </form>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps, {
  getFeedbackUpdateAPI,
  getBreakdownReportAPI,
})(withRouter(BreakdownReportPopup));
