const dailyOperationReportApiActions = {
    GET_DAILYOPERATIONREPORT: "GET_DAILYOPERATIONREPORT",
    GET_DAILYOPERATIONREPORT_SUCCESS: "GET_DAILYOPERATIONREPORT_SUCCESS",
    GET_DAILYOPERATIONREPORT_FAILURE: "GET_DAILYOPERATIONREPORT_FAILURE",
  
    getDailyoperationReportAPI: (data) => ({
      type: dailyOperationReportApiActions.GET_DAILYOPERATIONREPORT,
      payload: { data },
    }),
  
    getDailyoperationReportAPISuccess: (saveStatusCode, result) => ({
      type: dailyOperationReportApiActions.GET_DAILYOPERATIONREPORT_SUCCESS,
      saveStatusCode,
      result,
    }),
  
    getDailyoperationReportAPIFailure: (saveStatusCode, message) => ({
      type: dailyOperationReportApiActions.GET_DAILYOPERATIONREPORT_FAILURE,
      saveStatusCode,
      message,
    }),
  
    PANEL_DAILYOPERATIONREPORT: "PANEL_DAILYOPERATIONREPORT",
    PANEL_DAILYOPERATIONREPORT_SUCCESS: "PANEL_DAILYOPERATIONREPORT_SUCCESS",
    PANEL_DAILYOPERATIONREPORT_FAILURE: "PANEL_DAILYOPERATIONREPORT_FAILURE",
  
   panelDailyOperationReportAPI: (data) => ({
      type: dailyOperationReportApiActions.PANEL_DAILYOPERATIONREPORT,
      payload: { data },
    }),
  
    panelDailyOperationReportAPISuccess: (saveStatusCode, result) => ({
      type: dailyOperationReportApiActions.PANEL_DAILYOPERATIONREPORT_SUCCESS,
      saveStatusCode,
      result,
    }),
  
    panelDailyOperationReportAPIFailure: (saveStatusCode, message) => ({
      type: dailyOperationReportApiActions.PANEL_DAILYOPERATIONREPORT_FAILURE,
      saveStatusCode,
      message,
    }),
  
   
  
    
  };
  
  export default dailyOperationReportApiActions;
  