const dataChangePopupApiActions = {
    GET_DATACHANGEPOPUP: "GET_DATACHANGEPOPUP",
    GET_DATACHANGEPOPUP_SUCCESS: "GET_DATACHANGEPOPUP_SUCCESS",
    GET_DATACHANGEPOPUP_FAILURE: "GET_DATACHANGEPOPUP_FAILURE",
  
    getDataChangePopupAPI: (data) => ({
      type: dataChangePopupApiActions.GET_DATACHANGEPOPUP,
      payload: { data },
    }),
  
    getDataChangePopupAPISuccess: (saveStatusCode, result) => ({
      type: dataChangePopupApiActions.GET_DATACHANGEPOPUP_SUCCESS,
      saveStatusCode,
      result,
    }),
  
    getDataChangePopupAPIFailure: (saveStatusCode, message) => ({
      type: dataChangePopupApiActions.GET_DATACHANGEPOPUP_FAILURE,
      saveStatusCode,
      message,
    }),
  };
  
  export default dataChangePopupApiActions;
  