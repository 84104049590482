const accidentMasterApiActions = {
    GET_ACCIDENTMASTERDETAILS: "GET_ACCIDENTMASTERDETAILS",
    GET_ACCIDENTMASTERDETAILS_SUCCESS: "GET_ACCIDENTMASTERDETAILS_SUCCESS",
    GET_ACCIDENTMASTERDETAILS_FAILURE: "GET_ACCIDENTMASTERDETAILS_FAILURE",
  
    getAccidentMasterAPI: (data) => ({
      type: accidentMasterApiActions.GET_ACCIDENTMASTERDETAILS,
      payload: { data },
    }),
  
    getAccidentMasterAPISuccess: (saveStatusCode, result) => ({
      type: accidentMasterApiActions.GET_ACCIDENTMASTERDETAILS_SUCCESS,
      saveStatusCode,
      result,
    }),
  
    getAccidentMasterAPIFailure: (saveStatusCode, message) => ({
      type: accidentMasterApiActions.GET_ACCIDENTMASTERDETAILS_FAILURE,
      saveStatusCode,
      message,
    }),
  
    CREATE_ACCIDENTMASTERDETAILS: "CREATE_ACCIDENTMASTERDETAILS",
    CREATE_ACCIDENTMASTERDETAILS_SUCCESS: "CREATE_ACCIDENTMASTERDETAILS_SUCCESS",
    CREATE_ACCIDENTMASTERDETAILS_FAILURE: "CREATE_ACCIDENTMASTERDETAILS_FAILURE",
  
    createAccidentMasterAPI: (data) => ({
      type: accidentMasterApiActions.CREATE_ACCIDENTMASTERDETAILS,
      payload: { data },
    }),
  
    createAccidentMasterAPISuccess: (saveStatusCode, result) => ({
      type: accidentMasterApiActions.CREATE_ACCIDENTMASTERDETAILS_SUCCESS,
      saveStatusCode,
      result,
    }),
  
    createAccidentMasterAPIFailure: (saveStatusCode, message) => ({
      type: accidentMasterApiActions.CREATE_ACCIDENTMASTERDETAILS_FAILURE,
      saveStatusCode,
      message,
    }),
  
    DELETE_ACCIDENTMASTERDETAILS: "DELETE_ACCIDENTMASTERDETAILS",
    DELETE_ACCIDENTMASTERDETAILS_SUCCESS: "DELETE_ACCIDENTMASTERDETAILS_SUCCESS",
    DELETE_ACCIDENTMASTERDETAILS_FAILURE: "DELETE_ACCIDENTMASTERDETAILS_FAILURE",
  
    deleteAccidentMasterAPI: (data) => ({
      type: accidentMasterApiActions.DELETE_ACCIDENTMASTERDETAILS,
      payload: { data },
    }),
  
    deleteAccidentMasterAPISuccess: (saveStatusCode, result) => ({
      type: accidentMasterApiActions.DELETE_ACCIDENTMASTERDETAILS_SUCCESS,
      saveStatusCode,
      result,
    }),
  
    deleteAccidentMasterAPIFailure: (saveStatusCode, message) => ({
      type: accidentMasterApiActions.DELETE_ACCIDENTMASTERDETAILS_FAILURE,
      saveStatusCode,
      message,
    }),
  
    UPDATE_ACCIDENTMASTERDETAILS: "UPDATE_ACCIDENTMASTERDETAILS",
    UPDATE_ACCIDENTMASTERDETAILS_SUCCESS: "UPDATE_ACCIDENTMASTERDETAILS_SUCCESS",
    UPDATE_ACCIDENTMASTERDETAILS_FAILURE: "UPDATE_ACCIDENTMASTERDETAILS_FAILURE",
  
    updateAccidentMasterAPI: (data) => ({
      type: accidentMasterApiActions.UPDATE_ACCIDENTMASTERDETAILS,
      payload: { data },
    }),
  
    updateAccidentMasterAPISuccess: (saveStatusCode, result) => ({
      type: accidentMasterApiActions.UPDATE_ACCIDENTMASTERDETAILS_SUCCESS,
      saveStatusCode,
      result,
    }),
  
    updateAccidentMasterAPIFailure: (saveStatusCode, message) => ({
      type: accidentMasterApiActions.UPDATE_ACCIDENTMASTERDETAILS_FAILURE,
      saveStatusCode,
      message,
    }),
  };
  
  export default accidentMasterApiActions;
  