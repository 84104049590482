const tripMonitoringLogApiActions = {
    GET_TRIP_MONITORING_LOG: "GET_TRIP_MONITORING_LOG",
    GET_TRIP_MONITORING_LOG_SUCCESS: "GET_TRIP_MONITORING_LOG_SUCCESS",
    GET_TRIP_MONITORING_LOG_FAILURE: "GET_TRIP_MONITORING_LOG_FAILURE",
  
    getTripMonitoringLogAPI: (data) => ({
      type: tripMonitoringLogApiActions.GET_TRIP_MONITORING_LOG,
      payload: { data },
    }),
  
    getTripMonitoringLogAPISuccess: (saveStatusCode, result) => ({
      type: tripMonitoringLogApiActions.GET_TRIP_MONITORING_LOG_SUCCESS,
      saveStatusCode,
      result,
    }),
  
    getTripMonitoringLogAPIFailure: (saveStatusCode, message) => ({
      type: tripMonitoringLogApiActions.GET_TRIP_MONITORING_LOG_FAILURE,
      saveStatusCode,
      message,
    }),
  };
  
  export default tripMonitoringLogApiActions
  