import actions from "./actions";
import { combineReducers } from "redux";

const initStateQuicksight = {
  loading: false,
  result: null,
  error: false,
};

function QuicksightApi(state = initStateQuicksight, action) {
  switch (action.type) {
    case actions.GET_QUICKSIGHT:
      return {
        ...state,
        loading: true,
      };
    case actions.GET_QUICKSIGHT_SUCCESS:
      return {
        ...state,
        loading: false,
        result: action.result,
      };
    case actions.GET_QUICKSIGHT_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        result: {
          errorResponse: action.saveStatusCode,
          message: action.message,
        },
      };
    default:
      return state;
  }
}

export default combineReducers({
  QuicksightApi,
});
