import actions from "./actions";
import { combineReducers } from "redux";

const initStateAccidentmasterdetails = {
  loading: false,
  result: null,
  error: false,
};

const initStateCreateAccidentmasterdetails = {
  loading: false,
  result: null,
  error: false,
};

const initStateDeleteAccidentmasterdetails = {
  loading: false,
  result: null,
  error: false,
};

const initStateUpdateAccidentmasterdetails = {
  loading: false,
  result: null,
  error: false,
};

function viewAccidentMasterApi(state = initStateAccidentmasterdetails, action) {
  switch (action.type) {
    case actions.GET_ACCIDENTMASTERDETAILS:
      return {
        ...state,
        loading: true,
      };
    case actions.GET_ACCIDENTMASTERDETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        result: action.result,
        actionType: actions.GET_ACCIDENTMASTERDETAILS_SUCCESS,
      };
    case actions.GET_ACCIDENTMASTERDETAILS_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        actionType: actions.GET_ACCIDENTMASTERDETAILS_FAILURE,
        result: {
          errorResponse: action.saveStatusCode,
          message: action.message,
        },
      };
    default:
      return state;
  }
}

function createAccidentMasterApi(
  state = initStateCreateAccidentmasterdetails,
  action
) {
  switch (action.type) {
    case actions.CREATE_ACCIDENTMASTERDETAILS:
      return {
        ...state,
        loading: true,
      };
    case actions.CREATE_ACCIDENTMASTERDETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        result: action.result,
        actionType: actions.CREATE_ACCIDENTMASTERDETAILS_SUCCESS,
      };
    case actions.CREATE_ACCIDENTMASTERDETAILS_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        actionType: actions.CREATE_ACCIDENTMASTERDETAILS_FAILURE,
        result: {
          errorResponse: action.saveStatusCode,
          message: action.message,
        },
      };
    default:
      return state;
  }
}

function deleteAccidentMasterApi(
  state = initStateDeleteAccidentmasterdetails,
  action
) {
  switch (action.type) {
    case actions.DELETE_ACCIDENTMASTERDETAILS:
      return {
        ...state,
        loading: true,
      };
    case actions.DELETE_ACCIDENTMASTERDETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        result: action.result,
        actionType: actions.DELETE_ACCIDENTMASTERDETAILS_SUCCESS,
      };
    case actions.DELETE_ACCIDENTMASTERDETAILS_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        actionType: actions.DELETE_ACCIDENTMASTERDETAILS_FAILURE,
        result: {
          errorResponse: action.saveStatusCode,
          message: action.message,
        },
      };
    default:
      return state;
  }
}

function updateAccidentMasterApi(
  state = initStateUpdateAccidentmasterdetails,
  action
) {
  switch (action.type) {
    case actions.UPDATE_ACCIDENTMASTERDETAILS:
      return {
        ...state,
        loading: true,
      };
    case actions.UPDATE_ACCIDENTMASTERDETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        result: action.result,
        actionType: actions.UPDATE_ACCIDENTMASTERDETAILS_SUCCESS,
      };
    case actions.UPDATE_ACCIDENTMASTERDETAILS_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        actionType: actions.UPDATE_ACCIDENTMASTERDETAILS_FAILURE,
        result: {
          errorResponse: action.saveStatusCode,
          message: action.message,
        },
      };
    default:
      return state;
  }
}

export default combineReducers({
    viewAccidentMasterApi,
  createAccidentMasterApi,
  deleteAccidentMasterApi,
  updateAccidentMasterApi,
});
