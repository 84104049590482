import React, { Component } from "react";
import "../charger_report/index.scss";
import ReactTable from "react-table";
import "react-table/react-table.css";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import BackToScreen from "../../component/back_to_screen";
import ReactTooltip from "react-tooltip";
import ReactLoader from "../../component/react-loader/react-loader.component";
import moment from "moment";
import { addDays } from "date-fns";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import Workbook from "react-excel-workbook";
import chargersReportApiActions from "../../redux/charger_report/actions";

const { getChargersReportAPI } = chargersReportApiActions;
var lastDay = new Date();
lastDay.setHours(lastDay.getHours() - 6);
var now = new Date();
now.setDate(now.getDate());

const timestamp = new Date().getTime();
const yesterday = timestamp - 24*60*60*1000;

var startTimestamp = new Date(yesterday);
startTimestamp.setHours(0);
startTimestamp.setMinutes(0);
startTimestamp.setSeconds(0)

var endTimestamp = new Date();
endTimestamp.setHours(23);
endTimestamp.setMinutes(59);
endTimestamp.setSeconds(58)
class ChargerReport extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // start_date: lastDay,
      // end_date: now,
      start_date: startTimestamp,
      end_date: endTimestamp,
      chargersdata: [],
      filterData: [],
      searchInput: "",
      editingfields: {},
    };
    this.backtodashboardPage = this.backtodashboardPage.bind(this);
  }

  componentDidMount() {
    this.getChargersReport();
  }

  getChargersReport() {
    const { start_date, end_date } = this.state;
    this.setState({ loading: true });

    if (start_date < end_date) {
      const payload = {
      //  user_id: "1",
      user_id : sessionStorage.getItem("userId"),
      customerId: sessionStorage.getItem("customerId"),
        start_timestamp: moment(start_date).format("YYYY-MM-DD HH:mm:ss"),
        end_timestamp: moment(end_date).format("YYYY-MM-DD HH:mm:ss"),
      };
      this.props.getChargersReportAPI(payload);
    } else if (start_date > end_date) {
      alert("End Date should be greater than Start Date");
    } else {
      alert("End Date should be greater than Start Date");
    }
  }
  componentWillReceiveProps(nextProps) {
    //console.log("chargesreport", nextProps);
    if (
      nextProps?.ChargersReportApi?.chargersReportApi?.result?.data
        ?.responseData
    ) {
      this.setState({
        chargersdata:
          nextProps.ChargersReportApi.chargersReportApi.result.data
            .responseData,
      });
      this.setState({
        filterData:
          nextProps.ChargersReportApi.chargersReportApi.result.data
            .responseData,
      });
    }
  }

  // Compare = (name, value) => {
  //   if (name == "start_date") {
  //     //if
  //     this.setState({ start_date: value });
  //   } else if (name == "end_date") {
  //     if (
  //       this.state.start_date != "" &&
  //       this.state.end_date != "" &&
  //       value >= this.state.start_date
  //     ) {
  //       this.setState({ end_date: value });
  //     } else {
  //       if (value < this.state.start_date) {
  //         alert("End Date should be greater than Start Date");
  //       }
  //     }
  //   }
  // };

  handleDateChange(evt) {
    const value = evt.target.value;

    const name = evt.target.name;
    this.setState((prevState) => ({
      editingfields: {
        // object that we want to update

        ...prevState.editingfields, // keep all other key-value pairs
        [name]: moment(value).format("YYYY-MM-DD HH:mm:00"),
        // update the value of specific key
      },
    }));
    this.setState({ [name]: value });
  }

  onSubmit = (e) => {
    this.getChargersReport();
  };

  chargersreporthandleChange = (event) => {
    const search = event.target.value.toLowerCase();
    this.setState({
      searchInput: search,
      filterData: this.state.chargersdata.filter(
        (item) =>
          (item.chargerId && item.chargerId.toLowerCase().includes(search)) ||
          (item.charger_type && item.charger_type.toLowerCase().includes(search))
      ),
    });
  };

  backtodashboardPage() {
    let tabItem = {
      tabIndex: 16,
      tabName: "Dashboard",
    };
    this.props.selectedTab(null, tabItem);
  }

  render() {
    let { searchInput } = this.state;
    return (
      <div className="charger_report">
        {this.props.ChargersReportApi?.chargersReportApi?.loading ? (
          <div className="loader-overlay">
            <div className="loader-container">
              <ReactLoader />
            </div>
          </div>
        ) : null}
        <div className="fleet-health-backward">
          <BackToScreen routingCall={this.backtodashboardPage} />
          <h3 className="charger_report-head">Charger Report</h3>
        </div>

        <div className="charger_report_div">
          <div className="charger_report_div_labels">
            <label className="charger_report_div_labels_style">
              Start Date:
            </label>
            {/* <DatePicker
              name="start_date"
              selected={this.state.start_date}
              maxDate={moment().toDate()}
              value={this.state.start_date}
              onChange={(value) => this.Compare("start_date", value)}
              dateFormat="yyyy-MM-dd"
            /> */}

            <DatePicker
              autocomplete="off"
              name="start_date"
              //selected={this.state.start_date}

              selected={this.state.start_date}
              maxDate={moment().toDate()}
              minDate={addDays(new Date(), -90)}
              value={
                this.state.editingfields.start_date == "undefined"
                  ? this.state.start_date.setHours(0, 0, 0)
                  : this.state.editingfields.start_date
              }
              onChange={(value) =>
                this.handleDateChange({
                  target: { name: "start_date", value },
                })
              }
              //defaultValue={moment(now).toDate()}
              timeInputLabel="Time:"
              dateFormat="yyyy-MM-dd HH:mm:00"
              showTimeInput
            />

            <label className="charger_report_div_labels_style-1">
              End Date:
            </label>
       

            <DatePicker
              autocomplete="off"
              // valueDefault={null}
              //maxDate={moment().toDate()}
              name="end_date"
              //selected={this.state.end_date}
              selected={this.state.end_date}
              maxDate={moment().toDate()}
              minDate={addDays(new Date(), -90)}
              value={
                this.state.editingfields.end_date == "undefined"
                  ? this.state.end_date.setHours(23, 59, 59)
                  : this.state.editingfields.end_date
              }
              onChange={(value) =>
                this.handleDateChange({
                  target: { name: "end_date", value },
                })
              }
              timeInputLabel="Time:"
              dateFormat="yyyy-MM-dd HH:mm:00"
              showTimeInput
            />
          </div>

          <div>
            <button
              className="charger_report_div_button-submit"
              onClick={this.onSubmit}
            >
              <i className="fa fa-check" style={{ paddingRight: "5px" }}></i>
              Submit
            </button>
          </div>
          <div>
            <button
              className="charger_report_div_button-submit-1"
              onClick={this.reset}
            >
              <i className="fa fa-refresh" style={{ paddingRight: "5px" }}></i>{" "}
              Reset
            </button>
          </div>

          <div className="charger_report_search">
            <form
              onSubmit={(e) => {
                e.preventDefault();
              }}
            >
              <input
                className="charger_report_search-input"
                type="text"
                id="filter"
                placeholder="Search Table(Charger ID / Charger Type)"
                value={searchInput || ""}
                onChange={this.chargersreporthandleChange}
              />
            </form>
          </div>

          <div>
            <Workbook
              filename="chargers-Report.xlsx"
              element={
                <div
                  className="charger_report-download"
                  data-tip
                  data-for="registerTip"
                >
                  Export
                  <img
                    src={require("../../assets/images/export/export.png")}
                    alt=""
                    className="charger_report-download-img"
                  />
                  <ReactTooltip id="registerTip" place="top" effect="solid">
                    Download
                  </ReactTooltip>
                </div>
              }
            >
              <Workbook.Sheet
                data={this.state.chargersdata}
                name="chargers-Report"
              >
                <Workbook.Column label="Charger ID" value="chargerId" />
                <Workbook.Column label="Charger Type" value="charger_type" />
                <Workbook.Column label="Date" value="date" />
                <Workbook.Column label="AC Consumption" value="acConsumption" />
                <Workbook.Column
                  label="DC consumption by Charger"
                  value="dcConsumptionByCharger"
                />

                <Workbook.Column
                  label="Power Drawn By Vehicle"
                  value="powerDrawnByVehicle"
                />

                <Workbook.Column
                  label="AC-DC conversion Charger"
                  value="ac_dc_conversionCharger"
                />
                <Workbook.Column
                  label="DC-DC Power conversion"
                  value="dc_dc_powerConversion"
                />
                <Workbook.Column
                  label="AC Input TO Vehicle Battery"
                  value="acInputToDcVehicleBattery"
                />
                <Workbook.Column
                  label="Start Time"
                  value="startTime"
                />
                 <Workbook.Column
                  label="End Time"
                  value="endTime"
                />
                 <Workbook.Column
                  label="Total Charge Time"
                  value="timeChargerOccupied"
                />
              </Workbook.Sheet>
            </Workbook>
          </div>
        </div>

        <div className="charger_report_table">
          <ReactTable
            NoDataComponent={() => null}
            pageSizeOptions={[20, 30, 50, 80, 100, 130, 200, 500]}
            style={{
              height: "490px",
              marginTop: "0%",
              marginRight: "30px",
              marginLeft: "40px",
            }}
            data={this.state.filterData}
            showPagination={true}
            columns={[
              {
                Header: "S.No",
                id: "serialNumber",
                filterable: false,
                resizable: false,
                width: 90,
                Cell: (row) => {
                  return <div>{row.index + 1}</div>;
                },
              },
              {
                Header: "Charger ID",
                id: "chargerId",
                accessor: (d) => d.chargerId,
                width: 110,

                resizable: false,
              },
              {
                Header: "Charger Type",
                id: "charger_type",
                accessor: (d) => d.charger_type,
                width: 130,

                resizable: false,
              },
              
              {
                Header: "Date",
                id: "date",
                accessor: (d) => d.date,
                width: 120,

                filterAll: true,
                resizable: false,
              },
              {
                Header: " AC Consumption ( Kwh )",
                id: "acConsumption",
                accessor: (d) => d.acConsumption,
                width: 160,

                resizable: false,
              },
              {
                Header: "DC Consumption By Charger ( Kwh )",
                id: "dcConsumptionByCharger",
                accessor: (d) => d.dcConsumptionByCharger,
                width: 240,

                filterAll: true,
                resizable: false,
              },

              {
                Header: "Power Drawn By Vehicle ( Kwh )",
                id: "powerDrawnByVehicle",
                accessor: (d) => d.powerDrawnByVehicle,
                width: 190,

                filterAll: true,
                resizable: false,
              },
              {
                Header: "AC-DC Conversion ( % )",
                id: "ac_dc_conversionCharger",
                accessor: (d) => d.ac_dc_conversionCharger,
                width: 160,

                filterAll: true,
                resizable: false,
              },

              {
                Header: "DC-DC Power Conversion ( % )",
                id: "dc_dc_powerConversion",
                accessor: (d) => d.dc_dc_powerConversion,
                width: 190,

                filterAll: true,
                resizable: false,
              },

              {
                Header: "AC Input to Vehicle Battery ( % )",
                id: "acInputToDcVehicleBattery",
                accessor: (d) => d.acInputToDcVehicleBattery,
                width: 190,

                filterAll: true,
                resizable: false,
              },

              {
                Header: "Start Time",
                id: "startTime",
                accessor: (d) => d.startTime,
                width: 190,

                filterAll: true,
                resizable: false,
              },
              {
                Header: "End Time",
                id: "endTime",
                accessor: (d) => d.endTime,
                width: 190,

                filterAll: true,
                resizable: false,
              },
              {
                Header: "Total Charge Time ( hh:mm:ss )",
                id: "timeChargerOccupied",
                accessor: (d) => d.timeChargerOccupied,
                width: 220,

                filterAll: true,
                resizable: false,
              },
            ]}
            defaultSorted={[
              {
                id: "serialNumber",
                asc: true,
              },
            ]}
            defaultPageSize={50}
            className="-striped -highlight"
          >
            {(state, makeTable, instance) => {
              let recordsInfoText = "";

              const { filtered, pageRows, pageSize, sortedData, page } = state;

              if (sortedData && sortedData.length > 0) {
                let isFiltered = filtered.length > 0;

                let totalRecords = sortedData.length;

                let recordsCountFrom = page * pageSize + 1;

                let recordsCountTo = recordsCountFrom + pageRows.length - 1;

                if (isFiltered)
                  recordsInfoText = `${recordsCountFrom}-${recordsCountTo} of ${totalRecords} filtered records`;
                else
                  recordsInfoText = `${recordsCountFrom}-${recordsCountTo} of ${totalRecords} records`;
              } else recordsInfoText = "No records";

              return (
                <div className="main-grid">
                  <div className="above-table text-right">
                    <div className="col-sm-12">
                      <span className="records-info">{recordsInfoText}</span>
                    </div>
                  </div>

                  {makeTable()}
                </div>
              );
            }}
          </ReactTable>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps, {
  getChargersReportAPI,
})(withRouter(ChargerReport));
