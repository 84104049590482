import React, { Component } from "react";
import "./index.scss";
//import Track from "../../container/track";

import { connect } from "react-redux";
import { withRouter } from "react-router";

import {
  Button,
  Container,
  Nav,
  NavLink,
  NavDropdown,
  Navbar,
  Dropdown,
} from "react-bootstrap";
import {
  TAB_MY_DASHBOARD as TabMyDashboard,
  TAB_CHARGING_SUMMARY as TabChargingSummary,
  TAB_CHARGING_INFRASTRUCTURE as TabChargingInfrastructure,
  //TAB_MAP_DASHBOARD as TabMapDashboard,
  TAB_CONNECTED_MODULE as TabConnectedModule,
  //TAB_REPORTS as TabReports,
  TAB_TRACK as TabTrack,
  TAB_LIVE_TRIP_TRACKING as TabLiveTripTracking,
  //TAB_GEOFENCE as TabGeofence,
  //TAB_TRIP_MASTER as TabTripMaster,
  //TAB_TRIP_UPDATE as TabTripUpdate,
  VIEW_REPORTS as TabViewReports,
  TAB_BREAKDOWN_REPORT as TabBreakdownReport,
  TAB_CHECKLIST_REPORT as TabChecklistReport,
  TAB_TRIP_DETAILS as TabTripDetails,
  TAB_REPORTING_DETAILS as TabReportingDetails,
  TAB_DASHBOARD as TabDashboard,
  TAB_REPORT as TabReport,
  TAB_DRIVER_MONITORING as TabDrivermonitoring,
  TAB_UPTIME_MANAGEMENT as TabUptimemanagement,
  TAB_CONTRACTUAL_KPI as TabContractualKPI,
  TAB_FLEET_HEALTH as TabFleethealth,
  TAB_FLEET_UPTIME as TabFleetuptime,
  //TAB_VEHICLE_SUMMARY_REPORT as TabVehicleSummaryReport,
  TAB_CHARGER_REPORT as TabChargerReport,
  TAB_TRIP_SUMMARY as TabTripSummary,
  TAB_DRIVER_DETAILS as TabDriverDetails,
  //TAB_ATTENDANCE as TabAttandance,
  //TAB_TRIP_MONITORING as TabTripmonitoring,
  //TAB_TRIP_MONITORING_LOG as TabTripminitoringlog,
  TAB_MASTER as TabMaster,
  TAB_VEHICLE_MASTER as TabVehicleMaster,
  TAB_ROOT_MASTER as TabRootMaster,
  TAB_DRIVER_MASTER as TabDriverMaster,
  TAB_DAILY_OPERATION_REPORT as TabDailyOperationReport,
  //TAB_FLEET_HEALTH_DETAILS as TabFleetHealthDetails,
  TAB_ACCIDENT_MASTER as TabAccidentMaster,
  TAB_TRIP_MASTER as TabTripMaster,
  TAB_ALERT as TabAlert,
  TAB_ALERT_History as TabAlertHistory,
  TAB_LIVE_ALERT as TabLiveAlert,
  TAB_SPEED_BAND as TabSpeedBand,
  TAB_ONBOARDING as TabOnboarding,
  TAB_ONBOARDING_CREATE_PROJECT as TabCreateproject,
  TAB_ONBOARDING_CREATE_USER as TabCreateUser,
  TAB_ONBOARDING_ASSIGN_PROJECT as TabAssignProject,
  TAB_VEHICLE_SUMMARY_TOGGLE  as TabVehicleSummaryToggle,
  TAB_EFFICIENCY_REPORT as TabEfficiencyReport,
  TAB_DAILY_OP_SHIFT_WISE_REPORT as TabDailyOpWiseReport,
  TAB_ITS_REPORT as TabItsReport,
} from "../../constants/app-contants";
//import { Link } from "react-router-dom";
let tabs = [];
//let loginTabs = [];
const tabInitial = [];

//["Dashboard", "CM", "CIM", "Operation", "Reports", "DM", "UM", "CKPI", "Master"]

class Menuheader extends Component {
  constructor(props) {
    let role = sessionStorage.getItem("roleName");
    super(props);
    // let tabs = "";
    //  if (role == "ADMIN") {

    this.state = {
      tabs,
      isNotificationTrayClick: false,
      button: true,
      loginTabs: [],
      // loginId: User.getLoginId(),
    };

    this.events = [
      "load",
      "mousemove",
      "mousedown",
      "click",
      "scroll",
      "keypress",
    ];
    this.handleClick = this.handleClick.bind(this);
    this.tabClick = this.tabClick.bind(this);
    // this.getRoleBasedMenus = this.getRoleBasedMenus.bind(this);
  }

  getRoleBasedMenus(data) {
    const ml = JSON.parse(data);
    ml.map((item, index) => {
      if (item == "Dashboard") {
        // tabs.push({
        //   tabimages: `${require("../../assets/images/nav-iconss/dashboard.png")}`,
        //   tabIndex: TabDashboard.ID,
        //   tabName: TabDashboard.NAME,
        //   isDropdown: false,
        //   item: item,
        //   key: item,
        // });

        tabs.push({
          //tabimages: `${require("../../assets/home.svg").default} `,
          //tabimages: `${require("../../assets/images/nav-iconss/dashboard.png")}`,
          tabIndex: TabDashboard.ID,
          //tabName: TabDashboard.NAME,
          isDropdown: false,
          item: item,
          key: item,
        });
      } else if (item == "CM") {
        tabs.push({
          tabimages: `${require("../../assets/images/nav-iconss/connected-nav.png")}`,
          tabIndex: TabConnectedModule.ID,
          tabName: TabConnectedModule.NAME,
          isDropdown: true,
          item: item,
          key: item,
          tabs: [
            {
              tabIndex: TabTrack.ID,
              tabName: TabTrack.NAME,
              tabImage: `${require("../../assets/images/track/track.png")}`,
            },
            {
              tabIndex: TabLiveTripTracking.ID,
              tabName: TabLiveTripTracking.NAME,
              tabImage: `${require("../../assets/images/livetrip/livetrip.png")}`,
            },
            // {
            //   tabIndex: TabGeofence.ID,
            //   tabName: TabGeofence.NAME,
            //   tabImage: `${require("../../assets/images/subnav/second.png")}`,
            // },
          ],
          tabImage: `${require("../../assets/images/nav/nav.png")}`,
        });
      } else if (item == "CIM") {
        tabs.push({
          tabimages: `${require("../../assets/images/nav-iconss/charging-infra-nav.png")}`,
          tabIndex: TabChargingInfrastructure.ID,
          tabName: TabChargingInfrastructure.NAME,
          isDropdown: true,
          item: item,
          key: item,
          tabs: [
            {
              tabIndex: TabMyDashboard.ID,
              tabName: TabMyDashboard.NAME,
              tabImage: `${require("../../assets/images/nav-iconss/live-dashbord.png")}`,
            },
            {
              tabIndex: TabChargingSummary.ID,
              tabName: TabChargingSummary.NAME,
              tabImage: `${require("../../assets/images/subnav/second.png")}`,
            },
          ],
        });
      } else if (item == "Operation") {
        tabs.push({
          tabimages: `${require("../../assets/images/nav-iconss/operation-nav.png")}`,
          tabIndex: TabViewReports.ID,
          tabName: TabViewReports.NAME,
          isDropdown: true,
          item: item,
          key: item,
          tabs: [
            // {
            //   tabIndex: TabTripUpdate.ID,
            //   tabName: TabTripUpdate.NAME,
            //   tabImage: `${require("../../assets/images/operation/trip-updation.png")}`,
            // },
  {
              tabIndex: TabTripMaster.ID,
              tabName: TabTripMaster.NAME,
              tabImage: `${require("../../assets/images/operation/trip-updation.png")}`,
            },
            
            {
              tabIndex: TabBreakdownReport.ID,
              tabName: TabBreakdownReport.NAME,
              tabImage: `${require("../../assets/images/operation/breakdown-details.png")}`,
            },
            {
              tabIndex: TabChecklistReport.ID,
              tabName: TabChecklistReport.NAME,
              tabImage: `${require("../../assets/images/operation/driver-feedback.png")}`,
            },
            {
              tabIndex: TabTripDetails.ID,
              tabName: TabTripDetails.NAME,
              tabImage: `${require("../../assets/images/operation/trip-details.png")}`,
            },
            {
              tabIndex: TabReportingDetails.ID,
              tabName: TabReportingDetails.NAME,
              tabImage: `${require("../../assets/images/operation/reporting-details.png")}`,
            },
            {
              tabIndex: TabAccidentMaster.ID,
              tabName: TabAccidentMaster.NAME,
              tabImage: `${require("../../assets/images/reports-icons/charger-report.png")}`,
            },

          ],
        });
      } else if (item == "Reports") {
        tabs.push({
          tabimages: `${require("../../assets/images/nav-iconss/report-nav.png")}`,
          tabIndex: TabReport.ID,
          tabName: TabReport.NAME,
          isDropdown: true,
          item: item,
          key: item,
          tabs: [
            {
              tabIndex: TabTripSummary.ID,
              tabName: TabTripSummary.NAME,
              tabImage: `${require("../../assets/images/subnav/second.png")}`,
            },
            // {
            //   tabIndex: TabVehicleSummaryReport.ID,
            //   tabName: TabVehicleSummaryReport.NAME,
            //   tabImage: `${require("../../assets/images/reports-icons/vehicle-summary.png")}`,
            // },

            {
              tabIndex: TabVehicleSummaryToggle.ID,
              tabName: TabVehicleSummaryToggle.NAME,
              tabImage: `${require("../../assets/images/reports-icons/vehicle-summary.png")}`,
            },
            {
              tabIndex: TabChargerReport.ID,
              tabName: TabChargerReport.NAME,
              tabImage: `${require("../../assets/images/reports-icons/charger-report.png")}`,
            },
            {
              tabIndex: TabDailyOperationReport.ID,
              tabName: TabDailyOperationReport.NAME,
              tabImage: `${require("../../assets/images/subnav/second.png")}`,
            },
            {
              tabIndex: TabSpeedBand.ID,
              tabName: TabSpeedBand.NAME,
              tabImage: `${require("../../assets/images/subnav/second.png")}`,
            },

            {
              tabIndex: TabEfficiencyReport.ID,
              tabName: TabEfficiencyReport.NAME,
              tabImage: `${require("../../assets/images/subnav/second.png")}`,
            },

            {
              tabIndex: TabDailyOpWiseReport.ID,
              tabName: TabDailyOpWiseReport.NAME,
              tabImage: `${require("../../assets/images/subnav/second.png")}`,
            },
            {
              tabIndex: TabItsReport.ID,
              tabName: TabItsReport.NAME,
              tabImage: `${require("../../assets/images/subnav/second.png")}`,
            },
            
          ],
        });
      } else if (item == "DM") {
        tabs.push({
          tabimages: `${require("../../assets/images/nav-iconss/driver-moni-nav.png")}`,
          tabIndex: TabDrivermonitoring.ID,
          tabName: TabDrivermonitoring.NAME,
          isDropdown: true,
          item: item,
          key: item,
          tabs: [
            {
              tabIndex: TabDriverDetails.ID,
              tabName: TabDriverDetails.NAME,
              tabImage: `${require("../../assets/images/fleet-uptime-icons/fleet-health.png")}`,
            },
            // {
            //   tabIndex: TabAttandance.ID,
            //   tabName: TabAttandance.NAME,
            //   tabImage: `${require("../../assets/images/driver-monitoring/attandance.png")}`,
            // },
            // {
            //   tabIndex: TabTripmonitoring.ID,
            //   tabName: TabTripmonitoring.NAME,
            //   tabImage: `${require("../../assets/images/driver-monitoring/trip-moni.png")}`,
            // },
          ],
        });
      } else if (item == "UM") {
        tabs.push({
          tabimages: `${require("../../assets/images/nav-iconss/uptime-nav.png")}`,
          tabIndex: TabUptimemanagement.ID,
          tabName: TabUptimemanagement.NAME,
          isDropdown: true,
          item: item,
          key: item,
          tabs: [
            {
              tabIndex: TabFleethealth.ID,
              tabName: TabFleethealth.NAME,
              tabImage: `${require("../../assets/images/fleet-uptime-icons/fleet-health.png")}`,
            },
            {
              tabIndex: TabFleetuptime.ID,
              tabName: TabFleetuptime.NAME,
              tabImage: `${require("../../assets/images/fleet-uptime-icons/fleet-uptime.png")}`,
            },

            // {
            //   tabIndex: TabFleetHealthDetails.ID,
            //   tabName: TabFleetHealthDetails.NAME,
            //   tabImage: `${require("../../assets/images/driver-monitoring/trip-moni.png")}`,
            // },
          ],
        });
      } else if (item == "CKPI") {
        tabs.push({
          tabimages: `${require("../../assets/images/nav-iconss/kpi.png")}`,
          tabIndex: TabContractualKPI.ID,
          tabName: TabContractualKPI.NAME,
          isDropdown: false,
          item: item,
          key: item,
        });
      } else if (item == "Master") {
        tabs.push({
          tabimages: `${require("../../assets/images/nav-iconss/report-nav.png")}`,
          tabIndex: TabMaster.ID,
          tabName: TabMaster.NAME,
          isDropdown: true,
          item: item,
          key: item,
          tabs: [
            {
              tabIndex: TabVehicleMaster.ID,
              tabName: TabVehicleMaster.NAME,
              tabImage: `${require("../../assets/images/subnav/second.png")}`,
            },
            {
              tabIndex: TabRootMaster.ID,
              tabName: TabRootMaster.NAME,
              tabImage: `${require("../../assets/images/reports-icons/vehicle-summary.png")}`,
            },
            {
              tabIndex: TabDriverMaster.ID,
              tabName: TabDriverMaster.NAME,
              tabImage: `${require("../../assets/images/reports-icons/charger-report.png")}`,
            },
            
          ],
        });
      }
      else if (item == "ALT") {
        tabs.push({
          tabimages: `${require("../../assets/images/nav-iconss/kpi.png")}`,
          tabIndex: TabAlert.ID,
          tabName: TabAlert.NAME,
          isDropdown: true,
          item: item,
          key: item,
          tabs: [
            {
              tabIndex: TabLiveAlert.ID,
              tabName: TabLiveAlert.NAME,
              tabImage: `${require("../../assets/images/subnav/second.png")}`,
            },
            {
              tabIndex: TabAlertHistory.ID,
              tabName: TabAlertHistory.NAME,
              tabImage: `${require("../../assets/images/reports-icons/vehicle-summary.png")}`,
            },
          ]
        });
      }


//// onboarding
      else if(item =="OBD"){
        tabs.push({
          tabimages: `${require("../../assets/images/nav-iconss/kpi.png")}`,
          tabIndex: TabOnboarding.ID,
          tabName: TabOnboarding.NAME,
          isDropdown: true,
          item: item,
          key: item,
          tabs: [
            {
              tabIndex: TabCreateproject.ID,
              tabName: TabCreateproject.NAME,
              tabImage: `${require("../../assets/images/subnav/second.png")}`,
            },
            {
              tabIndex: TabCreateUser.ID,
              tabName: TabCreateUser.NAME,
              tabImage: `${require("../../assets/images/reports-icons/vehicle-summary.png")}`,
            },
            {
              tabIndex: TabAssignProject.ID,
              tabName: TabAssignProject.NAME,
              tabImage: `${require("../../assets/images/reports-icons/vehicle-summary.png")}`,
            },
          ]
        });
      }
    });
  }

  handleClick() {
    this.setState({
      button: !this.state.button,
    });
  }

  // shouldComponentUpdate(nextProps) {
  //   return nextProps.loginApi !== this.props.loginApi;
  // }

  componentDidMount() {
    if (this.fetchPromise) {
      // already mounted previously
      return;
    }
    this.props.history.push("/dashboard");
    //loginTabs = sessionStorage.getItem("roleMenus");
    //console.log("calling menus twice2");
    // this.setState({ loginTabs: sessionStorage.getItem("roleMenus") });
    // tabInitial.push(sessionStorage.getItem("roleMenus"));

    this.getRoleBasedMenus(sessionStorage.getItem("roleMenus"));
    //this.reload();
  }
  componentWillUnmount(){
    tabs=[];
  }

  tabClick = (evt, tabItem) => {
    const { selectedTab } = this.props;
    //console.log("tab in", tabItem);
    if (evt.target.parentElement) {
      let tabs = evt.target.parentElement.getElementsByClassName("tab");
      Array.prototype.forEach.call(tabs, function (tab) {
        tab.classList.remove("active");
      });
    }
    evt.currentTarget.classList.add("active");
    this.setState({ selTab: tabItem.tabIndex });
    selectedTab && selectedTab(evt, tabItem);
  };

  tabClickHome = (evt, tabIndex) => {
    const { selectedTab } = this.props;
    let payload = { tabIndex: 16 };
    // if (evt.target.parentElement) {
    //   let tabs = evt.target.parentElement.getElementsByClassName("tab");
    //   Array.prototype.forEach.call(tabs, function (tab) {
    //     tab.classList.remove("active");
    //   });
    // }
    evt.currentTarget.classList.add("active");
    this.setState({ selTab: tabIndex });
    selectedTab && selectedTab(evt, payload);
  };
  render() {
    const { tabs } = this.state;
    const { isNavMenuDisabled, selTab, isNotificationTrayShow, loginDetails } =
      this.props;
    let tabDom = [],
      profileDom,
      navDom;
    if (tabs && tabs.length > 0) {
      // console.log("tab check", tabs);
      tabs.map((tabItem, index) => {
        if (this.state.loginId === "admin" && tabItem.tabName == "Master") {
        } else {
          let flag = false;
          if (tabItem.isDropdown) {
            tabItem.tabs.map((t) => {
              if (selTab === t.tabIndex) flag = true;
            });
          }
          let className =
            selTab === tabItem.tabIndex || flag
              ? "tabs__tab active tab ripple-effect__dug-egg "
              : "tabs__tab tab ripple-effect__dug-egg";

          if (tabItem.isDropdown) {
            tabDom.push(
              <li className={`${className} dropdown`} key={tabItem.tabIndex}>
                <img src={tabItem.tabimages} className="navicons" alt="" />
                <a>
                  {tabItem.tabName}

                  <img
                    src={require("../../assets/images/nav-up/nav-up.png")}
                    id="tab"
                    alt=""
                    className="nav-up"
                  />
                </a>

                <span className="tab-strip">
                  <img src={tabItem.tabImage} alt="" />
                </span>

                <div class="dropdown-content">
                  {tabItem.tabs.map((t) => (
                    <a
                      onClick={(e) => {
                        this.tabClick(e, t);
                      }}
                    >
                      <span className="hoverTab">
                        <img src={t.tabImage} />
                        <span className="imageSpace"></span> {t.tabName}
                      </span>
                    </a>
                  ))}
                </div>
              </li>
            );
          } else {
            tabDom.push(
              <li
                className={`${className} dropdown`}
                key={tabItem.tabIndex}
                onClick={(e) => {
                  if (!tabItem.isDropdown) this.tabClick(e, tabItem);
                }}
              >
                <img src={tabItem.tabimages} className="navicon" alt="" />
                <a>
                  {tabItem.tabName}

                  <img
                    src={require("../../assets/images/nav-up/nav-up.png")}
                    id="tab"
                    alt=""
                    className="nav-up"
                  />
                </a>
              </li>
            );
          }
        }
      }, this);
    }
// true or false
    if(JSON.parse(sessionStorage.getItem("showMenus"))===true){
    //  console.log("called",tabDom)
      navDom = (
        <div className="header__nav-menu">
       {/* <a href=""> */}
       {/* <img
              src={require("../../assets/home.svg").default}
              alt="home"
              className="home-svg"
              onClick={(e) => {
                this.tabClickHome(e, 16);
              }}
            /> */}
            <div className="home-text"
             onClick={(e) => {
              this.tabClickHome(e, 16);
            }}
            >HOME
            </div>
          {/* </a> */}
          {tabDom}
          
        </div>
      );
    }
    else{
      navDom="";
    }
    
    return <div className="subheader" disabled={true}>{navDom}</div>;
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps, {})(withRouter(Menuheader));
