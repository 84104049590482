import React, { Component } from "react";
import "./index.scss";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import ReactLoader from "../../component/react-loader/react-loader.component";
import ProgressBar from "react-bootstrap/ProgressBar";
import contractalKPIApiActions from "../../redux/contractual_kpi/actions";
const { getFinancialDetailsAPI } = contractalKPIApiActions;

class FinancialKPI extends Component {
  constructor(props) {
    super(props);

    this.state = {
      switchValue: false,
      togglevalues: false,
      data: [],
      DriverScore: [],
      actualRevenueGenerated: "",
      TargetRevenue: "",
      ActualTrips: "",
      TargetTrips: "",
      TotalCompletedTrips: "",
      ScheduledTrips: "",
      RevenueLost: "",
      TotalEfficiency: "",
      EnergyConversionEfficiency: "",
      EnergyConsumed: "",
    };
  }

  componentDidMount() {
    this.getFinancialdetails();
  }

  getFinancialdetails() {
    //const { start_date, end_date } = this.state;
    this.setState({ loading: true });
    const payload = {
     // id: "1",
     user_id: sessionStorage.getItem("userId"),
     customerId: sessionStorage.getItem("customerId"),
      // start_timestamp: moment(start_date).format("YYYY-MM-DD 00:00:00"),
      // end_timestamp: moment(end_date).format("YYYY-MM-DD 23:59:59"),
    };
    this.props.getFinancialDetailsAPI(payload);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props !== nextProps) {
      //console.log("finacial", nextProps);
      if (
        nextProps?.ContractalKpiApi?.finacialDetailsApi?.result?.data
          ?.responseData
      ) {
        this.setState({
          actualRevenueGenerated:
            nextProps?.ContractalKpiApi?.finacialDetailsApi?.result?.data
              ?.responseData.actual_revenue_generated,
        });
        this.setState({
          TargetRevenue:
            nextProps?.ContractalKpiApi?.finacialDetailsApi?.result?.data
              ?.responseData.target_revenue,
        });
        this.setState({
          ActualTrips:
            nextProps?.ContractalKpiApi?.finacialDetailsApi?.result?.data
              ?.responseData.actual_trips,
        });

        this.setState({
          TargetTrips:
            nextProps?.ContractalKpiApi?.finacialDetailsApi?.result?.data
              ?.responseData.target_trips,
        });

        this.setState({
          TotalCompletedTrips:
            nextProps?.ContractalKpiApi?.finacialDetailsApi?.result?.data
              ?.responseData.total_completed_trips,
        });

        this.setState({
          ScheduledTrips:
            nextProps?.ContractalKpiApi?.finacialDetailsApi?.result?.data
              ?.responseData.scheduled_trips,
        });

        this.setState({
          RevenueLost:
            nextProps?.ContractalKpiApi?.finacialDetailsApi?.result?.data
              ?.responseData.revenue_lost,
        });
        this.setState({
          TotalEfficiency:
            nextProps?.ContractalKpiApi?.finacialDetailsApi?.result?.data
              ?.responseData.total_efficiency,
        });
        this.setState({
          EnergyConversionEfficiency:
            nextProps?.ContractalKpiApi?.finacialDetailsApi?.result?.data
              ?.responseData.energy_conversion_efficiency,
        });

        this.setState({
          EnergyConsumed:
            nextProps?.ContractalKpiApi?.finacialDetailsApi?.result?.data
              ?.responseData.energy_consumed,
        });
      }
    }
  }
  render() {
    return (
      <div className="container-fluid">
        {this.props.ContractalKpiApi?.finacialDetailsApi?.loading ? (
          <div className="loader-overlay">
            <div className="loader-container">
              <ReactLoader />
            </div>
          </div>
        ) : null}
        <div className="row ms-2">
          <div className="col-lg-4 width-20  gx-0 shadow bg-body rounded">
            <div className="finacial_kpi_head">
              <div className="finacial_kpi_head-text-1">TRIP SUMMARY</div>
              <div class="content_img">
                <img
                  className="finacial_kpi_head_img-1"
                  src={require("../../assets/images/headernew/head.png")}
                  alt="next"
                />
              </div>
            </div>
            <div>
              <div className="row">
                <div className="col border-right">
                  <p className="kpi_finacial_text-1">
                    Total Completed <br /> Trips
                  </p>
                  <img
                    src={require("../../assets/images/kpi/fina-2.png")}
                    className="finacial_kpi_image-3"
                  />
                  <p className="finacial_kpi_number_tripsummary-1">
                    {this.state.TotalCompletedTrips}
                  </p>
                </div>

                <div className="col">
                  <p className="kpi_finacial_text-2">
                    Scheduled <br /> Trips
                  </p>
                  <img
                    src={require("../../assets/images/kpi/fina-6.png")}
                    className="finacial_kpi_image-2"
                  />
                  <p className="finacial_kpi_number_tripsummary-2">
                    {this.state.ScheduledTrips}
                  </p>
                </div>
              </div>
              <div className="finacial_kpi_progress_bar">
                <ProgressBar style={{ height: "10px" }}>
                  <ProgressBar
                    striped
                    variant="success"
                    now={this.state.TotalCompletedTrips}
                    key={1}
                  />
                  <ProgressBar
                    variant="warning"
                    now={
                      this.state.ScheduledTrips + this.state.TotalCompletedTrips
                    }
                    key={2}
                  />
                </ProgressBar>
              </div>
            </div>
          </div>
          <div className="col-lg-4 width-20 ms-4 gx-0 shadow bg-body rounded">
            <div className="finacial_kpi_head">
              <div className="finacial_kpi_head-text-1">
                TARGET VS ACTUAL TRIP KMS
              </div>
              <div class="content_img">
                <img
                  className="finacial_kpi_head_img-1"
                  src={require("../../assets/images/headernew/head.png")}
                  alt="next"
                />
              </div>
            </div>
            <div className="row">
              <div className="col border-right">
                <p className="kpi_finacial_text-1">Target Trips</p>
                <img
                  src={require("../../assets/images/kpi/fina-2.png")}
                  className="finacial_kpi_image-target"
                />
                <p className="finacial_kpi_number_TargetTrips-1">
                  {this.state.TargetTrips}
                </p>
              </div>

              <div className="col">
                <p className="kpi_finacial_text-2">Actual Trips</p>
                <img
                  src={require("../../assets/images/kpi/fina-6.png")}
                  className="finacial_kpi_image-actal"
                />
                <p className="finacial_kpi_number_TargetTrips-2">
                  {this.state.ActualTrips}
                </p>
              </div>
            </div>

            <div className="finacial_kpi_progress_bar-1">
              <ProgressBar style={{ height: "10px" }}>
                <ProgressBar
                  striped
                  variant="success"
                  now={this.state.TargetTrips}
                  key={1}
                />
                <ProgressBar
                  variant="warning"
                  now={this.state.ActualTrips + this.state.TargetTrips}
                  key={2}
                />
              </ProgressBar>
            </div>
          </div>
          <div className="col-lg-4 width-20 ms-4 gx-0 shadow bg-body rounded">
            <div className="finacial_kpi_head">
              <div className="finacial_kpi_head-text-1">REVENUE LOST</div>
              <div class="content_img">
                <img
                  className="finacial_kpi_head_img-1"
                  src={require("../../assets/images/headernew/head.png")}
                  alt="next"
                />
              </div>
            </div>
            <div>
              {" "}
              <img
                src={require("../../assets/images/kpi/fina-3.png")}
                className="finacial_kpi_image-revenue"
              />
              <span className="finacial_kpi_revenue">
                Rs {this.state.RevenueLost}
              </span>
              <div>
                <p className="finacial_kpi_revenue-text">
                  Lorem ipsum dolor sit amet, consectetur
                  <br />
                  adipiscing elit, sed do eiusmod tempor
                  <br /> Lorem ipsum dolor sit
                </p>
              </div>
            </div>
          </div>

          <div className="col-lg-4 width-20 ms-4 gx-0 shadow bg-body rounded">
            <div className="finacial_kpi_head">
              <div className="finacial_kpi_head-text-1">
                {" "}
                TARGET VS ACTUAL REVENUE GENERATED
              </div>
              <div class="content_img">
                <img
                  className="finacial_kpi_head_img-1"
                  src={require("../../assets/images/headernew/head.png")}
                  alt="next"
                />
              </div>
            </div>
            <div className="row">
              <div className="col border-right">
                <p className="kpi_finacial_text-1">Target Revenue</p>
                <img
                  src={require("../../assets/images/kpi/fina-4.png")}
                  className="finacial_kpi_image-Revenue"
                />
                <p className="finacial_kpi_number_target_revenue-1">
                  {this.state.TargetRevenue}
                </p>
              </div>

              <div className="col">
                <p className="kpi_finacial_text-2">
                  Actual Revenue <br /> Generated
                </p>
                <img
                  src={require("../../assets/images/kpi/fina-5.png")}
                  className="finacial_kpi_image-Revenue-1"
                />
                <p className="finacial_kpi_number_3">
                  {this.state.actualRevenueGenerated}
                </p>
              </div>
            </div>

            <div className="finacial_kpi_progress_bar-2">
              <ProgressBar style={{ height: "10px" }}>
                <ProgressBar
                  striped
                  variant="success"
                  now={this.state.TargetRevenue}
                  key={1}
                />
                <ProgressBar
                  variant="warning"
                  now={
                    this.state.actualRevenueGenerated + this.state.TargetRevenue
                  }
                  key={2}
                />
              </ProgressBar>
            </div>
          </div>
        </div>

        <div className="row mt-5 ms-2">
          <div className="col-lg-4 width-20  gx-0 shadow bg-body rounded">
            <div className="finacial_kpi_head">
              <div className="finacial_kpi_head-text-1">
                ENERGY CONSUMED (KWH)
              </div>
              <div class="content_img">
                <img
                  className="finacial_kpi_head_img-1"
                  src={require("../../assets/images/headernew/head.png")}
                  alt="next"
                />
              </div>
            </div>
            <div>
              <img
                src={require("../../assets/images/kpi/fina-8.png")}
                className="finacial_kpi_image-revenue"
              />
              <span className="finacial_kpi_revenue">
                {this.state.EnergyConsumed} Kwh
              </span>
              <div>
                <p className="finacial_kpi_revenue-text">
                  Lorem ipsum dolor sit amet, consectetur
                  <br />
                  adipiscing elit, sed do eiusmod tempor
                  <br /> Lorem ipsum dolor sit
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 width-20 ms-4 gx-0 shadow bg-body rounded">
            <div className="finacial_kpi_head">
              <div className="finacial_kpi_head-text-1">
                {" "}
                ENERGY CONVERSION EFFICIENCY (%)
              </div>
              <div class="content_img">
                <img
                  className="finacial_kpi_head_img-1"
                  src={require("../../assets/images/headernew/head.png")}
                  alt="next"
                />
              </div>
            </div>
            <img
              src={require("../../assets/images/kpi/fina-1.png")}
              className="finacial_kpi_image-5"
            />
            <span className="finacial_kpi_revenue">
              {this.state.EnergyConversionEfficiency} %
            </span>
            <div>
              <p className="finacial_kpi_revenue-text">
                Lorem ipsum dolor sit amet, consectetur
                <br />
                adipiscing elit, sed do eiusmod tempor
                <br /> Lorem ipsum dolor sit
              </p>
            </div>
          </div>
          <div className="col-lg-4 width-20 ms-4 gx-0 shadow bg-body rounded">
            <div className="finacial_kpi_head">
              <div className="finacial_kpi_head-text-1">
                TOTAL EFFICIENCY (KWH/KM)
              </div>
              <div class="content_img">
                <img
                  className="finacial_kpi_head_img-1"
                  src={require("../../assets/images/headernew/head.png")}
                  alt="next"
                />
              </div>
            </div>
            <div>
              {" "}
              <img
                src={require("../../assets/images/kpi/fina-1.png")}
                className="finacial_kpi_image-5"
              />
              <span className="finacial_kpi_revenue">
                {this.state.TotalEfficiency} Kwh/Km
              </span>
              <div>
                <p className="finacial_kpi_revenue-text">
                  Lorem ipsum dolor sit amet, consectetur
                  <br />
                  adipiscing elit, sed do eiusmod tempor
                  <br /> Lorem ipsum dolor sit
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps, {
  getFinancialDetailsAPI,
})(withRouter(FinancialKPI));
