const dailyShiftWiseReportApiActions = {
    GET_DAILYSHIFTWISEREPORT: "GET_DAILYSHIFTWISEREPORT",
    GET_DAILYSHIFTWISEREPORT_SUCCESS: "GET_DAILYSHIFTWISEREPORT_SUCCESS",
    GET_DAILYSHIFTWISEREPORT_FAILURE: "GET_DAILYSHIFTWISEREPORT_FAILURE",
  
    getDailyShiftWiseReportAPI: (data) => ({
      type: dailyShiftWiseReportApiActions.GET_DAILYSHIFTWISEREPORT,
      payload: { data },
    }),
  
    getDailyShiftWiseReportAPISuccess: (saveStatusCode, result) => ({
      type: dailyShiftWiseReportApiActions.GET_DAILYSHIFTWISEREPORT_SUCCESS,
      saveStatusCode,
      result,
    }),
  
    getDailyShiftWiseReportAPIFailure: (saveStatusCode, message) => ({
      type: dailyShiftWiseReportApiActions.GET_DAILYSHIFTWISEREPORT_FAILURE,
      saveStatusCode,
      message,
    }),
  };
  
  export default dailyShiftWiseReportApiActions;
  