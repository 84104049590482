import React, { Component } from "react";
import ReactTable from "react-table";
import "react-table/react-table.css";
import { connect } from "react-redux";
import { addDays } from "date-fns";
import { withRouter } from "react-router";
import "./index.scss";
import moment from "moment";
import BackToScreen from "../../component/back_to_screen";
import ReactTooltip from "react-tooltip";
import ReactLoader from "../../component/react-loader/react-loader.component";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Workbook from "react-excel-workbook";
import alertApiActions from "../../redux/alert/actions";

const { getAlertAPI } = alertApiActions;
var now = new Date();
now.setDate(now.getDate());
var lastDay = new Date();

lastDay.setHours(lastDay.getHours() - 6);
var startTimestamp = new Date();
startTimestamp.setHours(0);
startTimestamp.setMinutes(0);
startTimestamp.setSeconds(0);

var endTimestamp = new Date();
endTimestamp.setHours(23);
endTimestamp.setMinutes(59);
endTimestamp.setSeconds(58);
class AlertHistory extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      // start_date: lastDay,
      // end_date: now,
      start_date: startTimestamp,
      end_date: endTimestamp,
      dataSet: [],
      filteredData: [],
      inputSearch: "",
      editingfields: {},
    };
    this.backtodashboardPage = this.backtodashboardPage.bind(this);
    //this.getTripSummaryReport = this.getTripSummaryReport.bind(this);
  }

  handleDateChange(evt) {
    const value = evt.target.value;

    const name = evt.target.name;
    this.setState((prevState) => ({
      editingfields: {
        // object that we want to update

        ...prevState.editingfields, // keep all other key-value pairs
        [name]: moment(value).format("YYYY-MM-DD HH:mm:00"),
        // update the value of specific key
      },
    }));
    this.setState({ [name]: value });
  }

  getLoader = () => (
    <div className="loader-overlay">
      <div className="loader-container">
        <ReactLoader />
      </div>
    </div>
  );
  backtodashboardPage() {
    let tabItem = {
      tabIndex: 16,
      tabName: "Dashboard",
    };
    this.props.selectedTab(null, tabItem);
  }

  componentDidMount() {
    this.getAlertReport();
  }

  getAlertReport() {
    const { start_date, end_date } = this.state;
    this.setState({ loading: true });
    const payload = {
      // user_id: "1",
      user_id: sessionStorage.getItem("userId"),
      customerId: sessionStorage.getItem("customerId"),
      status: "Closed",
      start_timestamp: moment(start_date).format("YYYY-MM-DD HH:mm:ss"),
      end_timestamp: moment(end_date).format("YYYY-MM-DD HH:mm:ss"),
    };
    this.props.getAlertAPI(payload);
  }
  componentWillReceiveProps(nextProps) {
    //console.log("alertreport", nextProps);
    if (nextProps?.AlertApi?.alertApi?.result?.data?.responseData) {
      this.setState({
        Alertdata: nextProps.AlertApi.alertApi.result.data.responseData,
      });
      this.setState({
        filterData: nextProps.AlertApi.alertApi.result.data.responseData,
      });
    }
  }

  onSubmit = (e) => {
    this.getAlertReport();
  };

  alertreporthandleChange = (event) => {
    const search = event.target.value.toLowerCase();
    this.setState({
      searchInput: search,
      filterData: this.state.Alertdata.filter(
        (item) =>
          (item.alert_name && item.alert_name.toLowerCase().includes(search)) ||
          (item.type && item.type.toLowerCase().includes(search)) ||
          (item.reg_no && item.reg_no.toLowerCase().includes(search)) ||
          (item.vin && item.vin.toLowerCase().includes(search)) ||
          (item.driver_name &&
            item.driver_name.toLowerCase().includes(search)) ||
          (item.route_id && item.route_id.toLowerCase().includes(search))
      ),
    });
  };

  render() {
    let { searchInput } = this.state;
    return (
      <div className="alert_history">
        {this.props.AlertApi?.alertApi?.loading ? (
          <div className="loader-overlay">
            <div className="loader-container">
              <ReactLoader />
            </div>
          </div>
        ) : null}
        <div className="fleet-health-backward">
          <BackToScreen routingCall={this.backtodashboardPage} />
          <h3 className="alert_history-head">Alert History</h3>
        </div>

        <div className="alert_history_div">
          <div className="alert_history_div_labels">
            <label className="alert_history_div_labels_style">
              Start Date:
            </label>
            {/* <DatePicker
                  name="start_date"
                  selected={this.state.start_date}
                  maxDate={moment().toDate()}
                  value={this.state.start_date}
                  onChange={(value) => this.Compare("start_date", value)}
                  dateFormat="yyyy-MM-dd"
                /> */}

            <DatePicker
              autocomplete="off"
              name="start_date"
              //selected={this.state.start_date}

              selected={this.state.start_date}
              maxDate={moment().toDate()}
              minDate={addDays(new Date(), -90)}
              //value={this.state.editingfields.start_date}
              value={
                this.state.editingfields.start_date == "undefined"
                  ? this.state.start_date.setHours(0, 0, 0)
                  : this.state.editingfields.start_date
              }
              onChange={(value) =>
                this.handleDateChange({
                  target: { name: "start_date", value },
                })
              }
              timeInputLabel="Time:"
              dateFormat="yyyy-MM-dd HH:mm:00"
              showTimeInput
            />

            <label className="alert_history_div_labels_style-1">
              End Date:
            </label>

            <DatePicker
              autocomplete="off"
              name="end_date"
              selected={this.state.end_date}
              maxDate={moment().toDate()}
              minDate={addDays(new Date(), -90)}
              value={
                this.state.editingfields.end_date == "undefined"
                  ? this.state.end_date.setHours(23, 59, 59)
                  : this.state.editingfields.end_date
              }
              onChange={(value) =>
                this.handleDateChange({
                  target: { name: "end_date", value },
                })
              }
              timeInputLabel="Time:"
              dateFormat="yyyy-MM-dd HH:mm:00"
              showTimeInput
            />
          </div>

          <div>
            <button
              className="alert_history_div_button-submit"
              onClick={this.onSubmit}
            >
              <i className="fa fa-check" style={{ paddingRight: "5px" }}></i>
              Submit
            </button>
          </div>
          <div>
            <button
              className="alert_history_div_button-submit-1"
              onClick={this.reset}
            >
              <i className="fa fa-refresh" style={{ paddingRight: "5px" }}></i>{" "}
              Reset
            </button>
          </div>

          <div className="alert_history_search">
            <form
              onSubmit={(e) => {
                e.preventDefault();
              }}
            >
              <input
                className="alert_history_search-input"
                type="text"
                id="filter"
                placeholder="Search Table(Alert Name/Vin/Reg No/Route ID/Driver Name)"
                value={searchInput || ""}
                onChange={this.alertreporthandleChange}
              />
            </form>
          </div>

          <div>
            <Workbook
              filename="alert_history.xlsx"
              element={
                <div
                  className="alert_history_download"
                  data-tip
                  data-for="registerTip"
                >
                  Export
                  <img
                    src={require("../../assets/images/export/export.png")}
                    alt=""
                    className="alert_history_download-img"
                  />
                  <ReactTooltip id="registerTip" place="top" effect="solid">
                    Download
                  </ReactTooltip>
                </div>
              }
            >
              <Workbook.Sheet data={this.state.Alertdata} name="alert-Report">
                <Workbook.Column label="Reg No." value="reg_no" />
                <Workbook.Column label="Vin" value="vin" />
                <Workbook.Column label="Driver Name" value="driver_name" />
                <Workbook.Column label="Route ID" value="route_id" />
                <Workbook.Column label="Alert Name" value="alert_name" />
                <Workbook.Column label="Status" value="status" />
               <Workbook.Column label="Type" value="type" />
              
                {/* <Workbook.Column label="Alert ID" value="alert_id" /> */}
                <Workbook.Column label="created Time" value="created_time" />
              
                <Workbook.Column label="Closed Time" value="closed_time" />
              
                <Workbook.Column label="SOC" value="soc" />
                <Workbook.Column label="ODO" value="odo" />
                <Workbook.Column
                  label="Battery Temperature"
                  value="battery_temp"
                />
                   <Workbook.Column label="Speed" value="speed" />
            
                <Workbook.Column label="Latitude" value="latitude" />
                <Workbook.Column label="Longitude" value="longitude" />
              </Workbook.Sheet>
            </Workbook>
          </div>
        </div>

        <div style={{ marginTop: "-30px" }}>
          <ReactTooltip place="left" type="success" effect="solid" />
          <ReactTable
            NoDataComponent={() => null}
            pageSizeOptions={[20, 30, 50, 80, 100, 130, 200, 500]}
            style={{
              height: "490px",
              marginTop: "0%",
              marginRight: "30px",
              marginLeft: "40px",
            }}
            data={this.state.filterData}
            showPagination={true}
            columns={[
              {
                Header: "S.No",
                id: "serialNumber",
                filterable: false,
                resizable: false,
                width: 90,
                Cell: (row) => {
                  return <div>{row.index + 1}</div>;
                },
              },
              {
                Header: "Reg No.",
                id: "reg_no",
                accessor: (d) => d.reg_no,
                width: 140,

                resizable: false,
              },
              {
                Header: "VIN",
                id: "vin",
                accessor: (d) => d.vin,
                width: 170,

                resizable: false,
              },
              {
                Header: "Driver Name",
                id: "driver_name",
                accessor: (d) => d.driver_name,
                width: 150,

                resizable: false,
              },
              {
                Header: "Route ID",
                id: "route_id",
                accessor: (d) => d.route_id,
                width: 150,

                resizable: false,
              },
              // {
              //   Header: "Alert ID",
              //   id: "alert_id",
              //   accessor: (d) => d.alert_id,
              //   width: 100,

              //   resizable: false,
              // },
              {
                Header: "Alert Name",
                id: "alert_name",
                accessor: (d) => d.alert_name,
                width: 180,

                filterAll: true,
                resizable: false,
              },
              {
                Header: "Status",
                id: "status",
                accessor: (d) => d.status,
                width: 150,

                filterAll: true,
                resizable: false,
              },
              {
                Header: "Type ",
                id: "type",
                accessor: (d) => d.type,
                width: 160,

                filterAll: true,
                resizable: false,
              },

              {
                Header: "Created Time",
                id: "created_time",
                accessor: (d) => d.created_time,
                width: 140,

                filterAll: true,
                resizable: false,
              },
              {
                Header: "Closed Time ",
                id: "closed_time                ",
                accessor: (d) => d.closed_time,
                width: 140,

                resizable: false,
              },

              // {
              //   Header: "Received Time ",
              //   id: "received_time ",
              //   accessor: (d) => d.received_time,
              //   width: 180,

              //   filterAll: true,
              //   resizable: false,
              // },

              {
                Header: "Odometer (Km)",
                id: "odo",
                accessor: (d) => d.odo,
                width: 150,

                filterAll: true,
                resizable: false,
              },
              {
                Header: "SOC (%)",
                id: "soc",
                accessor: (d) => d.soc,
                width: 150,

                filterAll: true,
                resizable: false,
              },
              {
                Header: "Battery Temperature",
                id: "battery_temp",
                accessor: (d) => d.battery_temp,
                width: 140,

                filterAll: true,
                resizable: false,
              },
              {
                Header: "Speed",
                id: "speed",
                accessor: (d) => d.speed,
                width: 100,

                resizable: false,
              },
              {
                Header: "Latitude",
                id: "latitude",
                accessor: (d) => d.latitude,
                width: 140,

                filterAll: true,
                resizable: false,
              },
              {
                Header: "Longitude",
                id: "longitude",
                accessor: (d) => d.longitude,
                width: 140,

                filterAll: true,
                resizable: false,
              },
            ]}
            defaultSorted={[
              {
                id: "serialNumber",
                asc: true,
              },
            ]}
            defaultPageSize={50}
            className="-striped -highlight"
          >
            {(state, makeTable, instance) => {
              let recordsInfoText = "";

              const { filtered, pageRows, pageSize, sortedData, page } = state;

              if (sortedData && sortedData.length > 0) {
                let isFiltered = filtered.length > 0;

                let totalRecords = sortedData.length;

                let recordsCountFrom = page * pageSize + 1;

                let recordsCountTo = recordsCountFrom + pageRows.length - 1;

                if (isFiltered)
                  recordsInfoText = `${recordsCountFrom}-${recordsCountTo} of ${totalRecords} filtered records`;
                else
                  recordsInfoText = `${recordsCountFrom}-${recordsCountTo} of ${totalRecords} records`;
              } else recordsInfoText = "No records";

              return (
                <div className="main-grid">
                  <div className="above-table text-right">
                    <div className="col-sm-12">
                      <span className="records-info">{recordsInfoText}</span>
                    </div>
                  </div>

                  {makeTable()}
                </div>
              );
            }}
          </ReactTable>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps, {
  getAlertAPI,
})(withRouter(AlertHistory));
