import React, { Component } from "react";
import "../view_more_button/index.scss";
import { connect } from "react-redux";
import { withRouter } from "react-router";

class ViewMoreButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      //   addModalshow: false,
      //   selecteddata: {},
      //   addModalshows: false,
      //   selecteddatas: {},
      //   open: false,
    };
  }

  setdata = (data) => {
    // let rowData = this.params;
    // let i = rowData.rowIndex;
    //  this.setState({ addModalshow: true, selecteddata: data });console.log("att", this.props.function());
   // console.log("test",data)
    sessionStorage.setItem("driverMonitoringIDClicked", data);
    this.props.function();
  };

  setdataTripLog = (data) => {
    // let rowData = this.params;
    // let i = rowData.rowIndex;
    //  this.setState({ addModalshow: true, selecteddata: data });
    sessionStorage.setItem("driverMonitoringIDClicked", data);
    this.props.function2();
  };

  render() {
    // let { inputSearch } = this.state;
    //console.log("view ore", this.props.data);
    return (
      <div>
        {this.props.componentName == "driverDetails" ? (
          <div>
            <button
              onClick={() => this.setdata(this.props.data.original.id)}
              className="driver-details-action"
            >
              Attendance
              <img
                className="driverdetails-action-img"
                src={require("../../assets/images/driver-monitoring/view-more.png")}
                alt=""
              />
            </button>
            <span></span>
            <button
              onClick={() => this.setdataTripLog(this.props.data.original.id)}
              className="driver-details-action"
            >
              Trip Log
              <img
                className="driverdetails-action-img"
                src={require("../../assets/images/driver-monitoring/view-more.png")}
                alt=""
              />
            </button>
          </div>
        ) : (
          <div>
            <button
              onClick={() => this.setdata(this.props.data.original.id)}
              className="driver-details-action"
            >
              View More
              <img
                className="driverdetails-action-img"
                src={require("../../assets/images/driver-monitoring/view-more.png")}
                alt=""
              />
            </button>
          </div>
        )}
      </div>
    );
  }
}

export default ViewMoreButton;
