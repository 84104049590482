
import React, { Component } from "react";
import "./index.scss";
import { connect } from "react-redux";
import DatePicker from "react-datepicker";
import moment from "moment";
import TimePicker from "rc-time-picker";
import 'rc-time-picker/assets/index.css';
import { withRouter } from "react-router";
import ReactLoader from "../../component/react-loader/react-loader.component";
import accidentMasterApiActions from "../../redux/master_accident/actions";

const { createAccidentMasterAPI } = accidentMasterApiActions;
class MasterAccidentCreate extends Component {
  constructor(props) {
    super(props);

    this.state = {
      editingfields: {},
      message: "",
      messageType: 0,
      accident_reason: "",
      actual_cost: "",
      additional_remarks: "",
      air_serial_number: "",
      bus_on_road_date: "",
      corrective_action: "",
      cost_of_labour: "",
      cost_of_part: "",
     // createdtimestamp_ist: "",
      damaged_parts: "",
      date: "",
      description: "",
      driver_badge_number: "",
      driver_name: "",
      estimation_cost: "",
      id: "",
      immediate_action: "",
      insurance_claim_required: "",
      job_card_number: "",
      loss: "",
      loss_of_revenue: "",
      major_minor: "",
      planned_days_for_repair_the_bus: "",
      recovry_from_insurance: "",
      registration_number: "",
      time: "",
      total_days_used_for_accident_repair: "",
      type: "",
      vehicleid: "",
      driverList: [],
      vehicleList: [],
      soc:"",
      odometer:""
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    //console.log("props check component", this.props);
    this.setState({
      // show: this.props.show,
      // editingfields: this.props.data,
      // driverList: this.props.driverData,
      // vehicleList: this.props.vehicleData,

      driverList:
        this.props.driverlistDropdownApi?.driverlistDropdownApi?.result?.data
          ?.responseData,
      vehicleList:
        this.props.vehiclelistDropdownApi?.vehiclelistDropdownApi?.result?.data
          ?.responseData,
    });
  }

  componentDidUpdate(prevProps) {
   // console.log("accidentcreate", this.props);

    if (prevProps != this.props) {
      this.setState({
        driverList:
          this.props.driverlistDropdownApi?.driverlistDropdownApi?.result?.data
            ?.responseData,
        vehicleList:
          this.props.vehiclelistDropdownApi?.vehiclelistDropdownApi?.result
            ?.data?.responseData,
      });

      if (
        this.props.AccidentMaster?.createAccidentMasterApi?.result?.status ===
        200
      ) {
        this.setState({
          message: "Accident has been created successfully",
          messageType: 1,
        });
        this.messageTimeOut = setTimeout(
          () => this.onClickCloseMessage(),
          3 * 1000
        );
        // let data = JSON.parse(this.props.uploadTrip.result);
        // this.downloadResult(data);
      } else {
        this.setState({
          message: "Accident can not been created",
          messageType: -1,
        });
        this.messageTimeOut = setTimeout(
          () => this.onClickCloseMessage(),
          3 * 1000
        );
      }
    }
  }


  handleSubmit = (event) => {
    event.preventDefault();
    const {  accident_reason,
      actual_cost,
      additional_remarks,
      air_serial_number,
      bus_on_road_date,
     // corrective_action,
      cost_of_labour,
      cost_of_part,
      //createdtimestamp_ist,
      damaged_parts,
      date ,
      description,
      driver_badge_number,
      driver_name ,
      estimation_cost ,
      immediate_action,
      insurance_claim_required,
      job_card_number,
      loss,
      //loss_of_revenue ,
      major_minor,
      planned_days_for_repair_the_bus,
      recovry_from_insurance,
      registration_number,
      time,
      total_days_used_for_accident_repair,
      //type,
      vehicleid,
      reg_no,
      name_tagid,
      reg_vin,
      soc,
      odometer

    } =  this.state;

    //console.log("editing field", this.state.editingfields)

    let vehicleId= reg_vin.split("->");
    let driverId = name_tagid.split("->");

    //console.log("payload");
    const payload = {
      //user_id: "1",
      user_id : sessionStorage.getItem("userId"),
      customerId: sessionStorage.getItem("customerId"),
      accident_reason,
    actual_cost,
    additional_remarks,
    air_serial_number:this.state.air_serial_number,
    bus_on_road_date,
    //corrective_action,
    cost_of_labour,
    cost_of_part,
   // createdtimestamp_ist,
    damaged_parts,
    date ,
    description,
    driver_badge_number:driverId[1],
    estimation_cost ,
    immediate_action,
    insurance_claim_required,
    job_card_number,
    loss,
    //loss_of_revenue ,
    major_minor,
    planned_days_for_repair_the_bus,
    recovry_from_insurance,
    registration_number,
    time:moment(this.state.time).format("HH:mm"),
    total_days_used_for_accident_repair,
   // type,
    vehicleid:vehicleId[0],
    soc,
    odometer

    };
    this.props.createAccidentMasterAPI(payload);
    this.resetButtonClick();
  };

  handleChange = (evt) => {
    const value = evt.target.value;
    //console.log("driver_name", value);
    this.setState({
      ...this.state,
      [evt.target.name]: value,
    });
    const array = [];
    array.push(this.state);
  };




  handleDateChange(evt) {
    const value = evt.target.value;

    const name = evt.target.name;
    this.setState((prevState) => ({
      editingfields: {
        // object that we want to update

        ...prevState.editingfields, // keep all other key-value pairs
        [name]: moment(value).format("YYYY-MM-DD HH:mm:00"),
        // update the value of specific key
      },
    }));
    this.setState({ [name]: value });
  }

  resetButtonClick = () => {
    this.setState({
      accident_reason: "",
      actual_cost: "",
      additional_remarks: "",
      air_serial_number: "",
      bus_on_road_date: "",
      corrective_action: "",
      cost_of_labour: "",
      cost_of_part: "",
      damaged_parts: "",
      date: "",
      description: "",
      driver_badge_number: "",
      driver_name: "",
      estimation_cost: "",
      id: "",
      immediate_action: "",
      insurance_claim_required: "",
      job_card_number: "",
      loss: "",
      loss_of_revenue: "",
      major_minor: "",
      planned_days_for_repair_the_bus: "",
      recovry_from_insurance: "",
      registration_number: "",
      time:   "",
      total_days_used_for_accident_repair: "",
      type: "",
      vehicleid: "",
      soc:"",
      odometer:""
    });
  };

  onClickCloseMessage() {
    this.messageTimeOut = null;
    this.setState({
      message: "",
      messageType: 0,
    });
  }
  render() {
   // console.log("state check", this.state.editingfields);
    return (
      <div className="accident-creation-master">
        {this.state.message && (
          <div
            className={`alert ${
              this.state.messageType == 1
                ? "success"
                : this.state.messageType == -1
                ? "error"
                : ""
            }`}
          >
            <span
              className="closebtn"
              onClick={() => this.onClickCloseMessage()}
            >
              &times;
            </span>
            <strong>{this.state.message}</strong>
          </div>
        )}

        {this.props.AccidentMaster?.createAccidentMasterApi?.loading ? (
          <div className="loader-overlay">
            <div className="loader-container">
              <ReactLoader />
            </div>
          </div>
        ) : null}
        <form className="form-horizontal" onSubmit={this.handleSubmit}>
          <div className="formwidth">
            <div className="col_50">
              <div className="col_100">
                <label>
                  Vehicle:<span style={{ color: "red" }}> *</span>
                </label>
              </div>
              <div className="col_100">
                <select
                  placeholder="Registration Number:"
                  name="reg_vin"
                  onChange={this.handleChange}
                  value={this.state.reg_vin}
                >
                  <option defaultValue=""></option>
                  {this.state.vehicleList.map((e, i) => (
                    <option key={i} value={e.reg_vin}>
                      {e.reg_vin}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col_50">
              <div className="col_100">
                <label>
                  Driver Badge No:<span style={{ color: "red" }}> *</span>
                </label>
              </div>
              <div className="col_100">
                <select
                  placeholder="Driver Name:"
                  name="name_tagid"
                  onChange={this.handleChange}
                  value={this.state.name_tagid}
                >
                  <option defaultValue=""> </option>
                  {this.state.driverList.map((e, i) => (
                    <option key={i} value={e.name_tagid}>
                      {e.name_tagid}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
       

          <div className="formwidth">
         
            <div className="col_50">
              <div className="col_100">
                <label>
                  Time Of Accident:<span style={{ color: "red" }}> *</span>
                  {/* <span style={{ color: "red" }}> *</span> */}
                </label>
              </div>
              <div className="col_100">
                <div className="col_100">

                <TimePicker
                      showSecond={false}
                      className="xxx" 
                      TimeFormat="HH:mm:00"
                      placeholder="hh:mm"
                      //defaultValue={now}
                      value={this.state.time}
                      selected={this.state.time}
                      onChange={(value) => {
                        this.setState({ time: value });
                      }}
                      //selected={this.state.time}
                      name="time"
                      required={true}
                    />


                  {/* <input
                    type="text"
                    // placeholder="Description"
                    name="time"
                    style={{ textTransform: "uppercase" }}
                    value={this.state.time}
                    onChange={this.handleChange}
                    autocomplete="off"
                    required
                  /> */}
                </div>
              </div>
            </div>


            <div className="col_50">
              <div className="col_100">
                <label>
                  Date Of Accident:<span style={{ color: "red" }}> *</span>
                </label>
              </div>
              <div className="col_100">
                <div className="col_100">

                <input
                      type="Date"
                      maxDate={moment().toDate()}
                      //placeholder="Trip Date"
                      name="date"
                      value={this.state.date}
                      onChange={this.handleChange}
                      autocomplete="off"
                      required
                    />
                  {/* <input
                    type="text"
                    // placeholder="Description"
                    name="date"
                    style={{ textTransform: "uppercase" }}
                    value={this.state.editingfields.date}
                    onChange={this.handleChange}
                    autocomplete="off"
                    required
                  /> */}
                </div>
              </div>
            </div>
          </div>
          <div className="formwidth">
            <div className="col_50">
              <div className="col_100">
                <label>
                  Description:<span style={{ color: "red" }}> *</span>
                </label>
              </div>
              <div className="col_100">
                <div className="col_100">
                  <input
                    type="text"
                    //placeholder="Description"
                    name="description"
                    style={{ textTransform: "uppercase" }}
                    value={this.state.description}
                    onChange={this.handleChange}
                    autocomplete="off"
                    required
                  />
                </div>
              </div>
            </div>

            <div className="col_50">
              <div className="col_100">
                <label>
                  Immediate Action:
                </label>
              </div>
              <div className="col_100">
                <div className="col_100">
                  <input
                    type="text"
                    //   placeholder="Description"
                    name="immediate_action"
                    style={{ textTransform: "uppercase" }}
                    value={this.state.immediate_action}
                    onChange={this.handleChange}
                    autocomplete="off"
                    //  required
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="formwidth">
        
          <div className="col_50">
              <div className="col_100">
                <label>
                  SOC:
                </label>
              </div>
              <div className="col_100">
                <div className="col_100">
                  <input
                    type="number"
                    //   placeholder="Description"
                    name="soc"
                    style={{ textTransform: "uppercase" }}
                    value={this.state.soc}
                    onChange={this.handleChange}
                    autocomplete="off"
                    //  required
                  />
                </div>
              </div>
            </div>



            <div className="col_50">
              <div className="col_100">
                <label>
                  AIR Serial No:
                  {/* <span style={{ color: "red" }}> *</span> */}
                </label>
              </div>
              <div className="col_100">
                <div className="col_100">
                  <input
                    type="text"
                    // placeholder="Description"
                    name="air_serial_number"
                    style={{ textTransform: "uppercase" }}
                    value={this.state.air_serial_number}
                    onChange={this.handleChange}
                    autocomplete="off"
                    // required
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="formwidth">
            <div className="col_50">
              <div className="col_100">
                <label>
                  Damaged Part:
                  {/* <span style={{ color: "red" }}> *</span> */}
                </label>
              </div>
              <div className="col_100">
                <div className="col_100">
                  <input
                    type="text"
                    //   placeholder="Description"
                    name="damaged_parts"
                    style={{ textTransform: "uppercase" }}
                    value={this.state.damaged_parts}
                    onChange={this.handleChange}
                    autocomplete="off"
                    //required
                  />
                </div>
              </div>
            </div>

            <div className="col_50">
              <div className="col_100">
                <label>
                  Planned Days To Repair the bus:
                  {/* <span style={{ color: "red" }}> *</span> */}
                </label>
              </div>
              <div className="col_100">
                <div className="col_100">

                <input
                      type="number"
                     // min={moment().subtract(7, "d").format("YYYY-MM-DD")}
                     // max={moment().add(7, "d").format("YYYY-MM-DD")}
                      //placeholder="Trip Date"
                      name="planned_days_for_repair_the_bus"
                      value={
                        this.state.planned_days_for_repair_the_bus
                      }
                      onChange={this.handleChange}
                      autocomplete="off"
                      required
                    />
                  {/* <input
                    type="text"
                    // placeholder="Description"
                    name="planned_days_for_repair_the_bus"
                    style={{ textTransform: "uppercase" }}
                    value={
                      this.state.editingfields.planned_days_for_repair_the_bus
                    }
                    onChange={this.handleChange}
                    autocomplete="off"
                    // required
                  /> */}
                </div>
              </div>
            </div>
          </div>

          <div className="formwidth">
            <div className="col_50">
              <div className="col_100">
                <label>
                  Reason Of Accident:
                  {/* <span style={{ color: "red" }}> *</span> */}
                </label>
              </div>
              <div className="col_100">
                <div className="col_100">
                  <input
                    type="text"
                    // placeholder="Description"
                    name="accident_reason"
                    style={{ textTransform: "uppercase" }}
                    value={this.state.accident_reason}
                    onChange={this.handleChange}
                    autocomplete="off"
                    //required
                  />
                </div>
              </div>
            </div>

            <div className="col_50">
              <div className="col_100">
                <label>
                  Insurance Claim Required:
                  {/* <span style={{ color: "red" }}> *</span> */}
                </label>
              </div>
              <div className="col_100">
                <div className="col_100">
                  {/* <input
                    type="text"
                    //  placeholder="Description"
                    name="insurance_claim_required"
                    style={{ textTransform: "uppercase" }}
                    value={this.state.insurance_claim_required}
                    onChange={this.handleChange}
                    autocomplete="off"
                    //  required
                  /> */}
                     <select
                  placeholder=""
                  name="insurance_claim_required"
                  onChange={this.handleChange}
                  value={this.state.insurance_claim_required}
                >
                  <option defaultValue="">Yes</option>
                  <option defaultValue="">No</option>
               
                </select>
                </div>
              </div>
            </div>
          </div>

          <div className="formwidth">
            <div className="col_50">
              <div className="col_100">
                <label>
                  Job Card Number:
                  {/* <span style={{ color: "red" }}> *</span> */}
                </label>
              </div>
              <div className="col_100">
                <div className="col_100">
                  <input
                    type="text"
                    //  placeholder="Description"
                    name="job_card_number"
                    style={{ textTransform: "uppercase" }}
                    value={this.state.job_card_number}
                    onChange={this.handleChange}
                    autocomplete="off"
                    // required
                  />
                </div>
              </div>
            </div>

            <div className="col_50">
              <div className="col_100">
                <label>
                  Cost Of Part:
                  {/* <span style={{ color: "red" }}> *</span> */}
                </label>
              </div>
              <div className="col_100">
                <div className="col_100">
                  <input
                    type="number"
                    //  placeholder="Description"
                    name="cost_of_part"
                    style={{ textTransform: "uppercase" }}
                    value={this.state.cost_of_part}
                    onChange={this.handleChange}
                    autocomplete="off"
                    // required
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="formwidth">
            <div className="col_50">
              <div className="col_100">
                <label>
                  Cost Of Labour:
                  {/* <span style={{ color: "red" }}> *</span> */}
                </label>
              </div>
              <div className="col_100">
                <div className="col_100">
                  <input
                    type="number"
                    //  placeholder="Description"
                    name="cost_of_labour"
                    style={{ textTransform: "uppercase" }}
                    value={this.state.cost_of_labour}
                    onChange={this.handleChange}
                    autocomplete="off"
                    // required
                  />
                </div>
              </div>
            </div>

            <div className="col_50">
              <div className="col_100">
                <label>
                  Actual Cost:
                  {/* <span style={{ color: "red" }}> *</span> */}
                </label>
              </div>
              <div className="col_100">
                <div className="col_100">
                  <input
                    type="number"
                    // placeholder="Description"
                    name="actual_cost"
                    style={{ textTransform: "uppercase" }}
                    value={this.state.actual_cost}
                    onChange={this.handleChange}
                    autocomplete="off"
                    // required
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="formwidth">
            <div className="col_50">
              <div className="col_100">
                <label>
                  Estimation Cost:
                  {/* <span style={{ color: "red" }}> *</span> */}
                </label>
              </div>
              <div className="col_100">
                <div className="col_100">
                  <input
                    type="number"
                    //  placeholder="Description"
                    name="estimation_cost"
                    style={{ textTransform: "uppercase" }}
                    value={this.state.estimation_cost}
                    onChange={this.handleChange}
                    autocomplete="off"
                    // required
                  />
                </div>
              </div>
            </div>

            <div className="col_50">
              <div className="col_100">
                <label>
                  Recovery From Insurance:
                  {/* <span style={{ color: "red" }}> *</span> */}
                </label>
              </div>
              <div className="col_100">
                <div className="col_100">
                  <input
                    type="number"
                    // placeholder="Description"
                    name="recovry_from_insurance"
                    style={{ textTransform: "uppercase" }}
                    value={this.state.recovry_from_insurance}
                    onChange={this.handleChange}
                    autocomplete="off"
                    // required
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="formwidth">
            <div className="col_50">
              <div className="col_100">
                <label>
                  Loss:
                  {/* <span style={{ color: "red" }}> *</span> */}
                </label>
              </div>
              <div className="col_100">
                <div className="col_100">
                  <input
                    type="number"
                    //placeholder="Description"
                    name="loss"
                    style={{ textTransform: "uppercase" }}
                    value={this.state.loss}
                    onChange={this.handleChange}
                    autocomplete="off"
                    //required
                  />
                </div>
              </div>
            </div>

            <div className="col_50">
              <div className="col_100">
                <label>
                  Major/Minor:
                  {/* <span style={{ color: "red" }}> *</span> */}
                </label>
              </div>
              <div className="col_100">
                <div className="col_100">
                  {/* <input
                    type="text"
                    //placeholder="Description"
                    name="major_minor"
                    style={{ textTransform: "uppercase" }}
                    value={this.state.major_minor}
                    onChange={this.handleChange}
                    autocomplete="off"
                    //required
                  /> */}
                           <select
                  placeholder=""
                  name="major_minor"
                  onChange={this.handleChange}
                  value={this.state.major_minor}
                >
                  <option defaultValue="">Major</option>
                  <option defaultValue="">Minor</option>
               
                </select>
                </div>
              </div>
            </div>
          </div>

          <div className="formwidth">
            <div className="col_50">
              <div className="col_100">
                <label>
                  Bus On Road Date:
                  {/* <span style={{ color: "red" }}> *</span> */}
                </label>
              </div>
              <div className="col_100">
                <div className="col_100">


                <input
                      type="Date"
                      maxDate={moment().toDate()}
                      name="bus_on_road_date"
                      value={this.state.bus_on_road_date}
                      onChange={this.handleChange}
                      autocomplete="off"
                     // required
                    />
                  {/* <input
                    type="text"
                    //placeholder="Description"
                    name="bus_on_road_date"
                    style={{ textTransform: "uppercase" }}
                    value={this.state.editingfields.bus_on_road_date}
                    onChange={this.handleChange}
                    autocomplete="off"
                    // required
                  /> */}
                </div>
              </div>
            </div>

            <div className="col_50">
              <div className="col_100">
                <label>
                  Remarks:
                  {/* <span style={{ color: "red" }}> *</span> */}
                </label>
              </div>
              <div className="col_100">
                <div className="col_100">
                  <input
                    type="text"
                    // placeholder="Description"
                    name="additional_remarks"
                    style={{ textTransform: "uppercase" }}
                    value={this.state.additional_remarks}
                    onChange={this.handleChange}
                    autocomplete="off"
                    // required
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="formwidth">
          <div className="col_50">
              <div className="col_100">
                <label>
                  Odometer:
                  {/* <span style={{ color: "red" }}> *</span> */}
                </label>
              </div>
              <div className="col_100">
                <div className="col_100">
                  <input
                    type="number"
                    // placeholder="Description"
                    name="odometer"
                    style={{ textTransform: "uppercase" }}
                    value={
                      this.state
                        .odometer
                    }
                    onChange={this.handleChange}
                    autocomplete="off"
                    //  required
                  />
                </div>
              </div>
            </div>
            <div className="col_50">
              <div className="col_100">
                <label>
                  Total Days Used For Repair:
                  {/* <span style={{ color: "red" }}> *</span> */}
                </label>
              </div>
              <div className="col_100">
                <div className="col_100">
                  <input
                    type="number"
                    // placeholder="Description"
                    name="total_days_used_for_accident_repair"
                    style={{ textTransform: "uppercase" }}
                    value={
                      this.state
                        .total_days_used_for_accident_repair
                    }
                    onChange={this.handleChange}
                    autocomplete="off"
                    //  required
                  />
                </div>
              </div>
            </div>

       
          </div>
          <input type="reset" onClick={this.resetButtonClick}  value="Reset" />
          <input
            type="submit"
            value="Submit"
            className="submit-accident-master"
          />
        </form>
        <p>(The * marked fields are mandatory) </p>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps, {
  createAccidentMasterAPI,
})(withRouter(MasterAccidentCreate));
