const driverMonitoringviewApiActions = {
  GET_DRIVERMONITORINGVIEW: "GET_DRIVERMONITORINGVIEW",
  GET_DRIVERMONITORINGVIEW_SUCCESS: "GET_DRIVERMONITORINGVIEW_SUCCESS",
  GET_DRIVERMONITORINGVIEW_FAILURE: "GET_DRIVERMONITORINGVIEW_FAILURE",

  getDriverMonitoringViewAPI: (data) => ({
    type: driverMonitoringviewApiActions.GET_DRIVERMONITORINGVIEW,
    payload: { data },
  }),

  getDriverMonitoringViewAPISuccess: (saveStatusCode, result) => ({
    type: driverMonitoringviewApiActions.GET_DRIVERMONITORINGVIEW_SUCCESS,
    saveStatusCode,
    result,
  }),

  getDriverMonitoringViewAPIFailure: (saveStatusCode, message) => ({
    type: driverMonitoringviewApiActions.GET_DRIVERMONITORINGVIEW_FAILURE,
    saveStatusCode,
    message,
  }),

  GET_DRIVERMONITORINGCALENDARVIEW: "GET_DRIVERMONITORINGCALENDARVIEW",
  GET_DRIVERMONITORINGCALENDARVIEW_SUCCESS:
    "GET_DRIVERMONITORINGCALENDARVIEW_SUCCESS",
  GET_DRIVERMONITORINGCALENDARVIEW_FAILURE:
    "GET_DRIVERMONITORINGCALENDARVIEW_FAILURE",

  getDriverMonitoringCalendarViewAPI: (data) => ({
    type: driverMonitoringviewApiActions.GET_DRIVERMONITORINGCALENDARVIEW,
    payload: { data },
  }),

  getDriverMonitoringCalendarViewAPISuccess: (saveStatusCode, result) => ({
    type: driverMonitoringviewApiActions.GET_DRIVERMONITORINGCALENDARVIEW_SUCCESS,
    saveStatusCode,
    result,
  }),

  getDriverMonitoringCalendarViewAPIFailure: (saveStatusCode, message) => ({
    type: driverMonitoringviewApiActions.GET_DRIVERMONITORINGCALENDARVIEW_FAILURE,
    saveStatusCode,
    message,
  }),
};

export default driverMonitoringviewApiActions;
