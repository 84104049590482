import React, { Component } from "react";
import "../dashboard_opportunity_chargers/index.scss";
import { Collapse } from "react-collapse";
import Tooltip from "react-tooltip";
class DashboardOpportunityChargers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isDropdownOpen: false,
      dropdownToggleIcon: "+",
    };
  }

  onDropdownClicked = () => {
    this.setState((prevState) => ({
      isDropdownOpen: !prevState.isDropdownOpen,
      dropdownToggleIcon: prevState.dropdownToggleIcon === "+" ? "-" : "+",
    }));
  };

  clickedCard(cardName) {
    // console.log("cardName", cardName);
    sessionStorage.setItem("cardClicked", cardName);
    this.props.clickdata();
  }

  render() {
    return (
      <div className="opportynity">
        <div className="opportynity-chargers">
          <Tooltip id="info" style={{ backgroundColor: "blue" }}></Tooltip>
          <img
            data-tip-disable
            data-tip
            data-for="info"
            title="Opportunity chargers"
            style={{ marginLeft: "5px", marginTop: "-40px" }}
            src={require("../../assets/images/headernew/head.png")}
            alt="next"
          />
          <img
            className="oppotunity-png"
            src={require("../../assets/images/depot/oppor.png")}
            alt="next"
          />
          <div className="opportynity-chargers-content">
            <p className="opportynity-chargers-text"> OPPORTUNITY CHARGERS</p>
            <button
              className=""
              style={{
                borderRadius: "50%",
                height: "11px",
                width: "32px",
                marginTop: "-19px",
                backgroundColor: "#353844",
                color: "white",
                marginLeft: "10px",
                paddingBottom: "26px",
              }}
              onClick={this.onDropdownClicked}
            >
              {this.state.dropdownToggleIcon}
            </button>
            {/* <p className='clicktosee'> CLICK TO SEE THE VIEW </p> */}
          </div>
        </div>
        <Collapse
          isOpened={this.state.isDropdownOpen}
          className="opportynity-charger-status"
        >
          <div className="container-fluid">
            <p className="opportynity-charger-status-text">Charger Status</p>
            <div class="row mt-3 mb-3">
              <div class="col-sm ms-3">
                <img
                  className="imagesss"
                  src={require("../../assets/images/DEPOT-1/DEPOT-1.png")}
                  alt=""
                />
                <h3 className="opportynity-charger-number-1">
                  {this.props.oppoavailablecharger}
                </h3>
                <p className="opportynity-chargerss-text">
                  AVL CHARGING POINTS
                </p>
                <button
                  type="button"
                  className="opportynity-charger-btn-1"
                  onClick={() => this.clickedCard("oppo_available")}
                >
                  LIST VIEW
                  <img
                    className="opportynity-charger-button-png"
                    src={require("../../assets/images/arrow-red/red.png")}
                    alt="next"
                  />
                </button>
              </div>
              <div class="col-sm">
                <img
                  className="imagesss"
                  src={require("../../assets/images/DEPOT-1/DEPOT-1.png")}
                  alt=""
                />
                <h3 className="opportynity-charger-number-2">
                  {this.props.oppooccupiedcharger}
                </h3>
                <p className="opportynity-chargerss-text">
                  OCU CHARGING POINTS
                </p>
                <button
                  type="button"
                  className="opportynity-charger-btn-1"
                  onClick={() => this.clickedCard("oppo_occupied")}
                >
                  LIST VIEW
                  <img
                    className="opportynity-charger-button-png"
                    src={require("../../assets/images/arrow-red/red.png")}
                    alt="next"
                  />
                </button>
              </div>
              <div class="col-sm">
                <img
                  className="imagesss"
                  src={require("../../assets/images/DEPOT-3/DEPOT-3.png")}
                  alt=""
                />
                <h3 className="opportynity-charger-number-3">
                  {this.props.oppobreakdowncharger}
                </h3>
                <p className="opportynity-chargerss-text">
                  BKD CHARGING POINTS
                </p>
                <button
                  type="button"
                  className="opportynity-charger-btn-1"
                  onClick={() => this.clickedCard("oppo_breakdown")}
                >
                  LIST VIEW
                  <img
                    className="opportynity-charger-button-png"
                    src={require("../../assets/images/arrow-red/red.png")}
                    alt="next"
                  />
                </button>
              </div>
              <div class="col-sm">
                <img
                  className="imagess-1"
                  src={require("../../assets/images/DEPOT-2/DEPOT-2.png")}
                  alt=""
                />
                <h3 className="opportynity-charger-number-4">
                  {this.props.oppochargerefficiency} %
                </h3>
                <p className="opportynity-chargerss-text">CHARGER EFFCIENCY</p>
                <button
                  type="button"
                  className="opportynity-charger-btn-1"
                  onClick={() => this.clickedCard("oppo_charger")}
                >
                  LIST VIEW
                  <img
                    className="opportynity-charger-button-png"
                    src={require("../../assets/images/arrow-red/red.png")}
                    alt="next"
                  />
                </button>
              </div>
            </div>
          </div>
        </Collapse>
      </div>
    );
  }
}

export default DashboardOpportunityChargers;
