import React, { Component } from "react";
import ReactTable from "react-table";
import "react-table/react-table.css";
import TripUpdationButton from "../../component/trip_updation_button";
import "./index.scss";
import ReactTooltip from "react-tooltip";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { matchSorter } from "match-sorter";
import Workbook from "react-excel-workbook";
//import moment from "moment";
//import BackToScreen from "../../component/back_to_screen";
import ReactLoader from "../react-loader/react-loader.component";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalTitle,
  Button,
  closeButton,
} from "react-bootstrap";
// //Actions
import tripMasterDetailsActions from "../../redux/trip_master_details/actions";
import driverlistDropdownActions from "../../redux/driverlist_dropdown/actions";
import vehiclelistDropdownActions from "../../redux/vehiclelist_dropdown/actions";
import tripUploadApiActions from "../../redux/trip-upload/actions";
const {
  getTripMasterDetailsAPI,
  getTripSubmissionDetailsAPI,
  GET_TRIPSUBMISSIONDETAILS_FAILURE,
  GET_TRIPSUBMISSIONDETAILS_SUCCESS,
} = tripMasterDetailsActions;
const { getDriverlistDropdownAPI } = driverlistDropdownActions;
const { getVehiclelistDropdownAPI } = vehiclelistDropdownActions;

const { getTripUploadAPI } = tripUploadApiActions;
class TripUpdate extends Component {
  constructor(props) {
    super(props);
    let data1 = [
      {
        sno: "1",
        busNo: "CH01TB8398",
        routeNo: "7",
        duty: "M",
        driverID: "11035",
        driverName: "SATNAM Sinha",
        mobileNo: "8976453475",
        reportingTime: "31/10/2022 04:55:00 AM",
        startTime: "30/10/2022 05:55:00 AM",
        endTime: "30/10/2022 12:35:00 PM",
        startLocation: "W/shop Depot 3",
        endLocation: "Ramdarbar Bus Terminal",
      },
    ];

    this.state = {
      showModal: false,
      data1,
      data: [],
      pages: null,
      loading: true,
      message: "",
      messageType: 0,
      dataSet: [],
      selected: null,
      vehicleList: [],
      driverList: [],
      filteredData: [],
      searchInput: "",
    };
    this.backtodashboardPage = this.backtodashboardPage.bind(this);
    this.onSubmitSubmission = this.onSubmitSubmission.bind(this);
  }

  addZeroInfront = (data) => {
    let item = "" + data;
    if (item.length === 2) return item;
    else return "0" + item;
  };

  getCurrentDate = (num) => {
    let d = new Date();
    d.setDate(d.getDate() + num);
    let month = d.getMonth() + 1;
    let date =
      d.getFullYear() +
      "-" +
      this.addZeroInfront(month) +
      "-" +
      this.addZeroInfront(d.getDate());
    return date;
  };

  getFromDate = (num) => {
    let d = new Date();
    d.setDate(d.getDate() - num);
    let month = d.getMonth() + 1;
    let date =
      d.getFullYear() +
      "-" +
      this.addZeroInfront(month) +
      "-" +
      this.addZeroInfront(d.getDate());
    return date;
  };

  componentDidMount() {
    this.getTripMasterDetails();
    this.getDriverlistDropdown();
    this.getVehiclelistDropdown();
    // this.getTripUploadAPI();
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      if (
        this.props.tripMasterDetailsApi?.tripMasterDetailsApi?.result?.data
          ?.responseData?.length > 0
      ) {
        this.setState({
          dataSet:
            this.props.tripMasterDetailsApi.tripMasterDetailsApi.result.data
              .responseData,
        });
        this.setState({
          filteredData:
            this.props.tripMasterDetailsApi.tripMasterDetailsApi.result.data
              .responseData,
        });
      } else {
        this.setState({ dataSet: [] });
      }
      if (
        this.props.driverlistDropdownApi?.driverlistDropdownApi?.result?.data
          ?.responseData?.length > 0
      ) {
        this.setState({
          driverList:
            this.props.driverlistDropdownApi.driverlistDropdownApi.result.data
              .responseData,
        });
      } else {
        this.setState({ driverList: [] });
      }
      if (
        this.props.vehiclelistDropdownApi?.vehiclelistDropdownApi?.result?.data
          ?.responseData?.length > 0
      ) {
        this.setState({
          vehicleList:
            this.props.vehiclelistDropdownApi.vehiclelistDropdownApi.result.data
              .responseData,
        });
      } else {
        this.setState({ vehicleList: [] });
      }

      //submission
      if (
        prevProps.tripMasterDetailsApi.tripSubmissionDetailsApi !=
        this.props.tripMasterDetailsApi.tripSubmissionDetailsApi
      ) {
        if (
          this.props.tripMasterDetailsApi.tripSubmissionDetailsApi
            .actionType === GET_TRIPSUBMISSIONDETAILS_SUCCESS &&
          this.props.tripMasterDetailsApi.tripSubmissionDetailsApi.loading ===
            false
        ) {
          this.setState({
            message: "Trip Updation Submitted successfully",
            messageType: 1,
          });
          this.messageTimeOut = setTimeout(
            () => this.onClickCloseMessage(),
            3 * 1000
          );

          // this.getVehicleMaster();
        } else if (
          this.props.tripMasterDetailsApi.tripSubmissionDetailsApi
            .actionType === GET_TRIPSUBMISSIONDETAILS_FAILURE &&
          this.props.tripMasterDetailsApi.tripSubmissionDetailsApi.loading ===
            false
        ) {
          this.setState({
            message: "Trip Updation Can Not Be Submitted",
            messageType: -1,
          });
          this.messageTimeOut = setTimeout(
            () => this.onClickCloseMessage(),
            3 * 1000
          );
        }
      }
    }
  }

  getTripMasterDetails() {
    this.setState({ loading: true });
    const payload = {
      api_key: "ALL_TRIP_DETAILS",
      user_id: sessionStorage.getItem("userId"),
      customerId: sessionStorage.getItem("customerId"),
    };
    this.props.getTripMasterDetailsAPI(payload);
  }

  getDriverlistDropdown() {
    this.setState({ loading: true });
    const payload = {
      api_key: "ALL_DRIVER_DETAILS",
      user_id: sessionStorage.getItem("userId"),
      customerId: sessionStorage.getItem("customerId"),
    };
    this.props.getDriverlistDropdownAPI(payload);
  }

  onSubmitSubmission() {
    // console.log("called");
    //this.setState({ loading: true });
    // const payload = {
    //   user_id: sessionStorage.getItem("userId"),
    //   user_name: sessionStorage.getItem("userName"),
    //   status: true,
    // };
    // this.props.getTripSubmissionDetailsAPI(payload);

    this.setState({ showModal: true });
  }

  getVehiclelistDropdown() {
    this.setState({ loading: true });
    const payload = {
      api_key: "ALL_VEHICLE_DETAILS",
      user_id: sessionStorage.getItem("userId"),
      customerId: sessionStorage.getItem("customerId"),
    };
    this.props.getVehiclelistDropdownAPI(payload);
  }

  uploadTripFile = (e) => {
    let fileInput = document.querySelector('input[type="file"]');

    // if (fileInput.type == "xlsx") {
    if (fileInput) {
      this.setState({ uploadingFile: true });
      let data = new FormData();

      data.append("file", fileInput.files[0]);

      this.props.getTripUploadAPI({
        body: data,
      });
    }
  };

  handleChangemaster = (event) => {
    // const search = event.target.value;
    // this.setState({
    //   searchInput: search,
    //   filteredData: this.state.dataSet.filter(
    //     (item) =>
    //       (item.driver_name &&
    //         item.driver_name.toLowerCase().includes(search)) ||
    //       (item.reg_no && item.reg_no.toLowerCase().includes(search)) ||
    //       (item.route_id && item.route_id.toLowerCase().includes(search)) ||
    //       (item.driver_id && item.driver_id.includes(search)) ||
    //       (item.route_order && item.route_order.includes(search))
    //   ),
    // });


    
  const { value } = event.target;
  const { dataSet } = this.state;
  const searchInput = value.trim().toLowerCase();
  const filteredData = dataSet.filter((item) =>
    Object.keys(item).some(
      (key) =>
      typeof item[key] === "string" &&
        item[key] &&
        (key === "driver_name" ||
         key === "reg_no" ||
         key === "route_order" || 
         key === "driver_id"
         )
          &&
        item[key].toString().toLowerCase().includes(searchInput)
    )
  );
  this.setState({ searchInput, filteredData });
}
  

  backtodashboardPage() {
    let tabItem = {
      tabIndex: 16,
      tabName: "Dashboard",
    };
    this.props.selectedTab(null, tabItem);
  }
  addModalCloses = () => this.setState({ showModal: false });

  onClickCloseMessage() {
    this.messageTimeOut = null;
    this.setState({
      message: "",
      messageType: 0,
    });
  }

  handleChange = (evt) => {
    const value = evt.target.value;
    this.setState({
      ...this.state,
      [evt.target.name]: value,
    });
    const array = [];
    array.push(this.state);
  };

  handleSubmits = (event) => {
    //console.log("haiii", this.props.createVehicleMasterAPI(payload));
    event.preventDefault();
    const { remark } = this.state;

    const payload = {
      remark: remark.toUpperCase(),
      user_id: sessionStorage.getItem("userId"),
      customerId: sessionStorage.getItem("customerId"),
      user_name: sessionStorage.getItem("userName"),
      status: true,
    };
    this.props.getTripSubmissionDetailsAPI(payload);
    this.addModalCloses();
  };
  render() {
    let { searchInput } = this.state;
    const dataSet1 = [
      {
        columns: [
          "S.NO",
          "Bus No",
          "Route No",
          "Duty",
          "Driver ID",
          "Driver Name",
          "Mobile No",
          "Reporting Time",
          "Start Time",
          "End Time",
          "Start Location",
          "End Location",
        ],
        data: [],
      },
    ];
    // const { data } = this.state;
    // console.log("dropdown props", this.props);

    return (
      <div>
        {this.state.message && (
          <div
            className={`alert ${
              this.state.messageType === 1
                ? "success"
                : this.state.messageType === -1
                ? "error"
                : ""
            }`}
          >
            <span
              className="closebtn"
              onClick={() => this.onClickCloseMessage()}
            >
              &times;
            </span>
            <strong>{this.state.message}</strong>
          </div>
        )}
        {this.props.tripMasterDetailsApi?.tripMasterDetailsApi?.loading ? (
          <div className="loader-overlay">
            <div className="loader-container">
              <ReactLoader />
            </div>
          </div>
        ) : null}
        <div className="trip-master-subhead">
          {/* <div className="fleet-health-backward">
            <BackToScreen routingCall={this.backtodashboardPage} /> */}
          {/* <h3 className="tripmaster">Trip Updation</h3> */}
          {/* </div> */}

          {/* <div>
               
              </div> */}

          {/* <div className="table-items-count">
            Items: {this.state.filteredData.length}
          </div> */}
          <div className="search-trip-master-report ">
            <button
              className="trip-updation-submit-kpis"
              onClick={this.onSubmitSubmission}
            >
              {/* <i
                    className="fa fa-check"
                    style={{ paddingRight: "5px" }}
                  ></i> */}
              Daily Trip Submit
            </button>

            {this.state.showModal && (
              <div>
                {/* Modal content goes here */}

                <Modal
                  style={{
                    height: "1000px",
                    minHeight: "500px",
                  }}
                  show={this.state.showModal}
                  size="lg"
                  aria-labelledby="contained-modal-title-vcenter"
                  centered
                  // onHide={this.props.closemodal}
                  onHide={this.addModalCloses}
                >
                  <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                      <p className="trip_updation_popup_title">
                        Are you sure, you want to submit the trips?
                      </p>
                    </Modal.Title>
                  </Modal.Header>
                  <form
                    className="trip-popup-update"
                    autocomplete="off"
                    onSubmit={this.handleSubmits}
                  >
                    <Modal.Body
                      style={{
                        "max-height": "calc(80vh - 210px)",

                        "overflow-y": "auto",
                      }}
                    >
                      <div className="formwidth">
                        <div className="col_50_trip_update">
                          <div className="col_100_trip_update">
                            <label>Remark</label>
                            <span
                              style={{
                                color: "red",
                                marginLeft: "-120px",
                                marginTop: "20px",
                              }}
                            >
                              {" "}
                              *
                            </span>
                          </div>
                          <div className="col_100_trip_update">
                            <input
                              type="text"
                              // placeholder="Ex: CH01TB0000"
                              name="remark"
                              value={this.state.remark}
                              style={{ textTransform: "uppercase" }}
                              onChange={this.handleChange}
                              autocomplete="off"
                              required
                            />
                          </div>
                        </div>
                      </div>
                    </Modal.Body>
                    <Modal.Footer>
                      <input
                        className="button_trip_updation"
                        type="submit"
                        value="Submit"
                      />
                    </Modal.Footer>
                  </form>
                </Modal>
              </div>
            )}
            <form
              onSubmit={(e) => {
                e.preventDefault();
              }}
            >
              <input
                className="search-trip-master-input"
                type="text"
                id="filter"
                placeholder="Search Table ( Driver ID / Driver Name / Reg No )"
                value={searchInput || ""}
                onChange={this.handleChangemaster}
              />
            </form>
          </div>
          <div className="download-upload">
            <i
              className="fa fa-upload"
              //title="Edit"
              style={{ fontSize: "22px" }}
              onClick={() => {
                document.querySelector('input[type="file"]').value = null;
                document.querySelector('input[type="file"]').click();
              }}
            ></i>

            <input
              type="file"
              onChange={(e) => {
                this.uploadTripFile(e);
              }}
              style={{ display: "none" }}
            />
          </div>
          <div>
            <Workbook
              filename="Trip-Updation-Details.xlsx"
              element={
                <div
                  className="Trip-Updation_report-download"
                  data-tip
                  data-for="registerTip"
                >
                  Export
                  <img
                    src={require("../../assets/images/export/export.png")}
                    alt=""
                    className="Trip-Updation_report-download-img"
                  />
                  <ReactTooltip id="registerTip" place="top" effect="solid">
                    Download
                  </ReactTooltip>
                </div>
              }
            >
              <Workbook.Sheet
                data={this.state.dataSet}
                name="Trip-Updation-Details"
              >
                <Workbook.Column label="Route ID" value="route_id" />
                <Workbook.Column label="Route Code" value="route_order" />
                <Workbook.Column label="Duty" value="duty" />
                <Workbook.Column label="Reg No" value="reg_no" />
                <Workbook.Column label="Driver ID" value="driver_id" />
                <Workbook.Column label="Driver Name" value="driver_name" />
                <Workbook.Column label="Mobile No" value="mobile_number" />
                {/* <Workbook.Column
                  label="Reporting Time"
                  value="reporting_time"
                /> */}
                <Workbook.Column label="Start Time" value="start_time" />
                <Workbook.Column label="End Time" value="end_time" />
                <Workbook.Column
                  label="Start Location"
                  value="start_location"
                />
                <Workbook.Column label="End Location" value="end_location" />
                <Workbook.Column label="Trip ID" value="schedule_id" />
              </Workbook.Sheet>
            </Workbook>
          </div>
        </div>
        <div className="upload-container icon_pos">
          {/* <h2 className="msg">
            Note: Future trips will be reflected in Excel Sheet
          </h2> */}
          <ReactTooltip place="left" type="success" effect="solid" />
          <ReactTable
            NoDataComponent={() => null}
            pageSizeOptions={[20, 30, 50, 80, 100, 130, 200, 500]}
            style={{
              height: "460px",
              marginTop: "0%",
              marginRight: "10px",
              marginLeft: "20px",
            }}
            data={this.state.filteredData}
            showPagination={true}
            columns={[
              {
                Header: "Edit",
                width: 50,
                Cell: (props) => (
                  <TripUpdationButton
                    data={props}
                    driverDropdown={this.state.driverList}
                    vehicleDropdown={this.state.vehicleList}
                  ></TripUpdationButton>
                ),
                resizable: false,
              },

              {
                Header: "S.No",
                id: "serialNumber",
                resizable: false,
                filterable: false,
                width: 90,
                Cell: (row) => {
                  return <div>{row.index + 1}</div>;
                },
              },
              {
                Header: "Route ID",
                id: "route_id",
                accessor: (d) => d.route_id,
                width: 83,
                filterAll: true,
                resizable: false,
              },
              {
                Header: "Route Code",
                id: "route_order",
                accessor: (d) => d.route_order,
                width: 100,
                filterMethod: (filter, rows) =>
                  matchSorter(rows, filter.value, { keys: ["route_order"] }),
                filterAll: true,
                resizable: false,
              },

              {
                Header: "Duty",
                id: "duty",
                accessor: (d) => d.duty,
                width: 60,
                filterMethod: (filter, rows) =>
                  matchSorter(rows, filter.value, { keys: ["duty"] }),
                filterAll: true,
                resizable: false,
              },
              {
                Header: "Reg No.",
                id: "reg_no",
                accessor: (d) => d.reg_no,
                width: 120,
                filterAll: true,
                resizable: false,
              },
              {
                Header: "Driver Name",
                id: "driver_name",
                accessor: (d) => d.driver_name,
                width: 150,
                Cell: (rows) => {
                  /* Add data-tip */
                  return <span title={rows.value}>{rows.value}</span>;
                },
                filterMethod: (filter, rows) =>
                  matchSorter(rows, filter.value, { keys: ["driver_name"] }),
                filterAll: true,
                resizable: false,
              },

              {
                Header: "Driver ID",
                id: "driver_id",
                accessor: (d) => d.driver_id,
                width: 90,
                filterMethod: (filter, rows) =>
                  matchSorter(rows, filter.value, { keys: ["driver_id"] }),
                filterAll: true,
                resizable: false,
              },

              {
                Header: "Mobile Number",
                id: "mobile_number",
                accessor: (d) => d.mobile_number,
                width: 120,
                filterMethod: (filter, rows) =>
                  matchSorter(rows, filter.value, { keys: ["mobile_number"] }),
                filterAll: true,
                resizable: false,
              },
              {
                Header: "Start Time",
                id: "start_time",
                accessor: (d) => d.start_time,
                width: 90,
                filterMethod: (filter, rows) =>
                  matchSorter(rows, filter.value, { keys: ["start_time"] }),
                filterAll: true,
                resizable: false,
              },
              {
                Header: "End Time",
                id: "end_time",
                accessor: (d) => d.end_time,
                width: 80,
                filterMethod: (filter, rows) =>
                  matchSorter(rows, filter.value, { keys: ["end_time"] }),
                filterAll: true,
                resizable: false,
              },

              {
                Header: "Start Location",
                id: "start_location",
                width: 140,
                accessor: (d) => d.start_location,
                Cell: (rows) => {
                  /* Add data-tip */
                  return <span title={rows.value}>{rows.value}</span>;
                },
                filterMethod: (filter, rows) =>
                  matchSorter(rows, filter.value, { keys: ["start_location"] }),
                filterAll: true,
                resizable: false,
              },
              {
                Header: "End Location",
                id: "end_location",
                width: 150,
                accessor: (d) => d.end_location,
                Cell: (rows) => {
                  /* Add data-tip */
                  return <span title={rows.value}>{rows.value}</span>;
                },
                filterMethod: (filter, rows) =>
                  matchSorter(rows, filter.value, { keys: ["end_location"] }),
                filterAll: true,
                resizable: false,
              },
              {
                Header: "Trip ID",
                id: "schedule_id",
                accessor: (d) => d.schedule_id,
                width: 100,
                filterMethod: (filter, rows) =>
                  matchSorter(rows, filter.value, { keys: ["schedule_id"] }),
                filterAll: true,
                resizable: false,
              },
            ]}
            defaultSorted={[
              {
                id: "serialNumber",
                asc: true,
              },
            ]}
            defaultPageSize={50}
            className="-striped -highlight"
          >
            {(state, makeTable, instance) => {
              let recordsInfoText = "";

              const { filtered, pageRows, pageSize, sortedData, page } = state;

              if (sortedData && sortedData.length > 0) {
                let isFiltered = filtered.length > 0;

                let totalRecords = sortedData.length;

                let recordsCountFrom = page * pageSize + 1;

                let recordsCountTo = recordsCountFrom + pageRows.length - 1;

                if (isFiltered)
                  recordsInfoText = `${recordsCountFrom}-${recordsCountTo} of ${totalRecords} filtered records`;
                else
                  recordsInfoText = `${recordsCountFrom}-${recordsCountTo} of ${totalRecords} records`;
              } else recordsInfoText = "No records";

              return (
                <div className="main-grid">
                  <div className="above-table text-right">
                    <div className="col-sm-12">
                      <span
                        style={{ marginLeft: "21px" }}
                        className="records-info"
                      >
                        {recordsInfoText}
                      </span>
                    </div>
                  </div>

                  {makeTable()}
                </div>
              );
            }}
          </ReactTable>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps, {
  getTripMasterDetailsAPI,
  getTripSubmissionDetailsAPI,
  getDriverlistDropdownAPI,
  getVehiclelistDropdownAPI,
  getTripUploadAPI,
})(withRouter(TripUpdate));

//export default TripUpdate;
