import React, { Component } from "react";
import "../../component/dashboard_energy_status/index.scss";

class DashboardEnergyStatus extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
    };
  }

  render() {
    return (
      <div className="charger-status">
        <div className="charger-status-div">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-4">
                <div className="charger-status-available">
                  <div className="number-pulse">
                    <div className="pulsatingDotone" />
                    <h3 className="charger-available-number">
                      {this.props.acconsumption}
                      <span className="energy-status-unit"> KWH</span>
                    </h3>
                  </div>
                  <p className="textss">Energy Input</p>
                </div>
              </div>

              <div className="col-sm-4">
                <div className="charger-status-occupied">
                  <div className="number-pulse">
                    <div className="pulsatingDottwo" />
                    <h3 className="charger-occupied-number">
                      {this.props.dcconsumption}
                      <span> KWH</span>
                    </h3>
                  </div>
                  <p className="textss">
                    Energy Converted
                    <br />
                    &nbsp;&nbsp; at Charger
                  </p>
                </div>
              </div>

              <div className="col-sm-4">
                <div className="charger-status-breakdown">
                  <div className="number-pulse">
                    <div className="pulsatingDotthree" />

                    <h3 className="charger-breakdown-number">
                      {this.props.powerdrawn}
                      <span> KWH</span>
                    </h3>
                  </div>
                  <p className="textss">
                    Power drawn by
                    <br /> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Veh
                    Battery
                  </p>
                </div>
              </div>
            </div>

            <div className="row charger-status-div-two">
              <div className="col-sm-4">
                <div className="charger-status-efficiency">
                  <div className="number-pulse">
                    <div className="pulsatingDotfour" />
                    <h3 className="charger-efficiency-number">
                      {this.props.acdcconversion}
                      <span> %</span>
                    </h3>
                  </div>
                  <p className="textss">
                    Charger efficiency
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </p>
                </div>
              </div>

              <div className="col-sm-4">
                <div className="charger-status-opportunity">
                  <div className="number-pulse">
                    <div className="pulsatingDotfour" />
                    <h3 className="charger-opportunity-number">
                      {this.props.dcdcconversion}
                      <span> %</span>
                    </h3>
                  </div>
                  <p className="textss">
                    Battery IN /
                    <br />
                    &nbsp;Charger OUT
                  </p>
                </div>
              </div>

              <div className="col-sm-4">
                <div className="energy-status-ac-to-dc">
                  <div className="number-pulse">
                    <div className="pulsatingDotfour" />
                    <h3 className="charger-opportunity-number">
                      {this.props.actodcinput}
                      <span> %</span>
                    </h3>
                  </div>
                  <p className="textss">
                    Battery IN / <br />
                    &nbsp;&nbsp;Energy Input
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default DashboardEnergyStatus;
