const tripMonitoringApiActions = {
    GET_TRIP_MONITORING: "GET_TRIP_MONITORING",
    GET_TRIP_MONITORING_SUCCESS: "GET_TRIP_MONITORING_SUCCESS",
    GET_TRIP_MONITORING_FAILURE: "GET_TRIP_MONITORING_FAILURE",
  
    getTripMonitoringAPI: (data) => ({
      type: tripMonitoringApiActions.GET_TRIP_MONITORING,
      payload: { data },
    }),
  
    getTripMonitoringAPISuccess: (saveStatusCode, result) => ({
      type: tripMonitoringApiActions.GET_TRIP_MONITORING_SUCCESS,
      saveStatusCode,
      result,
    }),
  
    getTripMonitoringAPIFailure: (saveStatusCode, message) => ({
      type: tripMonitoringApiActions.GET_TRIP_MONITORING_FAILURE,
      saveStatusCode,
      message,
    }),
  };
  
  export default tripMonitoringApiActions
  